<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm :fullpage="fullpage" @submit="onClickFunding" class="low-balance">
      <h1>Zero or low balance detected.</h1>
      <div class="mb-4 disclaimer">
        We are unable to verify a sufficient balance in your bank account.
        Please add funds to the linked bank account or link a different account
        to gain access to a Privacy card.
      </div>
      <b-alert variant="danger" :show="errorMessage.length > 0">
        {{ errorMessage }}
      </b-alert>
      <div class="explainer">
        <div class="content">
          <SVGIcon class="mb-2" icon="funding-bank" size="80" />
          <div class="title">Connect Bank Account</div>
          <div class="description">
            This account will be used to fund purchases you make on Privacy
            cards
          </div>
          <BaseButton
            class="w-100 my-2"
            type="submit"
            variant="primary"
            size="lg"
          >
            Connect
          </BaseButton>
          <b-link
            @click="onClickCard"
            data-test="connect-debit-card"
            aria-label="connect a debit card"
          >
            Can't connect a bank?
          </b-link>
        </div>
      </div>
      <BaseButton variant="light" @click="onClickRetry" class="mt-4" size="lg">
        Retry with the same account
      </BaseButton>
    </BaseForm>
    <BaseModal id="add-card" hide-header>
      <AddCard @complete-step="submit(FundingSource.CARD)" />
    </BaseModal>
    <BaseModal id="add-funding" hide-header>
      <AddFunding @complete-step="submit(FundingSource.BANK)" />
    </BaseModal>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

import { FundingSource } from "@/types/Funding";
import { User } from "@/types/User";
import { userStore } from "@/store";
import { BAlert } from "bootstrap-vue";
import SVGIcon from "@/components/SVGIcon.vue";
import { OnboardingSteps } from "@/types/Onboarding";
import AddCard from "./AddCard.vue";
import AddFunding from "./AddFunding.vue";

@Component({
  components: {
    BAlert,
    SVGIcon,
    AddCard,
    AddFunding,
  },
})
export default class FundingTypeCharge extends Vue {
  @Prop({ default: false }) fullpage!: boolean;

  FundingSource = FundingSource;
  errorMessage = "";
  user: User | null = null;

  created() {
    userStore.actions.getCurrentUser().then(() => {
      this.user = userStore.getters.currentUser;
    });
  }

  onClickFunding() {
    this.$bvModal.show("add-funding");
  }

  onClickCard() {
    this.$bvModal.show("add-card");
  }

  onClickRetry() {
    // TODO
  }

  submit(fundingType: FundingSource = FundingSource.BANK) {
    if (fundingType === FundingSource.BANK) {
      this.$emit("complete-step", OnboardingSteps.SUCCESS_BANK);
    } else if (fundingType === FundingSource.CARD) {
      this.$emit("complete-step", OnboardingSteps.SUCCESS_CARD);
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .form {
  width: 21.75rem;
}

.add-funding {
  width: auto;
}

.low-balance {
  text-align: center;
  opacity: 0;
  animation: show-step $duration-default;
  animation-timing-function: $ease-out-expo;
  animation-fill-mode: forwards;

  > h1 {
    all: unset;
    margin-bottom: 15px;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    color: $gray-800;
    line-height: 1.4;
  }

  .disclaimer {
    color: $gray-600;
  }

  .explainer {
    position: relative;

    .content {
      padding: 24px 32px;
      background: $gray-100;
      border-radius: $border-radius;
      color: $gray-600;
      display: grid;
      gap: 10px;
      justify-items: center;

      ::v-deep svg {
        zoom: 180%;
      }

      .title {
        font-weight: 600;
        color: $gray-800;
      }
    }
  }
}
</style>
