<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      class="flagged-account"
      :fullpage="fullpage"
      @submit="submit"
      data-test="flagged-account"
    >
      <h1>Thank you for your application.</h1>
      <div class="notice" v-if="user && user.hasLowBalance">
        <p>
          Oops! Your funding source must have at least $50 before you can create
          a card.
        </p>
      </div>
      <div class="notice" v-else-if="user && user.shouldContactSupport">
        <p>
          Your account is paused. Please reach out to
          <a href="mailto:support@privacy.com">support@privacy.com</a> for
          assistance with activating your account.
        </p>
      </div>
      <div class="notice" v-else>
        You have been declined for a Privacy.com Account.
      </div>
      <BaseButton
        class="mt-2"
        type="submit"
        variant="primary"
        size="lg"
        data-test="go-to-dashboard"
        @click="trackClick"
      >
        Go to my Dashboard
      </BaseButton>
      <b-link
        v-if="shouldShowReasoning"
        @click="showReasoningModal"
        aria-label="open FAQ modal"
      >
        Why was I declined?
      </b-link>
    </BaseForm>
    <BanReasoningModal></BanReasoningModal>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import BanReasoningModal from "@/components/modals/BanReasoning.vue";
import { userStore } from "@/store";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component({
  components: {
    BanReasoningModal,
  },
})
export default class FlaggedAccount extends Vue {
  @Prop({ default: false }) fullpage!: boolean;

  get user() {
    return userStore.getters.currentUser;
  }

  get shouldShowReasoning() {
    // only show the reasoning for non-irreversible bans
    return (
      this.user && !this.user.shouldContactSupport && !this.user.hasLowBalance
    );
  }

  trackClick(): void {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.FLAGGED_ACCOUNT)} Primary CTA`,
    });
  }

  showReasoningModal() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.FLAGGED_ACCOUNT)} Secondary CTA`,
    });

    this.$bvModal.show("ban-reasoning");
  }

  submit() {
    this.$emit("complete-step");
  }
}
</script>

<style lang="scss" scoped>
::v-deep .form {
  width: 21.75rem;
}

.flagged-account {
  text-align: center;
  opacity: 0;
  animation: show-step $duration-default;
  animation-timing-function: $ease-out-expo;
  animation-fill-mode: forwards;

  > h1 {
    all: unset;
    margin-bottom: 15px;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    color: $gray-800;
    line-height: 1.4;
  }

  .notice {
    margin: 20px 0;
    color: $gray-600;
  }

  button {
    width: 100%;
    margin: 20px 0 15px !important;
  }
}
</style>
