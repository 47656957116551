<template>
  <div>
    <div v-if="pageState === 'success'" class="account-form">
      <h1 class="title">
        <img
          src="/assets/images/home/delinquent-page/success-checkmark.png"
          alt="Checkmark"
          width="30"
        />
        Settlement Submitted
      </h1>
      <p class="subtitle">
        Your settlement was submitted successfully, and the payment will be
        processed shortly. If you have any questions or concerns, reach out to
        <a href="mailto:support@privacy.com?subject=Settlement%20Submitted"
          >support@privacy.com</a
        >.
      </p>
    </div>
    <b-form v-else @submit.prevent="onPressSubmit" class="account-form">
      <h1 class="title">Payment Settlement</h1>
      <p class="subtitle">
        Please enter your case number and provide payment information below to
        settle your balance.
      </p>
      <BaseInput
        class="account-field"
        name="caseNumber"
        label="Case Number"
        placeholder="Your Repayment Case Number"
        v-model="Repayment.caseNumber"
        format="numeric"
        maxlength="6"
      />
      <BaseInput
        class="account-field"
        name="amount"
        label="Settlement Amount (USD)"
        placeholder="0.00"
        v-model="Repayment.amount"
        format="currency"
      />
      <div v-if="Repayment.method === 'bank'" class="field-group -fade-in">
        <BaseInput
          class="account-field"
          name="bankAccountNumber"
          label="Bank Account Number"
          v-model="Repayment.bankAccountNumber"
          maxlength="24"
        />
        <BaseInput
          class="account-field"
          name="routingNumber"
          label="Bank Routing Number"
          v-model="Repayment.routingNumber"
          format="numeric"
          maxlength="24"
        />
      </div>
      <div v-else class="field-group">
        <BaseInput
          class="account-field"
          name="ccNumber"
          label="Card Number"
          format="pan"
          placeholder="0000 0000 0000 0000"
          v-model="Repayment.ccNumber"
          maxlength="19"
        />
        <BaseInputGroup class="input-group">
          <BaseInput
            class="account-field"
            name="expDate"
            label="Card Expiration"
            placeholder="MM/YY"
            v-model="Repayment.expDate"
            format="date"
            maxlength="5"
          />
          <BaseInput
            class="account-field"
            name="cvv"
            label="Card CVV"
            placeholder="000"
            v-model="Repayment.cvv"
            format="numeric"
            maxlength="4"
          />
        </BaseInputGroup>
      </div>
      <div class="footer">
        <BaseButton
          type="submit"
          class="submit-button"
          :loading="loadingSubmit"
          variant="primary"
          >Process Payment</BaseButton
        >
      </div>
    </b-form>
  </div>
</template>
<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { Toast } from "@/mixins/Toast";
import { Repayment, RepaymentMethod } from "@/types/Repayment";
import { repaymentStore } from "@/store";

@Component({})
export default class RepaymentPage extends Mixins(Toast) {
  MAX_EXP_LENGTH = 5;
  pageState = "default";
  loadingSubmit = false;
  Repayment: Partial<Repayment> = {
    method: RepaymentMethod.CARD,
  };

  onPressSubmit() {
    if (this.loadingSubmit) {
      return;
    }

    if (
      !this.Repayment.caseNumber ||
      !this.Repayment.amount ||
      !this.Repayment.method
    ) {
      return this.errorToast("Provide case number, amount and method.");
    }

    if (this.Repayment.method === "card") {
      if (!this.Repayment.ccNumber) {
        // if invalid credit card number as detected by format
        return this.errorToast("Provide valid credit card number.");
      }
      if (
        !this.Repayment.expDate ||
        this.Repayment.expDate.length !== this.MAX_EXP_LENGTH
      ) {
        return this.errorToast("Provide valid card expiration date.");
      }
    }

    this.loadingSubmit = true;

    this.Repayment.expDate = this.Repayment.expDate?.replace("/", "");
    this.Repayment.ccNumber = this.Repayment.ccNumber?.split(" ").join("");

    repaymentStore.actions
      .settle(this.Repayment)
      .then(() => {
        this.loadingSubmit = false;
        this.pageState = "success";
      })
      .catch(() => {
        this.loadingSubmit = false;
        this.errorToast("Error submitting. Please try again later.");
      });
  }
}
</script>
<style lang="scss" scoped>
.account-form {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  padding: 40px 20px;
  min-width: 320px;

  > .title {
    margin-bottom: 20px;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    line-height: 50px;
    cursor: default;
  }

  > .subtitle {
    margin-bottom: 30px;
    color: $gray-600;
    cursor: default;
  }

  .input-group {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    flex-wrap: nowrap;

    &:last-child {
      margin-bottom: 0;
    }

    @media #{$media-phone} {
      flex-direction: column;
    }
  }

  .field-group {
    &.-fade-in {
      opacity: 0;
      animation: $animation-fade-in;
      animation-delay: $duration-short;
    }
  }

  .input-group > .account-field {
    @media #{$media-desktop} {
      margin-bottom: 0;
    }
  }

  .input-group > .account-field + .account-field {
    @media #{$media-desktop} {
      margin-left: 20px;
    }
  }

  > .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
  }

  .submit-button {
    display: inline-block;
  }
}

.account-field {
  margin-bottom: 20px;
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px 20px 50px;
  overflow: hidden;
  background-color: $gray-100;
  border-radius: $radius-large;
  transition: all $duration-shorter;

  ::v-deep input {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 40px 20px 15px;
    width: 100%;
    min-width: 0;
    background: none;
    border: 0;
    font-size: 14px;
  }

  ::v-deep .title {
    padding: 5px 0 0;
    font-size: 12px;
    font-weight: bold;
    line-height: 10px;
    text-transform: capitalize;
    pointer-events: none;
    user-select: none;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.submit-button {
  padding: 15px 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $gray-800;
  fill: $white;
  border-radius: 100px;
  outline: 0;
  font-family: lato, "helvetica neue", helvetica, arial;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: $white;
  transition-property: opacity, color, filter;
  transition-duration: 125ms;

  &:hover {
    opacity: 0.75;
  }
}
</style>
