<template>
  <SimplePage :fullpage="fullpage">
    <div class="remediation-failure" data-test="remediation-failure">
      <div class="wrapper">
        <h1>Pending Review</h1>
        <div>
          <p>
            Unfortunately we weren't able to verify your identity based on the
            info you provided. We'll need a bit more time to have a member of
            our team review your account.
          </p>
          <p>
            In the meantime, your ability to create cards and process
            transactions will not be impacted.
          </p>
          <p>We'll be in touch soon!</p>
        </div>
        <BaseButton
          variant="primary"
          size="lg"
          @click="onCompleteStep"
          data-test="close"
        >
          Got it
        </BaseButton>
      </div>
    </div>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component
export default class RemediationFailure extends Vue {
  @Prop({ default: false }) fullpage!: boolean;

  onCompleteStep() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.REMEDIATION_FAILURE)} Primary CTA`,
    });

    this.$emit("complete-step", OnboardingSteps.COMPLETE);
  }
}
</script>

<style lang="scss">
.remediation-failure {
  h1 {
    all: unset;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    color: $gray-800;
  }

  button {
    width: 100%;
  }

  .wrapper {
    max-width: 300px;
    margin: 0 auto;
    padding: 0px;
    display: grid;
    gap: 20px;
    text-align: center;
  }
}
</style>
