<template>
  <div
    class="helper -pending-review"
    data-test="helper-pending-business-review"
  >
    <div class="title">We are reviewing your information</div>
    <div class="body">
      <p>
        Thank you for submitting your information! Our team is working on
        reviewing this information and will be in touch shortly.
      </p>
    </div>
    <img
      class="bg-image"
      src="/assets/images/components/dashboard-helpers/pending-business-review-helper.svg"
      alt=""
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class HelperPendingBusinessReview extends Vue {}
</script>

<style lang="scss" scoped>
.helper.-pending-review {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  background-color: #eceef0;

  > .title {
    font-family: $font-stack-wes-fy;
    font-size: 18px;
    color: $gray-800;
    max-width: 180px;
    line-height: 19px;
  }

  > .body {
    position: absolute;
    top: 80px;
    left: 30px;
    right: 130px;
    font-family: $font-stack-lato;
    font-size: 14px;
    font-weight: 400;
    color: $gray-600;
  }

  > .bg-image {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }
}
</style>
