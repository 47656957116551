<template>
  <div class="account-section -spend-limits" id="spend-limits">
    <div class="section-heading">
      <h2>Account Level Spend Limits</h2>
    </div>
    <div class="section">
      <div class="limits" data-test="account-spend-limits">
        <div class="limit">
          <div class="title">
            {{
              user.isLimitWeekly ? "Weekly Spend Limit" : "Daily Spend Limit"
            }}
          </div>
          <DollarAmount
            :amount="parseInt(user.dailySpendLimit) * 100"
          ></DollarAmount>
        </div>
        <div class="limit">
          <div class="title">Monthly Spend Limit</div>
          <DollarAmount
            :amount="parseInt(user.monthlySpendLimit) * 100"
          ></DollarAmount>
        </div>
      </div>
      <div class="description">
        <p>
          All Privacy accounts have spending limits for user security. They're
          calculated on a rolling 24 hour and 30 day period, and can be
          increased by request.
          <a
            href="https://support.privacy.com/hc/en-us/articles/360011566794-How-do-spending-limits-work-"
            target="_blank"
            rel="noopener nofollow"
            >Learn more about account level spend limits</a
          >.
        </p>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { User } from "@/types/User";
import DollarAmount from "@/components/DollarAmount.vue";

@Component({
  components: { DollarAmount },
})
export default class AccountSpendLimits extends Vue {
  @Prop({ required: true }) user!: User;
}
</script>
<style lang="scss" scoped>
.limits {
  display: flex;
  width: 100%;
  padding-bottom: 30px;
  justify-content: space-evenly;
}

.limits > .limit {
  margin-right: auto;
  color: $gray-800;
  padding-right: 20px;
}

.limit > .title {
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 6px;
}

.limit > .dollar-amount {
  justify-content: flex-start;
  font-size: 16px;
}

.description > p {
  margin-bottom: 0;
}
</style>
