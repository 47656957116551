<template>
  <KybVerificationContainer>
    <BaseForm class="business-owner pt-0 mt-0">
      <h3 class="heading mb-3">Please confirm who owns this business</h3>
      <p class="blurb mb-4">
        Which individuals own 25% or more of this business?
      </p>
      <BaseButton
        variant="light"
        type="submit"
        class="mb-2"
        @click="submit(OwnershipType.PRIMARY_USER_OWNS)"
      >
        Myself and other individuals
      </BaseButton>
      <BaseButton
        variant="light"
        type="submit"
        class="mb-2"
        @click="submit(OwnershipType.OTHER_PERSON_OWNS)"
      >
        Other individuals
      </BaseButton>
      <BaseButton
        variant="light"
        type="submit"
        class="mb-2"
        @click="submit(OwnershipType.NO_ONE_OWNS)"
      >
        No individual owns 25% or more of the business
      </BaseButton>
    </BaseForm>
  </KybVerificationContainer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { organizationStore, userStore } from "@/store";
import KybVerificationContainer from "@/views/kyb-verification/KybVerificationContainer.vue";
import { OwnershipType } from "@/types/Organization";

@Component({
  components: {
    KybVerificationContainer,
  },
})
export default class ConfirmBusinessOwners extends Vue {
  get kybUser() {
    return userStore.getters.currentUser!.kybVerificationUser;
  }

  get piwikPrefix() {
    return this.kybUser ? "Verification" : "Onboarding";
  }

  nextStep(ownershipType: OwnershipType) {
    if (
      ownershipType === OwnershipType.PRIMARY_USER_OWNS ||
      ownershipType === OwnershipType.OTHER_PERSON_OWNS
    ) {
      this.$router.push({
        name: "kybBusinessOwners",
        query: {
          ownership:
            ownershipType === OwnershipType.PRIMARY_USER_OWNS
              ? "primary"
              : "other",
        },
      });
    } else if (ownershipType === OwnershipType.NO_ONE_OWNS) {
      this.$router.push({ name: "kybBusinessUsecase" });
    }
  }

  async created() {
    await userStore.actions.getCurrentUser();
    this.$piwik.trackEvent("Modal", "View", {
      name: `${this.piwikPrefix} Ownership Structure`,
    });

    const currentStep = "kybConfirmBusinessOwners";
    if (this.kybUser) {
      userStore.actions.updateVerificationStep(currentStep);
    } else {
      userStore.actions.updateUser({ kybCurrentStep: currentStep });
    }
  }

  OwnershipType = OwnershipType;

  async submit(ownershipType: OwnershipType) {
    this.trackPiwikEvents(ownershipType);
    // Other ownership types require owners that will be handled in the next step
    if (ownershipType === OwnershipType.NO_ONE_OWNS) {
      await organizationStore.actions.execs({
        ownershipType,
        owners: [],
      });
    }
    this.nextStep(ownershipType);
  }

  trackPiwikEvents(ownershipType: OwnershipType) {
    if (ownershipType === OwnershipType.PRIMARY_USER_OWNS) {
      this.$piwik.trackClick({
        name: `${this.piwikPrefix} Ownership Structure Myself and others CTA`,
      });
    } else if (ownershipType === OwnershipType.OTHER_PERSON_OWNS) {
      this.$piwik.trackClick({
        name: `${this.piwikPrefix} Ownership Structure Others CTA`,
      });
    } else if (ownershipType === OwnershipType.NO_ONE_OWNS) {
      this.$piwik.trackClick({
        name: `${this.piwikPrefix} Ownership Structure No one CTA`,
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  width: 320px !important;
}
.business-owner {
  .blurb {
    margin-bottom: 1rem;
    color: $gray-800;
  }
}
</style>
