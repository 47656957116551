<template>
  <div class="field" :class="{ disabled }">
    <div class="title" v-if="label" :class="{ disabled }">{{ label }}</div>
    <div class="file-list">
      <div
        v-for="(file, index) in value"
        :key="`file-${index}-${file.name}`"
        class="file"
      >
        <div class="text-truncate">{{ file.name }}</div>
      </div>
    </div>
    <div class="delete-list" v-if="value.length">
      <div
        class="delete"
        v-for="(file, index) in value"
        :key="`delete-${index}-${file.name}`"
      >
        <SVGIcon icon="trash" @click="deleteFile($event, index)" />
      </div>
    </div>
    <b-form-file
      v-bind="$attrs"
      ref="form-file-input"
      :value="value"
      @input="handleInput"
      :disabled="disabled"
      multiple
      :capture="capture"
    >
      <template slot="placeholder">
        <div v-if="value.length" class="-has-content" />
        <div v-else :class="loading ? '-placeholder -loading' : '-placeholder'">
          <span>{{ description }}</span>
          <b-spinner class="loading-spinner" v-if="loading" />
        </div>
      </template>
      <div
        slot="drop-placeholder"
        slot-scope="{ allowed }"
        class="-placeholder"
        :class="//@ts-ignore
        { '-drop': allowed }"
      >
        <span>{{ description }}</span>
      </div>
      <!-- File list is handled outside the b-form-file component because of issues with updating on deletion -->
      <div slot="file-name">&nbsp;</div>
    </b-form-file>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import SVGIcon from "./SVGIcon.vue";

@Component({
  inheritAttrs: false,
  components: {
    SVGIcon,
  },
})
export default class BaseFileUpload extends Vue {
  @Prop({ default: "" }) label!: string;
  @Prop({ default: "" }) description!: string;
  @Prop({ default: [] }) value!: File[];
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: 1 }) maxfiles!: number;
  @Prop({ default: "" }) image!: string;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: false }) capture!: boolean;

  handleInput(value: File[]) {
    const result = this.value.concat(value);
    this.$emit("input", result.slice(0, this.maxfiles));
  }

  deleteFile(event: Event, index: number) {
    const result = this.value.concat([]);
    result.splice(index, 1);
    this.$emit("input", result);
  }
}
</script>

<style lang="scss" scoped>
.field {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 12.5rem;
  cursor: text;

  &:hover:not(.disabled) {
    .file {
      padding-right: 1.25rem;
    }

    .delete-list {
      display: block;
    }
  }

  .title {
    padding: 1.25rem 0.25rem 0.25rem;
    margin: 1px 3.5rem 0 1rem;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 0.8;
    pointer-events: none;
    z-index: 2;

    &.disabled {
      opacity: 0.5;
    }
  }

  .image {
    width: 260px;
    img {
      width: 260px;
    }
  }

  .file-list {
    z-index: 1;
    padding: 0 1.25rem 1rem;
    pointer-events: none;

    .file {
      position: relative;
      margin-top: 0.5rem;
      padding-left: 1.25rem;
      background-image: url("/assets/images/icons/file-12.svg");
      background-repeat: no-repeat;
      background-position: left center;
    }
  }

  .delete-list {
    display: none;
    position: absolute;
    z-index: 2;
    top: 2rem;
    right: 1.25rem;

    .delete {
      margin-top: 0.5rem;
      color: $gray-600;
      cursor: pointer;

      &:hover {
        color: $gray-900;
      }
    }
  }

  .has-content {
    display: flex;
    flex-direction: column;
    background-color: $gray-100;
  }

  .-placeholder {
    color: $gray-400;
    background-image: url("/assets/images/onboarding/drop-file-mouse-grey-light.svg");
    background-repeat: no-repeat;
    background-position: bottom 1rem center;
    display: flex;
    flex-direction: column;

    &.-loading {
      background-image: none;
    }

    .loading-spinner {
      margin: 10px;
      align-self: center;
    }
  }

  .-drop {
    background-color: $gray-100;
    background-image: url("/assets/images/onboarding/drop-file-mouse-blue.svg");
    box-shadow: inset 0 0 0 2px $blue;
    border-radius: $border-radius;
    z-index: 2;

    span {
      opacity: 0.2;
    }
  }

  ::v-deep .custom-file,
  ::v-deep .custom-file-label,
  ::v-deep .form-file-text,
  .-placeholder {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 2.5rem 1.25rem 1rem;
  }

  ::v-deep .custom-file-label {
    white-space: normal;
    margin-bottom: 0;

    &::after {
      display: none;
    }
  }

  &:not(.disabled) ::v-deep .custom-file-label {
    cursor: pointer;
    &:hover {
      background-color: $gray-200;
      border-color: $gray-200;
    }
  }
}
</style>
