<template>
  <div class="helper -charge-terms" data-test="charge-terms-helper">
    <div class="title">We've upgraded Privacy Cards!</div>
    <div class="body">
      <p>
        As of 1/31/22 all pre-existing Visa cards were closed. Please review and
        accept our new Terms to create new cards and continue using Privacy.
      </p>
    </div>
    <div class="controls">
      <BaseButton
        variant="light"
        shape="round"
        class="action"
        @click="onClickCTA"
        width="narrow"
        >Get Started</BaseButton
      >
    </div>
    <div class="card">
      <PrivacyLogo :light="true"></PrivacyLogo>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import PrivacyLogo from "../PrivacyLogo.vue";

@Component({
  components: { PrivacyLogo },
})
export default class HelperChargeTerms extends Vue {
  onClickCTA() {
    this.$emit("start-onboarding");
  }
}
</script>
<style lang="scss" scoped>
.helper.-charge-terms {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  background: linear-gradient(
      96.5deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    $color-green;

  > .title {
    font-family: $font-stack-wes-fy;
    font-size: 18px;
    color: $white;
  }

  > .body {
    position: absolute;
    top: 60px;
    max-width: 300px;
    font-family: $font-stack-lato;
    font-size: 14px;
    font-weight: bold;
    color: $white;
  }

  > .controls {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    height: 50px;
    width: 100%;

    > .action {
      background-color: rgba(32, 45, 74, 0.2);
      color: $white;
    }
  }

  > .card {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 80px;
    width: 160px;
    background-color: $color-foreground;
    border-radius: 0 40px 0 0;

    a {
      position: absolute;
      top: 26px;
      right: 32px;
    }
  }
}
</style>
