<template>
  <SimplePage :fullpage="fullpage">
    <div class="header">
      <div class="card">
        <PrivacyLogo :light="true" />
      </div>
    </div>
    <BaseForm class="charge-terms-reminder" data-test="charge-terms-reminder">
      <div class="body">
        <div class="title">We've Upgraded Privacy Cards</div>
        <div class="description">
          <p>
            Welcome back! We recently upgraded Privacy Cards requiring all users
            to review and accept new Terms and to reissue any pre-existing Visa
            Privacy Cards. All open cards that were not reissued by 1/31/22 were
            closed. Please review and accept before creating a new card.
          </p>
          <p>
            The new Privacy Cards will continue to operate exactly like the
            cards you're used to -
            <b
              >no interest, no selling of your data, and no impact to your
              credit score.</b
            >
          </p>
          <p>
            To continue using Privacy please review and accept the new Terms and
            follow the prompts to reissue any closed cards. Visit our
            <b-link
              href="https://support.privacy.com/hc/en-us/categories/4414487825559-Privacy-Cards-Update-12-2021"
              target="_blank"
              >FAQs</b-link
            >
            to learn more.
          </p>
        </div>
        <BaseButton
          class="got-it"
          size="lg"
          variant="primary"
          data-test="got-it"
          v-if="reissueHelper"
          @click="onClickLater"
        >
          Got it
        </BaseButton>
        <template v-if="!reissueHelper">
          <BaseButton
            class="get-started"
            size="lg"
            variant="primary"
            data-test="get-started"
            @click="onClickGetStarted"
          >
            Get Started
          </BaseButton>
          <b-link class="later" data-test="later" @click="onClickLater">
            I'll do this later
          </b-link>
        </template>
      </div>
    </BaseForm>
  </SimplePage>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import PrivacyLogo from "@/components/PrivacyLogo.vue";
import { EVENTS } from "@/types/Event";
import { eventStore } from "@/store";

@Component({
  components: {
    PrivacyLogo,
  },
})
export default class ChargeTermsReminder extends Vue {
  @Prop({ default: false }) fullpage?: boolean;
  @Prop({ default: false }) reissueHelper!: boolean;

  onClickGetStarted() {
    eventStore.actions.record({
      name: EVENTS.CTA.CLICKED,
      data: {
        buttonContext: "Charge Terms Reminder",
        buttonName: "Get Started",
      },
    });
    this.$bvModal.hide("charge-terms-reminder");
    this.$emit("start-onboarding");
  }

  onClickLater() {
    eventStore.actions.record({
      name: EVENTS.CTA.CLICKED,
      data: {
        buttonContext: "Charge Terms Reminder",
        buttonName: "Later",
      },
    });
    this.$bvModal.hide("charge-terms-reminder");
  }
}
</script>
<style lang="scss" scoped>
.header {
  position: absolute;
  height: 140px;
  width: 100%;
  top: 0px;
  margin-top: -35px;
  border-radius: 1rem 1rem 0 0;
  left: 0px;
  background: linear-gradient(
      96.5deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    $color-green;

  > .card {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 105px;
    width: 274px;
    background-color: $color-foreground;
    border-radius: 0 40px 0 0;

    > .logo {
      position: absolute;
      top: 26px;
      right: 29px;
    }
  }
}

.charge-terms-reminder {
  padding: 140px 0 30px 0;

  > .body {
    > .title {
      font-family: $font-stack-wes-fy;
      font-size: 20px;
    }

    > .description {
      margin-top: 30px;
      margin-bottom: 30px;
      font-family: $font-stack-lato;
      font-size: 14px;
    }

    > .description > p {
      margin-top: 10px;
    }

    > .get-started,
    > .got-it {
      width: 100%;
      font-size: 14px;
    }

    > .later {
      display: block;
      width: 100%;
      text-align: center;
      margin-top: 15px;
      font-weight: bold;
      color: $blue;
    }

    @media #{$media-phone} {
      padding: 30px 30px 15px;
    }
  }
}
</style>
