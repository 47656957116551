import { LDFlagSet } from "@launchdarkly/node-server-sdk";
import { MerchantCategory } from "./MerchantCategory";

export interface CardStyle {
  bgColor?: string | null;
  icon?: string;
  filename?: string;
  bgImage?: string;
  lastModified?: string;
  hostname?: string;
}

export interface Recipient {
  email: string;
  reshareState?: "success" | "loading" | null;
}

export interface CardMeta {
  hostname?: string;
  platform?: string;
  customStyle?: {
    bgColor?: string | null;
    icon?: string;
  } | null;
  notes?: string;
  tagList?: string[];
  recipients?: Recipient[];
  reissuable?: boolean;
  reissuedFrom?: string;
  reissuedTo?: string;
  promoCardExpirationMilliseconds?: string;
  isExpiring?: boolean;
  billing?: {
    link: string;
    linkClicked: boolean;
    updated: boolean;
  };
  generatedFirstCard?: boolean;
  vaulted?: boolean;
}

export enum CardState {
  OPEN = "OPEN",
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
  CLOSED = "CLOSED",
}

export enum CardSortField {
  CREATED = "CREATED",
  MEMO = "MEMO",
  RECENTLY_USED = "RECENTLY_USED",
  TOTAL_SPEND = "TOTAL_SPEND",
}

export enum CardSortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum CardType {
  MERCHANT_LOCKED = "MERCHANT_LOCKED",
  SINGLE_USE = "SINGLE_USE",
  UNLOCKED = "UNLOCKED",
  DIGITAL_WALLET = "DIGITAL_WALLET",
  PHYSICAL = "PHYSICAL",
}

export enum CardTime {
  ANNUALLY = "ANNUALLY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
  TRANSACTION = "TRANSACTION",
  FOREVER = "FOREVER",
}

export interface SampleCard {
  memo?: string;
  type?: CardType.MERCHANT_LOCKED;
  reloadable?: boolean;
  hostname?: string;
  meta?: {
    hostname?: string;
  };
  spendLimit?: number;
  spendLimitDuration?: string;
}

export interface Card {
  bankAccountID: string;
  billingLink?: string;
  bin: string;
  cardGraphListCalculatedValue?: number;
  cardID: number;
  cardProgram: string;
  cardUuid: string;
  created: string;
  createdIP: string;
  CVV: string;
  expMonth: string;
  expYear: string;
  formattedPromoCredit?: any;
  fundingAccount: string;
  fundingBank: string;
  hostname: string;
  isUntitled?: boolean;
  lastFour: string;
  mcc: string;
  mccIconUrl?: string;
  memo: string;
  merchantCategory?: MerchantCategory | string;
  meta: CardMeta | null;
  name?: string;
  network: string;
  note?: string;
  owner: string;
  PAN: string;
  panWithSpaces?: string;
  promoCredit?: any;
  reloadable?: boolean;
  sharedWithRecipients?: {
    _id: string;
    recipientId: {
      _id: string;
      email: string;
    };
    shareTheme: "DEFAULT" | "PRIVACY" | "CONFETTI";
    shareTime: string; // ISO date string
  }[];
  spendLimit: number | string;
  spendLimitDuration: CardTime;
  spentThisMonthRolling?: number;
  spentThisYearRolling?: number;
  spentTotal?: number;
  state: CardState;
  style?: CardStyle | null;
  type: CardType;
  unused: boolean;
  uuid?: string;
}

export type CardWithTags = Card & { tags?: string };

// See server/modules/cards/cards.constants.ts#L20 for the list of fields
export interface RecipientCard
  extends Pick<
    Card,
    | "cardUuid"
    | "CVV"
    | "expMonth"
    | "expYear"
    | "hostname"
    | "lastFour"
    | "memo"
    | "merchantCategory"
    | "network"
    | "note"
    | "owner"
    | "PAN"
    | "spendLimit"
    | "spendLimitDuration"
    | "state"
    | "style"
    | "type"
  > {
  lockedMerchant?: string;
  shareTime: string;
  theme: {
    name: "default" | "privacy" | "confetti";
    type: "DEFAULT" | "PRIVACY" | "CONFETTI";
    backgroundImage?: string;
    previewImage?: string;
  };
}

export interface CardList {
  all: Card[];
  open: Card[];
  closed: Card[];
  totalCards?: number;
}

export interface ChargeReissue {
  expiring: Card[];
  reissued: Record<string, Card>;
  closed: Card[];
}

export type NewCardConfig = {
  flags?: LDFlagSet;
};

export type CardsPageState = {
  currentVisibleCards: Card[];
  totalCards: number;
};
