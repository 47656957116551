<template>
  <label class="field" :class="[variant, { 'label-hidden': hideLabel }]">
    <div class="title" :class="{ disabled, 'sr-only': hideLabel }">
      {{ label }}
      <span class="secondary-label" v-if="secondaryLabel"
        >({{ secondaryLabel }})</span
      >
    </div>
    <b-form-input
      :class="{ 'input-seen': inputSeen }"
      v-bind="$attrs"
      v-on="$listeners"
      :value="value"
      @focus="handleFocus"
      @input="handleInput"
      :formatter="formatter"
      :disabled="disabled"
    ></b-form-input>
    <slot></slot>
  </label>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { card } from "creditcards";
import { formatInputDate } from "@/lib/dates";
import { Format } from "../types/Form";
import {
  formatNumOnly,
  formatPhone,
  formatSSN,
  formatMicroauth,
  formatCurrency,
} from "../util";

@Component({
  inheritAttrs: false,
})
export default class BaseInput extends Vue {
  @Prop({ default: "" }) label!: string;
  @Prop({ default: "" }) secondaryLabel!: string;
  @Prop() value?: string;
  @Prop() format?: Format;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: "" }) variant!: "dark" | "";
  @Prop({ default: false }) hideLabel!: boolean;

  inputSeen = false;

  handleInput(value: string) {
    this.$emit("input", value);
  }

  handleFocus(event: Event) {
    this.inputSeen = true;

    this.$emit("focus", event);
  }

  formatter(value: string) {
    switch (this.format) {
      case Format.PHONE:
        return formatPhone(value);
      case Format.SSN:
        return formatSSN(value);
      case Format.MICROAUTH:
        return formatMicroauth(value);
      case Format.DATE:
        return formatInputDate(value);
      case Format.NUMERIC:
        return formatNumOnly(value);
      case Format.CURRENCY:
        return formatCurrency(value);
      case Format.PAN:
        return card.format(card.parse(value));
      default:
        return value;
    }
  }
}
</script>

<style lang="scss" scoped>
.field {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.25rem 3rem;
  cursor: text;

  .title {
    padding: 0.25rem 0 0;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 0.8;
    pointer-events: none;
    z-index: 1;

    @media #{$media-phone} {
      line-height: 1;
    }

    &.disabled {
      opacity: 0.5;
    }

    &.sr-only {
      padding: 0;
    }

    > .secondary-label {
      color: $gray-400;
    }
  }

  &.label-hidden {
    padding-bottom: 2.3125rem;

    ::v-deep input {
      padding-top: inherit;
    }
  }

  ::v-deep input {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 2.5rem;

    &:enabled:hover {
      background-color: $gray-200;
      border-color: $gray-200;
    }
  }
}

.dark {
  ::v-deep input {
    color: #f0f0f5;
    background-color: $foreground-default;
    border-color: $foreground-default;
    transition:
      background-color 0.3s,
      border-color 0.3s,
      color 0.3s;

    &::placeholder {
      color: #b8b8cc;
    }

    // Need everything in `:enabled` to make these more specific and
    // have them override the default styles
    &:enabled {
      &:hover {
        background-color: $accent-10;
        border-color: $foreground-default;
      }

      &:focus {
        background-color: $accent-10;
        border-color: $accent-default;
      }

      &:invalid:not(:focus).input-seen {
        background-color: $danger-10;
        border-color: $danger-default;
      }
    }
  }
}
</style>
