<template>
  <BaseModal id="ban-reasoning">
    <div class="content">
      <p>
        {{
          user &&
          `${user.firstName} ${user.lastName} - ${formatAddress(
            user.address1,
            user.address2,
            user.zipcode,
            user.city,
            user.state
          )}`
        }}
      </p>
      <p>
        Your request for credit was carefully considered, and we regret that we
        are unable to approve your application at this time for the following
        reason(s):
      </p>
      <ol>
        <li>We were unable to confirm your identity.</li>
        <li>We were unable to verify your bank account.</li>
      </ol>
      <p>
        Our credit decision was based in whole or in part on information
        obtained from an affiliate or from an outside source other than a
        consumer reporting agency. This decision was made by Patriot Bank, N.A.,
        located at 900 Bedford Street, Stamford, CT 06901.
      </p>
      <p>
        Notice: The Federal Equal Credit Opportunity Act prohibits creditors
        from discriminating against credit applicants on the basis of race,
        color, religion, national origin, sex, marital status, age (provided the
        applicant has the capacity to enter into a binding contract); because
        all or part of the applicant’s income derives from any public assistance
        program; or because the applicant has in good faith exercised any right
        under the Consumer Credit Protection Act. The Federal agency that
        administers compliance with this law concerning this creditor is the
        Office of the Comptroller of the Currency, Customer Assistance Group,
        1301 McKinney Street, Suite 3450, Houston, TX 77010-9050.
      </p>
    </div>
    <BaseButton class="mt-4 w-100" variant="primary" size="lg" @click="close">
      Done Reading
    </BaseButton>
  </BaseModal>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { userStore } from "@/store";
import { formatAddress } from "@/util";

@Component
export default class BanReasoningModal extends Vue {
  formatAddress = formatAddress;

  get user() {
    return userStore.getters.currentUser;
  }

  close() {
    this.$bvModal.hide("ban-reasoning");
  }
}
</script>

<style lang="scss" scoped>
.content {
  white-space: pre-line;
  min-height: 300px;
  max-height: 50vh;
  overflow-y: scroll;
  padding: 24px;
  background: $gray-100;
  border-radius: $border-radius;
  color: $gray-900;
}
</style>
