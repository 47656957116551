<template>
  <div class="helper -hiring-engineers" data-test="hiring-engineers-helper">
    <div class="title">We're hiring engineers!</div>
    <div class="body">
      <p>Love Privacy? Help us make it even better.</p>
    </div>
    <div class="controls">
      <a href="https://boards.greenhouse.io/lithic" target="_blank">
        <BaseButton
          data-test="hiring-engineers-button"
          variant="primary"
          shape="round"
          class="action"
          width="narrow"
          >View Open Positions
        </BaseButton>
      </a>
    </div>
    <div class="bg-image">
      <img
        class="logo"
        src="/assets/images/components/dashboard-helpers/hiring-engineers-helper.svg"
        alt="We're Hiring Engineers"
        width="183"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import PrivacyLogo from "../PrivacyLogo.vue";

@Component({
  components: { PrivacyLogo },
})
export default class HelperHiringEngineers extends Vue {}
</script>
<style lang="scss" scoped>
.helper.-hiring-engineers {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  background: #e7eef6;

  > .title {
    font-family: $font-stack-wes-fy;
    font-size: 18px;
    color: $gray-800;
    max-width: 205px;
    line-height: 19px;
  }

  > .body {
    position: absolute;
    top: 60px;
    max-width: 250px;
    font-family: $font-stack-lato;
    font-size: 14px;
    font-weight: 400;
    color: $gray-600;
  }

  > .controls {
    display: flex;
    justify-content: flex-start;
    margin-top: auto;
    height: 50px;
    width: 100%;

    > .action {
      color: $white;
      font-family: $font-stack-wes-fy;
      font-weight: 900;
      font-size: 14px;
    }
  }

  > .bg-image {
    position: absolute;
    bottom: 0;
    right: -33px;
    height: 235px;
    width: 183px;
  }
}
</style>
