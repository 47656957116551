<template>
  <span class="base-badge" :class="variant">
    <slot />
  </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BaseBadge extends Vue {
  @Prop({ default: "info" }) variant?: string;
}
</script>

<style lang="scss" scoped>
.base-badge {
  transition: none;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.1px;
  padding: 4px;
  border-radius: 4px;
  text-transform: uppercase;

  &.success {
    color: $success-default;
    background-color: $success-10;
  }

  &.info {
    color: $accent-default;
    background-color: $accent-10;
  }

  &.danger {
    color: $danger-default;
    background-color: $danger-10;
  }
}
</style>
