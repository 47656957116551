<template>
  <BaseModal
    :id="modalId"
    size="md"
    hide-header
    modal-class="edit-spend-limit-modal"
  >
    <EditSpendLimitForm
      :card="card"
      :hideLimitSlider="hideLimitSlider"
      @update-card="emitUpdate"
      @cancel-update="closeModal"
      :isSampleCard="isSampleCard"
      :subscription="subscription"
    />
  </BaseModal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Card } from "@/types/Card";
import { Subscription } from "@/types/Subscription";
import EditSpendLimitForm from "./EditSpendLimitForm.vue";

@Component({
  components: {
    EditSpendLimitForm,
  },
})
export default class EditSpendLimitModal extends Vue {
  @Prop() card!: Card;
  @Prop({ default: false }) hideLimitSlider?: boolean;
  @Prop({ default: "edit-spend-limit" }) modalId!: string;
  @Prop() isSampleCard?: boolean;
  @Prop() subscription!: Subscription;

  closeModal() {
    this.$bvModal.hide(this.modalId);
  }

  emitUpdate(updatedCard: Card) {
    this.$emit("update-card", updatedCard);
    this.closeModal();
  }
}
</script>

<style lang="scss" scoped>
.edit-spend-limit-modal ::v-deep .modal-body {
  padding: 0 2.5rem 2.5rem;
}
</style>
