<template>
  <div class="helper -mobile">
    <div class="title">Get Privacy for iOS and Android</div>
    <div class="content">
      <div class="app-links">
        <a
          href="https://itunes.apple.com/us/app/privacy.com-forget-your-credit/id1040298266?mt=8"
          target="_blank"
          aria-label="get Privacy on the Apple store"
          ><SVGIcon icon="brand-apple" class="svg-icon app-link"></SVGIcon
        ></a>
        <a
          href="https://play.google.com/store/apps/details?id=com.privacy.pay"
          target="_blank"
          aria-label="get Privacy on the Google Play store"
          ><SVGIcon icon="brand-android" class="svg-icon app-link"></SVGIcon
        ></a>
      </div>
      <img
        src="/assets/images/components/dashboard-helpers/mobile-app.png"
        alt=""
        class="mobile-app-img"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import SVGIcon from "../SVGIcon.vue";

@Component({
  components: { SVGIcon },
})
export default class HelperMobile extends Vue {}
</script>
<style lang="scss" scoped>
.helper.-mobile {
  padding-bottom: 0;
  background: $gray-100;

  > .title {
    text-align: center;
    font-family: $font-stack-wes-fy;
    font-size: 20px;
  }

  > .content {
    display: flex;
    flex-grow: 1;
  }

  .app-links {
    display: flex;
    flex-basis: 40px;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    a {
      background-color: fade-out($color: $gray-900, $amount: 0.9);
      border-radius: 100%;
    }
  }

  .app-link {
    padding: 4px;
    height: 30px;
    width: 30px;
    opacity: 0.5;
    transition: opacity $duration-default;
    transition-timing-function: $ease-out-expo;

    &:hover {
      opacity: 1;
    }
  }

  .svg-icon {
    height: 30px;
    width: 30px;
    box-sizing: content-box;

    ::v-deep svg * {
      fill: $gray-800;
    }
  }

  .mobile-app-img {
    width: 250px;
    align-self: flex-end;
    margin-right: 5px;
  }
}
</style>
