<template>
  <div class="dollar-amount" data-test="dollar-amount">
    <span class="refund" v-if="transaction && parts.negative"> + </span>
    <span class="negative" v-if="!transaction && parts.negative"> - </span>
    <span class="amount">{{ parts.amount }}</span>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import numbro from "numbro";

@Component
export default class DollarAmount extends Vue {
  @Prop({
    required: true,
  })
  amount!: string | number;
  @Prop() transaction?: boolean;
  @Prop({ default: true }) showCents?: boolean;

  get parts() {
    const ret = { negative: false, amount: "" };
    let amount = String(this.amount).replace(/,/g, "");

    if (Number(amount) < 0) {
      amount = amount.replace("-", "");
      ret.negative = true;
    }

    if (amount.includes(".")) {
      amount = String(Number(amount) * 100);
    }

    const decimals = 2;
    const formattedAmount = numbro(
      (Number(amount) / 100 || 0).toFixed(decimals)
    );

    const format = this.showCents ? "0,0.00" : "0,0";
    ret.amount = formattedAmount.formatCurrency(format);

    return ret;
  }
}
</script>
<style lang="scss" scoped>
.dollar-amount {
  text-align: right;
  line-height: 100%;
}
</style>
