<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      class="commercial-charge-terms-reminder"
      data-test="commercial-charge-terms-reminder"
    >
      <div class="container" v-if="!success">
        <b-img
          class="hero"
          src="/assets/images/home/commercial-charge-terms/hero.svg"
          alt="card"
        ></b-img>
        <h2 class="title">
          {{
            user.chargeTermsAcceptTime
              ? "We're making a change to your Privacy Terms"
              : "We're making a change to our Privacy cards. Update your account now!"
          }}
        </h2>
        <div v-if="user.chargeTermsAcceptTime">
          <p>
            We're updating the terms of your Privacy Cardholder Agreement to
            better reflect your card usage. This update will only apply to
            future cards you create.
          </p>
          <p>
            These changes do not impact any of the privacy and security features
            you love. Your cards will also continue to operate with no interest,
            no data selling, and no impact to your credit score.
          </p>
          <p>To update your account, click "Accept New Terms" today.</p>
        </div>
        <div v-else>
          <p>
            To keep up with changes in network rules, we're updating our cards
            to charge cards. This update will only apply to future cards you
            create.
          </p>
          <p>
            These new Privacy Cards will expand merchant acceptance while
            maintaining the same features you love, with no interest, no data
            selling, and no impact to your credit score.
          </p>
        </div>
        <BaseButton
          size="lg"
          variant="primary"
          data-test="request-new-cards"
          @click="onClickRequestNewCards"
        >
          {{
            user.chargeTermsAcceptTime
              ? "Accept New Terms"
              : "Request New Cards"
          }}
        </BaseButton>
        <BaseButton
          class="see-full-terms"
          variant="transparent"
          href="https://privacy.com/commercial-cardholder-agreement"
          target="_blank"
        >
          See the full terms
        </BaseButton>
      </div>
      <div class="container" v-else>
        <b-img
          class="check-mark"
          src="/assets/images/home/commercial-charge-terms/check.svg"
          alt="green-check-mark"
        ></b-img>
        <h2 class="title" v-if="!user.chargeTermsAcceptTime">
          You're approved for updated Privacy cards
        </h2>
        <h2 class="title" v-else-if="user.chargeTermsAcceptTime">
          Your account has been updated
        </h2>
        <BaseButton
          class="got-it"
          v-if="success"
          size="lg"
          variant="primary"
          data-test="got-it"
          @click="onClickGotIt"
        >
          Got it
        </BaseButton>
      </div>
    </BaseForm>
  </SimplePage>
</template>
<script lang="ts">
import { userStore, eventStore } from "@/store";
import { EVENTS } from "@/types/Event";
import { User } from "@/types/User";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class CommercialChargeTermsReminder extends Vue {
  @Prop({ default: false }) fullpage?: boolean;
  @Prop({ required: true }) user!: User;
  success = false;

  onClickRequestNewCards(): void {
    eventStore.actions.record({
      name: EVENTS.CTA.CLICKED,
      data: {
        buttonContext: "Commercial Charge Terms Reminder",
        buttonName: "Get Started",
      },
    });
    userStore.actions.commercialChargeCardAgree().then(() => {
      this.success = true;
    });
  }

  onClickGotIt(): void {
    this.$bvModal.hide("commercial-charge-terms-reminder");
  }
}
</script>
<style lang="scss" scoped>
.commercial-charge-terms-reminder {
  text-align: center;
  padding: 2.5rem 0 0 0;
  width: 100%;
  margin: 0;

  > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .hero {
    width: 100%;
  }

  .check-mark {
    width: 75px;
  }

  .title {
    font-family: $font-stack-wes-fy;
    margin: 1em 0;
  }

  p {
    opacity: 0.5;
  }

  .btn {
    margin-top: 1em;
    width: 100%;
  }

  .see-full-terms {
    opacity: 0.5;
  }
}
</style>
