import { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import TFASetup from "@/views/TFASetup.vue";
import ChangePassword from "@/views/ChangePassword.vue";
import ChangeEmail from "@/views/ChangeEmail.vue";
import SelectSubscriptionPlan from "@/views/subscriptions/SelectSubscriptionPlan.vue";
import LowBalance from "@/views/funding/LowBalance.vue";
import AddFundingLongTail from "@/views/funding/AddFundingLongTail.vue";
import OnboardingContainer from "@/views/OnboardingContainer.vue";
import { OnboardingSteps } from "@/types/Onboarding";
import CustomPlan from "@/views/signup/CustomPlan.vue";
import OnfidoUpload from "@/views/OnfidoUpload.vue";
import AcceptCommercialChargeTerms from "@/views/AcceptCommercialChargeTerms.vue";
import { AccountPurposes, User } from "@/types/User";
import OnboardingMobilePlaid from "@/views/OnboardingMobilePlaid.vue";
import { subscriptionStore, userStore } from "@/store";
import { Subscription, SubscriptionState } from "@/types/Subscription";
import paths from "./paths.json";

function getUser(): Promise<User> {
  const user = userStore.getters.currentUser;

  if (user) {
    return Promise.resolve(user);
  }

  return new Promise((resolve, reject) => {
    userStore.actions
      .getCurrentUser()
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .then(() => resolve(userStore.getters.currentUser!))
      .catch(reject);
  });
}

async function getSubscription(): Promise<Subscription> {
  const { subscription } = subscriptionStore.getters;

  if (subscription) {
    return subscription;
  }

  await subscriptionStore.actions.fetchSubscription();

  return subscriptionStore.getters.subscription!;
}

const routes: Array<RouteConfig> = [
  {
    // This route can be used to go to the "next" step in fullpage view,
    // when the specific next step is unknown
    path: paths.signupNext,
    name: `onboarding-${OnboardingSteps.COMPLETE}`,
    component: OnboardingContainer,
    meta: {
      title: "Complete Your Profile",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.COMPLETE,
    },
  },
  {
    path: paths.signupPurpose,
    name: `onboarding-${OnboardingSteps.PURPOSE}`,
    component: OnboardingContainer,
    meta: {
      title: "Account Purpose",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.PURPOSE,
    },
  },
  {
    path: paths.signupBusinessType,
    name: `onboarding-${OnboardingSteps.BUSINESS_TYPE}`,
    component: OnboardingContainer,
    meta: {
      title: "Your Business",

      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.BUSINESS_TYPE,
    },
    beforeEnter(_to: Route, _from: Route, next: NavigationGuardNext): void {
      // Only allow access to this route if user has not completed it yet,
      // or were manually flagged by support as needing biz info
      userStore.actions.getCurrentUser().then(() => {
        const { isPendingBusinessInfo, organization } =
          userStore.getters.currentUser || {};

        // route isPendingBusinessInfo users to KYB flow
        if (!organization?.typeComplete || isPendingBusinessInfo) {
          next({ name: "startKybVerification" });
        } else {
          next({ name: "home" });
        }
      });
    },
  },
  {
    path: paths.signupBusinessConfirmSoleProprietor,
    name: `onboarding-${OnboardingSteps.BUSINESS_CONFIRM_SOLE_PROPRIETOR}`,
    component: OnboardingContainer,
    meta: {
      title: "Sole Proprietorship",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.BUSINESS_CONFIRM_SOLE_PROPRIETOR,
    },
  },
  {
    path: paths.signupBusinessNotSoleProprietor,
    name: `onboarding-${OnboardingSteps.BUSINESS_NOT_SOLE_PROPRIETOR}`,
    component: OnboardingContainer,
    meta: {
      title: "Unsupported Business Type",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.BUSINESS_NOT_SOLE_PROPRIETOR,
    },
  },
  {
    path: paths.signupBusinessInfoSuccess,
    name: `onboarding-${OnboardingSteps.BUSINESS_INFO_SUCCESS}`,
    component: OnboardingContainer,
    meta: {
      title: "Thank You",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.BUSINESS_INFO_SUCCESS,
    },
  },
  {
    path: paths.signupInfo,
    alias: paths.signupIdentify,
    name: `onboarding-${OnboardingSteps.INFO}`,
    component: OnboardingContainer,
    meta: {
      title: "Account Info",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.INFO,
    },
  },
  {
    path: paths.signupBasicInfo,
    name: `onboarding-${OnboardingSteps.BASIC_INFO}`,
    component: OnboardingContainer,
    meta: {
      title: "Basic Info",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.BASIC_INFO,
    },
  },
  {
    path: paths.signupBillingAddress,
    name: `onboarding-${OnboardingSteps.BILLING_ADDRESS}`,
    component: OnboardingContainer,
    meta: {
      title: "Billing Address",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.BILLING_ADDRESS,
    },
  },
  {
    path: paths.signupPhone,
    name: `onboarding-${OnboardingSteps.PHONE}`,
    component: OnboardingContainer,
    meta: {
      title: "Add Your Phone",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.PHONE,
    },
  },
  {
    path: paths.signupConfirmPhone,
    name: `onboarding-${OnboardingSteps.PHONE_CONFIRM}`,
    component: OnboardingContainer,
    meta: {
      title: "Confirm Your Phone",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.PHONE_CONFIRM,
    },
  },
  {
    path: paths.signupVerify,
    name: "id-verify",
    component: OnfidoUpload,
    meta: {
      title: "Verify your ID",
      authenticate: true,
    },
  },
  {
    path: paths.changePassword,
    name: "change-password",
    component: ChangePassword,
    meta: {
      title: "Change your password",
      authenticate: true,
    },
    props: {
      fullpage: true,
    },
  },
  {
    path: paths.changeEmail,
    name: "change-email",
    component: ChangeEmail,
    meta: {
      title: "Change your email",
      authenticate: true,
    },
    props: {
      fullpage: true,
    },
  },
  {
    path: paths.tfaSetup,
    name: "tfa-setup",
    component: TFASetup,
    meta: {
      title: "Enable Two-Factor Auth",
      authenticate: true,
    },
    props: {
      fullpage: true,
    },
  },
  {
    path: paths.changeWebhook,
    redirect: "/account",
    meta: {
      authenticate: true,
    },
  },
  {
    path: paths.signupBusiness,
    name: `onboarding-${OnboardingSteps.DEPRECATED_BUSINESS_INFO}`,
    component: OnboardingContainer,
    meta: {
      title: "Your Business",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.DEPRECATED_BUSINESS_INFO,
    },
  },
  {
    path: paths.signupFundingType,
    name: `onboarding-${OnboardingSteps.FUNDING_TYPE}`,
    component: OnboardingContainer,
    meta: {
      title: "Choose Funding Type",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.FUNDING_TYPE,
    },
  },
  {
    path: paths.signupFundingBusiness,
    name: `onboarding-${OnboardingSteps.FUNDING_TYPE_BUSINESS}`,
    component: OnboardingContainer,
    meta: {
      title: "Connect Bank Account",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.FUNDING_TYPE_BUSINESS,
    },
  },
  {
    path: paths.signupAddCard,
    name: `onboarding-${OnboardingSteps.ADD_CARD}`,
    component: OnboardingContainer,
    meta: {
      title: "Add Your Card",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.ADD_CARD,
    },
  },
  {
    path: paths.signupRetryAddCard,
    name: `onboarding-${OnboardingSteps.ADD_CARD_REROUTED}`,
    component: OnboardingContainer,
    meta: {
      title: "Add Your Card",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.ADD_CARD_REROUTED,
    },
  },
  {
    path: paths.signupCardConfirmed,
    name: `onboarding-${OnboardingSteps.SUCCESS_CARD}`,
    component: OnboardingContainer,
    meta: {
      title: "Connect a Card",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.SUCCESS_CARD,
    },
  },
  {
    path: paths.signupCardConfirmedBatch,
    name: `onboarding-${OnboardingSteps.SUCCESS_CARD_BATCH}`,
    component: OnboardingContainer,
    meta: {
      title: "Connect a Card",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.SUCCESS_CARD_BATCH,
    },
  },
  {
    path: paths.signupConfirmCard,
    name: `onboarding-${OnboardingSteps.CONFIRM_CARD}`,
    component: OnboardingContainer,
    meta: {
      title: "Confirm Your Card",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.CONFIRM_CARD,
    },
  },
  {
    path: paths.signupLowBalance,
    name: "low-balance",
    component: LowBalance,
    meta: {
      title: "Zero or low balance detected.",
      authenticate: true,
    },
    props: {
      fullpage: true,
    },
  },
  {
    path: paths.signupDisclosure,
    name: `onboarding-${OnboardingSteps.CHARGE_DISCLOSURE}`,
    component: OnboardingContainer,
    meta: {
      title: "Almost done!",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.CHARGE_DISCLOSURE,
    },
  },
  {
    path: paths.signupBusinessAbout,
    name: `onboarding-${OnboardingSteps.DEPRECATED_BUSINESS_INFO_ABOUT}`,
    component: OnboardingContainer,
    meta: {
      title: "Your Business",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.DEPRECATED_BUSINESS_INFO_ABOUT,
    },
  },
  {
    path: paths.fundingLongtailType,
    name: "funding-longtail",
    component: AddFundingLongTail,
    meta: {
      title: "Add a new funding source",
    },
    props: {
      fullpage: true,
    },
  },
  {
    path: paths.signupBusinessDocs,
    name: `onboarding-${OnboardingSteps.DEPRECATED_BUSINESS_INFO_LEGAL}`,
    component: OnboardingContainer,
    meta: {
      title: "Your Business",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.DEPRECATED_BUSINESS_INFO_LEGAL,
    },
  },
  {
    path: paths.signupBusinessOwners,
    name: `onboarding-${OnboardingSteps.DEPRECATED_BUSINESS_OWNERS}`,
    component: OnboardingContainer,
    meta: {
      title: "Business Owners",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.DEPRECATED_BUSINESS_OWNERS,
    },
  },
  {
    path: paths.signupBusinessDetails,
    name: `onboarding-${OnboardingSteps.BUSINESS_DETAILS}`,
    component: OnboardingContainer,
    meta: {
      title: "Business Details",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.BUSINESS_DETAILS,
    },
  },
  {
    path: paths.signupBusinessExecs,
    name: `onboarding-${OnboardingSteps.BUSINESS_EXECS}`,
    component: OnboardingContainer,
    meta: {
      title: "Executives & Owners",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.BUSINESS_EXECS,
    },
  },
  {
    path: paths.signupBusinessUse,
    name: `onboarding-${OnboardingSteps.BUSINESS_USE}`,
    component: OnboardingContainer,
    meta: {
      title: "Intended Usage",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.BUSINESS_USE,
    },
  },
  {
    path: paths.selectPlan,
    name: "subscription-plan",
    component: SelectSubscriptionPlan,
    meta: {
      title: "Choose Your Plan",
      authenticate: true,
      showHeader: true,
    },
    beforeEnter(_to: Route, _from: Route, next: NavigationGuardNext): void {
      getSubscription()
        .then((subscription) => {
          if (subscription.state === SubscriptionState.DELINQUENT) {
            next({ name: "account" });
            return;
          }

          next();
        })
        .catch(() => {
          next({ name: "account" });
        });
    },
  },
  {
    path: paths.billing,
    name: `onboarding-${OnboardingSteps.BILLING}`,
    component: OnboardingContainer,
    meta: {
      title: "Billing",
      authenticate: true,
      showHeader: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.BILLING,
    },
  },
  {
    path: paths.signupVerifySsn,
    name: `onboarding-${OnboardingSteps.VERIFY_SSN}`,
    component: OnboardingContainer,
    meta: {
      title: "Verify Your Social",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.VERIFY_SSN,
    },
  },
  {
    path: paths.signupSsnLastFour,
    name: `onboarding-${OnboardingSteps.SSN_LAST_FOUR}`,
    component: OnboardingContainer,
    meta: {
      title: "Verify Your Social",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.SSN_LAST_FOUR,
    },
  },
  {
    path: paths.signupReviewDetails,
    name: `onboarding-${OnboardingSteps.REVIEW_DETAILS}`,
    component: OnboardingContainer,
    meta: {
      title: "Review Your Details",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.REVIEW_DETAILS,
    },
  },
  {
    path: paths.signupFunding,
    alias: paths.fundingAdd,
    name: `onboarding-${OnboardingSteps.ADD_BANK}`,
    component: OnboardingContainer,
    meta: {
      title: "Set Your Funding Source",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.ADD_BANK,
    },
  },
  {
    path: paths.signupBankConfirmed,
    name: `onboarding-${OnboardingSteps.SUCCESS_BANK}`,
    component: OnboardingContainer,
    meta: {
      title: "Your Details",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.SUCCESS_BANK,
    },
  },
  {
    path: paths.signupFundingCharge,
    name: `onboarding-${OnboardingSteps.FUNDING_TYPE_CHARGE}`,
    component: OnboardingContainer,
    meta: {
      title: "Choose Funding Type",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.FUNDING_TYPE_CHARGE,
    },
  },
  {
    path: paths.signupConfirmBankAccount,
    name: `onboarding-${OnboardingSteps.CONFIRM_BANK}`,
    component: OnboardingContainer,
    meta: {
      title: "Confirm Bank",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.CONFIRM_BANK,
    },
  },
  {
    path: paths.signupDisclosures,
    alias: paths.signupCardDisclosures,
    name: `onboarding-${OnboardingSteps.LEGACY_DISCLOSURES}`,
    component: OnboardingContainer,
    meta: {
      title: "Please review these important documents",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.LEGACY_DISCLOSURES,
    },
  },
  {
    path: paths.signupComplete,
    name: `onboarding-${OnboardingSteps.SIGNUP_COMPLETE}`,
    component: OnboardingContainer,
    meta: {
      title: "Signup Complete",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.SIGNUP_COMPLETE,
    },
  },
  {
    path: paths.signupApplicationComplete,
    name: `onboarding-${OnboardingSteps.APPLICATION_COMPLETE}`,
    component: OnboardingContainer,
    meta: {
      title: "Application Complete",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.APPLICATION_COMPLETE,
    },
  },
  {
    path: paths.signupFlagged,
    name: `onboarding-${OnboardingSteps.FLAGGED_ACCOUNT}`,
    component: OnboardingContainer,
    meta: {
      title: "Thank you for your application.",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.FLAGGED_ACCOUNT,
    },
  },
  {
    path: paths.signupAdditionalVerification,
    name: `onboarding-${OnboardingSteps.ADDITIONAL_VERIFICATION}`,
    component: OnboardingContainer,
    meta: {
      title: "We need some additional information",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.ADDITIONAL_VERIFICATION,
    },
  },
  {
    path: paths.signupReturningVerifySsn,
    name: `onboarding-${OnboardingSteps.EXISTING_USER_VERIFY_SSN}`,
    component: OnboardingContainer,
    meta: {
      title: "Verify Your SSN",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.EXISTING_USER_VERIFY_SSN,
    },
  },
  {
    path: paths.signupUpdatedDisclosure,
    name: `onboarding-${OnboardingSteps.EXISTING_USER_CHARGE_DISCLOSURE}`,
    component: OnboardingContainer,
    meta: {
      title: "Upgrade Your Account",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.EXISTING_USER_CHARGE_DISCLOSURE,
    },
  },
  {
    path: paths.signupTermsSuccess,
    name: `onboarding-${OnboardingSteps.CHARGE_TERMS_SUCCESS}`,
    component: OnboardingContainer,
    meta: {
      title: "Signup Complete",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.CHARGE_TERMS_SUCCESS,
    },
  },
  {
    path: paths.signupUpdateRetry,
    name: `onboarding-${OnboardingSteps.REMEDIATION_RETRY}`,
    component: OnboardingContainer,
    meta: {
      title: "Review Your Details",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.REMEDIATION_RETRY,
    },
  },
  {
    path: paths.signupUpdateSuccess,
    name: `onboarding-${OnboardingSteps.REMEDIATION_SUCCESS}`,
    component: OnboardingContainer,
    meta: {
      title: "Information Confirmed",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.REMEDIATION_SUCCESS,
    },
  },
  {
    path: paths.signupUpdatePending,
    name: `onboarding-${OnboardingSteps.REMEDIATION_PENDING}`,
    component: OnboardingContainer,
    meta: {
      title: "Information Confirmed",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.REMEDIATION_PENDING,
    },
  },
  {
    path: paths.signupUpdateFailure,
    name: `onboarding-${OnboardingSteps.REMEDIATION_FAILURE}`,
    component: OnboardingContainer,
    meta: {
      title: "Pending Review",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.REMEDIATION_FAILURE,
    },
  },
  {
    path: paths.signupAddFunds,
    name: `onboarding-${OnboardingSteps.ADD_FUNDS}`,
    component: OnboardingContainer,
    meta: {
      title: "Add Funds",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.ADD_FUNDS,
    },
  },
  {
    path: paths.signupCommercialChargeDiscolsure,
    name: `onboarding-${OnboardingSteps.COMMERCIAL_CHARGE_DISCLOSURE}`,
    component: OnboardingContainer,
    meta: {
      title: "Accept Commercial Charge Terms",
      authenticate: true,
    },
    props: {
      fullpage: true,
      startingScreen: OnboardingSteps.COMMERCIAL_CHARGE_DISCLOSURE,
    },
  },
  {
    path: paths.subscribePlan,
    name: "custom-plan",
    component: CustomPlan,
    meta: {
      title: "Custom Plan",
      authenticate: true,
    },
    props: {
      fullpage: true,
    },
  },
  {
    path: paths.businessVerify,
    name: "business-verify",
    redirect: paths.signupBusinessType,
    meta: {
      authenticate: true,
    },
    props: {
      fullpage: true,
    },
  },
  {
    path: paths.acceptChargeTerms,
    name: paths.acceptCommercialChargeTerms,
    component: AcceptCommercialChargeTerms,
    meta: {
      authenticate: true,
    },
    props: {
      fullpage: true,
    },
    beforeEnter(_to: Route, _from: Route, next: NavigationGuardNext): void {
      getUser()
        .then((user) => {
          if (
            user.accountPurpose === AccountPurposes.BUSINESS &&
            !user.commercialChargeTermsAcceptTime
          ) {
            userStore.actions
              .commercialChargeCardAgree()
              .then(() => {
                next();
              })
              .catch(() => {
                next({ name: "home" });
              });
          } else {
            next({ name: "home" });
          }
        })
        .catch(() => {
          next({ name: "home" });
        });
    },
  },
  {
    path: paths.signupFundingPlaid,
    name: `onboarding-plaid`,
    component: OnboardingMobilePlaid,
  },
];

export default routes;
