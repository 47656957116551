export interface SelectOption {
  value: string;
  text: string;
  disabled?: boolean;
}

export type InputState = boolean | null;

export interface CardValue {
  pan: string;
  cvv: string;
  exp: string;
}

export enum Format {
  PHONE = "phone-us",
  SSN = "ssn",
  MICROAUTH = "dollar-microauth",
  DATE = "date",
  NUMERIC = "numeric",
  CURRENCY = "currency",
  PAN = "pan",
}

export type WebCamType = {
  capture: () => string;
};
