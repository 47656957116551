<template>
  <SimplePage :fullpage="fullpage" :class="{ '-loading': waiting }">
    <BaseForm
      :fullpage="fullpage"
      @submit="submit"
      :class="['confirm-bank-form', 'signup-step']"
    >
      <h1>Confirm Your Bank</h1>

      <p class="blurb">
        Enter the two deposits we made to your bank account below to confirm
        ownership.
        <strong>These can take up to two days to appear in your bank.</strong>
      </p>

      <b-alert variant="danger" :show="errorMessage.length > 0">
        {{ errorMessage }}
      </b-alert>

      <BaseInputGroup>
        <BaseInput
          v-for="(deposit, index) in depositList"
          :key="index"
          name="amount"
          :label="`Deposit ${index + 1}`"
          placeholder="0.00"
          v-model="deposit.amount"
          type="text"
          maxlength="5"
          :state="deposit.error ? false : null"
          autocomplete="off"
          :format="Format.MICROAUTH"
        />
      </BaseInputGroup>

      <BaseButton
        variant="primary"
        @click="trackClick"
        :loading="waiting"
        size="lg"
        type="submit"
        data-test="confirm-bank-confirm"
        >Confirm</BaseButton
      >
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { Format } from "@/types/Form";
import { BAlert } from "bootstrap-vue";
import { bankStore, userStore } from "@/store";
import { AxiosError } from "axios";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

const DEFAULT_ERROR = "Oops! There was a problem confirming the deposits.";

@Component({
  components: {
    BAlert,
  },
})
export default class ConfirmBank extends Vue {
  @Prop({ default: false }) fullpage!: boolean;

  bankAccountID = "";
  depositList = [
    { amount: "", error: false },
    { amount: "", error: false },
  ];
  errorMessage = "";
  waiting = false;
  Format = Format;

  get user() {
    return userStore.getters.currentUser;
  }

  mounted() {
    if (!this.user?._id) {
      userStore.actions.getCurrentUser();
    }

    const { confirmingBank } = bankStore.getters;

    if (confirmingBank) {
      this.bankAccountID = confirmingBank?.bankAccountID;
    } else {
      const pendingBankAccount = this.user?.bankAccountList.find(
        (bank) => bank.state === "PENDING"
      );
      this.bankAccountID = pendingBankAccount?.bankAccountID;
    }

    if (!this.bankAccountID) {
      this.$emit("complete-step");
    }
  }

  trackClick(): void {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.CONFIRM_BANK)} Primary CTA`,
    });
  }

  submit() {
    this.errorMessage = "";
    this.depositList.forEach(({ amount = "" }, index) => {
      if (amount.length) {
        this.depositList[index].error = false;
      } else {
        this.depositList[index].error = true;
        this.errorMessage = "Enter both of your deposits amounts.";
      }
    });
    if (this.errorMessage) {
      return;
    }

    this.waiting = true;

    const [amount1, amount2] = this.depositList.map(({ amount }) => {
      const num = parseInt(amount.replace(/\D/g, "") || "0");
      // $0.3 -> 30 (otherwise itll be passed in as 3 which represents 03)
      return num < 10 ? num * 10 : num;
    });

    bankStore.actions
      .confirmMicroDeposits({
        bankAccountID: this.bankAccountID,
        amount1,
        amount2,
      })
      .then(({ data }) => {
        (this.user?.bankAccountList || []).forEach((bank) => {
          if (bank.bankAccountID === this.bankAccountID) {
            bank.state = "ENABLED";
          }
        });

        userStore.mutations.updateCurrentUser({
          bankAccountList: this.user?.bankAccountList,
        });

        bankStore.mutations.unsetConfirmingBank();

        const verificationNeeded = data.verificationNeeded || false;
        if (verificationNeeded) {
          this.$emit("complete-step", OnboardingSteps.ADDITIONAL_VERIFICATION);
        } else {
          this.$emit("complete-step");
        }
      })
      .catch((error: AxiosError<{ message?: string }>) => {
        this.errorMessage = error.response?.data?.message || DEFAULT_ERROR;
      })
      .finally(() => {
        this.waiting = false;
      });
  }
}
</script>

<style lang="scss" scoped>
::v-deep .blurb {
  color: $gray-600;
}

h1 {
  all: unset;
  font-family: $font-stack-wes-fy;
  font-size: 24px;
  text-align: center;
  margin-bottom: 15px;
  line-height: 1.4;
  color: $gray-800;
}
</style>
