<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :fullpage="fullpage"
      @submit="onClickFunding"
      class="funding-type"
      data-test="funding-type-business"
    >
      <h1>Connect a Business Bank Account</h1>
      <div class="explainer">
        <div class="content">
          <SVGIcon
            icon="funding-bank"
            size="80"
            data-test="funding-icon-bank"
          />
          <p class="description">
            The account you connect will be used to fund purchases you make on
            Privacy cards.
          </p>
          <p class="description">
            By connecting, you're enrolling in the automatic funding of
            purchases.
          </p>
        </div>
      </div>
      <BaseButton
        class="mt-2"
        type="submit"
        variant="primary"
        size="lg"
        data-test="funding-type-choose-bank"
        @click="trackClick"
      >
        Connect and Enroll
      </BaseButton>
      <p class="description">
        Don't see your bank listed? Please email
        <b-link href="mailto:support@privacy.com">support@privacy.com</b-link>
      </p>
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { BAlert } from "bootstrap-vue";
import SVGIcon from "@/components/SVGIcon.vue";
import { userStore } from "@/store";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component({
  components: {
    BAlert,
    SVGIcon,
  },
})
export default class FundingTypeBusiness extends Vue {
  @Prop({ default: false }) fullpage!: boolean;

  trackClick(): void {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.FUNDING_TYPE_BUSINESS)} Primary CTA`,
    });
  }

  onClickFunding(): void {
    userStore.actions.automaticPaymentsEnroll().then(() => {
      this.$emit("complete-step", OnboardingSteps.ADD_BANK);
    });
  }
}
</script>

<style lang="scss" scoped>
::v-deep .form {
  width: 21.75rem;
}

.funding-type {
  text-align: center;
  opacity: 0;
  animation: show-step $duration-default;
  animation-timing-function: $ease-out-expo;
  animation-fill-mode: forwards;

  > h1 {
    all: unset;
    margin-bottom: 15px;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    color: $gray-800;
    line-height: 1.4;
  }

  .explainer {
    position: relative;

    .content {
      padding: 24px 32px;
      background: $gray-100;
      border-radius: $border-radius;
      color: $gray-600;

      ::v-deep svg {
        zoom: 180%;
        margin-bottom: 10px;
      }

      .title {
        margin-bottom: 5px;
        font-weight: 600;
        color: $gray-800;
      }
    }
  }

  > button {
    margin: 20px 0 15px !important;
  }
}
</style>
