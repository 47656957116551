<template>
  <div class="banner-remediate" data-test="banner-remediate">
    <div class="wrapper">
      <div data-test="banner-remediate">
        <div class="title">
          Your account has been paused because we need to verify some of your
          information
        </div>
        <div class="description">
          Please submit this information to reactivate your Privacy Account.
        </div>
      </div>
      <BaseButton
        variant="primary"
        size="lg"
        class="btn-remediate"
        @click="onClickRemediate"
        data-test="start-remediation"
      >
        Verify My Info
      </BaseButton>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class BannerRemediate extends Vue {
  onClickRemediate() {
    this.$emit("start-remediation");
  }
}
</script>
<style lang="scss" scoped>
.banner-remediate {
  $media-width-1200: "only screen and (max-width: 1200px)";
  $media-width-1110: "only screen and (max-width: 1110px)";
  $media-width-820: "only screen and (max-width: 820px)";

  $responsive-column-1200: 1090px;
  $responsive-column-1110: 820px;
  $responsive-column-820: 550px;

  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 30px 20px;

  .wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background: $gray-100;
    border-radius: $border-radius-lg;
    padding: 30px;

    .title {
      color: $gray-900;
      font-weight: bold;
    }

    .description {
      color: $gray-600;
    }

    .btn-remediate {
      width: 200px;
    }
  }

  @media #{$media-width-1200} {
    max-width: $responsive-column-1200;
  }

  @media #{$media-width-1110} {
    max-width: $responsive-column-1110;
    padding: 20px;

    .btn-remediate {
      margin-left: 30px;
    }
  }

  @media #{$media-width-820} {
    max-width: 580px;

    .wrapper {
      flex-flow: column nowrap;

      .btn-remediate {
        margin-left: 0px;
        margin-top: 20px;
        width: 100%;
      }
    }
  }
}
</style>
