<template>
  <KybVerificationContainer v-if="!isRedirecting">
    <h3 class="heading">Please confirm your account information</h3>
    <p class="pb-0 mb-0">
      To comply with "Know Your Customer" or "KYC" rules and regulations, we
      must periodically confirm and update customer account information.
    </p>
    <p class="pb-0 mb-0">
      If you are using this account for business purposes, we may need to
      request documents from you to verify your EIN, bank account information,
      and other information.
    </p>
    <div class="divider"></div>
    <BaseButton variant="primary" @click="nextStep" class="cta-btn"
      >Continue</BaseButton
    >
  </KybVerificationContainer>
</template>

<script lang="ts">
import BaseButton from "@/components/BaseButton.vue";
import { userStore } from "@/store";
import { Component, Vue } from "vue-property-decorator";
import SVGIcon from "@/components/SVGIcon.vue";
import KybVerificationContainer from "./KybVerificationContainer.vue";

@Component({
  components: {
    BaseButton,
    SVGIcon,
    KybVerificationContainer,
  },
})
export default class StartKybVerification extends Vue {
  isRedirecting = true;

  get kybUser() {
    return userStore.getters.currentUser!.kybVerificationUser;
  }

  get pendingBusinessInfoUser() {
    return userStore.getters.currentUser!.isPendingBusinessInfo;
  }

  get pendingBusinessInfoCurrentStep() {
    return userStore.getters.currentUser!.kybCurrentStep;
  }

  get piwikPrefix() {
    return this.kybUser ? "Verification" : "Onboarding";
  }

  nextStep() {
    this.$piwik.trackClick({ name: `${this.piwikPrefix} Intro CTA` });
    this.$router.push({ name: "kybAccountPurpose" });
  }

  async initializeKybVerification() {
    if (!this.kybUser?.verificationStartDate) {
      await userStore.actions.startVerification();
    }
  }

  handleRedirect() {
    let currentStep;

    // KYB flow takes priority over isPendingBusinessInfo if user has both
    if (this.kybUser) {
      currentStep = this.kybUser.currentStep;
    } else {
      // default to kybBusinessType screen if isPendingBusinessInfo has not started
      currentStep = this.pendingBusinessInfoCurrentStep || "kybBusinessType";
    }

    if (currentStep) {
      if (currentStep.includes("?")) {
        const [step, query] = currentStep.split("?");
        const key = query.split("=")[0];
        const value = query.split("=")[1];

        this.$router.push({
          name: step,
          query: {
            [key]: value,
          },
        });
      } else {
        this.$router.push({ name: currentStep });
      }
    }
  }

  async created() {
    await userStore.actions.getCurrentUser();

    if (this.kybUser) {
      await this.initializeKybVerification();
    }

    this.handleRedirect();
    this.isRedirecting = false;
  }

  mounted() {
    this.$piwik.trackEvent("Modal", "View", {
      name: `${this.piwikPrefix} Intro`,
    });
  }
}
</script>

<style lang="scss">
.cta-btn {
  display: flex;
  height: 40px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}
</style>
