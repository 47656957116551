import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export class Toast extends Vue {
  _sendToast(message: string, variant: string) {
    this.$root.$bvToast.toast(message, {
      noCloseButton: true,
      variant,
    });
  }

  notifyToast(message: string) {
    this._sendToast(message, "primary");
  }

  successToast(message: string) {
    this._sendToast(message, "success");
  }

  errorToast(message: string) {
    this._sendToast(message, "danger");
  }
}
