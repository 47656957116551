<template>
  <div class="signup-login-wrapper">
    <div class="logo-wrapper">
      <PrivacyLogo
        href="/"
        class="privacy-logo"
        aria-label="Go to Home"
      ></PrivacyLogo>
    </div>
    <b-alert :show="accountClosed" variant="success">
      Your account has been closed and you have been logged out. Thanks for
      using Privacy!
    </b-alert>
    <div class="container-wrapper">
      <div class="container -login-content">
        <component :is="computedLoginComponent" />
      </div>
      <div class="container -left-content" :class="leftContentClass()">
        <component :is="computedLeftComponent()" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Extension } from "@/mixins/Extension";
import { Component, Prop, Mixins } from "vue-property-decorator";
import { isValidToken } from "@/router/navigation-guard";
import PrivacyLogo from "@/components/PrivacyLogo.vue";
import Login from "./Login.vue";
import NewUser from "./NewUser.vue";
import GetExtension from "./GetExtension.vue";
import ResetPassword from "./ResetPassword.vue";
import TwoFactorAuth from "./TwoFactorAuth.vue";
import TwoFactorAuthRecovery from "./TwoFactorAuthRecovery.vue";

import { userStore } from "../../store";

@Component({
  components: {
    Login,
    NewUser,
    GetExtension,
    PrivacyLogo,
    ResetPassword,
    TwoFactorAuth,
    TwoFactorAuthRecovery,
  },
})
export default class LoginContainer extends Mixins(Extension) {
  @Prop({ default: "login" }) state!: string;
  @Prop({ default: false }) accountClosed?: boolean;

  get computedLoginComponent(): string {
    const state = this.$route.params.state || this.state;
    switch (state) {
      case "reset-password":
      case "forgot-password":
        return "ResetPassword";
      case "tfa":
        return "TwoFactorAuth";
      case "tfa-recovery":
        return "TwoFactorAuthRecovery";
      default:
        return "Login";
    }
  }

  computedLeftComponent(): string {
    return this.extensionExistsForBrowser ? "GetExtension" : "NewUser";
  }

  leftContentClass(): string {
    return this.extensionExistsForBrowser ? "-extension" : "-new-user";
  }

  created() {
    if (
      isValidToken(this.$cookies.get("token")) ||
      userStore.getters.isLoggedIn
    ) {
      return this.$router.push({ name: "home" });
    }

    // If route params were passed, set these values in the store
    if (this.$route.params.userToken) {
      userStore.mutations.setUserToken(this.$route.params.userToken);
    }

    if (this.$route.params.twoFactorAuth) {
      userStore.mutations.updateCurrentUser({
        twoFactorAuth: !!this.$route.params.twoFactorAuth,
      });
    }

    if (this.$route.params.oneTimeCode) {
      userStore.mutations.setOneTimeCode(this.$route.params.oneTimeCode);
    }
  }
}
</script>

<style lang="scss" scoped>
.signup-login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  > .logo-wrapper {
    min-height: 40px;

    > .privacy-logo {
      position: relative;
      top: 20px;
      left: auto;
      right: auto;

      @media #{$media-width-900} {
        position: relative;
      }
    }
  }
}

.alert {
  margin-top: 1rem;
}

.signup-login-wrapper > .container-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
  margin: auto;
  width: 100%;
  max-width: 960px;
  overflow: hidden;
  padding: 0 20px;

  > .container {
    display: flex;
    flex-basis: 50%;
    flex-grow: 1;
    margin: 20px 0;
    min-height: 460px;
    max-width: none;
  }

  > .container.-login-content {
    justify-content: stretch;
    margin-left: -2px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: inset 0 0 0 2px $gray-100;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  ::v-deep .signup-step {
    &:before,
    &:after {
      content: none;
    }

    > .privacy-logo {
      display: none;
    }
  }

  > .container.-left-content {
    display: flex;
    padding-left: 30px;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    user-select: none;

    &.-extension {
      background-color: #0b1730;
      overflow: hidden;
    }

    &.-new-user {
      background-color: mix($white, $blue, 90%);
    }
  }

  @media #{$media-width-900} {
    flex-direction: column;
    flex-grow: 1;
    align-items: stretch;
    margin: 0;
    border-radius: 0;
    padding: 0;

    > .container {
      margin: 0;
    }

    > .container.-login-content {
      box-shadow: none;
      border-radius: 0;
    }

    > .container.-left-content {
      border-radius: 0;
    }
  }
}

::v-deep .signup-step {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 20px 10px;
  min-height: 100%;
  max-width: 320px;

  > .privacy-logo {
    margin: 20px auto 60px;
  }

  &:after {
    content: "";
    display: block;
    margin-top: auto;
  }

  &.-loading .field,
  &.-loading .account-checkbox {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  }

  &.-modal {
    padding: 40px;
    max-width: 380px;
    background-color: $white;
    box-shadow: $box-shadow-large, $box-shadow-hairline;
    border-radius: $border-radius;
    animation: $animation-fade-in-up-zoom;
  }

  &.-modal > .privacy-logo {
    display: none;
  }

  > button {
    margin-top: 10px;
  }

  > button > a {
    border-radius: $border-radius;
    max-width: 320px;
    margin: 0 auto;
  }

  > .form-header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 15px;
  }

  > .form-header > .header-link {
    color: $blue;
    cursor: pointer;
    align-self: center;
  }

  > .form-header > h1,
  > h1 {
    margin-bottom: 15px;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    color: $gray-800;
    line-height: 1.4;
  }

  > .form-header > h1 {
    margin-right: auto;
    margin-bottom: 0;
  }

  ._reset-expand-to-fill {
    flex-grow: 1;
    max-height: 26px;
  }

  .error-message + ._reset-expand-to-fill {
    display: none;
  }

  > .blurb {
    margin-top: 5px;
    margin-bottom: 15px;
    text-align: center;
    color: mix($gray-800, $gray-500, 50%);
  }

  > .blurb strong {
    color: $gray-800;
  }

  > .blurb.-left {
    text-align: left;
  }

  > .blurb > p + p {
    margin-top: 10px;
  }

  .blurb > a,
  p > a[href] {
    color: $blue;
    cursor: pointer;
  }

  a.-standalone {
    display: block;
    padding: 10px;
    text-align: center;
    cursor: pointer;
  }

  > .error-container > .error-message,
  > .warning-message,
  > .info-message,
  > .success-message {
    padding: 15px 20px;
    background-color: mix($white, $red, 90%);
    border-radius: $border-radius;
    font-weight: bold;
    color: darken($color-privacy-red, 10%);
    animation: show-error-message $duration-short;
    animation-timing-function: $ease-in-out-expo;
    animation-fill-mode: forwards;
    word-wrap: break-word;
  }

  > .warning-message {
    background-color: mix($white, $pastel-yellow, 90%);
    color: darken($pastel-yellow, 10%);
    animation: none;
  }

  > .info-message {
    background-color: mix($white, $blue, 90%);
    color: $color-blue-darker;
    animation: none;
    font-weight: normal;
  }

  > .success-message {
    background-color: mix($white, $color-green, 85%);
    color: darken($color-green, 15%);
  }

  > .error-message + .warning-message,
  > .error-message + .info-message,
  > .error-message + .success-message {
    margin-top: 10px;
  }

  .field {
    margin-top: 10px;
    margin-bottom: 0;
  }

  > .submit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    height: 50px;
    background-color: $blue;
    border-radius: $border-radius;
    font: inherit;
    font-weight: bold;
    color: $white;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $blue;
      opacity: 0.8;
    }
  }

  .account-checkbox {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 0px;
    padding: 15px 20px;
    box-shadow: inset 0 0 0 2px $gray-100;
    border-radius: $border-radius;
    font-size: 12px;
    line-height: 20px;
    cursor: default;
  }

  .account-checkbox.-error {
    box-shadow: inset 0 0 0 2px fade-out($color-privacy-red, 0.5);
  }

  > .account-checkbox > .checkbox {
    display: inline-block;
    flex-shrink: 0;
    margin-right: 10px;
    height: 20px;
    width: 20px;
    background-color: mix($gray-400, $gray-100, 20%);
    box-shadow: 0 0 0 2px fade-out($blue, 1);
    border-radius: $border-radius;
    outline: none;
    transition: all $duration-shorter;
    appearance: none;

    &:hover {
      filter: brightness(95%);
    }

    &:focus {
      box-shadow: 0 0 0 2px $blue;
    }

    &:checked {
      background-color: $gray-800;
      background-image: url("/assets/images/icons/tick-20-white.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  > .captcha {
    margin-top: 10px;
  }

  > .document {
    display: block;
    margin: 10px -20px 20px;
    padding: 20px;
    height: calc(100vh - 240px);
    max-height: 400px;
    overflow: scroll;
    border: 1px solid mix($gray-400, $gray-100, 50%);
    border-radius: $border-radius;

    > h1,
    h2,
    h3,
    h4,
    p {
      &:not(:first-child) {
        margin-top: 1em;
      }
    }

    > h1,
    h2,
    h3,
    h4 {
      font-weight: bold;
      text-transform: unset;
    }

    @media #{$media-phone} {
      margin: 0 0 10px;
      padding: 20px 0;
      height: auto;
      max-height: none;
      overflow: visible;
      border-width: 1px 0;
      border-radius: 0;
    }
  }

  > .warning-notice {
    padding: 18px 20px;
    background-color: mix($white, $pastel-yellow, 80%);
    border-radius: $border-radius;
    color: darken($pastel-yellow, 20%);

    > .title {
      font-size: 14px;
      font-weight: bold;
      color: darken($pastel-yellow, 30%);
    }

    > p:not(:first-child) {
      margin-top: 5px;
    }
  }

  .card-field {
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 24px 26px 0;
    min-height: 180px;
    background-color: $gray-100;
    border-radius: $border-radius;

    .visa-logo {
      position: absolute;
      right: 20px;
      bottom: 20px;
      pointer-events: click-through;
    }

    > .label {
      margin-bottom: 30px;
      font-size: 12px;
      font-weight: bold;
    }

    > .field-group > .field.-cvv {
      flex-basis: 50px;
      margin-left: 52px;
      border-radius: $border-radius;
    }

    > .field-group > .field.-exp {
      flex-basis: 76px;
      border-radius: $border-radius;
    }

    .field > .label {
      display: none;
    }

    .field > .input {
      position: static;
      margin: 4px 0 0;
      padding: 5px;
      font-family: $font-stack-ocr;
      font-size: 18px;
      letter-spacing: -0.3px;
      color: $gray-800;
    }

    .field {
      margin-left: -6px;
      padding: 0;
      background-color: transparent;
    }
  }

  > .signup-inner-dialog {
    position: absolute;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: fade-out($white, 0.1);
    opacity: 0;
    animation: $animation-fade-in;
  }

  &.-modal > .signup-inner-dialog {
    border-radius: $border-radius;
  }

  > .signup-inner-dialog > .content {
    padding: 20px;
    background-color: $white;
    box-shadow: $box-shadow-hairline, $box-shadow-large;
    border-radius: $border-radius;
    animation: $animation-fade-in-up;
  }

  > .signup-inner-dialog > .content > button {
    margin-top: 20px;
    border-radius: $border-radius;
  }

  @media #{$media-phone} {
    > .privacy-logo {
      margin-bottom: 40px;
    }
  }

  @media only screen and (max-height: 700px) {
    > .privacy-logo {
      margin-bottom: 40px;
    }
  }
}

@keyframes show-error-message {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
