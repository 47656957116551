<template>
  <div class="helper -charge-closed" data-test="commercial-charge-closed">
    <div class="title">We updated our Privacy Cards!</div>
    <div class="body">
      <p>
        Please review and accept our new Terms to create new cards and continue
        using Privacy.
      </p>
    </div>
    <div class="controls">
      <BaseButton
        data-test="commercial-closed-get-started"
        variant="primary"
        shape="round"
        class="action"
        width="narrow"
        @click="$bvModal.show('commercial-charge-closed-reminder')"
        >Get Started</BaseButton
      >
    </div>
    <div class="bg-image">
      <img
        src="/assets/images/components/dashboard-helpers/commercial-charge-reissued-helper.png"
        width="140"
        alt="Illustration of a credit card with recycle arrows that indicate it is being updated"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import PrivacyLogo from "../PrivacyLogo.vue";

@Component({
  components: { PrivacyLogo },
})
export default class HelperCommercialChargeClosed extends Vue {}
</script>
<style lang="scss" scoped>
.helper.-charge-closed {
  position: relative;
  overflow: hidden;
  background: linear-gradient(
      96.5deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    #e7eef6;

  > .title {
    font-family: $font-stack-wes-fy;
    font-size: 18px;
    color: $gray-800;
    max-width: 195px;
    line-height: 19px;
  }

  > .body p {
    max-width: 200px;
    margin-top: 0.375rem;
    margin-bottom: 0.5rem;
    font-family: $font-stack-lato;
    font-weight: 400;
    color: $gray-600;
  }

  > .action {
    color: $white;
    font-family: $font-stack-wes-fy;
    font-weight: 900;
    font-size: 14px;
  }

  > .bg-image {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
  }
}
</style>
