import { FundingStep } from "./Funding";
import { AccountIntegration } from "./AccountIntegration";
import { CashbackKey } from "./Cashback";
import { Organization } from "./Organization";
import { Card } from "./Card";

export type NotificationSetting = "NONE" | "DECLINES" | "TRANSACTIONS";

export interface AccountBalances {
  ach: string;
  available: string;
  cash: string;
  limit?: string;
  pendingAuth: string;
  promo: string;
}

export interface UserAccountInfo {
  limits: Record<any, any>;
  keyValuePair: Record<string, string>;
  fundingCardBatchStatus: boolean;
}

export interface ManualBatchData {
  hasBatchesToApprove: boolean;
  latestBatchID: string;
  totalPenniesOwed: number;
  oldestUnapprovedBatch: Date | null;
}

export interface PromoCredits {
  amount: string;
}

export enum AccountPurposes {
  PERSONAL = "PERSONAL",
  BUSINESS = "BUSINESS",
}

export enum TwoFactorPreferences {
  EMAIL = "email",
  SMS = "sms",
  AUTHENTICATOR = "authenticator",
}

export enum KybVerificationUserStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  IN_REVIEW = "IN_REVIEW",
  COMPLETED = "COMPLETED",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
}

export interface KybVerificationUser {
  accountID: string;
  status: KybVerificationUserStatus;
  currentStep: string;
  verificationStartDate: string;
  verificationEndDate: string;
}

export interface User {
  _id: string;
  email: string;
  extensionToken?: string;
  // todo-ts: type this
  activePromo: any;
  address1: string;
  address2: string;
  zipcode: string;
  state: string; // i.e. NY
  city: string;
  banned?: boolean;
  // todo-ts: type this
  subscription?: any;
  organization?: Organization;
  firstName: string;
  middleName?: string;
  lastName: string;
  preferredName: string;
  preferredFirstName?: string;
  emailConfirmed?: boolean;
  KYCVerified?: boolean;
  phoneLastFour?: string;
  lastAppLogin?: string;
  lastExtensionLogin?: string;
  /**
   * Returns true if the user is grandfathered in
   * (to not need a phone number) or if the user
   * has a validated phone number
   */
  phoneVerified?: boolean;
  /**
   * Returns true if the user actually has a validated phone
   * number and ignores any grandfathering rules
   */
  phoneNumberVerified?: boolean;
  // todo-ts: type this
  bankAccountList: Array<any>;
  // todo-ts: type this
  fundingCardList: Array<any>;
  // todo-ts: type this
  apiSandbox: any;
  // todo-ts: type this
  api: any;
  isPrepaid?: boolean;
  isJIT?: boolean;
  isACH?: boolean;
  isLimitWeekly?: boolean;
  acceptedDisclosure?: boolean;
  businessReviewNeeded?: boolean;
  // todo-ts: type this
  accountType?: string;
  verificationNeeded?: boolean;
  applicationDeclined?: boolean;
  shouldContactSupport?: boolean;
  hasLowBalance?: boolean;
  hasFullSsn?: boolean;
  hasBlockscoreSsn?: boolean;
  canBypassSsn?: boolean;
  twoFactorAuth?: boolean;
  cashbackKeys?: Array<CashbackKey>;
  // todo-ts: type this
  cashbackProgram?: any;
  // todo-ts: type this
  activeSpendToEarnCashbackOffer: any;
  acceptedDisclosureDate?: Date;
  acceptedSharingDisclosureDate?: Date;
  referralCode?: string;
  photo?: string | null;
  activeIntegrations?: Array<AccountIntegration>;
  transactionNotifications: NotificationSetting;
  legacyCardLimit?: string | false;
  hasSsnLast4: boolean;
  ssnLast4?: string;
  // todo-ts: type this
  fundingSourceList: Array<any>;
  accountBalances?: AccountBalances;
  permissions?: Array<string>;
  dailySpendLimit: string;
  monthlySpendLimit: string;
  created: string;
  accountPurpose?: AccountPurposes | null;
  manualBatchData?: ManualBatchData;
  manualApplicationSentTime?: Date;
  chargeTermsAcceptTime?: Date;
  commercialChargeTermsAcceptTime?: Date;
  hasManualPayments: boolean;
  hasFundingSource: boolean;
  hasHadAnyFundingSource: boolean;
  referredBy?: {
    _user: string;
    promoID: string;
    referralCode: string;
    issueRewardOnActivation: boolean;
    rewarded: boolean;
  };
  readOnly?: boolean;
  blockExt?: boolean;
  failedTransaction?: boolean;
  dob: string;
  isEditingDetails?: boolean;
  isRemediatingKYC: boolean;
  hasPendingAlternateFundingRequest: boolean;
  currentRemediationStep: string;
  sampleCard: Partial<Card>;
  hasConfirmedDetails?: boolean;
  shouldShowLimits: boolean;
  promoCredits: PromoCredits;
  isPendingBusinessReview?: boolean;
  hasSeenPostMigrationNotice?: boolean;
  isPendingBusinessInfo?: boolean;
  tfaPreference: TwoFactorPreferences;
  canAccessLiveChat?: boolean;
  kybVerificationUser?: KybVerificationUser;
  // current step for isPendingBusinessInfo using KYB flow
  kybCurrentStep?: string;
}

export class UserState {
  currentUser: User | null = null;
  hasExtension = false;
  seenConfirmEmailStep = false;
  fundingStep: FundingStep | null = null;
  userToken: string | null = null;
  oneTimeCode: string | null = null;
  authMessage?: string | null = null;
  accountPurpose: AccountPurposes | null = null;
  accountInfo: UserAccountInfo | null = null;
}
