<template>
  <div></div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class AppRedirect extends Vue {
  created() {
    const state = this.$route.params.stateName;
    this.$router.push({ name: state });
  }
}
</script>
