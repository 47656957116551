<template>
  <BaseModal
    :id="modalId"
    size="md"
    hide-header
    @hide="onCancelEdit"
    @show="updateNotes"
    :visible="showModal"
  >
    <div class="modal-card-edit-notes">
      <div class="header">
        <div class="title">Card Note</div>
      </div>
      <BaseTextarea
        class="notes-input"
        id="modal-card-edit-notes-input"
        data-test="card-edit-notes-input"
        autofocus
        autocorrect="off"
        spellcheck="false"
        :maxlength="MAX_NOTES_LENGTH"
        v-model="cardNotes"
        :disabled="!canEditNotes"
        @change="onChangeNotes"
      />
      <span class="limiter">{{ charactersLeft }}</span>
      <div class="upgrade-cta d-flex mt-2 w-50" v-if="!canEditNotes">
        <SVGIcon class="padlock" icon="padlock" />
        Upgrade to edit notes
      </div>
      <span class="mt-2 mb-3 pb-2 info">
        For your security, please avoid entering sensitive account information
        here, such as passwords or credit card numbers.
      </span>
      <BaseButton
        block
        class="pill-button"
        variant="primary"
        :loading="submitting"
        @click="onClickSave"
        data-test="card-edit-notes-submit"
        :disabled="!canEditNotes"
      >
        Save
      </BaseButton>
    </div>
  </BaseModal>
</template>
<script lang="ts">
import { Component, Prop, Mixins } from "vue-property-decorator";
import { Card, CardType } from "@/types/Card";
import { EVENTS } from "@/types/Event";
import { cardStore, eventStore, userStore, subscriptionStore } from "@/store";
import { Toast } from "@/mixins/Toast";
import SVGIcon from "@/components/SVGIcon.vue";

@Component({
  components: {
    SVGIcon,
  },
})
export default class EditCardNotes extends Mixins(Toast) {
  @Prop({ default: "edit-card-notes" }) modalId!: string;
  @Prop() card!: Card;
  @Prop() isSampleCard?: boolean;
  @Prop({ default: false }) showModal!: boolean;

  CardType = CardType;

  cardNotes = "";
  changedNotes = false;
  submitting = false;
  MAX_NOTES_LENGTH = 300;

  get user() {
    return userStore.getters.currentUser;
  }

  get isNewCard() {
    return !this.card.created;
  }

  updateNotes() {
    this.cardNotes = this.card.note ?? "";
  }

  closeModal() {
    this.$bvModal.hide(this.modalId);
  }

  onChangeNotes() {
    if (this.cardNotes !== this.card.note) {
      this.changedNotes = true;
    }
  }

  get canEditNotes() {
    return !!this.subscription?.features.cardNotes;
  }

  get subscription() {
    return subscriptionStore.getters.subscription;
  }

  get charactersLeft() {
    const char = this.cardNotes.length;
    const limit = this.MAX_NOTES_LENGTH;
    return limit - char + " / " + limit + " characters remaining";
  }

  async onClickSave() {
    if (this.submitting) {
      return;
    }

    this.submitting = true;

    const updatedCard = {
      ...this.card,
      note: this.cardNotes,
    };

    if (this.isNewCard || this.isSampleCard) {
      this.$emit("update-card", updatedCard);
      this.closeModal();
      this.submitting = false;
      return;
    }
    try {
      await cardStore.actions.setCardNote(updatedCard);
      if (this.changedNotes) {
        eventStore.actions.record({
          name: EVENTS.CARD.SET_NOTES,
        });
      }
      this.$emit("update-card", updatedCard);
      this.closeModal();
    } catch (error) {
      this.errorToast(
        "There was an error saving the card notes. Please try again."
      );
    } finally {
      this.submitting = false;
    }
  }

  onCancelEdit() {
    this.cardNotes = this.card.note ?? "";
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/styles/mixins.scss";

.modal-card-edit-notes {
  @include modal-card-edit;

  padding-top: 30px;
  background-color: $white;
  border-radius: $border-radius-lg;
  margin: 0 auto;
}

.info {
  color: $gray-600;
  border-bottom: 1px solid $gray-200;
}
.upgrade-cta {
  border-radius: $border-radius-lg;
  background-color: $gray-200;
  padding: 10px 15px;
  font-weight: bold;
}
::v-deep .padlock {
  margin-right: 10px;
}
::v-deep textarea {
  padding-top: 20px !important;
}
@media #{$media-phone} {
  .notes-input {
    margin: 0 0 10px;
  }
}
</style>
