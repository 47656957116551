import Vue, { VNode } from "vue";
import { Toast } from "@/mixins/Toast";
import { DirectiveBinding } from "vue/types/options";
import { uniqueId } from "lodash";

const toast = new Toast();

function copyValue(
  value: DirectiveBinding["value"],
  modifiers: DirectiveBinding["modifiers"]
) {
  return async function (e: Event) {
    if (modifiers.stop) {
      e.stopPropagation();
    }

    const target = e.currentTarget as HTMLElement;

    try {
      navigator.clipboard.writeText(value);

      const successText =
        target.getAttribute("click-to-copy-text") || "Copied to clipboard";
      toast.successToast(successText);
    } catch (err) {
      toast.errorToast((err as Error).message || "Unable to copy");
    }
  };
}

const EventHandlerMap = new Map();

Vue.directive("clickToCopy", {
  bind: (
    element: HTMLElement,
    { value, modifiers }: DirectiveBinding,
    vnode: VNode
  ) => {
    if (!vnode.key) {
      vnode.key = uniqueId();
    }

    const callback = copyValue(value, modifiers);
    EventHandlerMap.set(vnode.key, callback);

    element.addEventListener("click", callback);
  },
  unbind: (element: HTMLElement, _binding: DirectiveBinding, vnode: VNode) => {
    const callback = EventHandlerMap.get(vnode.key);
    EventHandlerMap.delete(vnode.key);

    element.removeEventListener("click", callback);
  },
});
