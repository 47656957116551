<template>
  <div class="helper -extension" data-test="extension-install-helper">
    <div class="title">Save Time at Checkout</div>
    <div class="subtitle">
      Create and autofill Privacy cards with our browser extension
    </div>
    <BaseButton variant="primary" shape="round" @click="install('helper')">
      Download
    </BaseButton>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Mixins } from "vue-property-decorator";
import { Extension } from "@/mixins/Extension";

@Component
export default class HelperExtensionInstall extends Mixins(Extension, Vue) {
  get imageLink() {
    return this.browserIcon("32");
  }
}
</script>
<style lang="scss" scoped>
.helper.-extension {
  background-color: #0b1730;
  background-image: url("/assets/images/misc/extension-background.png");
  background-repeat: no-repeat;
  background-size: 185px auto;
  background-position: right;
  color: $white;

  button {
    font-weight: 900;
    padding: 12px 8px;
    white-space: nowrap;
    width: 100px;
  }

  .title {
    font-size: 18px;
    font-family: $font-stack-wes-fy;
    font-weight: 900;
    line-height: 21px;
    width: 50%;
  }

  .subtitle {
    font-family: $font-stack-lato;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.8px;
    width: 50%;
    padding-top: 10px;
    padding-bottom: 20px;
  }
}
.browser-image {
  height: 26px;
  width: 26px;
  margin-right: 8px;
}
</style>
