<template>
  <div class="banner-extension-install" data-test="banner-extension-install">
    <button
      class="banner-hide"
      @click="hideExtensionBanner"
      data-test="banner-extension-hide"
    >
      <b-icon icon="x" scale="1" />
      <span class="sr-only">Close</span>
    </button>
    <div class="banner-content-container">
      <div class="banner-text-container">
        <div class="title">Save Time at Checkout</div>
        <div class="subtitle">
          Create and autofill cards with our browser extension
        </div>
      </div>
      <button class="cta-button" @click="install('card-modal-banner')">
        <div class="cta-button-content">
          Add To {{ browserName }}
          <img :src="browserIconSrc" class="browser-icon" />
        </div>
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Mixins, Emit } from "vue-property-decorator";
import { BIcon, BIconX } from "bootstrap-vue";
import { Extension } from "@/mixins/Extension";

@Component({
  components: {
    BIcon,
    BIconX,
  },
})
export default class BannerExtensionInstall extends Mixins(Extension) {
  get browserIconSrc() {
    return this.browserIcon("32");
  }

  @Emit()
  hideExtensionBanner() {
    this.$cookies.set("hideExtensionBannerOnCardInspector", true);
  }
}
</script>
<style lang="scss" scoped>
.banner-extension-install {
  background: #0b1730;
  border-radius: $border-radius-lg;
  padding: 5px;
  margin-bottom: 30px;
  display: flex;
  position: relative;
}

.banner-hide {
  background: none;
  border: none;
  opacity: 0.75;
  color: white;
  position: absolute;
  top: 0;
  right: 0;

  &:hover {
    opacity: 1;
  }
}

.banner-content-container {
  padding: 15px;
  width: 100%;
}

.cta-button {
  width: 140px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-top: 6px;
  color: white;
  background-color: #30394b;
  border-radius: 6px;
  font-family: $font-stack-lato;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  border: none;

  .cta-button-content {
    width: 100%;
  }

  .browser-icon {
    margin-left: 8px;
    margin-bottom: 2px;
    width: 16px;
    height: 16px;
  }
}

.banner-text-container {
  float: left;
  margin-right: 30px;
}

.title {
  font-family: $font-stack-wes-fy;
  color: $white;
  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
}

.subtitle {
  font-family: $font-stack-lato;
  color: $white;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
</style>
