<template>
  <div class="logout"></div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import * as Sentry from "@sentry/vue";

@Component
export default class Logout extends Vue {
  created() {
    this.$cookies.remove("token");
    this.$cookies.remove("_vpsmb");
    // by avoiding clientside navigation, we wipe all data saved in the
    // store so we don't need to clear it manually on logout

    // Redirect to login or account-closed
    window.location.href = this.$route.query.accountClosed
      ? "/account-closed"
      : "/login";

    try {
      Sentry.getCurrentScope().clear();
    } catch (err) {
      /* noop */
    }
  }
}
</script>
