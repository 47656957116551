<template>
  <section>
    <p class="info">Enter your email address to see cards shared with you</p>
    <div v-if="errorMessage" class="form-error mb-3">{{ errorMessage }}</div>
    <form novalidate @submit.prevent="submit">
      <BaseInput
        class="mb-3"
        name="email"
        label="Email Address"
        placeholder="email@example.com"
        type="email"
        variant="dark"
        autofocus
        required
        @blur="checkValidity"
      />
      <div v-if="fieldError" class="error mb-3">{{ fieldError }}</div>
      <BaseButton
        :disabled="submitting"
        class="w-100 submit"
        variant="primary"
        aria-label="Submit"
        type="submit"
        >Next
      </BaseButton>
      <div class="text-center">
        <img src="/assets/images/shared/card-envelope.png" alt="" />
      </div>
    </form>
  </section>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { sharingStore } from "@/store";

@Component
export default class SharedLoginEmail extends Vue {
  errorMessage = "";
  submitting = false;
  fieldError = "";

  checkValidity({ target }: { target: HTMLInputElement }) {
    target.setCustomValidity("");

    if (!target.checkValidity()) {
      this.fieldError = "Please enter a valid email address";
      target.setCustomValidity(this.fieldError);
      target.setAttribute("aria-invalid", "true");
    } else {
      this.fieldError = "";
      target.setAttribute("aria-invalid", "false");
    }
  }

  async submit(event: Event) {
    const target = event.target as HTMLFormElement;

    this.submitting = true;
    this.checkValidity({ target: target.email });

    if (this.fieldError) {
      this.submitting = false;

      return;
    }

    try {
      const data = await sharingStore.actions.sendCodeFromEmail(
        target.email.value
      );

      this.$router.push({
        name: "shared-login-token",
        params: { linkToken: data.linkToken },
      });
    } catch (err) {
      this.errorMessage =
        (err as any).response?.data?.message || "There was an error";
    } finally {
      this.submitting = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.form-error {
  padding: 12px 16px;
  border-radius: $border-radius;
  font-family: $font-stack-graphik;
  background-color: $danger-10;
  color: $danger-default;
}

.info {
  font-family: $font-stack-graphik;
  line-height: 130%;
  text-align: center;
}

.error {
  margin-top: -10px;
  color: $danger-default;
  font-size: 12px;
  font-family: $font-stack-graphik;
  line-height: 130%;
}

.submit {
  margin-bottom: 144px;
}

.btn-primary {
  background-color: $button-primary-dark;

  &:hover {
    background-color: $button-primary-dark-hover;
  }
}
</style>
