export enum NotificationVariants {
  DARK,
}

export interface NotificationDropdown {
  text: string;
  onClick: () => void;
}

export interface NotificationInfo {
  id: string;
  priority: number;
  text: string;
  bank?: string;
  variant?: NotificationVariants;
  dropdown?: NotificationDropdown[];
  buttonText?: string;
  dismissManually?: boolean;
  onClick?: () => void;
}

export interface NotificationState {
  notificationList: NotificationInfo[];
  hidden: boolean;
}
