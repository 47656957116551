<template>
  <div class="card-promo" v-if="card.promoCredit">
    <div class="callout"></div>
    <div class="content">
      <div class="details">
        <div class="label">
          {{
            card && card.meta && card.meta.promoCardExpirationMilliseconds
              ? "Expiring "
              : "Promo "
          }}
          Credit
        </div>
        <div class="timer">
          {{ card && card.meta && card.meta.promoCardExpirationMilliseconds }}
        </div>
      </div>
      <div class="credit">${{ card.promoCredit / 100 }}</div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Card } from "../types/Card";

@Component
export default class CardPromo extends Vue {
  @Prop() card!: Card;
}
</script>
<style lang="scss" scoped>
.card-promo {
  flex-shrink: 0;
  margin: -10px auto 20px;
  padding-top: 12px;
  width: 240px;
  background-color: $pastel-yellow;
  box-shadow: 0 10px 20px fade-out($pastel-yellow, 0.8);
  border-radius: $border-radius-lg;
  color: $white;

  > .callout {
    margin: -24px auto 0;
    padding-top: 8px;
    height: 12px;
    width: 40px;
    overflow: hidden;
  }

  > .callout::after {
    content: "";
    display: block;
    margin: auto;
    height: 30px;
    width: 30px;
    background-color: $pastel-yellow;
    border-radius: $border-radius;
    transform: rotate(45deg);
  }

  > .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
  }

  > .content > .details {
    display: flex;
    flex-direction: column;
  }

  > .content > .details > .label {
    font-weight: bold;
  }

  > .content > .credit {
    font-size: 24px;
    text-align: right;
  }
}
</style>
