<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :fullpage="fullpage"
      @submit="submit(FundingSource.BANK)"
      class="funding-type"
      data-test="funding-type"
    >
      <h1>Connect Bank Account</h1>
      <b-alert variant="danger" :show="errorMessage.length > 0">
        {{ errorMessage }}
      </b-alert>
      <div class="explainer">
        <div class="content">
          <SVGIcon
            icon="funding-bank"
            size="80"
            data-test="funding-icon-bank"
          />
          <div class="description">
            Connect the bank account you want to use to fund purchases you make
            on your Privacy cards.
          </div>
        </div>
      </div>
      <BaseButton
        class="mt-2"
        type="submit"
        variant="primary"
        size="lg"
        data-test="funding-type-choose-bank"
        @click="trackClick"
      >
        Connect Bank Account
      </BaseButton>
      <b-link
        @click="connectDebitCard"
        data-test="funding-type-choose-card"
        aria-label="connect a debit card"
      >
        Can't connect a bank? Connect debit card instead
      </b-link>
      <div v-if="showAltFundingCta" class="alt-funding-cta">
        <hr />
        <div>
          Looking for another way to connect your funding source? <br />
          Please write to
          <a href="mailto:support@privacy.com">support@privacy.com</a>
        </div>
      </div>
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import ldGet from "lodash/get";
import { LDFlagValue } from "@launchdarkly/node-server-sdk";
import { FeatureFlags } from "@/types/LaunchDarkly";
import { Confirm } from "@/mixins/Confirm";
import { FundingSource } from "@/types/Funding";
import { User } from "@/types/User";
import { EVENTS } from "@/types/Event";
import { eventStore, featureStore, userStore } from "@/store";
import { BAlert } from "bootstrap-vue";
import PrivacyLogo from "@/components/PrivacyLogo.vue";
import SVGIcon from "@/components/SVGIcon.vue";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component({
  components: {
    BAlert,
    PrivacyLogo,
    SVGIcon,
  },
})
export default class FundingType extends Mixins(Confirm) {
  @Prop({ default: false }) fullpage!: boolean;

  FundingSource = FundingSource;
  errorMessage = "";
  showAltFundingCta = false;
  user: User | null = null;

  created() {
    userStore.actions.getCurrentUser().then(() => {
      this.user = userStore.getters.currentUser;
    });

    featureStore.actions
      .fetchFlag({ id: FeatureFlags.EXISTING_VISA_DEBIT_USERS })
      .then((value: LDFlagValue) => {
        this.showAltFundingCta = value;
      });
  }

  trackClick() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.FUNDING_TYPE)} Primary CTA`,
    });
  }

  connectDebitCard() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.FUNDING_TYPE)} Debit Card CTA`,
    });

    this.submit(FundingSource.CARD);
  }

  submit(fundingType: FundingSource = FundingSource.BANK) {
    this.errorMessage = "";
    // These lists contain both ENABLED and PENDING funding sources
    const bankList = ldGet<any, string, any[]>(
      this,
      "user.bankAccountList",
      []
    );
    const cardList = ldGet<any, string, any[]>(
      this,
      "user.fundingCardList",
      []
    );

    if (userStore.getters.isPendingManualPaymentsApproval) {
      return this.confirm(
        "You can't add a funding source until your manual payments application is submitted and approved."
      );
    }

    // Check if the user attempting to get into a funding configuration
    // that we don't yet support.
    let shouldBlock = false;
    if (fundingType === FundingSource.BANK && cardList.length > 0) {
      // Connecting a bank when they have an existing card
      shouldBlock = true;
    } else if (
      fundingType === FundingSource.CARD &&
      (bankList.length > 0 || cardList.length > 0)
    ) {
      // - Connecting a card when they have an existing bank account; or
      // - Connecting a card when they have an existing card
      shouldBlock = true;
    }

    if (shouldBlock) {
      this.confirm(
        "Please delete your existing funding source if you would like to add a new one",
        {
          title: "Only One Funding Source Per Account",
          cancelTitle: "Cancel",
          okTitle: "View Funding Sources",
          okOnly: true,
          modalClass: "delete-existing-source-modal",
        }
      ).then(() => {
        // Record an event. If this happens frequently then we should optimize this flow or
        // consider supporting more mutli-funding scenarios
        const type = fundingType || "";

        eventStore.actions.record({
          name: EVENTS.FUNDING_SOURCE.BLOCKED,
          data: {
            fundingType: type.charAt(0).toUpperCase() + type.slice(1),
            hasBank: !!bankList.length,
            hasCard: !!cardList.length,
          },
        });

        if (this.fullpage) {
          this.$router.push({
            name: "account",
          });
        } else {
          this.$emit("complete-step", OnboardingSteps.BLOCKED);
        }
      });
      return;
    }

    const nextState =
      fundingType === FundingSource.CARD
        ? OnboardingSteps.ADD_CARD
        : OnboardingSteps.ADD_BANK;
    this.$emit("complete-step", nextState);
  }
}
</script>

<style lang="scss" scoped>
::v-deep .form {
  width: 21.75rem;
}

.funding-type {
  text-align: center;
  opacity: 0;
  animation: show-step $duration-default;
  animation-timing-function: $ease-out-expo;
  animation-fill-mode: forwards;
  padding-top: 0;
  padding-bottom: 0;

  > h1 {
    all: unset;
    margin-bottom: 15px;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    color: $gray-800;
    line-height: 1.4;
  }

  .explainer {
    position: relative;

    .content {
      padding: 24px 32px;
      background: $gray-100;
      border-radius: $border-radius;
      color: $gray-600;

      ::v-deep svg {
        zoom: 180%;
        margin-bottom: 10px;
      }

      .title {
        margin-bottom: 5px;
        font-weight: 600;
        color: $gray-800;
      }
    }
  }

  > button {
    margin: 20px 0 15px !important;
  }

  .alt-funding-cta {
    hr {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    font-size: 12px;
    color: $gray-600;
  }
}
</style>
