<template>
  <SimplePage>
    <p>
      Please update to the latest version of the app or use the website to
      continue.
    </p>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class FundingLongTail extends Vue {}
</script>

<style lang="scss" scoped>
p {
  text-align: center;
}
</style>
