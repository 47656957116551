<template>
  <div class="dispute-reason" :class="{ 'dispute-modal': !fullPage }">
    <div class="content" v-if="state('')">
      <div class="header" v-if="!fullPage">
        <h3>Issue with this transaction?</h3>
        <SVGIcon
          icon="cross"
          class="close _mobile-only"
          @click="closeDisputeForm"
        />
      </div>

      <div
        class="flex-column flex-grow-1 justify-content-center"
        data-test="dispute-options"
      >
        <BaseButton
          block
          variant="primary"
          @click="go('purchase_type')"
          data-test="dispute-start"
          >Open Dispute</BaseButton
        >
        <BaseButton
          block
          variant="light"
          @click="closeDisputeForm"
          data-test="dispute-cancel"
          v-if="!fullPage"
          >Cancel</BaseButton
        >
      </div>
    </div>
    <div
      class="content"
      v-for="slide in visibleSlides"
      :key="'slide' + slide.state"
    >
      <div class="header">
        <h3>{{ slide.header }}</h3>
        <SVGIcon
          icon="cross"
          class="close _mobile-only"
          @click="closeDisputeForm"
        />
      </div>
      <p v-if="slide.text">{{ slide.text }}</p>
      <div class="flex-grow-1" v-if="slide.links">
        <a
          v-for="link in slide.links"
          :key="link.text"
          class="btn btn-block text-left"
          :class="link.class || 'btn-outline-primary'"
          @click="go(link.go, link)"
        >
          {{ link.text }}
        </a>
      </div>
      <div class="controls" v-if="slide.back">
        <BaseButton @click="back" variant="light"> Back </BaseButton>
      </div>
      <div class="controls" v-if="slide.ok">
        <BaseButton
          @click="closeDisputeForm"
          variant="primary"
          data-test="dispute-close"
        >
          OK
        </BaseButton>
      </div>
    </div>
    <div class="content" v-if="state('explain')">
      <h3>Explain the situation</h3>
      <p>
        Please provide a detailed description of the circumstances that have
        caused you to dispute this transaction. Go into as much detail as
        possible:
      </p>
      <b-textarea
        class="details"
        placeholder="Detail the reason for your chargeback."
        data-test="dispute-explain-text"
        v-model="disputeForm.details"
      />

      <div class="controls">
        <BaseButton @click="back" variant="light"> Back </BaseButton>
        <BaseButton
          @click="go('evidence')"
          variant="primary"
          data-test="dispute-explain-submit"
        >
          Next
        </BaseButton>
      </div>
    </div>
    <div class="content" v-if="state('evidence')">
      <h3>Relevant evidence</h3>
      <p>
        Attach any relevant evidence below regarding your dispute including:
        Screenshots of all correspondence with a visible timestamp; Copies of
        receipts with confirmation number and date of purchase; Any additional
        documents or photos relevant to the transaction.
        <em>
          Please note: Disputes with evidence attached have a higher rate of
          successful outcomes.
        </em>
      </p>
      <BaseFileUpload
        label="Evidence"
        class="file-drop drop-box"
        accept="image/*, application/pdf"
        description="Drop files into this box to upload"
        image="/assets/images/onboarding/drop-file-mouse-blue.svg"
        maxfiles="10"
        v-model="files"
      />
      <div class="controls">
        <BaseButton
          @click="back"
          :disabled="submittingEvidence"
          variant="light"
        >
          Back
        </BaseButton>
        <BaseButton
          @click="submitDisputeEvidence"
          :disabled="submittingEvidence"
          :loading="submittingEvidence"
          variant="primary"
          data-test="dispute-evidence-submit"
        >
          Next
        </BaseButton>
      </div>
    </div>
    <div class="content" v-if="state('sign')">
      <h3>Please Sign</h3>
      <p>
        By signing in the box below, you claim that the information you have
        provided in this dispute is true to the best of your knowledge:
      </p>
      <div class="signature-container" data-test="dispute-signature">
        <VueSignaturePad
          width="100%"
          height="200px"
          ref="signaturePad"
          :options="{ onEnd: onSignatureEnd }"
        />
        <BaseButton
          class="-clear"
          @click="clear"
          :disabled="submittingForm"
          variant="light"
        >
          Clear
        </BaseButton>
      </div>
      <div class="controls mt-auto">
        <BaseButton
          @click="
            clear();
            back();
          "
          :disabled="submittingForm"
          variant="light"
        >
          Back
        </BaseButton>
        <BaseButton
          @click="submitDisputeClaim"
          :disabled="!hasSignature || submittingForm"
          :loading="submittingForm"
          variant="primary"
          data-test="dispute-submit"
        >
          Submit Claim
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import result from "lodash/result";
import { Component, Mixins, Prop, Vue } from "vue-property-decorator";
import { Confirm } from "@/mixins/Confirm";
import { Toast } from "@/mixins/Toast";
import { Transaction } from "@/types/Transaction";
import { transactionStore } from "@/store";
import SVGIcon from "@/components/SVGIcon.vue";
import VueSignaturePad from "vue-signature-pad";
import { DisputeSlide, DisputeSlideLink } from "@/types/Dispute";

Vue.use(VueSignaturePad);

@Component({
  components: {
    SVGIcon,
  },
})
export default class DisputeReason extends Mixins(Confirm, Toast) {
  @Prop() transaction!: Transaction;
  @Prop() fullPage?: boolean;

  history: string[] = [];
  files: File[] = [];
  initialDisputeFormState = {
    answers: {},
    details: "",
  };
  disputeForm: any = this.initialDisputeFormState;
  submittingEvidence = false;
  submittingForm = false;
  hasSignature = false;

  slides: DisputeSlide[] = [
    {
      state: "purchase_type",
      header: "What kind of purchase was it?",
      links: [
        {
          go: "reason",
          text: "Goods (ex: clothing)",
        },
        {
          go: "reason",
          text: "Services (ex: Hulu)",
        },
        {
          go: "reason",
          text: "Unknown",
        },
      ],
    },
    {
      state: "reason",
      header: "Why are you disputing this purchase?",
      links: [
        {
          go: "merchant_contact",
          text: "I don't recognize this charge",
        },
        {
          go: "merchant_contact",
          text: "I didn't receive the merchandise or service",
        },
        {
          go: "merchant_contact",
          text: "The merchandise or service is defective or not as described",
        },
        {
          go: "merchant_contact",
          text: "I was charged for a cancelled subscription or membership",
        },
        {
          go: "merchant_contact",
          text: "I was charged for a cancelled purchase",
        },
        {
          go: "merchant_contact",
          text: "I was charged multiple times for the same transaction",
        },
        {
          go: "merchant_contact",
          text: "I was charged the wrong amount",
        },
        {
          go: "merchant_contact",
          text: "I paid for the merchandise or service using another payment method",
        },
        {
          go: "merchant_contact",
          text: "I returned the merchandise but have not receive a refund",
        },
        {
          go: "explain",
          text: "Other",
        },
      ],
    },
    {
      state: "merchant_contact",
      header: "Have you contacted the merchant?",
      text: "Many issues can be resolved quickly if you reach out to the merchant directly.",
      links: [
        { go: "explain", text: "Yes, I have contacted the merchant" },
        { go: "explain", text: "No, I have not contacted the merchant" },
      ],
      back: true,
    },
    {
      state: "success",
      header: "Dispute Submitted",
      text: "Your dispute has been submitted. Someone will be in touch in the next 3 business days.",
      ok: true,
    },
  ];

  get visibleSlides(): DisputeSlide[] {
    return this.slides.filter((slide) => this.state(slide.state));
  }

  onShow(): void {
    this.disputeForm = this.initialDisputeFormState;
    this.history = [];
    this.files = [];
  }

  onSignatureEnd(): void {
    this.hasSignature = !result(this, "$refs.signaturePad.isEmpty");
  }

  clear(): void {
    result(this, "$refs.signaturePad.clearSignature");
    this.hasSignature = false;
  }

  closeDisputeForm(): void {
    this.$emit("close-modal");
    if (this.fullPage) {
      this.$router.push({ name: "home" });
    }
  }

  resetDisputeForm(): void {
    this.files = [];
    this.disputeForm = Object.assign({}, this.initialDisputeFormState);
  }

  state(state: string): boolean {
    if (!state && !this.history.length) {
      return true;
    }

    return this.history[this.history.length - 1] === state;
  }

  getSlideForLink(link: DisputeSlideLink): DisputeSlide | undefined {
    const slide = this.slides.find((_slide) => {
      return (_slide.links || []).includes(link);
    });
    return slide;
  }

  go(state: string, from?: DisputeSlideLink): void {
    if (state === "close-modal") {
      this.closeDisputeForm();
    }

    if (state === "explain") {
      this.resetDisputeForm();
    }

    if (from) {
      const fromSlide = this.getSlideForLink(from);
      const question = fromSlide!.header;
      this.disputeForm.answers[question] = from.text;
    }

    if (state) {
      this.history.push(state);
    } else {
      this.resetDisputeForm();
      this.history = [];
    }
  }

  back(): void {
    this.history.pop();
  }

  submitDisputeEvidence(): boolean | void {
    if (!this.files || this.files.length === 0) {
      this.confirm(
        "Disputes with no attached evidence have a 21% success rate on average.",
        {
          title: "You haven't attached any evidence!",
          okTitle: "Continue Without Evidence",
          cancelTitle: "Add Evidence",
        }
      ).then((resp) => {
        if (resp) this.go("sign");
      });

      return false;
    }
    this.submittingEvidence = true;

    transactionStore.actions
      .submitDisputeEvidence({
        transactionID: this.transaction.transactionID,
        images: this.files,
      })
      .then(({ data }) => {
        this.disputeForm.evidenceImages = data.success || [];

        if (data.success.length) {
          this.successToast(
            `${data.success.length} files uploaded successfully`
          );
        }

        if (data.fail.length) {
          this.errorToast(
            `The following files failed to upload: ${data.fail.join(", ")}`
          );
        }

        this.go("sign");
      })
      .catch(({ data }) => {
        this.errorToast(
          (data && data.message) ||
            "Error uploading evidence. Please try again later."
        );
      })
      .finally(() => {
        this.submittingEvidence = false;
      });
  }

  submitDisputeClaim(): void {
    this.submittingForm = true;
    const { data } = result(this, "$refs.signaturePad.saveSignature") as any;
    const signatureData = { dataUrl: data };
    this.disputeForm.signatureImage = signatureData.dataUrl;
    transactionStore.actions
      .openDisputeWithEvidence({
        transactionID: this.transaction.transactionID,
        details: this.disputeForm,
      })
      .then(() => {
        this.go("success");
      })
      .catch(({ data: errData }) => {
        this.errorToast(
          (errData && errData.message) ||
            "Error submitting dispute. Please try again later."
        );
      })
      .finally(() => {
        this.submittingForm = false;
      });
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/mixins.scss";
$media-width-680: "only screen and (max-width: 680px)";

.dispute-modal {
  @media #{$media-phone} {
    @include absolute-cover;
    background: white;
    padding: 40px;
    height: 100%;
  }
}

.dispute-reason {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  flex-grow: 1;
  overflow: visible;

  @media #{$media-width-680} {
    width: unset;
  }

  > .close {
    position: absolute;
    top: 25px;
    right: 25px;
    height: 30px;
    width: 30px;
    z-index: 999;

    > svg * {
      fill: $gray-300;
    }
  }

  > .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    user-select: none;

    @media #{$media-phone} {
      width: unset;
      min-height: unset;
    }

    > .btn {
      margin-bottom: 10px;
    }

    > .controls {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 0.625rem;
    }

    > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      > h3 {
        line-height: 1.4;
        font-weight: 600;
        color: $gray-800;
        margin: 0;
      }
    }
  }

  > .content > .signature-container {
    margin: 20px auto 80px;
    height: 200px;
    width: 100%;
    background: url("/assets/images/home/signature-short.svg");
    background-repeat: no-repeat;
    background-position: bottom 20px left 20px;
    border: 2px dashed fade-out($gray-800, 0.9);

    > .-clear {
      margin-top: 0.625rem;
    }

    @media #{$media-phone} {
      max-width: 100%;
      margin: 20px auto 120px;
    }
  }

  > .content > .file-drop {
    flex-grow: 1;
    margin: 20px 0;
  }

  > .content > textarea.details {
    flex-grow: 20;
    height: 200px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-color: fade-out($gray-800, 0.9);
    border-style: solid;
    border-width: 1px 0;
    font-size: 16px;
    resize: none;
  }
}
</style>
