<template>
  <div class="helper -onboarding">
    <div class="progress-bar" v-if="!currentStepData.hideProgress">
      <div class="fill-bar" :style="{ width: progressWidth }"></div>
    </div>
    <div class="title next-step" v-if="!currentStepData.hideProgress">
      Next step...
    </div>
    <div class="title">{{ currentStepData.title }}</div>
    <div class="description">
      {{ currentStepData.description }}
    </div>
    <div
      class="button-wrap"
      v-if="currentStepData.actionText || currentStepData.secondaryActionText"
    >
      <BaseButton
        variant="primary"
        v-if="currentStepData.actionText"
        @click="onClickAction"
      >
        {{ currentStepData.actionText }}
      </BaseButton>
      <div class="spacer"></div>
      <b-link
        v-if="currentStepData.secondaryActionText && !loading && !success"
        @click="onClickSecondaryAction"
      >
        {{ currentStepData.secondaryActionText }}
      </b-link>
      <p v-if="currentStepData.secondaryActionText && loading && !success">
        <b-spinner small />
      </p>
      <p
        class="-success"
        v-if="currentStepData.secondaryActionText && !loading && success"
      >
        Confirmation sent!
      </p>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { cardStore, userStore } from "@/store";
import {
  OnboardingSteps,
  HelperSteps,
  HelperScreens,
} from "@/types/Onboarding";
import { Toast } from "@/mixins/Toast";

const SSN_STEPS = [
  OnboardingSteps.VERIFY_SSN,
  OnboardingSteps.EXISTING_USER_VERIFY_SSN,
];

const FUNDING_STEPS = [
  OnboardingSteps.FUNDING_TYPE,
  OnboardingSteps.FUNDING_TYPE_CHARGE,
  OnboardingSteps.FUNDING_TYPE_BUSINESS,
  OnboardingSteps.ADD_CARD,
  OnboardingSteps.CONFIRM_CARD,
  OnboardingSteps.ADD_BANK,
  OnboardingSteps.CONFIRM_BANK,
];

const TERMS_STEPS = [
  OnboardingSteps.CHARGE_DISCLOSURE,
  OnboardingSteps.LEGACY_DISCLOSURES,
  OnboardingSteps.EXISTING_USER_CHARGE_DISCLOSURE,
];

const SUBSCRIPTION_STEPS = [
  OnboardingSteps.DEPRECATED_BUSINESS_INFO,
  OnboardingSteps.DEPRECATED_BUSINESS_INFO_ABOUT,
  OnboardingSteps.DEPRECATED_BUSINESS_INFO_LEGAL,
  OnboardingSteps.DEPRECATED_BUSINESS_OWNERS,
  OnboardingSteps.BILLING,
];

@Component
export default class HelperOnboarding extends Mixins(Toast) {
  loading = false;
  success = false;

  helperSteps: HelperScreens = {
    [HelperSteps.COMPLETE_PROFILE]: {
      title: "Complete your profile",
      actionText: "Complete Profile",
    },
    [HelperSteps.PHONE_CONFIRM]: {
      title: "Confirm your phone number",
      actionText: "Confirm Phone",
    },
    [HelperSteps.VERIFY_SSN]: {
      title: "Verify your SSN",
      actionText: "Verify SSN",
    },
    [HelperSteps.CONNECT_FUNDING]: {
      title: "Connect a funding source!",
      actionText: "Connect Funding Source",
    },
    [HelperSteps.ACCEPT_TERMS]: {
      title: "Agree to our terms",
      actionText: "View Terms",
    },
    [HelperSteps.CONFIRM_EMAIL]: {
      title: "Confirm your email address",
      description: "We've sent you a confirmation link.",
      secondaryActionText: "Resend email",
      secondaryActionFn: () => {
        this.resendConfirmationEmail();
      },
    },
    [HelperSteps.COMPLETE_SUBSCRIPTION]: {
      title: "Complete your subscription to Privacy", // TODO show plan name
      actionText: "Complete Subscription",
      secondaryActionText: "Change Plan",
      secondaryActionFn: () => {
        this.$router.push({ name: "subscription-plan" });
      },
    },
    [HelperSteps.ADDITIONAL_VERIFICATION]: {
      hideProgress: true,
      title: "We're reviewing your account",
      description:
        "Your account has been paused while a member of our team reviews it. This usually takes less than 48 hours. We'll be in touch soon!",
    },
    [HelperSteps.BUSINESS_REVIEW]: {
      hideProgress: true,
      title: "We're reviewing your account",
      description:
        "Your account needs to be reviewed by a member of our team before you can access business features. This step usually takes less than 48 hours. We'll be in touch soon!",
    },
    [HelperSteps.CREATE_CARD]: {
      title: "Create your first card!",
      actionText: "Create a Card",
      actionFn: () => {
        this.$emit("create-card");
      },
    },
    [HelperSteps.DO_TRANSACTION]: {
      title: "Make First Transaction",
    },
  };

  get user() {
    return userStore.getters.currentUser;
  }

  get progressWidth() {
    const completionFraction =
      this.currentStep / Object.keys(this.helperSteps).length;
    return `${completionFraction * 100}%`;
  }

  get currentStep() {
    const onboardingStep = userStore.getters.nextOnboardingStep();

    if (onboardingStep !== OnboardingSteps.COMPLETE) {
      if (onboardingStep === OnboardingSteps.PHONE_CONFIRM) {
        return HelperSteps.PHONE_CONFIRM;
      }

      if (SSN_STEPS.includes(onboardingStep)) {
        return HelperSteps.VERIFY_SSN;
      }

      if (FUNDING_STEPS.includes(onboardingStep)) {
        return HelperSteps.CONNECT_FUNDING;
      }

      if (TERMS_STEPS.includes(onboardingStep)) {
        return HelperSteps.ACCEPT_TERMS;
      }

      if (SUBSCRIPTION_STEPS.includes(onboardingStep)) {
        return HelperSteps.COMPLETE_SUBSCRIPTION;
      }

      return HelperSteps.COMPLETE_PROFILE;
    }

    if (!this.user?.emailConfirmed) {
      return HelperSteps.CONFIRM_EMAIL;
    }

    if (this.user?.verificationNeeded && !this.user?.isRemediatingKYC) {
      return HelperSteps.ADDITIONAL_VERIFICATION;
    }

    if (this.user?.businessReviewNeeded) {
      return HelperSteps.BUSINESS_REVIEW;
    }

    if (cardStore.getters.cardList().all.length === 0) {
      return HelperSteps.CREATE_CARD;
    }

    return HelperSteps.DO_TRANSACTION;
  }

  get currentStepData() {
    return this.helperSteps[this.currentStep];
  }

  onClickAction() {
    if (this.currentStepData?.actionFn) {
      this.currentStepData.actionFn();
    } else {
      this.$emit("start-onboarding");
    }
  }

  onClickSecondaryAction() {
    if (this.currentStepData?.secondaryActionFn) {
      this.currentStepData.secondaryActionFn();
    }
  }

  resendConfirmationEmail() {
    this.loading = true;

    userStore.actions
      .resendConfirmationEmail()
      .then(() => {
        this.loading = false;
        this.success = true;
      })
      .catch(() => {
        this.loading = false;
        this.errorToast(
          "There was an error re-sending the confirmation email. Please contact support@privacy.com"
        );
      });
  }
}
</script>
<style lang="scss" scoped>
.helper.-onboarding {
  position: relative;

  .title {
    line-height: 22px;
    font-family: $font-stack-wes-fy;
    font-size: 20px;
  }

  .next-step {
    margin-top: 20px;
    margin-bottom: 5px;
    color: $gray-400;
    opacity: 0.75;
  }

  .description {
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    font-family: $font-stack-lato;
    font-size: 14px;
    color: $gray-600;
    margin-top: 22px;
  }

  .button-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: auto;
    width: 100%;

    .spacer {
      flex-grow: 1;
    }

    p {
      margin-bottom: 0;
    }

    .-success {
      color: $color-green;
    }
  }

  .progress-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 6px;
    width: 100%;
    background-color: $gray-100;
    border-radius: 6px;
    overflow: visible;

    .fill-bar {
      position: relative;
      display: inline-block;
      height: 6px;
      width: 0%;
      min-width: 12px;
      background-color: $blue;
      border-radius: 6px;
      transition: width 250ms linear;
    }

    .fill-bar::after {
      content: "";
      position: absolute;
      right: 0;
      display: inline-block;
      box-sizing: content-box;
      margin: -7px 0 0 -12px;
      height: 12px;
      width: 12px;
      background-color: inherit;
      box-shadow: $box-shadow-default;
      border: 4px solid $white;
      border-radius: 12px;
    }
  }
}
</style>
