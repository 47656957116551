<template>
  <div class="radial-meter">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      enable-background="new 0 0 40 40"
      xml:space="preserve"
      class="track-container"
    >
      <circle
        fill="transparent"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        cx="20"
        cy="20"
        r="18"
        class="track"
      ></circle>
    </svg>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      enable-background="new 0 0 40 40"
      xml:space="preserve"
      class="fill-container"
    >
      <circle
        :class="{ '-empty': empty }"
        fill="transparent"
        stroke-width="3"
        stroke-miterlimit="10"
        :style="{
          'stroke-dashoffset': strokeDashoffset,
          'stroke-dasharray': strokeDasharray,
        }"
        stroke-linecap="round"
        cx="20"
        cy="20"
        r="18"
        class="fill"
      ></circle>
    </svg>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class RadialMeter extends Vue {
  @Prop({ required: true }) fillvalue!: number;
  @Prop({ required: true }) fillrange!: number;

  strokeDasharray = 114;

  get percent() {
    return this.fillvalue / this.fillrange;
  }

  get strokeDashoffset() {
    return Math.max(0, 114 - 114 * (this.percent / 100));
  }

  get empty(): boolean {
    return this.percent === 0;
  }
}
</script>
<style lang="scss" scoped>
.radial-meter {
  display: block;
  transform: rotate(-90deg);

  .track-container {
    position: absolute;
  }

  .track {
    stroke: $gray-100;
  }

  .fill-container {
    position: relative;
  }

  .fill {
    stroke: $gray-800;

    &.-empty {
      stroke: transparent;
    }
  }
}
</style>
