<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :fullpage="fullpage"
      @submit="onSubmit"
      :class="['change-name-form', 'signup-step']"
      data-test="change-name-form"
    >
      <h1>Change Your Preferred Name</h1>
      <BaseInput
        label="Preferred Name"
        v-model="newPreferredName"
        max-length="128"
        :disabled="loading"
        data-test="change-name-input"
      >
      </BaseInput>
      <BaseButton
        type="submit"
        :variant="validationErrorMessage ? 'invalid' : 'primary'"
        size="lg"
        v-if="!success"
        :disabled="loading || validationErrorMessage"
        data-test="change-name-submit-button"
      >
        Change Preferred Name
      </BaseButton>
      <BaseButton
        type="submit"
        variant="success"
        size="lg"
        v-if="success"
        :disabled="true"
        data-test="change-name-success"
      >
        Success!
        <span v-if="fullpage">&nbsp;Redirecting...</span>
      </BaseButton>
      <div class="change-name-error">
        <b-alert variant="danger" v-if="validationErrorMessage" show>
          {{ validationErrorMessage }}
        </b-alert>
        <b-alert variant="danger" v-if="errorMessage" show>
          {{ errorMessage }}
        </b-alert>
      </div>
    </BaseForm>
  </SimplePage>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { userStore } from "@/store";

const SUCCESS_MESSAGE_DURATION = 1000;

@Component
export default class ChangePreferredNameForm extends Vue {
  @Prop({ default: false }) fullpage!: boolean;
  @Prop({ default: "" }) preferredName!: string;
  @Prop() modalId!: string;
  errorMessage = "";
  newPreferredName = "";
  success = false;
  loading = false;
  validationErrorMessage = "";

  mounted() {
    this.newPreferredName = this.preferredName;
  }

  @Watch("newPreferredName", { immediate: true })
  resetValidationErrorState(newVal: string) {
    if (newVal.match(/\s+/)) {
      this.validationErrorMessage = "Preferred name should be a single word";
    } else {
      this.validationErrorMessage = "";
    }
  }

  async onSubmit() {
    const user = userStore.getters.currentUser;

    if (!user) {
      this.errorMessage = "Please log in first";
      return;
    }

    this.loading = true;

    try {
      await userStore.actions.updateUser({
        preferredFirstName: this.newPreferredName,
      });
      this.success = true;
      this.loading = false;

      if (this.fullpage) {
        setTimeout(() => {
          this.$router.push({
            name: "home",
          });
        }, SUCCESS_MESSAGE_DURATION);
      } else if (this.modalId) {
        setTimeout(() => {
          this.$bvModal.hide(this.modalId);
        }, SUCCESS_MESSAGE_DURATION);
      }
    } catch (err) {
      this.errorMessage =
        "We couldn't change your preferred name. Please try again later.";
      this.loading = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.signup-step.change-name-form {
  h1 {
    all: unset;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    margin-bottom: 15px;
    line-height: 1.4;
    color: $gray-800;
  }

  .change-name-error {
    padding-top: 10px;
  }
}
</style>
