<template>
  <SimplePage :fullpage="fullpage" class="update-bank-link">
    <h1>Refresh Bank Connection</h1>
    <div class="loading-text">
      <div>
        Attempting to reconnect your {{ bankAccount.bankName }} account...
      </div>
      <b-spinner class="mx-auto my-4" v-show="!errorMessage" />
    </div>

    <b-alert
      :show="errorMessage.length > 0"
      variant="danger"
      data-test="update-bank-link-error"
    >
      {{ errorMessage }}
    </b-alert>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Plaid } from "plaid-link";
import ldGet from "lodash/get";
import { loadScriptAsync } from "@/util";
import { bankStore, eventStore } from "@/store";
import { FundingBank } from "@/types/Funding";

@Component({})
export default class UpdateBankLink extends Vue {
  @Prop() bankAccount!: FundingBank;
  @Prop({ default: false }) fullpage!: boolean;

  errorMessage = "";
  plaidHandler: Plaid.LinkHandler | null = null;

  created() {
    // Add the Plaid Link initialize script (if it doesn't already exist)
    if (!document.getElementById("plaid-link")) {
      loadScriptAsync(
        "plaid-link",
        "https://cdn.plaid.com/link/v2/stable/link-initialize.js"
      );
    }

    // Wait until our dynamically added Plaid initializer <script> has loaded. Once loaded call
    // Plaid.create() on it, this will take another few moments to load before we can call
    // plaidHandler.open() on it.
    const _plaidInitializeInterval = window.setInterval(() => {
      if (typeof window.Plaid?.create === "function") {
        window.clearInterval(_plaidInitializeInterval);
        this.setUpPlaid();
      }
    }, 250);
  }

  async setUpPlaid() {
    const linkToken = this.$cookies.get("plaidLinkToken");

    const _setUpPlaid = (token: string, receivedRedirectUri?: string) => {
      this.plaidHandler = window.Plaid?.create({
        token,
        receivedRedirectUri,

        onLoad: async () => {
          this.plaidHandler?.open();
        },

        onSuccess: async () => {
          try {
            await bankStore.actions.refreshBankToken(this.bankAccount.uuid);
            this.$emit("close", this.bankAccount);
          } catch (err) {
            this.errorMessage =
              "We were unable to refresh your bank account. Please try again later.";
          }
        },
        onExit: (err: Error | null, metadata: Plaid.OnExitMetaData) => {
          this.$emit("close");

          // Log this server side
          if (err) {
            eventStore.actions.error({
              type: "plaid",
              metadata,
              err,
            });
          } else {
            eventStore.actions.info({
              type: "plaid",
              metadata,
            });
          }
        },
        onEvent: (eventName: string, metadata: Plaid.OnEventMetaData) => {
          eventStore.actions.info({
            type: "plaid",
            eventName,
            metadata,
          });
        },
      } as unknown as Plaid.CreateConfig);
    };

    // If user is returning to the page after completing OAuth flow,
    // initialize Plaid link flow using the existing token
    if (linkToken) {
      const { href } = window.location;
      const receivedRedirectUri = href.includes("oauth_state_id")
        ? href
        : undefined;
      _setUpPlaid(linkToken, receivedRedirectUri);
    } else {
      let response: any;

      try {
        response = await bankStore.actions.createUpdateLinkToken(
          this.bankAccount.uuid
        );
        const token = ldGet<any, string>(response, "data.link_token");
        this.$cookies.set("plaidLinkToken", token);
        _setUpPlaid(token);
      } catch (err) {
        const error = err as any;

        if (error.response?.status === 415) {
          this.errorMessage = error.response?.data?.message;
          return;
        }

        this.errorMessage =
          "We're having trouble connecting to the bank network right now, please try again later.";
      }
    }
  }

  beforeDestroy() {
    this.$cookies.remove("plaidLinkToken");
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/animations.scss";
@import "../../assets/styles/mixins.scss";

.update-bank-link {
  width: 21.75rem; // match BaseForm width
  margin: auto;
  padding-top: 1.25rem;
  max-width: unset;
  @media #{$media-phone} {
    max-width: 100%;
  }
}

h1,
::v-deep .modal-body h1 {
  font-family: $font-stack-wes-fy;
  font-size: 24px;
  text-align: center;
  margin-bottom: 15px;
  line-height: 1.4;
  color: $gray-800;
}

.blurb {
  text-align: center;
}
.loading-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  text-align: center;
}
</style>
