<template>
  <BaseModal
    id="category-card-create-modal"
    size="lg"
    hide-header
    modal-class="category-card-create-modal"
    data-test="category-card-create"
    @show="handleShow"
  >
    <h3 class="header">Select a Category</h3>
    <b-tabs content-class="mt-3" nav-class="tabs" nav-wrapper-class="tabs">
      <p class="descriptor">
        <span class="bolder"
          >These categories are pre-locked to a specific set of merchant
          types.</span
        >
        If a merchant does not fall into one of these predefined categories,
        transactions made there will not be successful.
      </p>
      <b-tab title="Popular Categories" active>
        <CategorySelector
          :categories="defaultCategories"
          :selectCategory="selectCategory"
          :selectedCategory="selectedCategory"
        />
      </b-tab>
      <b-tab title="Other">
        <CategorySelector
          :categories="otherCategories"
          :selectCategory="selectCategory"
          :selectedCategory="selectedCategory"
        />
      </b-tab>
    </b-tabs>

    <div class="d-flex flex-row-reverse gap-1">
      <BaseButton
        class="button -next"
        type="submit"
        variant="primary"
        data-test="select-category"
        @click="select"
        :disabled="!selectedCategory"
      >
        Next
      </BaseButton>
      <BaseButton
        class="button -back"
        variant="transparent"
        data-test="back-button"
        @click="handleBack"
      >
        Back
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import { Component, Emit, Vue } from "vue-property-decorator";
import CategorySelector from "@/components/card/CategorySelector.vue";
import { MerchantCategory } from "@/types/MerchantCategory";
import { merchantCategoriesStore } from "@/store";

@Component({
  components: {
    CategorySelector,
  },
})
export default class SelectCardTypeModal extends Vue {
  selectedCategory: MerchantCategory | null = null;

  handleShow() {
    this.selectedCategory = null;
  }

  handleBack() {
    this.$emit("back");
  }

  @Emit()
  select(): MerchantCategory {
    return this.selectedCategory!;
  }

  selectCategory(category: MerchantCategory | null = null) {
    this.selectedCategory = category;
  }

  get defaultCategories() {
    return merchantCategoriesStore.getters.defaults;
  }

  get otherCategories() {
    return merchantCategoriesStore.getters.others;
  }
}
</script>

<style lang="scss" scoped>
.category-card-create-modal {
  @media #{$media-phone} {
    transition: none !important;

    .modal-dialog {
      transition: none !important;
    }
  }
}
.descriptor {
  font-family: $font-stack-graphik;
}
.bolder {
  font-weight: 600;
}
.header {
  font-family: $font-stack-fk-grotesk;
  padding-top: 32px;
}
.button {
  margin: 0 4px;
  &.-next {
    padding: 0 52px;
    background-color: $brand-purple;
  }
  &.-back {
    background-color: #f5f7f8;
  }
}
.categories {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}
.category-item {
  background-color: #f5f7f8;
  font-family: $font-stack-graphik;
  overflow: hidden;
  flex-basis: 340px;
  &.-active {
    background-color: $brand-purple-10;
  }
  cursor: pointer;
}
.category-item-body {
  padding: 12px 8px;
  p {
    margin-bottom: 0;
  }
}
::v-deep .nav-tabs {
  border-bottom: 0px;
}
::v-deep .nav-tabs .nav-link {
  border: 0px;
  color: $foreground-default;
  font-family: $font-stack-graphik;
  font-weight: 600;
  &.active {
    color: $brand-purple;
    border-bottom: 3px solid $brand-purple;
  }
}
</style>
