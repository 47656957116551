<template>
  <div class="account-summary">
    <div class="spinner-container" v-if="loading">
      <b-spinner></b-spinner>
    </div>
    <div class="account-summary-column" v-if="!loading">
      <div class="box -spend">
        <h4 class="title">Spend</h4>
        <div class="content">
          <div class="item" v-if="user && !user.isLimitWeekly">
            <div class="label">Today</div>
            <DollarAmount :amount="metricList.dayTotal"></DollarAmount>
          </div>
          <div class="item" v-if="user && user.isLimitWeekly">
            <div class="label">Last 7 Days</div>
            <DollarAmount :amount="metricList.weekTotal"></DollarAmount>
          </div>
          <div class="item" v-if="user && !user.isLimitWeekly">
            <div class="label">This Month</div>
            <DollarAmount :amount="metricList.monthTotal"></DollarAmount>
          </div>
        </div>
      </div>
      <div class="box -cashback-earned">
        <h4 class="title">Cashback Earned</h4>
        <div class="content">
          <div class="item">
            <DollarAmount
              :amount="metricList.totalPendingCashback"
            ></DollarAmount>
          </div>
        </div>
      </div>
      <div class="box -privacy-credits">
        <h4 class="title">Promo Credits</h4>
        <div class="content">
          <div class="item">
            <DollarAmount
              :amount="(user && user.promoCredits.amount) || 0"
            ></DollarAmount>
          </div>
        </div>
      </div>
      <div class="_container -open-cards">
        <h4 class="title">Cards</h4>
        <CardGraphList
          :current-card="currentCard"
          :first-date="range.start"
          :last-date="range.end"
          :cards="cardList.all"
          :transactions="transactionList.all"
          :limit="cardList.all.length > 9 ? 8 : 9"
          @click-card="onClickCard"
        >
        </CardGraphList>
      </div>
      <div class="_container -spent-history">
        <div class="select-date">
          <v-date-picker
            v-model="range"
            is-range
            locale="en-US"
            :max-date="new Date()"
            class="datepicker"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <BaseInput
                class="date-picker-button"
                label="Start Date"
                type="text"
                :value="formatDate(inputValue.start)"
                readonly
                v-on="inputEvents.start"
              />
              <BaseInput
                class="date-picker-button"
                label="End Date"
                type="text"
                :value="formatDate(inputValue.end)"
                readonly
                v-on="inputEvents.end"
              />
            </template>
          </v-date-picker>
          <div
            class="selected-card"
            v-if="currentCard"
            @click="onClickCard(null)"
            :style="{
              'background-color':
                currentCard.style && currentCard.style.bgColor
                  ? '#' + currentCard.style.bgColor
                  : 'auto',
            }"
          >
            {{
              currentCard.memo || currentCard.hostname || currentCard.lastFour
            }}
            <SVGIcon icon="cross"></SVGIcon>
          </div>
        </div>
        <SpendingGraph
          :transactions="transactionList.all"
          :first-date="range.start"
          :last-date="range.end"
          :current-card="currentCard"
        >
        </SpendingGraph>
        <TransactionListComponent
          :transactionsProp="transactionsToDisplay"
          @click-transaction="inspectTransaction"
          :limit="transactionLimit"
        >
        </TransactionListComponent>
        <div class="pagination-controls">
          <BaseButton
            variant="light"
            @click="onClickShowMore"
            v-if="filteredTransactions.length > transactionLimit"
          >
            Show More
          </BaseButton>
          <BaseButton
            variant="light"
            @click="onClickShowFewer"
            v-if="transactionLimit > INITIAL_TRANSACTION_LIMIT"
          >
            Show Fewer
          </BaseButton>
        </div>
      </div>
    </div>
    <CardInspector
      id="card-inspector"
      :transactionProp="currentTransaction"
      :cardProp="modalCard"
      @switch="switchCurrentCard"
    ></CardInspector>
  </div>
</template>
<script lang="ts">
import ldGet from "lodash/get";
import { formatDate, getDate } from "@/lib/dates";
import { cardStore, metricsStore, transactionStore, userStore } from "@/store";
import { Component, Mixins } from "vue-property-decorator";
import { Transaction, TransactionList } from "@/types/Transaction";
import { Card, CardList } from "@/types/Card";
import { MetricList } from "@/types/Metrics";
import { Toast } from "../mixins/Toast";
import DollarAmount from "../components/DollarAmount.vue";
import SpendingGraph from "../components/SpendingGraph.vue";
import CardGraphList from "../components/CardGraphList.vue";
import CardInspector from "../components/CardInspector.vue";
import TransactionListComponent from "../components/TransactionList.vue";
import SVGIcon from "../components/SVGIcon.vue";

const INITIAL_TRANSACTION_LIMIT = 6;

@Component({
  components: {
    DollarAmount,
    SpendingGraph,
    CardGraphList,
    TransactionListComponent,
    CardInspector,
    SVGIcon,
  },
})
export default class AccountSummary extends Mixins(Toast) {
  loading = true;
  metricList: MetricList = {
    dayTotal: 0,
    weekTotal: 0,
    monthTotal: 0,
    pendingTotal: 0,
    totalPendingCashback: 0,
    totalCashback: 0,
  };
  currentTransaction: Transaction | null = null;
  modalCard: Card | null = null;
  transactionLimit = INITIAL_TRANSACTION_LIMIT;
  transactionList: TransactionList = {
    all: [],
    declines: [],
  };
  cardList: CardList = {
    all: [],
    open: [],
    closed: [],
  };
  range: { start: Date | null; end: Date | null } = {
    start: getDate().subtract(30, "days").toDate(),
    end: new Date(),
  };
  currentCard: Card | null = null;

  INITIAL_TRANSACTION_LIMIT = INITIAL_TRANSACTION_LIMIT;

  get user() {
    return userStore.getters.currentUser;
  }

  get filteredTransactions(): Transaction[] {
    const filterFirstDate = getDate(this.range.start).startOf("days");
    const filterLastDate = getDate(this.range.end).endOf("days");

    return ldGet<any, string, Transaction[]>(
      this,
      "transactionList.all",
      []
    ).filter((t: Transaction) => {
      if (!t.dateAuthorized && !t.dateSettled) {
        return false;
      }
      if (
        this.currentCard &&
        String(t.cardID) !== String(this.currentCard.cardID)
      ) {
        return false;
      }

      const dateAuthorized = getDate(t.dateAuthorized || t.dateSettled);
      return (
        filterFirstDate.isSameOrBefore(dateAuthorized) &&
        filterLastDate.isSameOrAfter(dateAuthorized)
      );
    });
  }

  get transactionsToDisplay(): Transaction[] {
    return this.filteredTransactions.slice(0, this.transactionLimit);
  }

  mounted() {
    Promise.all([
      cardStore.actions.getCards({}),
      transactionStore.actions.fetchTransactions({}),
      metricsStore.actions.getMetrics(),
    ]).then(([cardList, transactionList, metricList]) => {
      this.cardList = cardList;
      this.transactionList = transactionList;
      this.metricList = { ...this.metricList, ...metricList };
      this.loading = false;
    });
  }

  formatDate(date: Date) {
    return formatDate("MMM D, YYYY", date);
  }

  onClickCard(card: any) {
    this.currentCard = (card as Card) || null;
  }

  inspectTransaction(transaction: Transaction) {
    this.currentTransaction = transaction;
    this.$bvModal.show("card-inspector");
  }

  onClickShowMore() {
    this.transactionLimit += INITIAL_TRANSACTION_LIMIT;
  }

  onClickShowFewer() {
    this.transactionLimit = INITIAL_TRANSACTION_LIMIT;
  }

  switchCurrentCard(cardID: number): void {
    const oldCard = cardStore.getters.getCard(cardID);
    if (oldCard) {
      this.modalCard = oldCard;
      this.currentTransaction = null;
    }
  }
}
</script>
<style lang="scss" scoped>
.account-summary {
  padding-top: 80px;

  > .spinner-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > .account-summary-column {
    display: grid;
    margin: 0 auto;
    padding: 20px;
    width: 100%;
    max-width: 1220px;
    grid-template-rows: min-content;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
  }

  .-spent-amount {
    grid-area: 1 / 1 / 2 / 2;
  }
  .-cashback-earned {
    grid-area: 1 / 2 / 2 / 3;
  }
  .-privacy-credits {
    grid-area: 1 / 3 / 2 / 4;
  }
  .-open-cards {
    grid-area: 2 / 1 / 3 / 2;
  }
  .-spent-history {
    grid-area: 2 / 2 / 3 / 4;
  }

  & .title {
    font-family: $font-stack-wes-fy;
    font-size: 16px;
    font-weight: 900;
  }

  & .box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    height: 180px;
    border-radius: $radius-large;

    > .content {
      display: flex;
      font-family: $font-stack-lato;

      > .item {
        width: 50%;
      }

      > .item > .label {
        margin-bottom: 16px;
        font-family: $font-stack-lato;
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
        white-space: nowrap;
        color: $gray-600;
      }

      > .item > .dollar-amount {
        justify-content: flex-start;
        font-size: 20px;
        font-weight: bold;
        text-align: left;

        > * {
          color: #26334d;
        }

        &:only-child {
          font-size: 48px;
        }
      }
    }

    &.-spend {
      background-color: $gray-100;

      > .title {
        margin-bottom: 0;
      }
    }

    &.-cashback-earned {
      background-color: fade-out($pastel-yellow, 0.9);

      > .title {
        color: mix($pastel-yellow, $gray-800, 50%);
        margin-bottom: 0;
      }

      ::v-deep .dollar-amount * {
        font-weight: 400;
        color: $pastel-yellow;
      }
    }

    &.-privacy-credits {
      background-color: fade-out($color-green, 0.9);

      > .title {
        color: mix($color-green, $gray-800, 50%);
        margin-bottom: 0;
      }

      ::v-deep .dollar-amount * {
        font-weight: 400;
        color: $color-green;
      }
    }
  }

  & ._container {
    min-width: 0;

    &.-open-cards {
      justify-content: flex-start;
      margin: 0 0 20px;

      > .title {
        margin-bottom: 24px;
      }
    }

    &.-spent-history {
      position: relative;
      justify-content: flex-start;
      margin-top: 37px;
      padding: 0;

      > .select-date {
        position: absolute;
        z-index: 1;
        top: -16px;
        left: 10px;

        > .datepicker {
          display: flex;

          .field {
            padding: 0;
            margin-bottom: 0px;
            display: inline-flex;
            width: 125px;

            &:first-of-type {
              margin-right: 20px;
            }
          }

          ::v-deep .title {
            display: none;
          }

          ::v-deep input {
            padding-top: 1rem;
            background-color: $gray-800;
            color: $white;
          }
        }

        > .selected-card {
          position: absolute;
          z-index: 1;
          left: 270px;
          margin-left: 10px;
          padding: 0 24px 0 8px;
          height: 33px;
          background: $gray-800;
          box-shadow: $box-shadow-default-faint;
          border-radius: 0.5rem;
          font-size: 14px;
          line-height: 31px;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: $white;
          transition-property: opacity, color, filter;
          transition-duration: 125ms;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }

          ::v-deep .svg-icon {
            position: absolute;
            top: 6px;
            right: 4px;
            transform: scale(0.75);

            & path {
              fill: $white;
            }
          }
        }
      }

      > .spending-graph {
        height: 200px;
      }

      > .transaction-list {
        padding-top: 10px;
        width: 100%;

        ::v-deep .transaction {
          padding: 15px 10px;
        }

        @media #{$media-width-900} {
          ::v-deep .transaction > .left,
          ::v-deep .transaction > .right {
            flex-direction: column-reverse;
            align-items: flex-start;
            justify-content: space-between;
            height: 50px;
          }

          ::v-deep .transaction > .right {
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;
          }

          ::v-deep .transaction > .left > .descriptor {
            flex-grow: 0;
          }
        }
      }

      > .pagination-controls {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        margin: 10px 0 0;
        width: 100%;

        > .btn {
          flex-grow: 1;

          & + .btn {
            margin-left: 10px;
          }
        }
      }
    }
  }

  @media #{$media-width-900} {
    > .account-summary-column {
      grid-template-columns: repeat(6, 1fr);
    }

    .-spend {
      grid-area: 1 / 1 / 2 / 7;
    }
    .-cashback-earned {
      grid-area: 2 / 1 / 3 / 4;
    }
    .-privacy-credits {
      grid-area: 2 / 4 / 3 / 7;
    }
    .-open-cards {
      grid-area: 3 / 1 / 4 / 4;
    }
    .-spent-history {
      grid-area: 3 / 4 / 4 / 7;
    }

    & .box.-spend {
      flex-direction: row;
      align-items: center;
      height: 130px;

      .item:last-child {
        margin-left: 60px;
      }

      & .dollar-amount {
        font-size: 34px;
      }
    }
  }

  @media #{$media-phone} {
    > .account-summary-column {
      padding-top: 0;
      grid-template-columns: repeat(1, 1fr);
    }

    .-open-cards .title,
    .-spent-history .transaction-list,
    .-spent-history .pagination-controls,
    .-spent-history .datepicker {
      display: none;
    }

    & .box {
      flex-direction: row;
      align-items: center;
      padding: 20px;
      height: 90px;

      & .dollar-amount {
        font-size: 34px;
      }

      &.-spend {
        .item {
          margin-left: 40px;
        }

        .value > .dollar-amount {
          font-size: 20px;
        }
      }
    }

    .-spend {
      grid-area: 1 / 1 / 2 / 2;
    }
    .-cashback-earned {
      grid-area: 2 / 1 / 3 / 2;
    }
    .-privacy-credits {
      grid-area: 3 / 1 / 4 / 2;
    }
    .-open-cards {
      grid-area: 5 / 1 / 6 / 2;
    }
    .-spent-history {
      grid-area: 4 / 1 / 5 / 2;
    }
  }
}
</style>
