<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      @submit="submit"
      :class="['signup-phone-confirm', { '-loading': waiting }]"
      data-test="phone-confirm-form"
    >
      <h1>Confirm Phone</h1>
      <p class="blurb">
        We sent a confirmation code to (***) ***-{{ userPhoneLastFour }}.
        <br />Can't find it?
        <b-link
          :class="[{ '-disabled': codeSent }]"
          @click="resendCode"
          aria-label="resend confirmation code"
        >
          Resend it
        </b-link>
        or
        <b-link
          href="#"
          @click="showChangeNumber"
          class="change-number-button"
          aria-label="change your phone number"
          >change your number.</b-link
        >
      </p>

      <b-alert variant="danger" :show="errorMessage.length > 0">
        {{ errorMessage }}
      </b-alert>

      <BaseInput
        ref="confirmPhoneInput"
        name="code"
        label="Confirmation Code"
        placeholder="00000"
        v-model="code"
        type="text"
        maxlength="5"
        :autofocus="true"
        autocomplete="one-time-code"
        :state="codeError ? false : null"
        data-test="phone-confirm-field"
      />

      <b-spinner v-if="waiting && !fullpage" class="mx-auto mt-4"></b-spinner>

      <BaseButton
        v-if="fullpage"
        variant="primary"
        size="lg"
        type="submit"
        @click="trackClick"
        :loading="waiting"
        data-test="phone-confirm-button"
      >
        Confirm Phone
      </BaseButton>
      <OnboardingFooterActions
        :allowChangeAccountType="true"
        @footer-action="onFooterAction"
      />
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Watch, Vue } from "vue-property-decorator";
import { Toast } from "@/mixins/Toast";
import { userStore } from "@/store";
import { User } from "@/types/User";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";
import OnboardingFooterActions from "@/components/OnboardingFooterActions.vue";

@Component({
  components: {
    OnboardingFooterActions,
  },
})
export default class SignupPhoneConfirm extends Mixins(Toast) {
  @Prop({ default: false }) fullpage!: boolean;

  code = "";
  codeError = false;
  codeSent = false;
  errorMessage = "";
  submitted = false;
  user: User | null = null;
  waiting = false;

  get userPhoneLastFour() {
    return this.user?.phoneLastFour || "0000";
  }

  created() {
    userStore.actions.getCurrentUser().then(() => {
      this.user = userStore.getters.currentUser;
    });
  }

  // Submit the form when the user has typed or pasted enough digits
  @Watch("code")
  autoSubmitCode() {
    if (!this.fullpage && this.code && this.code.length === 5) {
      this.submit();
    }
  }

  trackClick() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.PHONE_CONFIRM)} Primary CTA`,
    });
  }

  submit() {
    if (this.submitted) {
      return;
    }

    if (!this.code.length) {
      this.errorMessage = "Please enter the code we sent you";
      this.codeError = true;
      return;
    }

    this.errorMessage = "";
    this.codeError = false;

    this.submitted = true;
    this.waiting = true;
    (document.activeElement as HTMLInputElement).blur();

    userStore.actions
      .verifyPhone(this.code)
      .then(() => {
        this.waiting = false;
        this.$emit("complete-step");
      })
      .catch((err) => {
        this.errorMessage =
          err.response?.data?.message || "That code was incorrect";
        this.waiting = false;
        this.submitted = false;
        (this.$refs.confirmPhoneInput as Vue).$el
          .getElementsByTagName("input")[0]
          .focus();
      });
  }

  showChangeNumber() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.PHONE_CONFIRM)} Change Number`,
    });

    this.$emit("complete-step", OnboardingSteps.PHONE);
  }

  resendCode() {
    if (this.submitted || this.codeSent) {
      return;
    }

    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.PHONE_CONFIRM)} Secondary CTA`,
    });

    this.errorMessage = "";
    this.codeError = false;
    const userToken = userStore.getters.userToken || "";

    userStore.actions
      .sendVerificationCode({ userToken })
      .then(() => {
        this.successToast("We sent you another code");
        this.codeSent = true;
        window.setTimeout(() => {
          this.codeSent = false;
        }, 5000);
      })
      .catch((err) => {
        this.errorMessage =
          err.response?.data?.message || "Error sending SMS code";
      });
  }

  onFooterAction(nextScreen: OnboardingSteps): void {
    this.$emit("complete-step", nextScreen);
  }
}
</script>

<style lang="scss" scoped>
h1 {
  all: unset;
  font-family: $font-stack-wes-fy;
  font-size: 24px;
  text-align: center;
  margin-bottom: 15px;
  line-height: 1.4;
  color: $gray-800;
}

// Make the confirmation code input large and simple
::v-deep .field {
  padding: 0;
  .title {
    display: none;
  }

  input {
    position: static;
    padding: 5px 20px;
    font-family: "roboto mono", "monaco", "andale mono", "courier new",
      "monospace";
    font-size: 30px;
    letter-spacing: 5px;
    line-height: 30px;
    height: 80px;
  }
}
</style>
