<template>
  <div class="tag-menu" ref="tagMenu" @click.stop="">
    <form
      class="search-form"
      v-if="!editMenuOpen"
      @submit.prevent="handleCreateTag"
    >
      <div class="input-container">
        <SVGIcon icon="tag" />
        <input
          type="text"
          data-test="create-search-input"
          :placeholder="
            filteredTags.length ? 'Create or search' : 'Create your first tag'
          "
          v-model="currentTag.title"
          autofocus
          required
        />
        <SVGIcon
          class="close"
          icon="cross"
          v-if="currentTag.title"
          @click="reset"
        />
      </div>
    </form>
    <div class="tag-list" v-if="!editMenuOpen">
      <div class="tag-row" v-for="tag in filteredTags" :key="tag._id">
        <Tag
          :isSelected="shouldShowTagSelected(tag)"
          :title="tag.title"
          :color="tag.color"
          @click.native="onClickTag(tag)"
        />
        <div
          class="edit-tag"
          @click="setCurrentTag($event, tag)"
          data-test="edit-tag"
        >
          <SVGIcon icon="pencil" />
        </div>
      </div>
      <div
        class="new-tag"
        data-test="create-tag"
        v-if="shouldShowNewTagButton"
        @click="handleCreateTag"
      >
        Create "{{ currentTag.title }}"
      </div>
    </div>
    <div class="edit-menu-cover" v-if="editMenuOpen" @click="reset">
      <form
        class="edit-menu"
        @click="$event.stopPropagation()"
        @submit="handleEditMenuSubmit"
      >
        <input
          data-test="title-input"
          type="text"
          v-model="currentTag.title"
          autofocus
        />
        <div class="color-picker">
          <div
            class="color -default"
            data-test="color"
            @click="currentTag.color = ''"
            :class="{ '-selected': !currentTag.color }"
          >
            <SVGIcon v-if="!currentTag.color" icon="tick" />
          </div>
          <div
            class="color"
            v-for="color in colors"
            :key="color"
            data-test="color"
            :class="`-${color}`"
            @click="currentTag.color = color"
          >
            <SVGIcon v-if="currentTag.color === color" icon="tick" />
          </div>
        </div>
        <div class="button-container">
          <BaseButton
            v-if="!cardView"
            class="pill-button"
            data-test="delete-tag"
            size="small"
            icon="trash"
            shape="round"
            variant="light"
            @click="$bvModal.show('confirm-delete')"
            aria-label="delete"
          >
            <SVGIcon icon="trash" />
          </BaseButton>
          <BaseButton
            class="pill-button"
            variant="primary"
            data-test="submit-edit-tag"
            size="small"
            shape="round"
            @click="handleEditMenuSubmit"
          >
            {{ currentTag._id ? "Done" : "Create" }}
          </BaseButton>
        </div>
        <span class="delete-info mt-2" v-if="cardView && tags.length">
          Tags can be deleted from the card inspector view
        </span>
      </form>
    </div>
    <BaseModal id="confirm-delete">
      <h4 class="title">{{ `Delete the tag "${currentTag.title}"?` }}</h4>
      <h6 class="subtitle">Are you sure? This can't be undone.</h6>
      <BaseButton variant="primary" @click="deleteTag"> Yes </BaseButton>
    </BaseModal>
  </div>
</template>
<script lang="ts">
import ldGet from "lodash/get";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Card } from "@/types/Card";
import { Tag } from "@/types/Tag";
import { EVENTS } from "@/types/Event";
import { tagStore, cardStore, eventStore } from "@/store";
import { Toast } from "@/mixins/Toast";
import TagComp from "./Tag.vue";
import SVGIcon from "./SVGIcon.vue";

@Component({
  components: {
    Tag: TagComp,
    SVGIcon,
  },
})
export default class TagMenu extends Mixins(Toast) {
  @Prop() selectedTagIds?: string[];
  @Prop() card!: Card;
  @Prop({ type: Boolean }) cardView!: boolean;

  selectedTag = {};
  currentTag: Partial<Tag> = {};
  editMenuOpen = false;
  colors = [
    "red",
    "orange",
    "yellow",
    "yellow-green",
    "lush-green",
    "sky-blue",
    "blue",
    "purple",
    "pink",
  ];

  created() {
    tagStore.actions.fetchTags();
    window.addEventListener("click", this.handleClickOutside);
    window.addEventListener("keydown", this.handleKeyDown);
  }

  destroyed() {
    window.removeEventListener("click", this.handleClickOutside);
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  handleClickOutside(event: MouseEvent) {
    event.stopPropagation();
    const tagMenu = this.$refs.tagMenu as HTMLElement;
    const eventTarget = event.target as HTMLElement;
    if (eventTarget && !tagMenu.contains(eventTarget) && !this.editMenuOpen) {
      this.$emit("close");
    }
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Escape" || event.code === "Escape") {
      this.$emit("close");
    }
  }

  shouldShowTagSelected(tag: Tag) {
    if (this.card) {
      return ldGet<any, string, string[]>(
        this,
        "card.meta.tagList",
        []
      ).includes(tag._id);
    }
    return (this.selectedTagIds || []).includes(tag._id);
  }

  get tags() {
    return tagStore.getters.getTags;
  }

  get filteredTags() {
    return this.tags.filter(
      ({ title }) =>
        !this.currentTag.title || title.includes(this.currentTag.title)
    );
  }

  setCurrentTag(e: Event, tag: Partial<Tag>) {
    e.preventDefault();
    this.currentTag = { ...tag };
    this.editMenuOpen = true;
  }

  handleCreateTag(e?: Event) {
    if (e) e.preventDefault();

    if (!this.shouldShowNewTagButton) {
      return;
    }

    tagStore.actions.createTag(this.currentTag).then((newTag: Tag) => {
      if (this.card && newTag) this.tagCard(newTag);
      this.reset();
    });
  }

  handleEditMenuSubmit() {
    tagStore.actions.updateTag(this.currentTag).then(this.reset);
  }

  deleteTag() {
    this.currentTag.deleted = true;
    tagStore.actions.updateTag(this.currentTag).then(() => {
      if (
        this.currentTag._id &&
        (this.selectedTagIds || []).includes(this.currentTag._id)
      ) {
        this.$emit("tag-selected", this.currentTag);
      }
      this.reset();
    });
  }

  get shouldShowNewTagButton() {
    if (!ldGet<any, string>(this, "currentTag.title.length")) {
      return false;
    }

    return !this.filteredTags.find(
      (tag: Partial<Tag> = {}) =>
        (tag.title || "").toLowerCase() ===
          (this.currentTag.title || "").toLowerCase() && !tag.deleted
    );
  }

  onClickTag(tag: Tag) {
    if (this.card) {
      if (
        ldGet<any, string, string[]>(this, "card.meta.tagList", []).includes(
          tag._id
        )
      ) {
        this.untagCard(tag);
      } else {
        this.tagCard(tag);
      }
    } else {
      this.$emit("tag-selected", tag);
    }
  }

  tagCard(tag: Tag) {
    const tagListCopy = ldGet<any, string, string[]>(
      this,
      "card.meta.tagList",
      []
    );
    tagListCopy.push(tag._id);

    let { meta } = this.card;
    if (!meta) {
      meta = {};
    }
    meta.tagList = tagListCopy;
    cardStore.actions
      .update({ uuid: this.card.cardUuid, updates: { meta } })
      .then(() => {
        eventStore.actions.record({
          name: EVENTS.CARD.TAGGED,
        });
      })
      .catch((err) => {
        this.errorToast(err?.response?.data?.message || "Couldn't add tag");
      });
  }

  untagCard(tag: Tag) {
    const tagListCopy = ldGet<any, string, string[]>(
      this,
      "card.meta.tagList",
      []
    );
    const filteredTagListCopy = tagListCopy.filter((t: Partial<Tag>) => {
      return t !== tag._id;
    });

    let { meta } = this.card;
    if (!meta) {
      meta = {};
    }
    meta.tagList = filteredTagListCopy;
    cardStore.actions
      .update({ uuid: this.card.cardUuid, updates: { meta } })
      .then(() => {
        eventStore.actions.record({
          name: EVENTS.CARD.UNTAGGED,
        });
      })
      .catch((err) => {
        this.errorToast(err?.response?.data?.message || "Couldn't remove tag");
      });
  }

  reset() {
    this.editMenuOpen = false;
    this.currentTag = {};
    this.$bvModal.hide("confirm-delete");
  }
}
</script>
<style lang="scss" scoped>
#confirm-delete {
  * {
    text-align: center;
    margin: 0 auto;
  }

  .title {
    font-family: $font-stack-wes-fy;
    margin-bottom: 1.25rem;
  }

  .subtitle {
    margin-bottom: 1.25rem;
  }

  button {
    display: block;
  }
}

.tag-menu {
  cursor: default;
  z-index: 1;
  position: absolute;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 300px;
  overflow: hidden;
  background: $white;
  box-shadow: $box-shadow-hairline, $box-shadow-default;
  border-radius: $border-radius;

  > .edit-menu-cover {
    height: 100%;
    border-radius: $border-radius;
    background: fade-out($gray-200, 0.1);
    padding: 20px;
  }

  > .edit-menu-cover > .edit-menu {
    background-color: white;
    box-shadow: $box-shadow-hairline, $box-shadow-default;
    border-radius: $border-radius;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-flow: column;
  }

  > .edit-menu-cover > .edit-menu > input {
    background-color: $gray-100;
    height: 35px;
    padding: 0 10px;
    border: none;
    border-radius: $border-radius;
    width: 100%;
    font-size: 14px;
  }

  > .edit-menu-cover > .edit-menu > .color-picker {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 5px;
    row-gap: 5px;
    margin: 20px 0;

    > .color {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: $border-radius;
      cursor: pointer;

      &.-default {
        background-color: $tag-color-default;
      }
      &.-red {
        background-color: $tag-color-red;
      }
      &.-orange {
        background-color: $tag-color-orange;
      }
      &.-yellow {
        background-color: $tag-color-yellow;
      }
      &.-yellow-green {
        background-color: $tag-color-yellow-green;
      }
      &.-lush-green {
        background-color: $tag-color-lush-green;
      }
      &.-sky-blue {
        background-color: $tag-color-sky-blue;
      }
      &.-blue {
        background-color: $tag-color-blue;
      }
      &.-purple {
        background-color: $tag-color-purple;
      }
      &.-pink {
        background-color: $tag-color-pink;
      }

      & svg * {
        fill: white;
      }
    }
  }

  > .edit-menu-cover > .edit-menu > .button-container {
    display: flex;
    margin-top: auto;

    > .pill-button {
      margin-right: 10px;

      &:last-child {
        margin: 0;
        flex-grow: 1;
      }
    }
  }

  > .search-form {
    padding: 15px;
  }

  > .search-form > .input-container {
    position: relative;
    height: 35px;
    background-color: $gray-100;
    border-radius: $border-radius;

    > input {
      position: absolute;
      left: 0;
      background-color: transparent;
      border: none;
      padding: 0 40px;
      font-family: $font-stack-lato;
      font-size: 14px;
      width: 100%;
      height: 35px;

      &:focus {
        outline: none;
      }
    }

    & .svg-icon {
      position: absolute;
      left: 10px;
      top: 8px;
      opacity: 0.33;

      &.close {
        left: unset;
        right: 10px;
        cursor: pointer;
      }
    }

    &:focus-within .svg-icon {
      opacity: 1;
    }
  }

  > .tag-list {
    padding: 0 15px 15px;
    overflow-y: auto;
  }

  > .tag-list > .tag-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    &:last-child {
      margin: 0;
    }

    > .edit-tag {
      display: none;
      flex-shrink: 0;
    }

    > .tag {
      flex-grow: 0;
      max-width: 200px;
    }

    > .tag.-selected {
      max-width: 177px;
    }

    &:hover {
      > .tag {
        cursor: pointer;
        max-width: 182px;
      }

      > .tag.-selected {
        max-width: 159px;
      }

      > .tag:hover {
        opacity: 1;
      }

      > .edit-tag {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        opacity: 0.25;
        transition: opacity $duration-shorter;
      }

      > .edit-tag:hover {
        opacity: 1;
      }
    }
  }

  > .tag-list > .new-tag {
    background-color: $gray-100;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: $border-radius;
    font-family: $font-stack-lato;
    color: $black;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }

    &:not(:first-child) {
      margin-top: 15px;
    }
  }
}

.delete-info {
  font-family: $font-stack-lato;
  color: $gray-600;
  font-size: 12px;
  line-height: 1;
  text-align: center;
}
</style>
