<template>
  <div
    class="card-details"
    :class="{ '-disabled': card.state === CardState.CLOSED }"
  >
    <div v-if="recipients.length" class="shared">
      <BaseBadge variant="info">Shared Card</BaseBadge>
      <b-tooltip
        target="shared-card"
        triggers="hover"
        delay="300"
        placement="right"
      >
        <p class="share-title">Shared with</p>
        <ul class="share-list">
          <li v-for="recipient in recipients" :key="recipient">
            {{ recipient }}
          </li>
        </ul>
      </b-tooltip>
    </div>
    <div
      class="detail -nickname"
      @click="onClickNickname"
      data-test="card-details-nickname"
    >
      <div class="label">Nickname</div>
      <div class="value">
        {{ card.memo || card.hostname || "Untitled" }}
      </div>
    </div>
    <div
      v-if="showCardNote"
      class="detail -notes"
      data-test="card-details-notes"
    >
      <div class="label">Card Note</div>
      <div class="notes" @click="onClickNotes">
        <span class="value" v-if="card.note" :title="card.note">
          {{ card.note }}
        </span>
        <span v-else>Add a note...</span>
      </div>
    </div>
    <div class="detail -limit" @click="onClickSpendLimit">
      <div class="label">{{ spendLimitDurationLabel }}</div>
      <div class="value -spend-limit" v-if="!card.spendLimit">
        <div class="amount">No Limit</div>
      </div>
      <div class="value -spend-limit" v-else>
        <div
          class="amount"
          v-if="card.spendLimitDuration === CardTime.TRANSACTION"
          data-test="spend-limit"
        >
          ${{ card.spendLimit }}&nbsp;
          <div class="description">per transaction</div>
        </div>
        <div
          class="amount"
          v-else-if="card.spendLimitDuration === CardTime.MONTHLY"
          data-test="spent-this-month"
        >
          {{ parseDollarAmount(card.spentThisMonthRolling) }}&nbsp;
          <div class="description">/ ${{ card.spendLimit }}</div>
        </div>
        <div
          class="amount"
          v-else-if="card.spendLimitDuration === CardTime.ANNUALLY"
          data-test="spent-this-year"
        >
          {{ parseDollarAmount(card.spentThisYearRolling) }}&nbsp;
          <div class="description">/ ${{ card.spendLimit }}</div>
        </div>
        <div class="amount" v-else data-test="spent-total">
          {{ parseDollarAmount(card.spentTotal) }}&nbsp;
          <div class="description">/ ${{ card.spendLimit }}</div>
        </div>
      </div>
    </div>
    <div
      class="detail -funding-source"
      v-if="shouldShowFundingSource"
      @click="onClickFundingSource"
      :class="{ '-disabled': userFundingSources.length < 2 }"
    >
      <div class="label">Funding Source</div>
      <div class="value">
        <FundingSource
          :key="cardFundingSource._id"
          :source="cardFundingSource"
          size="small"
          :hideDefaultBadge="true"
        />
      </div>
    </div>
    <div class="detail -funding-source -disabled" v-else-if="isSampleCard">
      <div class="label">Funding Source</div>
      <div class="value">No Funding Source</div>
    </div>
    <EditNickname
      :card="card"
      :hasMerchantSelector="hasMerchantSelector"
      @update-card="handleCardUpdate"
      :isSampleCard="isSampleCard"
    />
    <EditFundingSource :card="card" @update-card="handleCardUpdate" />
    <EditSpendLimitModal
      :card="card"
      :hideLimitSlider="hideLimitSlider"
      @update-card="handleCardUpdate"
      :isSampleCard="isSampleCard"
      :subscription="subscription"
    />
    <EditCardNotes
      modalId="edit-notes"
      :card="card"
      @update-card="handleCardUpdate"
      :isSampleCard="isSampleCard"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Card, CardState, CardType, CardTime, SampleCard } from "@/types/Card";
import { userStore, subscriptionStore } from "@/store";
import { parseDollarAmount } from "@/util";
import FundingSource from "./FundingSource.vue";
import EditNickname from "./EditNickname.vue";
import BaseBadge from "./BaseBadge.vue";
import EditFundingSource from "./EditFundingSource.vue";
import EditSpendLimitModal from "./EditSpendLimitModal.vue";
import EditCardNotes from "./EditCardNotes.vue";

@Component({
  components: {
    BaseBadge,
    FundingSource,
    EditNickname,
    EditFundingSource,
    EditSpendLimitModal,
    EditCardNotes,
  },
})
export default class CardDetails extends Vue {
  @Prop() cardProp!: Partial<Card>;
  @Prop() hasMerchantSelector?: boolean;
  @Prop() hideLimitSlider?: boolean;
  @Prop() isSampleCard?: boolean;

  card: Partial<Card> = {};
  parseDollarAmount = parseDollarAmount;

  CardState = CardState;
  CardType = CardType;
  CardTime = CardTime;

  updateCard(card: Card): void {
    this.card = card;
  }

  created() {
    this.card = this.cardProp;
  }

  get notesFlag() {
    return !!this.subscription?.features.cardNotes;
  }

  get user() {
    return userStore.getters.currentUser;
  }

  get subscription() {
    return subscriptionStore.getters.subscription;
  }

  get showCardNote() {
    if (this.isNewCard || this.isSampleCard) {
      return false;
    }

    if (this.subscription?.features.cardNotes) {
      return true;
    }

    if (this.card.note) {
      return true;
    }

    return false;
  }

  get isNewCard() {
    return !this.card.created;
  }

  get spendLimitDurationLabel() {
    if (this.card.spendLimitDuration === CardTime.TRANSACTION) {
      return "Transaction Limit";
    } else if (this.card.spendLimitDuration === CardTime.MONTHLY) {
      return "Monthly Limit";
    } else if (this.card.spendLimitDuration === CardTime.ANNUALLY) {
      return "Yearly Limit";
    }
    return "Total Spend Limit";
  }

  get userFundingSources() {
    return (this.user?.bankAccountList || []).concat(
      this.user?.fundingCardList || []
    );
  }

  get shouldShowFundingSource() {
    // Show the funding source if we are inspecting an existing card,
    // or if creating a new card while having more than one funding
    // source connected. Don't show it if the user is PREPAID.
    const isExistingCard = this.card.cardID;
    const isPrepaidUser = this.user?.accountType === "PREPAID";
    const hasMultipleFundingSources = this.userFundingSources.length > 1;
    const hasAnyFundingSources = this.userFundingSources.length > 0;

    return (
      !isPrepaidUser &&
      hasAnyFundingSources &&
      (hasMultipleFundingSources || isExistingCard)
    );
  }

  get cardFundingSource() {
    const cardFundingSource = this.userFundingSources.find((fundingSource) => {
      const cardFundingBankID = this.card.bankAccountID?.toString();

      const isFundingBank = cardFundingBankID === fundingSource.bankAccountID;
      const isFundingByDefault = !cardFundingBankID && fundingSource.default;
      const isOnlyFundingSource = this.userFundingSources.length === 1;

      return isFundingBank || isFundingByDefault || isOnlyFundingSource;
    });

    return cardFundingSource || {};
  }

  get recipients() {
    const shared = this.card?.sharedWithRecipients?.map(
      (recipient) => recipient.recipientId.email
    );

    return shared || [];
  }

  @Watch("cardProp")
  updateFromCardProp() {
    this.card = this.cardProp;
  }

  onClickNickname() {
    this.$bvModal.show("edit-nickname");
  }

  onClickNotes() {
    this.$bvModal.show("edit-notes");
  }

  onClickSpendLimit() {
    this.$bvModal.show("edit-spend-limit");
  }

  onClickFundingSource() {
    this.$bvModal.show("edit-funding");
  }

  handleCardUpdate(card: Card) {
    this.updateCard(card);

    if (this.isSampleCard) {
      userStore.actions.updateSampleCard(card as SampleCard);
    }

    this.$emit("update-card", card);
  }
}
</script>

<style lang="scss" scoped>
.shared {
  padding: 4px 20px;
}

.share-title {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-align: left;
}

.share-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;

  li {
    font-size: 14px;
    line-height: 1.5;
  }
}

.card-details {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 280px;
  user-select: none;

  & .-disabled,
  &.-disabled > .detail {
    pointer-events: none;
    user-select: none;
  }

  > .detail {
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
    border-radius: $border-radius-lg;
    cursor: pointer;
  }

  > .detail:hover {
    background-color: fade-out($gray-100, 0.5);
  }

  > .detail > .label {
    font-weight: bold;
    color: $gray-600;
  }

  > .detail > .value {
    overflow: hidden;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    text-overflow: ellipsis;

    > .amount > .description {
      color: $gray-600;
      display: inline;
    }

    &.-spend-limit {
      color: $gray-500;

      & .amount {
        display: inline;
        color: $gray-800;
      }

      & .description {
        text-transform: lowercase;
      }
    }
  }

  > .detail > .value > .funding-source-wrapper {
    margin-top: 5px;
  }

  .notes {
    margin-bottom: 5px;
    border-radius: $border-radius-lg;
    padding-top: 5px;
    cursor: pointer;

    .value {
      display: -webkit-box;
      line-clamp: 2;
      text-overflow: ellipsis;
      line-height: 1.25;
    }
  }
}
</style>
