<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm :class="'existing-user-verify-ssn'" @submit="onSubmit">
      <h1>SSN Verification</h1>
      <p>
        We couldn't verify your identity with the info you provided. Please
        provide your full SSN so we can try to verify you.
      </p>
      <CreditScoreExplainer></CreditScoreExplainer>
      <b-alert v-if="errorMessage" variant="danger" show>
        {{ errorMessage }}
      </b-alert>
      <BaseInput
        label="Social Security Number"
        format="ssn"
        placeholder="000-00-0000"
        v-model="ssn"
        maxlength="11"
        :state="errorMessage ? false : null"
        :disabled="loading"
        data-test="input-ssn"
      >
        <SVGIcon class="padlock" icon="padlock" v-if="!errorMessage" />
      </BaseInput>
      <BaseButton
        type="submit"
        variant="primary"
        size="lg"
        :disabled="loading"
        v-if="!success"
        data-test="verify-ssn-submit"
        @click="trackClick"
      >
        Submit
      </BaseButton>
      <BaseButton
        type="submit"
        variant="success"
        size="lg"
        :disabled="true"
        v-else
      >
        Submitted!&nbsp;<span v-if="fullpage">Redirecting...</span>
      </BaseButton>
      <OnboardingFooterActions
        :showFinishLater="isRemediatingKYC"
        @footer-action="onFooterAction"
      />
      <p class="secure">
        Your information is encrypted and never shared. Learn more about how we
        use and protect your personal data by reading our
        <b-link href="https://privacy.com/privacy-policy" target="_blank"
          >Privacy Policy
        </b-link>
        and
        <b-link href="https://privacy.com/security" target="_blank"
          >Security</b-link
        >
        pages.
      </p>
    </BaseForm>
  </SimplePage>
</template>
<script lang="ts">
import { AxiosError } from "axios";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import CreditScoreExplainer from "@/components/CreditScoreExplainer.vue";
import OnboardingFooterActions from "@/components/OnboardingFooterActions.vue";
import SVGIcon from "@/components/SVGIcon.vue";
import { eventStore, userStore } from "@/store";
import { EVENTS } from "@/types/Event";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

const SUCCESS_MESSAGE_DURATION = 1000;

@Component({
  components: {
    CreditScoreExplainer,
    OnboardingFooterActions,
    SVGIcon,
  },
})
export default class ExistingUserVerifySsn extends Vue {
  @Prop({ default: false }) fullpage!: boolean;
  ssn = "";
  errorMessage = "";
  loading = false;
  success = false;

  get user() {
    return userStore.getters.currentUser;
  }

  get isRemediatingKYC(): boolean {
    return !!userStore.getters.currentUser?.isRemediatingKYC;
  }

  @Watch("ssn")
  resetErrorState() {
    this.errorMessage = "";
  }

  mounted() {
    eventStore.actions.record({
      name: EVENTS.MODAL.VIEWED,
      data: {
        modalName: "Existing User Verify SSN",
      },
    });
  }

  trackClick() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.EXISTING_USER_VERIFY_SSN)} Primary CTA`,
    });
  }

  async onSubmit() {
    eventStore.actions.record({
      name: EVENTS.CTA.CLICKED,
      data: {
        buttonContext: "Existing User Verify SSN",
        buttonName: "Submit",
      },
    });
    this.resetErrorState();

    const ssn = this.ssn.replace(/-/g, "");

    if (!ssn || !/^[0-9]{9}$/.test(ssn)) {
      this.errorMessage = "Please enter a valid SSN";
      return;
    }

    this.loading = true;

    try {
      await userStore.actions.verifyFullSsn({ ssn: ssn });

      if (this.user?.isRemediatingKYC) {
        const response = await userStore.actions.checkRemediationID();
        const remediationResult = response.data?.result;

        const nextStep =
          userStore.getters.nextRemediationStep(remediationResult);

        // If remediation isn't complete now, update step
        if (nextStep !== undefined && this.user?.isRemediatingKYC) {
          userStore.actions.updateRemediationStep(nextStep);
        }
        this.$emit("complete-step", nextStep);
      } else {
        this.success = true;
        setTimeout(() => {
          this.$emit("complete-step");
        }, SUCCESS_MESSAGE_DURATION);
      }
    } catch (err) {
      this.errorMessage =
        (err as AxiosError<{ message?: string }>)?.response?.data?.message ||
        "Something went wrong, please try again.";
    }

    this.loading = false;
  }

  onFooterAction(nextScreen: OnboardingSteps): void {
    this.$emit("complete-step", nextScreen);
  }
}
</script>
<style lang="scss" scoped>
.existing-user-verify-ssn {
  h1 {
    all: unset;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    margin-bottom: 15px;
    line-height: 1.4;
    color: $gray-800;
  }

  p {
    font-size: 14px;
    color: $gray-600;
    text-align: center;
    padding: 0 10px;

    > a {
      font-weight: bold;
    }
  }

  .btn {
    margin: 1em 0;
  }

  .secure {
    font-size: 12px;
  }

  ::v-deep .padlock {
    position: absolute;
    right: 20px;
    bottom: 30px;
  }

  ::v-deep .onboarding-footer-actions {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }

  ::v-deep .credit-score-explainer {
    padding: 1em 20px;

    > .text {
      padding-right: 10px;
    }
  }
}
</style>
