<template>
  <label
    class="merchant-style"
    :class="{ selected, disabled }"
    :style="{ 'background-color': background }"
    @click="handleClick"
  >
    <span class="name" :class="{ 'sr-only': logo }">{{ name }}</span>
    <img v-if="logo" :src="logoPath" :alt="`${name} logo`" />
    <input
      type="checkbox"
      :value="name"
      hidden
      :name="`merchant-style-${name}`"
      :disabled="disabled"
      :checked="selected"
      @input="handleInput"
    />
  </label>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { getLogoSrc } from "../util";

@Component
export default class MerchantStyle extends Vue {
  @Prop() backgroundColor?: string;
  @Prop() logo?: string;
  @Prop({ required: true }) name!: string;
  @Prop({ type: Boolean }) selected!: boolean;
  @Prop({ type: Boolean }) disabled!: boolean;

  get background(): string {
    return this.backgroundColor ? "#" + this.backgroundColor : "";
  }

  get logoPath(): string | undefined {
    return this.logo ? getLogoSrc({ filename: this.logo }) : undefined;
  }

  handleClick(event: Event): void {
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  handleInput(event: Event): void {
    this.$emit("input", (event.target as HTMLInputElement).value);
  }
}
</script>

<style lang="scss" scoped>
.merchant-style {
  background-color: $gray-200;
  font-family: $font-stack-wes-fy;
  font-size: 24px;
  color: $gray-800;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 80px;
  cursor: pointer;
  position: relative;
  border: 4px solid #fff;
  margin-bottom: auto;
}

.selected {
  border-color: #fff;
  outline: 2px solid $blue;
  outline-offset: -2px;

  &::after {
    content: "";
    position: absolute;
    right: -2px;
    bottom: -2px;
    display: block;
    height: 30px;
    width: 30px;
    background: $blue url("/assets/images/icons/tick-20-white.svg") no-repeat
      center;
    border-top: 2px solid;
    border-left: 2px solid;
    border-color: #fff;
    border-radius: 10px 0 8px;
  }
}

.disabled {
  opacity: 0.3;
  cursor: default;
}

.name {
  text-transform: capitalize;
  background: inherit;
  background-clip: text;
  font-size: 18px;
}

img {
  max-height: 35px;
  max-width: 80px;
}
</style>
