<template>
  <KybVerificationContainer>
    <BaseForm class="business-type">
      <h3 class="heading">What type of business do you have?</h3>
      <BaseButton
        variant="light"
        block
        @click="selectedBusinessType = types.INDIVIDUAL"
        :pressed="selectedBusinessType === types.INDIVIDUAL"
        >Sole proprietorship</BaseButton
      >
      <BaseButton
        variant="light"
        block
        @click="selectedBusinessType = types.PRIVATE_COMPANY"
        :pressed="selectedBusinessType === types.PRIVATE_COMPANY"
        >Private, registered business entity (e.g., LLC or Inc.)</BaseButton
      >
      <BaseButton
        variant="light"
        block
        @click="selectedBusinessType = types.NONPROFIT"
        :pressed="selectedBusinessType === types.NONPROFIT"
        >Nonprofit or charity</BaseButton
      >
      <div class="divider mt-4 mb-4"></div>
      <p class="info text-center">
        By continuing, you agree that you are a controlling officer in your
        company (ex: CFO, CEO, or other executive) and that you are authorized
        to enter into legally binding contracts on behalf of your company.
      </p>
      <BaseButton
        variant="primary"
        @click="submit"
        class="cta-btn"
        :disabled="selectedBusinessType === null"
        >Continue</BaseButton
      >
    </BaseForm>
  </KybVerificationContainer>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { Toast } from "@/mixins/Toast";
import { organizationStore, userStore } from "@/store";
import { BusinessType as BusinessTypeValues } from "@/types/Organization";
import KybVerificationContainer from "./KybVerificationContainer.vue";

@Component({
  components: {
    KybVerificationContainer,
  },
})
export default class BusinessType extends Mixins(Toast) {
  get kybUser() {
    return userStore.getters.currentUser!.kybVerificationUser;
  }

  get piwikPrefix() {
    return this.kybUser ? "Verification" : "Onboarding";
  }

  nextStep(): void {
    if (this.selectedBusinessType === this.types.INDIVIDUAL) {
      this.$router.push({ name: "kybBusinessConfirmSoleProprietor" });
    }
    if (
      this.selectedBusinessType === this.types.PRIVATE_COMPANY ||
      this.selectedBusinessType === this.types.NONPROFIT
    ) {
      this.$router.push({ name: "kybBusinessDetails" });
    }
  }

  async created() {
    await userStore.actions.getCurrentUser();
    this.$piwik.trackEvent("Modal", "View", {
      name: `${this.piwikPrefix} Business Type`,
    });

    const currentStep = "kybBusinessType";
    if (this.kybUser) {
      userStore.actions.updateVerificationStep(currentStep);
    } else {
      userStore.actions.updateUser({ kybCurrentStep: currentStep });
    }
  }

  types = BusinessTypeValues;
  selectedBusinessType: BusinessTypeValues | null = null;

  async submit(): Promise<void> {
    this.$piwik.trackClick({
      name: `${this.piwikPrefix} Business Type CTA`,
    });

    if (this.selectedBusinessType === this.types.INDIVIDUAL) {
      // don't save INDIVIDUAL business type here - will be saved once confirmed in next step
      this.$router.push({ name: "kybBusinessConfirmSoleProprietor" });
      return;
    }

    if (
      this.selectedBusinessType === this.types.PRIVATE_COMPANY ||
      this.selectedBusinessType === this.types.NONPROFIT
    ) {
      try {
        await organizationStore.actions.businessType({
          businessType: this.selectedBusinessType,
        });

        this.$router.push({ name: "kybBusinessDetails" });
      } catch (err: any) {
        this.errorToast(
          err?.response?.data?.message || "Couldn't update business type"
        );
      }
    }
  }
}
</script>

<style lang="scss">
p.info {
  font-size: 12px;
  color: #323242;
}
</style>
