import { NotificationState, NotificationInfo } from "@/types/Notification";
import { MutationHandler } from "./vuex-typex";
import { storeBuilder } from "./storeBuilder";

const builder = storeBuilder.module<NotificationState>("notification", {
  notificationList: [],
  hidden: false,
});

type NotificationMutation<Payload = void> = MutationHandler<
  NotificationState,
  Payload
>;

const activeNotification = builder.read(
  (state) => (state.notificationList.length ? state.notificationList[0] : null),
  "activeNotification"
);

const hidden = builder.read((state) => state.hidden, "hidden");

export const getters = {
  get activeNotification() {
    return activeNotification();
  },
  get hidden() {
    return hidden();
  },
};

const addNotification: NotificationMutation<NotificationInfo> = (
  state,
  value
) => {
  if (
    state.notificationList.some((notification) => notification.id === value.id)
  ) {
    return;
  }
  state.notificationList.push(value);
  state.notificationList.sort((a, b) => a.priority - b.priority);
};

const dismissNotification: NotificationMutation<string> = (state, id) => {
  const index = state.notificationList.findIndex(
    (notification) => notification.id === id
  );

  if (index >= 0) {
    state.notificationList.splice(index, 1);
  }
};

const hideNotification: NotificationMutation = (state) => {
  state.hidden = true;
};

export const mutations = {
  addNotification: builder.commit(addNotification),
  dismissNotification: builder.commit(dismissNotification),
  hideNotification: builder.commit(hideNotification),
};
