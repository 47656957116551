<template>
  <BaseModal
    id="autologout-modal"
    :visible="visible"
    class="text-center"
    static
    lazy
    @hide="$emit('hide')"
  >
    <div class="logout-header">Still there?</div>
    <p>To keep you safe, we'll log you out in {{ logoutWarningDuration }}</p>
    <div>
      <BaseButton
        variant="primary"
        class="mr-2"
        @click="$bvModal.hide('autologout-modal')"
      >
        Stay logged in
      </BaseButton>
      <BaseButton variant="secondary" to="/logout">Log out</BaseButton>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import { duration } from "@/lib/dates";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AutoLogoutModal extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop({ required: true }) remaining!: number | null;

  get logoutWarningDuration(): string {
    const dur = duration(this.remaining || 0);

    const minutes = dur.get("minutes").toString().padStart(2, "0");
    const seconds = dur.get("seconds").toString().padStart(2, "0");

    return `${minutes}:${seconds}`;
  }
}
</script>

<style lang="scss" scoped>
.logout-header {
  font-family: $font-stack-wes-fy;
  font-size: 20px;
  line-height: 30px;
}
</style>
