<template>
  <div class="account-section -statements" id="statements">
    <div class="section-heading">
      <h2>Statements &amp; Accounting</h2>
    </div>
    <div class="section">
      <BaseButton
        size="lg"
        variant="primary"
        data-test="button-open-statements"
        @click="viewStatements"
      >
        View Statements
      </BaseButton>
      <p class="blurb">
        View your account activity and export it as PDF or CSV.
      </p>
    </div>
    <BaseModal id="statements" size="lg">
      <Statements :user="user"></Statements>
    </BaseModal>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { User } from "@/types/User";
import Statements from "@/views/Statements.vue";

@Component({
  components: {
    Statements,
  },
})
export default class AccountStatements extends Vue {
  @Prop({ required: true }) user!: User;

  viewStatements() {
    this.$bvModal.show("statements");
  }
}
</script>

<style lang="scss" scoped>
#statements .section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .btn {
    flex-basis: 180px;
    flex-shrink: 0;
    margin-right: 40px;
  }

  .blurb {
    margin-bottom: 0;
  }

  @media #{$media-phone} {
    flex-direction: column;

    .btn {
      width: 100%;
      flex-basis: unset;
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
}
</style>
