<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      @submit="submit"
      :fullpage="fullpage"
      :class="['signup-info', { '-loading': waiting }]"
      data-test="signup-info-form"
      id="signup-info-form"
    >
      <h1>Account Information</h1>

      <p class="blurb" v-if="isBusinessSignup">
        We'll need to verify personal information of the Privacy business
        account manager (that's you!)
      </p>
      <p class="blurb" v-else>
        We're required to verify this information against public records, but
        we'll keep it private.
      </p>

      <b-alert variant="danger" :show="errorMessage.length > 0">
        {{ errorMessage }}
      </b-alert>

      <BaseInputGroup>
        <BaseInput
          name="firstName"
          label="First Name"
          placeholder="Given Name"
          v-model="user.firstName"
          type="text"
          maxlength="128"
          :autofocus="true"
          :state="firstNameError ? false : null"
          data-test="input-personal-first-name"
        />
        <BaseInput
          name="lastName"
          label="Last Name"
          placeholder="Family Name"
          v-model="user.lastName"
          type="text"
          maxlength="128"
          :state="lastNameError ? false : null"
          data-test="input-personal-last-name"
        />
      </BaseInputGroup>

      <BaseInputGroup>
        <BaseInput
          name="dob"
          label="Date of Birth"
          placeholder="MM/DD/YYYY"
          v-model="user.dob"
          type="text"
          maxlength="10"
          format="date"
          :state="dobError ? false : null"
          data-test="input-personal-dob"
        />
        <BaseInput
          name="ssnLast4"
          label="SSN Last 4"
          placeholder="4321"
          v-model="user.ssnLast4"
          type="text"
          maxlength="4"
          :state="ssnLast4Error ? false : null"
          id="input-personal-ssn"
          data-test="input-personal-ssn"
        >
          <b-tooltip
            target="input-personal-ssn"
            triggers="hover"
            container="signup-info-form"
          >
            Your information is encrypted and never shared.
          </b-tooltip>
        </BaseInput>
      </BaseInputGroup>

      <BaseInput
        name="address1"
        :label="isBusinessSignup ? 'Home Street Address' : 'Street Address'"
        placeholder="Your US Street Address"
        v-model="user.address1"
        type="text"
        maxlength="128"
        :state="address1Error ? false : null"
        data-test="input-personal-address"
      />

      <BaseInputGroup>
        <BaseInput
          name="address2"
          label="Apt/Unit (Optional)"
          placeholder="Apartment"
          v-model="user.address2"
          type="text"
          maxlength="128"
        />
        <BaseInput
          name="zipcode"
          label="ZIP Code"
          placeholder="00000"
          v-model="user.zipcode"
          type="text"
          maxlength="5"
          :state="zipcodeError ? false : null"
          data-test="input-personal-zip"
        />
      </BaseInputGroup>

      <BaseInput
        name="phone"
        label="US Phone Number"
        placeholder="(000) 000-0000"
        v-model="user.phone.number"
        type="text"
        maxlength="14"
        format="phone-us"
        :state="phoneError ? false : null"
        v-show="showPhone"
        data-test="input-personal-phone"
      />

      <BaseInput
        name="ssn"
        label="Social Security Number"
        placeholder="XXX-XX-XXXX"
        v-model="user.ssn"
        type="text"
        maxlength="11"
        format="ssn"
        :state="ssnError ? false : null"
        v-show="isIssuingSignup"
        data-test="input-personal-ssn-full"
      />

      <div class="business-notice mt-1 mb-2" v-if="isBusinessSignup">
        <strong>You should know:</strong>
        By continuing you agree that you are a controlling officer in your
        company (example CFO, CEO, or other executive), and that you are
        authorized to enter into legally binding contracts on behalf of your
        company.
      </div>
      <BaseButton
        variant="primary"
        type="submit"
        :loading="waiting"
        size="lg"
        data-test="signup-info-form-submit"
        @click="trackClick"
      >
        {{ buttonText }}
      </BaseButton>
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { subscriptionStore, userStore } from "@/store";
import { BAlert, BTooltip } from "bootstrap-vue";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component({
  components: {
    BAlert,
    BTooltip,
  },
})
export default class SignupInfo extends Vue {
  @Prop({ default: false }) fullpage!: boolean;
  @Prop({ default: false }) isIssuingSignup!: boolean;
  @Prop({ default: false }) showPhone!: boolean;

  user = {
    firstName: "",
    lastName: "",
    dob: "",
    address1: "",
    address2: "",
    zipcode: "",
    phone: { number: "" },
    ssn: "",
    ssnLast4: "",
  };
  errorMessage = "";
  firstNameError = false;
  lastNameError = false;
  dobError = false;
  ssnError = false;
  address1Error = false;
  zipcodeError = false;
  phoneError = false;
  ssnLast4Error = false;
  waiting = false;
  isBusinessSignup = false;

  async created() {
    await subscriptionStore.actions.fetchPlans();
    const selectedPlan = subscriptionStore.getters.selectedPlan();
    this.isBusinessSignup = !!selectedPlan?.organization;
  }

  get buttonText() {
    if (this.isBusinessSignup) {
      return "Agree and Continue";
    }
    return this.showPhone ? "Confirm Information" : "Next";
  }

  trackClick() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.INFO)} Primary CTA`,
    });
  }

  submit() {
    this.errorMessage = "";

    if (this.user.firstName.length) {
      this.firstNameError = false;
    } else {
      this.firstNameError = true;
      this.errorMessage = "Please enter your first name";
      return;
    }

    if (this.user.lastName.length) {
      this.lastNameError = false;
    } else {
      this.lastNameError = true;
      this.errorMessage = "Please enter your last name";
      return;
    }

    if (this.user.address1.length) {
      this.address1Error = false;
    } else {
      this.address1Error = true;
      this.errorMessage = "Please enter your street address";
      return;
    }

    if (this.user.zipcode.length && this.user.zipcode.length === 5) {
      this.zipcodeError = false;
    } else {
      this.zipcodeError = true;
      this.errorMessage = "Please enter a valid ZIP code";
      return;
    }

    if (this.user.dob.length && this.user.dob.length === 10) {
      this.dobError = false;
    } else {
      this.dobError = true;
      this.errorMessage =
        "Please enter a correctly formatted date of birth (MM/DD/YYYY)";
      return;
    }

    if (
      !this.isIssuingSignup ||
      (this.user.ssn.length && this.user.ssn.length === 9)
    ) {
      this.ssnError = false;
    } else {
      this.ssnError = true;
      this.errorMessage =
        "Please enter a correctly formatted SSN (XXX-XX-XXXX)";
      return;
    }

    if (
      !this.showPhone ||
      (this.user.phone.number.length && this.user.phone.number.length === 14)
    ) {
      this.phoneError = false;
    } else if (!this.user.phone.number.length) {
      this.phoneError = true;
      this.errorMessage = "Please enter your phone number";
      return;
    } else {
      this.phoneError = true;
      this.errorMessage = "Please enter a valid 10-digit phone number";
      return;
    }

    if (/^[0-9]{4}$/.test(this.user.ssnLast4)) {
      this.ssnLast4Error = false;
    } else {
      this.ssnLast4Error = true;
      this.errorMessage = "Please provide the last 4 digits of your SSN";
      return;
    }

    // Remove formatting from form input values
    const dobNumeric = this.user.dob.replace(/\D/g, "");
    const phoneNumeric = this.user.phone.number.replace(/\D/g, "");
    const userFormatted = {
      ...this.user,
      phone: { number: phoneNumeric },
      dob: dobNumeric,
    };

    this.waiting = true;
    userStore.actions
      .kyc(userFormatted)
      .then(() => {
        if (this.showPhone) {
          return userStore.actions
            .phoneSetup(userFormatted.phone.number)
            .then(() => {
              this.$emit("complete-step");
            })
            .catch(({ response }) => {
              this.errorMessage =
                response?.data?.message || "Error sending SMS code";
              this.waiting = false;
            });
        }
        this.$emit("complete-step");
      })
      .catch(({ response }) => {
        this.waiting = false;
        this.errorMessage =
          response?.data?.message ||
          "There was an internal server error, please contact support@privacy.com";
      });
  }
}
</script>

<style lang="scss" scoped>
.signup-info {
  h1 {
    all: unset;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    margin-bottom: 15px;
    line-height: 1.4;
    color: $gray-800;
  }
}

.business-notice {
  padding: 15px 20px;
  box-shadow: inset 0 0 0 2px $gray-100;
  border-radius: $border-radius;
  font-size: $font-size-sm;
}
</style>
