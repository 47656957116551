<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm :fullpage="fullpage" @submit="onSubmit" class="tfa-setup-form">
      <h1>Setup Authentication</h1>
      <p>
        <b-tooltip target="tfa-suggested-apps" triggers="hover"
          >Try 1Password, Google Authenticator, or Authy, available on iOS and
          Android</b-tooltip
        >
        <span id="tfa-suggested-apps">Using your authentication app</span>, scan
        the QR code or enter the 16-digit key, then enter the one-time access
        code your app generates below.
      </p>
      <div v-if="seedError">
        <b-alert variant="danger" show>{{ seedError }}</b-alert>
        <p>
          Please {{ modalId ? "close this window" : "refresh this page" }} this
          and try again.
        </p>
      </div>
      <div v-else>
        <div
          class="qrcode-container"
          :class="{ '-generating-seed': generatingSeed }"
        >
          <b-spinner
            small
            class="spinner"
            label="Loading..."
            v-if="generatingSeed"
          ></b-spinner>
          <div class="key">{{ tfaSeed }}</div>
          <canvas id="qrcode" class="qrcode"></canvas>
        </div>
        <b-alert
          v-if="errorMessage"
          variant="danger"
          show
          data-test="tfa-setup-error"
        >
          {{ errorMessage }}
        </b-alert>
        <BaseInput
          label="One-Time Access Code"
          placeholder="000000"
          v-model="tfaResponse"
          max-length="6"
          :state="errorMessage ? false : null"
          :disabled="loading || success"
          data-test="tfa-setup-input"
        >
        </BaseInput>
        <BaseButton
          type="submit"
          variant="primary"
          size="lg"
          v-if="!success"
          class="w-100"
          :disabled="loading"
          data-test="tfa-setup-submit-button"
        >
          Enable
        </BaseButton>
        <BaseButton
          type="submit"
          variant="success"
          size="lg"
          class="w-100"
          v-if="success"
          :disabled="true"
          data-test="tfa-setup-success"
        >
          TFA Enabled
        </BaseButton>
      </div>
    </BaseForm>
  </SimplePage>
</template>
<script lang="ts">
import QRCode from "qrcode";
import { AxiosError } from "axios";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { eventStore, userStore } from "@/store";
import { Toast } from "@/mixins/Toast";

const SUCCESS_MESSAGE_DURATION = 1000;

@Component
export default class TFASetupForm extends Mixins(Toast) {
  @Prop({ default: false }) fullpage!: boolean;
  @Prop() modalId?: string;
  seedError = "";
  errorMessage = "";
  tfaResponse = "";
  tfaSeed = "";
  generatingSeed = true;
  success = false;
  loading = false;

  get user() {
    return userStore.getters.currentUser;
  }

  async created() {
    if (!this.user) {
      await userStore.actions.getCurrentUser();
    }

    this.updateTwoFactorSeed();
  }

  async updateTwoFactorSeed() {
    const serviceName = encodeURIComponent("Privacy.com");
    const email = this.user?.email || "";

    try {
      const seed = await userStore.actions.getTfaSeed();
      const tfaUrl = `otpauth://totp/${serviceName}:${email}?secret=${seed}&issuer=${serviceName}`;
      const canvas = document.getElementById("qrcode");
      this.tfaSeed = seed;

      QRCode.toCanvas(
        canvas,
        tfaUrl,
        {
          errorCorrectionLevel: "H",
          width: 160,
        },
        function (err: Error) {
          if (err) {
            eventStore.actions.error(err.message);

            throw err;
          }
        }
      );
    } catch (err) {
      this.seedError =
        (err as AxiosError<{ message?: string }>)?.response?.data?.message ||
        (err as Error).message ||
        "There was an unknown error. Please try again.";
    } finally {
      this.generatingSeed = false;
    }
  }

  async onSubmit() {
    this.errorMessage = "";

    if (!this.tfaResponse.trim()) {
      this.errorMessage = "Please enter your one-time access code";
      return;
    }

    this.loading = true;

    try {
      await userStore.actions.setupTfa(this.tfaResponse);

      this.success = true;

      setTimeout(() => {
        if (this.fullpage) {
          this.$router.push({
            name: "home",
          });
        } else if (this.modalId) {
          this.$bvModal.hide(this.modalId);
          this.successToast("Your 2FA settings have been updated.");
        }
      }, SUCCESS_MESSAGE_DURATION);
    } catch (err) {
      this.errorMessage =
        (err as AxiosError<{ message?: string }>)?.response?.data?.message ||
        "There was an error. Please try again.";
    } finally {
      this.loading = false;
      this.tfaResponse = "";
    }
  }
}
</script>
<style lang="scss" scoped>
h1 {
  all: unset;
  font-family: $font-stack-wes-fy;
  font-size: 24px;
  text-align: center;
  margin-bottom: 15px;
  line-height: 1.4;
  color: $gray-800;
}

.qrcode-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  height: 250px;
  box-shadow: inset 0 0 0 2px mix($gray-400, $gray-200, 25%);
  border-radius: $border-radius-lg;
  font-family: $font-stack-ocr;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 10px;
  color: $black;

  .spinner {
    position: absolute;
  }

  .key {
    margin-bottom: 20px;
  }

  .qrcode {
    height: 160px;
    width: 160px;
  }

  .key,
  .qrcode {
    transition: opacity $duration-short;
  }
}

.qrcode-container.-generating-seed .key,
.qrcode-container.-generating-seed .qrcode {
  opacity: 0;
}

#tfa-suggested-apps {
  border-bottom: 1px dotted $gray-600;
}

@media #{$media-phone} {
  .tfa-setup-form {
    padding: 30px;
    max-width: 100%;
  }
}
</style>
