<template>
  <BaseModal id="cash-out" size="md" @show="onShow">
    <div v-if="!loading" class="cash-out-wrapper">
      <h1 class="title">Cash Out</h1>
      <p class="subtitle">
        Withdraw funds from your Privacy account onto your default card.
      </p>
      <div class="input-wrapper">
        <CurrencyInput
          v-model="value"
          :minValue="minValue"
          :maxValue="maxValue"
        />
        <div class="input-controls">
          <b-input
            class="slider"
            type="range"
            :min="minValue"
            :max="maxValue"
            :step="step"
            v-model="value"
          />
          <p class="description" :class="{ '-visible': value === maxValue }">
            My entire balance
          </p>
        </div>
      </div>
      <div class="funding-card-wrapper">
        <FundingCardPreview :fundingCard="fundingCard" />
      </div>
      <div class="controls">
        <BaseButton
          :disabled="loadingSubmit"
          variant="primary"
          @click="onSubmit"
          data-test="cash-out-confirm"
        >
          Cash Out
        </BaseButton>
      </div>
    </div>
  </BaseModal>
</template>
<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Toast } from "@/mixins/Toast";
import { User } from "@/types/User";
import FundingCardPreview from "@/components/FundingCardPreview.vue";
import CurrencyInput from "@/components/CurrencyInput.vue";
import { FundingCard } from "@/types/Funding";
import { fundingCardStore, userStore } from "@/store";

@Component({
  components: {
    FundingCardPreview,
    CurrencyInput,
  },
})
export default class CashOut extends Mixins(Toast) {
  @Prop() user!: User;
  loading = true;
  loadingSubmit = false;
  value = 0;
  minValue = 1;
  maxValue = 0;
  step = 1;
  fundingCard!: FundingCard;

  onShow() {
    this.loading = true;
    this.value = 0;
    fundingCardStore.actions
      .getCardFromUser({ user: this.user })
      .then((card) => {
        this.fundingCard = card;
        const max = this.user.accountBalances?.available;

        if (!max) {
          this.errorToast(
            "This card does not have any available cash to withdraw"
          );
          this.close();
          return;
        }

        this.maxValue = parseFloat(max) / 100;

        this.loading = false;
      })
      .catch(() => {
        this.errorToast("There is no active card on this account.");
        this.close();
      });
  }

  onSubmit() {
    if (!this.value) {
      return this.errorToast("Please enter a valid amount to cash out");
    }

    if (this.value < this.minValue) {
      return this.errorToast(
        `You must cash out at least $${this.minValue.toFixed(2)}`
      );
    }

    if (!this.fundingCard?.uuid) {
      return this.errorToast("You don't have an active connected card");
    }

    this.loadingSubmit = true;

    const amount = this.value * 100;

    fundingCardStore.actions
      .withdraw({
        uuid: this.fundingCard.uuid,
        amount,
      })
      .then(() => {
        const accountBalances =
          this.user?.accountBalances ||
          userStore.getters.defaultAccountBalances;

        const newBalances = {
          ...accountBalances,
          available: String(parseInt(accountBalances.available) - amount),
          cash: String(parseInt(accountBalances.cash) - amount),
        };

        userStore.mutations.updateCurrentUser({ accountBalances: newBalances });

        this.$emit("refresh-funding-sources");

        this.successToast(`$${this.value} cashed out successfully!`);
        this.close();
      })
      .catch((err) => {
        this.errorToast(
          err?.response?.data?.message ||
            "There was an error while cashing out - please try again later"
        );
      })
      .finally(() => {
        this.loadingSubmit = false;
      });
  }

  close() {
    this.$bvModal.hide("cash-out");
  }
}
</script>
<style lang="scss" scoped>
.cash-out-wrapper {
  max-width: 300px;
  margin: 0 auto;
  padding: 0 2.5em 2.5em;
}

.title {
  font-size: 24px;
  font-family: $font-stack-wes-fy;
  text-align: center;
  margin-bottom: 40px;
}

.input-wrapper > .input-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-wrapper > .input-controls > .description {
  color: $blue;
  opacity: 0;
  transition: 100ms;
  margin-bottom: 0;
  user-select: none;

  &.-visible {
    opacity: 1;
    user-select: auto;
  }
}

.input-wrapper > .input-controls > button {
  width: calc(50% - 1px);
  background: $gray-100;
  color: $gray-800;
  font-size: 28px;
  outline: none !important;

  &:hover {
    background: $gray-300;
  }

  &:first-of-type {
    border-radius: 0 0 0 $radius-large;
  }

  &:last-of-type {
    border-radius: 0 0 $radius-large 0;
  }
}

.funding-card-wrapper {
  margin-top: 20px;
}

.controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.controls > button {
  width: 100%;
}
</style>
