<template>
  <button
    :class="[
      'toggle-switch',
      `-color-${color}`,
      `-size-${size}`,
      { '-on': value },
    ]"
    @click="onClickHandler"
    v-bind="$attrs"
    :aria-pressed="value"
    :aria-label="label"
  />
</template>
<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

type Color = "privacy-red" | "gold" | "blue";

@Component({})
export default class ToggleSwitch extends Vue {
  @Prop({ default: false }) value!: boolean;
  @Prop({ required: false }) color?: Color;
  @Prop({ required: false }) size?: "small";
  @Prop() onClick?: (value?: boolean) => void;
  @Prop({ default: false }) label!: string;

  onClickHandler() {
    if (!this.onClick) {
      this.value = !this.value;
      return;
    }

    this.onClick(!!this.value);
  }
}
</script>
<style lang="scss" scoped>
$padding: 2px;

$height-default: 34px;
$height-small: 26px;

$width-default: 54px;
$width-small: 42px;

@mixin grabber-dimensions($height) {
  height: calc(#{$height} - #{$padding * 2});
  width: calc(#{$height} - #{$padding * 2});
}

.toggle-switch {
  display: inline-block;
  padding: $padding;
  height: $height-default;
  width: $width-default;
  background-color: $color-shadow-black-faint;
  box-shadow: inset 0 0 0 1px $color-shadow-black-faint;
  border-radius: $height-default;
  transition-property: opacity, background-color;
  transition-duration: $duration-shorter;
  cursor: pointer;
  user-select: none;
  border: none;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    filter: brightness(95%);
  }

  &::after {
    @include grabber-dimensions($height-default);

    content: "";
    display: block;
    background-color: $white;
    box-shadow:
      0 2px 4px $color-shadow-black,
      0 0 0 1px $color-shadow-black-faint;
    border-radius: 100%;
    transition: margin-left $duration-short $ease-out-expo;
  }

  &.-size-small {
    height: $height-small;
    width: $width-small;

    &::after {
      @include grabber-dimensions($height-small);
    }
  }

  &.-on::after {
    // I should write a mixin to calculate this
    margin-left: 20px;
  }

  &.-on.-size-small::after {
    margin-left: 16px;
  }

  // Colors

  &.-on {
    background-color: $color-green;
  }

  &.-on.-color-privacy-red {
    background-color: $color-privacy-red;
  }

  &.-on.-color-gold {
    background-color: $color-pastel-yellow;
  }

  &.-on.-color-blue {
    background-color: $blue;
  }
}
</style>
