import axios from "axios";
import { Event, PlaidEvent } from "@/types/Event";
import { ActionHandler } from "./vuex-typex";
import { RootState, storeBuilder } from "./storeBuilder";

class EventState {}

const builder = storeBuilder.module<EventState>("event", new EventState());

type EventAction<Payload = void, Type = void> = ActionHandler<
  EventState,
  RootState,
  void,
  Payload,
  Type
>;

const base = "/api/v1/event";

const record: EventAction<Event> = (_x, event: Event) => {
  if (window.document.referrer) {
    if (!event.data) {
      event.data = {};
    }
    event.data.documentReferrer = window.document.referrer;
  }
  return axios.post(base, { event });
};

const info: EventAction<Event | PlaidEvent> = (
  _x,
  event: Event | PlaidEvent
) => {
  return axios.post(base + "/info", { event });
};

const error: EventAction<Event | PlaidEvent | string> = (
  _x,
  event: Event | PlaidEvent | string
) => {
  return axios.post(base + "/error", { event });
};

export const actions = {
  record: builder.dispatch(record),
  info: builder.dispatch(info),
  error: builder.dispatch(error),
};
