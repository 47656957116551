<template>
  <div
    class="funding-preview d-flex align-items-center"
    :class="`funding-preview-${size}`"
    v-on="$listeners"
  >
    <SVGIcon v-if="isCard" :icon="cardIcon" size="40" class="funding-icon" />
    <img v-else :src="source.icon" alt="" class="funding-icon" />
    <div>
      <div class="funding-name" v-if="source.name">{{ source.name }}</div>
      <b-badge variant="tertiary" v-if="source.default"> Default </b-badge>
      <span class="funding-details">
        {{ institution }}
        &middot;&middot;&middot;
        {{ source.number }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { FundingType, V2FundingSource } from "@/types/Funding";
import SVGIcon from "@/components/SVGIcon.vue";

const cardNetworksWithLogo = ["visa", "mastercard", "discover"];
const networkBase = "cards/";

type Sizes = "sm" | "md" | "lg";

@Component({
  components: {
    SVGIcon,
  },
})
export default class FundingPreview extends Vue {
  @Prop({ required: true }) source!: V2FundingSource;
  @Prop({ default: "md" }) size?: Sizes;

  get isCard() {
    return this.source.type === FundingType.CARD;
  }

  get institution() {
    return this.source.institution?.toLowerCase();
  }

  get cardIcon() {
    const network = this.source.institution?.toLowerCase();
    return cardNetworksWithLogo.includes(network)
      ? `${networkBase}${network}`
      : "";
  }
}
</script>

<style lang="scss" scoped>
.funding-preview {
  background-color: $gray-100;
  border-radius: $border-radius;
  font-family: $font-stack-graphik;
  padding: 16px;
  color: $gray-800;

  &.funding-preview-sm {
    padding: 10px 12px;
  }
}

.funding-icon {
  width: 40px;
  margin-right: 16px;
}

.funding-name {
  font-weight: 500;
}

.funding-details {
  text-transform: capitalize;
  color: $gray-600;
}

.badge-tertiary {
  background-color: #e4e4e8;
  font-weight: normal;
  font-size: 12px;
}
</style>
