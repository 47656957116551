<template>
  <KybVerificationContainer>
    <BaseForm class="account-purpose">
      <h3 class="heading mb-4">How are you using this Privacy account?</h3>
      <BaseButton
        variant="light"
        type="submit"
        size="lg"
        class="mb-2"
        @click="submit(AccountPurposes.PERSONAL)"
      >
        For my personal spending
      </BaseButton>
      <BaseButton
        variant="light"
        type="submit"
        size="lg"
        @click="submit(AccountPurposes.BUSINESS)"
      >
        For my business spending
      </BaseButton>
    </BaseForm>
  </KybVerificationContainer>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { AccountPurposes } from "@/types/User";
import { Toast } from "@/mixins/Toast";
import { userStore } from "@/store";
import BaseButton from "@/components/BaseButton.vue";
import KybVerificationContainer from "./KybVerificationContainer.vue";

@Component({
  components: {
    KybVerificationContainer,
    BaseButton,
  },
})
export default class AccountPurpose extends Mixins(Toast) {
  AccountPurposes = AccountPurposes;

  async created() {
    await userStore.actions.getCurrentUser();
    this.$piwik.trackEvent("Modal", "View", {
      name: "Verification Account Purpose",
    });
    userStore.actions.updateVerificationStep("kybAccountPurpose");
  }

  submit(purpose: AccountPurposes): void {
    userStore.actions
      .setAccountPurpose(purpose)
      .then(() => {
        if (purpose === AccountPurposes.BUSINESS) {
          this.$piwik.trackClick({
            name: "Verification Account Purpose Business CTA",
          });
          this.$router.push({ name: "kybBusinessType" });
        } else {
          this.$piwik.trackClick({
            name: "Verification Account Purpose Personal CTA",
          });
          this.$router.push({ name: "kybConsumerChargeDisclosure" });
        }
      })
      .catch((err) => {
        this.errorToast(err.response?.data?.message || "There was an error");
      });
  }
}
</script>
