<template>
  <div class="onboarding-footer-actions" data-test="onboarding-footer-actions">
    <b-link
      v-if="showChangeAccountType"
      @click.prevent="changeAccountType"
      data-test="change-account-type"
    >
      Change Account Type
    </b-link>
    <div
      class="spacer"
      v-if="showChangeAccountType && showFinishLater"
      data-test="spacer"
    ></div>
    <b-link
      v-if="showFinishLater"
      @click.prevent="finishLater"
      data-test="finish-later"
    >
      Finish Later
    </b-link>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Mixins } from "vue-property-decorator";
import { userStore } from "@/store";
import { OnboardingSteps } from "@/types/Onboarding";
import { Confirm } from "@/mixins/Confirm";

@Component
export default class OnboardingFooterActions extends Mixins(Confirm) {
  @Prop({ default: false }) allowChangeAccountType!: boolean;
  @Prop({ default: false }) showFinishLater!: boolean;
  @Prop({ default: false }) hasEnteredData!: boolean;

  get showChangeAccountType(): boolean {
    return (
      this.allowChangeAccountType &&
      !userStore.getters.currentUser?.hasHadAnyFundingSource
    );
  }

  get isRemediatingKYC(): boolean {
    return !!userStore.getters.currentUser?.isRemediatingKYC;
  }

  changeAccountType(): void {
    this.$piwik.trackClick({
      name: `Onboarding Change Account Type`,
    });

    this.$emit("footer-action", OnboardingSteps.PURPOSE);
  }

  finishLater(): void {
    this.$piwik.trackClick({
      name: `Onboarding Finish Later`,
    });

    if (this.hasEnteredData) {
      const h = this.$createElement;
      const titleNode = h("div", {}, [
        h("b-img", {
          props: { src: "/assets/images/business/finish-later.svg", alt: "" },
        }),
        h("h3", { class: "mt-3" }, ["Are you sure you want to finish later?"]),
      ]);

      this.confirm("Your information on this screen will not be saved.", {
        title: [titleNode],
        okTitle: "Confirm",
        cancelTitle: "Never mind",
        size: "md",
        dialogClass: "px-5",
        headerClass: "justify-content-center text-center p-0",
        bodyClass: "text-center confirm-body px-0 py-3",
        footerClass: "d-flex flex-column-reverse align-items-stretch",
        bodyTextVariant: "secondary",
        cancelVariant: "link",
      }).then((confirmed) => {
        if (confirmed) {
          this.$piwik.trackClick({
            name: `Onboarding Finish Later Confirm`,
          });

          this.$emit("footer-action", OnboardingSteps.COMPLETE);
        } else {
          this.$piwik.trackClick({
            name: `Onboarding Finish Later Cancel`,
          });
        }
      });
    } else if (this.isRemediatingKYC) {
      this.confirm(
        "You’ll need to submit this information to reactivate your Privacy Account.",
        {
          title: "Finish Later",
          okTitle: "Got it",
          okOnly: true,
          size: "md",
          dialogClass: "px-5",
          bodyClass: "text-center confirm-body px-2 py-4",
          footerClass: "d-flex flex-column-reverse align-items-stretch",
          bodyTextVariant: "secondary",
        }
      ).then((confirmed) => {
        if (confirmed) {
          this.$piwik.trackClick({
            name: `Onboarding Remediation Finish Later Confirm`,
          });

          this.$emit("footer-action", OnboardingSteps.COMPLETE);
        } else {
          this.$piwik.trackClick({
            name: `Onboarding Remediation Finish Later Cancel`,
          });
        }
      });
    } else {
      this.$emit("footer-action", OnboardingSteps.COMPLETE);
    }
  }
}
</script>

<style lang="scss" scoped>
.onboarding-footer-actions {
  margin-top: 1rem;
  display: flex;
  justify-content: center;

  a {
    color: $gray-600;
    font-weight: 700;
  }

  .spacer {
    flex: 1;
  }
}
</style>
