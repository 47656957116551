<template>
  <label class="field">
    <div class="title" :class="{ disabled }">{{ label }}</div>
    <b-form-select
      v-bind="$attrs"
      :value="value"
      @input="handleInput"
      :class="{ placeholder: !value }"
      :options="optionsWithPlaceholder"
      :disabled="disabled"
    ></b-form-select>
  </label>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { SelectOption } from "../types/Form";

@Component({
  inheritAttrs: false,
})
export default class BaseSelect extends Vue {
  @Prop({ default: "" }) label!: string;
  @Prop() value?: string;
  @Prop() options!: SelectOption[];
  @Prop() placeholder?: string;
  @Prop({ default: false }) disabled!: boolean;

  handleInput(value: string) {
    this.$emit("input", value);
  }

  get optionsWithPlaceholder() {
    const newOptions: SelectOption[] = [];
    if (this.placeholder) {
      newOptions.push({ value: "", text: this.placeholder, disabled: true });
    }

    return newOptions.concat(this.options);
  }
}
</script>

<style lang="scss" scoped>
.field {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.25rem 3rem;
  cursor: text;

  .title {
    padding: 0.25rem 0 0;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 0.8;
    pointer-events: none;
    z-index: 1;

    &.disabled {
      opacity: 0.5;
    }
  }

  ::v-deep select {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 2.5rem;

    &:enabled:hover {
      background-color: $gray-200;
      border-color: $gray-200;
    }

    &:disabled {
      opacity: 1;
    }

    &.placeholder {
      color: $gray-400;
    }
  }
}
</style>
