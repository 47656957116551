import axios, { AxiosResponse } from "axios";
import Vue from "vue";
import { FundingBank } from "@/types/Funding";
import { instIDtoBankType, BankState } from "@/types/Bank";
import { ActionHandler, MutationHandler } from "./vuex-typex";
import { RootState, storeBuilder } from "./storeBuilder";
import { userStore } from ".";

const defaultState: BankState = {
  confirmingBank: undefined,
};

const builder = storeBuilder.module<BankState>("bank", defaultState);

type BankMutation<Payload = void> = MutationHandler<BankState, Payload>;
type BankAction<Payload = void, Type = void> = ActionHandler<
  BankState,
  RootState,
  any,
  Payload,
  Type
>;

const base = "/api/v1/bank";

const setDefault: BankAction<{
  bankAccountID: FundingBank["bankAccountID"];
  persistOldDefaultFunding?: boolean;
}> = (context, { bankAccountID, persistOldDefaultFunding = false }) => {
  // When `persistDefaultFunding` is set to true, all current cards
  // that uses the default funding source will be fixed to the
  // default funding source before we set a new one as default.
  return axios.post(`${base}/${bankAccountID}/default`, {
    persistOldDefaultFunding,
  });
};

const deleteBank: BankAction<{
  bankAccountID: FundingBank["bankAccountID"];
}> = (context, { bankAccountID }) => {
  return axios.post(`${base}/state`, { bankAccountID, state: "deleted" });
};

const editNickname: BankAction<{
  bankAccountID: FundingBank["bankAccountID"];
  nickname: string;
}> = (context, { bankAccountID, nickname }) => {
  return axios.post(`${base}/nickname`, { bankAccountID, nickname });
};

const getBankIconFromInstID = builder.read(
  () =>
    ({ instID, src = false }: { instID: string; src?: boolean }): string => {
      const bankName = instIDtoBankType[instID];
      if (bankName) {
        return !src ? bankName : `/assets/images/banks/${bankName}@2x.png`;
      }
      return !src
        ? "bank"
        : `https://s3.amazonaws.com/privacy-web/images/bank/${instID}.png`;
    },
  "getBankIconFromInstID"
);

const confirmingBank = builder.read(
  (state) => state.confirmingBank,
  "confirmingBank"
);

export const getters = {
  get getBankIconFromInstID() {
    return getBankIconFromInstID();
  },
  get confirmingBank() {
    return confirmingBank();
  },
};

const setConfirmingBank: BankMutation<FundingBank> = (state, value) => {
  state.confirmingBank = value;
};

const unsetConfirmingBank: BankMutation<void> = (state) => {
  state.confirmingBank = undefined;
};

export const mutations = {
  setConfirmingBank: builder.commit(setConfirmingBank),
  unsetConfirmingBank: builder.commit(unsetConfirmingBank),
};

const createLinkToken: BankAction<void, AxiosResponse> = () => {
  return axios.get(`${base}/createLinkToken`);
};

const createUpdateLinkToken: BankAction<FundingBank["uuid"], AxiosResponse> = (
  _context,
  bankAccountUuid = ""
) => {
  return axios.post(`${base}/createUpdateLinkToken`, { bankAccountUuid });
};

const refreshBankToken: BankAction<FundingBank["uuid"], AxiosResponse> = (
  _context,
  bankAccountUuid = ""
) => {
  return axios.post(`${base}/refreshBankToken`, { bankAccountUuid });
};

const list: BankAction<string | void, AxiosResponse> = (
  _context,
  bankAccountID = ""
) => {
  return axios.get(`${base}/${bankAccountID}`);
};

const postConnectionHooks: BankAction = () => {
  if (Vue.$cookies.get("utm_source") === "taboola") {
    const image = document.createElement("img");
    const encodedURL = encodeURIComponent(window.location.href);
    image.setAttribute(
      "src",
      "//trc.taboola.com/ladderio2-sc/log/3/action?name=" +
        `Privacy_download&item-url=${encodedURL}" width="0" height="0" />`
    );
    document.body.appendChild(image);
  }
};

const addLongTail: BankAction<
  {
    publicToken: string;
    plaidAccountID: string;
    bankName: string;
    institutionID: string;
  },
  AxiosResponse
> = (_context, { publicToken, plaidAccountID, bankName, institutionID }) => {
  return axios
    .post(`${base}/link`, {
      publicToken,
      plaidAccountID,
      bankName,
      institutionID,
    })
    .then((response) => {
      if (response.data.isPendingBusinessReview) {
        userStore.mutations.updateCurrentUser({
          isPendingBusinessReview: true,
        });
      }
      return response;
    });
};

const addManually: BankAction<{ [key: string]: any }, AxiosResponse> = (
  _context,
  bankAccount
) => {
  return axios.post(`${base}/connect`, bankAccount);
};

const search: BankAction<string, AxiosResponse> = (_context, keyword) => {
  return axios.get(`${base}/search/${keyword}`);
};

const validateRoutingNumber: BankAction<string, AxiosResponse> = (
  _context,
  routingNumber
) => {
  return axios.post(`${base}/aba/validate`, { routingNumber });
};

const confirmMicroDeposits: BankAction<
  {
    bankAccountID: string;
    amount1: number;
    amount2: number;
  },
  AxiosResponse
> = (_context, { bankAccountID, amount1, amount2 }) => {
  return axios
    .post(`${base}/validate`, {
      bankAccountID,
      amount1,
      amount2,
    })
    .then((response) => {
      if (response.data.isPendingBusinessReview) {
        userStore.mutations.updateCurrentUser({
          isPendingBusinessReview: true,
        });
      }
      return response;
    });
};

const requestAlternateFunding: BankAction = () => {
  return axios.post(`${base}/addToAlternateFundingQueue`);
};

export const actions = {
  setDefault: builder.dispatch(setDefault),
  delete: builder.dispatch(deleteBank),
  editNickname: builder.dispatch(editNickname),
  list: builder.dispatch(list),
  postConnectionHooks: builder.dispatch(postConnectionHooks),
  createLinkToken: builder.dispatch(createLinkToken),
  createUpdateLinkToken: builder.dispatch(createUpdateLinkToken),
  refreshBankToken: builder.dispatch(refreshBankToken),
  addLongTail: builder.dispatch(addLongTail),
  addManually: builder.dispatch(addManually),
  search: builder.dispatch(search),
  validateRoutingNumber: builder.dispatch(validateRoutingNumber),
  confirmMicroDeposits: builder.dispatch(confirmMicroDeposits),
  requestAlternateFunding: builder.dispatch(requestAlternateFunding),
};
