import { FundingBank } from "./Funding";

export const instIDtoBankType: { [key: string]: string } = {
  ins_1: "bofa",
  ins_3: "chase",
  ins_4: "wells",
  ins_5: "citi",
  ins_6: "us",
  ins_7: "usaa",
  ins_9: "capone",
  ins_11: "schwab",
  ins_12: "fidelity",
  ins_13: "pnc",
  ins_14: "td",
  ins_15: "nfcu",
  ins_16: "suntrust",
  ins_24: "simple",
  ins_25: "ally",
  ins_56: "chase",
  ins_127989: "bofa",
  ins_127990: "us",
  ins_127991: "wells",
  ins_128026: "capone",
};

export interface Bank {
  accountID?: string;
  bankAccountID?: string;
  token: string;
  activeBalance: number;
  bankName?: string;
  bank?: string;
  accountNumber: number;
  // eslint-disable-next-line
  institution_id?: string;
}

export interface BankState {
  confirmingBank?: FundingBank;
}
