<template>
  <div class="currency-input-wrapper" data-test="currency-input-wrapper">
    <input
      class="input"
      v-bind="$attrs"
      v-model="inputVal"
      data-test="currency-input"
    />
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CurrencyInput extends Vue {
  @Prop({ default: 0 }) value!: number;
  @Prop() minValue?: number;
  @Prop() maxValue?: number;
  lastKnownGoodInput = "$0";

  // we do some funky logic here to handle cases where a user types in
  // 20.0 before typing 20.01, we don't want to blindly convert to a number
  // removing the trailing zero
  set inputVal(valueRaw: string) {
    // if invalid format, return the last good input
    const format = /^[$]?(([0-9]+)?[.]?[0-9]{0,2})?$/;
    if (!format.test(valueRaw)) {
      // forceUpdate is necessary because vue doesn't re-render if the same
      // value is emitted
      this.$forceUpdate();
      this.$emit("input", this.lastKnownGoodInput);
      return;
    }

    const value = valueRaw.replace("$", "") || "0";
    let final = parseFloat(value);

    // Only check max value.
    // Replacing current value with min value can interrupt user typing.
    // Do this validation in the parent component!
    if (this.maxValue && final > this.maxValue) {
      final = this.maxValue;
    }

    const isLimit = String(final) !== value;

    const formatted = this.formatNumber(isLimit ? String(final) : value);

    this.$forceUpdate();
    this.lastKnownGoodInput = formatted;
    this.$emit("input", this.lastKnownGoodInput);
  }

  get inputVal() {
    return `$${this.value}`;
  }

  formatNumber(value: string) {
    const [int, dec] = value.split(".");
    const transformed =
      parseInt(int || "0") + (dec === undefined ? "" : `.${dec}`);

    return transformed;
  }
}
</script>
<style lang="scss" scoped>
.currency-input-wrapper > .input {
  margin-bottom: 2px;
  height: 138px;
  width: 100%;
  min-width: 0px;
  background: $gray-100;
  border: 0;
  border-radius: $radius-large;
  font-family: $font-stack-wes-fy;
  font-size: 48px;
  line-height: 84px;
  text-align: center;
  color: $gray-800;
  outline: none;

  @media #{$media-phone} {
    font-size: 48px;
    line-height: 60px;
  }
}
</style>
