<template>
  <SimplePage :fullpage="fullpage">
    <div class="application-complete" data-test="application-complete">
      <img src="/assets/images/business/plan-icon-pro.svg" alt="" />
      <h1>Application Complete!</h1>
      <div class="description">
        We’ll review your application and follow up within a few days
      </div>
      <BaseButton
        variant="primary"
        size="lg"
        @click="onCompleteStep"
        data-test="go-to-dashboard"
      >
        Go to Dashboard
      </BaseButton>
    </div>
  </SimplePage>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component
export default class ApplicationComplete extends Vue {
  @Prop({ default: false }) fullpage!: boolean;

  onCompleteStep() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.APPLICATION_COMPLETE)} Primary CTA`,
    });

    this.$emit("complete-step");
  }
}
</script>
<style lang="scss">
.application-complete {
  max-width: 340px;
  margin: 0 auto;
  display: grid;
  gap: 20px;
  text-align: center;
  color: $gray-600;

  img {
    margin: 50px auto 20px;
    height: 70px;
  }

  h1 {
    all: unset;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    color: $gray-800;
  }

  .description {
    color: $gray-600;
    max-width: 200px;
    margin: 0 auto;
  }

  button {
    width: 100%;
    margin-bottom: 30px;
  }
}
</style>
