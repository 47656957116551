export enum RepaymentMethod {
  CARD = "card",
  BANK = "bank",
}

export interface Repayment {
  caseNumber: string;
  amount: number;
  method: RepaymentMethod;
  ccNumber?: string;
  cvv?: string;
  expDate?: string;
  bankAccountNumber?: string;
  routingNumber?: string;
}
