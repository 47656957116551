<template>
  <div class="container">
    <div class="logo-wrapper">
      <PrivacyLogo href="/" class="privacy-logo" aria-label="Go to Home" />
    </div>
    <div class="title">Dispute Transaction</div>
    <div class="content-wrapper" v-if="loaded && transaction">
      <div class="transaction-info">
        <TransactionInspector
          :transactionProp="transaction"
          :fullPage="true"
          @close-transaction-dispute="confirmCloseTransactionDispute"
        />
      </div>
      <div class="dispute-container">
        <div v-if="eligibleForDispute" data-test-id="dispute-eligible">
          <DisputeReason :transaction="transaction" :fullPage="true" />
        </div>
        <div v-else data-test-id="dispute-already-open">
          You can expect a response from our support team within 3 business days
          from when the dispute was submitted. Please email
          <b-link href="mailto:support@privacy.com">support@privacy.com</b-link>
          to check on the status of your dispute.
        </div>
      </div>
    </div>
    <div class="content-wrapper" v-if="loaded && !transaction">
      <div class="transaction-info" data-test-id="dispute-transaction-error">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import DisputeReason from "@/components/DisputeReason.vue";
import PrivacyLogo from "@/components/PrivacyLogo.vue";
import TransactionInspector from "@/components/TransactionInspector.vue";
import { Component, Mixins } from "vue-property-decorator";
import { transactionStore } from "@/store/";
import { Transaction as TransactionType } from "@/types/Transaction";
import { DisputeType } from "@/types/Dispute";
import { Toast } from "@/mixins/Toast";
import { Confirm } from "@/mixins/Confirm";

@Component({
  components: {
    DisputeReason,
    PrivacyLogo,
    TransactionInspector,
  },
})
export default class OpenDispute extends Mixins(Toast, Confirm) {
  loaded = false;
  error = false;
  transaction?: TransactionType | null = null;

  errorMessage = "The transaction was not found.";

  async created() {
    try {
      const response = await transactionStore.actions.fetchTransaction(
        this.$route.params.transactionUuid
      );

      if (response?.total !== 1) {
        throw new Error("Transaction not found");
      }

      this.transaction = response.data[0];
      this.loaded = true;
    } catch (e) {
      const err = e as Error;
      this.errorToast(err.message);
    }
  }

  get eligibleForDispute() {
    return (
      this.transaction &&
      this.transaction.dateSettled &&
      this.transaction.state !== DisputeType.DISPUTE_OPEN
    );
  }

  confirmCloseTransactionDispute(): void {
    this.confirm("Are you sure?", {
      title: "Close Dispute?",
    }).then((resp) => {
      if (resp) {
        this.closeTransactionDispute(this.transaction!);
      }
    });
  }

  closeTransactionDispute(transaction: TransactionType) {
    transactionStore.actions
      .cancelDispute(transaction.transactionID)
      .then(() => {
        this.$router.push({ name: "home" });
      });
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  > .logo-wrapper {
    min-height: 40px;

    > .privacy-logo {
      position: relative;
      top: 20px;
      left: auto;
      right: auto;
    }
  }

  .title {
    position: relative;
    left: auto;
    right: auto;
    padding-top: 16px;
    font-family: $font-stack-wes-fy;
    font-size: 20px;
  }

  .content-wrapper {
    width: 600px;

    @media #{$media-phone} {
      width: unset;
    }
  }

  .transaction-info {
    padding: 30px;
    margin-top: 30px;
    background-color: #efefef;
    border-radius: 10px;
  }

  .dispute-container {
    padding: 20px;
  }
}
</style>
