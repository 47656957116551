<template>
  <div class="meter">
    <div class="icon" v-if="password.length">
      <img
        :src="`/assets/images/onboarding/strength-${score}.png`"
        width="24"
        alt=""
        aria-hidden="true"
      />
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      class="svg"
      aria-hidden="true"
    >
      <circle
        class="fill"
        fill="transparent"
        :stroke="strokeColor"
        stroke-width="4"
        stroke-miterlimit="10"
        :stroke-dashoffset="strokeDashoffset"
        stroke-linecap="round"
        cx="25"
        cy="25"
        r="23"
      />
    </svg>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ZXCVBNResult } from "zxcvbn";

const PASSWORD_MIN_SCORE = 2;
const PASSWORD_MIN_LENGTH = 8;
const SCORE_MULTIPLIER = 4;
const STROKE_COLORS = ["#FD482C", "#FFD036", "#95E645", "#28DB67", "#28DB67"];

interface CheckResult {
  score: ZXCVBNResult["score"];
  feedback: {
    warning: ZXCVBNResult["feedback"]["warning"] | string;
    suggestions: ZXCVBNResult["feedback"]["suggestions"] | string[];
  };
}

@Component({
  metaInfo: {
    script: [
      {
        src: "/assets/js/zxcvbn.js",
        async: true,
        defer: true,
      },
    ],
  },
})
export default class PasswordStrengthMeter extends Vue {
  @Prop({ default: "" }) password!: string;
  @Prop({ default: "" }) email!: string;

  score: ZXCVBNResult["score"] = 0;

  get strokeDashoffset() {
    const fill = Math.min(
      28.8 * this.score + this.password.length * SCORE_MULTIPLIER
    );

    return Math.max(144 - fill, 0);
  }

  get strokeColor() {
    return this.password.length ? STROKE_COLORS[this.score] : "transparent";
  }

  @Watch("password")
  @Watch("email")
  updateScore() {
    let result: CheckResult;
    const lowerCaseEmail = this.email.toLowerCase();
    const lowerCasePassword = this.password.toLowerCase();

    if (!window.zxcvbn || !this.password) {
      result = {
        score: 0,
        feedback: {
          warning: "Password must be provided",
          suggestions: ["Enter a password"],
        },
      };
    } else if (this.password.trim().length < PASSWORD_MIN_LENGTH) {
      result = {
        score: 0,
        feedback: {
          warning: `Password must be at least ${PASSWORD_MIN_LENGTH} characters in length`,
          suggestions: ["Increase password length"],
        },
      };
    } else if (
      lowerCaseEmail.length &&
      lowerCasePassword.includes(lowerCaseEmail)
    ) {
      result = {
        score: 0,
        feedback: {
          warning: `Password must not contain your email address`,
          suggestions: ["Remove the email address from the password"],
        },
      };
    } else {
      result = window.zxcvbn!(this.password);
    }

    this.score = result.score;
    this.$emit("update", {
      valid: this.score >= PASSWORD_MIN_SCORE,
      feedback: result.feedback,
    });
  }
}

@Component
export class PasswordStrengthMixin extends Vue {
  passwordValid = false;
  passwordFeedback = "Enter a secure password";

  updatePasswordValidity({
    valid,
    feedback,
  }: {
    valid: boolean;
    feedback: any;
  }) {
    this.passwordValid = valid;

    if (valid) {
      this.passwordFeedback = "Password is valid!";
    } else {
      this.passwordFeedback = "Invalid password - " + feedback.warning;
    }
  }
}
</script>
<style lang="scss" scoped>
.meter {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 20px;
  height: 50px;
  width: 50px;
  box-shadow: inset 0 0 0 4px $shadow-black;
  border-radius: 50%;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.svg {
  transform: rotate(-90deg);
  transform-origin: center;
}

.fill {
  stroke-dasharray: 144;
  transition: all 200ms ease-out;
  transition-timing-function: cubic-bezier(0.25, 0, 0.25, 1);
}
</style>
