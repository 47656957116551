<template>
  <KybVerificationContainer>
    <h3 class="heading">Verification Complete</h3>
    <div class="w-100">
      <img
        src="/assets/images/kyb-verification/checkmark.svg"
        alt=""
        style="height: 80px; width: 64px"
      />
    </div>
    <p v-if="isSoleProprietor" class="pb-0 mb-0">
      Thank you for confirming this information! You can continue to pay with
      Privacy.
    </p>
    <p v-else class="pb-0 mb-0">
      Thank you for confirming your information. Your account has been confirmed
      as a Privacy Personal account for personal spending.
    </p>
    <div class="divider"></div>
    <BaseButton variant="primary" class="cta-btn" @click="nextStep">
      Go to my Privacy Dashboard
    </BaseButton>
  </KybVerificationContainer>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { Toast } from "@/mixins/Toast";
import { userStore } from "@/store";
import BaseButton from "@/components/BaseButton.vue";
import KybVerificationContainer from "./KybVerificationContainer.vue";

@Component({
  components: {
    BaseButton,
    KybVerificationContainer,
  },
})
export default class KybSuccess extends Mixins(Toast) {
  isSoleProprietor: boolean | null = null;

  get kybUser() {
    return userStore.getters.currentUser!.kybVerificationUser;
  }

  get piwikPrefix() {
    return this.kybUser ? "Verification" : "Onboarding";
  }

  nextStep() {
    this.$piwik.trackClick({
      name: `${this.piwikPrefix} Complete CTA`,
    });
    this.$router.push({ name: "home" });
  }

  created() {
    userStore.actions.getCurrentUser();
    if (this.$route.params.isSoleProprietor) {
      this.isSoleProprietor = this.$route.params.isSoleProprietor === "true";
    }
  }

  mounted() {
    this.$piwik.trackEvent("Modal", "View", {
      name: `${this.piwikPrefix} Complete`,
    });
  }
}
</script>
