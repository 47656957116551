<template>
  <b-dropdown
    variant="light"
    aria-label="Card sort menu"
    block
    no-caret
    class="sort-dropdown"
    menu-class="w-100"
    data-test="card-sort-dropdown"
  >
    <template #button-content>
      <SVGIcon
        icon="arrow-down"
        v-if="currentCardsSortOrder === 'DESC'"
        data-test="card-sort-dropdown-DESC-arrow"
      ></SVGIcon>
      <SVGIcon
        icon="arrow-up"
        v-if="currentCardsSortOrder === 'ASC'"
        data-test="card-sort-dropdown-ASC-arrow"
      ></SVGIcon>
      <span class="text">
        {{ getSortFieldTexyById(currentCardsSortField) }}
      </span>
      <SVGIcon icon="chevron-down"></SVGIcon>
    </template>

    <b-dropdown-group>
      <b-dropdown-item
        v-for="cardSortField in cardSortFields"
        :key="cardSortField.id"
        @click="setCardSortFieldAndSortOrder(cardSortField.id)"
        :active="cardSortField.id === currentCardsSortField"
        :data-test="`card-sort-dropdown-item-${cardSortField.id}`"
      >
        {{ cardSortField.text }}
        <span class="float-right">
          <SVGIcon icon="arrow-up" v-if="showSortArrow(cardSortField, 'ASC')" />
          <SVGIcon
            icon="arrow-down"
            v-if="showSortArrow(cardSortField, 'DESC')"
          />
        </span>
      </b-dropdown-item>
    </b-dropdown-group>
  </b-dropdown>
</template>
<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { CardSortField, CardSortOrder } from "@/types/Card";
import { Prop } from "vue-property-decorator";
import { cardStore } from "@/store";
import SVGIcon from "./SVGIcon.vue";

@Component({ components: { SVGIcon } })
export default class CardSortDropdown extends Vue {
  @Prop() currentCardsSortField!: CardSortField;
  @Prop() currentCardsSortOrder!: CardSortOrder;

  cardSortFields = [
    { id: CardSortField.RECENTLY_USED, text: "Recently Used" },
    { id: CardSortField.CREATED, text: "Date Created" },
    { id: CardSortField.MEMO, text: "Name" },
    { id: CardSortField.TOTAL_SPEND, text: "Total Spend" },
  ];

  getSortFieldTexyById(id: CardSortField) {
    return this.cardSortFields.find((field) => field.id === id)?.text;
  }

  showSortArrow(cardSortField: any, sortOrder: string) {
    return (
      this.currentCardsSortField === cardSortField.id &&
      this.currentCardsSortOrder === sortOrder
    );
  }

  setCardSortFieldAndSortOrder(cardSortField: CardSortField) {
    // If the user is sorting by the same field as before,
    // reverse the current sort order. Otherwise,
    // sort by the new field in descending order.
    let sortOrder = cardStore.getters.defaultSortOrder;

    // If sortfield is Name, then the default sort order is ASC
    if (cardSortField === CardSortField.MEMO) {
      sortOrder = CardSortOrder.ASC;
    }

    if (this.currentCardsSortField === cardSortField) {
      sortOrder =
        this.currentCardsSortOrder === CardSortOrder.ASC
          ? CardSortOrder.DESC
          : CardSortOrder.ASC;
    }

    this.$emit("card-sort-change", cardSortField, sortOrder);
  }
}
</script>
<style scoped>
.text {
  margin: 0 12px;
}

::v-deep .dropdown-toggle {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
</style>
