import axios from "axios";
import { MetricList } from "@/types/Metrics";

import { ActionHandler } from "./vuex-typex";
import { RootState, storeBuilder } from "./storeBuilder";

type MetricsState = Record<string, never>;

const builder = storeBuilder.module<MetricsState>("metrics", {});

type MetricsAction<Payload = void, Type = void> = ActionHandler<
  MetricsState,
  RootState,
  any,
  Payload,
  Type
>;

const base = "/api/v1/metric";

const getMetrics: MetricsAction<void, MetricList> = () => {
  return axios.get(base).then((resp) => {
    return resp.data.metricList;
  });
};

export const actions = {
  getMetrics: builder.dispatch(getMetrics),
};
