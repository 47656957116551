<template>
  <a :href="href" class="logo" :class="{ light, '-square': isSquare }">
    <svg
      v-if="!isSquare"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 80"
    >
      <path
        d="M320 0v80H0V0h320zm-80.81 19.2c-2.75 0-5.384.43-7.904 1.289a21.718 21.718 0 0 0-6.844 3.808c-2.043 1.68-3.685 3.924-4.926 6.73-1.24 2.807-1.861 5.966-1.861 9.48 0 3.36.62 6.376 1.861 9.049 1.241 2.672 2.902 4.81 4.983 6.414a23.286 23.286 0 0 0 6.902 3.666c2.52.84 5.154 1.26 7.904 1.26 4.2 0 8.075-.86 11.626-2.577v-8.42c-2.443 2.215-5.88 3.322-10.31 3.322-1.794 0-3.522-.258-5.182-.773a15.242 15.242 0 0 1-4.525-2.262c-1.356-.993-2.434-2.32-3.236-3.981-.802-1.66-1.203-3.56-1.203-5.699 0-4.505 1.384-7.913 4.152-10.223 2.769-2.31 6.1-3.465 9.995-3.465 3.97 0 7.312 1.05 10.023 3.15v-8.19c-3.437-1.719-7.255-2.578-11.455-2.578zm-196.07.802H28v40.092h8.591V46.921h6.53c11.53 0 17.296-4.487 17.296-13.46s-5.765-13.46-17.296-13.46zm43.54 0H70.163v40.092h8.591V46.062h6.3l10.826 14.032h9.908l-11.97-15.12c5.842-1.871 8.763-5.842 8.763-11.914 0-2.443-.449-4.543-1.346-6.3-.898-1.756-2.138-3.112-3.723-4.066a17.538 17.538 0 0 0-5.04-2.062c-1.776-.42-3.714-.63-5.814-.63zm36.55 0h-8.59v40.092h8.59V20.002zm18.742 0h-9.279l15.579 40.092h8.82l15.636-40.092h-9.278l-10.768 30.012-10.71-30.012zm55.763 0h-9.278l-16.381 40.092h8.878l2.978-7.503h18.328l2.92 7.503h8.878l-16.323-40.092zm69.005 0h-9.68l15.694 25.888v14.204h8.534V45.89l15.636-25.888h-9.68l-10.252 17.984-10.252-17.984zm-73.644 7.961c.19 1.031.477 2.081.859 3.15l5.613 14.376h-12.944l5.555-14.376c.382-.916.688-1.966.917-3.15zm-149.555-.4c5.422 0 8.133 1.966 8.133 5.898 0 3.933-2.71 5.9-8.133 5.9h-6.93V27.562zm43.54 0c1.985 0 3.608.457 4.868 1.374 1.26.916 1.89 2.348 1.89 4.295 0 1.91-.64 3.332-1.919 4.267-1.28.936-2.892 1.404-4.84 1.404h-8.305v-11.34z"
        fill="#000"
        fill-rule="evenodd"
      />
    </svg>
    <svg
      v-else
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.4 1H10.6C7.23969 1 5.55953 1 4.27606 1.65396C3.14708 2.2292 2.2292 3.14708 1.65396 4.27606C1 5.55953 1 7.23969 1 10.6V29.4C1 32.7603 1 34.4405 1.65396 35.7239C2.2292 36.8529 3.14708 37.7708 4.27606 38.346C5.55953 39 7.23969 39 10.6 39H29.4C32.7603 39 34.4405 39 35.7239 38.346C36.8529 37.7708 37.7708 36.8529 38.346 35.7239C39 34.4405 39 32.7603 39 29.4V10.6C39 7.23969 39 5.55953 38.346 4.27606C37.7708 3.14708 36.8529 2.2292 35.7239 1.65396C34.4405 1 32.7603 1 29.4 1Z"
        fill="#232320"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.5603 10.0009H12V30.0471H16.2956V23.4605H19.5603C25.3259 23.4605 28.2087 21.2172 28.2087 16.7307C28.2087 12.2442 25.3259 10.0009 19.5603 10.0009ZM19.7607 13.781C22.4717 13.781 23.8272 14.7643 23.8272 16.7307C23.8272 18.6971 22.4717 19.6803 19.7607 19.6803H16.2956V13.781H19.7607Z"
        fill="white"
      />
    </svg>
  </a>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PrivacyLogo extends Vue {
  @Prop({ default: "" }) href!: string;
  @Prop({ default: false }) light!: boolean;
  @Prop({ default: false }) isSquare!: boolean;
}
</script>

<style lang="scss" scoped>
.logo {
  display: inline-block;
  width: 6rem;

  &.-square {
    width: auto;
  }

  & > svg {
    display: block;
  }

  &.light > svg > path {
    fill: $white;
  }
}
</style>
