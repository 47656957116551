import Vue from "vue";
import ldGet from "lodash/get";
import { Component, Prop } from "vue-property-decorator";
import { Card } from "@/types/Card";
import { Tag } from "@/types/Tag";

const INITIAL_LIMIT = 3;

@Component
export class CardListPropsAndHandlers extends Vue {
  @Prop({ default: () => [] }) cardsProp!: Card[];
  @Prop({ default: INITIAL_LIMIT }) limitProp!: number;
  @Prop({ default: false }) showTags!: boolean;
  @Prop({ default: () => [] }) selectedTagIds!: string[];

  tagList: Tag[] = [];
  limit = INITIAL_LIMIT;

  get filteredCards() {
    return this.cardsProp.filter(this.shouldShowCard);
  }

  get cardsToDisplay(): Card[] {
    if (this.cardsProp.length) {
      return this.shouldLimit
        ? this.filteredCards.slice(0, this.limit)
        : this.filteredCards;
    }

    return [];
  }

  get shouldLimit() {
    return !this.selectedTagIds?.length;
  }

  shouldShowCard(card: Card) {
    if (!this.selectedTagIds?.length) {
      return true;
    }
    if (
      ldGet<any, string, string[]>(card, "meta.tagList", []).some(
        (id: string) => {
          return this.selectedTagIds.includes(id);
        }
      )
    ) {
      return true;
    }
    return false;
  }

  openTagMenu(event: Event, card: Card) {
    if (event) {
      event.stopPropagation();
    }

    this.$emit("open-tag-menu", card);
  }

  onClickCard(card: Card | undefined) {
    if (card) this.$emit("click-card", card);
  }
}
