<template>
  <div class="helper -invite">
    <div class="title">Invite a friend, get $5</div>
    <p>
      Invite a friend and you'll get $5 after they make their first purchase!
    </p>
    <img
      class="image -left"
      src="/assets/images/components/dashboard-helpers/dollar-blue.svg"
      alt=""
    /><img
      class="image -right"
      src="/assets/images/components/dashboard-helpers/five-blue.svg"
      alt=""
    /><BaseButton
      v-if="user"
      variant="primary"
      pill
      class="link -copy"
      v-click-to-copy="`https://app.privacy.com/join/${user.referralCode}`"
      click-to-copy-text="Referral link copied to clipboard!"
      >Copy Invite Link</BaseButton
    >
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { userStore } from "@/store";

@Component
export default class HelperInvite extends Vue {
  get user() {
    return userStore.getters.currentUser;
  }
}
</script>
<style lang="scss" scoped>
.helper.-invite {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 30px 60px;
  background: $gray-900 !important;
  background-image: linear-gradient(
    -16deg,
    fade-out($blue, 0.7) 0%,
    fade-out($blue, 1) 100%
  ) !important;
  box-shadow:
    0 8px 16px 0 rgba(38, 51, 77, 0.1),
    inset 0 0 0 1px rgba(32, 45, 74, 0.1);

  > .title {
    justify-content: center;
    color: $white;
    text-align: center;
    font-size: 20px;
    font-family: $font-stack-wes-fy;

    @media #{$media-phone-medium} {
      font-size: 18px;
    }
  }

  > button {
    align-self: center;
  }

  > p {
    margin: 10px 0;
    text-align: center;
    color: #87b5e0;
  }

  > .image {
    position: absolute;

    &.-left {
      left: 0;

      @media #{$media-phone-small} {
        left: -12px;
        height: 70%;
      }
    }

    &.-right {
      right: 0;

      @media #{$media-phone-small} {
        right: -15px;
        height: 60%;
      }
    }
  }

  @media #{$media-phone-small} {
    padding: 30px;
    overflow: hidden;
  }
}
</style>
