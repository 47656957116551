<template>
  <div class="card-detail-container" :class="`card-theme-${theme}`">
    <CardComponent :card="card" />
    <div class="card-explanation" v-if="cardExplanation">
      <SVGIcon
        :icon="explanationIcon"
        :uri="explanationIconUri"
        size="16"
        class="explanation-icon"
        v-if="explanationIcon || explanationIconUri"
      />
      <div>
        {{ cardExplanation }}
        <strong v-if="cardExplanationName"> {{ cardExplanationName }}</strong>
      </div>
    </div>
    <div class="card-detail card-memo">{{ card.memo }}</div>
    <div v-if="card.note" class="card-note">
      {{ card.note }}
    </div>
    <div class="card-detail card-spend-limit">
      <div class="card-detail-label">Spend Limit</div>
      <div class="card-detail-contents">
        {{ cardSpendLimit }}
      </div>
    </div>
    <div class="card-detail card-owner">
      <div class="card-detail-label">Sender</div>
      <div class="card-detail-contents">{{ card.owner }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { CardType, RecipientCard } from "@/types/Card";
import { MerchantCategory } from "@/types/MerchantCategory";
import { formatTimePeriodAbbr, parseDollarAmount } from "@/util";
import CardComponent from "@/components/card/CardComponent.vue";
import SVGIcon from "@/components/SVGIcon.vue";

@Component({
  components: {
    CardComponent,
    SVGIcon,
  },
})
export default class SharedCardDetail extends Vue {
  @Prop({ required: true }) card!: RecipientCard;
  @Prop({ default: "default" }) theme!: RecipientCard["theme"]["name"];

  get merchantCategory(): MerchantCategory {
    return this.card?.merchantCategory as MerchantCategory;
  }

  get isCategoryCard() {
    return this.card.type === CardType.UNLOCKED && !!this.merchantCategory;
  }

  get cardExplanation(): string {
    if (
      this.card.type === CardType.MERCHANT_LOCKED &&
      this.card.lockedMerchant
    ) {
      return "Authorized for purchases at:";
    }

    if (this.card.type === CardType.MERCHANT_LOCKED) {
      return "This card will lock itself to the merchant of the first transaction made and cannot be used elsewhere";
    }

    if (this.card.type === CardType.SINGLE_USE) {
      return "This card will automatically close after the first transaction";
    }

    if (this.isCategoryCard) {
      return "Authorized for purchases that fall under the category:";
    }

    if (this.card.type === CardType.UNLOCKED) {
      return "This card is unrestricted and can be used at multiple merchants";
    }

    return "";
  }

  get cardExplanationName(): string {
    if (
      this.card.type === CardType.MERCHANT_LOCKED &&
      this.card.lockedMerchant
    ) {
      return this.card.lockedMerchant;
    }

    if (this.isCategoryCard) {
      return this.merchantCategory?.name;
    }

    return "";
  }

  get explanationIcon() {
    if (this.card.type === CardType.MERCHANT_LOCKED || this.isCategoryCard) {
      return "padlock";
    }

    return "";
  }

  get explanationIconUri() {
    if (this.card.type === CardType.SINGLE_USE) {
      return "/assets/images/icons/card-icon-burner.svg";
    }

    if (this.card.type === CardType.UNLOCKED && !this.isCategoryCard) {
      return "/assets/images/icons/unlocked.svg";
    }

    return "";
  }

  get cardSpendLimit(): string {
    if (!this.card.spendLimit) {
      return "No limit";
    }

    const amount = parseDollarAmount(this.card.spendLimit, 0);
    const time = formatTimePeriodAbbr(this.card.spendLimitDuration);

    return `${amount} / ${time} limit`;
  }
}
</script>

<style lang="scss" scoped>
.card-detail-container {
  background-color: $white;
  border-radius: 24px;
  padding: 40px;
  backdrop-filter: blur(32px);
}

.card-theme-confetti {
  background-color: $functional-opacities-light-gray;

  .card-detail-label {
    color: $brand-extended-purple-2;
  }

  .card-memo,
  .card-note,
  .card-detail-contents {
    color: $white;
  }
}

.card-theme-privacy {
  background-color: $functional-opacities-purple;
}

.card-detail {
  margin-top: 32px;
}

.card-detail-label {
  color: $foreground-subtle;
  font-family: $font-stack-graphik;
  font-size: 12px;
  line-height: 130%;
  margin-bottom: 8px;
}

.card-detail-contents {
  color: $foreground-default;
  font-family: $font-stack-graphik;
  font-size: 18px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.36px;
}

.card-explanation {
  background-color: $foreground-default;
  color: $foreground-border;
  border-radius: $radius-large;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 12px 16px;
  width: 345px;
}

.explanation-icon {
  margin-right: 8px;
  height: 16px;
  width: 16px;
}

.explanation-icon ::v-deep > svg {
  width: inherit;
  height: inherit;
}

.explanation-icon ::v-deep path {
  fill: $foreground-muted;
}

.card-memo {
  color: $foreground-default;
  font-family: $font-stack-fk-grotesk;
  font-size: 24px;
  letter-spacing: -0.24px;
}

.card-note {
  margin-top: 8px;
  font-size: 18px;
  max-width: 345px;
}

@media #{$media-phone} {
  .card-detail-container {
    padding-left: 0;
    padding-right: 0;
    background-color: unset;
    border-radius: unset;
    backdrop-filter: unset;
  }
}
</style>
