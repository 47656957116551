<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :fullpage="fullpage"
      @submit="submit"
      :class="[
        'signup-step',
        '-business-info',
        '-business-info-legal',
        { '-loading': waiting, '-preloading': preloading },
      ]"
      data-test="business-info-legal"
    >
      <h1>Your Business</h1>
      <b-spinner class="m-auto" v-if="preloading" />
      <p class="blurb">
        For verification, we need a few more details about your business
      </p>

      <b-alert variant="danger" :show="errorMessage.length > 0">
        {{ errorMessage }}
      </b-alert>

      <BaseInput
        name="ein"
        label="EIN or Tax ID"
        placeholder="000000000"
        v-model="business.ein"
        type="text"
        maxlength="9"
        :state="errors.ein ? false : null"
        :format="formatNumericOnly"
        data-test="input-business-ein"
      />

      <BaseFileUpload
        :class="[
          '-upload my-2',
          { '-full': business.files.length > 0, '-error': errors.files },
        ]"
        v-model="business.files"
        @input="onFileDrop"
        description="Click and browse or drag files here to upload, preferrably as a single PDF."
        :label="businessDocumentsLabel"
        maxfiles="10"
        accept="image/*,application/pdf"
        multiple
        data-test="input-business-docs"
      />

      <BaseFileUpload
        v-if="isIssuingSignup"
        :class="[
          '-upload my-2',
          {
            '-full': business.statements.length > 0,
            '-error': errors.statements,
          },
        ]"
        v-model="business.statements"
        description="Click and browse or drag files here to upload, preferrably as a single PDF."
        label="Bank Statements (optional)"
        maxfiles="10"
        accept="image/*,application/pdf"
        multiple
        data-test="input-business-docs-issuing"
      />

      <b-form-checkbox
        v-if="isIssuingSignup"
        :class="['legal-checkbox', { '-error': errors.checkbox }]"
        v-model="business.legalAgreement"
        data-test="checkbox-business-legal-agreement"
      >
        <p>
          I have read and agreed to the
          <a href="https://privacy.com/privacy-policy" target="_blank"
            >privacy policy</a
          >
          and
          <a href="https://privacy.com/card-issuing-agreement" target="_blank"
            >card issuing agreement.</a
          >
        </p>
      </b-form-checkbox>

      <BaseButton
        class="mt-2"
        variant="primary"
        type="submit"
        size="lg"
        :loading="waiting"
        data-test="complete-business-details"
        >Complete Business Details</BaseButton
      >
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { BAlert, BFormCheckbox, BSpinner } from "bootstrap-vue";
import ldGet from "lodash/get";
import SVGIcon from "@/components/SVGIcon.vue";
import { Format } from "@/types/Form";
import { organizationStore, userStore } from "@/store";
import { User } from "@/types/User";

@Component({
  components: {
    BAlert,
    BFormCheckbox,
    BSpinner,
    SVGIcon,
  },
})
export default class BusinessInfoLegal extends Vue {
  @Prop({ default: false }) fullpage!: boolean;
  waiting = false;
  preloading = false;
  isSoleProprietorship = false;
  isIssuingSignup = false;
  user: User | null = null;
  business = {
    ein: "",
    files: [],
    statements: [],
    legalAgreement: false,
  };
  errors = {
    ein: false,
    files: false,
    checkbox: false,
    statements: false,
  };
  errorMessage = "";
  formatNumericOnly = Format.NUMERIC;

  created() {
    this.preloading = true;

    userStore.actions.getCurrentUser().then(() => {
      this.user = userStore.getters.currentUser;
      this.isSoleProprietorship =
        ldGet<any, string>(this, "user.organization.type") === "proprietorship";
      this.isIssuingSignup = ldGet<any, string>(
        this,
        "user.organization.pendingIssuing"
      );
      this.preloading = false;
    });
  }

  get businessDocumentsLabel() {
    return this.isSoleProprietorship
      ? "Incorporation Documents (optional)"
      : "Incorporation Documents";
  }

  submit() {
    if (ldGet<any, string, string>(this, "business.ein", "").length === 9) {
      this.errors.ein = false;
    } else {
      this.errors.ein = true;
      this.errorMessage = "9-digit EIN or other Tax ID is required";
      return;
    }

    if (
      this.isSoleProprietorship ||
      ldGet<any, string, any[]>(this, "business.files", []).length > 0
    ) {
      this.errors.files = false;
    } else {
      this.errors.files = true;
      this.errorMessage = "Please provide at least one incorporation document";
      return;
    }

    if (
      !this.isIssuingSignup ||
      ldGet<any, string, boolean>(this, "business.legalAgreement", false)
    ) {
      this.errors.checkbox = false;
    } else {
      this.errors.checkbox = true;
      this.errorMessage = "You must agree to the terms and agreements";
      return;
    }

    this.waiting = true;
    organizationStore.actions
      .verify(this.business)
      .then(() => {
        this.waiting = false;
        if (this.$cookies.get("isVerifyingOrg")) {
          // verification is complete
          this.$cookies.remove("isVerifyingOrg");
        }
        this.$emit("complete-step");
      })
      .catch((err) => {
        this.errorMessage = err.response?.data?.message || "There was an error";
        this.waiting = false;
      });
  }

  onFileDrop() {
    this.errors.files = false;
  }
}
</script>

<style lang="scss" scoped>
@import "./business-info-base";

// Hack to hide the background image in the BaseFileUpload
// component until I can figure out a solution w/scoped slots
::v-deep .field .-placeholder.-drop img {
  display: none;
}
</style>
