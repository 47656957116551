<template>
  <b-button
    v-bind="$attrs"
    @click="handleClick"
    :disabled="computedDisabled"
    :variant="computedVariant"
  >
    <b-icon icon="check" v-if="!loading && success" />
    <slot v-if="!loading"></slot>
    <b-spinner small v-if="loading"></b-spinner>
  </b-button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { BIcon, BIconCheck } from "bootstrap-vue";

@Component({
  inheritAttrs: false,
  components: {
    BIcon,
    BIconCheck,
  },
})
export default class BaseButton extends Vue {
  @Prop({ required: true }) variant!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: false }) success!: boolean;

  handleClick($event: Event): void {
    this.$emit("click", $event);
  }

  get computedDisabled(): boolean {
    return this.disabled || this.loading || this.success;
  }

  get computedVariant(): string {
    return this.success ? "success" : this.variant;
  }
}
</script>

<style lang="scss" scoped>
.btn-outline-primary {
  background-color: $color-blue-light-primary;

  &:disabled {
    background-color: fade-out($color-blue-light-primary, 0.2);
  }
}

.btn-outline-success {
  background-color: $color-green-light-success;
  color: $color-green;

  &:disabled {
    background-color: fade-out($color-green-light-success, 0.2);
  }
}
</style>
