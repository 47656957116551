import { BvMsgBoxOptions } from "bootstrap-vue";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { VNode } from "vue/types/umd";

@Component
export class Confirm extends Vue {
  confirm(
    message: string | VNode[],
    bvConfig?: BvMsgBoxOptions | null
  ): Promise<any> {
    const method = bvConfig?.okOnly ? "msgBoxOk" : "msgBoxConfirm";
    const name = bvConfig?.okOnly ? "Ok" : "Confirm";

    this.$piwik.trackEvent("Modal", "View", {
      name,
    });

    return this.$bvModal[method](message, {
      contentClass: "shadow confirm-content",
      centered: true,
      headerClass: "justify-content-center p-0",
      bodyClass: "text-center confirm-body",
      footerClass: "justify-content-center pt-0",
      cancelVariant: "light",
      ...(bvConfig || {}),
    });
  }
}
