<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm :class="'verify-ssn'" @submit="onSubmit" data-test="verify-ssn">
      <h1>SSN Verification</h1>
      <p>
        We cannot confirm your identity with the information provided. In order
        to verify it's you, please enter your full nine-digit social security
        number.
      </p>
      <CreditScoreExplainer></CreditScoreExplainer>
      <b-alert v-if="errorMessage" variant="danger" show>
        {{ errorMessage }}
      </b-alert>
      <BaseInput
        label="Social Security Number"
        format="ssn"
        placeholder="000-00-0000"
        v-model="ssn"
        maxlength="11"
        :state="errorMessage ? false : null"
        :disabled="loading"
        data-test="input-ssn"
      >
        <SVGIcon class="padlock" icon="padlock" v-if="!errorMessage" />
      </BaseInput>
      <BaseButton
        type="submit"
        variant="primary"
        size="lg"
        :disabled="loading"
        v-if="!success"
        data-test="verify-ssn-submit"
        @click="trackClick"
      >
        Verify
      </BaseButton>
      <BaseButton
        type="submit"
        variant="success"
        size="lg"
        :disabled="true"
        v-else
      >
        Saved!&nbsp;<span v-if="fullpage">Redirecting...</span>
      </BaseButton>
      <p class="secure">
        Your information is encrypted and never shared. Learn more about how we
        use and protect your personal data by reading our
        <b-link href="https://privacy.com/privacy-policy" target="_blank"
          >Privacy Policy
        </b-link>
        and
        <b-link href="https://privacy.com/security" target="_blank"
          >Security</b-link
        >
        pages.
      </p>
    </BaseForm>
  </SimplePage>
</template>
<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import CreditScoreExplainer from "@/components/CreditScoreExplainer.vue";
import SVGIcon from "@/components/SVGIcon.vue";
import { eventStore, userStore } from "@/store";
import { EVENTS } from "@/types/Event";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

const SUCCESS_MESSAGE_DURATION = 1000;

@Component({
  components: {
    CreditScoreExplainer,
    SVGIcon,
  },
})
export default class VerifySsn extends Vue {
  @Prop({ default: false }) fullpage!: boolean;
  ssn = "";
  errorMessage = "";
  loading = false;
  success = false;

  @Watch("ssn")
  resetErrorState() {
    this.errorMessage = "";
  }

  created() {
    eventStore.actions.record({
      name: EVENTS.MODAL.VIEWED,
      data: {
        modalName: "SSN Screen Viewed",
      },
    });
  }

  trackClick() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.VERIFY_SSN)} Primary CTA`,
    });
  }

  onSubmit() {
    this.resetErrorState();

    const ssn = this.ssn.replace(/-/g, "");

    if (!ssn || !/^[0-9]{9}$/.test(ssn)) {
      this.errorMessage = "Please enter a valid SSN";
      return;
    }

    this.loading = true;
    userStore.actions
      .verifyFullSsn({ ssn: ssn })
      .then(() => {
        this.success = true;

        setTimeout(() => {
          this.$emit("complete-step");
        }, SUCCESS_MESSAGE_DURATION);
      })
      .catch((err) => {
        this.errorMessage =
          err?.response?.data?.message ||
          "Something went wrong, please try again.";
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
</script>
<style lang="scss" scoped>
.verify-ssn {
  h1 {
    all: unset;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    margin-bottom: 15px;
    line-height: 1.4;
    color: $gray-800;
  }

  p {
    font-size: 14px;
    color: $gray-600;
    text-align: center;
    padding: 0 10px;

    > a {
      font-weight: bold;
    }
  }

  .btn {
    margin: 1em 0;
  }

  .secure {
    font-size: 12px;
  }

  ::v-deep .padlock {
    position: absolute;
    right: 20px;
    bottom: 30px;
  }

  ::v-deep .credit-score-explainer {
    padding: 1em 20px;

    > .text {
      padding-right: 10px;
    }
  }
}
</style>
