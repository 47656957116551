<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :fullpage="fullpage"
      @submit="submit"
      :class="[
        'signup-step',
        '-business-info',
        '-business-info-about',
        { '-loading': waiting },
      ]"
      data-test="business-info-about"
    >
      <h1>Your Business</h1>
      <p class="blurb">
        A bit more information on your company <br />and how you'll use Privacy.
      </p>
      <b-alert variant="danger" :show="errorMessage.length > 0">
        {{ errorMessage }}
      </b-alert>
      <BaseInput
        name="website"
        label="Website"
        placeholder="https://www.mycompany.com"
        v-model="business.website"
        type="text"
        :state="errors.website ? false : null"
        data-test="input-business-website"
      />
      <BaseInput
        name="referral"
        label="How'd you hear about us? (optional)"
        placeholder="I heard about you…"
        v-model="business.referral"
        type="text"
        maxlength="50"
        data-test="input-business-referral"
      />
      <BaseTextarea
        name="useCase"
        label="Intended Use Case"
        placeholder="A brief description of your business and your plans for our API"
        v-model="business.useCase"
        :state="errors.useCase ? false : null"
        maxlength="500"
        rows="6"
        data-test="input-business-use"
      />
      <BaseButton
        variant="primary"
        type="submit"
        size="lg"
        class="submit-button"
        :loading="waiting"
        data-test="submit-business-details"
      >
        Submit Business Details
      </BaseButton>
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import ldGet from "lodash/get";
import { Component, Vue, Prop } from "vue-property-decorator";
import { BAlert } from "bootstrap-vue";
import { organizationStore } from "@/store";
import { validateUrl } from "@/util";

@Component({
  components: {
    BAlert,
  },
})
export default class BusinessInfoAbout extends Vue {
  @Prop({ default: false }) fullpage!: boolean;

  waiting = false;
  business = {
    website: "",
    useCase: "",
    referral: "",
  };
  errors = {
    website: false,
    useCase: false,
  };
  errorMessage = "";

  submit() {
    if (
      ldGet<any, string, string>(this, "business.website", "").length > 0 &&
      validateUrl(ldGet<any, string>(this, "business.website"))
    ) {
      this.errors.website = false;
    } else {
      this.errors.website = true;
      this.errorMessage = "A valid website is required";
      return;
    }

    if (ldGet<any, string, string>(this, "business.useCase", "").length > 0) {
      this.errors.useCase = false;
    } else {
      this.errors.useCase = true;
      this.errorMessage = "Use case is required";
      return;
    }

    this.waiting = true;
    organizationStore.actions
      .additionalInfo(this.business)
      .then(() => {
        this.$emit("complete-step");
      })
      .catch((err) => {
        this.errorMessage = err.response?.data?.message || "There was an error";
      })
      .finally(() => {
        this.waiting = false;
      });
  }
}
</script>

<style lang="scss" scoped>
@import "./business-info-base";

.submit-button {
  margin-top: 2.5rem;
}
</style>
