<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :fullpage="fullpage"
      @submit="submit"
      :class="['signup-step', { '-loading': waiting }]"
      data-test="business-use"
    >
      <ProgressTracker
        v-if="showProgressTracker"
        :currentStep="5"
        :totalSteps="5"
      ></ProgressTracker>
      <h1>Intended Usage</h1>
      <p class="blurb">
        To better understand how we can best support you, please answer the
        following:
      </p>
      <b-alert
        variant="danger"
        :show="errorMessage.length > 0"
        data-test="error-business-use"
      >
        {{ errorMessage }}
      </b-alert>
      <BaseTextarea
        name="businessServices"
        label="Business Type"
        placeholder="Tell us about your business (e.g. what goods or services you provide)"
        v-model="businessServices"
        :state="errorType.includes('businessServices') ? false : null"
        maxlength="500"
        rows="6"
        data-test="input-business-services"
      />
      <BaseTextarea
        name="useCase"
        label="Intended Use Case"
        placeholder="How you plan to use Privacy.com"
        v-model="useCase"
        :state="errorType.includes('usecase') ? false : null"
        maxlength="500"
        rows="6"
        data-test="input-business-use"
      />
      <BaseButton
        variant="primary"
        type="submit"
        size="lg"
        :loading="waiting"
        class="submit-button"
        data-test="submit-business-use"
        @click="trackClick"
      >
        Submit
      </BaseButton>
      <OnboardingFooterActions
        :allowChangeAccountType="true"
        :showFinishLater="true"
        :hasEnteredData="hasEnteredData"
        @footer-action="onFooterAction"
      />
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { organizationStore } from "@/store";
import ProgressTracker from "@/components/ProgressTracker.vue";
import OnboardingFooterActions from "@/components/OnboardingFooterActions.vue";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component({
  components: {
    ProgressTracker,
    OnboardingFooterActions,
  },
})
export default class BusinessUse extends Vue {
  @Prop({ default: false }) fullpage!: boolean;
  @Prop({ default: false }) showProgressTracker!: boolean;

  waiting = false;
  businessServices = "";
  useCase = "";
  errorMessage = "";
  errorType = "";

  get hasEnteredData(): boolean {
    return this.useCase !== "" && this.businessServices !== "";
  }

  getErrorType(): string {
    let errorType = "";
    if (this.useCase.length < 5) {
      errorType += "usecase";
    }
    if (this.businessServices.length < 5) {
      errorType += "businessServices";
    }
    return errorType;
  }

  trackClick() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.BUSINESS_USE)} Primary CTA`,
    });
  }

  submit(): void {
    if (this.useCase.length >= 5 && this.businessServices.length >= 5) {
      this.errorMessage = "";
      this.errorType = "";
    } else {
      this.errorMessage = "Please provide more detail";
      this.errorType = this.getErrorType();
      return;
    }

    this.waiting = true;
    organizationStore.actions
      .useCase({
        useCase: this.useCase,
        businessServices: this.businessServices,
      })
      .then(() => {
        this.$emit("complete-step");
      })
      .catch((err) => {
        this.errorMessage = err.response?.data?.message || "There was an error";
        this.errorType = err.response?.data?.type;
      })
      .finally(() => {
        this.waiting = false;
      });
  }

  onFooterAction(nextScreen: OnboardingSteps): void {
    this.$emit("complete-step", nextScreen);
  }
}
</script>

<style lang="scss" scoped>
@import "./business-info-base";

.submit-button {
  margin-top: 1rem;
}
</style>
