<template>
  <b-form-checkbox
    :checked="agreed"
    @change="handleChange"
    class="account-checkbox"
    :class="{ error: agreementError }"
    size="lg"
    data-test="account-checkbox"
  >
    I am a US resident and I agree to the
    <b-link href="https://privacy.com/terms" target="_blank">terms</b-link>,
    <b-link
      href="https://privacy.com/cardholder-agreement"
      target="_blank"
      aria-label="card authorizations"
      >card</b-link
    >
    and
    <b-link
      href="https://privacy.com/ach"
      target="_blank"
      aria-label="ACH authorizations"
      >ACH</b-link
    >
    authorizations, and
    <b-link
      href="https://privacy.com/e-sign-consent"
      target="_blank"
      aria-label="electronic communications consent/agreement"
      >E-Sign Consent</b-link
    >.
  </b-form-checkbox>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class DisclosureCheckbox extends Vue {
  @Prop({ default: false }) agreementError!: boolean;
  @Prop({ default: false }) agreed!: boolean;

  handleChange(value: boolean) {
    this.$emit("input", value);
  }
}
</script>

<style lang="scss" scoped>
.account-checkbox.custom-checkbox {
  font-size: 12px;
  border-radius: $border-radius;
  border: 2px solid $gray-100;
  margin-bottom: 0.5rem;
  padding: 15px 20px;
  padding-left: calc(1.5rem + 20px);

  &.error {
    border-color: $red;
  }

  ::v-deep .custom-control-label {
    &::before,
    &::after {
      margin-top: 5px;
    }
  }
}
</style>
