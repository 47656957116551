<template>
  <div
    v-if="plan"
    :key="plan.uuid"
    class="subscription-plan d-flex flex-column align-self-stretch p-4"
  >
    <div class="plan-name font-weight-bold">
      <span class="p-1">{{ plan.name }}</span>
    </div>
    <div class="plan-price">
      <DollarAmount
        :amount="plan.amount"
        :show-cents="false"
        class="d-inline-block font-weight-bold"
      />
      / mo.
    </div>
    <div class="divider"></div>
    <div class="headline" v-if="plan.description.headline">
      <strong>{{ plan.description.headline }}</strong>
    </div>
    <div class="plan-description">
      <div class="plan-features">
        <ul class="features-list">
          <li
            v-for="(feature, index) in plan.description.features"
            :key="index"
          >
            {{ feature }}
          </li>
        </ul>
      </div>
    </div>
    <div
      class="disclaimer font-italic"
      v-if="plan.description.disclaimer"
      v-html="plan.description.disclaimer[0]"
    />
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { BAlert, BTooltip } from "bootstrap-vue";
import DollarAmount from "@/components/DollarAmount.vue";
import { PlanData } from "@/types/Subscription";

@Component({
  components: {
    BAlert,
    BTooltip,
    DollarAmount,
  },
})
export default class SubscriptionPlan extends Vue {
  @Prop({ required: true }) plan!: PlanData;
}
</script>
<style lang="scss" scoped>
.subscription-plan {
  flex: 1 0 0;
  border-radius: 16px;
  background: linear-gradient(180deg, #4949f2 23.5%, #b5b5f9 100%);
  color: white;
  gap: 24px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
}

.plan-name-price,
.plan-description {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 0;
}

.plan-name {
  span {
    border-radius: 4px;
    background: #000000;
    color: #ffffff;
    font-size: 11px;
    line-height: normal;
    letter-spacing: 1.1px;
    text-transform: uppercase;
  }
}

.plan-price {
  font-size: 24px;
}

.headline {
  margin-bottom: 4px;
  font-size: 16px;
}

.features-list {
  margin: 0px;
  padding: 0px;
  padding-left: 20px;
}

.features-list li {
  padding: 5px 0;
  position: relative;
}

.divider {
  width: 100%;
  height: 1px;
  background: $brand-purple;
}
</style>
