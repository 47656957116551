<template>
  <BaseModal :id="modalId" size="md" hide-header>
    <div class="modal-card-edit-nickname">
      <div class="edit-content">
        <div class="header">
          <div class="title">Nickname</div>
        </div>
        <div class="brands-content">
          <div class="nickname-input-wrap">
            <b-input
              class="nickname-input"
              id="modal-card-edit-nickname-input"
              type="text"
              placeholder="Nickname"
              autofocus
              autocorrect="off"
              spellcheck="false"
              maxlength="40"
              v-model="viewNickname"
              @change="onChangeViewNickname"
            />
            <BaseButton
              block
              class="pill-button"
              variant="primary"
              :loading="submitting"
              @click="onClickSave"
            >
              Save
            </BaseButton>
          </div>
          <div
            class="merchant-selector-wrap"
            v-if="
              hasMerchantSelector &&
              card.unused &&
              !card.hostname &&
              card.type === CardType.MERCHANT_LOCKED
            "
          >
            <h4 class="merchant-selector-title">
              {{
                viewNickname.length > 2 ? "Suggested Logos" : "Popular Cards"
              }}
            </h4>
            <MerchantSelector
              :query="viewNickname"
              :currentMerchant="viewStyle"
              @select-merchant="handleSelectMerchant"
            />
            <button
              class="more-cards"
              @click="showVaultedModal"
              data-test="more-cards"
            >
              See other popular cards
            </button>
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>
<script lang="ts">
import ldGet from "lodash/get";
import startCase from "lodash/startCase";
import { Component, Prop, Mixins } from "vue-property-decorator";
import { Card, CardMeta, CardStyle, CardType } from "@/types/Card";
import { Merchant } from "@/types/Merchant";
import { EVENTS } from "@/types/Event";
import { cardStore, eventStore, userStore } from "@/store";
import { Alert } from "@/mixins/Alert";
import { Toast } from "@/mixins/Toast";
import MerchantSelector from "./MerchantSelector.vue";

@Component({
  components: {
    MerchantSelector,
  },
})
export default class EditNickname extends Mixins(Alert, Toast) {
  @Prop({ default: "edit-nickname" }) modalId!: string;
  @Prop() card!: Card;
  @Prop({ default: true }) hasMerchantSelector?: boolean;
  @Prop() isSampleCard?: boolean;

  CardType = CardType;

  cardEdit: Partial<Card> = {};
  customStyle: Partial<CardStyle> = {};
  viewStyle: CardStyle | null = null;
  viewNickname = "";
  changedMemo = false;
  changedStyle = false;
  submitting = false;

  get user() {
    return userStore.getters.currentUser;
  }

  get isNewCard() {
    return !this.card.created;
  }

  created() {
    this.viewNickname =
      this.card.isUntitled || !this.card.memo ? "" : this.card.memo;
    this.cardEdit = { ...this.card };
  }

  closeModal() {
    this.$bvModal.hide("edit-nickname");
  }

  onChangeViewNickname() {
    if (this.viewNickname !== this.card.memo) {
      this.changedMemo = true;
    }
  }

  // Copy/pasted in EditCardStyle
  handleSelectMerchant(merchant: Merchant) {
    this.customStyle = {};

    if (
      ldGet<Merchant, any>(merchant, "_id") ===
      ldGet<any, string>(this, "cardEdit.style.id")
    ) {
      // "Unselect" the merchant and reset styles
      this.cardEdit.style = { bgColor: null, icon: "" };
      this.customStyle = { bgColor: null, icon: "" };
      return;
    }

    if (this.viewNickname === "") {
      this.viewNickname = startCase(
        ldGet<Merchant, any>(merchant, "friendlyNames[0]") ||
          merchant.strippedHostname
      );
      this.changedMemo = true;
    }

    this.changedStyle = true;
    this.cardEdit = { ...this.cardEdit, style: merchant };
  }

  onClickSave() {
    if (this.submitting) {
      return;
    }

    this.submitting = true;
    this.card.style = { ...this.cardEdit.style };

    const updateData: Partial<Card> = { memo: this.viewNickname };
    const existingMeta: CardMeta = ldGet<any, string, CardMeta>(
      this,
      "card.meta",
      {}
    );
    const didSetCustomStyle =
      "bgColor" in this.customStyle || "icon" in this.customStyle;

    let updatedStyle;

    if (didSetCustomStyle) {
      updateData.meta = existingMeta;
      updateData.meta.customStyle = this.customStyle;
      updateData.meta.hostname = "";
    } else if (this.cardEdit.style?.hostname) {
      updateData.meta = existingMeta;
      updateData.meta.hostname = this.cardEdit.style?.hostname;
      updateData.meta.customStyle = null;
      updatedStyle = this.cardEdit.style;

      if (!ldGet<Partial<Card>, any>(updateData, "style.bgColor")) {
        updatedStyle.bgColor = "ffffff";
      }

      updateData.hostname = updateData.meta.hostname;
    } else {
      updateData.meta = this.card.meta || {};
      updateData.meta.hostname = "";
      updatedStyle = null;
    }

    // add style to card so it can be updated in the UI
    // but do not add it to the updateData as it is an invalid field
    const updatedCard = { ...this.card, ...updateData, style: updatedStyle };

    if (this.isNewCard || this.isSampleCard) {
      this.$emit("update-card", updatedCard);
      this.closeModal();
      this.submitting = false;
      return;
    }

    cardStore.actions
      .update({ uuid: this.card.cardUuid!, updates: updateData })
      .then(() => {
        if (this.changedMemo) {
          eventStore.actions.record({
            name: EVENTS.CARD.SET_NICKNAME,
          });
        }

        if (this.changedStyle) {
          const styleType = didSetCustomStyle ? "Custom" : "Merchant";
          eventStore.actions.record({
            name: EVENTS.CARD.SET_STYLE,
            data: { styleType },
          });
        }

        this.$emit("update-card", updatedCard);
        this.closeModal();
      })
      .catch((err) => {
        this.errorToast(
          err?.response?.data?.message || "Couldn't update nickname"
        );
      })
      .finally(() => {
        this.submitting = false;
      });
  }

  showVaultedModal(): void {
    this.$bvModal.show("vaulted-cards-modal");
    this.$bvModal.hide(this.modalId);
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/styles/mixins.scss";

.modal-card-edit-nickname {
  @include modal-card-edit;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 30px;
  background-color: $white;
  border-radius: $border-radius-lg;
  max-width: 300px;
  margin: 0 auto;

  > .edit-content > .header {
    position: relative;
    margin-bottom: 10px;
  }

  > .edit-content > .header > .title {
    font-family: $font-stack-wes-fy;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }

  > .edit-content > .brands-content {
    min-height: 0;
  }

  > .edit-content > .brands-content > .nickname-input-wrap {
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  > .edit-content > .brands-content > .nickname-input-wrap > .nickname-input {
    flex: 1;
    min-width: 200px;
    font-size: 16px;
    padding: 6px 1.25rem;

    & + .pill-button {
      margin-left: 10px;
      height: 54px;
    }
  }

  > .edit-content
    > .brands-content
    > .merchant-selector-wrap
    > .merchant-selector-title {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }

  > .edit-content > .custom-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-height: 390px;
  }

  > .edit-content > .custom-content > .custom-picker {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    margin: 15px 0;

    &.-color {
      padding: 0 6px;
    }
  }

  > .edit-content
    > .custom-content
    > .custom-picker.-color
    + .custom-picker.-color {
    margin-top: 0;
  }

  > .edit-content > .pill-button {
    margin-top: auto;
  }

  @media (max-width: 720px) {
    > .card-preview {
      display: none;
    }
  }

  @media #{$media-phone} {
    > .edit-content > .brands-content > .nickname-input-wrap > .nickname-input {
      margin: 0 0 10px;
    }
  }
}

.more-cards {
  margin-top: 24px;
  display: block;
  border-radius: $border-radius;
  width: 100%;
  height: 60px;
  background: #e7eef6 url(/assets/images/misc/popular-cards.png) no-repeat right;
  border: none;
  text-align: left;
  padding: 0 16px;
}
</style>
