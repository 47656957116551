<template>
  <div class="signup">
    <span class="teaser">
      Join Privacy to create your own secure virtual cards.
    </span>
    <form class="submit-form" novalidate @submit.prevent="submit">
      <div class="legend">Sign-up for Free</div>
      <label for="email" class="sr-only"> Email address </label>
      <b-form-input
        class="email-input"
        id="email"
        name="email"
        type="email"
        placeholder="someone@example.com"
      />
      <BaseButton type="submit" class="signup-button" variant="primary">
        Create Account
      </BaseButton>
    </form>
  </div>
</template>

<script lang="ts">
import { eventStore } from "@/store";
import { EVENTS } from "@/types/Event";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class SharedSignup extends Vue {
  submit(event: Event) {
    const email = (event.target as HTMLFormElement).email.value;
    let query = {};

    if (email !== "") {
      query = { email: btoa(email) };
    }

    eventStore.actions.record({
      name: EVENTS.CTA.CLICKED,
      data: {
        buttonContext: "Shared Dashboard",
        buttonName: "Create Account",
      },
    });

    this.$router.push({ name: "signup", query });
  }
}
</script>

<style lang="scss" scoped>
.signup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $white;
  padding: 8px 24px;
  border-bottom: 1px solid $foreground-border;
  z-index: 1;
}

.teaser,
.legend {
  font-family: $font-stack-graphik;
  font-size: 14px;
  color: $foreground-default;
  margin-right: 16px;
}

.submit-form {
  display: flex;
  align-items: center;
}

.email-input {
  padding: 9px 16px;
  margin-right: 8px;
}

.legend,
.signup-button {
  flex-shrink: 0;
}

.btn-primary {
  background-color: $button-primary-dark;

  &:hover {
    background-color: $button-primary-dark-hover;
  }
}

@media #{$media-phone} {
  .signup {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    text-align: center;
    padding: 32px 40px;
  }

  .teaser,
  .legend {
    display: block;
    margin-right: 0;
    margin-bottom: 12px;
  }

  .legend {
    font-weight: 600;
  }

  .email-input {
    margin: 0 0 8px;
  }

  .submit-form {
    flex-flow: column nowrap;
    align-items: stretch;
  }
}
</style>
