<template>
  <div class="card-state-banner" :class="cardStateStr" v-if="cardStateStr">
    {{ cardStateStr }}
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { CardState } from "@/types/Card";

@Component
export default class CardStateBanner extends Vue {
  @Prop({ required: true }) state!: CardState;

  get cardStateStr() {
    if (this.state === CardState.PAUSED) {
      return "paused";
    }

    if (this.state === CardState.CLOSED) {
      return "closed";
    }

    return "";
  }
}
</script>

<style lang="scss" scoped>
.card-state-banner {
  padding: 8px;
  width: 100%;
  color: $white;
  font-family: $font-stack-graphik;
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.paused {
  background-color: $accent-default;
}

.closed {
  background-color: $neutrals-4;
}
</style>
