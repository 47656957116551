<template>
  <VueRecaptcha
    class="recaptcha"
    v-bind="$attrs"
    sitekey="6LfoaUcqAAAAACv0sWjmDWKd0B2Puv2I6-cG0KSs"
    :loadRecaptchaScript="true"
    @verify="onCaptchaVerified"
    @expired="onCaptchaExpired"
  >
    <slot></slot>
  </VueRecaptcha>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { VueRecaptcha } from "vue-recaptcha";

@Component({
  components: {
    VueRecaptcha,
  },
})
export default class Recaptcha extends Vue {
  @Prop() onCaptchaVerified!: (recaptchaToken: string) => void;
  @Prop() onCaptchaExpired!: () => void;
}
</script>
