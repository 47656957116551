<template>
  <SimplePage :fullpage="fullpage" id="extension-install">
    <BaseForm :fullpage="fullpage">
      <h3>Get Our Extension</h3>
      <p class="blurb">
        Our browser extension is the best way to create, configure, and
        auto-fill virtual cards at checkout.
      </p>
      <img
        class="preview-image"
        alt=""
        src="/assets/images/account/extension-install.png"
        width="534"
      />
      <b-link
        @click="handleClick"
        :href="browser.chrome ? CHROME_STORE_LINK : FIREFOX_STORE_LINK"
        target="_blank"
      >
        <BaseButton variant="primary" size="lg">
          <img
            class="browser-logo"
            :src="`/assets/images/icons/browsers/${
              browser.chrome ? 'chrome' : 'firefox'
            }-32@2x.png`"
            alt=""
          />
          Get Privacy for {{ browser.chrome ? "Google Chrome" : "Firefox" }}
        </BaseButton>
      </b-link>
    </BaseForm>
  </SimplePage>
</template>
<script lang="ts">
import { Component, Prop, Mixins } from "vue-property-decorator";
import bowser from "bowser";
import { Toast } from "@/mixins/Toast";
import { Extension } from "@/mixins/Extension";

@Component
export default class ExtensionInstall extends Mixins(Toast, Extension) {
  @Prop({ default: false }) fullpage!: boolean;
  browser = bowser;
  loading = true;

  created() {
    // Check for supported browser/device
    if ((!bowser.chrome && !bowser.firefox) || bowser.mobile || bowser.tablet) {
      this.$router.push({
        name: "home",
      });
    }
  }

  handleClick() {
    this.$emit("complete-step");
  }
}
</script>
<style lang="scss" scoped>
#extension-install {
  form {
    align-items: center;
  }

  .preview-image {
    max-width: calc(100vw - 20px);
  }

  .browser-logo {
    width: 32px;
    margin-right: 5px;
  }
}
</style>
