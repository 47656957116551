<!--
This is a component that is loaded via an authenticated webview from the
mobile app. It is used to display the Zendesk web widget and should always
automatically open when the page is loaded.
-->
<template>
  <div class="d-flex align-items-center justify-content-center h-100">
    <b-spinner v-if="!loaded" />
    <div v-else>
      <p class="text-center">
        Please click the "?" button to open <br />
        the support window
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class MobileHelp extends Vue {
  loaded = false;

  created() {
    this.$zendesk.$on("hide", () => {
      this.$zendesk.open();
    });

    this.$zendesk.$on("open", () => {
      this.$zendesk.show();
    });

    this.$zendesk.$on("show", () => {
      // give it extra time to fade in
      setTimeout(() => {
        this.loaded = true;
      }, 1000);
    });

    this.$zendesk.hide();
  }
}
</script>
