<template>
  <div class="card-component" :class="{ preview }">
    <CardContainer :card="card">
      <div class="card-contents" @click="toggleHideInfo">
        <CardHeader :card="card" :preview="preview" />
        <CardStateBanner class="mt-auto" :state="card.state" />
        <div class="card-bottom" :class="cardBottomClasses" v-if="preview">
          <slot name="preview">
            <div class="card-info-pan-lastfour">{{ card.lastFour }}</div>
          </slot>
        </div>
        <template v-else>
          <div class="card-bottom" :class="cardBottomClasses" v-show="hideInfo">
            <div class="card-info">
              <SVGIcon icon="eye-slash" size="20" />
              <div class="card-info-pan-redact">••••</div>
              <div class="card-info-pan-lastfour">{{ card.lastFour }}</div>
              <img :src="cardNetworkSrc" class="ml-auto card-network" />
            </div>
          </div>
          <div
            class="card-bottom"
            :class="cardBottomClasses"
            v-show="!hideInfo"
          >
            <div class="card-info" v-click-to-copy.stop="card.PAN">
              <SVGIcon icon="copy" size="16" />
              <div class="mr-auto card-info-pan">
                {{ cardFormattedPan }}
              </div>
            </div>
            <div class="card-info">
              <div class="card-info-exp" v-click-to-copy.stop="cardExp">
                <div class="card-info-label">EXP</div>
                {{ cardExp }}
              </div>
              <div class="card-info-cvv" v-click-to-copy.stop="card.CVV">
                <div class="card-info-label">CVV</div>
                {{ card.CVV }}
              </div>
              <img :src="cardNetworkSrc" class="ml-auto card-network" />
            </div>
          </div>
        </template>
      </div>
    </CardContainer>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Card, CardType } from "@/types/Card";
import { getIsDarkBackground } from "@/util";
import { eventStore } from "@/store";
import { EVENTS } from "@/types/Event";
import SVGIcon from "@/components/SVGIcon.vue";
import CardContainer from "./CardContainer.vue";
import CardHeader from "./CardHeader.vue";
import CardStateBanner from "./CardStateBanner.vue";

@Component({
  components: {
    SVGIcon,
    CardContainer,
    CardHeader,
    CardStateBanner,
  },
})
export default class CardComponent extends Vue {
  @Prop({ required: true }) card!: Card;
  @Prop({ default: false }) preview!: boolean;

  hideInfo = true;

  get isCustomCard() {
    return (
      !!this.card.style?.bgColor ||
      !!this.card.style?.filename ||
      !!this.card.style?.icon
    );
  }

  get isDarkBackground() {
    return getIsDarkBackground(this.card.style?.bgColor);
  }

  get cardBottomClasses() {
    const classes: Record<string, boolean> = {};

    if (this.card.type === CardType.MERCHANT_LOCKED) {
      if (this.isCustomCard) {
        classes["card-bottom-custom"] = true;

        if (!this.isDarkBackground) {
          classes["card-bottom-dark"] = true;
        }
      } else {
        classes["card-bottom-default"] = true;
      }
    }

    return classes;
  }

  get cardNetworkSrc() {
    if (this.card.network === "mastercard") {
      return "/assets/images/privacy-card/networks/mastercard-transparent.svg";
    }

    if (!this.isDarkBackground && this.isCustomCard) {
      return "/assets/images/privacy-card/networks/visa-dark-transparent.svg";
    }

    return "/assets/images/privacy-card/networks/visa-transparent.svg";
  }

  get cardFormattedPan() {
    return this.card.PAN.replace(/(.{4})/g, "$1 ");
  }

  get cardExp() {
    return `${this.card.expMonth}/${this.card.expYear.slice(2)}`;
  }

  toggleHideInfo() {
    this.hideInfo = !this.hideInfo;

    if (!this.hideInfo) {
      eventStore.actions.record({
        name: EVENTS.CARD.VIEWED_NUMBER,
        data: {
          page: "Shared Dashboard",
        },
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.card-component {
  height: 220px;
  width: 345px;
}

.card-contents {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.card-bottom {
  width: 100%;
  padding: 24px;
  font-family: $font-stack-graphik;
  font-size: 16px;
  font-weight: 600;
}

.card-bottom-custom {
  background-color: fade-out($black, 0.9);
  backdrop-filter: blur(32px);
}

.card-bottom-default {
  background-color: fade-out($brand-purple, 0.9);
  backdrop-filter: blur(32px);
}

.card-bottom-dark ::v-deep path {
  fill: $black;
}

.card-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.card-info + .card-info {
  margin-top: 12px;
}

.card-info-pan-redact {
  padding: 0 16px;
  letter-spacing: 3.2px;
}

.card-info-pan,
.card-info-cvv {
  padding-left: 16px;
}

.card-info-exp,
.card-info-cvv {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.card-info-label {
  margin-right: 8px;
  font-size: 14px;
  opacity: 50%;
}

.card-network {
  height: 21px;
  width: 34px;
  object-fit: contain;
}

.preview {
  &.card-component {
    height: 140px;
    width: 220px;
  }

  .card-contents {
    cursor: default;
  }

  .card-bottom {
    padding: 16px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
</style>
