<template>
  <div class="group">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class BaseInputGroup extends Vue {}
</script>

<style lang="scss" scoped>
.group {
  display: flex;

  ::v-deep .field {
    flex-basis: 50%;
  }

  ::v-deep .field + .field {
    margin-left: 2px;
  }

  ::v-deep .field:not(:first-child) ::v-deep input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  ::v-deep .field:not(:last-child) ::v-deep input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
