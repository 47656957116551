import { RouteConfig } from "vue-router";

import Login from "@/views/login/LoginContainer.vue";
import paths from "./paths.json";

const routes: Array<RouteConfig> = [
  {
    path: paths.login,
    name: "login",
    component: Login,
    meta: {
      title: "Account Login",
    },
    props: {
      state: "login",
    },
  },
  {
    path: paths.resetPassword,
    alias: paths.forgotPassword,
    name: "reset-password",
    component: Login,
    meta: {
      title: "Reset Your Password",
    },
    props: {
      state: "reset-password",
    },
  },
  {
    path: paths.tfa,
    name: "tfa",
    component: Login,
    meta: {
      title: "2 Factor Authentication",
    },
    props: {
      state: "tfa",
    },
  },
  {
    path: paths.tfaRecovery,
    name: "tfa-recovery",
    component: Login,
    meta: {
      title: "2 Factor Authentication Recovery",
    },
    props: {
      state: "tfa-recovery",
    },
  },
  {
    path: paths.accountClosed,
    name: "account-closed",
    component: Login,
    meta: {
      title: "Account Closed",
    },
    props: {
      state: "login",
      accountClosed: true,
    },
  },
];

export default routes;
