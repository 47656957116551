<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :fullpage="fullpage"
      class="business-additional-info-success"
      data-test="business-additional-info-success"
    >
      <img src="/assets/images/business/additional-info-success.svg" alt="" />
      <h1>Thank you for submitting additional information!</h1>
      <div class="description">
        Your account has been configured for business use and you're all set.
      </div>
      <BaseButton
        variant="primary"
        size="lg"
        @click="onCompleteStep"
        data-test="button-close"
      >
        Dismiss
      </BaseButton>
    </BaseForm>
  </SimplePage>
</template>
<script lang="ts">
import { OnboardingSteps } from "@/types/Onboarding";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class BusinessAdditionalInfoSuccess extends Vue {
  @Prop({ default: false }) fullpage!: boolean;

  onCompleteStep(): void {
    this.$emit("complete-step", OnboardingSteps.COMPLETE);
  }
}
</script>
<style lang="scss">
.business-additional-info-success {
  text-align: center;

  img {
    height: 100px;
    margin-bottom: 24px;
  }

  h1 {
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    color: $gray-800;
    margin-bottom: 24px;
  }

  .description {
    color: $gray-600;
    margin-bottom: 24px;
  }
}
</style>
