<template>
  <SimplePage>
    <div class="onfido-upload-page">
      <div id="onfido-mount"></div>
    </div>
  </SimplePage>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { loadScriptAsync, linkStylesheetAsync } from "@/util";
import { userStore } from "@/store";
import { User } from "@/types/User";

const ONFIDO_UI_OPTIONS = {
  // typography
  fontFamilyTitle: "wes-fy, Lato, helvetica neue, helvetica, arial",
  fontSizeTitle: "24px",
  // bold
  fontWeightTitle: 700,
  colorContentTitle: "#000000",
  fontFamilySubtitle: "Lato, helvetica neue, helvetica, arial",
  fontSizeSubtitle: "16px",
  fontWeightSubtitle: 700,
  colorContentSubtitle: "#202d4a",
  fontFamilyBody: "Lato, helvetica neue, helvetica, arial",
  fontSizeBody: "14px",
  // regular weight
  fontWeightBody: 400,
  colorContentBody: "#8d95a6",
  // main container options
  colorBackgroundSurfaceModal: "#ffffff",
  colorBorderSurfaceModal: "#dee2e7",
  borderWidthSurfaceModal: "1px",
  borderRadiusSurfaceModal: "8px",
  // primary button
  colorContentButtonPrimaryText: "#ffffff",
  colorBackgroundButtonPrimary: "#3399ff",
  colorBackgroundButtonPrimaryHover: "#2e8ae5",
  colorBackgroundButtonPrimaryActive: "#297acc",
  // secondary button
  colorContentButtonSecondaryText: "#26334d",
  colorBackgroundButtonSecondary: "#f2f6fa",
  colorBackgroundButtonSecondaryActive: "#dde2e9",
  colorBackgroundButtonSecondaryHover: "#e8ecf1",
  // doc type
  colorContentDocTypeButton: "#26334d",
  colorBackgroundDocTypeButton: "#f5f8fb",
  colorBorderDocTypeButtonHover: "#f2f6fa",
  colorBorderDocTypeButtonActive: "#f2f6fa",
  // links
  colorContentLinkTextHover: "#8d95a6",
  colorBorderLinkUnderline: "#8d95a6",
  colorBackgroundLinkHover: "202d4a",
  colorBackgroundLinkActive: "#202d4a",
};

@Component
export default class OnfidoUpload extends Vue {
  get user(): User | null {
    return userStore.getters.currentUser;
  }

  mounted() {
    if (document.getElementById("onfido-sdk") === null) {
      loadScriptAsync(
        "onfido-sdk",
        "https://sdk.onfido.com/v13.2.0",
        this.onOnfidoLoadedSuccess,
        this.onOnfidoLoadedError
      );
      linkStylesheetAsync("https://sdk.onfido.com/v13.2.0/style.css");
    }
  }

  onOnfidoLoadedSuccess() {
    this.onfidoInit();
  }

  onOnfidoLoadedError() {
    this.$router.push({ name: "secure-upload" });
  }

  async getOnfidoToken() {
    try {
      return await userStore.actions.getOnfidoSDKToken();
    } catch {
      this.onOnfidoLoadedError();
    }
  }

  async onfidoInit() {
    try {
      const token = await this.getOnfidoToken();
      /* @ts-ignore */
      window.Onfido.init({
        token: token?.sdkToken,
        containerId: "onfido-mount",
        customUI: ONFIDO_UI_OPTIONS,
        onComplete: function (sdkData: any) {
          // eslint-disable-next-line
          const { document_video, data, poa, ...documentData } = sdkData;
          userStore.actions.runOnfidoChecks(documentData);
        },
        crossDeviceClientIntroProductName: "for Privacy.com",
        crossDeviceClientIntroProductLogoSrc:
          "https://app.privacy.com/assets/images/icon/favicon-96x96.png",
        steps: [
          "welcome",
          {
            type: "document",
            options: {
              documentTypes: {
                driving_licence: {
                  country: "USA",
                },
                passport: {
                  country: "USA",
                },
                national_identity_card: {
                  country: "USA",
                },
              },
              forceCrossDevice: false,
            },
          },
          "face",
          "complete",
        ],
      });
    } catch {
      this.onOnfidoLoadedError();
    }
  }
}
</script>

<style lang="scss" scoped>
.onfido-upload-page {
  position: relative;
  width: 100%;
  min-height: 700px;
}
</style>
