<template>
  <div
    class="transaction-list"
    ref="transactionList"
    :class="
      hideColumns && {
        '-descriptor': !hideColumns.descriptor,
        '-date': !hideColumns.date,
        '-settlement': !hideColumns.settlement,
        '-amount': !hideColumns.amount,
        '-decline-reason': !hideColumns.declineReason,
      }
    "
  >
    <div class="zero-state" v-if="!filteredTransactions.length">
      {{ filterText ? "No transactions found" : "No transactions" }}
    </div>
    <template v-else-if="filteredTransactions.length && !showDateBuckets">
      <TransactionComponent
        v-for="transaction in filteredTransactions.slice(0, limit)"
        :key="transaction.transactionID"
        :transactionProp="transaction"
        @click.native="onClickTransaction(transaction)"
        :hideColumns="hideColumns"
        :showThumbnails="showThumbnails"
      />
    </template>
    <template v-else>
      <template v-for="dateBucket in dateBuckets">
        <div
          :key="dateBucket.id"
          class="date-bucket"
          v-if="filterTransactions(dateBucket.transactions).length"
        >
          <div class="title">
            {{ dateBucket.title }}
            <div class="total" v-if="dateBucket.transactions.length > 1">
              <DollarAmount :amount="dateBucket.total" />
            </div>
          </div>
          <TransactionComponent
            v-for="transaction in filterTransactions(dateBucket.transactions)"
            :transactionProp="transaction"
            :key="transaction.transactionID"
            @click.native="onClickTransaction(transaction)"
            :hideColumns="hideColumns"
            :showThumbnails="showThumbnails"
          />
        </div>
      </template>
    </template>
    <div class="spinner-container" v-if="loading">
      <b-spinner />
    </div>
  </div>
</template>
<script lang="ts">
import ldGet from "lodash/get";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  Transaction,
  DateBucket,
  HideColumns,
  TransactionList,
} from "@/types/Transaction";
import { transactionStore } from "@/store";
import { relativeDate } from "@/lib/dates";
import { INITIAL_TXN_LIMIT } from "@/util";
import TransactionComponent from "./Transaction.vue";
import DollarAmount from "./DollarAmount.vue";

const INITIAL_LIMIT = INITIAL_TXN_LIMIT;

@Component({
  components: {
    TransactionComponent,
    DollarAmount,
  },
})
export default class TransactionListComponent extends Vue {
  @Prop({ default: () => [] }) transactionsProp!: Transaction[];
  @Prop({ default: INITIAL_LIMIT }) limitProp!: number;
  @Prop({ default: INITIAL_LIMIT }) incrementByProp!: number;
  @Prop({ default: false }) foundAllTransactionsProp!: boolean;
  @Prop() nodePaginate?: boolean;
  @Prop() showDateBuckets?: boolean;
  @Prop() showThumbnails?: boolean;
  @Prop() filterText?: string;
  @Prop() hideColumns?: HideColumns;
  @Prop() showDeclines?: boolean;
  @Prop() query?: string;
  @Prop() parent?: HTMLElement | undefined;

  transactions: Transaction[] = [];
  limit = INITIAL_LIMIT;
  incrementBy = INITIAL_LIMIT;
  foundAllTransactions = false;
  loading = false;
  shouldShowMore = false;
  offset = INITIAL_LIMIT;
  incrementing = false;
  dateBuckets: DateBucket[] = [];
  selectedTransaction: Transaction | null = null;

  get container() {
    return this.parent || window;
  }

  // Pagination is handled internally,
  // so if there's an update from the parent component,
  // reset everything
  @Watch("transactionsProp")
  replaceTransactionList() {
    const newTransactions = this.transactionsProp;
    if (newTransactions) {
      this.transactions = newTransactions;
      if (this.showDateBuckets) {
        this.dateBuckets = this.generateDateBuckets(newTransactions);
      }
      this.resetPagination();
    }
  }

  created() {
    this.transactions = this.transactionsProp;
    this.limit = this.limitProp;
    this.incrementBy = this.incrementByProp;
    this.foundAllTransactions = this.foundAllTransactionsProp;
    this.dateBuckets = this.generateDateBuckets(this.transactions);
  }

  mounted() {
    this.container.addEventListener("scroll", this.handleScroll);
  }

  destroyed() {
    this.container.removeEventListener("scroll", this.handleScroll);
  }

  onClickTransaction(transaction: Transaction) {
    this.$emit("click-transaction", transaction);
  }

  get filteredTransactions() {
    return this.filterTransactions(this.transactions);
  }

  filterTransactions(transactions: Transaction[]) {
    return transactions.filter(
      ({ descriptor }) =>
        !this.filterText || descriptor.includes(this.filterText)
    );
  }

  handleScroll(event: any) {
    const el = this.$refs.transactionList as HTMLElement;
    const bounding = el.getBoundingClientRect();

    this.shouldShowMore =
      this.container === window
        ? bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) + 100
        : event.target.offsetHeight + event.target.scrollTop >=
          event.target.scrollHeight - 100;

    if (
      this.shouldShowMore &&
      !this.incrementing &&
      !this.foundAllTransactions
    ) {
      this.incrementing = true;
      this.incrementLimit();
    }
  }

  generateDateBuckets(transactions: Transaction[]): DateBucket[] {
    if (!transactions || !transactions.length) return [];

    let transactionListCopy = [...transactions];

    transactionListCopy.sort((t1, t2) => {
      return (
        new Date(t2.dateAuthorized || t2.dateSettled || "").getTime() -
        new Date(t1.dateAuthorized || t1.dateSettled || "").getTime()
      );
    });

    if (this.limit) {
      transactionListCopy = transactionListCopy.splice(0, this.limit);
    }

    const relativeDateList: string[] = [];
    const transactionsByDate: { [key: string]: Transaction[] } = {};

    transactionListCopy.forEach((transaction) => {
      try {
        const sectionDate = new Date(
          transaction.dateAuthorized || transaction.dateSettled || ""
        );
        const relativeSectionDate = relativeDate(sectionDate);

        if (!relativeDateList.includes(relativeSectionDate)) {
          relativeDateList.push(relativeSectionDate);
          transactionsByDate[relativeSectionDate] = [];
        }
        transactionsByDate[relativeSectionDate].push(transaction);
      } catch (e) {
        return false;
      }
    });

    return relativeDateList.map((relDate, id) => {
      let total = 0;
      const txByDate = transactionsByDate[relDate];
      txByDate.forEach((t: Transaction) => {
        total += t.promoCreditAmount
          ? t.amount - t.promoCreditAmount
          : t.amount;
      });

      return {
        id,
        title: relDate,
        total,
        transactions: txByDate,
      };
    });
  }

  fetchNextPage() {
    return transactionStore.actions
      .fetchTransactions({
        cardID: "",
        limit: this.incrementBy,
        offset: this.offset,
        query: this.query,
      })
      .then((result: TransactionList) => {
        const subset = this.showDeclines ? "declines" : "all";
        this.transactions = [
          ...this.transactions,
          ...ldGet<TransactionList, any, Transaction[]>(result, subset, []),
        ];

        this.offset += this.incrementBy;

        if (
          result.totalTransactions &&
          this.offset >= result.totalTransactions
        ) {
          this.foundAllTransactions = true;
        }

        this.incrementing = false;
      });
  }

  incrementLimit() {
    this.loading = true;
    this.limit += this.incrementBy;

    const updateDateBuckets = () => {
      if (this.showDateBuckets) {
        this.dateBuckets = this.generateDateBuckets(this.transactions);
        this.loading = false;
      } else {
        this.loading = false;
      }
    };

    if (this.nodePaginate) {
      this.fetchNextPage().then(() => {
        updateDateBuckets();
      });
    } else {
      updateDateBuckets();
      this.incrementing = false;
    }
  }

  resetPagination() {
    this.limit = INITIAL_LIMIT;
    this.offset = INITIAL_LIMIT;
    this.foundAllTransactions = false;
  }
}
</script>
<style lang="scss" scoped>
.transaction-list {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;

  > .zero-state {
    padding: 15px 0;
    color: $gray-800;
  }

  > .date-bucket {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 20px;
    padding-bottom: 25px;
  }

  > .date-bucket > .title {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    > .total {
      color: $gray-600;
    }
  }

  &.-descriptor.-date.-amount:not(.-settlement) {
    & .transaction > .left {
      width: 75%;
    }

    & .transaction > .right {
      width: 25%;
    }
  }

  > .spinner-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}
</style>
