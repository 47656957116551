<template>
  <div class="card-type-header" :class="{ preview }">
    <div class="card-icon-emoji" v-if="isEmojiIcon">
      {{ card.style?.icon }}
    </div>
    <img :src="cardIconSrc" class="card-icon-logo" v-else-if="cardIconSrc" />
    <img
      src="/assets/images/privacy-card/card-logo-base.svg"
      class="card-icon-default"
      v-else
    />
    <div class="card-type-label">
      <SVGIcon
        :icon="labelIcon"
        :uri="labelIconUri"
        :size="labelIconSize"
        :class="labelIconClass"
        v-if="labelIcon || labelIconUri"
      />
      <div class="card-type-label-text">{{ labelText }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Card, CardType } from "@/types/Card";
import { getIsDarkBackground } from "@/util";
import SVGIcon from "@/components/SVGIcon.vue";
import CardContainer from "./CardContainer.vue";

const baseImageBucket = "https://s3.amazonaws.com/privacy-web/images/";
const CARD_IMAGES = baseImageBucket + "cards/";

@Component({
  components: {
    SVGIcon,
    CardContainer,
  },
})
export default class CardHeader extends Vue {
  @Prop({ required: true }) card!: Card;
  @Prop({ default: false }) preview!: boolean;

  get isCustomCard() {
    return (
      this.card.type === CardType.MERCHANT_LOCKED &&
      (!!this.card.style?.bgColor ||
        !!this.card.style?.filename ||
        !!this.card.style?.icon)
    );
  }

  get isCategoryCard() {
    return this.card.type === CardType.UNLOCKED && !!this.card.merchantCategory;
  }

  get isEmojiIcon() {
    return !!this.card.style?.icon;
  }

  get cardIconSrc() {
    if (this.card.style?.filename) {
      let src = `${CARD_IMAGES}${this.card.style.filename}`;

      if (this.card.style?.lastModified) {
        src += `?lastModified=${this.card.style.lastModified}`;
      }

      return src;
    }

    return "";
  }

  get labelText() {
    if (this.card.type === CardType.SINGLE_USE) {
      return "SINGLE-USE";
    }

    if (this.isCategoryCard) {
      return "CATEGORY LOCKED";
    }

    if (this.card.type === CardType.UNLOCKED) {
      return "UNLOCKED";
    }

    if (this.card.type === CardType.DIGITAL_WALLET) {
      return "DIGITAL WALLET";
    }

    return "MERCHANT LOCKED";
  }

  get labelIcon() {
    if (this.card.type === CardType.MERCHANT_LOCKED || this.isCategoryCard) {
      return "padlock";
    }

    return "";
  }

  get labelIconUri() {
    if (this.card.type === CardType.SINGLE_USE) {
      return "/assets/images/icons/card-icon-burner.svg";
    }

    if (this.card.type === CardType.UNLOCKED && !this.isCategoryCard) {
      return "/assets/images/icons/unlocked.svg";
    }

    return "";
  }

  get labelIconSize() {
    return this.preview ? 16 : 12;
  }

  get labelIconClass() {
    if (this.isCustomCard || this.isCategoryCard) {
      const isDarkBackground = getIsDarkBackground(this.card.style?.bgColor);
      return isDarkBackground ? "label-icon" : "label-icon-light";
    }

    return "label-icon";
  }
}
</script>

<style lang="scss" scoped>
.card-type-header {
  padding: 24px 24px 0 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.card-icon-default {
  height: 24px;
  width: 24px;
}

.card-icon-logo {
  max-height: 48px;
  max-width: 100px;
  object-fit: contain;
}

.card-icon-emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  background-color: fade-out($black, 0.9);
  backdrop-filter: blur(32px);
  border-radius: $radius-large;
  font-size: 32px;
}

.card-type-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: fade-out($neutrals-8, 0.9);
  border-radius: 4px;
  font-family: $font-stack-graphik;
  font-size: 11px;
  font-weight: 600;
  padding: 4px 8px;
}

.label-icon,
.label-icon-light {
  margin-right: 8px;
  height: 12px;
  width: 12px;
}

.label-icon ::v-deep > svg {
  width: inherit;
  height: inherit;
}

.label-icon ::v-deep path {
  fill: $white;
}

.label-icon-light ::v-deep path {
  fill: $black;
}

.preview {
  padding: 16px 16px 0 16px;

  .card-icon-logo {
    max-height: 32px;
    max-width: 64px;
  }

  .card-icon-emoji {
    height: 32px;
    width: 32px;
    font-size: 20px;
  }

  .label-icon,
  .label-icon-light {
    margin-right: 0;
    height: 16px;
    width: 16px;
  }

  .card-type-label {
    background-color: unset;
    padding: 0px;
  }

  .card-type-label-text {
    display: none;
  }
}
</style>
