<template>
  <div class="reissue-row">
    <div class="card-preview">
      <PrivacyCard
        :cardProp="card"
        :thumbnail="true"
        @click.native="inspectCard()"
      />
    </div>
    <div class="card-thumbnail" :style="thumbnailStyle">
      <div class="icon">{{ cardIcon }}</div>
    </div>
    <div class="column nickname">
      <h6 class="col-header">Nickname</h6>
      <div class="col-content">{{ card.memo || defaultMemo }}</div>
    </div>
    <template v-if="!isReissued">
      <div class="column last-four">
        <h6 class="col-header">Card Number</h6>
        <div class="col-content">•••{{ card.lastFour }}</div>
      </div>
      <div class="flex-grow-1"></div>
      <b-dropdown
        variant="primary"
        split
        class="reissue-button"
        v-if="!loading"
        @click="reissue"
        aria-label="card reissue menu"
      >
        <template #button-content>
          <b-icon icon="arrow-repeat" />
          <span>Reissue</span>
        </template>
        <b-dropdown-item @click="close">
          <SVGIcon icon="trash" />
          <span>Close Card</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
    <template v-if="isReissued">
      <div class="column full-pan">
        <h6 class="col-header new">New Card Number</h6>
        <div class="col-content">
          <b-icon icon="check-circle-fill" class="new-check" />
          <span class="copy-pan" v-click-to-copy.stop="card.PAN">
            {{ panWithSpaces }}
          </span>
        </div>
      </div>
      <div class="column exp">
        <h6 class="col-header new">Exp</h6>
        <div class="col-content">{{ card.expMonth }}/{{ card.expYear }}</div>
      </div>
      <div class="column cvv">
        <h6 class="col-header new">CVV</h6>
        <div class="col-content">{{ card.CVV }}</div>
      </div>
      <div class="flex-grow-1"></div>
      <template v-if="card.billingLink && !loading">
        <b-tooltip
          target="update-billing"
          v-if="card.state === CardState.PAUSED"
          placement="left"
          show
        >
          Don't forget to unpause this card before updating your billing info!
        </b-tooltip>
        <BaseButton
          id="update-billing"
          variant="success"
          :class="billingComplete ? 'billing-complete' : 'billing'"
          @click="openBilling()"
          v-if="!loading"
        >
          <span>Update Billing</span>
          <b-icon icon="box-arrow-up-right" class="new-tab-icon" />
        </BaseButton>
      </template>
      <template v-if="!card.billingLink && !loading">
        <div class="billing-reminder">
          Don't forget to update your card details with the merchant
        </div>
      </template>
    </template>
    <b-spinner class="spinner" label="Loading..." v-if="loading"></b-spinner>
  </div>
</template>

<script lang="ts">
import { StyleValue } from "vue/types/jsx";
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  BIcon,
  BIconArrowRepeat,
  BIconCheckCircleFill,
  BIconBoxArrowUpRight,
} from "bootstrap-vue";
import { Card, CardState } from "@/types/Card";
import { EVENTS } from "@/types/Event";
import PrivacyCard from "@/components/PrivacyCard.vue";
import { eventStore } from "@/store";

const CARD_LOGO_BUCKET = "https://s3.amazonaws.com/privacy-web/images/cards/";

@Component({
  components: {
    PrivacyCard,
    BIcon,
    BIconArrowRepeat,
    BIconCheckCircleFill,
    BIconBoxArrowUpRight,
  },
})
export default class ReissueRow extends Vue {
  @Prop({ required: true }) card!: Card;
  @Prop({ default: false }) loading!: boolean;
  CardState = CardState;

  billingComplete = false;

  get defaultMemo() {
    return "Untitled";
  }

  get cardIcon() {
    return this.card?.style?.icon || "";
  }

  get thumbnailStyle() {
    const styles: StyleValue = {};

    if (this.card?.style?.bgColor) {
      styles["background-color"] = `#${this.card.style.bgColor}`;
    }

    if (this.card?.style?.filename) {
      styles["background-image"] = `url("${CARD_LOGO_BUCKET}${
        this.card.style.filename
      }?lastModified=${this.card?.style?.lastModified || ""}")`;
    } else if (this.card?.style?.icon) {
      styles["background-image"] = "none";
    }

    return styles;
  }

  get isReissued() {
    return !!this.card.meta?.reissuedFrom;
  }

  get panWithSpaces() {
    return (this.card.PAN.toString().match(/.{4}/g) || []).join(" ");
  }

  reissue() {
    this.$emit("reissue");
  }

  close() {
    this.$emit("close");
  }

  openBilling() {
    const newTab = window.open(this.card.billingLink, "_blank");
    if (newTab) {
      newTab.focus();
    }
    this.billingComplete = true;
    eventStore.actions.record({
      name: EVENTS.CARD.UPDATED_BILLING,
      data: {
        context: "Reissue Page",
      },
    });
  }

  inspectCard() {
    this.$emit("inspect-card");
  }
}
</script>

<style lang="scss" scoped>
.reissue-row {
  height: 100px;
  padding: 0 30px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $color-shadow-faint;
}

.card-preview {
  width: 280px;
  height: 99px;
  padding: 20px 20px 0;
  overflow: hidden;
}

::v-deep .privacy-card {
  cursor: pointer;
}

.card-thumbnail {
  display: none;
  background-color: $gray-400;
  background-image: url("/assets/images/icons/card-20-white.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  background-size: contain;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  padding: 8px 3px;
  margin: 20px;
  border-radius: 50%;

  .icon {
    font-size: 22px;
    line-height: 1;
    text-align: center;
    margin-left: 1px;
  }
}

.column {
  padding: 0 10px 0 20px;
}

.col-header {
  color: $gray-400;
  margin-bottom: 5px;
  font-weight: 700;

  &.new {
    color: $color-green;
  }
}

::v-deep .new-check {
  fill: $color-green;
}

.col-content {
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.nickname {
  max-width: 160px;
  min-width: 100px;
  width: 11vw;
}

.full-pan {
  width: 210px;
}

.copy-pan {
  cursor: pointer;
}

.exp {
  width: 100px;
}

.reissue-button {
  ::v-deep .dropdown-toggle {
    border-left: 1px solid $color-shadow-faint;

    &::after {
      margin-left: 0;
    }
  }

  ::v-deep .dropdown-item svg {
    margin-right: 0.625rem;
    margin-left: -0.25rem;
  }
}

.billing-reminder {
  max-width: 190px;
  text-align: right;
}

::v-deep .btn.billing {
  background: $color-green;

  &:hover:enabled {
    background-color: darken($color-green, 7.5%);
  }

  .new-tab-icon {
    margin-right: -0.25rem;
    margin-left: 0.625rem;
  }
}

::v-deep .btn.billing-complete {
  background: fade-out($color-green, 0.9);
  fill: $color-green;
  color: $color-green;

  .new-tab-icon {
    margin-right: -0.25rem;
    margin-left: 0.625rem;
  }
}

@media only screen and (max-width: 969px) {
  .card-preview {
    display: none;
  }

  .card-thumbnail {
    display: block;
  }
}

@media only screen and (max-width: 759px) {
  .card-thumbnail {
    display: none;
  }
}

@media only screen and (max-width: 679px) {
  .reissue-row {
    flex-flow: column;
    height: auto;
    padding: 20px 0;
    text-align: center;
  }

  .column {
    padding: 0 0 15px;
  }
}
</style>
