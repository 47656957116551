<template>
  <div class="reissue-page">
    <ReissueList />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ReissueList from "@/components/ReissueList.vue";

@Component({
  components: {
    ReissueList,
  },
})
export default class ReissuePage extends Vue {}
</script>

<style lang="scss" scoped>
.reissue-page {
  position: relative;
  top: 60px;
  min-height: calc(100vh - 60px);
  width: 100%;
  overflow: hidden;
}
</style>
