<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :class="'billing-address'"
      @submit="onSubmit"
      data-test="signup-billing-address-form"
    >
      <ProgressTracker
        v-if="showProgressTracker"
        :currentStep="2"
        :totalSteps="5"
      ></ProgressTracker>
      <h1>Billing Address</h1>
      <p class="match">
        This should be a permanent US residential address. Please no P.O. boxes,
        mail drops, or temporary addresses (see your Cardholder Agreement).
      </p>
      <b-alert v-if="errorMessage" variant="danger" show>
        {{ errorMessage }}
      </b-alert>
      <AddressInput
        @address-changed="handleAddressChanged"
        :existingAddress="address1"
        label="Street Address"
      />
      <BaseInputGroup>
        <BaseInput
          name="address2"
          label="Apt/Unit (Optional)"
          placeholder="Apartment"
          v-model="address2"
          type="text"
          maxlength="128"
        />
        <BaseInput
          name="zipcode"
          label="ZIP Code"
          placeholder="00000"
          v-model="zipcode"
          type="text"
          maxlength="5"
          :state="zipcodeError ? false : null"
          data-test="input-personal-zip"
        />
      </BaseInputGroup>
      <BaseButton
        type="submit"
        variant="primary"
        size="lg"
        :disabled="loading"
        data-test="confirm-address"
        @click="trackClick"
      >
        Confirm Address
      </BaseButton>
      <OnboardingFooterActions
        :allowChangeAccountType="true"
        :showFinishLater="isRemediatingKYC"
        @footer-action="onFooterAction"
      />
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import ProgressTracker from "@/components/ProgressTracker.vue";
import AddressInput from "@/components/AddressInput.vue";
import OnboardingFooterActions from "@/components/OnboardingFooterActions.vue";
import { eventStore, userStore } from "@/store";
import { EVENTS } from "@/types/Event";
import {
  AddressResult,
  onboardingStepName,
  OnboardingSteps,
} from "@/types/Onboarding";

@Component({
  components: {
    ProgressTracker,
    AddressInput,
    OnboardingFooterActions,
  },
})
export default class SignupBillingAddress extends Vue {
  @Prop({ default: false }) fullpage!: boolean;
  @Prop({ default: false }) showProgressTracker!: boolean;
  errorMessage = "";
  loading = false;
  success = false;
  zipcodeError = false;

  address1 = "";
  address2 = "";
  zipcode = "";

  get isRemediatingKYC(): boolean {
    return !!userStore.getters.currentUser?.isRemediatingKYC;
  }

  @Watch("billingAddress")
  resetErrorState() {
    this.errorMessage = "";
  }

  created() {
    const user = userStore.getters.currentUser;
    this.address1 = user?.address1 || "";
    this.address2 = user?.address2 || "";
    this.zipcode = user?.zipcode || "";

    eventStore.actions.record({
      name: EVENTS.MODAL.VIEWED,
      data: {
        modalName: "Billing Address Screen Viewed",
      },
    });
  }

  handleAddressChanged(addressResult: AddressResult) {
    // Fill in data from google.  If user needs to change it, they can.
    this.address1 = addressResult.address1;
    if (addressResult.zipcode) {
      this.zipcode = addressResult.zipcode;
    }
  }

  trackClick() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.BILLING_ADDRESS)} Primary CTA`,
    });
  }

  onSubmit() {
    this.resetErrorState();
    const { address1, address2, zipcode } = this;

    if (!address1.length) {
      this.errorMessage = "Please enter your street address";
      return;
    }

    // zipcode is validated and city/state are derived in node,
    // just do some basic validation for now
    if (zipcode?.length === 5 && /^[0-9]{5}$/.test(zipcode)) {
      this.zipcodeError = false;
    } else {
      this.zipcodeError = true;
      this.errorMessage = "Please enter a valid 5-digit ZIP code";
      return;
    }

    this.loading = true;
    const addressInfo = {
      address1,
      address2,
      zipcode,
    };

    userStore.actions
      .saveAddress(addressInfo)
      .then(() => {
        this.loading = false;
        this.$emit("complete-step");
      })
      .catch(({ response }) => {
        this.loading = false;
        this.errorMessage =
          response?.data?.message ||
          "There was an internal server error, please contact support@privacy.com";
      });
  }

  onFooterAction(nextScreen: OnboardingSteps): void {
    this.$emit("complete-step", nextScreen);
  }
}
</script>

<style lang="scss" scoped>
.billing-address {
  h1 {
    all: unset;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    margin-bottom: 15px;
    line-height: 1.4;
    color: $gray-800;
  }

  .field {
    margin-bottom: 2px;
  }

  .match {
    font-size: 14px;
    color: $gray-600;
    text-align: center;
    padding: 0 10px;
    margin-top: 1em;
  }

  .btn {
    margin-top: 1em;
  }
}
</style>
