<template>
  <div class="row flex-grow-1">
    <BaseForm
      class="signup-step -login -tfa-recovery"
      v-if="!smsFailure"
      @submit="tfaRecovery"
      :class="{ error: errorMessage.length > 0, '-loading': pendingRequest }"
      novalidate="novalidate"
    >
      <div class="form-header">
        <h1>TFA Reset</h1>
        <a class="header-link" @click="backToLogin">Back to Login</a>
      </div>
      <div class="blurb -left">
        We've sent you a text containing the security code to your recovery
        phone number set when enabling authentication app TFA.
      </div>
      <div class="error-message" v-if="errorMessage.length > 0">
        {{ errorMessage }}
      </div>
      <BaseInput
        name="code"
        label="Verification Code"
        placeholder="12345"
        v-model="code"
        type="text"
        max-length="5"
        :autofocus="true"
        autocomplete="off"
        :state="errorMessage.length > 0 ? false : null"
        :disabled="tfaReset"
      ></BaseInput>
      <BaseButton
        v-if="!tfaReset"
        variant="primary"
        @click="tfaRecovery"
        :loading="pendingRequest"
        >Reset</BaseButton
      >
      <BaseButton v-if="tfaReset" variant="success"
        >TFA Reset - Please log in again</BaseButton
      >
    </BaseForm>
    <BaseForm
      class="signup-step -login"
      v-if="smsFailure"
      novalidate="novalidate"
    >
      <div class="form-header">
        <h1>TFA Recovery</h1>
      </div>
      <div class="blurb -left">
        We couldn't reset your 2FA settings via SMS because you don't have a
        phone number stored with us. Please contact
        <a href="mailto:support@privacy.com">support@privacy.com</a>
      </div>
    </BaseForm>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import BaseButton from "../../components/BaseButton.vue";
import BaseForm from "../../components/BaseForm.vue";
import BaseInput from "../../components/BaseInput.vue";

import { userStore } from "../../store";

const CODE_MIN_LENGTH = 5;

@Component({
  components: {
    BaseButton,
    BaseForm,
    BaseInput,
  },
})
export default class TwoFactorAuthRecovery extends Vue {
  get userToken() {
    return userStore.getters.userToken;
  }
  pendingRequest = false;
  tfaReset = false;
  code = "";
  errorMessage = "";
  smsFailure = false;

  created() {
    userStore.actions
      .sendVerificationCode({ userToken: this.userToken || "" })
      .catch(() => {
        this.errorMessage = "Error sending SMS code.";
        // request user to email support
        this.smsFailure = true;
      })
      .finally(() => {
        this.pendingRequest = false;
      });
  }

  tfaRecovery() {
    if (!this.code) {
      this.errorMessage = "Enter your SMS verification code";
      return;
    }
    if (this.code.length < CODE_MIN_LENGTH) {
      this.errorMessage = "Enter your 5 digit verification code";
      return;
    }
    this.pendingRequest = true;

    userStore.actions
      .removeTFA({
        userToken: this.userToken || "",
        code: this.code,
      })
      .then(() => {
        this.tfaReset = true;
        this.errorMessage = "";
      })
      .catch((err) => {
        this.errorMessage =
          err.response?.data.message || "Error confirming verification code";
      })
      .finally(() => {
        this.pendingRequest = false;
      });
  }

  backToLogin() {
    userStore.mutations.clearCurrentUser();
    this.$router.push({ name: "login" });
  }
}
</script>

<style lang="scss" scoped>
.btn-success {
  pointer-events: none;
}
</style>
