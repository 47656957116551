<template>
  <div class="card-container" :class="cardClasses" :style="cardCustomStyles">
    <div class="bg-container">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Card, CardType } from "@/types/Card";
import { getIsDarkBackground } from "@/util";

@Component
export default class CardContainer extends Vue {
  @Prop({ required: true }) card!: Pick<
    Card,
    "type" | "state" | "style" | "merchantCategory"
  >;

  get isCustomCard() {
    return (
      this.card.type === CardType.MERCHANT_LOCKED &&
      (!!this.card.style?.bgColor ||
        !!this.card.style?.filename ||
        !!this.card.style?.icon)
    );
  }

  get isCategoryCard() {
    return this.card.type === CardType.UNLOCKED && !!this.card.merchantCategory;
  }

  get cardCustomStyles() {
    if (
      (this.isCustomCard || this.isCategoryCard) &&
      this.card.style?.bgColor
    ) {
      return {
        "background-color": `#${this.card.style.bgColor}`,
      };
    }

    return {};
  }

  get cardClasses() {
    if (this.isCustomCard || this.isCategoryCard) {
      const isDarkBackground = getIsDarkBackground(this.card.style?.bgColor);
      return isDarkBackground ? "locked-custom" : "locked-custom-light";
    }

    if (this.card.type === CardType.SINGLE_USE) {
      return "single-use";
    }

    if (this.card.type === CardType.UNLOCKED) {
      return "unlocked";
    }

    return "locked-default";
  }
}
</script>

<style lang="scss" scoped>
.card-container,
.bg-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: $radius-large;
}

.card-container {
  color: $white;
}

.locked-custom-light {
  color: $black;
  border: 1px solid $foreground-border;
}

.locked-default {
  background: linear-gradient(292.13deg, #fbf5ef 0%, #4949f1 64%, #2121a4 100%);
}

.single-use {
  background: linear-gradient(292deg, #ed8b72 0%, #c12943 80.5%);
}

.unlocked {
  background: linear-gradient(112deg, #7221a4 22.59%, #4949f1 91.86%);
}

.locked-custom > .bg-container,
.locked-custom-light > .bg-container {
  background-image: url("/assets/images/privacy-card/card-locked-custom.svg");
}

.locked-default > .bg-container {
  background-image: url("/assets/images/privacy-card/card-locked-default.svg");
}

.unlocked > .bg-container {
  background-image: url("/assets/images/privacy-card/card-unlocked-default.svg");
}

.bg-container {
  background-size: cover;
}
</style>
