<template>
  <div class="pwp-merchant-selector">
    <div class="merchant-list" :class="{ '-loading': pageState === 'LOADING' }">
      <div
        class="merchant"
        :class="{
          '-selected': isCurrentMerchant(merchant),
          '-blank': !merchant.hostname,
        }"
        :style="{ 'background-color': '#' + merchant.bgColor }"
        @click="onSelectMerchant(merchant)"
        v-for="(merchant, i) in merchants.slice(0, 6)"
        :key="i + (merchant.id || '') + (merchant.bgColor || '')"
      >
        <img alt="" :src="getLogoSrc(merchant)" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import ldGet from "lodash/get";
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { cardStore } from "@/store";
import { Merchant } from "@/types/Merchant";
import { Toast } from "@/mixins/Toast";
import { getLogoSrc } from "@/util";

@Component
export default class MerchantSelector extends Mixins(Toast) {
  @Prop() query?: string;
  @Prop() currentMerchant?: Merchant;

  getLogoSrc = getLogoSrc;

  pageState = "LOADING";
  merchants: Partial<Merchant>[] = [{}, {}, {}, {}, {}, {}];
  suggestedMerchants: Partial<Merchant>[] = [];
  queryTimeout = 0;
  internalMerchant: Partial<Merchant> | null = null;

  created() {
    this.getSuggestedMerchants();
    this.internalMerchant = this.currentMerchant || null;
  }

  getSuggestedMerchants() {
    cardStore.actions
      .getSuggestedMerchants()
      .then((merchants = []) => {
        this.pageState = "DEFAULT";
        this.merchants = merchants;
        this.suggestedMerchants = merchants;
      })
      .catch(() => {
        this.pageState = "DEFAULT";
      });
  }

  @Watch("query")
  searchMerchants() {
    const term = this.query;
    window.clearTimeout(this.queryTimeout);

    if (!term || term.length < 2) {
      this.pageState = "DEFAULT";
      const merchantInResults = !!this.suggestedMerchants.find(
        (merchant) =>
          merchant.hostname ===
          ldGet<any, string>(this, "internalMerchant.hostname")
      );
      const merchantList = [...this.suggestedMerchants];
      if (this.internalMerchant) merchantList.unshift(this.internalMerchant);

      this.merchants =
        merchantInResults || !this.internalMerchant
          ? this.suggestedMerchants
          : merchantList;
      return;
    }

    this.pageState = "LOADING";
    const TIMEOUT = 500;
    const blankResults = [{}, {}, {}, {}, {}, {}];

    this.queryTimeout = window.setTimeout(() => {
      cardStore.actions
        .searchMerchants(term)
        .then((merchants: Partial<Merchant>[] = []) => {
          this.pageState = "DEFAULT";
          const merchantInResults = !!merchants.find(
            (merchant: Partial<Merchant>) =>
              merchant.hostname ===
              ldGet<any, string>(this, "internalMerchant.hostname")
          );

          const filteredMerchantList = merchants.filter(
            (merchant: Partial<Merchant>) =>
              merchant.id !==
              ldGet<any, string>(this, "internalMerchant.hostname")
          );

          this.merchants =
            merchantInResults || !this.internalMerchant
              ? merchants.concat(blankResults)
              : [this.internalMerchant, ...filteredMerchantList].concat(
                  blankResults
                );
        })
        .catch(() => {
          this.pageState = "DEFAULT";
        });
    }, TIMEOUT);
  }

  isCurrentMerchant(merchant: Partial<Merchant>) {
    return (
      merchant &&
      this.internalMerchant &&
      this.internalMerchant.hostname === merchant.hostname
    );
  }

  onSelectMerchant(merchant: Partial<Merchant>) {
    if (
      ldGet<any, string>(this, "internalMerchant.hostname") ===
      merchant.hostname
    ) {
      this.internalMerchant = null;
    } else {
      this.internalMerchant = merchant;
    }

    this.$emit("select-merchant", merchant);
  }
}
</script>
<style lang="scss" scoped>
.pwp-merchant-selector {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > .search {
    display: flex;
    flex-direction: row;
    padding: 0 30px;
  }

  > .search > .svg-icon {
    transform: translate(0, 2px) scale(0.8, 0.8);
  }

  > .search > input {
    flex-grow: 1;
    background: none;
    border: 0;
    font-size: 16px;
  }

  > .merchant-list {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 20px;
  }

  > .merchant-list > .merchant {
    position: relative;
    display: flex;
    flex-basis: calc(100% / 2 - 5px);
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 0 10px;
    height: 80px;
    background-color: $gray-300;
    border-radius: $border-radius;
    transition: opacity $duration-shorter;
    transition-duration: $ease-out-expo;
    cursor: pointer;
    user-select: none;

    &:hover {
      opacity: 0.8;
    }

    &.-blank {
      pointer-events: none;
    }
  }

  > .merchant-list > .merchant.-selected {
    box-shadow:
      0 0 0 2px $white,
      0 0 0 4px $blue;
    opacity: 1;

    &::after {
      content: "";
      position: absolute;
      right: -2px;
      bottom: -2px;
      display: block;
      height: 30px;
      width: 30px;
      background: $blue;
      background-image: url("/assets/images/icons/tick-20-white.svg");
      background-repeat: no-repeat;
      background-position: center;
      border-top: 2px solid $white;
      border-left: 2px solid $white;
      border-radius: 10px 0 8px;
    }
  }

  > .merchant-list > .merchant:hover {
    background-color: $gray-300;
  }

  > .merchant-list > .merchant > img {
    height: auto;
    max-height: 35px;
    width: auto;
    max-width: 80px;
  }

  > .merchant-list > .merchant > .hostname {
    margin-left: auto;
    padding-left: 10px;
    overflow: hidden;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $white;
  }

  .merchant-list.-loading > .merchant:not(.-current-merchant) {
    background: $gray-300 !important;
    background-color: $gray-300 !important;
    box-shadow: none;
    opacity: 1;

    > img {
      visibility: hidden;
    }

    &::after,
    &::before {
      display: none;
    }
  }
}
</style>
