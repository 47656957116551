<template>
  <div class="privacy-explainer">
    <div class="back">
      <nav>
        <BaseButton variant="link" @click="hideExplainer">
          <SVGIcon icon="arrow-left" /> Back to My Cards
        </BaseButton>
      </nav>
    </div>
    <div class="hi">
      <img src="/assets/images/shared/explainer-hi.svg" alt="" />
    </div>
    <div class="content">
      <p>
        Hi! You're probably here because someone shared a Privacy Virtual Card
        with you. These cards are for making online purchases, like regular
        debit or credit cards, but they're a bit smarter.
      </p>
      <p class="list-label">
        There are different types of shared Privacy Virtual Cards:
      </p>
      <ul>
        <li>
          <strong>Single-Use Cards</strong> automatically close after the first
          transaction.
        </li>
        <li>
          <strong>Merchant-Locked Cards</strong> can only be used at one
          merchant (think one card for Netflix, one card for Amazon, etc).
        </li>
        <li>
          <strong>Category-Locked Cards</strong> will only authorize purchases
          that fall under the specified category (i.e. Groceries).
        </li>
      </ul>
      <p>
        Privacy Virtual Cards also have spend limits to prevent unwanted fees or
        overspending (a $13 monthly limit for Netflix ensures you never get
        overbilled).
      </p>
      <p>
        Purchases you make with the card will be paid for by whoever shared the
        card with you (so please spend responsibly).
      </p>
      <p>
        If you have more questions, check out our
        <b-link href="https://privacy.com" target="_blank">main website</b-link>
        and
        <b-link href="https://support.privacy.com" target="_blank">
          Help Center</b-link
        >.
      </p>
      <p><strong>&mdash; The Privacy Team</strong></p>
    </div>
    <div class="badges">
      <div class="badge">
        <button
          class="extension-badge"
          @click="install('shared-cards-explainer')"
        >
          <div class="extension-badge-content">
            <img
              class="extension-badge-browser-icon"
              :src="browserIconSrc"
              alt=""
            />
            Add to {{ browserName }}
          </div>
        </button>
      </div>
      <div class="badge">
        <a
          href="https://apps.apple.com/us/app/privacy-com/id1040298266?itsct=apps_box_link&itscg=30200"
          target="_blank"
          rel="noopener"
          aria-label="Get Privacy on the App Store"
        >
          <img
            class="badge-icon"
            src="/assets/images/badges/app-store.svg"
            alt="Get Privacy on the App Store"
          />
        </a>
      </div>
      <div class="badge">
        <a
          href="https://play.google.com/store/apps/details?id=com.privacy.pay&hl=en_US&gl=US"
          target="_blank"
          rel="noopener"
          aria-label="Get Privacy on the Google Play Store"
        >
          <img
            class="badge-icon"
            src="/assets/images/badges/google-play.svg"
            alt="Get Privacy on the Google Play Store"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";

import SVGIcon from "@/components/SVGIcon.vue";
import { Extension } from "@/mixins/Extension";

@Component({
  components: {
    SVGIcon,
  },
})
export default class PrivacyExplainer extends Mixins(Extension) {
  async hideExplainer() {
    this.$emit("hideExplainer");
  }

  get browserIconSrc() {
    return this.browserIcon("32");
  }
}
</script>

<style lang="scss" scoped>
.privacy-explainer {
  max-width: 690px;
  padding: 40px;

  display: flex;
  flex-flow: column;
  align-items: center;
}

.back {
  .btn-link {
    color: black;
    font-weight: 600;
  }

  .svg-icon {
    margin-top: -3px;
  }
}

.hi {
  margin: 40px 0;
}

.content {
  font-size: 18px;
  margin-bottom: 40px;

  p,
  ul {
    margin-bottom: 2rem;
  }

  p.list-label {
    margin-bottom: 0.5rem;
  }

  li {
    margin-bottom: 0.5rem;
  }
}

.badges {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 250px;
}

@media #{$media-phone} {
  .badges {
    flex-direction: column;
  }

  .badge {
    margin-bottom: 20px;
  }

  .extension-badge {
    margin: auto;
  }
}

.badge-icon {
  height: 40px;
}

.extension-badge {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 6px 10px;
  color: white;
  background-color: black;
  border-radius: 6px;
  font-family: $font-stack-lato;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  border: none;

  .extension-badge-content {
    width: 100%;
  }

  .extension-badge-browser-icon {
    margin-right: 8px;
    margin-bottom: 2px;
    width: 20px;
    height: 20px;
  }
}
</style>
