import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { eventStore } from "@/store";
import { EVENTS } from "@/types/Event";
import auth from "./auth";
import dashboard from "./dashboard";
import login from "./login";
import onboarding from "./onboarding";
import oneclick from "./oneclick";
import sharing from "./sharing";
import NotFound from "../views/NotFound.vue";
import { navigationGuard } from "./navigation-guard";
import kybVerification from "./kyb-verification";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  ...auth,
  ...dashboard,
  ...login,
  ...onboarding,
  ...oneclick,
  ...sharing,
  ...kybVerification,
  {
    path: "*",
    meta: { title: "Page Not Found" },
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: () => {
    // always scroll to top
    return { x: 0, y: 0 };
  },
});

router.beforeEach(navigationGuard);

router.afterEach((to) => {
  eventStore.actions.record({
    name: EVENTS.PAGE.PAGEVIEW,
    data: {
      page: to.path,
      queryParams: to.query,
      name: to.name,
      pageWidth: Math.max(
        document.body.clientWidth || 0,
        window.innerWidth || 0
      ),
      pageHeight: Math.max(
        document.body.clientHeight || 0,
        window.innerHeight || 0
      ),
    },
  });
});

export default router;
