<template>
  <div>
    <div class="category-container">
      <b-card
        v-for="(category, index) in categories"
        :key="index"
        border-variant="white"
        class="category-item"
        body-class="category-item-body"
        :class="{
          '-active': selectedCategory?.name === category.name,
        }"
        @click="selectCategory(category)"
      >
        <p>{{ category.icon }} {{ category.name }}</p>
      </b-card>
    </div>
  </div>
</template>

<script lang="ts">
import { MerchantCategory } from "@/types/MerchantCategory";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class SelectCardTypeModal extends Vue {
  @Prop({ required: true }) categories!: MerchantCategory[];
  @Prop({ required: true }) selectCategory!: (
    category: MerchantCategory
  ) => void;
  @Prop({ default: undefined })
  selectedCategory!: MerchantCategory;
}
</script>

<style lang="scss" scoped>
.category-container {
  display: flex;
  flex-wrap: wrap;
  height: 275px;
  border-bottom: 1px solid $foreground-border;
  margin-bottom: 24px;
  align-content: baseline;
  gap: 8px;
  overflow-y: scroll;
  padding: 2px;
}
.category-item {
  align-self: flex-start;
  background-color: #f5f7f8;
  font-family: $font-stack-graphik;
  overflow: hidden;
  flex-basis: calc(50% - 8px);
  cursor: pointer;
  &.-active {
    background-color: $brand-purple-10;
    outline: 2px solid $brand-extended-purple-2;
    border-color: transparent !important;
  }
}
.category-item-body {
  padding: 12px 8px;
  p {
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  min-width: 0;
}
</style>
