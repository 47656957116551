<template>
  <BaseModal id="close-account" size="md" data-test="close-account">
    <h1 class="title">Closing your account?</h1>
    <div class="spinner-container" v-if="loading" data-test="loading">
      <b-spinner />
    </div>
    <div v-else-if="accountCanBeClosed" data-test="can-close-account">
      <p class="subtitle">
        Closing your account is permanent and can't be undone. Before you go,
        we'd love to know why you are leaving and how we can improve.
      </p>
      <BaseForm class="modal-form" @submit="onSubmit">
        <label class="field form-control" data-test="why-leaving">
          <div class="input-title">Why are you leaving?</div>
          <b-form-checkbox
            v-for="reason in reasons"
            :key="reason.label"
            class="pb-1"
            v-model="reason.value"
            >{{ reason.label }}</b-form-checkbox
          >
        </label>
        <BaseTextarea
          label="How can we improve Privacy.com?"
          placeholder="Let us know what you would change..."
          data-test="how-to-improve"
          v-model="feedback"
        />
        <BaseInput
          label="Confirm Password"
          placeholder="Password"
          type="password"
          maxlength="128"
          data-test="password"
          class="mt-3 mb-3"
          v-model="password"
        />
        <b-alert
          :show="passwordError"
          variant="danger"
          data-test="password-error"
        >
          Please verify that you entered the correct password and try again.
        </b-alert>
        <b-alert
          :show="closeAccountError"
          variant="danger"
          data-test="error-closing-account"
        >
          There was an error closing your account. Try again shortly or contact
          our support team for help.
        </b-alert>
        <BaseButton
          type="submit"
          variant="primary"
          data-test="confirm-close-account"
          class="mt-1 mb-2"
        >
          Permanently Close My Account
        </BaseButton>
        <BaseButton
          variant="secondary"
          @click="closeModal"
          data-test="cancel-close-account"
        >
          Cancel
        </BaseButton>
      </BaseForm>
    </div>
    <div v-else data-test="can-not-close-account">
      <p v-if="!!pendingItems" class="subtitle">
        Unfortunately, we are unable to close your account right now because you
        have pending items. You can try again after the following are resolved,
        or contact our support team for additional help.
      </p>
      <p v-else>
        Unfortunately, we are unable to close your account right now. Please
        contact support if the issue persists.
      </p>
      <div v-for="(list, name) in pendingItems" :key="name">
        <div v-if="list && list.length" class="pending-items">
          <p>
            {{ pendingItemsTitles[name] }}
          </p>
          <ul>
            <li
              v-for="item in list.slice(0, maxPendingItemsShown)"
              :key="item.transactionID"
              class="pending-item"
            >
              {{ item.descriptor.toLowerCase() }}
              <span class="amount">{{ parseCentAmount(item.amount) }}</span>
            </li>
          </ul>
          <span v-if="list.length > maxPendingItemsShown">
            + {{ list.length - maxPendingItemsShown }} more
          </span>
        </div>
      </div>
      <BaseButton
        variant="light"
        size="lg"
        class="got-it-button mt-2"
        @click="closeModal"
        data-test="cancel-ineligible-close-account"
      >
        Got it
      </BaseButton>
    </div>
  </BaseModal>
</template>
<script lang="ts">
import { transactionStore, userStore } from "@/store";
import { parseCentAmount } from "@/util";
import { Transaction } from "@/types/Transaction";
import { Component, Mixins } from "vue-property-decorator";
import { Confirm } from "@/mixins/Confirm";
import BaseModal from "../BaseModal.vue";
import BaseForm from "../BaseForm.vue";
import BaseTextarea from "../BaseTextarea.vue";
import BaseInput from "../BaseInput.vue";
import BaseButton from "../BaseButton.vue";

@Component({
  components: {
    BaseModal,
    BaseForm,
    BaseInput,
    BaseTextarea,
    BaseButton,
  },
})
export default class CloseAccount extends Mixins(Confirm) {
  loading = true;
  pendingItems: {
    bounces: Transaction[];
    pendingCharges: Transaction[];
    pendingDisputes: Transaction[];
  } | null = null;
  passwordError = false;
  closeAccountError = false;
  maxPendingItemsShown = 3;
  pendingItemsTitles = {
    bounces: "Bounces",
    pendingCharges: "Pending Charges",
    pendingDisputes: "Pending Disputes",
  };
  password = "";
  feedback = "";
  reasons = [
    { label: "The price is too high", value: false },
    { label: "The product does not meet my needs", value: false },
    { label: "The product does not function properly", value: false },
    { label: "The customer service is insufficient", value: false },
    { label: "I found a better alternative", value: false },
    { label: "Other", value: false },
  ];
  get selectedReasons() {
    return this.reasons.filter((reason) => !!reason.value);
  }
  get feedbackMessage() {
    let message = "";
    if (this.selectedReasons.length > 0) {
      message += `User selected reasons: ${this.selectedReasons
        .map((reason) => reason.label)
        .join(", ")}`;
      message += `\n\n`;
    }
    if (this.feedback) {
      message += `User feedback: ${this.feedback}`;
    }
    return message;
  }
  parseCentAmount = parseCentAmount;

  get accountCanBeClosed() {
    return (
      this.pendingItems?.bounces?.length === 0 &&
      this.pendingItems?.pendingCharges?.length === 0 &&
      this.pendingItems?.pendingDisputes?.length === 0
    );
  }

  async created() {
    try {
      const pendingItems =
        await transactionStore.actions.fetchPendingTransactions();
      this.pendingItems = pendingItems.data;
    } catch (err) {
      // don't allow them to close account if we can't fetch pending items
      this.pendingItems = null;
    }
    this.loading = false;
  }

  async onSubmit() {
    if (!this.password) {
      this.passwordError = true;
      return;
    }
    const resp = await this.confirm(
      "Any future attempted transactions on your Privacy cards will be declined and information like your funding source will be removed. This can't be undone.",
      {
        title: "Are you sure you wish to close your account?",
        okTitle: "Yes, Close my Account",
        okVariant: "danger",
      }
    );
    if (!resp) {
      this.$bvModal.hide("close-account");
      return;
    }

    // close the account
    try {
      await this.closeAccount();
      // redirect
      this.$router.push({ name: "logout", query: { accountClosed: "true" } });
    } catch (err) {
      // @ts-ignore
      const status = err.response?.status;
      this.passwordError = status === 400;
      this.closeAccountError = status !== 400;
    }
  }

  async closeAccount(): Promise<void> {
    return userStore.actions.closeAccount({
      password: this.password,
      message: this.feedbackMessage,
    });
  }

  closeModal() {
    this.$bvModal.hide("close-account");
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-size: 22px;
  font-family: $font-stack-wes-fy;
}

.spinner-container {
  text-align: center;
  padding: 50px;
}

.input-title {
  padding-bottom: 0.75rem;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 0.8;
  pointer-events: none;
  z-index: 1;
  @media #{$media-phone} {
    line-height: 1;
  }
}

.modal-form {
  width: auto;
  padding: 0;
}

.got-it-button {
  width: 100%;
}

.pending-items {
  margin-bottom: 1rem;
  p {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
}

.pending-item {
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}

.amount {
  float: right;
  font-weight: bold;
  text-transform: none;
}
</style>
