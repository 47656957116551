<template>
  <div class="account-integration" id="integration">
    <div class="section-heading">
      <h2>1Password x Privacy.com</h2>
    </div>
    <div class="section -integration-list">
      <div class="integration-container">
        <div class="integration">
          <div class="icon">
            <img
              src="/assets/images/account/1pw-logo.svg"
              width="40"
              height="40"
              alt=""
            />
          </div>
          <div class="details">
            <div class="name">Streamline your online security toolkit</div>
            <div class="description">
              Create, use, and save Privacy cards directly within your 1Password
              account!
              <a
                href="https://privacy.com/blog/create-virtual-cards-with-privacy-and-1password"
                target="_blank"
                >Learn more</a
              >.
            </div>
          </div>
        </div>
        <div class="action -loading" v-show="integrationState === 'LOADING'">
          <b-spinner small />
        </div>
        <div class="action" v-show="integrationState === 'LINKED'">
          <b-dropdown
            class="menu"
            size="sm"
            no-caret
            right
            variant="link"
            aria-label="integration menu"
          >
            <template #button-content>
              <SVGIcon icon="ellipsis" />
            </template>
            <b-dropdown-item
              href="#"
              @click="onClickRevokeAccess"
              class="js-delete"
              >Revoke Access</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <div class="action" v-show="integrationState === 'AVAILABLE'">
          <a @click="onClickAddIntegration">Connect</a>
          <BaseButton
            v-show="false"
            :id="BUTTON_ID"
            variant="primary"
            data-onepassword-save-button
            data-onepassword-provide-public-key
            disabled
          ></BaseButton>
          <input
            type="hidden"
            data-onepassword-save-request
            data-onepassword-type="login"
            v-model="value"
          />
        </div>
        <div class="action" v-show="integrationState === 'UNAVAILABLE'">
          <a @click="goToPartnerSite">Get 1Password</a>
        </div>
      </div>

      <div class="banner" v-show="integrationState === 'UNAVAILABLE'">
        To create Privacy Cards, get the latest version of
        <a href="http://1password.com/browsers" target="_blank"
          >1Password in your browser</a
        >.
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Confirm } from "@/mixins/Confirm";
import { Toast } from "@/mixins/Toast";
import { eventStore, subscriptionStore, userStore } from "@/store";
import { User } from "@/types/User";
import { EVENTS } from "@/types/Event";
import SVGIcon from "@/components/SVGIcon.vue";
import { AxiosResponse } from "axios";

const INTEGRATION_KEY = "onepassword";
const INTEGRATION_NAME = "1Password";
const PUBKEY_DATA_FIELD = "data-onepassword-public-key";

@Component({
  components: {
    SVGIcon,
  },
})
export default class AccountIntegrations extends Mixins(Confirm, Toast) {
  @Prop({ required: true }) user!: User;
  value = "";
  pubkey = "";
  hasActiveKey = false;
  isLoading = true;
  extensionAvailable = false;
  BUTTON_ID = "onepassword-save-button";

  created() {
    this.loadPlanData(this.user);
  }

  @Watch("user")
  loadPlanData(user: User) {
    this.hasActiveKey = (user.activeIntegrations || []).some(
      ({ key }) => key === INTEGRATION_KEY
    );
  }

  mounted() {
    // Ensures all child components have rendered
    this.$nextTick(() => {
      const bodyEl = document.body;
      const buttonEl = this.getButtonEl();

      const pubkeyObserver = new MutationObserver(() => {
        const newValue = bodyEl.getAttribute(PUBKEY_DATA_FIELD);
        if (newValue) {
          this.pubkey = atob(newValue);
          this.extensionAvailable = true;
          this.isLoading = buttonEl.disabled;
          pubkeyObserver.disconnect();
        }
      });
      pubkeyObserver.observe(bodyEl, { attributeFilter: [PUBKEY_DATA_FIELD] });

      const buttonObserver = new MutationObserver(() => {
        if (!buttonEl.disabled) {
          this.extensionAvailable = true;
          this.isLoading = !this.pubkey;
          buttonObserver.disconnect();
        }
      });
      buttonObserver.observe(buttonEl, { attributeFilter: ["disabled"] });

      setTimeout(() => {
        document.dispatchEvent(new Event("OPButtonAdded"));
      }, 0);

      setTimeout(() => {
        // If extension hasn't responded by now, stop loading anyways
        this.isLoading = false;
      }, 2000);
    });
  }

  get isBusiness() {
    return !!subscriptionStore.getters.currentPlan?.organization;
  }

  get integrationState() {
    if (this.isLoading) {
      return "LOADING";
    }

    if (this.hasActiveKey) {
      return "LINKED";
    }

    if (this.pubkey && !this.getButtonEl().disabled) {
      return "AVAILABLE";
    }

    return "UNAVAILABLE";
  }

  onClickAddIntegration() {
    this.isLoading = true;
    eventStore.actions.record({
      name: EVENTS.CTA.CLICKED,
      data: {
        buttonContext: "Account Integrations",
        buttonName: "Connect",
        integration: INTEGRATION_KEY,
      },
    });

    this.confirm(
      "This will grant 1Password access to your Privacy cards and transactions. You can revoke this access at any time.",
      {
        title: "Connect 1Password?",
        cancelTitle: "Cancel",
        okTitle: "Continue",
      }
    )
      .then((confirm) => {
        if (confirm) {
          return userStore.actions.getIntegrationApiKey({
            integration: INTEGRATION_KEY,
            fullName: INTEGRATION_NAME,
            pubkey: this.pubkey,
          });
        }
      })
      .then((response: AxiosResponse | undefined) => {
        // If `response` is empty, user did not confirm the integration, so ignore
        if (response) {
          const activeIntegrations = (
            userStore.getters.currentUser?.activeIntegrations || []
          ).concat({
            key: INTEGRATION_KEY,
            fullName: INTEGRATION_NAME,
          });

          userStore.mutations.updateCurrentUser({ activeIntegrations });
          this.successToast("Connection complete!");
          this.value = response.data.saveObject;

          // Wait until DOM is updated with the above value
          this.$nextTick(() => {
            this.isLoading = false;
            // Save the user a click
            this.getButtonEl().click();
            // TODO deal with failure from extension?
          });
        }
      })
      .catch((err: any) => {
        this.errorToast(
          err.response?.data?.message ||
            "There was an error adding your account"
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  onClickRevokeAccess() {
    eventStore.actions.record({
      name: EVENTS.CTA.CLICKED,
      data: {
        buttonContext: "Account Integrations",
        buttonName: "Revoke",
        integration: INTEGRATION_KEY,
      },
    });

    this.isLoading = true;

    userStore.actions
      .revokeIntegrationApiKey({ integration: INTEGRATION_KEY })
      .then(() => {
        const activeIntegrations = (
          userStore.getters.currentUser?.activeIntegrations || []
        ).filter(({ key }) => key !== INTEGRATION_KEY);
        userStore.mutations.updateCurrentUser({ activeIntegrations });

        this.hasActiveKey = false;
        this.successToast("Access removed");
      })
      .catch((err) => {
        this.errorToast(err.message || "There was an error revoking access");
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  getButtonEl() {
    return document.getElementById(this.BUTTON_ID) as HTMLButtonElement;
  }

  goToPartnerSite() {
    eventStore.actions.record({
      name: EVENTS.CTA.CLICKED,
      data: {
        buttonContext: "Account Integrations",
        buttonName: "Partner Site",
        integration: INTEGRATION_KEY,
      },
    });

    const typeLanding = this.isBusiness ? "business" : "home";
    window.open(`https://1password.com/promo/privacy/${typeLanding}`, "_blank");
  }
}
</script>

<style lang="scss" scoped>
// for specificity over parent component SCSS
.account-integration .section.-integration-list {
  padding: 26px 40px;
}

.integration-container {
  display: flex;
  align-items: center;
  height: 100px;

  & + & {
    border-top: 2px solid $white;
  }
}

.banner {
  margin: 0 -40px;
  padding: 25px 40px;
  background-color: change-color($blue, $alpha: 0.2);
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  color: $color-blue-darker;

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.integration {
  display: flex;
  align-items: center;
  margin-right: auto;
  min-width: 0;
  max-width: 100%;
  font-size: 14px;
  font-weight: normal;

  > .icon {
    display: flex;
    margin-right: 20px;
    height: 40px;
    width: 40px;
  }
}

.details {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  overflow: hidden;
  line-height: 22px;

  .name {
    overflow: hidden;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $gray-800;
  }

  .description {
    align-items: center;
    color: $gray-600;
    padding-right: 10px;
  }
}

.action {
  position: relative;

  &.-loading {
    margin-right: 20px;
  }

  & > a {
    font-weight: bold;
    color: $blue;
    cursor: pointer;
  }

  & > .menu {
    margin-left: 10px;
  }
}

@media #{$media-phone} {
  .integration-container {
    flex-wrap: wrap;
    padding: 20px;
    height: auto;
  }

  .integration-container .integration {
    flex-basis: calc(100% - 40px);
    margin-right: 0;
  }

  .action {
    margin-top: 10px;
    margin-left: 60px;
  }

  .action > .menu {
    flex-basis: 20px;
    margin: -10px;
  }
}
</style>
