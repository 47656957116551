<template>
  <div class="page">
    <div class="icon">
      <b-icon class="svg-icon" icon="check" />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { BIcon, BIconCheck } from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconCheck,
  },
})
export default class OnboardingMobilePlaid extends Vue {}
</script>
<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px;
  text-align: center;
  align-items: center;
  background-color: $gray-100;
}

.page > .icon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  background-color: $green;
  border-radius: 50px;
  margin: 0 0 20px;

  ::v-deep svg {
    fill: $white;
    transform: scale(1.5);
  }
}
</style>
