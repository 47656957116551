<template>
  <div class="helper -credit">
    <div class="title">Balance</div>
    <div class="extra-link">
      <router-link to="/summary" class="summary-link"
        >Limits &amp; Spend History</router-link
      >
    </div>
    <div class="stats">
      <div class="stat-row">
        <div class="stat-item">
          <DollarAmount
            class="stat-value -large"
            :amount="accountBalanceAvailable - accountBalancePromo"
          ></DollarAmount>
        </div>
      </div>
      <div class="stat-row">
        <div class="stat-item">
          <div class="stat-title">Promo Credits</div>
          <DollarAmount
            class="stat-value -promo-credits"
            :amount="accountBalancePromo"
          ></DollarAmount>
        </div>
      </div>
    </div>
    <div class="controls">
      <BaseButton variant="white" @click="onClickAddFunds"
        >Add Funds</BaseButton
      >
      <BaseButton
        variant="white"
        @click="onClickCashOut"
        :disabled="accountBalanceAvailable - accountBalancePromo < 1"
        >Cash Out</BaseButton
      >
    </div>
    <BaseModal id="add-funds-prepaid">
      <AddFunds />
    </BaseModal>
    <CashOut :user="user" />
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { userStore } from "@/store";
import DollarAmount from "@/components/DollarAmount.vue";
import AddFunds from "@/components/modals/AddFunds.vue";
import CashOut from "@/components/modals/CashOut.vue";

@Component({
  components: {
    DollarAmount,
    AddFunds,
    CashOut,
  },
})
export default class HelperAccountCredit extends Vue {
  get user() {
    return userStore.getters.currentUser;
  }

  get accountBalanceAvailable() {
    return this.user?.accountBalances?.available
      ? parseFloat(this.user?.accountBalances?.available)
      : 0;
  }

  get accountBalancePromo() {
    return this.user?.accountBalances?.promo
      ? parseFloat(this.user?.accountBalances?.promo)
      : 0;
  }

  onClickAddFunds() {
    this.$bvModal.show("add-funds-prepaid");
  }

  onClickCashOut() {
    this.$bvModal.show("cash-out");
  }
}
</script>
<style lang="scss" scoped>
.helper.-credit {
  position: relative;
  overflow: hidden;

  > .title {
    font-size: 18px;
    font-family: $font-stack-wes-fy;
  }

  > .extra-link {
    position: absolute;
    left: 50%;
    padding-left: 15px;
    font-size: 12px;
    line-height: 2.2;
  }

  .summary-link {
    color: $blue;

    &:hover {
      text-decoration: none;
      color: $gray-800;
    }
  }

  > .stats {
    display: flex;
    flex-grow: 1;
    margin: 30px -14px 0;
  }

  .stat-row {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .stat-title {
    margin-bottom: 6px;
    font-size: 12px;
    font-weight: bold;
    line-height: 10px;
    color: $gray-600;
  }

  .stat-item {
    flex: 1;
    margin: 0 4px;
    padding: 0 10px;
    border-radius: $radius-large;
    transition: background-color $duration-shorter;
    transition-duration: $ease-out-expo;
  }

  .stat-value {
    justify-content: flex-start;
    font-size: 16px;

    &.-promo-credits {
      font-weight: 700;
      color: $color-green;
    }

    &.-large {
      font-family: $font-stack-wes-fy;
      font-size: 32px;
    }
  }

  .controls {
    display: flex;
    margin: 0 -30px -30px;
    height: 70px;
    border-top: 1px solid mix($white, $gray-400, 80%);
  }

  .btn {
    flex-basis: 50%;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }

    &:hover:enabled {
      opacity: 75%;
    }
  }

  .btn:first-child {
    border-right: 1px solid mix($white, $gray-400, 80%);
  }
}
</style>
