<template>
  <SimplePage
    :fullpage="fullpage"
    class="accept-commercial-charge-terms"
    data-test="accept-commercial-charge-terms"
  >
    <b-spinner v-if="loading" />
    <BaseForm v-else :fullpage="fullpage">
      <b-img
        class="check-mark"
        src="/assets/images/home/commercial-charge-terms/check.svg"
        alt="green-check-mark"
      ></b-img>
      <h2 class="title" v-if="user && !user.chargeTermsAcceptTime">
        You're approved for updated Privacy cards
      </h2>
      <h2 class="title" v-else-if="user && user.chargeTermsAcceptTime">
        Your account has been updated
      </h2>
      <BaseButton
        class="got-it"
        size="lg"
        variant="primary"
        data-test="got-it"
        @click="onClickGotIt"
      >
        Got it
      </BaseButton>
    </BaseForm>
  </SimplePage>
</template>
<script lang="ts">
import { userStore } from "@/store";
import { User } from "@/types/User";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class AcceptCommercialChargeTerms extends Vue {
  @Prop({ default: true }) fullpage!: boolean;
  loading = false;

  get user(): User | null {
    return userStore.getters.currentUser;
  }

  mounted(): void {
    this.loading = true;
    userStore.actions.getCurrentUser().then(() => {
      this.loading = false;
    });
  }

  onClickGotIt(): void {
    this.$router.push({ name: "home" });
  }
}
</script>
<style lang="scss" scoped>
.accept-commercial-charge-terms {
  display: flex;
  color: var(--color-foreground);
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  position: relative;
  width: 100%;

  > .spinner-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .check-mark {
    width: 75px;
    margin: auto;
  }

  .title {
    font-family: $font-stack-wes-fy;
    margin: 1em 0;
    text-align: center;
  }

  .btn {
    margin-top: 1em;
    width: 100%;
  }

  .reissue-blurb {
    text-align: center;
  }
}
</style>
