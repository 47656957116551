import { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import { userStore } from "@/store";
import { User } from "@/types/User";
import StartKybVerification from "@/views/kyb-verification/StartKybVerification.vue";
import AccountPurpose from "@/views/kyb-verification/AccountPurpose.vue";
import ConsumerChargeDisclosureContainer from "@/views/kyb-verification/ConsumerChargeDisclosureContainer.vue";
import KybSuccess from "@/views/kyb-verification/KybSuccess.vue";
import BusinessType from "@/views/kyb-verification/BusinessType.vue";
import BusinessConfirmSoleProprietor from "@/views/kyb-verification/BusinessConfirmSoleProprietor.vue";
import BusinessDetails from "@/views/kyb-verification/BusinessDetails.vue";
import EINUpload from "@/views/kyb-verification/EINUpload.vue";
import ConfirmBusinessOnlyOwner from "@/views/kyb-verification/ConfirmBusinessOnlyOwner.vue";
import BusinessUsecase from "@/views/kyb-verification/BusinessUsecase.vue";
import KybInReview from "@/views/kyb-verification/kybInReview.vue";
import ConfirmBusinessOwners from "@/views/kyb-verification/ConfirmBusinessOwners.vue";
import BusinessOwners from "@/views/kyb-verification/BusinessOwners.vue";
import paths from "./paths.json";

async function getUser(): Promise<User> {
  const user = userStore.getters.currentUser;

  if (user) {
    return user;
  }

  await userStore.actions.getCurrentUser();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return userStore.getters.currentUser!;
}

const routes: Array<RouteConfig> = [
  {
    path: paths.startKybVerification,
    name: "startKybVerification",
    component: StartKybVerification,
    meta: {
      title: "Start KYB Verification",
      showHeader: true,
      authenticate: true,
    },
  },
  {
    path: paths.accountPurpose,
    name: "kybAccountPurpose",
    component: AccountPurpose,
    meta: {
      title: "Account Purpose",
      showHeader: true,
      authenticate: true,
    },
  },
  {
    path: paths.consumerChargeDisclosure,
    name: "kybConsumerChargeDisclosure",
    component: ConsumerChargeDisclosureContainer,
    meta: {
      title: "Consumer Charge Disclosure",
      showHeader: true,
      authenticate: true,
    },
  },
  {
    path: paths.kybSuccess,
    name: "kybSuccess",
    component: KybSuccess,
    meta: {
      title: "KYB Success",
      showHeader: true,
      authenticate: true,
      isSoleProprietor: false,
    },
  },
  {
    path: paths.businessType,
    name: "kybBusinessType",
    component: BusinessType,
    meta: {
      title: "Business Type",
      showHeader: true,
      authenticate: true,
    },
  },
  {
    path: paths.businessConfirmSoleProprietor,
    name: "kybBusinessConfirmSoleProprietor",
    component: BusinessConfirmSoleProprietor,
    meta: {
      title: "Business Confirm Sole Proprietor",
      showHeader: true,
      authenticate: true,
    },
  },
  {
    path: paths.businessDetails,
    name: "kybBusinessDetails",
    component: BusinessDetails,
    meta: {
      title: "Business Details",
      showHeader: true,
      authenticate: true,
    },
  },
  {
    path: paths.einUpload,
    name: "kybEINUpload",
    component: EINUpload,
    meta: {
      title: "EIN Upload",
      showHeader: true,
      authenticate: true,
    },
  },
  {
    path: paths.confirmBusinessOnlyOwner,
    name: "kybConfirmBusinessOnlyOwner",
    component: ConfirmBusinessOnlyOwner,
    meta: {
      title: "Confirm Business Owner",
      showHeader: true,
      authenticate: true,
    },
  },
  {
    path: paths.businessUsecase,
    name: "kybBusinessUsecase",
    component: BusinessUsecase,
    meta: {
      title: "Business Use Case",
      showHeader: true,
      authenticate: true,
    },
  },
  {
    path: paths.kybInReview,
    name: "kybInReview",
    component: KybInReview,
    meta: {
      title: "In Review",
      showHeader: true,
      authenticate: true,
    },
  },
  {
    path: paths.confirmBusinessOwners,
    name: "kybConfirmBusinessOwners",
    component: ConfirmBusinessOwners,
    meta: {
      title: "Confirm Business Owners",
      showHeader: true,
      authenticate: true,
    },
  },
  {
    path: paths.businessOwners,
    name: "kybBusinessOwners",
    component: BusinessOwners,
    meta: {
      title: "Business Owners",
      showHeader: true,
      authenticate: true,
    },
  },
];

routes.forEach((route) => {
  route.beforeEnter = async (
    _to: Route,
    _from: Route,
    next: NavigationGuardNext
  ) => {
    try {
      const user = await getUser();
      // only allow users who have not completed KYB or isPendingBusinessInfo
      if (user.kybVerificationUser || user.isPendingBusinessInfo) {
        return next();
      }
      next({ name: "home" });
    } catch {
      next({ name: "home" });
    }
  };
});

export default routes;
