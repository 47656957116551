<template>
  <b-modal
    hide-footer
    centered
    hide-header
    size="lg"
    content-class="shadow overflow-hidden"
    body-class="vaulted"
    @show="initialize"
    @hide="disconnect"
    data-test="vaulted-cards-modal"
    :id="id"
  >
    <div class="container">
      <div class="row vaulted-container">
        <section class="col-lg categories d-flex flex-column">
          <h3>
            Here are a few <br />
            ideas to get started
          </h3>
          <p class="description mb-4" data-test="vaulted-description">
            {{ categoryDescription }}
          </p>
          <nav class="flex-grow-1">
            <ul
              class="category-list d-flex flex-nowrap d-lg-block list pb-2 pb-lg-0"
            >
              <li
                v-for="category in categories"
                :id="`nav-${category.id}`"
                :key="`nav-${category.id}`"
                class="mr-4"
              >
                <a
                  :href="`#category-${category.id}`"
                  :class="{
                    active: currentCategory === category.id,
                  }"
                  @click.prevent="scrollIntoView"
                  :data-test="`vaulted-${category.id}`"
                >
                  {{ category.title }}
                </a>
              </li>
            </ul>
          </nav>
          <p class="mt-1 mb-0 mb-lg-2">
            Cards selected:
            <span
              class="count"
              :class="{ warning: maxSelections }"
              data-test="vaulted-selection-count"
            >
              {{ selected.length }}/{{ selectionLimit }}
            </span>
          </p>
        </section>
        <section class="col-lg d-flex flex-column selections">
          <p class="mr-5">
            Select the Privacy Cards you'd like to create. You can also skip and
            create your own custom cards later.
          </p>
          <div class="merchants" ref="merchants" data-test="vaulted-merchants">
            <ul class="list pr-4">
              <li v-for="category in categories" :key="`list-${category.id}`">
                <h4 :data-test="`vaulted-title-${category.id}`">
                  {{ category.title }}
                </h4>
                <ul
                  class="merchant-list list"
                  :ref="`category-${category.id}`"
                  :id="`category-${category.id}`"
                  :data-category="category.id"
                >
                  <li
                    v-for="merchant in category.merchants"
                    :key="`${category.id}_${merchantKey(merchant.name)}`"
                  >
                    <MerchantStyle
                      :logo="merchant.style.filename"
                      :background-color="merchant.style.bgColor"
                      :name="merchant.name"
                      :selected="isSelected(merchant.name)"
                      :disabled="isDisabled(merchant.name)"
                      @input="toggleSelected"
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="text-center pt-3 pr-5">
            <BaseButton
              block
              variant="primary"
              class="mb-1"
              @click="submitCards"
              :disabled="!selected.length"
              data-test="vaulted-card-submit"
            >
              Create my Privacy Cards
            </BaseButton>
            <div>
              <b-button
                class="skip"
                variant="link"
                @click="skip"
                data-test="vaulted-cards-skip"
              >
                Skip
              </b-button>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div
      class="container upgrade"
      data-test="vaulted-upgrade-banner"
      v-if="showUpgrade"
    >
      <div class="row">
        <div class="col-9">
          <p><strong>You've reached your monthly card create limit</strong></p>
          <p v-if="canUpdatePlan" class="sub">
            Upgrade your plan to get higher card create limits and other
            benefits!
          </p>
        </div>
        <div v-if="canUpdatePlan" class="col d-flex align-items-center">
          <b-button
            :to="{ name: 'subscription-plan' }"
            class="upgrade-btn"
            data-test="vaulted-upgrade-btn"
            @click="setUpgradeToken"
          >
            Upgrade Your Plan
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script lang="ts">
import { Component, Prop, Ref, Emit } from "vue-property-decorator";
import snakeCase from "lodash/snakeCase";
import MerchantStyle from "@/components/MerchantStyle.vue";
import { cardStore, eventStore, userStore } from "@/store";
import { BillingCategory, BillingMerchant } from "@/types/Billing";
import { EVENTS } from "@/types/Event";
import { Card, CardType } from "@/types/Card";
import { Toast } from "@/mixins/Toast";

@Component({
  components: {
    MerchantStyle,
  },
})
export default class VaultedCardsModal extends Toast {
  @Prop({ default: 12 }) selectionLimit!: number;
  @Prop({ default: "onboarding" }) phase?: "onboarding" | "active";
  @Prop({ default: false }) canUpdatePlan!: boolean;
  @Prop({ required: true }) id!: string;

  @Ref("merchants") merchantPane!: HTMLDivElement;

  categories: BillingCategory[] = [];
  currentCategory = "";
  observer!: IntersectionObserver | undefined;
  selected: string[] = [];
  sessionKeys = {
    selection: "vaultedCards",
    reopen: "reopenVaultedCards",
  };

  get categoryDescription(): string | undefined {
    return this.categories.find(
      (category) => category.id === this.currentCategory
    )?.description;
  }

  get maxSelections(): boolean {
    return this.selected.length >= this.selectionLimit;
  }

  get showUpgrade(): boolean {
    return this.phase !== "onboarding" && this.maxSelections;
  }

  get merchantMap(): Record<string, BillingMerchant["style"]> {
    return this.categories.reduce(
      (merchants, category) => {
        category.merchants.forEach((merchant) => {
          merchants[merchant.name!] = merchant.style;
        });

        return merchants;
      },
      {} as Record<string, BillingMerchant["style"]>
    );
  }

  initialize(): void {
    window.sessionStorage.removeItem("reopenVaultedCards");
    this.selected = [];

    eventStore.actions.record({
      name: EVENTS.MODAL.VAULTED_CARD_SHOW,
      data: {
        phase: this.phase,
      },
    });

    this.$piwik.trackEvent("Modal", "View", {
      name:
        this.phase === "onboarding"
          ? "Onboarding Create Cards"
          : "Create Vaulted Cards",
    });

    if (this.categories.length) {
      this.initializeSelections();
      this.initializeObservers();
    }

    cardStore.actions.getMerchantCategories().then((data) => {
      this.categories = data;
      this.initializeSelections();
      this.initializeObservers();
    });
  }

  initializeSelections(): void {
    const savedCards = window.sessionStorage.getItem(
      this.sessionKeys.selection
    );

    if (savedCards) {
      this.selected = JSON.parse(savedCards);
    }
  }

  initializeObservers(): void {
    if (this.observer) {
      return;
    }

    // setTimeout ensures the rendering has happened and there
    // are DOM elements for the Intersection observer
    setTimeout(() => {
      this.observer = new IntersectionObserver(this.setActive, {
        root: this.merchantPane,
        threshold: 0.35,
      });

      Object.entries(this.$refs).forEach(([key, el]): void => {
        if (key.startsWith("category")) {
          // @ts-ignore
          this.observer.observe((el as HTMLElement[])[0]);
        }
      });
    });
  }

  disconnect(): void {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = undefined;
    }

    this.currentCategory = "";
  }

  merchantKey(name = ""): string {
    return snakeCase(name);
  }

  isSelected(name = ""): boolean {
    return this.selected.includes(name);
  }

  isDisabled(name = ""): boolean {
    return !this.isSelected(name) && this.maxSelections;
  }

  toggleSelected(name: string): void {
    const index = this.selected.findIndex((value) => name === value);
    if (index > -1) {
      this.selected.splice(index, 1);

      eventStore.actions.record({
        name: EVENTS.CARD.DESELECT_VAULTED_CARD,
        data: {
          merchant: name,
          phase: this.phase,
        },
      });
    } else if (!this.maxSelections) {
      this.selected.push(name);

      eventStore.actions.record({
        name: EVENTS.CARD.SELECT_VAULTED_CARD,
        data: {
          merchant: name,
          phase: this.phase,
        },
      });
    }

    if (this.selected.length) {
      window.sessionStorage.setItem(
        this.sessionKeys.selection,
        JSON.stringify(this.selected)
      );
    } else {
      window.sessionStorage.removeItem(this.sessionKeys.selection);
    }
  }

  scrollIntoView(event: MouseEvent): void {
    const target = event.target as HTMLAnchorElement;
    const href: string = target.getAttribute("href")!.replace("#", "");
    const el = (this.$refs[href] as HTMLElement[])[0];
    const title = el.previousElementSibling! as HTMLElement;

    const elOffset = el.offsetTop;
    const titleBottom =
      title.clientHeight +
      parseInt(window.getComputedStyle(title).marginBottom);

    this.merchantPane.scrollTop = elOffset - titleBottom;
  }

  setActive(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.currentCategory = (entry.target as HTMLElement).dataset.category!;
        document
          .querySelector("#nav-" + this.currentCategory)!
          .scrollIntoView();
      }
    });
  }

  setUpgradeToken(): void {
    eventStore.actions.record({
      name: EVENTS.CTA.VAULTED_UPGRADE_CLICKED,
      data: {
        merchants: this.selected,
      },
    });

    window.sessionStorage.setItem("reopenVaultedCards", "1");
  }

  @Emit()
  skip(): string {
    this.$bvModal.hide(this.id);
    eventStore.actions.record({
      name: EVENTS.MODAL.VAULTED_CARD_SKIP,
      data: {
        phase: this.phase,
      },
    });

    const eventName =
      this.phase === "onboarding"
        ? "Onboarding Create Card Secondary CTA"
        : "Create Vaulted Cards Secondary CTA";

    this.$piwik.trackClick({
      name: eventName,
    });

    window.sessionStorage.removeItem(this.sessionKeys.selection);
    return this.id;
  }

  submitCards(): void {
    let action;

    if (this.phase === "onboarding") {
      action = userStore.actions.createSampleCards;
    } else {
      action = cardStore.actions.batchCreate;
    }

    const eventName =
      this.phase === "onboarding"
        ? "Onboarding Create Card Primary CTA"
        : "Create Vaulted Cards Primary CTA";

    this.$piwik.trackClick({
      name: eventName,
    });

    const cardData = this.formatCardData();
    action(cardData)
      .then(({ cards, error }) => {
        eventStore.actions.record({
          name: EVENTS.CARD.CREATED_VAULTED_CARDS,
          data: {
            merchants: this.selected,
            phase: this.phase,
          },
        });

        this.$bvModal.hide(this.id);
        this.$emit("create-success", cards);
        this.successToast(`Created ${cards.length} new cards`);

        if (error) {
          this.errorToast(error);
        }
      })
      .catch((error) => {
        const message =
          error?.response?.data?.message || "Could not create cards";
        this.errorToast(message);
      })
      .finally(() => {
        window.sessionStorage.removeItem(this.sessionKeys.selection);
      });
  }

  private formatCardData(): Partial<Card>[] {
    return this.selected!.map((name) => ({
      memo: name,
      hostname: this.merchantMap[name].hostname,
      type: CardType.MERCHANT_LOCKED,
      meta: {
        hostname: this.merchantMap[name].hostname,
        vaulted: true,
      },
    }));
  }
}
</script>

<style lang="scss" scoped>
::v-deep .vaulted {
  padding: 0;
}

h3 {
  font-family: $font-stack-wes-fy;
  font-size: 24px;
  color: $gray-800;
}

h4 {
  font-size: 16px;
  font-weight: bold;
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 3px 0 5px;
  z-index: 1;
}

.vaulted-container {
  section {
    padding-top: 32px;
    padding-bottom: 16px;
  }
}

.categories {
  padding-left: 32px;
  padding-right: 36px;
  background-color: #f5f8fb;
  color: $gray-600;
}

.selections {
  padding-right: 0;
  padding-left: 36px;
  height: 100%;
  overflow: hidden;
}

.list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.category-list {
  white-space: nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;

  a {
    color: #8a93a2;

    &.active {
      color: $gray-800;
    }
  }
}

.count {
  color: $gray-800;
  font-weight: bold;
}

.warning {
  color: darken($color-privacy-red, 10%);
}

.merchants {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 320px;
  scroll-behavior: smooth;
  position: relative;

  > .list > li {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.merchant-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  li {
    flex-basis: calc(50% - 3px);
    margin-bottom: 6px;
  }
}

.skip {
  color: $gray-600;
  text-decoration: underline;
}

.upgrade {
  padding: 24px 24px 20px 32px;
  background-color: $shadow-black-base;
  color: #fff;

  p {
    margin: 4px 0;
  }
}

.sub {
  color: #858b98;
}

.upgrade-btn {
  background: linear-gradient(
    128.76deg,
    rgba(11, 23, 48, 0.2) -96%,
    rgba(255, 255, 255, 0.2) 100%
  );
}
</style>
