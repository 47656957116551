<template>
  <header class="shared-dashboard-header">
    <div class="header-column branding">
      <PrivacyLogo
        href="/"
        aria-label="Go to Home"
        :light="true"
        class="header-logo"
      />
      <BaseButton
        variant="link"
        class="header-btn explainer"
        @click="showExplainer"
      >
        What is Privacy?
      </BaseButton>
    </div>
    <div class="header-column">
      <div class="email-container">
        <div class="email-label">Viewing cards shared with:</div>
        <div class="email-badge">{{ email }}</div>
      </div>
      <BaseButton variant="link" class="header-btn" @click="logout">
        Logout
      </BaseButton>
    </div>
  </header>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import PrivacyLogo from "@/components/PrivacyLogo.vue";
import { eventStore } from "@/store";
import { EVENTS } from "@/types/Event";

@Component({
  components: {
    PrivacyLogo,
  },
})
export default class SharedDashboardHeader extends Vue {
  @Prop({ required: true }) email!: string;

  async logout() {
    this.$emit("logout");
  }

  async showExplainer() {
    eventStore.actions.record({
      name: EVENTS.CTA.CLICKED,
      data: {
        buttonContext: "Shared Dashboard",
        buttonName: "What is Privacy",
      },
    });

    this.$emit("showExplainer");
  }
}
</script>
<style lang="scss" scoped>
.shared-dashboard-header {
  background: $neutrals-8;
  box-shadow: 0 0 10px fade-out($gray-800, 0.8);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 88px;
  width: 100%;
  padding: 24px 40px;
  -webkit-font-smoothing: antialiased;
}

.header-column,
.email-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-logo {
  margin-right: 1rem;
  width: 80px;

  ::v-deep svg {
    height: 20px;
  }
}

.header-btn {
  color: $white;
  font-weight: 600;
  padding: 10px 12px;
  flex-shrink: 0;

  ::v-deep .btn:focus {
    box-shadow: none;
  }
}

.email-label {
  color: $foreground-muted;
  flex-shrink: 0;
}

.email-badge {
  margin: 0 1rem;
  padding: 4px;
  border-radius: 4px;
  color: $accent-default;
  background-color: fade-out($accent-default, 0.9);
  font-family: $font-stack-graphik;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1.1px;
  text-transform: uppercase;
}

@media #{$media-phone} {
  .shared-dashboard-header {
    flex-direction: column;
    height: auto;
    padding: 24px;
  }

  .header-column {
    width: 100%;
    justify-content: space-between;
  }

  .branding {
    flex-direction: column;
    align-items: flex-start;
    .header-btn {
      padding-left: 0;
    }
  }

  .header-column + .header-column {
    margin-top: 24px;
  }

  .email-container {
    flex-direction: column;
    align-items: start;
  }

  .email-badge {
    margin: 0;
  }
}
</style>
