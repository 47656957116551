import Vue from "vue";
import Vuex from "vuex";

import { UserState } from "@/types/User";
import { getStoreBuilder } from "./vuex-typex";

Vue.use(Vuex);

export interface RootState {
  user: UserState;
}

export const storeBuilder = getStoreBuilder<RootState>();
