<template>
  <div class="account-snapshot">
    <div class="stat-rows">
      <div class="stat-row">
        <div
          v-b-tooltip.hover
          :title="
            user && user.shouldShowLimits
              ? '$' +
                dailySpendLimitUSD +
                ' spend limit over a period of ' +
                (user && user.isLimitWeekly ? '7 days' : '24 hours')
              : ''
          "
          class="stat -daily-spend"
        >
          <RadialMeter
            v-if="user && user.shouldShowLimits"
            :fillvalue="
              user && user.isLimitWeekly
                ? metricList.weekTotal || 0
                : metricList.dayTotal || 0
            "
            :fillrange="dailySpendLimitUSD"
          ></RadialMeter>
          <div class="content">
            <div class="title">Daily Spend</div>
            <DollarAmount
              :amount="
                user && user.isLimitWeekly
                  ? metricList.weekTotal || 0
                  : metricList.dayTotal || 0
              "
              data-test="account-snapshot-daily-spend"
            ></DollarAmount>
          </div>
        </div>
        <div
          v-b-tooltip.hover
          :title="
            user && user.shouldShowLimits
              ? '$' +
                monthlySpendLimitUSD +
                ' spend limit over a period of 30 days'
              : ''
          "
          class="stat -monthly-spend"
        >
          <RadialMeter
            v-if="user && user.shouldShowLimits"
            :fillvalue="metricList.monthTotal || 0"
            :fillrange="monthlySpendLimitUSD"
          ></RadialMeter>
          <div class="content">
            <div class="title">Monthly Spend</div>
            <DollarAmount
              :amount="metricList.monthTotal || 0"
              data-test="account-snapshot-monthly-spend"
            ></DollarAmount>
          </div>
        </div>
        <div
          v-b-tooltip.hover
          :title="
            user && user.shouldShowLimits
              ? 'Create up to ' +
                planLimits.cardsPerMonth +
                ' new cards each calendar month'
              : ''
          "
          class="stat -card-limits"
          v-if="planLimits"
        >
          <RadialMeter
            v-if="user && user.shouldShowLimits"
            :fillvalue="planLimits.cardsCreatedLast30 * 100"
            :fillrange="planLimits.cardsPerMonth"
          ></RadialMeter>
          <div class="content">
            <div class="title">Monthly Cards</div>
            {{ planLimits.cardsCreatedLast30 }} / {{ planLimits.cardsPerMonth }}
          </div>
        </div>
        <div
          v-if="
            user &&
            user.accountType === 'PREPAID' &&
            user.accountBalances &&
            parseFloat(user.accountBalances.available) > 0
          "
          v-b-tooltip.hover
          title="Available Balance"
          class="stat -ledger"
        >
          <div class="content">
            <div class="title">Available Balance</div>
            <DollarAmount
              :amount="
                user && user.accountBalances && user.accountBalances.available
              "
            ></DollarAmount>
          </div>
        </div>
      </div>
      <div class="stat-row">
        <div
          v-if="
            user &&
            user.cashbackProgram &&
            (user.cashbackProgram.enabled ||
              Number(metricList.totalPendingCashback))
          "
          v-b-tooltip.hover
          title="Cashback earned this month is converted to Privacy Credit around the 10th of the next month"
          class="stat -cashback"
        >
          <div class="content">
            <div class="title">Cashback</div>
            <DollarAmount
              :amount="metricList.totalPendingCashback || 0"
              data-test="account-snapshot-cashback"
            ></DollarAmount>
          </div>
        </div>
        <div
          v-b-tooltip.hover
          title="Privacy Credit applies automatically to purchases made using Privacy cards"
          class="stat -credit"
        >
          <div class="content">
            <div class="title">Privacy Credit</div>
            <DollarAmount
              :amount="
                (user && user.promoCredits && user.promoCredits.amount) || 0
              "
              data-test="account-snapshot-credit"
            ></DollarAmount>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { metricsStore, subscriptionStore, userStore } from "@/store";
import { MetricList } from "../types/Metrics";
import DollarAmount from "../components/DollarAmount.vue";
import RadialMeter from "../components/RadialMeter.vue";

@Component({
  components: {
    DollarAmount,
    RadialMeter,
  },
})
export default class AccountSnapshot extends Vue {
  metricList: MetricList = {
    dayTotal: 0,
    weekTotal: 0,
    monthTotal: 0,
    pendingTotal: 0,
    totalPendingCashback: 0,
    totalCashback: 0,
  };

  get user() {
    return userStore.getters.currentUser;
  }

  get planLimits() {
    return subscriptionStore.getters.limits;
  }

  get dailySpendLimitUSD() {
    return this.user?.dailySpendLimit ? parseInt(this.user.dailySpendLimit) : 0;
  }

  get monthlySpendLimitUSD() {
    return this.user?.monthlySpendLimit
      ? parseInt(this.user.monthlySpendLimit)
      : 0;
  }

  async mounted() {
    metricsStore.actions.getMetrics().then((result) => {
      this.metricList = result;
    });
  }
}
</script>
<style lang="scss" scoped>
.account-snapshot {
  margin: 0 15px 20px;
  padding-bottom: 15px;
  border-bottom: 2px solid $gray-100;

  @media #{$media-phone} {
    margin: 0 20px;
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.account-snapshot > .stat-rows {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  > .stat-row {
    display: flex;
    justify-content: flex-start;

    @media #{$media-width-1110} {
      flex-direction: column;
    }
  }

  > .stat-row > .stat {
    display: flex;
    justify-content: flex-start;
    margin-right: 20px;
    padding: 10px;
    height: 60px;
    border-radius: $radius-large;
    transition: background-color $duration-shorter;
    transition-duration: $ease-out-expo;

    &:hover {
      background-color: $gray-100;
      cursor: help;
    }

    @media #{$media-width-1110} {
      margin-bottom: 15px;
    }

    &.-daily-spend,
    &.-monthly-spend {
      min-width: 155px;
    }

    &.-cashback {
      min-width: 100px;

      > .content > .title {
        color: $pastel-yellow;
      }
    }

    &.-credit {
      min-width: 120px;

      > .content > .title {
        color: $green;
      }
    }
  }

  > .stat-row > .stat > .radial-meter {
    margin: 0 10px 0 0;
    height: 40px;
    width: 40px;
  }

  ::v-deep .stat-row > .stat:hover > .radial-meter .track {
    stroke: $white;
  }
}

.account-snapshot .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: bold;

  > .title {
    font-size: 14px;
    line-height: 10px;
    color: $gray-600;
  }
}

.account-snapshot > .summary {
  display: inline-flex;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  vertical-align: top;
  padding: 10px;
  min-width: 165px;
  transition: background-color $duration-shorter;
  transition-duration: $ease-out-expo;
  border-radius: $radius-large;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    background-color: $gray-100;
  }

  > .svg-icon {
    margin-right: 10px;
  }

  > .content > .title {
    color: $blue;

    > .chevron {
      margin-left: -5px;
      transform: scale(0.8);

      & * {
        fill: $blue;
      }
    }
  }

  > .content > span {
    font-weight: normal;
    color: $gray-600;
  }

  @media #{$media-width-820} {
    display: none;
  }
}
</style>
