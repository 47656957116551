<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      @submit="submit"
      :fullpage="fullpage"
      :class="{ '-loading': waiting, '-preloading': preloading }"
    >
      <h1>Connect Your Debit Card</h1>
      <div class="text-center" v-if="preloading">
        <b-spinner></b-spinner>
      </div>
      <div class="blurb" v-if="user">
        <span v-if="user.isPrepaid"
          >This card will top-up your Privacy balance, which you can then spend
          securely using virtual cards.
        </span>
        <span v-if="!user.isPrepaid"
          >Purchases made on Privacy virtual cards will be funded directly from
          this card.
        </span>
      </div>
      <div class="tip" v-show="tip.length > 0">{{ tip }}</div>
      <b-alert
        v-if="errorMessage.length > 0"
        variant="danger"
        show
        data-test="add-card-error"
      >
        {{ errorMessage }}
      </b-alert>
      <BaseCardInput
        label="Debit Card Details"
        v-model="cardValue"
        @paste="autoComplete"
        @input="validatePan"
      ></BaseCardInput>
      <div class="blurb">Enter the billing address for your debit card.</div>
      <BaseInput
        name="address1"
        label="Street Address"
        placeholder="Your US Street Address"
        v-model="address1"
        type="text"
        max-length="128"
        :state="addressError ? false : null"
        data-test="address1"
      ></BaseInput>
      <BaseInputGroup>
        <BaseInput
          name="address2"
          label="Apt/Unit (Optional)"
          placeholder="Apartment"
          v-model="address2"
          type="text"
          max-length="128"
          data-test="address2"
        ></BaseInput>
        <BaseInput
          name="zip"
          label="ZIP Code"
          placeholder="00000"
          v-model="zip"
          type="text"
          max-length="5"
          format="numeric"
          :state="zipError ? false : null"
          data-test="zip"
        ></BaseInput>
      </BaseInputGroup>
      <div class="warning-notice" v-if="showBankReplacementWarning">
        <div class="title">This replaces your connected banks!</div>
        <p>
          Connecting a debit card will replace your connected bank accounts.
        </p>
      </div>
      <BaseButton
        type="submit"
        variant="primary"
        size="lg"
        :loading="waiting"
        data-test="button-connect-card"
        @click="trackClick"
      >
        Connect Your Funding Card
      </BaseButton>
    </BaseForm>
  </SimplePage>
</template>
<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import ldGet from "lodash/get";
import { CardValue } from "@/types/Form";
import { FundingCard } from "@/types/Funding";
import { User } from "@/types/User";
import { Introspection } from "@/mixins/Introspection";
import { eventStore, fundingCardStore, userStore } from "@/store";
import { BAlert, BModal, BSpinner } from "bootstrap-vue";
import { EVENTS } from "@/types/Event";
import { OnboardingSteps, onboardingStepName } from "@/types/Onboarding";

const nonVisaMasterError =
  "We currently only support Visa and Mastercard debit cards";
const rerouteTip =
  "If your bank connection was unsuccessful, try signing up with a debit card.";

@Component({
  components: {
    BAlert,
    BModal,
    BSpinner,
  },
})
export default class AddCard extends Mixins(Introspection) {
  @Prop({ default: false }) fullpage!: boolean;
  @Prop({ default: false }) isRerouted!: boolean;

  user: User | null = null;
  waiting = false;
  preloading = false;
  errorMessage = "";
  tip = "";
  cardValue: CardValue = {
    pan: "",
    cvv: "",
    exp: "",
  };
  panError = false;
  expError = false;
  cvvError = false;
  address1 = "";
  address2 = "";
  addressError = false;
  zip = "";
  zipError = false;
  showBankReplacementWarning = false;
  pastedFields: string[] = [];

  validatePan({ pan }: CardValue) {
    if (pan.length > 0 && !/^[4|5]/.test(pan)) {
      this.panError = true;
      this.errorMessage = nonVisaMasterError;
    } else {
      this.panError = false;
      this.errorMessage = "";
    }
  }

  created() {
    this.runAllIntrospectionChecks();

    userStore.actions
      .getCurrentUser()
      .then(() => {
        this.user = userStore.getters.currentUser;
        if (
          ldGet<any, string, any[]>(this, "user.bankAccountList", []).length > 0
        ) {
          this.showBankReplacementWarning = true;
        }
      })
      .catch(() => {
        this.showBankReplacementWarning = false;
      });

    if (this.isRerouted) {
      this.tip = rerouteTip;
    }
  }

  // TODO: type this
  autoComplete(event: any) {
    if (event.target?.name) {
      this.pastedFields.push(event.target.name);
    }
  }

  trackClick(): void {
    const step = this.isRerouted
      ? OnboardingSteps.ADD_CARD_REROUTED
      : OnboardingSteps.ADD_CARD;

    this.$piwik.trackClick({
      name: `${onboardingStepName(step)} Primary CTA`,
    });
  }

  submit() {
    if (this.waiting) {
      this.preloading = false;
      return;
    }

    this.errorMessage = "";
    const {
      cardValue: { pan: panRaw, exp: expRaw, cvv },
      address1,
      address2,
      zip,
    } = this;

    // Strip non-digits from card info
    const pan = panRaw.replace(/[^\d]/g, "");
    const exp = expRaw.replace(/[^\d]/g, "");

    if (!pan || pan.length < 16 || !/^[4|5]\d{15}/.test(pan)) {
      this.errorMessage =
        pan.length < 15
          ? "Please enter a valid card number"
          : nonVisaMasterError;
      this.panError = true;
      this.preloading = false;
      return;
    }
    this.panError = false;

    if (!exp || exp.length < 4) {
      this.errorMessage = "Please enter your card's expiration date";
      this.expError = true;
      this.preloading = false;
      return;
    }
    this.expError = false;

    if (!cvv || exp.length < 3) {
      this.errorMessage = "Please enter your card's CVV";
      this.cvvError = true;
      this.preloading = false;
      return;
    }
    this.cvvError = false;

    if (!address1) {
      this.errorMessage = "Please enter your billing address";
      this.addressError = true;
      this.preloading = false;
      return;
    }
    this.addressError = false;

    if (!zip || zip.length !== 5) {
      this.errorMessage = "Please enter a valid zip code";
      this.zipError = true;
      this.preloading = false;
      return;
    }
    this.zipError = false;

    const expMonth = exp.substring(0, 2);
    const expYear = `20${exp.substring(2, 4)}`;

    const cardData: FundingCard = {
      pan,
      cvv,
      expMonth,
      expYear,
    };

    if (this.pastedFields.length > 0 && window.btoa !== undefined) {
      cardData.ancillaryData = btoa(JSON.stringify(this.pastedFields));
    }

    if (address1 && zip) {
      cardData.avs = {
        address1,
        address2,
        zip,
      };
    }

    this.waiting = true;

    fundingCardStore.actions
      .add(cardData)
      .then(({ data, status }: any) => {
        if (status === 206 && !address1 && !zip) {
          this.preloading = false;
          eventStore.actions.record({
            name: EVENTS.FUNDING_SOURCE.ERRORED,
            data: {
              fundingType: "Card",
              errorMessage: data.message,
            },
          });
          return;
        }
        const cardListData = {
          ...cardData,
          state: "PENDING",
          uuid: data.uuid,
          lastFour: pan.substr(-4),
        };

        if (
          ldGet<any, string, any[]>(this, "user.fundingCardList", []).length ===
          0
        ) {
          this.user!.fundingCardList = [cardListData];
        } else {
          this.user!.fundingCardList.push(cardListData);
        }
        userStore.mutations.updateCurrentUser({
          fundingCardList: this.user!.fundingCardList,
        });

        if (!this.user!.isPrepaid) {
          userStore.mutations.updateCurrentUser({ accountType: "JIT" });
        }

        this.$emit("complete-step");
      })
      .catch(({ response }: any) => {
        this.errorMessage =
          response?.data?.message ||
          "Error submitting. Please try again later.";
        eventStore.actions.record({
          name: EVENTS.FUNDING_SOURCE.ERRORED,
          data: {
            fundingType: "Card",
            errorMessage: this.errorMessage,
          },
        });
      })
      .finally(() => {
        this.preloading = false;
        this.waiting = false;
      });
  }
}
</script>

<style lang="scss" scoped>
::v-deep .form {
  width: 21.75rem;
}

.-preloading > h1,
.-preloading > .blurb,
.-preloading > .btn {
  visibility: hidden;
}
.-preloading .field {
  display: none;
}

.-loading .field {
  opacity: 0.5;
}

h1 {
  all: unset;
  margin-bottom: 15px;
  font-family: $font-stack-wes-fy;
  font-size: 24px;
  text-align: center;
  color: $gray-800;
  line-height: 1.4;
}

.tip {
  margin-bottom: 5px;
  padding: 15px 20px;
  background-color: mix($white, $color-pastel-yellow, 80%);
  border-radius: $border-radius;
  font-weight: bold;
  text-align: center;
  color: darken($color-pastel-yellow, 20%);
}

.field,
.group {
  margin-bottom: 10px;
}
</style>
