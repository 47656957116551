<template>
  <div v-if="userIsEligible">
    <div class="section-heading">
      <h2>Referral Rewards</h2>
    </div>
    <div class="rewards section">
      <p class="description">
        Enter a referral or promotional code below to redeem your reward.
      </p>
      <BaseForm @submit="onSubmitPromoCode" class="form">
        <b-form-input
          class="input"
          placeholder="Enter Code"
          v-model="promoCode"
          data-test="rewards-promo-input"
          :disabled="loading"
        />
        <BaseButton
          variant="primary"
          :disabled="loading"
          :loading="loading"
          type="submit"
          data-test="rewards-promo-submit-button"
        >
          Redeem Reward
        </BaseButton>
      </BaseForm>
    </div>
  </div>
</template>
<script lang="ts">
import { Prop, Mixins, Component } from "vue-property-decorator";
import { userStore } from "@/store";
import { User } from "@/types/User";
import { Toast } from "@/mixins/Toast";
import BaseButton from "@/components/BaseButton.vue";
import BaseForm from "@/components/BaseForm.vue";

@Component({
  components: {
    BaseButton,
    BaseForm,
  },
})
export default class Rewards extends Mixins(Toast) {
  @Prop({ required: true }) user!: User;
  loading = false;
  promoCode = "";

  TEN_DAYS = 10 * 24 * 3600 * 1000;

  // only show if the user has not already claimed a referral, or is less than 10 days old
  get userIsEligible() {
    const createdDate = new Date(this.user.created).getTime();
    const tenDaysAgo = Date.now() - this.TEN_DAYS;
    return !this.user.referredBy?._user && createdDate > tenDaysAgo;
  }

  onSubmitPromoCode() {
    if (!this.promoCode) {
      return;
    }

    this.loading = true;
    userStore.actions
      .applyPromo({ promoCode: this.promoCode })
      // todo-ts: type the response
      .then((response: any) => {
        this.successToast(response.data?.message || "Code redeemed!");
        const state = response.data?.redirectState;

        if (state) {
          this.$router.push({
            name: state,
          });
        }
      })
      .catch((err) => {
        this.errorToast(
          err?.response?.data?.message ||
            "Sorry! That code couldn't be redeemed"
        );
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
</script>
<style lang="scss" scoped>
.rewards > .description {
  margin: 0;
}

.rewards > .form {
  padding: 0;
  max-width: 100%;
}

.rewards > .form > .input {
  margin: 25px 0;
  height: 60px;
  width: 100%;
  font-size: 16px;
  line-height: 30px;
  padding: 10px 25px;
  background: $white;
  border-radius: 30px;
}
</style>
