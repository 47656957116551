import { BvMsgBoxOptions } from "bootstrap-vue";
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export class Alert extends Vue {
  alert(message: string, bvConfig?: BvMsgBoxOptions | null) {
    return this.$bvModal.msgBoxOk(message, {
      contentClass: "shadow alert-content",
      centered: true,
      headerClass: "justify-content-center p-0",
      bodyClass: "text-center alert-body",
      footerClass: "justify-content-center pt-0",
      ...(bvConfig || {}),
    });
  }
}
