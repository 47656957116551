<template>
  <section>
    <div v-if="errorMessage" class="notice form-error mb-3">
      {{ errorMessage }}
    </div>
    <div class="notice mb-3" v-else>
      A one-time code has been sent to {{ sharing.email }}. Enter it below to
      view your cards.
    </div>
    <form novalidate @submit.prevent="submit">
      <BaseInput
        class="mb-3"
        name="code"
        label="One-time Code"
        placeholder="000000"
        minlength="6"
        variant="dark"
        autofocus
        required
        @blur="checkValidity"
      />
      <div v-if="fieldError" class="error mb-3">{{ fieldError }}</div>
      <b-form-checkbox
        class="consent mb-3"
        :class="{ 'consent-hidden': sharing.previouslyAcceptedTerms }"
        :state="acceptedTermsState"
        :checked="sharing.previouslyAcceptedTerms"
        name="acceptedTerms"
        required
      >
        I agree to the
        <b-link href="https://privacy.com/terms" target="_blank">terms</b-link>,
        and
        <b-link
          href="https://privacy.com/auth-user-card-terms"
          target="_blank"
          aria-label="ACH authorizations"
          >authorized card user agreement</b-link
        >.
      </b-form-checkbox>
      <BaseButton
        :loading="submitting"
        class="w-100 submit"
        variant="primary"
        aria-label="Submit"
        type="submit"
        >View Cards
      </BaseButton>
    </form>
    <div class="divider" />
    <BaseButton class="w-100" variant="secondary" @click="resendCode"
      >Resend Code
    </BaseButton>
  </section>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { Toast } from "@/mixins/Toast";
import { sharingStore } from "@/store";

@Component
export default class SharedLoginOneTimeCode extends Mixins(Toast) {
  errorMessage = "";
  submitting = false;
  fieldError = "";
  preloading = true;

  acceptedTermsState: boolean | null = null;

  get sharing() {
    return sharingStore.getters.state;
  }

  async created() {
    const linkTokenParam = this.$route.params.linkToken;

    if (linkTokenParam === this.sharing.linkToken) {
      this.preloading = false;

      return;
    }

    try {
      await sharingStore.actions.sendCodeFromLink(linkTokenParam);
    } catch (err) {
      this.errorMessage =
        (err as any).response?.data?.message || "There was an error";
    } finally {
      this.preloading = false;
    }
  }

  checkValidity({ target }: { target: HTMLInputElement }) {
    target.setCustomValidity("");

    if (!target.checkValidity()) {
      this.fieldError = "Please enter your one-time code";
      target.setCustomValidity(this.fieldError);
      target.setAttribute("aria-invalid", "true");
    } else {
      this.fieldError = "";
      target.setAttribute("aria-invalid", "false");
    }
  }

  async submit(event: Event) {
    const target = event.target as HTMLFormElement;

    this.submitting = true;
    this.checkValidity({ target: target.code });

    const targetAcceptedTerms = target.acceptedTerms as HTMLInputElement;

    if (!targetAcceptedTerms.checked) {
      this.acceptedTermsState = false;
    } else {
      this.acceptedTermsState = null;
    }

    if (this.fieldError || this.acceptedTermsState === false) {
      this.submitting = false;

      return;
    }

    try {
      await sharingStore.actions.auth({
        linkToken: this.sharing.linkToken,
        code: target.code.value,
        acceptedTerms: true,
      });

      this.$router.push({
        name: "shared-dashboard",
      });
    } catch (err) {
      this.errorMessage =
        (err as any).response?.data?.message || "There was an error";
      this.submitting = false;
    } finally {
      this.submitting = false;
    }
  }

  async resendCode() {
    try {
      await sharingStore.actions.resendCode(this.sharing.linkToken);

      this.successToast("A new one-time code has been sent to your email");
    } catch (err) {
      this.errorMessage =
        (err as any).response?.data?.message ||
        "There was an error resending the code";
    }
  }
}
</script>

<style lang="scss" scoped>
.notice {
  padding: 12px 16px;
  border-radius: $border-radius;
  font-family: $font-stack-graphik;
  background-color: $success-30;
  color: $success-default;
}

.form-error {
  background-color: $danger-10;
  color: $danger-default;
}

.error {
  margin-top: -10px;
  color: $danger-default;
  font-size: 12px;
  font-family: $font-stack-graphik;
  line-height: 130%;
}

.btn-primary {
  background-color: $button-primary-dark;

  &:hover {
    background-color: $button-primary-dark-hover;
  }
}

.btn-secondary {
  background-color: $button-secondary-dark;

  &:hover {
    background-color: $button-secondary-dark-hover;
  }
}

.consent.consent-hidden {
  position: absolute;
  left: -99999px;
  top: -99999px;
}

.consent ::v-deep {
  .custom-control-label {
    &::before,
    &::after {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  a {
    color: $foreground-highlight;
  }
}

.submit {
  margin-bottom: 40px;
}

.divider {
  border-top: 1px solid $divider;
  margin-bottom: 40px;
}
</style>
