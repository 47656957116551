<template>
  <main class="main">
    <header class="header">
      <PrivacyLogo class="logo" :light="true" href="/" />
      <h1 class="title mb-2">My Shared Cards</h1>
    </header>
    <section class="section">
      <router-view />
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import PrivacyLogo from "@/components/PrivacyLogo.vue";

@Component({
  components: {
    PrivacyLogo,
  },
})
export default class SharedLoginPage extends Vue {}
</script>

<style lang="scss" scoped>
.main {
  background-color: $color-background !important;
  color: #fff !important;
  height: 100%;
}

.header {
  text-align: center;
}

.section {
  margin: 0 auto;
  max-width: 360px;
}

.info {
  margin-bottom: 40px;
}

.logo {
  margin: 40px 0 88px;
}

.title {
  font-family: $font-stack-fk-grotesk;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.64px;
}
</style>
