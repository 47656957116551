import Piwik, {
  ContentTracking,
  CustomEvent,
  UserManagement,
  SiteSearch,
} from "@piwikpro/tracking-base-library";
import { PiwikProPlugin, VuePiwikPro, PiwikProConfig } from "./types";

const PiwikPro: PiwikProPlugin = {
  install(Vue, config) {
    Piwik.initialize(config.containerId, config.containerUrl);

    const instance: VuePiwikPro = new Vue();

    if (config.debug) {
      Vue.$cookies?.set("stg_debug", 1);
    } else if (window.location.hostname === "localhost") {
      Vue.$cookies?.remove("stg_debug");
    }

    instance.trackClick = ({ name, value, dimensions } = {}) => {
      CustomEvent.trackEvent("Button", "Tap", name, value, dimensions);
    };

    instance.trackContent = () => {
      // Need to wait for event loop in the case of modals
      setTimeout(() => {
        ContentTracking.trackAllContentImpressions();
      });
    };

    instance.trackContentImpression = (
      name: string,
      piece: string,
      target: string = ""
    ) => {
      ContentTracking.trackContentImpression(name, piece, target);
    };

    instance.trackEvent = (
      category,
      action,
      { name, value, dimensions } = {}
    ) => {
      CustomEvent.trackEvent(category, action, name, value, dimensions);
    };

    instance.trackSiteSearch = (
      keyword,
      { category, searchCount, dimensions } = {}
    ) => {
      SiteSearch.trackSiteSearch(keyword, category, searchCount, dimensions);
    };

    instance.resetUserId = () => {
      UserManagement.resetUserId();
    };

    instance.setUserId = (userId) => {
      UserManagement.setUserId(userId);
    };

    Vue.prototype.$piwik = instance;
  },
};

export default PiwikPro;
export type { PiwikProConfig };
