<template>
  <KybVerificationContainer>
    <BaseForm class="business-confirm-sole-proprietor">
      <h3 class="heading mb-3">Are you a sole proprietor?</h3>
      <p class="info-light text-center">
        A sole proprietor is a business owned and run by one person, without an
        incorporated entity like an LLC or corporation.
      </p>
      <BaseButton
        variant="light"
        block
        @click="isSoleProprietor = true"
        :pressed="isSoleProprietor == true"
        >Yes</BaseButton
      >
      <BaseButton
        variant="light"
        block
        @click="isSoleProprietor = false"
        :pressed="isSoleProprietor == false"
        >No</BaseButton
      >
      <div class="divider mt-4 mb-4"></div>
      <p class="text-center">
        By continuing, you agree that at least 80% of your purchases will be
        used for business spending.
      </p>
      <BaseButton
        variant="primary"
        @click="nextStep"
        class="cta-btn"
        :disabled="isSoleProprietor === null"
        >Continue</BaseButton
      >
    </BaseForm>
  </KybVerificationContainer>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { Toast } from "@/mixins/Toast";
import { organizationStore, userStore } from "@/store";
import { BusinessType as BusinessTypeValues } from "@/types/Organization";
import KybVerificationContainer from "./KybVerificationContainer.vue";

@Component({
  components: {
    KybVerificationContainer,
  },
})
export default class BusinessConfirmSoleProprietor extends Mixins(Toast) {
  types = BusinessTypeValues;
  isSoleProprietor: boolean | null = null;

  get kybUser() {
    return userStore.getters.currentUser!.kybVerificationUser;
  }

  get piwikPrefix() {
    return this.kybUser ? "Verification" : "Onboarding";
  }

  async created() {
    await userStore.actions.getCurrentUser();
    this.$piwik.trackEvent("Modal", "View", {
      name: `${this.piwikPrefix} Sole Prop Confirmation`,
    });

    const currentStep = "kybBusinessConfirmSoleProprietor";
    if (this.kybUser) {
      userStore.actions.updateVerificationStep(currentStep);
    } else {
      userStore.actions.updateUser({
        kybCurrentStep: currentStep,
      });
    }
  }

  nextStep(): void {
    this.$piwik.trackClick({
      name: `${this.piwikPrefix} Sole Prop Confirmation CTA`,
    });
    organizationStore.actions
      .businessType({
        businessType: this.isSoleProprietor
          ? this.types.INDIVIDUAL
          : this.types.OTHER,
      })
      .then(() => {
        if (this.isSoleProprietor) {
          this.$router.push({
            name: "kybSuccess",
            params: { isSoleProprietor: "true" },
          });
        } else if (this.kybUser) {
          this.$router.push({ name: "kybAccountPurpose" });
        } else {
          this.$router.push({ name: "kybBusinessType" });
        }
      })
      .catch((err: any) => {
        this.errorToast(err.response?.data?.message || "An error occurred");
      });
  }
}
</script>

<style lang="scss">
.info-light {
  color: #828299;
}
</style>
