<template>
  <b-modal
    v-bind="$attrs"
    hide-footer
    centered
    :content-class="contentClass"
    @show="handleShow"
    @hide="handleHide"
    v-on="$listeners"
  >
    <slot></slot>
  </b-modal>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
})
export default class BaseModal extends Vue {
  @Prop({ default: "shadow" }) contentClass?: string;

  handleHide() {
    this.$emit("hide");
  }

  handleShow() {
    this.$emit("show");
  }
}
</script>
<style lang="scss" scoped>
::v-deep .modal-header {
  padding-bottom: 0;
  height: 2.25rem;
  z-index: 100;
}
::v-deep .modal-body {
  padding-top: 0;
}
</style>
