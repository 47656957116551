<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :fullpage="fullpage"
      @submit="submit"
      :class="['signup-step', '-business-info', { '-loading': waiting }]"
      data-test="business-info-form"
    >
      <h1>Your Business</h1>
      <p class="blurb">
        Some basic details about your company <br />and its location.
      </p>

      <b-alert :show="errorMessage.length > 0" variant="danger">
        {{ errorMessage }}
      </b-alert>
      <BaseInput
        name="name"
        label="Business Name"
        placeholder="My Company, Inc."
        v-model="organization.name"
        type="text"
        maxlength="128"
        :state="errors.name ? false : null"
        data-test="input-business-name"
      ></BaseInput>
      <BaseSelect
        name="type"
        label="Company Type"
        placeholder="Select your company type"
        v-model="organization.type"
        :options="companyOptions"
        :state="errors.type ? false : null"
        data-test="input-business-type"
      ></BaseSelect>
      <BaseInput
        name="address"
        label="Street Address"
        placeholder="Business Street Address"
        v-model="organization.address1"
        type="text"
        maxlength="128"
        :state="errors.address ? false : null"
        data-test="input-business-address"
      ></BaseInput>
      <div class="BaseInput-group">
        <BaseInput
          name="address2"
          label="Unit (Optional)"
          placeholder="Unit or Floor"
          v-model="organization.address2"
          type="text"
          maxlength="128"
          data-test="input-business-unit"
        ></BaseInput>
        <BaseInput
          name="zipcode"
          label="ZIP Code"
          placeholder="00000"
          v-model="organization.zipcode"
          type="text"
          maxlength="10"
          :state="errors.zipcode ? false : null"
          data-test="input-business-zip"
        ></BaseInput>
      </div>
      <BaseButton
        type="submit"
        variant="primary"
        size="lg"
        :loading="waiting"
        data-test="next"
        >Next</BaseButton
      >
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

import { organizationStore } from "@/store";
import { Organization, OrganizationType } from "@/types/Organization";
import { OnboardingSteps } from "@/types/Onboarding";

@Component({})
export default class BusinessInfo extends Vue {
  @Prop({ default: false }) fullpage!: boolean;

  organization: Partial<Organization> = {};
  waiting = false;
  errorMessage = "";
  errors = {
    name: false,
    type: false,
    address: false,
    zipcode: false,
  };
  companyOptions = [
    { text: "C Corp", value: OrganizationType.C_CORP },
    { text: "S Corp", value: OrganizationType.S_CORP },
    { text: "LLC", value: OrganizationType.LLC },
    { text: "Non-Profit", value: OrganizationType.NON_PROFIT },
  ];

  submit() {
    if (this.organization.name) {
      this.errors.name = false;
    } else {
      this.errors.name = true;
      this.errorMessage = "Business name is required";
      return;
    }

    const validTypes = this.companyOptions.map((type) => type.value);
    if (this.organization.type && validTypes.includes(this.organization.type)) {
      this.errors.type = false;
    } else {
      this.errors.type = true;
      this.errorMessage = "Please select a company type";
      return;
    }

    if (this.organization.address1) {
      this.errors.address = false;
    } else {
      this.errors.address = true;
      this.errorMessage = "Business address is required";
      return;
    }

    if (this.organization.zipcode && this.organization.zipcode.length >= 5) {
      this.errors.zipcode = false;
    } else {
      this.errors.zipcode = true;
      this.errorMessage = "Business ZIP code is required";
      return;
    }

    this.waiting = true;

    organizationStore.actions
      .info(this.organization)
      .then(() => {
        if (this.organization.type === OrganizationType.SOLE_PROPRIETOR) {
          this.$emit("complete-step");
        } else {
          this.$emit(
            "complete-step",
            OnboardingSteps.DEPRECATED_BUSINESS_OWNERS
          );
        }
      })
      .catch((err) => {
        this.errorMessage = err.response?.data?.message || "There was an error";
      })
      .finally(() => {
        this.waiting = false;
      });
  }
}
</script>

<style lang="scss" scoped>
@import "./business-info-base";
</style>
