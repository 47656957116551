<template>
  <SimplePage :fullpage="fullpage">
    <div class="header" v-if="!fullpage">
      <div class="card">
        <PrivacyLogo :light="true" />
        <img
          class="check"
          src="/assets/images/home/charge-terms/check.svg"
          alt=""
        />
      </div>
    </div>
    <BaseForm
      :fullpage="fullpage"
      @submit="submit"
      class="charge-terms-success"
    >
      <div class="body">
        <h1>You're Approved!</h1>
        <div class="explainer">
          {{
            hasReissuableCards
              ? "Your old cards have been closed, get started reissuing them!"
              : "You're ready to create new Privacy Cards."
          }}
        </div>
        <BaseButton
          class="mt-2"
          type="submit"
          variant="primary"
          size="lg"
          data-test="button-done"
          @click="trackClick"
        >
          {{ hasReissuableCards ? "Reissue My Cards" : "Done" }}
        </BaseButton>
      </div>
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

import SVGIcon from "@/components/SVGIcon.vue";
import PrivacyLogo from "@/components/PrivacyLogo.vue";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component({
  components: {
    SVGIcon,
    PrivacyLogo,
  },
})
export default class ChargeTermsSuccess extends Vue {
  // As of now this component isn't displayed as a full page anywhere
  // Leaving the boilerplate in case we want to do this later
  @Prop({ default: false }) fullpage!: boolean;
  @Prop({ default: false }) hasReissuableCards!: boolean;

  trackClick() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.CHARGE_TERMS_SUCCESS)} Primary CTA`,
    });
  }

  submit() {
    this.$emit("complete-step");
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: absolute;
  top: -2.25rem;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 140px;
  width: 100%;
  border-radius: 1rem 1rem 0 0;
  background: linear-gradient(
      96.5deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    $color-green;

  > .card {
    position: relative;
    height: 105px;
    width: 374px;
    background-color: $color-foreground;
    border-radius: 40px 40px 0 0;

    > .check {
      position: absolute;
      top: 24px;
      left: 29px;
      height: 40px;
    }

    > .logo {
      position: absolute;
      top: 30px;
      right: 30px;
      width: 80px;
    }
  }
}

.charge-terms-success {
  width: 374px !important;
  height: 350px;
  text-align: center;
  opacity: 0;
  animation: show-step $duration-default;
  animation-timing-function: $ease-out-expo;
  animation-fill-mode: forwards;
  padding-top: 140px;
  padding-bottom: 0;

  > .body {
    > h1 {
      all: unset;
      margin-bottom: 15px;
      font-family: $font-stack-wes-fy;
      font-size: 24px;
      text-align: center;
      color: $gray-800;
      line-height: 1.4;
    }

    > .explainer {
      color: $gray-600;
      position: relative;
      margin: 20px 0 30px;
    }

    > .btn {
      width: 290px;
    }
  }
}
</style>
