<template>
  <b-form
    class="form"
    :class="{ fullpage }"
    @submit.prevent="handleSubmit"
    v-bind="$attrs"
  >
    <slot></slot>
  </b-form>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import PrivacyLogo from "./PrivacyLogo.vue";

@Component({
  components: {
    PrivacyLogo,
  },
})
export default class BaseForm extends Vue {
  @Prop({ default: false }) fullpage!: boolean;

  handleSubmit() {
    this.$emit("submit");
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%;
  min-height: 100%;
  width: 18.75rem;
  margin: auto;
  padding: 1.25rem 0;
  max-width: unset;
  @media #{$media-phone} {
    max-width: 100%;
  }

  &.fullpage::after {
    content: "";
    display: block;
    margin-top: auto;
  }

  .privacy-logo {
    margin: 1.25rem auto 3.75rem;
  }

  ::v-deep h3 {
    font-family: $font-stack-wes-fy;
    text-align: center;
    margin-bottom: 1rem;
  }

  ::v-deep .blurb {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
    color: $gray-600;

    &.-success {
      color: $green;
      opacity: 0;
      animation: show-resend-success;
      animation-duration: $duration-short;
      animation-timing-function: $ease-in-out-expo;
      animation-fill-mode: forwards;
    }
  }
}
</style>
