<template>
  <SimplePage :fullpage="fullpage">
    <div class="remediation-pending" data-test="remediation-pending">
      <div class="wrapper">
        <h1>We are reviewing your information</h1>
        <div>
          Thank you for submitting your information! Our team is currently
          reviewing your submission.
        </div>
        <BaseButton
          variant="primary"
          size="lg"
          @click="onCompleteStep"
          data-test="close"
        >
          {{ fullpage ? "Go to Dashboard" : "Dismiss" }}
        </BaseButton>
      </div>
    </div>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component
export default class RemediationPending extends Vue {
  @Prop({ default: false }) fullpage!: boolean;

  onCompleteStep() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.REMEDIATION_PENDING)} Primary CTA`,
    });

    this.$emit("complete-step", OnboardingSteps.COMPLETE);
  }
}
</script>

<style lang="scss">
.remediation-pending {
  h1 {
    all: unset;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    color: $gray-800;
  }

  button {
    width: 100%;
  }

  .wrapper {
    max-width: 300px;
    margin: 0 auto;
    padding: 0px;
    display: grid;
    gap: 20px;
    text-align: center;
  }
}
</style>
