<template>
  <div class="status-message" data-test="status-message">
    <div class="status-icon">
      <b-icon class="svg-icon" icon="x" />
    </div>
    <p v-if="status === 'expired'">
      <strong>Uh oh&hellip;</strong> This link has expired
    </p>
    <p v-else>
      <strong>Something went wrong.</strong> If this problem persists, contact
      <a href="mailto:support@privacy.com">support@privacy.com</a>
    </p>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { BIcon, BIconCheck, BIconX } from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconCheck,
    BIconX,
  },
})
export default class OneClick extends Vue {
  status = "";

  created() {
    this.status = this.$route.params.status || "";
  }
}
</script>
<style lang="scss" scoped>
.status-message {
  display: flex;
  align-items: center;
  padding: 40px;
  background-color: $gray-100;
  border-radius: 20px;
  font-size: 20px;
  line-height: 1.6;
  color: $gray-800;

  @media #{$media-phone} {
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    font-size: 16px;
    text-align: center;
  }
}

.status-message strong {
  font-weight: bold;
}

.status-message > .status-icon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin: 0 40px 0 0;
  height: 50px;
  width: 50px;
  background-color: $red;
  border-radius: 50px;

  ::v-deep svg {
    fill: $white;
    transform: scale(1.5);
  }

  @media #{$media-phone} {
    margin: 0 0 20px;
  }
}
</style>
