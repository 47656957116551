<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :fullpage="fullpage"
      class="business-not-sole-proprietor"
      data-test="business-not-sole-proprietor"
    >
      <div class="icon mb-4">
        <SVGIcon icon="error" size="16" />
      </div>
      <div class="headline">
        We’re sorry but we don’t support business types that are not sole
        proprietors.
      </div>
      <div class="copy">
        You can still create a Privacy account for personal spend purposes.
      </div>
      <hr />
      <BaseButton
        variant="primary"
        size="md"
        @click="resetPurpose"
        data-test="button-close"
      >
        Change to Personal
      </BaseButton>
      <BaseButton
        variant="light"
        size="md"
        @click="onClose"
        data-test="button-close"
      >
        Close
      </BaseButton>
    </BaseForm>
  </SimplePage>
</template>
<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import SVGIcon from "@/components/SVGIcon.vue";
import { Toast } from "@/mixins/Toast";
import { organizationStore, userStore } from "@/store";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component({
  components: {
    SVGIcon,
  },
})
export default class BusinessNotSoleProprietor extends Mixins(Toast) {
  @Prop({ default: false }) fullpage!: boolean;

  resetPurpose(): void {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.BUSINESS_NOT_SOLE_PROPRIETOR)} Primary CTA`,
    });

    organizationStore.actions
      .businessType({ businessType: null })
      .then(() => {
        userStore.actions
          .setAccountPurpose(null)
          .then(() => {
            this.$emit("complete-step", OnboardingSteps.PURPOSE);
          })
          .catch((err) => {
            this.errorToast(
              err.response?.data?.message || "There was an error"
            );
          });
      })
      .catch((err) => {
        this.errorToast(err.response?.data?.message || "There was an error");
      });
  }

  onClose(): void {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.BUSINESS_NOT_SOLE_PROPRIETOR)} Secondary CTA`,
    });

    this.$emit("complete-step", OnboardingSteps.COMPLETE);
  }
}
</script>
<style lang="scss" scoped>
.business-not-sole-proprietor {
  text-align: center;
  padding: 0;

  .headline {
    color: $gray-800;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  .copy {
    color: $gray-800;
    margin-bottom: 1.5rem;
  }

  hr {
    width: 100%;
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  .btn-primary {
    margin-bottom: 0.5rem;
  }
}
</style>
