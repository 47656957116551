<template>
  <div :class="{ '-searching': searching }" class="home-header">
    <TopNotification />
    <div class="column">
      <PrivacyLogo href="/" aria-label="Go to Home" is-square="true" />
      <SVGIcon
        icon="magnifier"
        @click="toggleSearching(true)"
        class="search-button"
      >
      </SVGIcon>
      <SearchInput
        :user="user"
        @focus="toggleSearching(true)"
        @search-input-closed="toggleSearching(false)"
        @search-results-closed="toggleSearching(false)"
        :searching="searching"
      >
      </SearchInput>
      <b-nav class="menu">
        <b-nav-item
          :active="currentPage === '/home'"
          class="header-link"
          to="/home"
        >
          Home
        </b-nav-item>
        <b-nav-item
          :active="currentPage === '/cards'"
          class="header-link"
          to="/cards"
        >
          Cards
        </b-nav-item>
        <b-nav-item
          :active="currentPage === '/transactions'"
          class="header-link"
          to="/transactions"
        >
          Transactions
        </b-nav-item>
        <b-nav-item
          class="header-link"
          href="https://support.privacy.com/"
          target="_blank"
        >
          Help
        </b-nav-item>
        <li>
          <b-dropdown
            class="user-dropdown-menu"
            variant="transparent"
            size="sm"
            data-test="user-dropdown-menu"
            aria-label="account menu"
            right
          >
            <template #button-content>
              <AccountPhoto :user="user" :editable="false" />
            </template>
            <b-dropdown-item class="header-link" to="/account">
              Account
            </b-dropdown-item>
            <b-dropdown-item
              v-if="user && user.activePromo && user.activePromo.enabled"
              class="header-link"
              to="/refer"
            >
              Refer Friends
            </b-dropdown-item>
            <b-dropdown-item
              :to="{ name: 'subscription-plan' }"
              class="header-link"
            >
              Upgrade
            </b-dropdown-item>
            <b-dropdown-item class="header-link" to="/logout">
              Log out
            </b-dropdown-item>
          </b-dropdown>
        </li>
      </b-nav>
      <b-dropdown
        class="dashboard-dropdown-menu"
        variant="link"
        size="sm"
        no-caret
      >
        <template #button-content>
          <span class="sr-only">Open navigation menu</span>
          <SVGIcon :icon="'hamburger'"></SVGIcon>
        </template>
        <b-dropdown-item class="header-link" to="/home"> Home </b-dropdown-item>
        <b-dropdown-item class="header-link" to="/cards">
          Cards
        </b-dropdown-item>
        <b-dropdown-item class="header-link" to="/transactions">
          Transactions
        </b-dropdown-item>
        <b-dropdown-item
          class="header-link"
          href="https://support.privacy.com/"
          target="_blank"
        >
          Help
        </b-dropdown-item>
        <b-dropdown-item class="header-link" to="/account">
          Account
        </b-dropdown-item>
        <b-dropdown-item
          v-if="user && user.activePromo && user.activePromo.enabled"
          class="header-link"
          to="/refer"
        >
          Refer Friends
        </b-dropdown-item>
        <b-dropdown-item
          :to="{ name: 'subscription-plan' }"
          class="header-link"
        >
          Upgrade
        </b-dropdown-item>
        <b-dropdown-item class="header-link" to="/logout">
          Log out
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import bowser from "bowser";
import { userStore } from "@/store";
import { Extension } from "@/mixins/Extension";
import AccountPhoto from "@/components/AccountPhoto.vue";
import PrivacyLogo from "@/components/PrivacyLogo.vue";
import SearchInput from "@/components/SearchInput.vue";
import SVGIcon from "@/components/SVGIcon.vue";
import TopNotification from "@/components/TopNotification.vue";

@Component({
  components: {
    AccountPhoto,
    PrivacyLogo,
    SearchInput,
    SVGIcon,
    TopNotification,
  },
})
export default class Navbar extends Mixins(Extension) {
  searching = false;
  browser = bowser;
  isSticky = false;

  get user() {
    return userStore.getters.currentUser;
  }

  get currentPage(): string {
    return this.$route.path;
  }

  get shouldShowCardCreateButton() {
    return ["/home", "/cards", "/transactions"].some(
      (route) => route === this.currentPage
    );
  }

  onScroll() {
    this.isSticky = document.documentElement.scrollTop > 0;
  }

  mounted() {
    window.addEventListener("scroll", this.onScroll);
  }

  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  }

  toggleSearching(isSearching = false) {
    this.searching = isSearching;
  }
}
</script>
<style lang="scss" scoped>
.home-header {
  position: fixed;
  top: 0;
  z-index: 101;
  width: 100vw;
  background: $white;
  box-shadow: 0 0 10px fade-out($gray-800, 0.8);
  -webkit-font-smoothing: antialiased;

  .logo {
    margin-left: 20px;
    flex-shrink: 0;
  }

  > .column {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    min-height: 60px;
    max-width: 1180px;
    user-select: none;

    @media only screen and (max-width: 1400px) {
      margin: 0;
      padding-right: 10px;
      max-width: none;
    }
  }

  > .column > .search-button {
    display: none;

    @media #{$media-phone} {
      cursor: pointer;
      display: block;
      margin-left: auto;
    }
  }

  > .column > .menu {
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    margin-left: auto;

    @media only screen and (max-width: 899px) {
      display: none;
    }
  }

  > .column > .menu > li {
    list-style: none;
    &:nth-child(5) {
      margin-left: auto;
    }
  }

  > .column > .menu > li > a > .header-link {
    flex-shrink: 0;
    padding: 6px 10px;
    font-weight: bold;
    white-space: nowrap;
    color: $gray-600;
    transition: opacity $duration-shorter;
    &:hover,
    &.active {
      color: $gray-800;
      text-decoration: none;
    }
  }

  .spacer {
    flex: 1;
  }

  a.header-link,
  a.nav-link {
    color: $gray-600;
    font-weight: bold;
    &:hover {
      text-decoration: none;
      color: $gray-800;
    }
  }

  .dashboard-dropdown-menu {
    margin-left: auto;

    > .dropdown-toggle {
      outline: none;
    }

    @media only screen and (min-width: 900px) {
      display: none;
    }

    @media #{$media-phone} {
      margin-left: unset;
    }
  }

  ::v-deep .dropdown-toggle::after {
    display: none;
  }

  ::v-deep .btn:focus {
    box-shadow: none;
  }

  .user-dropdown-menu {
    @media #{$media-phone} {
      margin-left: unset;
    }

    ::v-deep .dropdown-toggle:hover {
      background-color: rgba(255, 255, 255, 1);
    }
  }

  &.-searching {
    @media #{$media-phone} {
      > .column > .logo,
      > .column > .search-button,
      > .column > .dashboard-dropdown-menu {
        display: none;
      }
    }
  }

  &:not(.-searching) {
    @media #{$media-phone} {
      > .column ::v-deep .search-input {
        display: none;
      }
    }
  }
}
</style>
