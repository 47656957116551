<template>
  <SimplePage class="oneclick-page">
    <div class="message-container">
      <section class="section">
        <router-view />
      </section>
      <div class="home-link">
        You can close this window or <a href="/">head home</a>
      </div>
    </div>
  </SimplePage>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class OneClick extends Vue {}
</script>
<style lang="scss" scoped>
.oneclick-page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  min-height: 100vh;
  font-family: $font-stack-lato;

  > .privacy-logo {
    margin-bottom: auto;
  }

  .message-container {
    margin: auto 0;
    padding: 40px 10px;
    max-width: 540px;
  }

  .message-container > .home-link {
    margin-top: 20px;
    text-align: center;
    color: $gray-600;
  }

  .message-container > .home-link > a {
    text-decoration: underline;
    color: inherit;
  }

  @media #{$media-phone} {
    min-height: auto;
  }
}
</style>
