<template>
  <SimplePage :fullpage="true">
    <BaseForm id="id-verify-form" @submit="submit" :fullpage="true">
      <h1 class="title">Secure Identity Verification</h1>

      <div v-if="!success">
        <p class="blurb text-left">
          Your ID must be an official, unexpired, US government-issued ID that
          includes a photo of you. The following types of government-issued
          photo ID are acceptable: Driver's license, Passport, and
          State/National Identity Card.
        </p>
        <div class="blurb text-left">
          When responding, please attach/upload the following
          <span class="bold">three</span> photos:
          <ul>
            <li>Front of your government-issued ID</li>
            <li>Back of your government-issued ID</li>
            <li>Yourself holding the ID, facing forward</li>
          </ul>
        </div>
        <p class="blurb text-left">
          Please ensure that
          <span class="bold">
            your face is visible, and your ID is in focus and fully
            readable</span
          >.
        </p>
      </div>

      <b-img
        v-if="!webCamEnabled && !success"
        class="photo-guideline"
        :src="imgSrc"
      />
      <b-alert variant="danger" show v-if="errorMessage">
        {{ errorMessage }}
      </b-alert>
      <BaseFileUpload
        v-if="!file && !webCamEnabled && !success"
        accept="image/jpeg, image/png"
        name="docs"
        label="ID Images"
        image="/assets/images/onboarding/id-verify-photo-guideline.png"
        :description="
          isMobile
            ? 'Tap to take a photo or browse existing photos'
            : 'Click to browse, or drag photo here to upload'
        "
        maxfiles="3"
        v-model="docs"
        :disabled="docsDisabled"
        :state="docsValid"
        @input="validateInputSize"
        :loading="loading"
        :capture="isMobile"
        data-test="id-verify-file-upload"
      />
      <webCam
        class="webcam"
        v-if="webCamEnabled && !captured"
        ref="webcam"
        selectFirstDevice
        width="500"
      />
      <div class="webcam" v-if="webCamEnabled && captured">
        <b-img :src="captured" width="500" />
      </div>
      <BaseButton
        @click="capture"
        v-if="webCamEnabled && !captured"
        buttonSize="sm"
        class="submit-button"
        variant="success"
      >
        Take Photo
      </BaseButton>

      <div class="photo-buttons" v-if="webCamEnabled && captured">
        <BaseButton variant="success" size="sm" @click="savePhoto">
          <span>Use Photo</span>
        </BaseButton>
        <BaseButton variant="danger" size="sm" @click="captured = ''">
          <span>Retake Photo</span>
        </BaseButton>
      </div>
      <BaseButton
        v-if="!isMobile && !success"
        @click="webCamEnabled = !webCamEnabled"
        variant="primary"
        class="submit-button"
      >
        <b-icon v-if="!webCamEnabled" icon="camera-fill" />
        {{ webCamEnabled ? "Upload Files" : "Use Webcam" }}
      </BaseButton>
      <b-form-checkbox
        class="onfido-checkbox"
        v-model="acceptedOnfidoDisclosure"
        v-if="!success"
        data-test="id-verify-onfido-checkbox"
      >
        This verification process is powered by Onfido. I agree I have read,
        understand, and accept the
        <a
          href="https://onfido.com/facial-scan-policy-and-release/"
          target="_blank"
          >Onfido Facial Scan Policy and Release</a
        >,
        <a href="https://onfido.com/privacy/" target="_blank">Privacy Policy</a
        >, and
        <a href="https://onfido.com/terms-of-service/" target="_blank"
          >Terms of Service</a
        >
      </b-form-checkbox>
      <BaseButton
        @click="submit"
        v-if="!webCamEnabled"
        variant="primary"
        :disabled="invalid || docs.length < 3 || !acceptedOnfidoDisclosure"
        :success="success"
        buttonSize="lg"
        class="submit-button"
        :loading="loading"
        data-test="id-verify-submit"
      >
        {{ success ? "Done" : "Submit" }}
      </BaseButton>
      <b-link class="home-link" v-if="success" href="/">
        Continue to Home
      </b-link>
    </BaseForm>
  </SimplePage>
</template>
<script lang="ts">
import ldGet from "lodash/get";
import bowser from "bowser";
import { WebCam } from "vue-web-cam";
import { Component, Mixins } from "vue-property-decorator";
import { BIcon, BIconCameraFill } from "bootstrap-vue";
import { Toast } from "@/mixins/Toast";
import { WebCamType } from "@/types/Form";
import { userStore } from "@/store";

@Component({
  components: {
    WebCam,
    BIcon,
    BIconCameraFill,
  },
})
export default class IdVerify extends Mixins(Toast) {
  isMobile = false;
  webCamEnabled = false;
  captured = "";
  loading = false;
  success = false;
  file = null;
  invalid: boolean | null = null;
  acceptedOnfidoDisclosure = false;
  docsDisabled = false;
  docsValid = null;
  docs: File[] = [];
  errorMessage: string | null = null;
  MAX_SIZE = 10 * 1000000; // 10 MB in Bytes
  imgSrc = "/assets/images/onboarding/id-verify-photo-guideline.png";

  capture() {
    const wc = this.$refs.webcam as unknown as WebCamType;
    this.captured = wc.capture();
  }

  savePhoto() {
    // vue-web-cam saves images as a base64 dataURL.
    // the following code attempts to extract the mimetype
    // and photodata and convert it to a File
    // adapted from SO [https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f]
    try {
      let arr = [];
      arr = this.captured.split(",");
      if (arr.length < 2) {
        // we won't be able to create a file from this dataURL
        throw new Error();
      }
      const match = arr[0].match(/:(.*?);/) || [];
      // if we can't find the mimetype, assume jpg
      const mime = match.length > 1 ? match[1] : "image/jpeg";
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n) {
        n -= 1;
        u8arr[n] = bstr.charCodeAt(n);
      }
      const photoFile = new File(
        [u8arr],
        "privacy_img_" + (this.docs.length + 1),
        {
          type: mime,
        }
      );
      this.docs.push(photoFile);
    } catch {
      this.success = false;
      this.errorToast("There was an error saving your photo");
    }
    this.webCamEnabled = false;
    this.captured = "";
  }

  created() {
    this.isMobile = bowser.mobile || bowser.tablet;
  }

  validateInputSize(files: File[]) {
    if (files.find((f) => f.size > this.MAX_SIZE)) {
      this.invalid = true;
      this.errorMessage = "Your file cannot be over 10MB";
    } else {
      this.errorMessage = null;
    }
  }

  submit() {
    this.loading = true;

    userStore.actions
      .uploadID({
        files: this.docs,
        acceptedOnfidoDisclosure: this.acceptedOnfidoDisclosure,
      })
      .then(() => {
        this.success = true;
        this.errorMessage = null;
      })
      .catch((err) => {
        this.success = false;
        const errorMessage = ldGet<any, string>(err, "response.data.message");
        this.errorMessage = errorMessage;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
</script>
<style lang="scss" scoped>
.title {
  margin-bottom: 15px;
  font-size: 24px;
  text-align: center;
  color: $gray-800;
  line-height: 1.4;
  font-family: $font-stack-wes-fy;
}
::v-deep .blurb .bold {
  font-weight: bolder;
  color: $gray-800;
}
.photo-guideline {
  margin-bottom: 5px;
}
.webcam {
  justify-content: center;
  align-self: center;
  width: 500px;
  height: 467px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.photo-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  margin: 10px 0;
  justify-content: space-evenly;
}

.onfido-checkbox {
  font-size: 12px;
  border-radius: $border-radius;
  border: 2px solid $gray-100;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  padding: 15px 20px;
  padding-left: calc(1.5rem + 20px);
  &.error {
    border-color: $red;
  }
  ::v-deep .custom-control-label {
    &::before,
    &::after {
      margin-top: 5px;
    }
  }
}

.submit-button {
  margin-top: 5px;
}
.home-link {
  color: $blue;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
</style>
