<template>
  <div
    class="tag"
    data-test="tag"
    :class="{
      [`-${color}`]: true,
      '-selected': isSelected,
    }"
  >
    <svg width="24" height="28">
      <path
        d="M23.515 0v28h-8.687a8 8 0 01-5.656-2.343l-7.415-7.414a6 6 0 010-8.486l7.415-7.414A8 8 0 0114.828 0h8.687zm-9 10a4 4 0 100 8 4 4 0 000-8z"
        fill-rule="evenodd"
      ></path>
    </svg>
    <div class="title">
      {{ title }}
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TagRow extends Vue {
  @Prop() title!: string;
  @Prop() color?: string;
  @Prop() isSelected?: boolean;
}
</script>
<style lang="scss" scoped>
.tag {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  font-family: $font-stack-lato;
  font-weight: bold;
  background-color: fade-out($tag-color-default, 0.8);
  color: $tag-color-default;
  height: 28px;
  padding: 0 10px;

  > .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }

  > svg {
    display: none;
  }

  &.-selected {
    background-color: $tag-color-default;
    color: $white;
    opacity: 1;
    padding: 0 10px 0 2px;
    margin-left: 23px;
    border-radius: 0 4px 4px 0;
    max-width: calc(100% - 23px);

    > svg {
      display: block;
      position: absolute;
      left: -22px;

      > path {
        fill: $tag-color-default;
      }
    }
  }

  // colors

  &.-red {
    background-color: fade-out($tag-color-red, 0.9);
    color: $tag-color-red;

    &.-selected {
      background-color: $tag-color-red;
      color: $white;

      > svg > path {
        fill: $tag-color-red;
      }
    }
  }

  &.-orange {
    background-color: fade-out($tag-color-orange, 0.9);
    color: $tag-color-orange;

    &.-selected {
      background-color: $tag-color-orange;
      color: $white;

      > svg > path {
        fill: $tag-color-orange;
      }
    }
  }

  &.-yellow {
    background-color: fade-out($tag-color-yellow, 0.9);
    color: $tag-color-yellow;

    &.-selected {
      background-color: $tag-color-yellow;
      color: $white;

      > svg > path {
        fill: $tag-color-yellow;
      }
    }
  }

  &.-yellow-green {
    background-color: fade-out($tag-color-yellow-green, 0.9);
    color: $tag-color-yellow-green;

    &.-selected {
      background-color: $tag-color-yellow-green;
      color: $white;

      > svg > path {
        fill: $tag-color-yellow-green;
      }
    }
  }

  &.-lush-green {
    background-color: fade-out($tag-color-lush-green, 0.9);
    color: $tag-color-lush-green;

    &.-selected {
      background-color: $tag-color-lush-green;
      color: $white;

      > svg > path {
        fill: $tag-color-lush-green;
      }
    }
  }

  &.-sky-blue {
    background-color: fade-out($tag-color-sky-blue, 0.9);
    color: $tag-color-sky-blue;

    &.-selected {
      background-color: $tag-color-sky-blue;
      color: $white;

      > svg > path {
        fill: $tag-color-sky-blue;
      }
    }
  }

  &.-blue {
    background-color: fade-out($tag-color-blue, 0.9);
    color: $tag-color-blue;

    &.-selected {
      background-color: $tag-color-blue;
      color: $white;

      > svg > path {
        fill: $tag-color-blue;
      }
    }
  }

  &.-purple {
    background-color: fade-out($tag-color-purple, 0.9);
    color: $tag-color-purple;

    &.-selected {
      background-color: $tag-color-purple;
      color: $white;

      > svg > path {
        fill: $tag-color-purple;
      }
    }
  }

  &.-pink {
    background-color: fade-out($tag-color-pink, 0.9);
    color: $tag-color-pink;

    &.-selected {
      background-color: $tag-color-pink;
      color: $white;

      > svg > path {
        fill: $tag-color-pink;
      }
    }
  }
}
</style>
