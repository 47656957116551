<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      @submit="submit"
      :fullpage="fullpage"
      :class="['signup-basic-info', { '-loading': loading }]"
      data-test="signup-basic-info-form"
      id="signup-basic-info-form"
    >
      <ProgressTracker
        v-if="showProgressTracker"
        :currentStep="1"
        :totalSteps="5"
      ></ProgressTracker>
      <div class="header-content">
        <h1 v-if="isRemediatingKYC">
          We need to verify some of your information
        </h1>
        <h1 v-else>Tell us a bit more about yourself</h1>
        <p class="blurb" v-if="isRemediatingKYC">
          Please submit this information to reactivate your Privacy Account.
        </p>
        <p class="blurb" v-else>
          Let's start with some basic info. You must be 18 years or older to be
          eligible
        </p>
      </div>
      <b-alert variant="danger" :show="errorMessage.length > 0">
        {{ errorMessage }}
      </b-alert>
      <BaseInputGroup>
        <BaseInput
          name="firstName"
          label="Legal First Name"
          placeholder="First Name"
          v-model="firstName"
          type="text"
          maxlength="128"
          :autofocus="true"
          :state="firstNameError ? false : null"
          data-test="input-first-name"
        />
        <BaseInput
          name="middleName"
          label="Middle Name"
          secondary-label="Optional"
          placeholder="Middle Name"
          v-model="middleName"
          type="text"
          maxlength="128"
          data-test="input-middle-name"
        />
      </BaseInputGroup>
      <BaseInput
        name="lastName"
        label="Last Name"
        placeholder="Last Name"
        v-model="lastName"
        type="text"
        maxlength="128"
        :state="lastNameError ? false : null"
        data-test="input-last-name"
      />
      <BaseInput
        name="preferredFirstName"
        label="Preferred First Name"
        secondary-label="Optional"
        placeholder="Preferred Name"
        v-model="preferredFirstName"
        type="text"
        maxlength="128"
        :state="preferredFirstNameError ? false : null"
        data-test="input-preferred-first-name"
      />
      <BaseInput
        name="dob"
        label="Date of Birth"
        placeholder="MM/DD/YYYY"
        v-model="dob"
        type="text"
        maxlength="10"
        format="date"
        :state="dobError ? false : null"
        data-test="input-dob"
      />
      <BaseButton
        variant="primary"
        type="submit"
        :loading="loading"
        size="lg"
        data-test="next"
        @click="trackClick"
      >
        Next
      </BaseButton>
      <OnboardingFooterActions
        :allowChangeAccountType="true"
        :showFinishLater="isRemediatingKYC"
        @footer-action="onFooterAction"
      />
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { formatDate, isValidDate, utcDate } from "@/lib/dates";
import { Vue, Component, Prop } from "vue-property-decorator";
import { userStore } from "@/store";
import ProgressTracker from "@/components/ProgressTracker.vue";
import OnboardingFooterActions from "@/components/OnboardingFooterActions.vue";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component({
  components: {
    ProgressTracker,
    OnboardingFooterActions,
  },
})
export default class SignupBasicInfo extends Vue {
  @Prop({ default: false }) fullpage!: boolean;
  @Prop({ default: false }) showProgressTracker!: boolean;

  preferredFirstName = "";
  firstName = "";
  middleName = "";
  lastName = "";
  dob = "";

  errorMessage = "";
  firstNameError = false;
  lastNameError = false;
  dobError = false;
  preferredFirstNameError = false;
  loading = false;

  get isRemediatingKYC(): boolean {
    return !!userStore.getters.currentUser?.isRemediatingKYC;
  }

  mounted() {
    const user = userStore.getters.currentUser;
    this.preferredFirstName = user?.preferredFirstName || "";
    this.firstName = user?.firstName || "";
    this.middleName = user?.middleName || "";
    this.lastName = user?.lastName || "";
    let dob = user?.dob ?? "";
    // dob may look like "01/31/1979" or "1979-01-31T00:00:00.000Z"; if the former, don't go through
    // timezone conversion (and potentially change it to a different date), just display it
    if (dob && !isValidDate("MM/DD/YYYY", dob)) {
      dob = formatDate("MM/DD/YYYY", utcDate(dob));
    }
    this.dob = dob;
  }

  trackClick() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.BASIC_INFO)} Primary CTA`,
    });
  }

  submit() {
    this.errorMessage = "";

    if (this.firstName.length) {
      this.firstNameError = false;
    } else {
      this.firstNameError = true;
      this.errorMessage = "Please enter your first name";
      return;
    }

    if (this.lastName.length) {
      this.lastNameError = false;
    } else {
      this.lastNameError = true;
      this.errorMessage = "Please enter your last name";
      return;
    }

    if (this.dob?.length === 10) {
      this.dobError = false;
    } else {
      this.dobError = true;
      this.errorMessage =
        "Please enter a correctly formatted date of birth (MM/DD/YYYY)";
      return;
    }

    if (this.preferredFirstName.match(/\s/)) {
      this.preferredFirstNameError = true;
      this.errorMessage = "Preferred first name should be a single word";
      return;
    }
    this.preferredFirstNameError = false;

    // Remove formatting from form input values
    const dobNumeric = this.dob.replace(/\D/g, "");

    this.loading = true;

    const userInfo = {
      preferredFirstName: this.preferredFirstName,
      firstName: this.firstName,
      middleName: this.middleName,
      lastName: this.lastName,
      dob: dobNumeric,
    };

    userStore.actions
      .saveBasicInfo(userInfo)
      .then(() => {
        this.loading = false;
        this.$emit("complete-step");
      })
      .catch(({ response }) => {
        this.loading = false;
        this.errorMessage =
          response?.data?.message ||
          "There was an internal server error, please contact support@privacy.com";
      });
  }

  onFooterAction(nextScreen: OnboardingSteps): void {
    this.$emit("complete-step", nextScreen);
  }
}
</script>

<style lang="scss" scoped>
.signup-basic-info {
  width: 100%;
  max-width: 340px;

  h1 {
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    margin-bottom: 15px;
    line-height: 1.4;
    color: $gray-800;
  }

  .field {
    margin-bottom: 2px;
  }

  ::v-deep field.title {
    line-height: 0.8;
    @media #{$media-phone} {
      line-height: 1;
    }
  }

  .btn {
    margin-top: 20px;
  }
}
</style>
