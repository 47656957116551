export enum OrganizationType {
  CORP = "corp",
  S_CORP = "sCorp",
  C_CORP = "cCorp",
  LLC = "llc",
  NON_PROFIT = "nonProfit",
  PARTNERSHIP = "partnership",
  SOLE_PROPRIETOR = "proprietorship",
  OTHER = "other",
}

export enum BusinessType {
  INDIVIDUAL = "INDIVIDUAL",
  PRIVATE_COMPANY = "PRIVATE_COMPANY",
  NONPROFIT = "NONPROFIT",
  PUBLIC_COMPANY = "PUBLIC_COMPANY",
  OTHER = "OTHER",
}

export enum OwnershipType {
  PRIMARY_USER_OWNS = "PRIMARY_USER_OWNS",
  OTHER_PERSON_OWNS = "OTHER_PERSON_OWNS",
  NO_ONE_OWNS = "NO_ONE_OWNS",
}

export interface BusinessOwner {
  firstName: string;
  lastName: string;
  phone?: string;
  address1: string;
  address2?: string;
  zipcode: string;
  dob: string;
  ssn?: string;
  ssnLast4?: string;
  needsAlternateID?: boolean;
  reusePhone?: boolean;
}

export interface Organization {
  name: string;
  type?: OrganizationType;
  address?: {
    street1: string;
    street2: string;
    zipcode: string;
  };
  // Maintaining these for backwards compatibility with the BusinessInfo screen
  // TODO remove these once we remove that deprecated screen
  address1?: string;
  address2?: string;
  zipcode?: string;

  // Additional business info
  website?: string;
  useCase?: string;
  businessServices?: string;
  businessType?: BusinessType | null;
  referral?: string;
  doingBusinessAs?: string;
  ownershipType?: OwnershipType;
  owners: BusinessOwner[];

  // Legal info
  ein?: string;
  files?: File[];
  statements?: File[];
  legalAgreement?: boolean;

  // Onboarding related info
  infoComplete?: boolean;
  infoAboutComplete?: boolean;
  legalComplete?: boolean;
  pendingIssuing?: boolean;
  enterpriseContactSent?: boolean;
  typeComplete?: boolean;
  detailsComplete?: boolean;
  useCaseComplete?: boolean;
  businessServicesComplete?: boolean;
  ownersComplete?: boolean;

  active?: boolean;
}

export interface ContactForm {
  firstName: string;
  lastName: string;
  businessName: string;
  useCase: string;
}
