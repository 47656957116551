export interface AWSImg {
  key: string;
  location: string;
}

export interface Dispute {
  accountID: string;
  email: string;
  PAN: string;
  dateAuthorized: string;
  dateSettled: string;
  amount: string;
  settledAmount: string;
  descriptor: string;
  transactionID: number;
  state: string;
  sent: string;
  result: string;
  loadingScore: boolean;
  disputeScore: string;
  totalDisputes: number;
  totalTransactions: string;
  evidence: {
    dateOpened: Date;
    reason?: string;
    details: string;
    evidenceImages: AWSImg[];
    signatureImage: AWSImg;
  };
}

export interface DisputeState {
  disputes: Dispute[];
}

export enum DisputeType {
  DISPUTE_OPEN = "DISPUTE_OPEN",
  DISPUTE_PENDING = "DISPUTE_PENDING",
  DISPUTE_REJECTED = "DISPUTE_REJECTED",
  DISPUTE_APPROVED = "DISPUTE_APPROVED",
  DISPUTE_CLOSED = "DISPUTE_CLOSED",
  CHARGEBACK_PENDING = "CHARGEBACK_PENDING",
  CHARGEBACK_APPROVED = "CHARGEBACK_APPROVED",
  CHARGEBACK_REJECTED = "CHARGEBACK_REJECTED",
  INTERNAL_CHARGEBACK_PENDING = "INTERNAL_CHARGEBACK_PENDING",
  INTERNAL_CHARGEBACK_APPROVED = "INTERNAL_CHARGEBACK_APPROVED",
  INTERNAL_CHARGEBACK_REJECTED = "INTERNAL_CHARGEBACK_REJECTED",
  ACTIVE = "ACTIVE",
}

export interface DisputeAction {
  name: string;
  newState: DisputeType;
  emailUserByDefault: boolean;
  emailTemplate: string;
  emailHasCustomText: boolean;
  emailDefaultCustomText: string;
  canRefund: boolean;
  refundReason: string | null;
}

export interface DisputeModalActionSelections {
  notifyUser: boolean;
  refundAmount: string;
  shouldRefund: boolean;
  refundType: string;
  customText: string;
}

export interface DisputeModalAction {
  dispute: Dispute;
  config: DisputeAction;
  selections: DisputeModalActionSelections;
}

export enum RefundType {
  CASH = "CASH",
  PROMO = "PROMO",
}

export const disputeActions = {
  REJECT_DISPUTE: {
    name: "Reject Dispute",
    newState: DisputeType.DISPUTE_REJECTED,
    emailUserByDefault: true,
    emailTemplate: "dispute-rejected",
    emailHasCustomText: true,
    emailDefaultCustomText:
      "Unfortunately, the third-party chargeback issuer has denied the chargeback filed on your behalf. The dispute has been closed in favor of the merchant.",
    canRefund: false,
    refundReason: null,
  },
  MERCHANT_REFUNDED: {
    name: "Merchant Refunded",
    newState: DisputeType.DISPUTE_REJECTED,
    emailUserByDefault: true,
    emailTemplate: "dispute-merchant-refunded",
    emailHasCustomText: false,
    emailDefaultCustomText: null,
    canRefund: false,
    refundReason: null,
  },
  APPROVE_DISPUTE: {
    name: "Accept Dispute",
    newState: DisputeType.DISPUTE_APPROVED,
    emailUserByDefault: true,
    emailTemplate: "dispute-approved",
    emailHasCustomText: false,
    emailDefaultCustomText: null,
    canRefund: true,
    refundReason: "DISPUTE_WON_CREDIT",
  },
  FILE_CHARGEBACK: {
    name: "File Chargeback",
    newState: DisputeType.CHARGEBACK_PENDING,
    emailUserByDefault: true,
    emailTemplate: "dispute-cb-pending",
    emailHasCustomText: false,
    emailDefaultCustomText: null,
    canRefund: true,
    refundReason: "CHARGEBACK_PROVISIONAL_CREDIT",
  },
  REQUEST_EVIDENCE: {
    name: "Request Info",
    newState: DisputeType.DISPUTE_PENDING,
    emailUserByDefault: false,
    emailTemplate: null,
    emailHasCustomText: false,
    emailDefaultCustomText: null,
    canRefund: false,
    refundReason: null,
  },
  DISPUTE_PENDING: {
    name: "Dispute Pending",
    newState: DisputeType.DISPUTE_PENDING,
    emailUserByDefault: false,
    emailTemplate: null,
    emailHasCustomText: false,
    emailDefaultCustomText: null,
    canRefund: false,
    refundReason: null,
  },
  CHARGEBACK_WON: {
    name: "Chargeback Accepted",
    newState: DisputeType.CHARGEBACK_APPROVED,
    emailUserByDefault: true,
    emailTemplate: "dispute-cb-approved",
    emailHasCustomText: false,
    emailDefaultCustomText: null,
    canRefund: true,
    refundReason: "CHARGEBACK_WON_CREDIT",
  },
  CHARGEBACK_LOST: {
    name: "Chargeback Rejected",
    newState: DisputeType.CHARGEBACK_REJECTED,
    emailUserByDefault: true,
    emailTemplate: "dispute-rejected",
    emailHasCustomText: true,
    emailDefaultCustomText:
      "Unfortunately, the third-party chargeback issuer has denied the chargeback filed on your behalf. The dispute has been closed in favor of the merchant.",
    canRefund: false,
    refundReason: null,
  },
  CHARGEBACK_REVERSED: {
    name: "Chargeback Reversed",
    newState: DisputeType.CHARGEBACK_REJECTED,
    emailUserByDefault: true,
    emailTemplate: null,
    emailHasCustomText: true,
    emailDefaultCustomText: null,
    canRefund: false,
    refundReason: null,
  },
  FILE_INTERNAL_CHARGEBACK: {
    name: "Reset to Filed",
    newState: DisputeType.INTERNAL_CHARGEBACK_PENDING,
    emailUserByDefault: false,
    emailTemplate: null,
    emailHasCustomText: false,
    emailDefaultCustomText: null,
    canRefund: false,
    refundReason: null,
  },
  INTERNAL_WON: {
    name: "Internal Chargeback Won",
    newState: DisputeType.INTERNAL_CHARGEBACK_APPROVED,
    emailUserByDefault: false,
    emailTemplate: null,
    emailHasCustomText: false,
    emailDefaultCustomText: null,
    canRefund: false,
    refundReason: null,
  },
  INTERNAL_LOST: {
    name: "Internal Chargeback Lost",
    newState: DisputeType.INTERNAL_CHARGEBACK_REJECTED,
    emailUserByDefault: false,
    emailTemplate: null,
    emailHasCustomText: false,
    emailDefaultCustomText: null,
    canRefund: false,
    refundReason: null,
  },
  CANCEL_INTERNAL: {
    name: "Cancel Internal Chargeback",
    newState: DisputeType.ACTIVE,
    emailUserByDefault: false,
    emailTemplate: null,
    emailHasCustomText: false,
    emailDefaultCustomText: null,
    canRefund: false,
    refundReason: null,
  },
};

export const disputeStates = {
  DISPUTE_OPEN: {
    name: "Open Dispute",
    key: DisputeType.DISPUTE_OPEN,
    actions: [
      disputeActions.REJECT_DISPUTE,
      disputeActions.APPROVE_DISPUTE,
      disputeActions.REQUEST_EVIDENCE,
      disputeActions.FILE_CHARGEBACK,
      disputeActions.MERCHANT_REFUNDED,
    ],
  },
  DISPUTE_PENDING: {
    name: "Pending Dispute",
    key: DisputeType.DISPUTE_PENDING,
    actions: [
      disputeActions.REJECT_DISPUTE,
      disputeActions.APPROVE_DISPUTE,
      disputeActions.FILE_CHARGEBACK,
      disputeActions.MERCHANT_REFUNDED,
    ],
  },
  CHARGEBACK_PENDING: {
    name: "Chargeback Pending",
    key: DisputeType.CHARGEBACK_PENDING,
    actions: [
      disputeActions.CHARGEBACK_WON,
      disputeActions.CHARGEBACK_LOST,
      disputeActions.MERCHANT_REFUNDED,
      disputeActions.CHARGEBACK_REVERSED,
    ],
  },
  DISPUTE_CLOSED: {
    name: "Dispute Won (legacy)",
    key: DisputeType.DISPUTE_CLOSED,
    actions: [],
  },
  DISPUTE_REJECTED: {
    name: "Dispute Rejected",
    key: DisputeType.DISPUTE_REJECTED,
    actions: [disputeActions.DISPUTE_PENDING],
  },
  DISPUTE_APPROVED: {
    name: "Dispute Approved",
    key: DisputeType.DISPUTE_APPROVED,
    actions: [disputeActions.DISPUTE_PENDING],
  },
  CHARGEBACK_APPROVED: {
    name: "Chargeback Won",
    key: DisputeType.CHARGEBACK_APPROVED,
    actions: [disputeActions.FILE_CHARGEBACK],
  },
  CHARGEBACK_REJECTED: {
    name: "Chargeback Lost",
    key: DisputeType.CHARGEBACK_REJECTED,
    actions: [disputeActions.FILE_CHARGEBACK],
  },
  INTERNAL_CHARGEBACK_APPROVED: {
    name: "Internal Chargeback Won",
    key: DisputeType.INTERNAL_CHARGEBACK_APPROVED,
    actions: [disputeActions.FILE_INTERNAL_CHARGEBACK],
  },
  INTERNAL_CHARGEBACK_REJECTED: {
    name: "Internal Chargeback Lost",
    key: DisputeType.INTERNAL_CHARGEBACK_REJECTED,
    actions: [disputeActions.FILE_INTERNAL_CHARGEBACK],
  },
  INTERNAL_CHARGEBACK_PENDING: {
    name: "Internal Chargeback Filed",
    key: DisputeType.INTERNAL_CHARGEBACK_PENDING,
    actions: [
      disputeActions.INTERNAL_WON,
      disputeActions.INTERNAL_LOST,
      disputeActions.CANCEL_INTERNAL,
    ],
  },
};

export type DisputeSlideLink = {
  go: string;
  text: string;
  class?: string;
};

export type DisputeSlide = {
  state: string;
  header: string;
  text?: string;
  links?: DisputeSlideLink[];
  back?: boolean;
  ok?: boolean;
};
