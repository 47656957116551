<template>
  <BaseForm
    class="signup-step -login -reset-password"
    @submit="reset"
    :class="{
      error: submitted && errorMessage.length > 0,
      '-loading': waiting,
    }"
    novalidate
  >
    <div class="form-header">
      <h1>Reset Password</h1>
      <a class="header-link" @click="goToLogin">Login</a>
    </div>
    <div class="blurb -left">
      We’ll send you an email with a link to reset the password to your account.
    </div>
    <div class="error-message" v-if="errorMessage.length > 0">
      {{ errorMessage }}
    </div>
    <div class="_reset-expand-to-fill"></div>
    <BaseInput
      name="email"
      label="Email"
      placeholder="email@example.com"
      v-model="user.resetEmail"
      type="email"
      max-length="64"
      :autofocus="true"
      :disabled="emailSent"
      :state="errorMessage ? false : null"
    ></BaseInput>
    <Recaptcha
      class="captcha"
      v-if="promptCaptcha"
      :onCaptchaVerified="onCaptchaVerified"
      :onCaptchaExpired="onCaptchaExpired"
    >
    </Recaptcha>
    <BaseButton
      v-if="!emailSent"
      size="lg"
      variant="primary"
      @click="reset"
      :loading="waiting"
      data-test="button-reset"
      >Send Password Reset Link</BaseButton
    >
    <BaseButton v-if="emailSent" size="lg" variant="success" :success="true"
      >Reset Email Sent</BaseButton
    >
  </BaseForm>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import { userStore } from "../../store";
import { validateEmail } from "../../util";

import BaseButton from "../../components/BaseButton.vue";
import BaseForm from "../../components/BaseForm.vue";
import BaseInput from "../../components/BaseInput.vue";
import Recaptcha from "../../components/Recaptcha.vue";

@Component({
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
    Recaptcha,
  },
})
export default class ResetPassword extends Vue {
  emailSent = false;
  waiting = false;
  submitted = false;
  user = { resetEmail: "" };
  errorMessage = "";
  promptCaptcha = false;
  captchaResponse: string | null = null;

  reset() {
    if (this.waiting) {
      return;
    }

    this.$piwik.trackClick({ name: "Send Link" });

    if (this.checkResetFormValidation()) {
      this.waiting = true;
      userStore.actions
        .forgotPassword({
          email: this.user.resetEmail.toLowerCase(),
          captchaResponse: this.captchaResponse || "",
        })
        .then(() => {
          this.emailSent = true;
          this.waiting = false;
        })
        .catch(({ response }) => {
          const { data } = response;
          /** **TODO: Should probably return a x-capture-required
              header or something instead of just looking at
              the response message
          */
          if (data && data.message && /captcha/i.test(data.message)) {
            this.promptCaptcha = true;
          } else if (data && data.message) {
            this.errorMessage = data.message;
          }

          this.waiting = false;
        });
    }
  }

  checkResetFormValidation() {
    if (this.user.resetEmail.trim().length === 0) {
      this.errorMessage = "Please enter your email address";
    } else if (!validateEmail(this.user.resetEmail)) {
      this.errorMessage = "That email address doesn't look right";
    } else if (this.promptCaptcha && this.captchaResponse === null) {
      this.errorMessage = 'Please click "I\'m not a robot"';
    } else {
      this.errorMessage = "";
      return true;
    }
    this.waiting = false;
    this.captchaResponse = null;
  }

  onCaptchaVerified(response: string) {
    this.captchaResponse = response;
  }

  onCaptchaExpired() {
    this.captchaResponse = null;
  }

  goToLogin() {
    this.$router.push({ name: "login" });
  }
}
</script>

<style lang="scss" scoped>
.btn-success {
  pointer-events: none;
}
</style>
