<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :fullpage="fullpage"
      @submit="onSubmit"
      :class="['change-email-form', 'signup-step']"
      data-test="change-email-form"
    >
      <h1>Change Your Email</h1>
      <b-alert
        :show="errorMessage.length > 0"
        variant="danger"
        data-test="change-email-error"
      >
        {{ errorMessage }}
      </b-alert>
      <BaseInput
        label="New Email"
        placeholder="email@example.com"
        type="email"
        v-model="newEmail"
        max-length="64"
        :state="errorMessage ? false : null"
        :disabled="loading"
        data-test="change-email-input"
      >
      </BaseInput>
      <BaseButton
        type="submit"
        variant="primary"
        size="lg"
        :disabled="loading"
        :loading="loading"
        data-test="change-email-submit-button"
      >
        Change Email
      </BaseButton>
      <BaseModal
        id="change-email-success-modal"
        :no-close-on-esc="fullpage"
        :no-close-on-backdrop="fullpage"
        :hide-header="fullpage"
        :hide-header-close="fullpage"
      >
        <div class="content">
          <p>
            Success! We've emailed a confirmation link to
            <span class="user-email -bold">{{ newEmail }}</span
            >. Click the link in the email to confirm your new email address.
          </p>
          <BaseButton
            variant="success"
            size="md"
            @click="onClickDone"
            data-test="change-email-done-button"
          >
            Got it
          </BaseButton>
        </div>
      </BaseModal>
    </BaseForm>
  </SimplePage>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { validateEmail } from "@/util";
import { userStore } from "@/store";

@Component({})
export default class ChangeEmailForm extends Vue {
  @Prop({ default: false }) fullpage!: boolean;
  @Prop() modalId?: string;
  errorMessage = "";
  newEmail = "";
  success = false;
  loading = false;

  @Watch("newEmail")
  resetErrorState() {
    this.errorMessage = "";
  }

  onSubmit() {
    const user = userStore.getters.currentUser;

    if (!user) {
      this.errorMessage = "Please log in first";
      return;
    }

    if (!validateEmail(this.newEmail)) {
      this.errorMessage = "Please enter a valid email address";
      return;
    }

    if (user.email === this.newEmail) {
      this.errorMessage = "Your new email address must be different";
      return;
    }

    this.loading = true;
    this.resetErrorState();

    userStore.actions
      .changeEmail({ email: this.newEmail })
      .then(() => {
        this.success = true;
        this.loading = false;

        this.$bvModal.show("change-email-success-modal");
      })
      .catch(() => {
        this.errorMessage =
          "We couldn't change your email. Please try again later.";
        this.loading = false;
      });
  }

  onClickDone() {
    if (this.fullpage) {
      this.$router.push({
        name: "home",
      });
      return;
    }
    this.$bvModal.hide("change-email-success-modal");
    if (this.modalId) {
      this.$bvModal.hide(this.modalId);
    }
  }
}
</script>
<style lang="scss" scoped>
.signup-step.change-email-form {
  h1 {
    all: unset;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    margin-bottom: 15px;
    line-height: 1.4;
    color: $gray-800;
  }
}

.content {
  padding: 20px 10px 10px;
}

.user-email {
  color: $color-foreground;
}

.-bold {
  font-weight: bold;
}
</style>
