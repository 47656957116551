<template>
  <div
    class="account-photo"
    :class="{ '-empty': user.photo, 'small-photo': !editable }"
    data-test="account-photo-wrapper"
    v-if="user"
  >
    <div
      v-if="!user.photo && !editable"
      class="photo"
      data-test="account-photo-default-readonly"
    >
      <div
        class="placeholder"
        v-b-tooltip.hover
        :title="user.preferredName"
        :aria-label="user.preferredName"
      >
        <b-icon v-if="!loadingPhoto" icon="person" />
      </div>
    </div>
    <div
      v-else-if="user.photo && !editable"
      class="photo"
      data-test="account-photo-custom-readonly"
    >
      <img
        class="image"
        :src="`/api/v1/user/getAccountPhoto/${user.photo}`"
        alt="photo of user"
      />
    </div>
    <label v-else-if="editable" class="photo" for="account-photo-upload">
      <div
        class="placeholder"
        v-if="!user.photo"
        v-b-tooltip.hover
        title="Add Account Photo"
        data-test="account-photo-placeholder"
        aria-label="add account photo"
      >
        <b-icon v-if="!loadingPhoto" icon="camera" />
      </div>
      <img
        class="image"
        v-if="user.photo"
        :src="`/api/v1/user/getAccountPhoto/${user.photo}`"
        data-test="account-photo-image"
        :alt="`photo of ${user.preferredName}`"
      />
      <b-spinner
        small
        class="spinner"
        label="Loading..."
        v-if="loadingPhoto"
      ></b-spinner>
      <b-form-file
        id="account-photo-upload"
        class="photo-input"
        type="file"
        accept=".jpg, .png"
        v-model="file"
        @change="onClickUploadPhoto"
        data-test="account-photo-input"
      ></b-form-file>
    </label>
    <BaseButton
      v-if="user.photo && editable"
      variant="secondary"
      class="delete"
      size="sm"
      @click="onClickDeletePhoto"
      v-b-tooltip.hover
      title="Remove Photo"
      data-test="account-photo-delete"
      aria-label="remove photo"
    >
      <b-icon icon="x" size="12"></b-icon>
    </BaseButton>
  </div>
  <div v-else class="account-photo"></div>
</template>
<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import { BIcon, BIconPerson, BIconCamera, BIconX } from "bootstrap-vue";
import { Toast } from "@/mixins/Toast";
import { userStore, eventStore } from "@/store";
import { User } from "@/types/User";
import { EVENTS } from "@/types/Event";

const MAX_IMAGE_SIZE_BYTES = 10485760;

@Component({
  components: {
    BIcon,
    BIconPerson,
    BIconCamera,
    BIconX,
  },
})
export default class AccountPhoto extends Mixins(Toast) {
  @Prop({ required: true }) user!: User;
  @Prop({ default: true }) editable?: boolean;

  loadingPhoto = false;
  file?: File | null = null;

  onClickUploadPhoto(event: Event) {
    const fileUpload = (event.target as HTMLInputElement).files?.[0];

    if (!fileUpload) {
      return;
    }

    if (fileUpload.size > MAX_IMAGE_SIZE_BYTES) {
      this.errorToast("This file is too large");
    } else {
      this.loadingPhoto = true;

      userStore.actions
        .changeAccountPhoto({ file: fileUpload })
        // todo-ts: type this response
        .then((response: any) => {
          userStore.mutations.updateCurrentUser({
            photo: response?.data?.key,
          });

          eventStore.actions.record({
            name: EVENTS.SETTINGS.SET_ACCOUNT_PHOTO,
          });
        })
        .catch((err) => {
          this.errorToast(err.message || "Couldn't upload photo");
        })
        .finally(() => {
          this.loadingPhoto = false;
        });
    }
  }

  onClickDeletePhoto() {
    this.loadingPhoto = true;
    this.file = null;
    userStore.mutations.updateCurrentUser({ photo: null });
    userStore.actions
      .changeAccountPhoto({ file: null })
      .then(() => {
        eventStore.actions.record({
          name: EVENTS.SETTINGS.SET_ACCOUNT_PHOTO,
          data: { action: "Delete" },
        });
      })
      .catch((err) => {
        this.errorToast(err.message || "Couldn't delete photo");
      })
      .finally(() => {
        this.loadingPhoto = false;
      });
  }
}
</script>
<style lang="scss" scoped>
%_hover_effect {
  &:hover {
    filter: brightness(90%);
  }

  &:active {
    transition: none;
    filter: brightness(80%);
  }
}

.account-photo {
  position: relative;
  display: flex;
  align-items: stretch;
  height: 50px;
  width: 50px;

  &.small-photo {
    height: 40px;
    width: 40px;
  }

  > .photo {
    @extend %_hover_effect;

    display: flex;
    flex-grow: 1;
    overflow: hidden;
    border-radius: 100%;
    transition: filter $duration-shorter;
    margin-bottom: 0;
    cursor: pointer;
  }

  > .photo > .placeholder {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    background-color: $gray-100;
  }

  > .photo img {
    max-height: 100%;
    max-width: 100%;
  }

  > .photo > .image {
    flex-grow: 1;
    background-repeat: no-repeat;
    background-size: cover;
  }

  > .photo > .spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 20px;
    width: 20px;
  }

  > .delete {
    @extend %_hover_effect;

    position: absolute;
    top: -8px;
    right: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 26px;
    background-color: white;
    box-shadow: $box-shadow-default;
    border-radius: 100%;
    animation: $animation-fade-in-zoom;
    cursor: pointer;
  }

  &:not(:hover) > .delete {
    display: none;
  }

  > .delete {
    color: #000;
  }

  > .photo > .photo-input {
    position: absolute;
    z-index: -1;
    height: 0.1px;
    width: 0.1px;
    overflow: hidden;
    opacity: 0;
  }
}
</style>
