import { Route } from "vue-router";
import { MutationHandler } from "./vuex-typex";
import { storeBuilder } from "./storeBuilder";
import { LoginState } from "../types/Login";

const builder = storeBuilder.module<LoginState>("login", new LoginState());

type LoginMutation<Payload = void> = MutationHandler<LoginState, Payload>;

const afterAuth = builder.read((state): Route | undefined => {
  if (state.afterAuth === null) {
    return;
  }
  return state.afterAuth;
}, "afterAuth");

// Indicates that this is the user's first /home page view after login
const justLoggedIn = builder.read(
  (state) => state.justLoggedIn,
  "justLoggedIn"
);

export const getters = {
  get afterAuth() {
    return afterAuth();
  },
  get justLoggedIn() {
    return justLoggedIn();
  },
};

const setAfterAuth: LoginMutation<Route | null> = (state, afterAuthState) => {
  state.afterAuth = afterAuthState;
};

const setJustLoggedIn: LoginMutation<boolean> = (state, _justLoggedIn) => {
  state.justLoggedIn = _justLoggedIn;
};

export const mutations = {
  setAfterAuth: builder.commit(setAfterAuth),
  setJustLoggedIn: builder.commit(setJustLoggedIn),
};
