<template>
  <div class="funding-source-wrapper">
    <div
      v-if="fundingSourceIsBank"
      :class="{
        'funding-source': true,
        '-selected': isSelected,
        '-small': size === 'small',
      }"
    >
      <div class="icon">
        <BankImage :bank="bankFundingSource.bank" width="40" />
      </div>
      <div class="details">
        <p class="name" data-test="funding-source-name">
          {{ bankFundingSource.nickname || bankFundingSource.accountName }}
        </p>
        <div class="source">
          <span
            class="note -blue"
            v-if="bankFundingSource.default && !hideDefaultBadge"
            data-test="funding-source-note"
          >
            {{ bankFundingSource.default ? "Default" : "" }}
          </span>
          <span
            class="note"
            v-if="bankFundingSource.state === 'PENDING'"
            data-test="funding-source-note"
          >
            Pending
          </span>
          <div
            class="provider"
            :title="bankFundingSource.bankName || bankFundingSource.bank"
          >
            {{ bankFundingSource.bankName || bankFundingSource.bank }}
          </div>
          <div class="number">
            &middot; &middot; &middot; {{ bankFundingSource.number }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="fundingSourceIsCard"
      :class="{
        'funding-source': true,
        '-selected': isSelected,
        '-small': size === 'small',
      }"
    >
      <div class="icon">
        <SVGIcon icon="card" size="40" />
      </div>
      <div class="details">
        <p class="name" data-test="funding-source-name">
          {{ cardFundingSource.network }}
        </p>
        <div class="source">
          <span
            class="note"
            v-if="cardFundingSource.isPending"
            data-test="funding-source-note"
          >
            Pending
          </span>
          <div class="number">
            &middot; &middot; &middot; {{ cardFundingSource.lastFour }}
          </div>
        </div>
      </div>
    </div>
    <div class="checkmark" v-if="isSelected">
      <SVGIcon icon="tick" color="white" />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { FundingCard, FundingBank } from "@/types/Funding";
import BankImage from "./BankImage.vue";
import SVGIcon from "./SVGIcon.vue";

@Component({
  components: {
    BankImage,
    SVGIcon,
  },
})
export default class FundingSource extends Vue {
  @Prop({ default: false }) isSelected?: boolean;
  @Prop({ required: false }) size?: "small";
  @Prop({ required: true }) source!: FundingCard | FundingBank;
  @Prop({ default: false }) hideDefaultBadge?: boolean;

  get fundingSourceIsBank() {
    return "bank" in this.source;
  }

  get fundingSourceIsCard() {
    return !this.fundingSourceIsBank;
  }

  get bankFundingSource(): FundingBank {
    return this.source as FundingBank;
  }

  get cardFundingSource(): FundingCard {
    return this.source as FundingCard;
  }
}
</script>
<style lang="scss" scoped>
.funding-source-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.funding-source {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  max-width: 100%;
  font-size: 14px;
  font-weight: normal;

  .icon {
    display: flex;
    margin-right: 20px;
    height: 40px;
    width: 40px;
  }

  .details {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    overflow: hidden;
    line-height: 22px;

    .name {
      overflow: hidden;
      font-weight: bold;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $gray-800;
      margin-bottom: 0;
    }

    .source {
      display: flex;
      align-items: center;
      color: $gray-600;

      .note {
        margin-right: 5px;
        padding: 3px 5px;
        background-color: $gray-600;
        border-radius: $border-radius;
        font-size: 12px;
        font-weight: bold;
        line-height: 14px;
        color: $white;

        &.-blue {
          background: $blue;
        }
      }

      .provider {
        padding-right: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
        white-space: nowrap;
      }
    }

    .number {
      white-space: nowrap;
    }
  }

  .checkmark {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    height: 30px;
    width: 30px;
    background-color: $gray-800;
    border-radius: 30px;

    .svg-icon ::v-deep * {
      fill: $white;
    }
  }

  &.-small {
    .icon {
      margin-right: 5px;
      transform: scale(0.75);
    }
  }
}
</style>
