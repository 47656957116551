<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :fullpage="fullpage"
      class="account-purpose"
      data-test="account-purpose"
    >
      <h1>I'm going to use this for...</h1>
      <BaseButton
        variant="light"
        type="submit"
        size="lg"
        data-test="personal"
        @click="submit(AccountPurposes.PERSONAL)"
      >
        Myself
      </BaseButton>
      <BaseButton
        variant="light"
        type="submit"
        size="lg"
        data-test="business"
        @click="submit(AccountPurposes.BUSINESS)"
      >
        My Business
      </BaseButton>
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import { FeatureFlags } from "@/types/LaunchDarkly";
import { AccountPurposes } from "@/types/User";
import { Toast } from "@/mixins/Toast";
import { featureStore, userStore } from "@/store";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component
export default class AccountPurpose extends Mixins(Toast) {
  @Prop({ default: false }) fullpage!: boolean;

  AccountPurposes = AccountPurposes;

  submit(purpose: AccountPurposes): void {
    const eventType =
      purpose === AccountPurposes.PERSONAL ? "Myself" : "My Business";

    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.PURPOSE)} ${eventType} CTA`,
    });

    const showFullBusinessOnboarding = !!featureStore.getters.flag(
      FeatureFlags.SHOW_FULL_BUSINESS_ONBOARDING
    );

    userStore.actions
      .setAccountPurpose(purpose)
      .then(() => {
        if (purpose === AccountPurposes.BUSINESS) {
          const nextStep = showFullBusinessOnboarding
            ? OnboardingSteps.BUSINESS_TYPE
            : OnboardingSteps.BUSINESS_CONFIRM_SOLE_PROPRIETOR;
          this.$emit("complete-step", nextStep);
        } else {
          this.$emit("complete-step");
        }
      })
      .catch((err) => {
        this.errorToast(err.response?.data?.message || "There was an error");
      });
  }
}
</script>

<style lang="scss" scoped>
.account-purpose {
  h1 {
    all: unset;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    margin-bottom: 15px;
    line-height: 1.4;
    color: $gray-800;
  }
  .btn {
    margin-bottom: 1em;
  }
}
</style>
