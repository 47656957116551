import axios from "axios";
import { cardStore } from "@/store";

import { ActionHandler, MutationHandler } from "./vuex-typex";
import { RootState, storeBuilder } from "./storeBuilder";

type SharingState = {
  linkToken: string;
  email: string;
  previouslyAcceptedTerms: boolean;
  themes: {
    name: string;
    type: string;
    backgroundImage?: string;
    previewImage?: string;
  }[];
};

type SharingMutation<Payload = void> = MutationHandler<SharingState, Payload>;

const builder = storeBuilder.module<SharingState>("sharing", {
  linkToken: "",
  email: "",
  previouslyAcceptedTerms: false,
  themes: [],
});

// #region Getters

const getState = builder.read((state) => {
  return state;
}, "state");

const getLinkToken = builder.read((state) => {
  return state.linkToken;
}, "linkToken");

const getThemes = builder.read((state) => {
  return state.themes;
}, "themes");

export const getters = {
  get state() {
    return getState();
  },
  get linkToken() {
    return getLinkToken();
  },
  get themes() {
    return getThemes();
  },
};

// #endregion

// #region Mutations

const setSharingState: SharingMutation<Partial<SharingState>> = (
  state,
  props = {}
) => {
  Object.entries(props).forEach(([key, value]) => {
    // @ts-ignore
    state[key] = value;
  });
};

const setThemes: SharingMutation<any[]> = (state, themes) => {
  state.themes = themes;
};

export const mutations = {
  setSharingState: builder.commit(setSharingState),
  setThemes: builder.commit(setThemes),
};

// #endregion

type SharingAction<Payload = void, Type = any> = ActionHandler<
  SharingState,
  RootState,
  any,
  Payload,
  Type
>;

const base = "/api/v1/sharing";

const sendCodeFromEmail: SharingAction<
  string,
  {
    linkToken: string;
    email: string;
    acceptedTerms: boolean;
  }
> = async (_context, email) => {
  const { data } = await axios.post(base + "/code", { email });

  mutations.setSharingState({
    linkToken: data.linkToken,
    email: data.email,
    previouslyAcceptedTerms: data.acceptedTerms,
  });

  return data;
};

const sendCodeFromLink: SharingAction<
  string,
  {
    email: string;
    acceptedTerms: boolean;
  }
> = async (context, linkToken) => {
  const { data } = await axios.post(base + "/code", { linkToken });

  mutations.setSharingState({
    linkToken: data.linkToken,
    email: data.email,
    previouslyAcceptedTerms: data.acceptedTerms,
  });

  return data;
};

const resendCode: SharingAction<string> = (context, linkToken) => {
  return axios.post(base + "/resend-code", { linkToken });
};

const getSharedCards: SharingAction = async () => {
  const { data } = await axios.get("/api/v2/cards/shared");

  return data;
};

const shareCard: SharingAction<
  { email: string; cardUuid: string; theme?: string },
  any
> = async (context, { email, cardUuid, theme }) => {
  const response = await axios.post(`/api/v2/cards/${cardUuid}/share`, {
    email,
    theme,
  });

  cardStore.mutations.setCard(response.data);

  return response;
};

const auth: SharingAction<{
  linkToken: string;
  code: string;
  acceptedTerms: boolean;
}> = (_context, props) => {
  return axios.post(base + "/auth", props);
};

const logout: SharingAction = () => {
  return axios.post(base + "/logout");
};

const fetchThemes: SharingAction = async () => {
  if (getters.themes.length) {
    return getters.themes;
  }

  const { data } = await axios.get("/api/v2/sharing/themes");

  mutations.setThemes(data.data);

  return data.data;
};

export const actions = {
  fetchThemes: builder.dispatch(fetchThemes),
  sendCodeFromEmail: builder.dispatch(sendCodeFromEmail),
  sendCodeFromLink: builder.dispatch(sendCodeFromLink),
  resendCode: builder.dispatch(resendCode),
  getSharedCards: builder.dispatch(getSharedCards),
  shareCard: builder.dispatch(shareCard),
  auth: builder.dispatch(auth),
  logout: builder.dispatch(logout),
};
