import Vue from "vue";
import axios, { AxiosResponse } from "axios";
import ldGet from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import identity from "lodash/identity";
import { RootState, storeBuilder } from "@/store/storeBuilder";
import {
  User,
  UserState,
  NotificationSetting,
  AccountPurposes,
  AccountBalances,
  TwoFactorPreferences,
  UserAccountInfo,
} from "@/types/User";
import { FundingStep } from "@/types/Funding";
import { OnboardingSteps } from "@/types/Onboarding";
import { PERMISSION } from "@/constants";
import { pack } from "@/util";
import { UnknownComponents } from "@fingerprintjs/fingerprintjs";
import { BusinessType } from "@/types/Organization";
import { Card, SampleCard } from "@/types/Card";
import { FeatureFlags } from "@/types/LaunchDarkly";
import { MutationHandler, ActionHandler } from "./vuex-typex";
import { featureStore, subscriptionStore } from "./";

const base = "/api/v1/user";
const v2Base = "/api/v2/users";
const v2AuthBase = "/api/v2/auth";

const builder = storeBuilder.module<UserState>("user", new UserState());

type UserMutation<Payload = void> = MutationHandler<UserState, Payload>;
type UserAction<
  Payload = void,
  Type = void | AxiosResponse<any>,
> = ActionHandler<UserState, RootState, any, Payload, Type>;

function transformUser(user: User) {
  const transformed = cloneDeep(user);
  transformed.isACH = transformed?.accountType === "ACH";
  transformed.isJIT = transformed?.accountType === "JIT";
  transformed.isPrepaid = transformed?.accountType === "PREPAID";
  return transformed;
}

const currentUser = builder.read((state) => state.currentUser, "currentUser");

const accountInfo = builder.read((state) => state.accountInfo, "accountInfo");

const hasExtension = builder.read(
  (state) => state.hasExtension,
  "hasExtension"
);
const seenConfirmEmailStep = builder.read(
  (state) => state.seenConfirmEmailStep,
  "seenConfirmEmailStep"
);

const hasPendingBank = builder.read((state) => {
  const banks = state.currentUser?.bankAccountList || [];

  let hasPending = false;
  for (const bank of banks) {
    // since this function is primarily used for onboarding,
    // having any active bank will have them be "onboarded"
    if (bank.state === "ENABLED") {
      return false;
    }

    if (bank.state === "PENDING") {
      hasPending = true;
    }
  }

  return hasPending;
}, "hasPendingBank");

const hasPendingFundingCard = builder.read((state) => {
  return !!(state.currentUser?.fundingCardList || []).some(
    (card) => card.state === "PENDING"
  );
}, "hasPendingFundingCard");

const userToken = builder.read((state) => {
  return state.userToken;
}, "userToken");

const oneTimeCode = builder.read((state) => {
  return state.oneTimeCode;
}, "oneTimeCode");

const hasActiveFundingSource = builder.read((state) => {
  const sources = [
    ...(state.currentUser?.bankAccountList || []),
    ...(state.currentUser?.fundingCardList || []),
  ];

  return !!sources.reduce(
    (prev, current) => prev || current.state === "ENABLED",
    false
  );
}, "hasActiveFundingSource");

const fundingStep = builder.read((state) => state.fundingStep, "fundingStep");

const isLoggedIn = builder.read(
  (state) =>
    state.currentUser
      ? Object.prototype.hasOwnProperty.call(state.currentUser, "role")
      : null,
  "isLoggedIn"
);

const hasPermission = builder.read(
  (state) => (perm: PERMISSION) => {
    return (state.currentUser?.permissions || []).includes(perm);
  },
  "hasPermission"
);

const ssnVerified = builder.read((state) => {
  const user = state.currentUser;
  return user?.hasFullSsn || user?.hasBlockscoreSsn || user?.canBypassSsn;
}, "ssnVerified");

const isPendingManualPaymentsApproval = builder.read((state) => {
  const user = state.currentUser;
  return user?.manualApplicationSentTime && !user?.hasManualPayments;
}, "isPendingManualPaymentsApproval");

const getBusinessInfoStep = (user: User) => {
  if (
    user.organization?.businessType === BusinessType.PRIVATE_COMPANY ||
    user.organization?.businessType === BusinessType.NONPROFIT
  ) {
    if (!user.organization?.detailsComplete) {
      return OnboardingSteps.BUSINESS_DETAILS;
    }

    if (
      user.organization?.businessType === BusinessType.PRIVATE_COMPANY &&
      !user.organization?.ownersComplete
    ) {
      return OnboardingSteps.BUSINESS_EXECS;
    }

    if (!user.organization?.useCaseComplete) {
      return OnboardingSteps.BUSINESS_USE;
    }
  }
};

const nextOnboardingStep = builder.read(
  (state, getters) =>
    (
      isAddingFunding = false,
      isSwitchingToAutomatic = false
    ): OnboardingSteps => {
      const user = state.currentUser;

      if (!user?._id) {
        return OnboardingSteps.BLOCKED;
      }

      if (getters.isPendingManualPaymentsApproval && !isSwitchingToAutomatic) {
        return OnboardingSteps.BLOCKED;
      }

      if (
        !user.hasHadAnyFundingSource &&
        !user.accountPurpose &&
        !user.manualApplicationSentTime
      ) {
        return OnboardingSteps.PURPOSE;
      }

      // Gather business type, redirect public companies to enterprise contact form
      if (
        user.accountPurpose === AccountPurposes.BUSINESS &&
        !user.hasHadAnyFundingSource
      ) {
        if (
          featureStore.getters.flag(
            FeatureFlags.SHOW_FULL_BUSINESS_ONBOARDING
          ) ||
          user.isPendingBusinessInfo
        ) {
          if (
            !user.organization?.typeComplete ||
            user.organization?.businessType === BusinessType.PUBLIC_COMPANY ||
            user.organization?.businessType === BusinessType.OTHER
          ) {
            return OnboardingSteps.BUSINESS_TYPE;
          }
        } else if (!user.organization?.typeComplete) {
          return OnboardingSteps.BUSINESS_CONFIRM_SOLE_PROPRIETOR;
        } else if (
          user.organization?.businessType !== BusinessType.INDIVIDUAL
        ) {
          return OnboardingSteps.BUSINESS_NOT_SOLE_PROPRIETOR;
        }
      }

      // prevent legacy/mobile users from being re-prompted for info
      if (!(user.KYCVerified && user.hasHadAnyFundingSource)) {
        if (!user.firstName || !user.lastName || !user.dob) {
          return OnboardingSteps.BASIC_INFO;
        }

        if (!user.address1 || !user.zipcode) {
          return OnboardingSteps.BILLING_ADDRESS;
        }

        if (!user.ssnLast4 && !getters.ssnVerified) {
          return OnboardingSteps.SSN_LAST_FOUR;
        }

        if (!user.hasConfirmedDetails) {
          return OnboardingSteps.REVIEW_DETAILS;
        }
      }

      if (!user.phoneLastFour) {
        return OnboardingSteps.PHONE;
      }

      if (!user.phoneVerified) {
        return OnboardingSteps.PHONE_CONFIRM;
      }

      if (!user.hasHadAnyFundingSource && !getters.ssnVerified) {
        return OnboardingSteps.VERIFY_SSN;
      }

      if (user.accountPurpose === AccountPurposes.BUSINESS) {
        const step = getBusinessInfoStep(user);
        if (step !== undefined) {
          return step;
        }
      }

      if (getters.hasPendingBank) {
        return OnboardingSteps.CONFIRM_BANK;
      }

      if (getters.hasPendingFundingCard) {
        return OnboardingSteps.CONFIRM_CARD;
      }

      if (!user.hasFundingSource || isAddingFunding) {
        if (
          user.hasManualPayments ||
          subscriptionStore.getters.planRequiresOrganization ||
          user.isPendingBusinessInfo
        ) {
          return OnboardingSteps.ADD_BANK;
        }

        if (user.isPrepaid) {
          return OnboardingSteps.ADD_CARD;
        }

        if (
          (!user.hasHadAnyFundingSource &&
            user.accountPurpose !== AccountPurposes.BUSINESS) ||
          getters.isPendingManualPaymentsApproval
        ) {
          return OnboardingSteps.FUNDING_TYPE_CHARGE;
        }

        if (user.accountPurpose === AccountPurposes.BUSINESS) {
          return OnboardingSteps.FUNDING_TYPE_BUSINESS;
        }

        return OnboardingSteps.FUNDING_TYPE;
      }

      if (!user?.acceptedDisclosure) {
        return OnboardingSteps.LEGACY_DISCLOSURES;
      }

      if (
        (user.accountPurpose === AccountPurposes.PERSONAL ||
          user.hasManualPayments) &&
        !user.chargeTermsAcceptTime
      ) {
        return OnboardingSteps.CHARGE_DISCLOSURE;
      }

      if (
        user.accountPurpose === AccountPurposes.BUSINESS &&
        !user.commercialChargeTermsAcceptTime
      ) {
        return OnboardingSteps.COMMERCIAL_CHARGE_DISCLOSURE;
      }

      if (
        user.hasHadAnyFundingSource &&
        !user.accountPurpose &&
        !user.chargeTermsAcceptTime &&
        !user.hasManualPayments
      ) {
        if (!getters.ssnVerified) {
          return OnboardingSteps.EXISTING_USER_VERIFY_SSN;
        }

        return OnboardingSteps.EXISTING_USER_CHARGE_DISCLOSURE;
      }

      // Teams flow
      if (
        (subscriptionStore.getters.newPlanRequiresOrganization &&
          !user.organization?.active) ||
        user.isPendingBusinessInfo
      ) {
        if (!user.organization?.typeComplete) {
          return OnboardingSteps.BUSINESS_TYPE;
        }
        const step = getBusinessInfoStep(user);
        if (step !== undefined) {
          return step;
        }
      }

      if (subscriptionStore.getters.isSubscribing()) {
        return OnboardingSteps.BILLING;
      }

      return OnboardingSteps.COMPLETE;
    },
  "nextOnboardingStep"
);

const nextRemediationStep = builder.read(
  () =>
    (remediationResult: string): OnboardingSteps | undefined => {
      if (remediationResult === "needs-ssn") {
        return OnboardingSteps.EXISTING_USER_VERIFY_SSN;
      } else if (remediationResult === "verified") {
        return OnboardingSteps.REMEDIATION_SUCCESS;
      } else if (remediationResult === "pending") {
        return OnboardingSteps.REMEDIATION_PENDING;
      } else if (remediationResult === "failed") {
        return OnboardingSteps.REMEDIATION_FAILURE;
      } else if (remediationResult === "retry") {
        return OnboardingSteps.REMEDIATION_RETRY;
      }
    },
  "nextRemediationStep"
);

const prepaidBalanceAmount = builder.read((state) => {
  return (
    (parseFloat(state.currentUser?.accountBalances?.available || "0") -
      parseFloat(state.currentUser?.accountBalances?.promo || "0")) /
    100
  );
}, "prepaidBalanceAmount");

const defaultAccountBalances = builder.read(
  (): AccountBalances => ({
    available: "0",
    promo: "0",
    cash: "0",
    pendingAuth: "0",
    ach: "0",
  }),
  "defaultAccountBalances"
);

const shouldShowAccountPurpose = builder.read((state) => {
  const user = state.currentUser;
  if (!user) return false;
  return (
    !user.accountPurpose &&
    !user.hasFundingSource &&
    !user.manualApplicationSentTime
  );
}, "shouldShowAccountPurpose");

const authMessage = builder.read((state) => {
  return state.authMessage;
}, "authMessage");

export const getters = {
  get currentUser() {
    return currentUser();
  },
  get accountInfo() {
    return accountInfo();
  },
  get hasExtension() {
    return hasExtension();
  },
  get isLoggedIn() {
    return isLoggedIn();
  },
  get seenConfirmEmailStep() {
    return seenConfirmEmailStep();
  },
  get hasPendingBank() {
    return hasPendingBank();
  },
  get hasPendingFundingCard() {
    return hasPendingFundingCard();
  },
  get hasActiveFundingSource() {
    return hasActiveFundingSource();
  },
  get fundingStep() {
    return fundingStep();
  },
  get userToken() {
    return userToken();
  },
  get oneTimeCode() {
    return oneTimeCode();
  },
  get hasPermission() {
    return hasPermission();
  },
  get ssnVerified() {
    return ssnVerified();
  },
  get isPendingManualPaymentsApproval() {
    return isPendingManualPaymentsApproval();
  },
  get nextOnboardingStep() {
    return nextOnboardingStep();
  },
  get nextRemediationStep() {
    return nextRemediationStep();
  },
  get prepaidBalanceAmount() {
    return prepaidBalanceAmount();
  },
  get defaultAccountBalances() {
    return defaultAccountBalances();
  },
  get shouldShowAccountPurpose() {
    return shouldShowAccountPurpose();
  },
  get authMessage() {
    return authMessage();
  },
};

const setCurrentUser: UserMutation<User> = (state, user) => {
  state.currentUser = transformUser(user);
};

const updateCurrentUser: UserMutation<Partial<User>> = (state, updates) => {
  state.currentUser = Object.assign({}, state.currentUser, updates);
};

const clearCurrentUser: UserMutation = (state) => {
  state.currentUser = null;
  state.userToken = null;
  state.oneTimeCode = null;
  state.authMessage = null;
  state.accountInfo = null;
};

const setHasExtension: UserMutation<boolean> = (state, userHasExtension) => {
  state.hasExtension = userHasExtension;
};

const setConfirmEmailStep: UserMutation<boolean> = (state, value) => {
  state.seenConfirmEmailStep = value;
};

const setFundingStep: UserMutation<FundingStep> = (state, value) => {
  state.fundingStep = value;
};

const setUserToken: UserMutation<string> = (state, value) => {
  state.userToken = value;
};

const setOneTimeCode: UserMutation<string> = (state, value) => {
  state.oneTimeCode = value;
};

const setAuthMessage: UserMutation<string | null> = (state, value) => {
  state.authMessage = value;
};

const setAccountInfo: UserMutation<UserAccountInfo | null> = (state, value) => {
  state.accountInfo = value;
};

export const mutations = {
  setCurrentUser: builder.commit(setCurrentUser),
  updateCurrentUser: builder.commit(updateCurrentUser),
  clearCurrentUser: builder.commit(clearCurrentUser),
  setHasExtension: builder.commit(setHasExtension),
  setConfirmEmailStep: builder.commit(setConfirmEmailStep),
  setFundingStep: builder.commit(setFundingStep),
  setUserToken: builder.commit(setUserToken),
  setOneTimeCode: builder.commit(setOneTimeCode),
  setAuthMessage: builder.commit(setAuthMessage),
  setAccountInfo: builder.commit(setAccountInfo),
};

function fetchCurrentUser() {
  let request: any = null;

  return function fetchUser() {
    if (!request) {
      request = axios
        .get<User>(base + "/me")
        .then((response) => {
          if (ldGet<any, string>(response, "data._id")) {
            mutations.setCurrentUser(response.data);
          }
        })
        .finally(() => {
          request = null;
        });
    }

    return request;
  };
}

const getCurrentUser = fetchCurrentUser();

const forgotPassword: UserAction<{
  email: string;
  captchaResponse: string;
}> = (context, { email, captchaResponse }) => {
  return axios.post(`${base}/forgot`, { email, captchaResponse });
};

const resetPassword: UserAction<{
  id: string;
  newPassword: string;
  resetToken: string;
}> = (context, { id, newPassword, resetToken }) => {
  return axios.post(`${base}/${id}/reset/`, { newPassword, resetToken });
};

const create: UserAction<{
  email: string;
  password: string;
  browser: string;
  captchaResponse: string;
}> = (context, { email, password, browser, captchaResponse }) => {
  return axios
    .post(base, { email, password, browser, captchaResponse })
    .then(({ data }) => {
      Vue.$cookies.set("token", data.token);
      return getCurrentUser();
    });
};

const changePassword: UserAction<{
  oldPassword: string;
  newPassword: string;
}> = (context, { oldPassword, newPassword }) => {
  return axios.put(`${base}/password`, { oldPassword, newPassword });
};

const updateUser: UserAction<Partial<User>> = async (_context, updates) => {
  return axios.patch(v2Base, updates).then((response) => {
    mutations.updateCurrentUser(response.data.user);
    return response;
  });
};

const uploadID: UserAction<{
  files: File[];
  acceptedOnfidoDisclosure: boolean;
}> = (context, { files, acceptedOnfidoDisclosure }) => {
  const formData = new FormData();
  files.forEach((f) => {
    formData.append("file", f);
  });
  formData.append(
    "acceptedOnfidoDisclosure",
    acceptedOnfidoDisclosure.toString()
  );
  return axios.post(base + "/verify", formData);
};

const login: UserAction<{
  email: string;
  password: string;
  extensionInstalled: boolean;
  captchaResponse: string;
}> = (_context, user) => {
  return axios.post(`${v2AuthBase}/login`, user).then(({ data }) => {
    // Some folks have 2FA so an auth token may not be returned.
    // Only get the current users if an auth token was returned.
    if (data.token) {
      Vue.$cookies.set("token", data.token);
    }
    if (data.userToken) {
      mutations.setUserToken(data.userToken);
    }
    if (data.oneTimeCode) {
      mutations.setOneTimeCode(data.oneTimeCode);
    }
    if (data.twoFactorAuth) {
      mutations.updateCurrentUser({
        twoFactorAuth: data.twoFactorAuth,
      });
    }
    if (data.message) {
      mutations.setAuthMessage(data.message);
    }

    return data;
  });
};

const sendVerificationCode: UserAction<{ userToken: string }> = (
  context,
  { userToken: _userToken }
) => {
  // userToken is optional. for sending a verifcation code during tfa recovery.
  return axios.post(base + "/sendVerifySms", { userToken: _userToken });
};

const resendOneTimeCode: UserAction<{ userToken: string }> = (
  context,
  { userToken: _userToken }
) => {
  // userToken is optional. for sending a verifcation code during tfa recovery.
  return axios.post(`${v2AuthBase}/tfa/resend`, { userToken: _userToken });
};

const TFALogin: UserAction<{
  token?: string;
  code?: string;
  userToken: string;
  rememberDevice: boolean;
}> = (_context, body) => {
  return axios.post(`${v2AuthBase}/login/tfa`, body).then(({ data }) => {
    // Some folks have 2FA so an auth token may not be returned.
    // Only get the current users if an auth token was returned.
    if (data.token) {
      Vue.$cookies.set("token", data.token);
    }

    mutations.setAuthMessage(null);

    return data;
  });
};

const oneTimeCodeLogin: UserAction<{
  token: string;
  userToken: string;
  rememberDevice: boolean;
}> = (context, { token, userToken: _userToken, rememberDevice }) => {
  return axios
    .post("/auth/local/code", {
      code: token,
      userToken: _userToken,
      rememberDevice,
    })
    .then(({ data }) => {
      // Successful response, save the authtoken in le cookie for future calls
      if (data.token) {
        Vue.$cookies.set("token", data.token);
      }

      mutations.setAuthMessage(null);

      return data;
    });
};

const removeTFA: UserAction<{
  userToken: string;
  code: string;
}> = (context, { userToken: _userToken, code: verificationCode }) => {
  return axios.post(`${v2AuthBase}/tfa/reset`, {
    userToken: _userToken,
    verificationCode,
  });
};

const getTfaSeed: UserAction<void, string> = async () => {
  const { data } = await axios.post<void, AxiosResponse<{ seed: string }>>(
    `${v2AuthBase}/tfa/seed`
  );

  return data.seed;
};
const setupTfa: UserAction<
  string,
  Pick<User, "tfaPreference" | "twoFactorAuth">
> = async (_context, tfaResponse) => {
  const { data } = await axios.post(`${v2AuthBase}/tfa/validate`, {
    tfaResponse,
  });

  const update = {
    tfaPreference: data.tfaPreference,
    twoFactorAuth: data.twoFactorAuth,
  };

  mutations.updateCurrentUser(update);

  return update;
};

const changeEmail: UserAction<{ email: string }> = (context, { email }) => {
  return axios.put(`${base}/email`, { email }).then((response) => {
    mutations.updateCurrentUser({
      email,
      emailConfirmed: false,
    });
    return response;
  });
};

const resendConfirmationEmail: UserAction = () => {
  return axios.put(`${base}/email/resend-confirmation`);
};

const recordInstall: UserAction = () => {
  return axios.post(`${base}/extension/install`);
};

const changeWebhookUri: UserAction<{
  uri: string;
  environment: "production" | "sandbox";
}> = (context, { uri, environment }) => {
  return axios.post(base + `/publicapi/${environment}/webhook`, {
    uri,
  });
};

const verifyFullSsn: UserAction<{
  ssn: string;
}> = (context, { ssn }) => {
  return axios
    .put(base + "/ssn", {
      ssn,
    })
    .then((response) => {
      mutations.updateCurrentUser({ hasFullSsn: true });
      return response;
    });
};

const basicInfo: UserAction<Partial<User>> = (context, userInfo) => {
  return axios.post(`${base}/basic-info`, userInfo).then((response) => {
    const {
      preferredFirstName,
      preferredName,
      firstName,
      middleName,
      lastName,
      dob,
    } = response.data;
    mutations.updateCurrentUser({
      preferredFirstName,
      preferredName,
      firstName,
      middleName,
      lastName,
      dob,
    });
    return response;
  });
};

const address: UserAction<Partial<User>> = (context, addressInfo) => {
  return axios.post(`${base}/address`, addressInfo).then((response) => {
    const { address1, address2, zipcode, city, state } = response.data;
    mutations.updateCurrentUser({
      address1,
      address2,
      zipcode,
      city,
      state,
    });
    return response;
  });
};

const ssnLastFour: UserAction<Partial<User>> = (context, userInfo) => {
  return axios.post(`${base}/ssn-last-four`, userInfo).then((response) => {
    mutations.updateCurrentUser({
      ssnLast4: response.data.ssnLast4,
    });
    return response;
  });
};

const confirmDetails: UserAction = () => {
  return axios.post(`${base}/confirm-details`).then((response) => {
    mutations.updateCurrentUser({
      hasConfirmedDetails: true,
    });
    return response;
  });
};

const kyc: UserAction<Partial<User>> = (context, user) => {
  return axios.post(`${base}/kyc`, user).then((response) => {
    mutations.updateCurrentUser({
      KYCVerified: true,
      preferredFirstName: user.preferredFirstName,
      firstName: user.firstName,
      lastName: user.lastName,
      address1: user.address1,
      address2: user.address2,
      zipcode: user.zipcode,
    });
    return response;
  });
};

const phoneSetup: UserAction<string> = (context, phone) => {
  return axios.post(`${base}/phone/setup`, { phone }).then((response) => {
    mutations.updateCurrentUser({
      phoneLastFour: phone.substring(phone.length - 4),
      phoneVerified: false,
    });
    return response;
  });
};

const verifyPhone: UserAction<string> = (context, code) => {
  return axios
    .post(`${base}/phone/verify`, { verificationCode: code })
    .then((response) => {
      mutations.updateCurrentUser({
        hasBlockscoreSsn: !response.data?.needsFullSsn,
        phoneVerified: true,
      });
      return response;
    });
};

const changeAccountPhoto: UserAction<{ file: File | null }> = (
  context,
  { file }
) => {
  const formData = new FormData();
  if (file !== null) {
    formData.append("uploadFile", file);
  }
  return axios.post(`${base}/accountPhoto`, formData, {
    transformRequest: identity,
    // setting the content-type to undefined makes the
    // browser fill in the correct content-type and boundaries
    // without it this line it will default to "application/json;charset=utf-8"
    // with no boundaries set and the upload will fail.
    headers: { "Content-Type": undefined },
  });
};

const applyPromo: UserAction<{ promoCode: string }> = (
  context,
  { promoCode }
) => {
  return axios.post(`${base}/applyPromo`, { promoCode });
};

const getIntegrationApiKey: UserAction<
  {
    integration: string;
    fullName: string;
    pubkey: string;
  },
  AxiosResponse
> = (context, { integration, fullName, pubkey }) => {
  return axios.post(`${base}/integrationapi/${integration}/key`, {
    fullName,
    pubkey,
  });
};

const revokeIntegrationApiKey: UserAction<
  { integration: string },
  AxiosResponse
> = (context, { integration }) => {
  return axios.delete(`${base}/integrationapi/${integration}/key`);
};

const setTransactionNotifications: UserAction<{
  notificationTypes: NotificationSetting;
}> = (context, { notificationTypes }) => {
  return axios.post(`${base}/notifications`, { notificationTypes });
};

const acceptDisclosure: UserAction = () => {
  return axios.post(`${base}/acceptDisclosure`).then((response) => {
    mutations.updateCurrentUser({
      acceptedDisclosure: true,
    });
    return response;
  });
};

const setAccountPurpose: UserAction<AccountPurposes | null> = (
  context,
  purpose: AccountPurposes | null
) => {
  return axios.put(`${base}/purpose`, { purpose }).then((response) => {
    mutations.updateCurrentUser({
      accountPurpose: purpose,
    });
    return response;
  });
};

const chargeCardAgree: UserAction = () => {
  return axios.post(`${base}/charge-card-agree`).then((response) => {
    const now = new Date();
    mutations.updateCurrentUser({
      chargeTermsAcceptTime: now,
    });
    return response;
  });
};

const commercialChargeCardAgree: UserAction = () => {
  return axios.post(`${base}/commercial-charge-card-agree`).then((response) => {
    const now = new Date();
    mutations.updateCurrentUser({
      commercialChargeTermsAcceptTime: now,
    });
    return response;
  });
};

const updateRemediationStep: UserAction<OnboardingSteps> = (context, step) => {
  const stringStep = OnboardingSteps[step];
  return axios
    .put(`${base}/remediation-step`, { step: stringStep })
    .then((response) => {
      mutations.updateCurrentUser({
        currentRemediationStep: stringStep,
      });
      return response;
    });
};

const checkRemediation: UserAction<void, AxiosResponse> = () => {
  return axios.post(`${base}/remediation-check`).then((response) => {
    const needsSsn = response.data?.result === "needs-ssn";
    const finishedRemediation = ["verified", "failed", "pending"].includes(
      response.data?.result
    );
    mutations.updateCurrentUser({
      hasBlockscoreSsn: !needsSsn,
      isRemediatingKYC: !finishedRemediation,
    });
    return response;
  });
};

const checkRemediationID: UserAction<void, AxiosResponse> = () => {
  return axios.post(`${base}/remediation-check-id`).then((response) => {
    const finishedRemediation = ["verified", "failed", "pending"].includes(
      response.data?.result
    );
    mutations.updateCurrentUser({
      isRemediatingKYC: !finishedRemediation,
    });
    return response;
  });
};

const toggleApi: UserAction<void, AxiosResponse> = () => {
  return axios.post(`${base}/publicapi/toggle`);
};

const toggleWebhooks: UserAction<string, AxiosResponse> = (
  context,
  environment
) => {
  return axios.post(`${base}/publicapi/${environment}/webhook/toggle`);
};

const newPublicApiKey: UserAction<string, AxiosResponse> = (
  context,
  environment
) => {
  return axios.post(`${base}/publicapi/${environment}/key`);
};

const manualPaymentsApply: UserAction = () => {
  return axios.post(`${base}/manual-payments/apply`).then((response) => {
    const now = new Date();
    mutations.updateCurrentUser({
      manualApplicationSentTime: now,
      applicationDeclined: true,
      verificationNeeded: false,
    });
    return response;
  });
};

const automaticPaymentsEnroll: UserAction = () => {
  return axios.post(`${base}/automatic-payments/enroll`).then((response) => {
    mutations.updateCurrentUser({
      manualApplicationSentTime: undefined,
      applicationDeclined: false,
    });
    return response;
  });
};

const fingerprint: UserAction<{
  hash: string;
  properties: UnknownComponents;
}> = (context, { hash, properties }) => {
  // Properties is encoded as a base64 JSON string. Don't want the skript kiddy
  // scammers to know we're fingerprinting their browser
  const payload = hash + "" + pack(properties);
  return axios.post(`${base}/fp`, { payload: payload });
};

const ice: UserAction<{ candidates: string[] }> = (context, { candidates }) => {
  return axios.post(`${base}/ice`, { candidates: pack(candidates) });
};

const getOnfidoSDKToken: UserAction<void, { sdkToken: string }> = () => {
  return axios
    .post(`${base}/onfido-sdk-token`)
    .then((response) => response.data);
};

const runOnfidoChecks = (_context: any, documentData: Record<string, any>) => {
  return axios.post(`${base}/onfido-checks`, { documentData });
};

const setHasSeenPostMigrationNotice: UserAction = () => {
  return axios
    .post(`${base}/has-seen-post-migration-notice`)
    .then((response) => {
      mutations.updateCurrentUser({
        hasSeenPostMigrationNotice: true,
      });
      return response;
    });
};

const createSampleCards: UserAction<
  SampleCard | SampleCard[],
  { cards: Card[]; error?: string }
> = (_context, cards) => {
  if (!Array.isArray(cards)) {
    cards = [cards];
  }

  return axios.post(`${base}/samplecards`, { cards }).then(({ data }) => {
    mutations.updateCurrentUser({ sampleCard: data.cards });
    return data;
  });
};

const updateSampleCard: UserAction<SampleCard, SampleCard> = (
  _context,
  sampleCard
) => {
  return axios.put(`${base}/samplecards`, { sampleCard }).then(({ data }) => {
    mutations.updateCurrentUser({ sampleCard: data });
    return data;
  });
};

const closeAccount: UserAction<
  { password: string; message: string; subject?: string },
  void
> = (_context, { password, message, subject = "Self Serve Close Account" }) => {
  return axios.post(`${v2Base}/close-account`, {
    password,
    message,
    subject,
  });
};

const updateTfaPreference: UserAction<
  TwoFactorPreferences,
  Pick<User, "tfaPreference" | "twoFactorAuth">
> = async (_context, tfaPreference) => {
  const { data } = await axios.patch(`${v2AuthBase}/tfa`, {
    tfaPreference,
  });

  const update = {
    tfaPreference: data.tfaPreference,
    twoFactorAuth: data.twoFactorAuth,
  };

  mutations.updateCurrentUser(update);

  return update;
};

const getAccountInfo: UserAction<void, UserAccountInfo> = async () => {
  const { data } = await axios.get(`${v2Base}/account-info`);

  mutations.setAccountInfo(data);

  return data;
};

const setHideMerchantDescriptor: UserAction<boolean, void> = async (
  _context,
  shouldHideMerchantDescriptor
) => {
  return axios.post(`${v2Base}/hide-merchant-descriptor`, {
    shouldHideMerchantDescriptor,
  });
};

const updateVerificationStep: UserAction<string, void> = async (
  _context,
  currentStep
) => {
  return axios.patch(
    `/api/v2/kyb-verification-users/${getters.currentUser?._id}`,
    {
      currentStep: currentStep,
    }
  );
};

const startVerification: UserAction<void, void> = async () => {
  return axios.post(
    `/api/v2/kyb-verification-users/${getters.currentUser?._id}/start`
  );
};

export const actions = {
  getCurrentUser: builder.dispatch(getCurrentUser, "getCurrentUser"),
  forgotPassword: builder.dispatch(forgotPassword),
  resetPassword: builder.dispatch(resetPassword),
  create: builder.dispatch(create),
  changePassword: builder.dispatch(changePassword),
  updateUser: builder.dispatch(updateUser),
  uploadID: builder.dispatch(uploadID),
  getTfaSeed: builder.dispatch(getTfaSeed),
  setupTfa: builder.dispatch(setupTfa),
  changeEmail: builder.dispatch(changeEmail),
  resendConfirmationEmail: builder.dispatch(resendConfirmationEmail),
  login: builder.dispatch(login),
  sendVerificationCode: builder.dispatch(sendVerificationCode),
  resendOneTimeCode: builder.dispatch(resendOneTimeCode),
  removeTFA: builder.dispatch(removeTFA),
  oneTimeCodeLogin: builder.dispatch(oneTimeCodeLogin),
  TFALogin: builder.dispatch(TFALogin),
  changeWebhookUri: builder.dispatch(changeWebhookUri),
  recordInstall: builder.dispatch(recordInstall),
  verifyFullSsn: builder.dispatch(verifyFullSsn),
  kyc: builder.dispatch(kyc),
  phoneSetup: builder.dispatch(phoneSetup),
  verifyPhone: builder.dispatch(verifyPhone),
  changeAccountPhoto: builder.dispatch(changeAccountPhoto),
  applyPromo: builder.dispatch(applyPromo),
  getIntegrationApiKey: builder.dispatch(getIntegrationApiKey),
  revokeIntegrationApiKey: builder.dispatch(revokeIntegrationApiKey),
  setTransactionNotifications: builder.dispatch(setTransactionNotifications),
  acceptDisclosure: builder.dispatch(acceptDisclosure),
  setAccountPurpose: builder.dispatch(setAccountPurpose),
  toggleApi: builder.dispatch(toggleApi),
  toggleWebhooks: builder.dispatch(toggleWebhooks),
  newPublicApiKey: builder.dispatch(newPublicApiKey),
  manualPaymentsApply: builder.dispatch(manualPaymentsApply),
  automaticPaymentsEnroll: builder.dispatch(automaticPaymentsEnroll),
  chargeCardAgree: builder.dispatch(chargeCardAgree),
  commercialChargeCardAgree: builder.dispatch(commercialChargeCardAgree),
  updateRemediationStep: builder.dispatch(updateRemediationStep),
  checkRemediation: builder.dispatch(checkRemediation),
  checkRemediationID: builder.dispatch(checkRemediationID),
  fingerprint: builder.dispatch(fingerprint),
  ice: builder.dispatch(ice),
  saveBasicInfo: builder.dispatch(basicInfo),
  saveAddress: builder.dispatch(address),
  saveSsnLastFour: builder.dispatch(ssnLastFour),
  confirmDetails: builder.dispatch(confirmDetails),
  getOnfidoSDKToken: builder.dispatch(getOnfidoSDKToken),
  runOnfidoChecks: builder.dispatch(runOnfidoChecks),
  setHasSeenPostMigrationNotice: builder.dispatch(
    setHasSeenPostMigrationNotice
  ),
  createSampleCards: builder.dispatch(createSampleCards),
  updateSampleCard: builder.dispatch(updateSampleCard),
  closeAccount: builder.dispatch(closeAccount),
  updateTfaPreference: builder.dispatch(updateTfaPreference),
  getAccountInfo: builder.dispatch(getAccountInfo),
  setHideMerchantDescriptor: builder.dispatch(setHideMerchantDescriptor),
  updateVerificationStep: builder.dispatch(updateVerificationStep),
  startVerification: builder.dispatch(startVerification),
};
