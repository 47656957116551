<template>
  <SimplePage :fullpage="true">
    <BaseForm @submit="signup" :fullpage="true" data-test="signup-form">
      <h3 class="title">{{ signupTitle }}</h3>
      <b-alert
        variant="danger"
        :show="errorMessage.length > 0"
        data-test="alert-email-error"
      >
        {{ errorMessage }}
      </b-alert>
      <BaseInput
        label="Email"
        placeholder="Your Email Address"
        type="email"
        maxlength="128"
        :autofocus="true"
        v-model="email"
        data-test="email"
        :state="emailState"
      >
      </BaseInput>
      <BaseInput
        label="Password"
        placeholder="Something Secure"
        type="password"
        maxlength="128"
        v-model="password"
        :disabled="loading"
        data-test="password"
        :state="passwordState"
        :aria-label="passwordFeedback"
      >
        <PasswordStrengthMeter
          :password="password"
          :email="email"
          @update="passwordValidityCheck"
        />
      </BaseInput>
      <DisclosureCheckbox v-model="agreed" :agreementError="agreementError" />
      <div class="recaptcha-container">
        <Recaptcha
          v-if="promptCaptcha"
          :onCaptchaVerified="onCaptchaVerified"
          :onCaptchaExpired="onCaptchaExpired"
        />
      </div>
      <BaseButton
        type="submit"
        variant="primary"
        size="lg"
        :loading="loading"
        data-test="signup"
        @click="() => $piwik.trackClick({ name: 'Sign Up Primary CTA' })"
      >
        Get Started
      </BaseButton>
      <BaseButton variant="link" @click="personalSignUp" v-if="isSubscribing">
        Sign up for Privacy Personal
      </BaseButton>
      <div class="log-in-container">
        <p>Already have an account?</p>
        &nbsp;
        <router-link to="/login">Log in</router-link>.
      </div>
    </BaseForm>
  </SimplePage>
</template>
<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import PasswordStrengthMeter, {
  PasswordStrengthMixin,
} from "@/components/PasswordStrengthMeter.vue";
import Recaptcha from "@/components/Recaptcha.vue";
import DisclosureCheckbox from "@/components/DisclosureCheckbox.vue";
import { validateEmail } from "@/util";
import { featureStore, subscriptionStore, userStore } from "@/store";
import { OnboardingSteps } from "@/types/Onboarding";
import { isValidToken } from "@/router/navigation-guard";

const THIRTY_DAYS = 1000 * 60 * 60 * 24 * 30;

@Component({
  components: {
    Recaptcha,
    PasswordStrengthMeter,
    DisclosureCheckbox,
  },
})
export default class Signup extends Mixins(PasswordStrengthMixin) {
  signupTitle = "";
  loading = false;

  email = this.$route.query.email
    ? atob(this.$route.query.email as string)
    : "";
  password = "";

  agreed = false;

  errorMessage = "";
  emailState: boolean | null = null;
  passwordState: boolean | null = null;
  agreementError = false;

  isSubscribing = false;
  promptCaptcha = false;
  captchaResponse: string | null = null;

  passwordValidityCheck(event: any) {
    this.updatePasswordValidity(event);

    if (this.passwordValid) {
      this.passwordState = null;

      if (this.emailState === null) {
        this.errorMessage = "";
      }
    }
  }

  onCaptchaVerified(response: string) {
    this.captchaResponse = response;
  }

  onCaptchaExpired() {
    this.captchaResponse = null;
  }

  async created() {
    if (
      isValidToken(this.$cookies.get("token")) ||
      userStore.getters.isLoggedIn
    ) {
      let route = "home";

      if (this.$cookies.isKey("planType")) {
        route = `onboarding-${OnboardingSteps.BILLING}`;
      }

      return this.$router.replace({ name: route });
    }

    // Influencer campaigns
    const { campaign } = this.$route.query;

    // Generic marketing campaigns
    const marketingCampaign = this.$route.query.lc;

    if (campaign) {
      if (campaign === "1password") {
        this.$cookies.set("subscriptionPromo", { name: "1Password" });
      } else {
        this.$cookies.set(
          "influencerCampaign",
          campaign,
          new Date(Date.now() + THIRTY_DAYS)
        );
      }
    }

    if (marketingCampaign) {
      this.$cookies.set(
        "marketingCampaign",
        marketingCampaign,
        new Date(Date.now() + THIRTY_DAYS)
      );
    }

    let title = "Sign Up";
    const selectedPlanUuid = subscriptionStore.getters.selectedPlanCookie();

    if (selectedPlanUuid) {
      try {
        const plan =
          await subscriptionStore.actions.fetchPlan(selectedPlanUuid);

        if (plan) {
          this.isSubscribing = true;
          title = `Sign Up for Privacy ${plan.name}`;
        }
      } catch (err) {
        // @ts-ignore
        if (err?.response?.status === 404) {
          subscriptionStore.actions.removeTemporaryPlanCookie();
        }
      }
    }

    this.signupTitle = title;
  }

  personalSignUp() {
    this.signupTitle = "Sign Up";
    this.isSubscribing = false;
    this.$piwik.trackClick({ name: "Sign Up Secondary CTA" });
    subscriptionStore.actions.removeTemporaryPlanCookie();
  }

  signup() {
    this.emailState = null;
    this.passwordState = null;
    this.agreementError = false;

    if (this.loading) {
      return false;
    }

    if (this.email.length === 0) {
      this.errorMessage = "Please enter an email address";
      this.emailState = false;
      return;
    } else if (!validateEmail(this.email)) {
      this.errorMessage = "Please enter a valid email address";
      this.emailState = false;
      return;
    } else if (!this.passwordValid) {
      this.errorMessage = this.passwordFeedback;
      this.passwordState = false;
      return;
    } else if (this.promptCaptcha && this.captchaResponse === null) {
      this.errorMessage = "Please click 'I'm not a robot'";
      return;
    } else if (this.password.toLowerCase().includes(this.email.toLowerCase())) {
      this.errorMessage =
        "Please don't use your email as part of your password";
      return;
    } else if (!this.agreed) {
      this.errorMessage =
        "You must be a US resident, and agree to the terms and authorizations.";
      this.agreementError = true;
      return;
    }

    this.loading = true;
    userStore.actions
      .create({
        email: this.email.toLowerCase(),
        password: this.password,
        browser: navigator.userAgent,
        captchaResponse: this.captchaResponse || "",
      })
      .then(() => {
        const user = userStore.getters.currentUser;
        if (user && user.extensionToken) {
          const pwpExtension = document.body.getAttribute("data-pwp_extension");
          if (pwpExtension && !JSON.parse(pwpExtension)) {
            // Pass extension token through to the extension.
            document.body.setAttribute("data-pwp_token", user.extensionToken);
          }
        }

        subscriptionStore.actions.fetchPlans();
        subscriptionStore.actions.fetchSubscription();
        featureStore.actions.fetchAll();

        this.$router.push({ name: "email-confirm" });
      })
      .catch((err) => {
        const data = err?.response?.data || {};
        if (data.errors) {
          // @ts-ignore
          this.errorMessage = Object.values(data.errors)?.[0]?.message;
        } else if (data.message) {
          this.errorMessage = data.message;

          if (/captcha/i.test(data.message)) {
            this.promptCaptcha = true;
          }
        } else {
          this.errorMessage =
            "There was an error. Do you already have an account? If not please email support";
        }

        this.loading = false;
      });
  }
}
</script>
<style lang="scss" scoped>
.recaptcha-container {
  margin-top: 0.5rem;
}
.log-in-container {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}
</style>
