import AutoLogoutTimer from "./timer";

export interface VueAutoLogoutPlugin {
  start: () => void;
  stop: () => void;
  reset: () => void;

  readonly countdown: boolean;
  readonly duration: number | null;
  readonly end: boolean;
}

interface VueAutoLogoutPluginState {
  countdown: VueAutoLogoutPlugin["countdown"];
  duration: VueAutoLogoutPlugin["duration"];
  end: VueAutoLogoutPlugin["end"];
}

declare module "vue/types/vue" {
  interface VueConstructor {
    $autologout: VueAutoLogoutPlugin;
  }

  interface Vue {
    $autologout: VueAutoLogoutPlugin;
  }
}

const AutoLogout = {
  install(Vue: Vue.VueConstructor) {
    const state: VueAutoLogoutPluginState = new Vue({
      data: {
        countdown: false,
        duration: null,
        end: false,
      },
    });

    const timer = new AutoLogoutTimer({
      onCountdownEnd: () => {
        state.end = true;
        state.countdown = false;
      },
      onCountdownStart: (interval: number) => {
        state.countdown = true;
        state.duration = interval;
      },
      onCountdownUpdate: (interval: number) => {
        state.duration = interval;
      },
    });

    Vue.$autologout = {
      start: () => timer.start(),
      stop: () => {
        state.countdown = false;
        state.duration = null;
        state.end = false;
        timer.stop();
      },
      reset: () => {
        state.countdown = false;
        state.duration = null;
        timer.reset();
      },

      get countdown() {
        return state.countdown;
      },

      get duration() {
        return state.duration;
      },

      get end() {
        return state.end;
      },
    } as VueAutoLogoutPlugin;

    Vue.prototype.$autologout = Vue.$autologout;
  },
};

export default AutoLogout;
