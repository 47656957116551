<template>
  <div
    v-if="!!transaction"
    data-test="txn-test"
    class="transaction group"
    :class="{
      '-unconfirmed':
        transaction.state === DisputeType.ACTIVE && !transaction.dateSettled,
      '-blocked': !transaction.accepted,
      '-disputed':
        DisputeType.DISPUTE_OPEN === transaction.state ||
        DisputeType.DISPUTE_PENDING === transaction.state ||
        DisputeType.CHARGEBACK_PENDING === transaction.state,
      '-refunded':
        transaction.state === DisputeType.DISPUTE_CLOSED ||
        transaction.amount < 0,
      '-offset': transaction.achResult === '-2',
      '-bounced': transaction.hasBounced,
      '-promo': transaction.promoCreditAmount,
    }"
  >
    <div class="thumbnail" v-if="showThumbnails" :style="transactionStyles">
      <div class="icon" v-if="icon">
        {{ icon }}
      </div>
    </div>
    <div class="flex-grow-1">
      <div class="d-flex">
        <div class="group left">
          <div
            class="descriptor"
            v-if="!hideColumns || !hideColumns.descriptor"
          >
            {{ transaction.descriptor.toLowerCase() }}
          </div>
          <div class="date" v-if="showDateColumn">
            {{
              formatDate(transaction.dateAuthorized || transaction.dateSettled)
            }}
          </div>
        </div>
        <div class="group right">
          <div
            class="settlement"
            v-if="showSettlementColumn"
            :class="{
              '-settled': transaction.settlementStatus === 'SETTLED',
            }"
          >
            <BankImage
              v-if="
                transaction.settlementStatus !== 'SETTLED' &&
                transaction.achResult != '-2'
              "
              :bank="transaction.fundingBank"
              width="20"
            />
            {{ settlementStatus }}
          </div>
          <div class="amount" v-if="showAmountColumn">
            <DollarAmount :amount="transactionAmount" transaction />
          </div>
        </div>
        <div class="decline-reason" v-if="showDeclineReason">
          {{ parseDeclineReason(transaction.declineReason) }}
        </div>
      </div>
      <div v-if="showRefunds" class="d-flex flex-column pt-1 refunds -refunded">
        <div
          class="d-flex flex-grow-1 mb-1"
          v-for="refund in transaction.refunds"
          :key="refund.uuid"
        >
          <div class="group left">
            <div class="descriptor pl-3">Refund</div>
            <div class="date pl-3 pl-lg-0">
              {{ formatDate(refund.date) }}
            </div>
          </div>
          <div class="group right">
            <div class="amount">
              <DollarAmount :amount="`-${refund.amount}`" transaction />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import ldGet from "lodash/get";
import { formatDate, utcDate } from "@/lib/dates";
import { Component, Prop, Vue } from "vue-property-decorator";
import { StyleValue } from "vue/types/jsx";
import {
  Transaction as TransactionType,
  TransactionRefund,
  HideColumns,
} from "@/types/Transaction";
import { DisputeType } from "@/types/Dispute";
import { parseDeclineReason, getLogoSrc } from "@/util";
import { transactionStore } from "@/store";
import DollarAmount from "./DollarAmount.vue";
import BankImage from "./BankImage.vue";

@Component({
  components: {
    DollarAmount,
    BankImage,
  },
})
export default class Transaction extends Vue {
  @Prop() transactionProp!: TransactionType;
  @Prop() hideColumns?: HideColumns;
  @Prop() showThumbnails?: boolean;

  DisputeType = DisputeType;
  parseDeclineReason = parseDeclineReason;

  defaultBackgroundColor = "#ADBDCC";
  defaultBackgroundImage = "url(/assets/images/icons/card-20-white.svg)";

  get icon(): string {
    const txn: Record<string, any> = this.transaction || {};
    return txn.cardStyle && txn.cardStyle.icon;
  }

  get transactionStyles(): StyleValue | undefined {
    const txn = this.transaction;
    if (!txn) return undefined;

    const { cardStyle } = txn;

    // Card is not customized, return default styles
    if (!cardStyle) {
      return {
        "background-color": this.defaultBackgroundColor,
        "background-image": this.defaultBackgroundImage,
      };
    }

    // set logo
    let logoImage = getLogoSrc(cardStyle);
    if (logoImage.length > 0) {
      logoImage = "url(" + logoImage + ")";
    } else if (!cardStyle.icon) {
      logoImage = this.defaultBackgroundImage;
    }

    // set background color
    let { bgColor } = cardStyle;
    if (bgColor) {
      bgColor = `#${bgColor}`;
    } else if (logoImage.length > 0) {
      bgColor = "#ffffff";
    } else {
      bgColor = this.defaultBackgroundColor;
    }
    if (logoImage === this.defaultBackgroundImage && bgColor === "#ffffff") {
      // Prevent white on white
      bgColor = this.defaultBackgroundColor;
    }

    // set box shadow
    const boxShadow =
      bgColor === "#ffffff" ? "rgba(40,51,75,.2) 0px 0px 1px inset" : "none";

    return {
      "background-color": bgColor,
      "background-image": logoImage,
      "box-shadow": boxShadow,
    };
  }

  get showSettlementColumn(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const transaction = ldGet<any, string, any>(this, "transaction", {}) as any;
    return (
      !ldGet<any, string>(this, "hideColumns.settlement") &&
      !transaction.declineReason &&
      transaction.accepted
    );
  }

  get showDateColumn(): boolean {
    return !ldGet<any, string>(this, "hideColumns.date");
  }

  get showAmountColumn(): boolean {
    return !ldGet<any, string>(this, "hideColumns.amount");
  }

  get showDeclineReason(): boolean {
    return (
      !ldGet<any, string>(this, "hideColumns.declineReason") &&
      !!ldGet<any, string>(this, "transaction.declineReason")
    );
  }

  get showRefunds(): boolean {
    const amount = ldGet<any, string>(this, "transaction.amount");
    const refunds = ldGet<any, string, TransactionRefund[]>(
      this,
      "transaction.refunds",
      []
    );

    return amount >= 0 && refunds.length > 0;
  }

  get transaction(): TransactionType | null {
    const storeTransaction = transactionStore.getters.getTransactionById(
      this.transactionProp.transactionID
    );
    return typeof storeTransaction !== "undefined"
      ? storeTransaction
      : this.transactionProp;
  }

  get transactionAmount(): number | string {
    const amount = ldGet<any, string, number>(this, "transaction.amount", 0);
    const promoCredit = ldGet<any, string, number>(
      this,
      "transaction.promoCreditAmount",
      0
    );
    const originalAuthAmount = ldGet<any, string>(
      this,
      "transaction.originalAuth.amount"
    );

    if (this.showRefunds && originalAuthAmount) {
      return originalAuthAmount;
    }

    return amount - promoCredit;
  }

  get settlementStatus(): string | undefined {
    return this.transaction?.settlementStatus?.toLowerCase();
  }

  formatDate(date: string | Date | null): string | null {
    return date
      ? formatDate("MMM D, h:mma", utcDate(date, {}, true).local())
      : null;
  }
}
</script>
<style lang="scss" scoped>
.group {
  display: flex;
  flex-grow: 1;
  align-items: center;
  width: 100%;

  &.right {
    width: 35%;
  }
}

.transaction {
  padding: 10px 0 6px;
  min-height: 20px;
  cursor: pointer;
  transition: opacity $duration-shorter ease-in-out;

  &:hover {
    opacity: 0.5;
  }
}

.thumbnail {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  flex-shrink: 0;
  padding: 8px 3px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  background-size: contain;

  .icon {
    margin-left: 1px;
    font-size: 22px;
    text-align: center;
    line-height: 22px;
  }
}

.descriptor {
  flex-grow: 1;
  padding-right: 20px;
  min-width: 160px;
  max-width: 55%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;

  .-unconfirmed & {
    color: $gray-500;
  }
}

.date {
  flex-basis: 45%;
  padding-right: 20px;
  min-width: 160px;

  &::first-letter {
    text-transform: uppercase;
  }
}

.settlement {
  display: flex;
  align-items: center;
  min-width: fit-content;
  color: $gray-500;
  text-transform: capitalize;

  &.-settled {
    color: $gray-500;
  }

  .pwp-bank-img {
    height: 20px;

    ::v-deep img {
      margin-right: 8px;
    }
  }
}

.amount {
  flex-basis: 50%;
  flex-grow: 1;
  font-weight: bold;
  text-align: right;

  .-unconfirmed &,
  .-offset & {
    color: $gray-500;
  }

  .-blocked &,
  .-offset & {
    text-decoration: line-through;
  }

  .-disputed & {
    color: $color-orange;
  }

  .-refunded & {
    color: $color-green;
  }

  .-promo & {
    color: $blue;
  }
}

.decline-reason {
  text-align: right;
  flex-basis: 100%;
  color: $gray-500;
}

.-bounced {
  .settlement,
  .descriptor,
  .amount,
  .date {
    color: $color-red;
  }
}

.refunds .descriptor {
  background: url(/assets/images/misc/arrow-curved.svg) no-repeat left 6px;
}
</style>
