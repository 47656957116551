<template>
  <BaseForm @submit="onSubmit" :class="['change-webhook-form', 'signup-step']">
    <h1>Webhook URI</h1>
    <b-alert
      v-if="errorMessage"
      variant="danger"
      show
      data-test="change-webhook-error"
    >
      {{ errorMessage }}
    </b-alert>
    <BaseInput
      label="URI"
      placeholder="https://example.com/my-webhook"
      v-model="webhookUri"
      :state="errorMessage ? false : null"
      :disabled="loading"
      data-test="change-webhook-input"
    >
    </BaseInput>
    <BaseButton
      type="submit"
      variant="primary"
      size="lg"
      :disabled="loading"
      v-if="!success"
      data-test="change-webhook-submit-button"
    >
      Save
    </BaseButton>
    <BaseButton
      variant="success"
      size="lg"
      disabled="true"
      v-if="success"
      data-test="change-webhook-success"
    >
      Webhook URI Saved
    </BaseButton>
  </BaseForm>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { userStore } from "@/store";

const SUCCESS_MESSAGE_DURATION = 1000;

const envToKeyMap = {
  production: "api",
  sandbox: "apiSandbox",
} as const;

@Component({})
export default class ChangeWebhookForm extends Vue {
  @Prop() environment?: "production" | "sandbox";
  errorMessage = "";
  webhookUri = "";
  success = false;
  loading = false;

  @Watch("webhookUri")
  resetErrorState() {
    this.errorMessage = "";
    this.success = false;
  }

  @Watch("environment")
  updateWebhookUri() {
    if (!this.environment) {
      return;
    }

    const uri =
      userStore.getters.currentUser?.[envToKeyMap[this.environment]]?.webhook
        ?.uri || "";
    this.webhookUri = uri;
  }

  created() {
    this.updateWebhookUri();
  }

  onSubmit() {
    const webhookUri = this.webhookUri.trim();

    if (!webhookUri) {
      this.errorMessage = "Please enter your webhook URI";
      return;
    }

    if (this.environment === "production" && !/^https:\/\//i.test(webhookUri)) {
      this.errorMessage = "Your production webhook must begin with https://";
      return;
    }

    if (!this.environment) {
      this.errorMessage = `Environment "${this.environment}" not recognized`;
      return;
    }

    this.loading = true;

    userStore.actions
      .changeWebhookUri({
        uri: webhookUri,
        environment: this.environment,
      })
      .then(() => {
        this.updateUser();

        this.loading = false;
        this.success = true;

        setTimeout(() => {
          this.$emit("complete");
        }, SUCCESS_MESSAGE_DURATION);
      })
      .catch((err) => {
        this.loading = false;
        this.errorMessage =
          err.response?.data?.message ||
          "Something went wrong, please try again later";
      });
  }

  updateUser() {
    if (!this.environment) {
      return;
    }

    const user = userStore.getters.currentUser!;
    const key = envToKeyMap[this.environment];
    const updateField = {
      [key]: {
        ...user[key],
      },
    };
    updateField[key].webhook.uri = this.webhookUri;

    userStore.mutations.updateCurrentUser(updateField);
  }
}
</script>
<style lang="scss" scoped>
.signup-step.change-webhook-form {
  h1 {
    all: unset;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    margin-bottom: 15px;
    line-height: 1.4;
    color: $gray-800;
  }
}
</style>
