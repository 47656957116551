<template>
  <b-dropdown
    variant="light"
    aria-label="Card state menu"
    block
    no-caret
    class="card-state-dropdown"
    menu-class="w-100"
    data-test="card-state-dropdown"
  >
    <template #button-content>
      <SVGIcon icon="filter"></SVGIcon>
      <span class="text">
        {{ capatalizeCardState(currentCardsState) }} ({{ totalCards }})
      </span>
      <SVGIcon icon="chevron-down"></SVGIcon>
    </template>

    <b-dropdown-group>
      <b-dropdown-item
        v-for="cardState in cardStates"
        :key="cardState"
        @click="setCardState(cardState)"
        :active="cardState === currentCardsState"
        :data-test="`card-state-dropdown-item-${cardState}`"
      >
        {{ capatalizeCardState(cardState) }}
      </b-dropdown-item>
    </b-dropdown-group>
  </b-dropdown>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { CardState } from "@/types/Card";
import { Prop } from "vue-property-decorator";
import SVGIcon from "./SVGIcon.vue";

@Component({ components: { SVGIcon } })
export default class CardStateDropdown extends Vue {
  cardStates = Object.values(CardState);
  @Prop() currentCardsState!: CardState;
  @Prop() totalCards!: number;

  capatalizeCardState(cardState: CardState) {
    return cardState.charAt(0) + cardState.slice(1).toLowerCase();
  }

  setCardState(cardState: any) {
    this.$emit("card-state-change", cardState);
  }
}
</script>

<style scoped>
.text {
  margin: 0 12px;
}

::v-deep .dropdown-toggle {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
</style>
