<template>
  <BaseModal
    modal-class="edit-style-modal"
    :id="modalId"
    size="xl"
    hide-header
    @show="onShow"
    contentClass=""
  >
    <div class="modal-card-background" @click="closeModal" />
    <div class="modal-card-edit-style">
      <div class="card-preview">
        <div class="close-click" @click="closeModal" />
        <PrivacyCard :cardProp="cardEdit" />
        <div class="close-click" @click="closeModal" />
      </div>
      <div class="edit-content">
        <div class="header">
          <div class="title">Change Appearance</div>
        </div>
        <div class="appearance-selector-bar">
          <div
            class="appearance-selector"
            :class="{ '-active': showBrands }"
            @click="showBrands = !showBrands"
          >
            Brands
          </div>
          <div
            class="appearance-selector"
            :class="{ '-active': !showBrands }"
            @click="showBrands = !showBrands"
          >
            Custom
          </div>
        </div>
        <div class="brands-content" v-if="showBrands">
          <div class="nickname-input-wrap">
            <b-input
              class="nickname-input -search"
              type="text"
              placeholder="Search"
              v-model="merchantSelectorQuery"
              autofocus
              autocorrect="off"
              spellcheck="false"
              maxlength="40"
            />
          </div>
          <div class="merchant-selector-wrap">
            <h4 class="merchant-title">
              {{
                merchantSelectorQuery.length > 2
                  ? "Search Results"
                  : "Popular Cards"
              }}
            </h4>
            <MerchantSelector
              :query="merchantSelectorQuery"
              :currentMerchant="viewStyle"
              @select-merchant="handleSelectMerchant"
            />
          </div>
        </div>
        <div class="custom-content" v-if="!showBrands">
          <div class="custom-picker -color">
            <div
              class="color"
              v-for="color in cardColors.slice(0, 7)"
              :key="color"
              :style="{ backgroundColor: '#' + color }"
              :class="{
                '-active': cardEdit.style && cardEdit.style.bgColor === color,
              }"
              @click="editColor(color)"
            />
          </div>
          <div class="custom-picker -color">
            <div
              class="color"
              v-for="color in cardColors.slice(7, 14)"
              :key="color"
              :style="{ backgroundColor: '#' + color }"
              :class="{
                '-active': cardEdit.style && cardEdit.style.bgColor === color,
              }"
              @click="editColor(color)"
            />
          </div>
          <div class="custom-picker -emoji">
            <div
              class="emoji"
              v-for="emoji in cardEmojis"
              :key="emoji"
              :class="{
                '-active': cardEdit.style && cardEdit.style.icon === emoji,
              }"
              @click="editEmoji(emoji)"
            >
              {{ emoji }}
            </div>
          </div>
        </div>
        <BaseButton
          size="lg"
          variant="primary"
          :loading="submitting"
          @click="onPressSave"
        >
          Save
        </BaseButton>
      </div>
    </div>
  </BaseModal>
</template>
<script lang="ts">
import ldGet from "lodash/get";
import startCase from "lodash/startCase";
import { BvModalEvent } from "bootstrap-vue";
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { Card, CardStyle } from "@/types/Card";
import { Merchant } from "@/types/Merchant";
import { EVENTS } from "@/types/Event";
import { cardStore, eventStore } from "@/store";
import { Confirm } from "@/mixins/Confirm";
import { Toast } from "@/mixins/Toast";
import MerchantSelector from "./MerchantSelector.vue";

const RED = "FF421C";
const ORANGE = "FFA01C";
const YELLOW = "FFDC88";
const LIGHT_GREEN = "00D071";
const LIGHT_BLUE = "0099FF";
const LIGHT_PURPLE = "B088FF";
const PINK = "FFB6FF";
const BLACK = "0B1730";
const GRAY = "B0BBD1";
const WHITE = "FFFFFF";
const GREEN = "007C71";
const BLUE = "0051FF";
const PURPLE = "7948FF";
const MAGENTA = "bf0a77";

@Component({
  components: {
    MerchantSelector,
    // PrivacyCard may be recursive so import dynamically
    PrivacyCard: () => import("./PrivacyCard.vue"),
  },
})
export default class EditCardStyle extends Mixins(Confirm, Toast) {
  @Prop() card!: Card;
  @Prop() hasMerchantSelector?: boolean;
  @Prop({ default: "edit-style" }) modalId!: string;

  merchantSelectorQuery = "";
  cardEdit: Partial<Card> = {};
  customStyle: Partial<CardStyle> = {};
  viewStyle: CardStyle | null = null;
  hidePendingCustomizeConfirm = true;
  initialCardStyle: CardStyle = {};
  showBrands = true;
  viewNickname = "";
  changedMemo = false;
  changedStyle = false;
  submitting = false;
  cardColors: string[] = [
    RED,
    ORANGE,
    YELLOW,
    LIGHT_GREEN,
    LIGHT_BLUE,
    LIGHT_PURPLE,
    PINK,
    BLACK,
    GRAY,
    WHITE,
    GREEN,
    BLUE,
    PURPLE,
    MAGENTA,
  ];
  cardEmojis = [
    "🏡",
    "🍽️",
    "🚕",
    "🎶",
    "📺",
    "📱",
    "🛒",
    "💻",
    "💵",
    "🌎",
    "🔮",
    "💎",
    "🍕",
    "👾",
    "💯",
    "🛍",
    "🏋️",
    "🍰",
    "🥡",
    "🦐",
  ];

  get isNewCard() {
    return !this.card.created;
  }

  onShow(bvEvent: BvModalEvent) {
    if (this.hidePendingCustomizeConfirm) {
      this.showConfirm(bvEvent);
    }
  }

  created() {
    this.reset();
  }

  @Watch("card", { deep: true })
  reset() {
    this.viewNickname = "";
    this.viewStyle = this.card.style || null;
    this.showBrands = !ldGet<any, string>(this, "card.meta.customStyle");
    this.cardEdit = {
      ...this.card,
      cardID: undefined,
      style: this.card.style || {},
    };

    if (this.viewStyle) {
      this.initialCardStyle = { ...this.viewStyle };
    }

    if (this.card.meta?.customStyle) {
      this.customStyle = this.card.meta.customStyle;
      // clear viewStyle so the custom style isnt passed to the merchant selector
      this.viewStyle = null;
    }
  }

  showConfirm(bvEvent: BvModalEvent) {
    if (
      this.hidePendingCustomizeConfirm &&
      (ldGet<Card, any>(this.card, "meta.hostname") ||
        ldGet<Card, any>(this.card, "style.hostname") ||
        ldGet<Card, any>(this.card, "meta.customStyle"))
    ) {
      if (!bvEvent) {
        return;
      }
      bvEvent.preventDefault();
      this.confirm(
        "If you've already used this card with a merchant, changing the appearance won't change the merchant it locked to when it was first used.",
        {
          title: "Heads Up...",
          okTitle: "I Understand",
          cancelTitle: "Cancel",
        }
      ).then((resp) => {
        if (resp) {
          this.hidePendingCustomizeConfirm = false;
          this.$bvModal.show(this.modalId);
        }
      });
    } else {
      this.hidePendingCustomizeConfirm = false;
    }
  }

  closeModal() {
    this.$bvModal.hide(this.modalId);
  }

  // Copy/pasted in EditNickname
  handleSelectMerchant(merchant: Merchant) {
    this.customStyle = {};

    if (
      ldGet<any, string>(merchant, "_id") ===
      ldGet<any, string>(this, "cardEdit.style.id")
    ) {
      // "Unselect" the merchant and reset styles
      this.cardEdit.style = { bgColor: null, icon: "" };
      this.customStyle = { bgColor: null, icon: "" };
      return;
    }

    if (this.viewNickname === "" || this.merchantSelectorQuery === "") {
      this.viewNickname = startCase(
        ldGet<Merchant, any>(merchant, "friendlyNames[0]") ||
          merchant.strippedHostname
      );
      this.changedMemo = true;
    }

    this.changedStyle = true;
    this.cardEdit = { ...this.cardEdit, style: merchant };
  }

  editColor(color: string) {
    if (color === this.customStyle?.bgColor) {
      // use the empty string instead of null so the white color picker isn't active
      this.customStyle = { ...this.customStyle, bgColor: "" };
    } else {
      this.viewStyle = null;
      this.customStyle = { ...this.customStyle, bgColor: color };
    }

    this.changedStyle = true;
    this.cardEdit = { ...this.cardEdit, style: this.customStyle };
  }

  editEmoji(emoji: string) {
    if (emoji === this.customStyle?.icon) {
      delete this.customStyle.icon;
    } else {
      this.viewStyle = null;
      const newStyle: CardStyle = { ...this.cardEdit.style };

      if (!this.cardEdit.style || this.cardEdit.style.hostname) {
        newStyle.bgColor = null;
      }

      newStyle.icon = emoji;
      this.customStyle = newStyle;
    }

    this.changedStyle = true;
    this.cardEdit = { ...this.cardEdit, style: this.customStyle };
  }

  onPressSave() {
    if (this.submitting) {
      return;
    }

    this.submitting = true;

    const updateData: Partial<Card> = {};
    const existingMeta = this.card.meta || {};
    const didSetCustomStyle =
      "bgColor" in this.customStyle || "icon" in this.customStyle;
    let updatedStyle: CardStyle | null = { ...this.cardEdit.style };

    if (didSetCustomStyle) {
      updateData.meta = existingMeta;
      updateData.meta.customStyle = this.customStyle;
      updateData.meta.hostname = "";
    } else if (this.cardEdit.style?.hostname) {
      // NULL in sqlite returns as an empty string which you can't assign properties to
      updateData.meta = existingMeta;
      updateData.meta.hostname = ldGet<any, string>(
        this,
        "cardEdit.style.hostname"
      );
      updateData.meta.customStyle = null;
      updatedStyle = this.cardEdit.style;

      if (updatedStyle && !updatedStyle.bgColor) {
        updatedStyle.bgColor = "ffffff";
      }

      updateData.hostname = updateData.meta.hostname;
    } else {
      updateData.meta = this.card.meta || {};
      updateData.meta.hostname = "";
      updatedStyle = null;
    }

    const updatedCard = { ...this.card, ...updateData, style: updatedStyle };

    if (this.isNewCard) {
      this.$emit("update-card", updatedCard);
      this.closeModal();
      return;
    }

    cardStore.actions
      .update({ uuid: this.card.cardUuid, updates: updateData })
      .then(() => {
        if (this.changedMemo) {
          eventStore.actions.record({
            name: EVENTS.CARD.SET_NICKNAME,
          });
        }

        if (this.changedStyle) {
          const styleType = didSetCustomStyle ? "Custom" : "Merchant";
          eventStore.actions.record({
            name: EVENTS.CARD.SET_STYLE,
            data: { styleType },
          });
        }

        this.$emit("update-card", updatedCard);
        this.closeModal();
      })
      .catch((err) => {
        this.errorToast(
          err?.response?.data?.message || "Couldn't update card style"
        );
      })
      .finally(() => {
        this.submitting = false;
      });
  }
}
</script>
<style lang="scss">
@import "../assets/styles/mixins.scss";

.edit-style-modal {
  .modal-content {
    background: none;
  }

  .shadow {
    box-shadow: none !important;
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 100%;
    }
  }
}

.modal-card-background {
  position: absolute;
  width: 100%;
  height: 100%;
}

.modal-card-edit-style {
  @include modal-card-edit;

  display: flex;
  flex-direction: row;
  background: none;
  justify-content: center;

  > .card-preview {
    display: flex;
    flex-direction: column;
    padding-right: 20px;

    > .close-click {
      flex-grow: 1;
    }
  }

  > .edit-content {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow-hairline, $box-shadow-default-faint;
    padding: 14px 30px 30px;
    width: 400px;
    max-width: calc(100vw - 20px);
  }

  > .edit-content > .header {
    position: relative;
    margin-bottom: 10px;
  }

  > .edit-content > .header > .title {
    font-family: $font-stack-wes-fy;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }

  > .edit-content > .appearance-selector-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 2px;
    background: $gray-300;
    border: 0;
    border-radius: $border-radius;
    font-size: 18px;
  }

  > .edit-content > .appearance-selector-bar > .appearance-selector {
    z-index: 1;
    flex-basis: 50%;
    padding: 10px;
    border-radius: $border-radius;
    font-size: 14px;
    text-align: center;
    cursor: pointer;

    &.-active {
      background-color: $white;
      box-shadow: $modal-content-box-shadow-sm-up;
      font-weight: bolder;
    }
  }

  > .edit-content > .brands-content {
    min-height: 390px;

    &.-nickname-only {
      min-height: 0;
    }
  }

  > .edit-content > .brands-content > .nickname-input-wrap {
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  > .edit-content > .brands-content > .nickname-input-wrap > .nickname-input {
    flex: 1;
    min-width: 200px;
    font-size: 16px;
    padding: 6px 1.25rem;

    &.-search {
      padding: 10px 15px 10px 50px;
      min-width: none;
      background: transparent url("/assets/images/icons/magnifier-20.svg") left
        20px center no-repeat;
    }

    & + .pill-button {
      margin-left: 10px;
      height: 54px;
    }
  }

  > .edit-content
    > .brands-content
    > .merchant-selector-wrap
    > .merchant-title {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }

  > .edit-content > .custom-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-height: 390px;
  }

  > .edit-content > .custom-content > .custom-picker {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    margin: 15px 0;

    &.-color {
      padding: 0 6px;
    }
  }

  > .edit-content
    > .custom-content
    > .custom-picker.-color
    + .custom-picker.-color {
    margin-top: 0;
  }

  > .edit-content > .custom-content > .custom-picker > .color {
    height: 32px;
    width: 32px;
    box-shadow: inset 0 0 0 1px $color-shadow-black-faint;
    border-radius: $border-radius;
    transition: transform $duration-shortest;
    cursor: pointer;

    &:hover {
      box-shadow:
        inset 0 0 0 1px $color-shadow-faint,
        0 0 0 2px $white,
        $box-shadow-default;
      transform: scale(1.1);
    }

    &.-active {
      box-shadow:
        0 0 0 2px $white,
        0 0 0 4px $blue;
    }
  }

  > .edit-content > .custom-content > .custom-picker > .emoji {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    height: 50px;
    width: 50px;
    border-radius: $border-radius;
    font-size: 36px;
    text-align: center;
    transition: opacity $duration-shortest;
    cursor: pointer;

    &:hover {
      box-shadow:
        0 0 0 2px $white,
        0 0 0 4px $color-shadow-black-fainter,
        $box-shadow-default;
    }

    &.-active {
      box-shadow:
        0 0 0 2px $white,
        0 0 0 4px $blue;
    }
  }

  > .edit-content > .pill-button {
    margin-top: auto;
  }

  @media (min-width: 720px) {
    > .edit-content::before {
      content: "";
      position: absolute;
      margin: auto auto auto -48px;
      top: 0;
      bottom: 0;
      z-index: 999;
      display: block;
      flex-shrink: 0;
      height: 40px;
      width: 18px;
      background: url("/assets/images/privacy-card/callout.svg");
    }
  }

  @media (max-width: 720px) {
    > .card-preview {
      display: none;
    }
  }

  @media #{$media-phone} {
    > .edit-content > .brands-content > .nickname-input-wrap > .nickname-input {
      margin: 0 0 10px;
    }
  }
}
</style>
