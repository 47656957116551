<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :fullpage="fullpage"
      @submit="submit"
      class="success-card"
      data-test="success-card"
    >
      <h1>Connect Your Debit Card</h1>
      <div class="success">Account Connected</div>
      <div
        v-if="user && user.fundingCardList && user.fundingCardList[0]"
        class="card-mini"
      >
        <SVGIcon class="main" icon="card" size="40" />
        <div>
          <p>Debit card</p>
          <!-- NOTE: Visa is hard coded since we're only enabling fcb for Visa Cards -->
          <p>Visa Debit •••• {{ user.fundingCardList[0].lastFour }}</p>
        </div>
      </div>
      <div class="disclaimer">
        All transactions made using a Privacy Card over a 24-hour period will be
        collected from your debit card as an aggregated amount. This payment
        will appear as Privacy on your debit card statement.
      </div>
      <div class="divider"></div>
      <div class="disclaimer">
        <p class="header">Transaction Receipt</p>
        <p>
          You will receive an accompanying email that shows each individual
          Privacy transaction that has been included in the aggregated amount.
        </p>
      </div>
      <div class="receipt-wrapper">
        <div class="receipt-header">Example transaction receipt:</div>
        <div class="receipt">
          <div class="line-item">
            <div>July 14</div>
            <div>Amazon</div>
            <div>$10.00</div>
          </div>
          <div class="line-item">
            <div>July 14</div>
            <div>Walmart</div>
            <div>$15.00</div>
          </div>
          <div class="line-total">
            <div>Total Amount: &ensp; $25.00</div>
          </div>
        </div>
      </div>
      <BaseButton
        type="submit"
        variant="primary"
        size="lg"
        data-test="continue-enroll"
        aria-label="continue and enroll"
        @click="trackClick"
      >
        Continue
      </BaseButton>
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

import { User } from "@/types/User";
import { userStore } from "@/store";
import SVGIcon from "@/components/SVGIcon.vue";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component({
  components: {
    SVGIcon,
  },
})
export default class SuccessCard extends Vue {
  @Prop({ default: false }) fullpage!: boolean;

  user: User | null = null;

  created() {
    userStore.actions.getCurrentUser().then(() => {
      this.user = userStore.getters.currentUser;
    });

    this.$piwik.trackEvent("Modal", "View", {
      name: "Onboarding Add Debit Card Success",
    });
  }

  trackClick(): void {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.SUCCESS_CARD_BATCH)} Primary CTA`,
    });
  }

  submit() {
    userStore.actions.automaticPaymentsEnroll().then(() => {
      this.$emit("complete-step");
    });
    this.$piwik.trackClick({
      name: "Onboarding Add Debit Card Success Primary CTA",
    });
  }
}
</script>

<style lang="scss" scoped>
::v-deep .form {
  width: 22.75rem;
}

.success-card {
  text-align: center;
  opacity: 0;
  animation: show-step $duration-default;
  animation-timing-function: $ease-out-expo;
  animation-fill-mode: forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  font-family: $font-stack-graphik;

  > h1 {
    all: unset;
    margin-bottom: 15px;
    font-family: $font-stack-fk-grotesk;
    font-size: 24px;
    text-align: center;
    color: $gray-800;
    line-height: 1.4;
  }

  .success {
    background-color: $success-10;
    padding: 12px 16px;
    color: $success;
    font-weight: 600;
    text-align: left;
    border-radius: $border-radius;
    width: 100%;
  }

  .card-mini {
    background-color: #f3f6fa;
    padding: 16px;
    border-radius: $border-radius-lg;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    text-align: left;

    p {
      margin-bottom: 8px;
      &:first-child {
        font-weight: 600;
      }
      &:last-child {
        color: $neutrals-4;
      }
    }
  }

  .receipt-wrapper {
    width: 100%;
  }
  .receipt-header {
    font-weight: 600;
    font-style: italic;
    text-align: left;
    width: 100%;
    font-size: 12px;
    margin-bottom: 8px;
  }

  .receipt {
    background-color: $foreground-border;
    border-radius: $border-radius;
    width: 100%;
    padding: 16px;
    background-image: linear-gradient(
      to right,
      white 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 16px 4px;
    background-repeat: repeat-x;

    .line-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid black;
      text-align: left;
      padding: 12px 0;
      &:nth-child(2) {
        border-bottom: 2px solid black;
      }

      div {
        flex-basis: 20%;
        &:nth-child(2) {
          flex-basis: 60%;
        }
        &:last-child {
          text-align: right;
        }
      }
    }

    .line-total {
      font-weight: 600;
      padding: 12px 0;
      text-align: right;
    }
  }

  .disclaimer {
    text-align: left;

    .header {
      font-weight: 600;
    }
  }
  .divider {
    border-bottom: 1px solid $foreground-border;
    width: 100%;
  }

  > button {
    font-weight: 600;
    width: 100%;
    margin-bottom: 15px !important;
  }
}
</style>
