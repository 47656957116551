<template>
  <SimplePage :fullpage="fullpage">
    <div v-if="!loading" class="add-funds-wrapper">
      <h1 class="title">Add Funds</h1>
      <div class="input-wrapper">
        <CurrencyInput
          class="funds-input -flat-bottom"
          v-model="value"
          :minValue="minValue"
          :maxValue="maxValue"
        />
        <div class="input-controls">
          <BaseButton
            variant="secondary"
            @click="adjustAmount('decrease')"
            :disabled="value === minValue"
            data-test="add-funds-minus-5"
            aria-label="subtract five dollars"
          >
            <b-icon icon="dash" />
          </BaseButton>
          <BaseButton
            variant="secondary"
            @click="adjustAmount('increase')"
            :disabled="value === maxValue"
            data-test="add-funds-add-5"
            aria-label="add five dollars"
          >
            <b-icon icon="plus" />
          </BaseButton>
        </div>
      </div>
      <div class="funding-card-wrapper">
        <FundingCardPreview :fundingCard="fundingCard" />
      </div>
      <div class="controls">
        <BaseButton
          :disabled="loadingSubmit"
          variant="primary"
          @click="onSubmit"
          data-test="add-funds-confirm"
        >
          Add Funds
        </BaseButton>
      </div>
    </div>
  </SimplePage>
</template>
<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import { BIcon, BIconDash, BIconPlus } from "bootstrap-vue";
import { Toast } from "@/mixins/Toast";
import { fundingCardStore, userStore } from "@/store";
import FundingCardPreview from "@/components/FundingCardPreview.vue";
import CurrencyInput from "@/components/CurrencyInput.vue";
import { FundingCard } from "@/types/Funding";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component({
  components: {
    FundingCardPreview,
    CurrencyInput,
    BIcon,
    BIconDash,
    BIconPlus,
  },
})
export default class AddFunds extends Mixins(Toast) {
  @Prop({ default: false }) fullpage!: boolean;

  loading = true;
  loadingSubmit = false;
  value = 0;
  minValue = 0;
  maxValue = 0;
  step = 5;
  fundingCard!: FundingCard;

  get user() {
    return userStore.getters.currentUser!;
  }

  setValue(val: number | string) {
    let final = val;

    if (typeof final === "string") {
      final = parseFloat(final);
    }

    final = Math.max(final, this.minValue);
    final = Math.min(final, this.maxValue);

    this.value = final;
  }

  created() {
    this.loading = true;
    this.value = 25;
    const { MIN_LOAD_AMOUNT, MAX_LOAD_AMOUNT } =
      fundingCardStore.getters.constants;
    this.minValue = MIN_LOAD_AMOUNT;
    this.maxValue = MAX_LOAD_AMOUNT;
    this.setValue(this.minValue);

    fundingCardStore.actions
      .getCardFromUser({ user: this.user })
      .then((card) => {
        this.fundingCard = card;
        this.loading = false;
      })
      .catch(() => {
        this.errorToast("There is no active card on this account.");
        this.$emit("complete-step");
      });
  }

  adjustAmount(type: "increase" | "decrease") {
    if (type === "increase") {
      this.setValue(this.value + 5);
    } else {
      this.setValue(this.value - 5);
    }
  }

  onSubmit() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.ADD_FUNDS)} Primary CTA`,
    });

    if (!this.value) {
      return this.errorToast("Please enter a valid amount to add");
    }

    if (this.value < this.minValue) {
      return this.errorToast(
        `You must add at least $${this.minValue.toFixed(2)}`
      );
    }

    this.loadingSubmit = true;
    const amount = this.value * 100;

    if (!this.fundingCard.uuid) {
      return;
    }

    fundingCardStore.actions
      .load({ uuid: this.fundingCard.uuid, amount })
      .then(() => {
        const accountBalances =
          this.user?.accountBalances ||
          userStore.getters.defaultAccountBalances;

        accountBalances.available = String(
          parseInt(accountBalances.available) + amount
        );

        userStore.mutations.updateCurrentUser({ accountBalances });

        this.$emit("refresh-funding-sources");
        this.successToast(`$${this.value} was added to your account`);
        this.$emit("complete-step");
      })
      .catch((err) => {
        this.errorToast(
          err?.response?.data?.message ||
            "There was an error loading your account. Please try again later."
        );
      })
      .finally(() => {
        this.loadingSubmit = false;
      });
  }
}
</script>
<style lang="scss" scoped>
.add-funds-wrapper {
  max-width: 300px;
  margin: 0 auto;
}

.input-wrapper ::v-deep .funds-input.-flat-bottom input {
  border-radius: $radius-large $radius-large 0 0;
}

.title {
  font-size: 24px;
  font-family: $font-stack-wes-fy;
  text-align: center;
  margin-bottom: 40px;
}

.input-wrapper > .input-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.input-wrapper > .input-controls > button {
  width: calc(50% - 1px);
  background: $gray-100;
  color: $gray-800;
  font-size: 28px;
  outline: none !important;

  &:hover {
    background: $gray-300;
  }

  &:first-of-type {
    border-radius: 0 0 0 $radius-large;
  }

  &:last-of-type {
    border-radius: 0 0 $radius-large 0;
  }
}

.funding-card-wrapper {
  margin-top: 20px;
}

.controls {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.controls > button {
  width: 100%;
}
</style>
