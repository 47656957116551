<template>
  <BaseModal id="rename-funding-source" size="md">
    <BaseForm class="rename-funding-wrapper" @submit="onSubmit">
      <h1 class="title">Rename</h1>
      <b-input
        v-model="newName"
        :placeholder="(source && source.accountName) || ''"
        data-test="rename-funding-source"
      />
      <div class="controls">
        <BaseButton :disabled="loading" variant="secondary" @click="close">
          Cancel
        </BaseButton>
        <BaseButton
          :loading="loading"
          variant="primary"
          type="submit"
          data-test="confirm-rename-funding"
        >
          Done
        </BaseButton>
      </div>
    </BaseForm>
  </BaseModal>
</template>
<script lang="ts">
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Toast } from "@/mixins/Toast";
import { bankStore } from "@/store";
import { FundingBank } from "@/types/Funding";

@Component
export default class RenameFundingSource extends Mixins(Toast) {
  @Prop({ required: true }) source!: FundingBank;
  newName = "";
  loading = false;

  @Watch("source")
  onSourceChange(newSource: FundingBank) {
    this.newName = newSource?.nickname || "";
  }

  onSubmit() {
    this.loading = true;

    bankStore.actions
      .editNickname({
        bankAccountID: this.source.bankAccountID,
        nickname: this.newName,
      })
      .then(() => {
        this.successToast("Nickname saved");
        this.$emit("refresh-funding-sources");
        this.close();
      })
      .catch((err) => {
        this.errorToast(
          err?.response?.data?.message ||
            "Error updating nickname. Please try again later."
        );
      })
      .finally(() => {
        this.loading = false;
      });
  }

  close() {
    this.$bvModal.hide("rename-funding-source");
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-size: 24px;
  font-family: $font-stack-wes-fy;
  text-align: center;
  margin-bottom: 40px;
}

.controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.controls > button {
  flex-grow: 1;
  flex-basis: 50%;
  user-select: none;
}

.controls > button + button {
  margin-left: 10px;
}
</style>
