<template>
  <div class="field">
    <div class="title" :class="{ disabled }">{{ label }}</div>
    <b-form-input
      v-bind="$attrs"
      :value="value.pan"
      @input="handleInputPan"
      :disabled="disabled"
      maxlength="19"
      :formatter="formatPan"
      placeholder="0000 0000 0000 0000"
      data-test="pan"
    ></b-form-input>
    <div class="group">
      <b-form-input
        v-bind="$attrs"
        :value="value.exp"
        @input="handleInputExp"
        :disabled="disabled"
        maxlength="5"
        :formatter="formatExp"
        placeholder="MM/YY"
        class="-exp"
        data-test="exp"
      ></b-form-input>
      <b-form-input
        v-bind="$attrs"
        :value="value.cvv"
        @input="handleInputCvv"
        :disabled="disabled"
        maxlength="3"
        :formatter="formatNumOnly"
        placeholder="CVV"
        class="-cvv"
        data-test="cvv"
      ></b-form-input>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { card } from "creditcards";
import { formatInputDate } from "@/lib/dates";
import { CardValue } from "../types/Form";
import { formatNumOnly } from "../util";

@Component({
  inheritAttrs: false,
})
export default class BaseCardInput extends Vue {
  @Prop({ default: "" }) label!: string;
  @Prop({ default: () => ({ pan: "", cvv: "", exp: "" }) }) value!: CardValue;
  @Prop({ default: false }) disabled!: boolean;

  formatNumOnly = formatNumOnly;

  handleInputPan(value: string) {
    if (value.startsWith("34") || value.startsWith("37")) {
      this.$emit(
        "pan-error",
        "We're sorry, we do not support American Express cards"
      );
    } else {
      this.$emit("pan-error", "");
    }

    this.$emit("input", Object.assign({}, this.value, { pan: value }));
  }

  handleInputCvv(value: string) {
    this.$emit("input", Object.assign({}, this.value, { cvv: value }));
  }

  handleInputExp(value: string) {
    this.$emit("input", Object.assign({}, this.value, { exp: value }));
  }

  formatPan(value: string) {
    return card.format(card.parse(value));
  }

  formatExp(value: string) {
    return formatInputDate(value, true);
  }
}
</script>

<style lang="scss" scoped>
.field {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $gray-100;
  border-radius: 1rem;
  padding: 1.5rem 1.25rem 0;
  height: 11.25rem;
  cursor: text;

  .title {
    padding: 0.25rem 0 0;
    margin-bottom: 2.25rem;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 0.8;
    pointer-events: none;
    z-index: 1;

    &.disabled {
      opacity: 0.5;
    }
  }

  .group {
    margin-top: 0.75rem;
    display: flex;

    .-exp {
      flex-basis: 5.25rem;
    }

    .-cvv {
      flex-basis: 3.5rem;
      margin-left: 3.25rem;
    }
  }

  ::v-deep input {
    z-index: 0;
    font-family: $font-stack-ocr;
    font-size: 1.125rem;
    line-height: 1;
    letter-spacing: -0.3px;
    padding: 0.25rem 0.5rem;
    margin-left: -0.625rem;

    &:enabled:hover {
      background-color: $gray-200;
      border-color: $gray-200;
    }
  }
}
</style>
