<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      class="commercial-charge-disclosure"
      :fullpage="fullpage"
      @submit="submit"
      data-test="new-user-commercial-charge-disclosure"
    >
      <h1>Almost done!</h1>
      <CreditScoreExplainer
        displayText="Don't worry, this isn't a credit check and won't affect your credit score."
      ></CreditScoreExplainer>
      <div class="content">
        <p>
          To finish, you need to apply for a charge card with our bank partner,
          Patriot Bank N.A.
        </p>
        <p>By clicking "Next" below, you acknowledge and agree:</p>
        <ul>
          <li>
            To be bound by the
            <b-link
              href="https://privacy.com/commercial-cardholder-agreement"
              target="_blank"
              >Privacy.com Charge Cardholder Agreement</b-link
            >
            and
            <b-link
              href="https://bankpatriot.com/PatriotBank/media/Documents/Privacy_Policy.pdf"
              target="_blank"
              >Patriot Bank's GLBA Notice</b-link
            >
            if your application is accepted by our partner.
          </li>
          <li>
            That you will have to pay your card balance in full at the end of
            each billing cycle
          </li>
          <li>
            To help the government fight terrorism funding and money laundering,
            federal law requires that all financial institutions obtain, verify,
            and record identifying information about everyone who opens an
            account. Because of this, when you open an account with us, we will
            ask for your name, address, date of birth, and other information
            (which may include copies of drivers licenses or other documents)
            that identifies you.
          </li>
        </ul>
      </div>
      <BaseButton
        class="mt-2"
        type="submit"
        variant="primary"
        size="lg"
        data-test="next"
        @click="trackClick"
      >
        Next
      </BaseButton>
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Prop, Mixins } from "vue-property-decorator";
import { userStore, cardStore, subscriptionStore } from "@/store";
import { BAlert } from "bootstrap-vue";
import CreditScoreExplainer from "@/components/CreditScoreExplainer.vue";
import SVGIcon from "@/components/SVGIcon.vue";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";
import { User } from "@/types/User";
import { BusinessType } from "@/types/Organization";
import { Toast } from "@/mixins/Toast";
import ldGet from "lodash/get";

@Component({
  components: {
    BAlert,
    SVGIcon,
    CreditScoreExplainer,
  },
})
export default class CommercialChargeDisclosure extends Mixins(Toast) {
  @Prop({ default: false }) fullpage!: boolean;

  get user(): User | null {
    return userStore.getters.currentUser;
  }

  trackClick(): void {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.COMMERCIAL_CHARGE_DISCLOSURE)} Primary CTA`,
    });
  }

  submit(): void {
    userStore.actions
      .commercialChargeCardAgree()
      .then(() => {
        if (this.user?.applicationDeclined) {
          this.$emit("complete-step", OnboardingSteps.FLAGGED_ACCOUNT);
        } else if (subscriptionStore.getters.isSubscribing()) {
          this.$emit("complete-step", OnboardingSteps.BILLING);
        } else if (
          this.user?.verificationNeeded ||
          this.user?.isPendingBusinessReview
        ) {
          let step = OnboardingSteps.APPLICATION_COMPLETE;

          if (
            this.user?.organization?.businessType === BusinessType.INDIVIDUAL
          ) {
            step = OnboardingSteps.BUSINESS_INFO_SUCCESS;
          }

          this.$emit("complete-step", step);
        } else {
          cardStore.actions.activateSampleCard().then(() => {
            this.$emit("complete-step", OnboardingSteps.SIGNUP_COMPLETE);
          });
        }
      })
      .catch((resp) => {
        this.errorToast(
          ldGet<any, any, string>(
            resp?.response,
            "data.message",
            "Failed to accept charge terms."
          )
        );
      });
  }
}
</script>

<style lang="scss" scoped>
::v-deep .form {
  width: 21.75rem;
}

.commercial-charge-disclosure {
  text-align: center;
  opacity: 0;
  animation: show-step $duration-default;
  animation-timing-function: $ease-out-expo;
  animation-fill-mode: forwards;

  > h1 {
    all: unset;
    margin-bottom: 15px;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    color: $gray-800;
    line-height: 1.4;
  }

  .content {
    padding: 24px;
    background: $gray-100;
    border-radius: $border-radius;
    color: $gray-800;
    max-height: 200px;
    overflow-y: scroll;
    text-align: left;
    white-space: pre-line;

    > ul {
      padding-inline-start: 20px;

      > li:not(:last-child) {
        margin-bottom: 1em;
      }
    }
  }

  button {
    width: 100%;
    margin: 20px 0 15px !important;
  }
}
</style>
