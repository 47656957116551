<template>
  <SimplePage :fullpage="fullpage">
    <div class="signup-complete" data-test="signup-complete">
      <img
        v-if="!fullpage"
        src="/assets/images/home/signup-complete.svg"
        alt=""
      />
      <div class="wrapper">
        <h1>Signup Complete!</h1>
        <div>
          You were approved! You can start creating cards and making purchases.
        </div>
        <BaseButton
          v-if="isFreePlan"
          variant="primary"
          size="lg"
          @click="onCompleteStep"
          data-test="close"
        >
          {{ fullpage ? "Go to Dashboard" : "Close" }}
        </BaseButton>
        <BaseButton
          v-else
          variant="primary"
          size="lg"
          @click="onCompleteStep"
          data-test="complete-subscription"
          >Complete Subscription</BaseButton
        >
      </div>
    </div>
  </SimplePage>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { subscriptionStore } from "@/store";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component
export default class SignupComplete extends Vue {
  @Prop({ default: false }) fullpage!: boolean;

  get isFreePlan() {
    const isFree = !subscriptionStore.getters.isPaidSubscription;
    const isSubscribing = subscriptionStore.getters.isSubscribing();

    return isFree && !isSubscribing;
  }

  onCompleteStep() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.SIGNUP_COMPLETE)} Primary CTA`,
    });

    this.$emit("complete-step");
  }
}
</script>
<style lang="scss">
.signup-complete {
  padding: 0 !important;
  margin-top: -2.25rem;

  img {
    width: 100%;
  }

  h1 {
    all: unset;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    color: $gray-800;
  }

  button {
    width: 100%;
  }

  .wrapper {
    max-width: 300px;
    margin: 0 auto;
    padding: 40px 20px 50px;
    display: grid;
    gap: 20px;
    text-align: center;
  }
}
</style>
