<template>
  <SimplePage :fullpage="true">
    <BaseForm>
      <img
        alt="Mail envelope with error"
        src="/assets/images/onboarding/confirm-email-error.png"
        width="300"
      />
      <h1>Whoops!</h1>
      <p class="blurb">
        That email confirmation link is<br />either broken or expired.
      </p>
      <p class="blurb -resend-email" v-show="!emailSent && showResend">
        <BaseButton variant="link" @click="onClickResend">
          Send another confirmation link.
        </BaseButton>
      </p>
      <p class="blurb -resend-email -success" v-show="emailSent">
        <SVGIcon icon="tick" /> Email Resent
      </p>
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import Vue from "vue";
import { userStore } from "@/store";
import { User } from "@/types/User";
import { Component } from "vue-property-decorator";
import SVGIcon from "@/components/SVGIcon.vue";

const SUCCESS_TIMEOUT = 5000;

@Component({
  components: {
    SVGIcon,
  },
})
export default class EmailConfirmError extends Vue {
  emailSent = false;
  showResend = false;
  user: User | null = null;

  created() {
    userStore.actions.getCurrentUser().then(() => {
      this.user = userStore.getters.currentUser;
      if (this.user?._id) this.showResend = true;
    });
  }

  onClickResend() {
    userStore.actions.resendConfirmationEmail();
    this.emailSent = true;
    window.setTimeout(() => {
      this.emailSent = false;
    }, SUCCESS_TIMEOUT);
  }
}
</script>

<style lang="scss" scoped>
@import "./email-confirm-base";
</style>
