<template>
  <div>
    <div class="account-settings">
      <section>
        <div class="login-credentials section">
          <div class="credential">
            <strong class="label">Preferred Name</strong>
            <button
              v-if="user.preferredFirstName"
              class="value"
              @click="$bvModal.show('change-name-modal')"
              data-test="button-open-name-email"
            >
              {{ user.preferredFirstName || "" }}
              <b-icon class="svg-icon" icon="pencil-fill" />
            </button>
            <button
              v-else
              class="value"
              @click="$bvModal.show('change-name-modal')"
              data-test="button-open-name-email"
            >
              <b-icon icon="pencil-fill" />
            </button>
          </div>
          <div class="credential">
            <strong class="label">Email</strong>
            <button
              class="value"
              @click="$bvModal.show('change-email-modal')"
              data-test="button-open-change-email"
            >
              {{ user.email }}
              <b-icon class="svg-icon" icon="pencil-fill" />
            </button>
          </div>
          <div class="credential">
            <strong class="label">Password</strong>
            <button
              class="value"
              @click="$bvModal.show('change-password-modal')"
              data-test="button-open-change-password"
            >
              &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
              <b-icon class="svg-icon" icon="pencil-fill" />
            </button>
          </div>
          <div class="credential">
            <strong class="label">Phone</strong>
            <button
              class="value"
              v-if="user.phoneLastFour"
              @click="onPhoneSetup"
              data-test="button-open-change-phone"
            >
              (&bull;&bull;&bull;) &bull;&bull;&bull;-{{ user.phoneLastFour }}
              <b-icon class="svg-icon" icon="pencil-fill" />
            </button>
            <BaseButton
              class="phone-action"
              v-if="!user.phoneLastFour || !user.phoneVerified"
              @click="onPhoneSetup"
              size="sm"
              variant="primary"
              pill
              data-test="button-setup-phone"
            >
              {{ !user.phoneLastFour ? "Add Phone" : "Confirm" }}
            </BaseButton>
          </div>
        </div>
      </section>
    </div>
    <BaseModal id="change-password-modal">
      <ChangePasswordForm :fullpage="false" modal-id="change-password-modal" />
    </BaseModal>
    <BaseModal id="change-email-modal">
      <ChangeEmailForm :fullpage="false" modal-id="change-email-modal" />
    </BaseModal>
    <BaseModal id="change-name-modal">
      <ChangePreferredNameForm
        :fullpage="false"
        modal-id="change-name-modal"
        :preferredName="user.preferredFirstName"
      />
    </BaseModal>
    <BaseModal hide-header id="phone-update-modal">
      <SignupPhone @complete-step="onCompletePhone" />
    </BaseModal>
    <BaseModal hide-header id="phone-confirm-modal">
      <SignupPhoneConfirm @complete-step="onCompletePhoneConfirm" />
    </BaseModal>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { BIcon, BIconPencilFill } from "bootstrap-vue";
import { userStore } from "@/store";
import { TwoFactorPreferences, User } from "@/types/User";
import { OnboardingSteps } from "@/types/Onboarding";
import ChangePasswordForm from "@/views/ChangePassword.vue";
import ChangeEmailForm from "@/views/ChangeEmail.vue";
import ChangePreferredNameForm from "@/views/ChangePreferredName.vue";
import SignupPhone from "@/views/signup/SignupPhone.vue";
import SignupPhoneConfirm from "@/views/signup/SignupPhoneConfirm.vue";

@Component({
  components: {
    ChangePasswordForm,
    ChangeEmailForm,
    ChangePreferredNameForm,
    SignupPhone,
    SignupPhoneConfirm,
    BIcon,
    BIconPencilFill,
  },
})
export default class AccountSettings extends Vue {
  loadingTfa = false;
  tfaPreference: TwoFactorPreferences | null = null;

  get user(): User {
    return userStore.getters.currentUser || ({} as User);
  }

  get phoneVerified(): boolean {
    return !!this.user.phoneVerified;
  }

  onPhoneSetup() {
    const step =
      !this.user.phoneLastFour || this.user.phoneVerified
        ? "phone-update-modal"
        : "phone-confirm-modal";

    this.$bvModal.show(step);
  }

  onCompletePhone() {
    this.$bvModal.hide("phone-update-modal");
    this.$bvModal.show("phone-confirm-modal");
  }

  onCompletePhoneConfirm(forcedScreen: OnboardingSteps | null) {
    this.$bvModal.hide("phone-confirm-modal");
    if (forcedScreen === OnboardingSteps.PHONE) {
      this.$bvModal.show("phone-update-modal");
    }
  }
}
</script>
<style lang="scss" scoped>
.account-settings {
  user-select: none;

  .label {
    color: $gray-800;
    font-weight: bold;
  }
}

.credential {
  display: flex;
  align-items: center;
  padding: 0 20px;
  line-height: 40px;

  &:first-child {
    margin-top: -10px;
  }

  &:last-child {
    margin-bottom: -10px;
  }

  .label {
    flex-basis: 120px;
    padding-right: 10px;
  }

  .value {
    border: none;
    background-color: unset;
    padding: 0 1em;
    border-radius: $radius-default;
    white-space: nowrap;
    transition: background-color, $duration-shorter;

    & > .svg-icon {
      margin-left: 10px;
      transition: opacity $duration-shorter;

      @media #{$media-desktop} {
        opacity: 0;
      }
    }

    &:hover {
      background-color: desaturate(darken($gray-100, 5%), 20%);

      > .svg-icon {
        opacity: 1;
      }
    }
  }

  .phone-action {
    margin-left: 10px;
  }
}
</style>
