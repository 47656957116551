<template>
  <div v-if="activeHelpers.length > 0" class="dashboard-helpers-container">
    <div
      v-show="!isBeginning && activeHelpers.length > slidesPerView"
      @click="navPrev"
      class="helper-prev"
    />
    <div
      v-show="!isEnd && activeHelpers.length > slidesPerView"
      @click="navNext"
      class="helper-next"
    />
    <div class="dashboard-helpers-swiper swiper-container">
      <div class="dashboard-helpers swiper-wrapper">
        <div
          v-for="(helper, index) in activeHelpers"
          :key="`${helper}_${index}`"
          class="helper-item"
        >
          <component
            v-if="helper !== HELPER_TYPES.EMPTY"
            :is="helper"
            @show-commercial-charge-terms-reminder="
              $emit('show-commercial-charge-terms-reminder')
            "
            @start-onboarding="$emit('start-onboarding')"
            @create-card="$emit('create-card')"
          />
          <div v-else class="helper -empty" />
        </div>
      </div>
      <div class="swiper-pagination">
        <div
          v-for="index in slidesPagination"
          :key="index"
          class="swiper-pagination-bullet"
          :class="{ '-active': index === activeIndex }"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import ldGet from "lodash/get";
import Swiper from "swiper";
import { Component, Mixins, Vue } from "vue-property-decorator";
import { userStore, transactionStore, subscriptionStore } from "@/store";
import { Extension } from "@/mixins/Extension";
import { OnboardingSteps } from "@/types/Onboarding";
import HelperHiringEngineers from "./HelperHiringEngineers.vue";
import HelperChargeTerms from "./HelperChargeTerms.vue";
import HelperOnboarding from "./HelperOnboarding.vue";
import HelperAccountCredit from "./HelperAccountCredit.vue";
import HelperMobile from "./HelperMobile.vue";
import HelperExtensionInstall from "./HelperExtensionInstall.vue";
import HelperInvite from "./HelperInvite.vue";
import HelperCommercialChargeTerms from "./HelperCommercialChargeTerms.vue";
import HelperPendingBusinessReview from "./HelperPendingBusinessReview.vue";
import HelperCommercialChargeReissued from "./HelperCommercialChargeReissued.vue";
import HelperCommercialChargeClosed from "./HelperCommercialChargeClosed.vue";

const HELPER_TYPES: Record<string, string> = {
  HIRING_ENGINEERS: "HelperHiringEngineers",
  COMMERCIAL_CHARGE_TERMS: "HelperCommercialChargeTerms",
  CHARGE_TERMS: "HelperChargeTerms",
  PENDING_BUSINESS_REVIEW: "HelperPendingBusinessReview",
  ONBOARDING: "HelperOnboarding",
  ACCOUNT_CREDIT: "HelperAccountCredit",
  MOBILE: "HelperMobile",
  EXTENSION_INSTALL: "HelperExtensionInstall",
  INVITE: "HelperInvite",
  EMPTY: "HelperEmpty",
} as const;

@Component({
  components: {
    HelperHiringEngineers,
    HelperCommercialChargeTerms,
    HelperChargeTerms,
    HelperPendingBusinessReview,
    HelperOnboarding,
    HelperAccountCredit,
    HelperMobile,
    HelperExtensionInstall,
    HelperInvite,
    HelperCommercialChargeReissued,
    HelperCommercialChargeClosed,
  },
})
export default class Helpers extends Mixins(Extension, Vue) {
  HELPER_TYPES = HELPER_TYPES;
  swiper: null | Swiper = null;

  get isBeginning() {
    return this.swiper?.isBeginning;
  }

  get isEnd() {
    return this.swiper?.isEnd;
  }

  get activeIndex() {
    return (this.swiper?.activeIndex || 0) + 1;
  }

  get slidesPerView() {
    return typeof this.swiper?.params?.slidesPerView === "number"
      ? this.swiper?.params?.slidesPerView
      : 1;
  }

  get slidesPagination() {
    const slides = this.slidesPerView;
    return this.activeHelpers.length + 1 - slides;
  }

  get user() {
    return userStore.getters.currentUser;
  }

  get subscription() {
    return subscriptionStore.getters.subscription;
  }

  navNext() {
    this.swiper?.slideNext();
  }

  navPrev() {
    this.swiper?.slidePrev();
  }

  mounted() {
    this.swiper = new Swiper(".swiper-container", {
      slideClass: "helper-item",
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 20,
      breakpoints: {
        921: {
          slidesPerView: 2,
        },
        1201: {
          slidesPerView: 3,
        },
      },
    });
  }

  get transactions() {
    return transactionStore.getters.getTransactions({});
  }

  hasValidPrepaidFundingCard(): boolean {
    return !!this.user?.isPrepaid && this.user?.fundingCardList.length > 0;
  }

  showInviteHelper(): boolean {
    return (
      ldGet<any, string, string>(this.user, "activePromo.type", "") ===
      "give-5-get-5"
    );
  }

  showExtensionInstallHelper(): boolean {
    return this.canInstallExtension();
  }

  showMobileHelper(): boolean {
    return !this.user?.lastAppLogin;
  }

  showAccountCreditHelper(): boolean {
    return this.hasValidPrepaidFundingCard();
  }

  // Show to all BUSINESS users who have not yet signed the commercial charge terms
  // and have transacted (non-transacting users can access the terms via the progress widget).
  showCommercialChargeTermsHelper(): boolean {
    return (
      this.transactions?.all?.length > 0 &&
      this.user?.accountPurpose === "BUSINESS" &&
      !this.user?.commercialChargeTermsAcceptTime
    );
  }

  // This is a pre-charge-card legacy user who is delinquent in signing charge terms.
  // We know this because we started collecting account purpose at the same time
  // as we started requiring new users to sign charge terms.
  showChargeTermsHelper(): boolean {
    return (
      !!this.user?.hasHadAnyFundingSource &&
      !this.user?.chargeTermsAcceptTime &&
      !this.user?.accountPurpose &&
      !this.user?.manualApplicationSentTime &&
      !this.user?.applicationDeclined &&
      !this.user?.hasManualPayments
    );
  }

  showPendingBusinessReviewHelper(): boolean {
    return (
      !!this.user?.isPendingBusinessReview && !!this.user.organization?.useCase
    );
  }

  showOnboardingHelper(): boolean {
    if (this.user?.applicationDeclined && this.user?.hasManualPayments) {
      return false;
    }

    const emailConfirmed =
      this.user?.emailConfirmed || userStore.getters.seenConfirmEmailStep;

    const hasVerificationNeeded = this.user?.verificationNeeded;

    const isSubscribing = subscriptionStore.getters.isSubscribing();

    const hasBusinessReviewNeeded = this.user?.businessReviewNeeded;

    const onboardingStep = userStore.getters.nextOnboardingStep();

    const hasTransactions = this.transactions?.all?.length > 0;

    const hasRemainingOnboardingSteps =
      !this.hasValidPrepaidFundingCard() &&
      (!emailConfirmed ||
        // We only want to show this for standard onboarding steps if the
        // progress widget isn't also showing (user has already transacted)
        (onboardingStep !== OnboardingSteps.COMPLETE &&
          onboardingStep !== OnboardingSteps.COMMERCIAL_CHARGE_DISCLOSURE &&
          hasTransactions));

    return (
      hasBusinessReviewNeeded ||
      hasVerificationNeeded ||
      isSubscribing ||
      hasRemainingOnboardingSteps
    );
  }

  // If bank has not been connected, disclosure is not signed, or user's KYC info is missing or unverified
  accountPending(): boolean {
    return (
      (this.user?.bankAccountList.length === 0 &&
        this.user?.fundingCardList.filter((card) => {
          return card.state !== "DELETED";
        }).length === 0) ||
      !this.user?.acceptedDisclosure ||
      !this.user?.KYCVerified
    );
  }

  accountActive(): boolean {
    return (
      (!!this.user?.hasHadAnyFundingSource ||
        !!this.subscription?.issuingPlan) &&
      !userStore.getters.isPendingManualPaymentsApproval
    );
  }

  get activeHelpers(): Array<string> {
    let helpers: Record<string, boolean> = {};
    if (this.accountActive()) {
      helpers = {
        COMMERCIAL_CHARGE_TERMS: this.showCommercialChargeTermsHelper(),
        CHARGE_TERMS: this.showChargeTermsHelper(),
        PENDING_BUSINESS_REVIEW: this.showPendingBusinessReviewHelper(),
        ONBOARDING: this.showOnboardingHelper(),
        EXTENSION_INSTALL: this.showExtensionInstallHelper(),
        ACCOUNT_CREDIT: this.showAccountCreditHelper(),
        HIRING_ENGINEERS: false,
        MOBILE: this.showMobileHelper(),
      };
    } else if (this.accountPending()) {
      helpers = {
        ONBOARDING: true,
        EXTENSION_INSTALL: this.showExtensionInstallHelper(),
        MOBILE: this.showMobileHelper(),
        HIRING_ENGINEERS: false,
      };
    }

    // Filter helpers object down to an array of [helpername, true] entries
    const activeHelpers = Object.entries(helpers).filter(
      (helpersItem) => helpersItem[1]
    );

    // Add invite helper only if there are other helpers
    if (activeHelpers.length > 0 && this.showInviteHelper()) {
      activeHelpers.push(["INVITE", true]);
    }

    // Map named keys into a single array,
    // then map those keys to the corresponding helper component names
    return activeHelpers
      .map((activeItem) => activeItem[0])
      .map((helperKey) => HELPER_TYPES[helperKey]);
  }
}
</script>
<style lang="scss" scoped>
@import "./Swiper.scss";

$responsive-column-1300: 1090px;
$responsive-column-1200: 820px;
$responsive-column-920: 550px;

.dashboard-helpers-container {
  position: relative;
  margin: auto;
  width: 1200px;
  user-select: none;
  padding-top: 40px;

  > .helper-prev,
  > .helper-next {
    position: absolute;
    top: 0;
    bottom: 30px;
    display: block;
    margin: auto;
    height: 40px;
    width: 40px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 40px;
    opacity: 0.25;
    transition-property: opacity, background-color;
    transition-duration: $duration-shorter;
    cursor: pointer;

    &:hover {
      background-color: $gray-300;
      opacity: 1;
    }

    &.swiper-button-disabled {
      display: none;
    }

    @media only screen and (max-width: 680px) {
      height: 60px;
      width: 30px;
      border-radius: $radius-default;
    }

    @media #{$media-phone} {
      display: none;
    }
  }

  > .helper-prev {
    background-image: url("/assets/images/icons/chevron-left-20.svg");
    left: -40px;
    right: auto;

    @media only screen and (max-width: 680px) {
      left: -30px;
    }
  }

  > .helper-next {
    background-image: url("/assets/images/icons/chevron-right-20.svg");
    right: -40px;
    left: auto;

    @media only screen and (max-width: 680px) {
      right: -30px;
    }
  }

  @media #{$media-width-1300} {
    max-width: $responsive-column-1300;
  }

  @media #{$media-width-1200} {
    max-width: $responsive-column-1200;
  }

  @media #{$media-width-920} {
    max-width: $responsive-column-920;

    > .dashboard-helpers > .helper-item {
      flex-grow: 1;
    }

    > .dashboard-helpers > .helper-item > .helper {
      flex-grow: 1;
    }
  }

  @media #{$media-phone} {
    max-width: 100%;
  }
}

::v-deep .dashboard-helpers-swiper {
  display: block;
  flex-shrink: 0;
  padding: 10px;
  height: 280px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 9999; // Needs to sit on top of Swiper components
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 10px;
    background: linear-gradient(to right, $white, rgba(255, 255, 255, 0));
  }

  &::after {
    right: 0;
    left: auto;
    background: linear-gradient(to left, $white, rgba(255, 255, 255, 0));
  }

  @media #{$media-phone} {
    padding-right: 20px;
    padding-left: 20px;
    height: 260px;

    &::before,
    &::after {
      display: none;
    }
  }
}

.dashboard-helpers-swiper > .swiper-pagination {
  margin: auto;
  padding-top: 12px;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;

  > .swiper-pagination-bullet {
    height: 8px;
    width: 8px;
    background-color: $gray-900;
    opacity: 0.125;
    border-radius: 50%;
    margin: 0 3px;
  }

  > .swiper-pagination-bullet:only-child {
    display: none;
  }

  > .swiper-pagination-bullet.-active {
    opacity: 1;
  }
}

.helper {
  display: flex;
  box-sizing: border-box;
  padding: 30px;
  flex-direction: column;
  height: 225px;
  background: $white;
  box-shadow: $box-shadow-large-fainter, $box-shadow-hairline-faint;
  border-radius: $radius-large;
}

.dashboard-helpers {
  > .helper-item {
    box-sizing: border-box;
    // XXX: Why do I have to add this?
    flex-shrink: 0;
    height: 225px;
  }
}

.dashboard-helpers > .helper-item > .helper.-empty {
  box-shadow: none;
}
</style>
