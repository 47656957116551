<template>
  <div class="card-graph-list">
    <SearchInput
      :query="query"
      placeholder="Filter cards"
      :searching="query.length"
      :showSearchResults="false"
      @updated-query="handleUpdatedQuery"
    ></SearchInput>
    <div class="card-list" v-if="cardsToDisplay.length">
      <div
        class="_card"
        v-for="card in cardsToDisplay"
        :key="card.cardID"
        :class="{
          '-selected': currentCard && card.cardID === currentCard.cardID,
        }"
        @click="selectCard(card)"
      >
        <div
          class="logo-container"
          :style="
            card.style && (card.style.bgColor || card.style.filename)
              ? {
                  'background-color': '#' + (card.style.bgColor || 'FFF'),
                }
              : undefined
          "
          :class="{
            '-shadow': card.style && card.style.filename && !card.style.bgColor,
            '-digital-wallet': card.type === 'DIGITAL_WALLET',
          }"
        >
          <div
            class="card-logo -merchant"
            :style="{
              'background-image': `url(${
                card.style && getLogoSrc(card.style)
              })`,
            }"
          >
            <div
              class="placeholder"
              :class="{ icon: card.style && card.style.icon }"
              :style="{
                display:
                  card.style && getLogoSrc(card.style) ? 'none' : 'block',
              }"
            >
              {{
                card.style && card.style.icon
                  ? card.style.icon
                  : "&middot;&middot;&middot;" + card.lastFour
              }}
            </div>
          </div>
        </div>
        <div class="info">
          <div class="card-text">
            {{ card.memo || card.hostname || "Unnamed Card" }}
          </div>
          <DollarAmount
            :amount="getTotalSpend(card)"
            :transaction="true"
          ></DollarAmount>
          <div
            class="progress-bar"
            :style="{
              width: getWidth(card),
              'background-color':
                card.style && card.style.bgColor
                  ? '#' + card.style.bgColor
                  : 'auto',
            }"
          ></div>
        </div>
      </div>
    </div>
    <div class="pagination-controls" v-if="cardsToDisplay.length">
      <BaseButton
        variant="light"
        @click="onClickShowMore"
        v-if="filteredCards.length > limit"
      >
        Show More
      </BaseButton>
      <BaseButton
        variant="light"
        @click="onClickShowFewer"
        v-if="limit > INITIAL_LIMIT"
      >
        Show Fewer
      </BaseButton>
    </div>
    <div class="zero-state" v-if="!cardsToDisplay.length">
      {{ !query || !query.length ? "No cards" : "No cards found" }}
    </div>
  </div>
</template>
<script lang="ts">
import isUndefined from "lodash/isUndefined";
import { getDate, DateType } from "@/lib/dates";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Card } from "@/types/Card";
import { Transaction } from "@/types/Transaction";
import { getLogoSrc } from "../util";
import SearchInput from "../components/SearchInput.vue";
import DollarAmount from "../components/DollarAmount.vue";

const INITIAL_LIMIT = 8;

@Component({
  components: {
    SearchInput,
    DollarAmount,
  },
})
export default class CardGraphList extends Vue {
  @Prop({ default: null }) firstDate!: DateType | null;
  @Prop({ default: null }) lastDate!: DateType | null;
  @Prop({ required: true }) cards!: Card[];
  @Prop({ required: true }) transactions!: Transaction[];
  @Prop({ required: true }) currentCard!: Card | null;

  cardsByAmount: { [id: string]: number } = {};
  cardsByPercent: { [id: number]: number } = {};
  limit = INITIAL_LIMIT;
  query = "";

  INITIAL_LIMIT = INITIAL_LIMIT;
  getLogoSrc = getLogoSrc;

  get filteredCards() {
    return this.cards.filter((card: Card) => {
      const lcQuery = this.query.toLowerCase();
      return (
        card.hostname.toLowerCase().includes(lcQuery) ||
        card.memo.toLowerCase().includes(lcQuery)
      );
    });
  }

  get cardsToDisplay() {
    return this.filteredCards.slice(0, this.limit);
  }

  mounted() {
    this.calculateCardMetrics();
  }

  handleUpdatedQuery(query: string) {
    this.query = query;
  }

  @Watch("firstDate")
  @Watch("lastDate")
  calculateCardMetrics() {
    const cardsByAmount: { [id: string]: number } = {};
    const cardsByPercent: { [id: number]: number } = {};

    this.transactions.forEach((transaction: Transaction) => {
      // transaction.dateAuthorized is a YYYY-MM-DD HH:mm:ss string
      const dateAuthorized = getDate(
        transaction.dateAuthorized || transaction.dateSettled
      );

      const isInDateRange = (txDate: DateType) => {
        const { firstDate, lastDate } = this;
        const isAfterFirstDate = firstDate === null || txDate >= firstDate;
        const isBeforeLastDate = lastDate === null || txDate <= lastDate;
        return isAfterFirstDate && isBeforeLastDate;
      };

      if (isInDateRange(dateAuthorized)) {
        if (transaction.cardID && cardsByAmount[transaction.cardID]) {
          cardsByAmount[transaction.cardID] += transaction.amount;
        } else if (transaction.cardID) {
          cardsByAmount[transaction.cardID] = transaction.amount;
        }
      }
    });

    let largestCardUsage = 0;
    this.cards.forEach((card) => {
      // For scenario when card hasn't been used in time period, set usage to 0
      if (!cardsByAmount[card.cardID]) {
        cardsByAmount[card.cardID] = 0;
      }

      if (cardsByAmount[card.cardID] > largestCardUsage) {
        largestCardUsage = cardsByAmount[card.cardID];
      }
    });

    this.cards.forEach((card) => {
      const key = card.cardID;
      cardsByPercent[key] = Math.floor(
        (cardsByAmount[key] / (largestCardUsage || 1)) * 100
      );
      // used to sort cards by amount spent
      card.cardGraphListCalculatedValue = Math.abs(cardsByAmount[key]);
    });

    this.cards.sort((a: Card, b: Card) => {
      if (
        !isUndefined(a.cardGraphListCalculatedValue) &&
        !isUndefined(b.cardGraphListCalculatedValue)
      ) {
        return b.cardGraphListCalculatedValue - a.cardGraphListCalculatedValue;
      }
      return 0;
    });
    this.cardsByAmount = cardsByAmount;
    this.cardsByPercent = cardsByPercent;
  }

  selectCard(card: Card) {
    if (this.currentCard?.cardID === card.cardID) {
      this.$emit("click-card", null);
    } else {
      this.$emit("click-card", card);
    }
  }

  getTotalSpend(card: Card) {
    return this.cardsByAmount[card.cardID] || 0;
  }

  getWidth(card: Card) {
    return this.cardsByPercent[card.cardID]
      ? this.cardsByPercent[card.cardID] + "%"
      : "1px";
  }

  onClickShowMore() {
    this.limit += INITIAL_LIMIT;
  }

  onClickShowFewer() {
    this.limit = INITIAL_LIMIT;
  }
}
</script>
<style lang="scss" scoped>
.card-graph-list {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 280px;
}

.card-graph-list ::v-deep .search-input {
  margin-left: 0;
  height: 40px;
  padding-left: 10px;
  background-color: $gray-100;
  border-radius: 6px;
}

.card-graph-list > .card-list > ._card {
  position: relative;
  display: flex;
  margin-top: 10px;
  padding: 5px 0;
  border-radius: 6px;
  transition: transform;
  transition-duration: $duration-longest;
  transition-timing-function: $ease-out-expo;
  cursor: pointer;

  // hack to simulate a 5px gap between element and outline
  :hover::after,
  .-selected::after {
    content: "";
    position: absolute;
    top: -2px;
    right: -7px;
    bottom: -2px;
    left: -7px;
    border: 2px solid $gray-100;
    border-radius: 10px;
  }

  .-selected::after {
    border-color: $blue;
  }
}

.card-graph-list > .card-list > ._card > .logo-container {
  z-index: 1;
  display: block;
  padding: 10px;
  height: 40px;
  width: 80px;
  min-width: 80px;
  background-color: $gray-800;
  border-radius: 4px;

  &.-shadow {
    box-shadow: $box-shadow-hairline;
  }

  &.-digital-wallet {
    background-color: $color-foreground;
  }

  > .card-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    font-family: $font-stack-ocr;
    font-size: 12px;
    line-height: 11px;
    font-weight: bold;
    color: $white;

    > .icon {
      font-size: 24px;
      line-height: normal;
    }
  }
}

.card-graph-list > .card-list > ._card > .info {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 10px;
  overflow: hidden;

  > .card-text {
    z-index: 1;
    flex: 1;
    margin: 2px 5px 0 0;
    overflow: hidden;
    line-height: initial; // override body line-height
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > .dollar-amount {
    z-index: 1;
    margin-top: 4px;
    font-weight: bold;
    line-height: 100%; // override body line-height
  }

  > .progress-bar {
    position: absolute;
    bottom: 4px;
    height: 10px;
    min-width: 10px;
    background-color: $gray-400;
    border-radius: 4px;
    opacity: 0.3;
  }
}

.card-graph-list > .zero-state {
  margin-top: 25px;
  font-size: 14px;
  color: $gray-600;
}

.card-graph-list > .pagination-controls {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin: 10px 0 0;
  width: 100%;

  > .btn {
    flex-grow: 1;

    & + .btn {
      margin-left: 10px;
    }
  }
}
</style>
