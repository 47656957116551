<template>
  <div
    v-if="activeNotification && !hidden"
    class="top-notification"
    :class="{
      '-dark': activeNotification.variant === NotificationVariants.DARK,
      '-image': !!activeNotification.bank,
    }"
  >
    <div class="column">
      <BankImage
        v-if="activeNotification.bank"
        class="bank-image"
        :bank="activeNotification.bank"
        width="40"
      />
      <p v-html="activeNotification.text"></p>
      <BaseButton
        v-if="activeNotification && activeNotification.onClick"
        class="action"
        :variant="buttonVariant"
        @click="onClick"
      >
        {{ activeNotification.buttonText }}
      </BaseButton>
      <b-dropdown
        v-if="activeNotification && activeNotification.dropdown"
        class="action"
        :variant="buttonVariant"
        :text="activeNotification.buttonText"
      >
        <b-dropdown-item
          v-for="(dropdownItem, index) in activeNotification.dropdown"
          :key="activeNotificationId + '-dropdown-' + index"
          @click="dropdownItem.onClick"
        >
          {{ dropdownItem.text }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <a class="hide" @click="hideNotification">Hide</a>
  </div>
</template>

<script lang="ts">
import { getDate } from "@/lib/dates";
import { Component, Mixins, Watch } from "vue-property-decorator";
import {
  featureStore,
  notificationStore,
  transactionStore,
  subscriptionStore,
  userStore,
} from "@/store";
import { Confirm } from "@/mixins/Confirm";
import { Toast } from "@/mixins/Toast";
import BankImage from "@/components/BankImage.vue";
import { FeatureFlags } from "@/types/LaunchDarkly";
import { NotificationVariants } from "@/types/Notification";
import { OnboardingSteps } from "@/types/Onboarding";

@Component({
  components: {
    BankImage,
  },
})
export default class TopNotification extends Mixins(Confirm, Toast) {
  NotificationVariants = NotificationVariants;

  get user() {
    return userStore.getters.currentUser;
  }

  get subscription() {
    return subscriptionStore.getters.subscription;
  }

  get activeNotification() {
    return notificationStore.getters.activeNotification;
  }

  get activeNotificationId() {
    return this.activeNotification?.id;
  }

  get hidden() {
    return notificationStore.getters.hidden;
  }

  get buttonVariant() {
    return this.activeNotification?.variant === NotificationVariants.DARK
      ? "primary"
      : "dark";
  }

  // We shouldn't need to use watch here - refactor
  @Watch("user", { deep: true, immediate: true })
  onUserUpdate() {
    this.readOnlyNotification();
    this.adminNotification();
    this.featureFlagBanner();
    this.manualRepaymentNotification();
    this.failedTransactionNotification();
  }

  @Watch("subscription", { deep: true, immediate: true })
  onSubscriptionUpdate() {
    this.delinquentSubscriptionNotification();
    this.pendingSubscriptionNotification();
  }

  onClick() {
    if (!this.activeNotification?.onClick) {
      return;
    }

    this.activeNotification.onClick();
  }

  hideNotification() {
    notificationStore.mutations.hideNotification();
  }

  readOnlyNotification() {
    if (this.user?.readOnly) {
      notificationStore.mutations.addNotification({
        id: "read_only",
        priority: 5,
        text: `You are accessing ${this.user.preferredName}'s account in read only mode.`,
      });
    } else {
      notificationStore.mutations.dismissNotification("read_only");
    }
  }

  adminNotification() {
    if (this.user?.blockExt && !this.user?.readOnly) {
      notificationStore.mutations.addNotification({
        id: "admin_viewer",
        priority: 5,
        text: `You are accessing ${this.user.preferredName}'s account.`,
      });
    } else {
      notificationStore.mutations.dismissNotification("admin_viewer");
    }
  }

  featureFlagBanner() {
    const bannerContents = featureStore.getters.flag(
      FeatureFlags.NOTIFICATION_BANNER
    );
    if (bannerContents) {
      notificationStore.mutations.addNotification({
        id: "feature_flag",
        priority: 5,
        text: bannerContents,
      });
    } else {
      notificationStore.mutations.dismissNotification("feature_flag");
    }
  }

  manualRepaymentNotification() {
    if (this.user?.manualBatchData?.hasBatchesToApprove) {
      const today = getDate().startOf("day");

      const getDueDate = () => {
        // Due date is 14 days after the last issued statement
        // Statements are issued 7 days after the date range of transactions
        // ex. transactions from day 0-14, statement issued day 21, payment due day 35.

        const oldestUnapprovedBatch = getDate(
          this.user?.manualBatchData?.oldestUnapprovedBatch
        );

        const curDate = getDate();
        const dateAccountCreated = getDate(this.user?.created).startOf("day");
        // first statement includes days 0-14, issued after a 7 day wait period for txns to settle
        const firstStatementIssued = getDate(dateAccountCreated).add(
          21,
          "days"
        );

        let dueDate = getDate(firstStatementIssued);
        while (dueDate.isBefore(curDate)) {
          const endOfStatementRange = getDate(dueDate).subtract(21, "days");

          if (oldestUnapprovedBatch.isBefore(endOfStatementRange)) {
            // The user still hasnt payed a charge from this statement.
            // Their due date is in the past.
            // Don't increment their due date.
            break;
          }

          dueDate = getDate(dueDate).add(14, "days");
        }

        return dueDate;
      };

      const dueDate = getDueDate();
      const formattedDueDate = dueDate.format("MM/DD/YYYY");
      const { totalPenniesOwed } = this.user.manualBatchData;
      const { latestBatchID } = this.user.manualBatchData;

      if (!totalPenniesOwed || !latestBatchID) return;

      const formattedAmount = `$${(Number(totalPenniesOwed) / 100).toFixed(2)}`;
      notificationStore.mutations.addNotification({
        id: "manual_repayment",
        priority: 6,
        text: dueDate.isAfter(today)
          ? `Pay your outstanding balance
                            of ${formattedAmount} by ${formattedDueDate} to continue using Privacy`
          : `Your outstanding balance of ${formattedAmount} is past due (${formattedDueDate}).
                            Please pay to continue using your Privacy account.`,
        buttonText: "Pay Balance",
        dismissManually: true,
        onClick: () => {
          this.confirm(
            `Your connected funding source will be debited ${formattedAmount}`,
            {
              title: "Are you sure?",
            }
          ).then((confirmed) => {
            if (!confirmed) return;
            transactionStore.actions
              .manuallyApproveBatch({
                latestBatchID,
                totalPenniesApproved: totalPenniesOwed,
              })
              .then(() => {
                this.successToast("Your payment has been received!");
                notificationStore.mutations.dismissNotification(
                  "manual_repayment"
                );
              })
              .catch(() => {
                this.errorToast(
                  "There was a problem processing your payment.  Please try again later."
                );
              });
          });
        },
      });
    } else {
      notificationStore.mutations.dismissNotification("manual_repayment");
    }
  }

  failedTransactionNotification() {
    if (this.user?.failedTransaction) {
      notificationStore.mutations.addNotification({
        id: "failed_transaction",
        priority: 70,
        text: "Your account is temporarily paused while we try to resettle a recent transaction.",
      });
    } else {
      notificationStore.mutations.dismissNotification("failed_transaction");
    }
  }

  delinquentSubscriptionNotification() {
    const subscriptionName = this.subscription?.displayName || "subscription";

    if (
      this.subscription?.delinquentAmount &&
      this.subscription?.state === "DELINQUENT"
    ) {
      notificationStore.mutations.addNotification({
        id: "delinquent_subscription",
        priority: 75,
        text: `Your last Privacy ${subscriptionName} charge of $${
          this.subscription.delinquentAmount / 100
        } failed.`,
        buttonText: "Update your billing information",
        onClick: () => {
          const billingRoute = `onboarding-${OnboardingSteps.BILLING}`;
          this.$router.push({ name: billingRoute });
        },
      });
    } else {
      notificationStore.mutations.dismissNotification(
        "delinquent_subscription"
      );
    }
  }

  pendingSubscriptionNotification() {
    const subscriptionName = this.subscription?.displayName || "subscription";

    if (
      this.subscription?.pendingFailure &&
      this.subscription?.state === "PENDING"
    ) {
      notificationStore.mutations.addNotification({
        id: "pending_subscription",
        priority: 75,
        text: `Your Privacy ${subscriptionName} charge failed.`,
        buttonText: "Update your billing information",
        onClick: () => {
          const billingRoute = `onboarding-${OnboardingSteps.BILLING}`;
          this.$router.push({ name: billingRoute });
        },
      });
    } else {
      notificationStore.mutations.dismissNotification("pending_subscription");
    }
  }
}
</script>

<style lang="scss" scoped>
.top-notification {
  background: $blue;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: $white;

  &.-dark {
    background-color: $gray-800;
  }

  > .column {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    > p {
      margin-bottom: 0;
    }

    .bank-image {
      flex-grow: 0;
      margin-right: 20px;
    }

    .action {
      flex-shrink: 0;
      margin-left: 10px;
    }

    @media #{$media-phone} {
      flex-direction: column;

      .bank-image {
        display: block;
        margin: 0 auto 10px;
      }
    }
  }

  &.-image > .column {
    > p {
      padding-right: 20px;
      text-align: left;
    }

    > .action {
      margin-left: auto;
    }

    @media #{$media-phone} {
      > p {
        margin-bottom: 10px;
        padding-right: 0;
        text-align: center;
      }

      > .action {
        margin-left: 0;
        align-self: stretch;
      }
    }
  }

  > .hide {
    display: none;
    align-items: center;
    justify-content: center;
    height: 50px;
    color: $white;
    background-color: $color-shadow-black-faint;
    border-top: 1px solid $color-shadow-black;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      color: $white;
    }

    @media #{$media-phone} {
      display: flex;
    }
  }

  &.-dark > .hide {
    border-top-color: fade-out($white, 0.8);
    color: fade-out($white, 0.5);
  }

  @media #{$media-phone} {
    position: fixed;
    right: 10px;
    bottom: 10px;
    left: 10px;
    box-shadow: $box-shadow-large;
    border-radius: $radius-large;
    opacity: 0;
    animation: $animation-fade-in-up;
    animation-duration: $duration-longest;
    animation-delay: 1s;
  }
}
</style>
