import { RouteConfig } from "vue-router";
import OneClick from "@/views/oneclick/OneClick.vue";
import OneClickSuccess from "@/views/oneclick/OneClickSuccess.vue";
import OneClickError from "@/views/oneclick/OneClickError.vue";
import paths from "./paths.json";

const routes: Array<RouteConfig> = [
  {
    path: paths.oneclick,
    component: OneClick,
    children: [
      {
        path: "mcc-bypass/success",
        component: OneClickSuccess,
        name: "mcc-bypass-success",
        meta: {
          title: "MCC Bypass - Success",
        },
      },
      {
        path: "mcc-bypass/:status",
        component: OneClickError,
        name: "mcc-bypass-error",
        meta: {
          title: "MCC Bypass - Error",
        },
      },
      {
        path: "success",
        component: OneClickSuccess,
        name: "oneclick-success",
        meta: {
          title: "Email Action - Success",
        },
      },
      {
        path: ":status",
        component: OneClickError,
        name: "oneclick-error",
        meta: {
          title: "Email Action - Error",
        },
      },
    ],
  },
];

export default routes;
