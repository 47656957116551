import axios from "axios";
import { Repayment } from "@/types/Repayment";
import { ActionHandler } from "./vuex-typex";
import { RootState, storeBuilder } from "./storeBuilder";

type RepaymentState = Record<string, never>;

type RepaymentAction<Payload = void, Type = void> = ActionHandler<
  RepaymentState,
  RootState,
  any,
  Payload,
  Type
>;

const builder = storeBuilder.module<RepaymentState>("promo", {});
const base = "/api/v1/delinquent";

const settle: RepaymentAction<Partial<Repayment>, Repayment> = (
  context,
  repayment
) => {
  return axios.post(`${base}/settle`, { repayment });
};

export const actions = {
  settle: builder.dispatch(settle),
};
