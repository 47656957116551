<template>
  <div class="profile-card">
    <div>
      <AccountPhoto :user="user" />
    </div>
    <div class="account-card">
      <div class="name">{{ name }}</div>
      <div class="identifier">{{ email }}</div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { User } from "@/types/User";
import AccountPhoto from "@/components/AccountPhoto.vue";

@Component({
  components: {
    AccountPhoto,
  },
})
export default class ProfileCard extends Vue {
  @Prop({ required: true }) user!: User;

  get name() {
    if (!this.user.preferredName) {
      return "Privacy User";
    }
    return `${this.user.preferredName || ""} ${this.user.lastName || ""}`;
  }

  get email() {
    return this.user.email;
  }
}
</script>
<style lang="scss" scoped>
.profile-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 20px 30px;
  box-shadow: $box-shadow-hairline, $box-shadow-default-faint;
  border-radius: $border-radius-lg;

  .account-card {
    margin-left: 20px;
  }

  .account-card > .name {
    font-family: $font-stack-wes-fy;
    font-size: 20px;
  }

  .account-card > .identifier {
    font-weight: bold;
    color: $gray-600;
  }
}
</style>
