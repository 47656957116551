<template>
  <div
    class="card-list"
    ref="cardList"
    v-show="!loadingCardsPage"
    data-test="card-list"
  >
    <div class="_card" v-for="card in cardsToDisplay" :key="card.cardID">
      <PrivacyCard
        :cardProp="card"
        :preview="true"
        @click-card="onClickCard(card)"
        v-on="$listeners"
      />
      <TagRow
        v-if="showTags && selectedTagIds"
        @click-tag="openTagMenu"
        :cardID="card.cardID"
      >
      </TagRow>
    </div>
    <div class="zero-state _card" v-if="!cardsToDisplay.length">
      <div class="empty-card">No Cards</div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import { tagStore } from "@/store";
import { Card } from "@/types/Card";
import { CardListPropsAndHandlers } from "../mixins/CardListPropsAndHandlers";
import { Toast } from "../mixins/Toast";
import PrivacyCard from "./PrivacyCard.vue";
import TagRow from "./TagRow.vue";

@Component({
  components: {
    PrivacyCard,
    TagRow,
  },
})
export default class CardListPaginated extends Mixins(
  Toast,
  CardListPropsAndHandlers
) {
  @Prop({ default: false }) loadingCardsPage!: boolean;

  created() {
    this.tagList = tagStore.getters.getTags;
    this.limit = this.limitProp;
  }

  get cardsToDisplay(): Card[] {
    return this.cardsProp.length ? this.cardsProp : [];
  }
}
</script>
<style lang="scss" scoped>
.card-list {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > ._card {
    display: flex;
    flex-basis: 200px;
    flex-direction: column;
    margin-bottom: 20px;
  }

  > ._card > .details {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    max-width: 200px;
  }

  > ._card > .details > .name {
    flex-grow: 1;
    max-width: 120px;
    overflow: hidden;
    font-size: 13px;
    white-space: nowrap;
  }

  > ._card > .details > .controls {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    opacity: 0;
    transition: opacity $duration-shorter;
  }

  > ._card:hover > .details > .controls {
    opacity: 1;
  }

  > ._card > .details > .controls > .svg-icon {
    opacity: 0.5;
    transition: opacity $duration-shorter;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  > ._card > .tag-row {
    width: 240px;
    margin-top: 10px;
  }

  .empty-card {
    height: 150px;
    width: 240px;
    background-color: $white;
    border-radius: 16px;
    border: 2px dashed rgba(141, 149, 166, 0.2);
    color: $gray-600;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
