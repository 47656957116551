<template>
  <div class="search-input">
    <SVGIcon icon="magnifier"></SVGIcon>
    <input
      :placeholder="placeholder"
      v-model="query"
      class="search"
      @focus="handleFocus"
    />
    <form @submit="updateQuery($event)">
      <input
        :placeholder="placeholder"
        v-model="mobileQuery"
        ref="mobile-search"
        class="search"
        @focus="handleFocus"
      />
    </form>
    <BaseButton
      variant="transparent"
      @click="close()"
      color="grey-lighter"
      size="small"
      class="close-search"
      aria-label="close"
    >
      <SVGIcon icon="cross"></SVGIcon>
    </BaseButton>
    <SearchResults
      v-if="showSearchResults && !!query"
      :query="query"
      @search-results-closed="handleSearchResultsClosed"
    />
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import SVGIcon from "./SVGIcon.vue";
import SearchResults from "./SearchResults.vue";

@Component({
  components: {
    SVGIcon,
    SearchResults,
  },
})
export default class SearchInput extends Vue {
  @Prop({ required: true, default: false }) searching!: boolean;
  @Prop({ default: "Search cards and transactions" }) placeholder!: string;
  @Prop({ default: true }) showSearchResults!: boolean;

  query = "";
  mobileQuery = "";

  @Watch("query")
  handleUpdatedQuery() {
    this.$emit("updated-query", this.query);
  }

  handleFocus() {
    this.$emit("focus");
  }

  @Watch("searching")
  focus() {
    if (this.searching) {
      (this.$refs["mobile-search"] as HTMLElement).focus();
    }
  }

  // on mobile, only start searching when form is submitted
  updateQuery(e: Event) {
    e.preventDefault();
    (this.$refs["mobile-search"] as HTMLElement).blur();
    this.query = this.mobileQuery;
  }

  close() {
    this.query = "";
    this.mobileQuery = "";
    this.$emit("search-input-closed");
  }

  handleSearchResultsClosed() {
    this.close();
    this.$emit("search-results-closed");
  }
}
</script>
<style lang="scss" scoped>
.search-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  margin-left: 20px;
  height: 60px;
  background: $white;

  @media #{$media-phone} {
    animation: $animation-fade-in-down;
    animation-duration: $duration-long;

    > .search {
      display: none;
    }
  }

  > .svg-icon {
    position: relative;
    align-self: center;
    margin-right: 10px;
    height: 60px;
    transform: scale(0.75);
    cursor: pointer;

    ::v-deep svg * {
      fill: $gray-600;
    }
  }

  &:hover > .svg-icon ::v-deep svg *,
  &:focus-within > .svg-icon ::v-deep svg * {
    fill: $gray-800;
  }

  .search {
    flex-grow: 1;
    padding: 0;
    width: 100%;
    background: none;
    border: 0;
    font: inherit;
    font-weight: bold;
    outline: none;
  }

  > form {
    display: none;

    @media #{$media-phone} {
      display: flex;
      flex-grow: 1;
      align-items: center;
    }
  }

  > .close-search {
    display: none;

    @media #{$media-phone} {
      background: $gray-100;
      height: 40px;
      width: 40px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      margin-left: 10px;
      padding: 0px;
    }
  }
}
</style>
