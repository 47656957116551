import { Event } from "./Event";

// #region v2 Funding type definitions

export enum BankAccountState {
  PENDING = "PENDING",
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
  DELETED = "DELETED",
}

export enum FundingCardState {
  INVALID = "INVALID",
  PENDING = "PENDING",
  ENABLED = "ENABLED",
  DELETED = "DELETED",
  EXPIRED = "EXPIRED",
  FRAUD = "FRAUD",
}

export enum FundingType {
  CARD = "CARD",
  BANK_ACCOUNT = "BANK_ACCOUNT",
}

export enum FundingCardType {
  STANDARD = "STANDARD",
  SUBSCRIPTION = "SUBSCRIPTION",
}

export interface V2FundingSource {
  created: string; // ISO 8601 date string
  default: boolean;
  fundingSourceUuid: string;
  fundingType: string | FundingCardType; // Also includes BankType enum from core - not sure if we want to add it here
  icon: string;
  institution: string;
  name?: string;
  number: string;
  state: BankAccountState | FundingCardState;
  type: FundingType;
  uuid: string;
}

export interface CreateFundingCard {
  address1?: string;
  address2?: string;
  cvv: string;
  expMonth: string;
  expYear: string;
  isSubscription: boolean;
  pan: string;
  zip: string;
}

// #endregion

// #region Legacy funding type definitions

export enum FundingSource {
  CARD = "card",
  BANK = "bank",
}

export enum FundingStep {
  SELECT_TYPE,
  ADD_CARD,
  CONFIRM_CARD,
  ADD_BANK,
  CONFIRM_BANK,
  ADD_FUNDS,
  ADDITIONAL_VERIFICATION,
  BILLING,
}

export interface FundingBank {
  accountName: string;
  bank: string;
  bankAccountID: string;
  bankName: string;
  created: string;
  default: boolean;
  fundingSourceUuid: string;
  nickname: string;
  number: string;
  state: string;
  tokenState: string;
  type: string;
  uuid: string;
}

type WithDropdownMenu<T> = T & {
  dropdownMenu: Array<{
    class: string;
    label: string;
    onClick: (event: Event) => void;
    hide?: () => boolean;
  }>;
};

export type FundingBankWithDropdown = WithDropdownMenu<FundingBank>;

export interface FundingCard {
  pan: string;
  cvv: string;
  bin?: string;
  expMonth: string;
  expYear: string;
  avs?: {
    address1: string;
    address2: string;
    zip: string;
  };
  ancillaryData?: string;
  // todo-ts: type this
  isPending?: boolean;
  network?: string;
  state?: string;
  uuid?: string;
  lastFour?: string;
  fundingLastFour?: string;
}

export type FundingSourceType = FundingCard | FundingBank;

export type FundingCardWithDropdown = WithDropdownMenu<FundingCard>;

// #endregion
