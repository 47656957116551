<template>
  <div class="kyb-verification-pages">
    <div class="kyb-verification-card pt-3">
      <div class="container-close">
        <SVGIcon icon="cross" @click="dashboard" class="close-icon"></SVGIcon>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import SVGIcon from "@/components/SVGIcon.vue";

@Component({
  components: {
    SVGIcon,
  },
})
export default class KybVerificationContainer extends Vue {
  dashboard() {
    this.$router.push({ name: "home" });
  }
}
</script>

<style lang="scss">
.kyb-verification-pages {
  background: #eef2f580;
  max-width: 100vw;
  overflow: hidden;
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;

  .kyb-verification-card {
    display: flex;
    width: 400px;
    padding: 40px;
    padding-bottom: 30px;
    margin-bottom: 100px;
    margin-top: 100px;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    gap: 24px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
    position: relative;

    .close-icon {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }

    .heading {
      color: #323242;
      text-align: center;
      font-family: $font-stack-fk-grotesk;
      font-size: 16px;
      letter-spacing: 0.16px;
      align-self: stretch;
    }

    .divider {
      width: 100%;
      height: 1px;
      background: #f0f0f5;
    }

    .form {
      text-align: left;
    }

    @media #{$media-phone} {
      width: 100%;
      .form {
        width: 100%;
      }
    }
  }

  @media #{$media-width-820} {
    padding-top: 90px;
  }

  @media #{$media-phone} {
    padding-top: 70px;
  }
}
</style>
