<template>
  <div class="home" data-test="home">
    <BannerPending
      v-if="shouldShowPendingBanner"
      :manualPayments="
        user && user.manualApplicationSentTime && !user.verificationNeeded
      "
      @start-onboarding="onStartOnboarding"
    />
    <BannerRemediate
      v-if="shouldShowRemediationBanner"
      @start-remediation="onStartOnboarding"
    />
    <BannerOFACRemediation v-if="shouldShowOFACRemediationBanner" />
    <BannerKybVerification v-if="shouldShowKybVerificationBanner" />
    <BannerPendingAlternateFundingRequest
      v-if="shouldShowPendingAlternateFundingRequestBanner"
    />
    <Helpers
      v-if="userLoaded && cardsTransactionsLoaded"
      @start-onboarding="onStartOnboarding"
      @show-commercial-charge-terms-reminder="showCommercialChargeTermsReminder"
      @create-card="openCreateCard"
    />
    <div
      class="spinner-container"
      v-if="!(userLoaded && cardsTransactionsLoaded)"
    >
      <b-spinner></b-spinner>
    </div>
    <div class="home-list-container" v-else>
      <div class="mobile-controls">
        <div
          class="tab"
          :class="{ '-active': activeMobileTab === 'Cards' }"
          @click="activeMobileTab = 'Cards'"
        >
          Cards
        </div>
        <div
          class="tab"
          :class="{ '-active': activeMobileTab === 'Activity' }"
          @click="activeMobileTab = 'Activity'"
        >
          Activity
        </div>
        <router-link to="/summary" class="summary mx-auto">
          <SVGIcon icon="summary" size="40"></SVGIcon>
          Summary
          <SVGIcon icon="chevron-right" class="chevron"></SVGIcon>
        </router-link>
        <BaseButton
          variant="primary"
          size="sm"
          @click="openCreateCard"
          class="new-card"
          data-test="mobile-create-card"
        >
          New Card
        </BaseButton>
      </div>
      <div class="cards" :class="{ '-active': activeMobileTab === 'Cards' }">
        <div class="desktop-controls">
          <h2 class="title">
            <b-link :to="{ name: 'cards' }">Cards</b-link>
          </h2>
          <BaseButton
            variant="primary"
            size="sm"
            @click="openCreateCard"
            data-test="new-card"
          >
            New Card
          </BaseButton>
        </div>
        <CardListComponent
          :showTags="false"
          :cardsProp="visibleCards"
          @click-card="inspectCard"
          :limitProp="4"
          data-test="home-card-list"
        />
        <BaseButton
          class="see-all"
          v-if="visibleCards.length > 3"
          variant="light"
          size="md"
          @click="navigateToCardsPage"
          block
        >
          See All Cards
        </BaseButton>
      </div>
      <div
        class="activity"
        :class="{ '-active': activeMobileTab === 'Activity' }"
      >
        <div class="desktop-controls">
          <h2 class="title">
            <b-link to="/transactions">Activity</b-link>
          </h2>
          <router-link to="/summary" class="summary">
            <SVGIcon icon="summary" size="40" class="mr-1"></SVGIcon>
            Summary
            <SVGIcon icon="chevron-right" class="chevron"></SVGIcon>
          </router-link>
        </div>
        <AccountSnapshot></AccountSnapshot>
        <TransactionListComponent
          :limitProp="8"
          :transactionsProp="transactionList.all"
          :showThumbnails="true"
          @click-transaction="inspectTransaction"
        />
        <BaseButton
          v-if="transactionList.all.length"
          class="see-all"
          variant="light"
          size="md"
          block
          @click="navigateToTransactionsPage"
        >
          See All Activity
        </BaseButton>
      </div>
      <ProgressWidget
        v-if="shouldShowProgressWidget && progressWidgetVisible"
        @start-onboarding="onStartOnboarding"
        @create-sample-card="handleSampleCard"
        @hide-widget="handleHideWidget"
      />
    </div>
    <CardInspector
      id="card-inspector"
      :cardProp="currentCard"
      :transactionProp="currentTransaction"
      :user="user"
      @hide="handleCloseCardInspector"
      @start-onboarding="onStartOnboarding"
      @switch="switchCurrentCard"
    />
    <CardCreate
      id="card-create"
      :type="newCardType"
      :category="newCardCategory"
      @create-success="handleCreateCardSuccess"
    />
    <BaseModal id="onboarding-modal" class="onboarding-modal" static lazy>
      <OnboardingContainer
        @finished-onboarding="onFinishedOnboarding"
        :isSwitchingToAutomatic="isPendingManualPaymentsApproval"
      />
    </BaseModal>
    <BaseModal id="charge-terms-reminder">
      <ChargeTermsReminder @start-onboarding="onStartOnboarding" />
    </BaseModal>
    <BaseModal id="commercial-charge-terms-reminder" hide-header>
      <CommercialChargeTermsReminder :user="user" />
    </BaseModal>
    <VaultedCardsModal
      id="vaulted-cards-modal"
      :phase="cardPhase"
      :selectionLimit="cardCreateLimit"
      :canUpdatePlan="canUpdatePlan"
      @skip="handleVaultedCardSkip"
      @create-success="$bvModal.hide('card-create')"
    />
    <SelectCardTypeModal @select="changeCardType" />
    <CategoryCardCreateModal
      id="category-card-create-modal"
      @select="createCategoryCard"
      @back="categoryCardGoBack"
    />
  </div>
</template>
<script lang="ts">
import { Mixins, Component } from "vue-property-decorator";
import { Card, CardType } from "@/types/Card";
import { Transaction, TransactionList } from "@/types/Transaction";
import {
  userStore,
  cardStore,
  transactionStore,
  subscriptionStore,
  notificationStore,
  eventStore,
  loginStore,
  merchantCategoriesStore,
} from "@/store";
import { Confirm } from "@/mixins/Confirm";
import { Toast } from "@/mixins/Toast";
import BannerPending from "@/components/banners/BannerPending.vue";
import BannerRemediate from "@/components/banners/BannerRemediate.vue";
import BannerOFACRemediation from "@/components/banners/BannerOFACRemediation.vue";
import BannerPendingAlternateFundingRequest from "@/components/banners/BannerPendingAlternateFundingRequest.vue";
import Helpers from "@/components/helpers/Helpers.vue";
import CardInspector from "@/components/CardInspector.vue";
import CardListComponent from "@/components/CardList.vue";
import CardCreate from "@/components/CardCreate.vue";
import CategoryCardCreateModal from "@/components/card/CategoryCardCreateModal.vue";
import TransactionListComponent from "@/components/TransactionList.vue";
import AccountSnapshot from "@/components/AccountSnapshot.vue";
import ProgressWidget from "@/components/ProgressWidget.vue";
import OnboardingContainer from "@/views/OnboardingContainer.vue";
import ChargeTermsReminder from "@/views/ChargeTermsReminder.vue";
import CommercialChargeTermsReminder from "@/views/CommercialChargeTermsReminder.vue";
import { EVENTS } from "@/types/Event";
import { AccountPurposes, KybVerificationUserStatus, User } from "@/types/User";
import { NotificationVariants } from "@/types/Notification";
import { parseCentAmount, INITIAL_TXN_LIMIT } from "@/util";
import VaultedCardsModal from "@/components/vaulted-cards/Modal.vue";
import SelectCardTypeModal from "@/components/card/SelectCardTypeModal.vue";
import { formatDate, nextBankDay, utcDate } from "@/lib/dates";
import { MerchantCategory } from "@/types/MerchantCategory";
import BannerKybVerification from "@/components/banners/BannerKybVerification.vue";
import SVGIcon from "../components/SVGIcon.vue";

@Component({
  components: {
    BannerPending,
    BannerRemediate,
    BannerPendingAlternateFundingRequest,
    Helpers,
    CardInspector,
    AccountSnapshot,
    CardCreate,
    CardListComponent,
    TransactionListComponent,
    ProgressWidget,
    OnboardingContainer,
    ChargeTermsReminder,
    CommercialChargeTermsReminder,
    VaultedCardsModal,
    SelectCardTypeModal,
    SVGIcon,
    CategoryCardCreateModal,
    BannerOFACRemediation,
    BannerKybVerification,
  },
})
export default class Home extends Mixins(Confirm, Toast) {
  userLoaded = false;
  cardsTransactionsLoaded = false;
  currentCard: Partial<Card> | null = null;
  currentTransaction: Transaction | null = null;
  activeMobileTab = "Cards";
  transactionList: TransactionList = { all: [], declines: [] };
  newCardType: CardType | "" = "";
  newCardCategory: MerchantCategory | null = null;
  cardPhase = "onboarding";
  progressWidgetVisible = true;

  get user(): User | null {
    return userStore.getters.currentUser;
  }

  get hasHadAnyFundingSource(): boolean {
    return !!this.user?.hasHadAnyFundingSource;
  }

  get isPendingManualPaymentsApproval(): boolean {
    return !!userStore.getters.isPendingManualPaymentsApproval;
  }

  get hasExpiringCards(): boolean {
    return cardStore.getters.chargeReissue.expiring.length > 0;
  }

  get hasAutoClosedCards(): boolean {
    return cardStore.getters.chargeReissue.closed.length > 0;
  }

  get shouldShowPendingBanner(): boolean {
    if (this.user?.manualApplicationSentTime && !this.user?.hasManualPayments) {
      // user is waiting for manual payments application to be reviewed
      return true;
    } else if (
      this.user?.chargeTermsAcceptTime &&
      this.user?.verificationNeeded &&
      !this.user?.isRemediatingKYC
    ) {
      // user has completed charge card flow but has been YP'd
      return true;
    }

    return false;
  }

  get shouldShowRemediationBanner(): boolean {
    if (this.user?.canBypassSsn) {
      return false;
    }
    return !!this.user?.isRemediatingKYC;
  }

  get shouldShowPendingAlternateFundingRequestBanner(): boolean {
    return !!this.user?.hasPendingAlternateFundingRequest;
  }

  get shouldShowProgressWidget(): boolean {
    return (
      !!this.user?._id &&
      !this.transactionList?.all?.length &&
      !subscriptionStore.getters.isIssuingUser &&
      !this.isPendingManualPaymentsApproval
    );
  }

  get shouldShowCommercialChargeTermsReminder(): boolean {
    const hasTransactions = !!this.transactionList?.all?.length;
    return (
      !this.user?.commercialChargeTermsAcceptTime &&
      this.user?.accountPurpose === "BUSINESS" &&
      this.user?.hasHadAnyFundingSource &&
      hasTransactions
    );
  }

  get shouldShowOFACRemediationBanner(): boolean {
    const isPending = !!this.user?.isPendingBusinessInfo;
    const hasProvidedInfo =
      this.user?.organization?.typeComplete &&
      this.user?.organization?.detailsComplete &&
      this.user?.organization?.useCaseComplete;
    return (
      isPending && !hasProvidedInfo && !this.shouldShowKybVerificationBanner
    );
  }

  get shouldShowKybVerificationBanner() {
    const isKybInProgress =
      !!this.user?.kybVerificationUser &&
      [
        KybVerificationUserStatus.NOT_STARTED,
        KybVerificationUserStatus.IN_PROGRESS,
      ].includes(this.user.kybVerificationUser.status);

    return isKybInProgress;
  }

  get subscription() {
    return subscriptionStore.getters.subscription;
  }

  get canUpdatePlan(): boolean {
    const { plans } = subscriptionStore.getters;
    const priciestPlan = plans[plans.length - 1];
    return this.subscription?.subscriptionType !== priciestPlan?.type;
  }

  get cardCreateLimit(): number {
    return (
      this.subscription?.cardsPerMonth ||
      subscriptionStore.DEFAULT_CARD_CREATE_LIMIT
    );
  }

  get visibleCards(): Card[] {
    const cards = cardStore.getters.cardList();
    const sampleCard = this.user?.sampleCard;

    if (!cards.all.length && sampleCard) {
      if (!Array.isArray(sampleCard)) {
        return [sampleCard] as Card[];
      }

      return sampleCard;
    }

    return cards.open;
  }

  mounted(): void {
    if (this.user?._id) {
      this.handleLoadedUser();
    } else {
      this.userLoaded = false;
      userStore.actions
        .getCurrentUser()
        .then(() => {
          this.handleLoadedUser();
        })
        .catch(() => {
          /* noop */
        });
    }
  }

  handleLoadedUser(): void {
    this.showChargeReminders();
    this.fetchData();
    this.userLoaded = true;

    const { justLoggedIn } = loginStore.getters;
    // optional query param to force starting remediation flow,
    // so CX can send a direct link
    const verifyInfoParam = !!this.$route.query.verifyInfo;
    if (justLoggedIn || verifyInfoParam) {
      this.showRemediationSteps();
      if (justLoggedIn) {
        loginStore.mutations.setJustLoggedIn(false);
      }
    }

    if (window.sessionStorage.getItem("reopenVaultedCards")) {
      this.$bvModal.show("vaulted-cards-modal");
    }
  }

  handleHideWidget() {
    this.progressWidgetVisible = false;
  }

  fetchData(): void {
    Promise.all([
      cardStore.actions.getCards({}),
      transactionStore.actions.fetchTransactions({ limit: INITIAL_TXN_LIMIT }),
      merchantCategoriesStore.actions.getMerchantCategories(),
    ])
      .then((result) => {
        this.transactionList = result[1];
        this.cardsTransactionsLoaded = true;

        if (this.shouldShowCommercialChargeTermsReminder) {
          this.showCommercialChargeTermsReminder();
        }

        const hasBounces = this.transactionList.all.some(
          ({ hasBounced }) => hasBounced
        );
        if (hasBounces) {
          this.loadBounces();
        }
      })
      .catch((error) => {
        let message = "Error loading page.";
        this.cardsTransactionsLoaded = true;

        if (error?.response?.status === 403) {
          message = " Please contact support@privacy.com.";
        }

        if (error?.response?.status !== 401) {
          this.errorToast(message);
        }
      });
  }

  loadBounces(): void {
    transactionStore.actions.getBounces().then(({ data }) => {
      const { bounces } = data;

      if (bounces.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        bounces.forEach((bounce: any) => {
          const dropdownOptions = (this.user?.bankAccountList || []).map(
            (bank) => {
              const name = bank.nickname || bank.accountName;
              const estimatedResettleDay = nextBankDay();

              return {
                text: `${name} (···${bank.number})`,
                onClick: () => {
                  this.confirm(
                    `${parseCentAmount(
                      bounce.totalAmount
                    )} will be settled to ${name} ${estimatedResettleDay}`,
                    {
                      title: "Confirm balance resettle",
                      okButton: "Confirm",
                    }
                  ).then((confirmed) => {
                    if (!confirmed) return;
                    transactionStore.actions
                      .retryBatch({
                        batchIDs: bounce.batchIDs,
                        newBankAccountID: bank.bankAccountID,
                        initiatedByUser: true,
                      })
                      .then(() => {
                        this.successToast(
                          "Your resettlement has been submitted."
                        );
                        notificationStore.mutations.dismissNotification(
                          `resettle-${bounce.fundingBank}`
                        );
                      })
                      .catch(() => {
                        this.errorToast(
                          "There was a problem processing your resettlement. Please try again later."
                        );
                      });
                  });
                },
              };
            }
          );

          notificationStore.mutations.addNotification({
            id: `resettle-${bounce.fundingBank}`,
            priority: 5,
            text: `<b>${
              bounce.fundingAccount
            }</b> returned with insufficient funds when we tried to settle <b>${parseCentAmount(
              bounce.totalAmount
            )}</b> on <b>${this.dateToString(bounce.dateResettle)}</b>.`,
            bank: bounce.fundingBank,
            variant: NotificationVariants.DARK,
            dropdown: dropdownOptions,
            buttonText: `Resettle ${parseCentAmount(bounce.totalAmount)}`,
          });
        });
      }
    });
  }

  dateToString(date: Date | string) {
    return formatDate("MMM DD, YYYY", utcDate(date, {}, true).local());
  }

  showRemediationSteps(): void {
    if (this.user?.isRemediatingKYC) {
      this.onStartOnboarding();
    }
  }

  showChargeReminders(): void {
    const { user } = this;

    if (
      user?.hasHadAnyFundingSource &&
      !user?.chargeTermsAcceptTime &&
      !user?.accountPurpose &&
      !user?.manualApplicationSentTime &&
      !user?.applicationDeclined &&
      !user?.hasManualPayments
    ) {
      eventStore.actions.record({
        name: EVENTS.MODAL.VIEWED,
        data: {
          modalName: "Charge Terms Reminder",
        },
      });
      this.$bvModal.show("charge-terms-reminder");
    } else if (
      user?.hasManualPayments &&
      !user.chargeTermsAcceptTime &&
      user.accountPurpose !== AccountPurposes.PERSONAL
    ) {
      this.onStartOnboarding();
    }
  }

  inspectCard(card: Partial<Card>): void {
    this.currentCard = card;
    this.$bvModal.show("card-inspector");
  }

  inspectTransaction(transaction: Transaction): void {
    this.currentTransaction = transaction;
    this.$bvModal.show("card-inspector");
  }

  navigateToCardsPage(): void {
    this.$router.push({
      name: "cards",
    });
  }

  navigateToTransactionsPage(): void {
    this.$router.push({
      name: "transactions",
    });
  }

  handleCloseCardInspector(): void {
    this.currentCard = null;
    this.currentTransaction = null;
  }

  // XXX(JH): Similar checks in Cards.vue
  openCreateCard(): void {
    this.newCardCategory = null;
    this.newCardType = "";

    if (this.shouldShowCommercialChargeTermsReminder) {
      this.showCommercialChargeTermsReminder();
      return;
    }

    const { user } = this;
    if (!user?.hasHadAnyFundingSource) {
      if (!user?.sampleCard) {
        this.handleSampleCard();
      } else {
        this.errorToast("Complete your profile to create more cards");
      }
    } else if (
      user?.hasHadAnyFundingSource &&
      !user?.chargeTermsAcceptTime &&
      user?.accountPurpose !== "BUSINESS" &&
      !user?.manualApplicationSentTime &&
      !user?.applicationDeclined &&
      !user?.hasManualPayments
    ) {
      if (user?.accountPurpose === "PERSONAL") {
        // this is an onboarding user who has finished
        // the funding add step, but not yet signed terms
        this.errorToast(
          "You must agree to our terms before creating more cards"
        );
      } else {
        // This is a pre-charge-card legacy user
        // who is delinquent in signing charge terms
        eventStore.actions.record({
          name: EVENTS.CARD.CREATE_BLOCKED,
        });
        this.$bvModal.show("charge-terms-reminder");
      }
    } else {
      this.cardPhase = "active";
      this.$bvModal.show("card-type-modal");

      eventStore.actions.record({
        name: EVENTS.CTA.CLICKED,
        data: {
          buttonContext: "Home",
          buttonName: "Create Card",
        },
      });
    }
  }

  handleSampleCard(): void {
    this.$bvModal.show("vaulted-cards-modal");
  }

  handleVaultedCardSkip(): void {
    if (!this.user?.sampleCard) {
      this.createSampleCard();
    }
  }

  createSampleCard(): void {
    cardStore.actions.createSampleCard().then(() => {
      if (this.user?.sampleCard) {
        this.inspectCard(this.user.sampleCard);
      }
    });
  }

  handleCreateCardSuccess(card: Card) {
    this.$bvModal.hide("card-create");
    this.inspectCard(card);
  }

  onStartOnboarding(): void {
    this.$bvModal.show("onboarding-modal");
  }

  onFinishedOnboarding(): void {
    this.$bvModal.hide("onboarding-modal");
  }

  showCommercialChargeTermsReminder(): void {
    this.$bvModal.show("commercial-charge-terms-reminder");
  }

  showCommercialChargeCardsReissuedReminder(): void {
    this.$bvModal.show("commercial-charge-reissued-reminder");
  }

  showCommercialChargeCardsClosedReminder(): void {
    this.$bvModal.show("commercial-charge-closed-reminder");
  }

  switchCurrentCard(cardID: number): void {
    const oldCard = cardStore.getters.getCard(cardID);
    if (oldCard) {
      this.currentCard = oldCard;
    }
  }

  changeCardType(type: CardType | ""): void {
    this.newCardType = type;
    const newCardModalID =
      type === CardType.UNLOCKED ? "category-card-create-modal" : "card-create";
    this.$nextTick(() => this.$bvModal.show(newCardModalID));
  }

  createCategoryCard(category: MerchantCategory): void {
    this.newCardCategory = category;
    this.$bvModal.hide("category-card-create-modal");
    this.$nextTick(() => {
      this.$bvModal.show("card-create");
    });
  }

  categoryCardGoBack() {
    this.$bvModal.hide("category-card-create-modal");
    this.$nextTick(() => {
      this.$bvModal.show("card-type-modal");
    });
  }
}
</script>
<style lang="scss" scoped>
$responsive-column-1200: 1090px;
$responsive-column-1110: 820px;
$responsive-column-820: 550px;

.home {
  max-width: 100vw;
  overflow: hidden;
  padding-top: 60px;

  @media #{$media-width-820} {
    padding-top: 90px;
  }

  @media #{$media-phone} {
    padding-top: 70px;
  }
}

.spinner-container {
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 40px;
  min-height: calc(100vh - 140px);
}

.home-list-container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 20px 40px;
  width: 100%;
  max-width: 1220px;

  @media #{$media-width-1200} {
    max-width: $responsive-column-1200;
  }

  @media #{$media-width-1110} {
    max-width: $responsive-column-1110;
  }

  @media #{$media-width-820} {
    flex-direction: column;
    max-width: 580px;
  }

  @media #{$media-phone} {
    padding: 0;
  }

  > .mobile-controls {
    display: none;

    @media #{$media-phone} {
      display: flex;
      align-items: center;
      padding: 0 20px;
      min-height: 60px;
      box-shadow: inset 0 -1px $color-shadow-black-fainter;
    }

    > .tab {
      display: flex;
      align-items: center;
      align-self: stretch;
      margin-right: 20px;
      font-size: 16px;
      font-weight: bold;
      color: $gray-600;
      transition-property: color, box-shadow;
      transition-duration: $duration-shorter;
      cursor: pointer;

      &:hover,
      &.-active {
        box-shadow: inset 0 -1px $gray-800;
        color: $gray-800;
      }
    }

    > .new-card {
      margin-left: auto;
      margin-right: -15px;
    }
  }

  .desktop-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 15px;
    min-height: 90px;

    @media #{$media-phone} {
      display: none;
    }

    .title a,
    .title a:hover {
      font-family: $font-stack-wes-fy;
      text-decoration: none;
      color: $gray-800;
      font-size: 18px;
      margin-bottom: 0;
    }
  }

  .summary {
    text-decoration: none;
    .svg-icon.-size-40 {
      width: 30px;
      height: 30px;
    }
  }

  > .cards {
    display: flex;
    flex-direction: column;
    min-height: 600px;
    flex-basis: 280px;

    @media #{$media-width-820} {
      min-height: 540px;
    }

    @media #{$media-phone} {
      min-height: 480px;
    }

    &:not(.-active) {
      @media #{$media-phone} {
        display: none;
      }
    }

    > .card-list {
      z-index: 1;
      align-content: flex-start;
      align-items: flex-start;

      ::v-deep ._card {
        margin: 0 15px 30px;
      }

      ::v-deep ._card:nth-child(n + 4) {
        display: none;
      }

      @media #{$media-width-1110} {
        // override selector specificity
        ::v-deep ._card:nth-child(n) {
          display: block;
        }

        ::v-deep ._card:nth-child(n + 4) {
          display: none;
        }
      }

      @media #{$media-width-820} {
        // override selector specificity
        ::v-deep ._card:nth-child(n) {
          display: block;
        }

        ::v-deep ._card:nth-child(n + 5) {
          display: none;
        }
      }

      @media #{$media-phone} {
        justify-content: center;
        padding-top: 40px;
        height: 400px;
      }

      @media #{$media-width-540} {
        // force a single column of cards by decreasing available width
        padding-right: 20px;
        padding-left: 20px;

        // override selector specificity
        ::v-deep ._card:nth-child(n) {
          display: block;
        }

        ::v-deep ._card:nth-child(n + 3) {
          display: none;
        }
      }
    }

    > .see-all {
      max-width: 260px;
      margin: auto 5px 0;

      @media #{$media-width-820} {
        max-width: unset;
        margin: 21px 0 0;
      }

      @media #{$media-phone} {
        width: calc(100% - 40px);
        margin: 21px auto 0;
      }
    }
  }

  > .activity {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    margin-left: 40px;

    @media #{$media-width-820} {
      margin-left: 0px;
    }

    &:not(.-active) {
      @media #{$media-phone} {
        display: none;
      }
    }

    > .transaction-list {
      margin-top: -25px;
      padding-left: 15px;
      padding-right: 15px;

      // only show 8 transactions
      ::v-deep .transaction:nth-child(n + 9) {
        display: none;
      }

      @media #{$media-phone} {
        height: auto;
        padding-top: 5px;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -10px;
      }

      @media #{$media-width-1110} {
        height: auto;

        // only show 5 transactions
        ::v-deep .transaction:nth-child(n + 6) {
          display: none;
        }

        ::v-deep .transaction .left,
        ::v-deep .transaction .right {
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          height: 50px;
        }

        ::v-deep .transaction .right {
          align-items: flex-end;
          justify-content: space-between;
          flex-direction: column-reverse;
        }
      }
    }

    > .see-all {
      margin-top: auto;

      @media #{$media-phone} {
        width: calc(100% - 40px);
        margin: 21px auto 0;
      }
    }
  }
}

::v-deep {
  .commercial-charge-reissued-reminder,
  .commercial-charge-closed-reminder,
  .onboarding-modal {
    .modal-dialog {
      max-width: fit-content;
    }
  }

  .onboarding-modal {
    .modal-body {
      padding: 0;
    }
  }

  .commercial-charge-closed-reminder,
  .commercial-charge-reissued-reminder {
    .modal-body {
      padding: 1.5rem;
    }
  }
}
</style>
