<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :class="[
        'business-confirm-sole-proprietor',
        'signup-step',
        { '-loading': waiting },
      ]"
      data-test="business-confirm-sole-proprietor"
    >
      <div class="instructions">
        <h1>Sole Proprietorship</h1>
        <p class="copy">
          Privacy supports sole proprietorships or individual entrepreneurships
          which are a type of enterprise owned and run by only one person and in
          which there is no legal distinction between the owner and the business
          entity.
        </p>
        <hr />
        <p><strong>Unsupported business types:</strong></p>
        <p class="copy-example">
          Owner/representative of a private, registered business entity (e.g.
          LLC or Inc.)
        </p>
        <p class="copy-example">Representative of a nonprofit or charity</p>
      </div>
      <div class="questions">
        <h5 class="mb-3">Are you a sole proprietor?</h5>
        <b-alert
          variant="danger"
          :show="errorMessage.length > 0"
          data-test="error-business-confirm-sole-proprietor"
        >
          {{ errorMessage }}
        </b-alert>
        <BaseButton
          variant="light"
          block
          @click="submit(true)"
          data-test="business-confirm-sole-proprietor-btn-confirm"
          >Yes</BaseButton
        >
        <BaseButton
          variant="light"
          block
          @click="submit(false)"
          data-test="business-confirm-sole-proprietor-btn-deny"
          >No</BaseButton
        >
        <p class="disclaimer">
          *As a sole proprietor at least 80% of your purchases must be for
          business related expenses.
        </p>
      </div>
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Toast } from "@/mixins/Toast";
import { organizationStore } from "@/store";
import { BusinessType as BusinessTypeValues } from "@/types/Organization";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component
export default class BusinessConfirmSoleProprietor extends Mixins(Toast) {
  @Prop({ default: false }) fullpage!: boolean;

  waiting = false;
  errorMessage = "";
  types = BusinessTypeValues;

  submit(isSoleProprietor: boolean): void {
    const ctaName = isSoleProprietor ? "Primary" : "Secondary";
    const businessType = isSoleProprietor
      ? BusinessTypeValues.INDIVIDUAL
      : BusinessTypeValues.OTHER;

    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.BUSINESS_CONFIRM_SOLE_PROPRIETOR)} ${ctaName} CTA`,
    });

    this.waiting = true;
    organizationStore.actions
      .businessType({ businessType })
      .then(() => {
        if (isSoleProprietor) {
          this.$emit("complete-step");
        } else {
          this.$emit(
            "complete-step",
            OnboardingSteps.BUSINESS_NOT_SOLE_PROPRIETOR
          );
        }
      })
      .catch((err) => {
        this.errorMessage = err.response?.data?.message || "An error occurred";
      })
      .finally(() => {
        this.waiting = false;
      });
  }
}
</script>

<style lang="scss" scoped>
.business-confirm-sole-proprietor {
  width: 48.5rem;
  flex-direction: row;
  padding: 0;

  @media #{$media-phone} {
    flex-direction: column;
    width: 100%;
  }
}

h1 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h1,
h5 {
  font-family: $font-stack-wes-fy;
  color: $gray-800;
}

.copy {
  color: $gray-600;
}

.disclaimer {
  color: $gray-800;
  margin-top: 16px;
}

.copy-example {
  color: $gray-600;
  background-color: white;
  padding: 16px;
  text-align: center;
  border-radius: 8px;
}

p:last-child {
  margin-bottom: 0;
}

hr {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

button {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  margin-bottom: 6px;
}

.instructions,
.questions {
  width: 50%;
  padding: 2rem;

  @media #{$media-phone} {
    width: 100%;
  }
}

.instructions {
  background-color: #f5f8fb;
}
</style>
