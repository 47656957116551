<template>
  <label class="field">
    <div class="title">{{ label }}</div>
    <VueGoogleAutocomplete
      v-if="hasLoadedGoogleMapsAPI"
      id="address-input"
      class="address-input form-control"
      :class="{ 'is-invalid': isError }"
      :data-test="dataTest"
      country="us"
      :fields="fields"
      @inputChange="handleManualChange"
      :placeholder="placeholder"
      @placechanged="handlePlaceChanged"
      ref="address-input"
    >
    </VueGoogleAutocomplete>
  </label>
</template>
<script lang="ts">
import { AddressResult, GoogleMapsAddressResult } from "@/types/Onboarding";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { loadScriptAsync } from "@/util";
import { Component, Vue, Prop } from "vue-property-decorator";

Vue.use(VueGoogleAutocomplete);

const fields = [
  "address_components",
  "adr_address",
  "formatted_address",
  "geometry",
];

@Component({
  components: {
    VueGoogleAutocomplete,
  },
})
export default class AddressInput extends Vue {
  @Prop({ default: "Street Address" }) label!: string;
  @Prop({ default: "Your Street Address" }) placeholder!: string;
  @Prop({ default: "" }) existingAddress!: string;
  @Prop({ default: false }) isError!: boolean;
  @Prop({ default: "input-personal-address" }) dataTest!: string;

  address = "";
  fields = fields;
  hasGoogleResult = false;
  hasLoadedGoogleMapsAPI = false;
  hasMapsError = false;

  mounted() {
    const id = "google-maps-api";
    if (document.getElementById(id) === null) {
      loadScriptAsync(
        id,
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyA5yFxwE72uLoco66TV1CipkbD9sitghKg&libraries=places",
        this.onMapsLoaded,
        this.onMapsLoadedError
      );
    } else {
      this.hasLoadedGoogleMapsAPI = true;
    }
    this.updateAddressFromExisting();
  }

  async onMapsLoaded() {
    this.hasLoadedGoogleMapsAPI = true;
    await this.updateAddressFromExisting();
  }

  async onMapsLoadedError() {
    this.hasMapsError = true;
    await this.updateAddressFromExisting();
  }

  async updateAddressFromExisting() {
    // Wait for input field to render
    await this.$nextTick();
    const addressRef = this.$refs["address-input"];
    if (addressRef && this.existingAddress.length > 0) {
      // If the VueGoogleAutocomplete component rendered, use its update method
      // otherwise set the model value on BaseInput
      if (this.hasLoadedGoogleMapsAPI) {
        // @ts-ignore update method exists on the VueGoogleAutocomplete component
        addressRef.update(this.existingAddress);
      } else {
        this.address = this.existingAddress;
      }
    }
  }

  handleManualChange({ newVal }: { newVal: string }) {
    // Google appends city/state to the input value,
    // which we don't want to include in address1.
    // Don't fire an event if google updates the field text.
    if (!this.hasGoogleResult) {
      // If the user then manually edits the address from google and
      // it still contains a comma,
      // they probably left the city/state in it.
      newVal = newVal.split(",")[0];
      this.updateAddressFromExisting();
      this.$emit("address-changed", { address1: newVal });
    }
    this.hasGoogleResult = false;
  }

  handlePlaceChanged(gMapsResult: GoogleMapsAddressResult) {
    this.hasGoogleResult = true;
    // some addresses from Google don't have street_numbers
    // and we don't want to show "undefined" in the UI
    const address1 = gMapsResult.street_number
      ? `${gMapsResult.street_number} ${gMapsResult.route}`
      : gMapsResult.route;
    const result: AddressResult = {
      address1,
      zipcode: gMapsResult.postal_code,
    };
    this.updateAddressFromExisting();
    this.$emit("address-changed", result);
  }
}
</script>
<style lang="scss" scoped>
.field {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $gray-100;
  border-radius: 1rem;
  padding: 1rem 1.25rem 3rem;
  cursor: text;

  .title {
    padding: 0.25rem 0 0;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 0.8;
    pointer-events: none;
    z-index: 1;

    &.disabled {
      opacity: 0.5;
    }
  }

  ::v-deep input {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 2.5rem;

    &:enabled:hover {
      background-color: $gray-200;
      border-color: $gray-200;
    }
  }
}
</style>
