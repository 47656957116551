<template>
  <div class="tag-row" data-test="tag-row">
    <div ref="tagContainer" class="tag-container">
      <Tag
        v-if="tagsToDisplay && tagsToDisplay.length > 0"
        :key="tagsToDisplay[0]._id"
        :title="tagsToDisplay[0].title"
        :color="tagsToDisplay[0].color"
        :isSelected="true"
        @click="onClickTag($event)"
      />
    </div>
    <div
      class="num-overflowing"
      v-if="tagsToDisplay.length > 1"
      data-test="num-overflowing"
    >
      + {{ tagsToDisplay.length - 1 }}
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { cardStore, tagStore } from "../store";
import Tag from "./Tag.vue";

@Component({
  components: {
    Tag,
  },
})
export default class TagRow extends Vue {
  @Prop() cardID!: string;
  @Prop() onClickTag!: (event: Event) => void;

  loading = true;

  get card() {
    return cardStore.getters.getCard(this.cardID);
  }

  get tagsToDisplay() {
    return tagStore.getters.getTagsByCard(this.card);
  }

  created() {
    tagStore.actions.fetchTags();
  }
}
</script>
<style lang="scss" scoped>
.tag-row {
  display: flex;
  height: 28px;
  width: 100%;

  > .num-overflowing {
    display: flex;
    height: 100%;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    font-family: $font-stack-lato;
    font-weight: bold;
    font-size: 12px;
    background-color: $white;
    color: $gray-500;
    width: fit-content;
    padding-left: 5px;
  }

  > .tag-container {
    height: 28px;
    display: flex;
    max-width: 90%;

    > .tag {
      flex-shrink: 0;
      margin-right: 5px;
    }

    > .tag:hover {
      opacity: 1;
    }
  }
}
</style>
