<template>
  <div class="custom-plan"></div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { subscriptionStore } from "@/store";
import { OnboardingSteps } from "@/types/Onboarding";

@Component
export default class CustomPlan extends Vue {
  created() {
    const planID = this.$route.params.planID || "";

    if (!planID) {
      this.$router.push({ name: "home" });
    }

    subscriptionStore.actions.setTemporaryPlanCookie({
      planType: planID,
    });

    subscriptionStore.actions
      .fetchPlans()
      .then(() => {
        const selectedPlan = subscriptionStore.getters.selectedPlan();

        if (!selectedPlan) {
          this.$router.push({ name: "subscription-plan" });
        } else {
          const billingRoute = `onboarding-${OnboardingSteps.BILLING}`;
          this.$router.push({ name: billingRoute });
        }
      })
      .catch(() => this.$router.push({ name: "home" }));
  }
}
</script>
