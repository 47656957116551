<template>
  <BaseModal id="change-default-funding" size="md">
    <h1 class="title">Change your default funding source?</h1>
    <p class="subtitle">
      Cards set to use your default funding source will now debit from this
      account.
    </p>
    <label
      class="keep-existing"
      v-if="currentDefaultCardsNumber > 0"
      for="persistOldDefaultFunding"
    >
      <input
        type="checkbox"
        name="persistOldDefaultFunding"
        id="persistOldDefaultFunding"
        v-model="persistOldDefaultFunding"
      />
      Keep {{ currentDefaultCardsNumber }} existing cards on the old default
      funding source.
    </label>
    <div class="controls">
      <BaseButton variant="secondary" @click="close">Cancel</BaseButton>
      <BaseButton
        :disabled="loading"
        variant="primary"
        @click="onSubmit"
        data-test="confirm-default-funding"
        >OK</BaseButton
      >
    </div>
  </BaseModal>
</template>
<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Toast } from "@/mixins/Toast";
import { Card } from "@/types/Card";
import { bankStore, cardStore, subscriptionStore } from "@/store";
import { FundingBank } from "@/types/Funding";

function usesDefaultFundingSource(card: Card) {
  return !card.fundingBank;
}

@Component
export default class ChangeDefaultFunding extends Mixins(Toast) {
  currentDefaultCardsNumber = 0;
  persistOldDefaultFunding = false;
  loading = false;
  @Prop({ required: true }) source!: FundingBank;

  created() {
    cardStore.actions.getCards({}).then(() => {
      const { open } = cardStore.getters.cardList();
      this.currentDefaultCardsNumber = open.filter(
        usesDefaultFundingSource
      ).length;
    });
  }

  onSubmit() {
    this.loading = true;
    bankStore.actions
      .setDefault({
        bankAccountID: this.source.bankAccountID,
        persistOldDefaultFunding: this.persistOldDefaultFunding,
      })
      .then(() => {
        subscriptionStore.actions.chargeOutstandingBalance();
        this.$emit("refresh-funding-sources");
        this.close();
        this.successToast("Default Funding Source updated");
      })
      .catch(() => {
        this.errorToast(
          "There was a problem updating your Default Funding Source"
        );
      })
      .finally(() => {
        this.loading = false;
      });
  }

  close() {
    this.$bvModal.hide("change-default-funding");
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-size: 20px;
  font-family: $font-stack-wes-fy;
  text-align: center;
}

.subtitle {
  text-align: center;
}

.keep-existing {
  text-align: center;
  display: block;
  margin-top: 20px;
  padding: 10px;
  border: 2px solid $gray-100;
  border-radius: $radius-default;
}

.controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.controls > button {
  flex-grow: 1;
  flex-basis: 50%;
  user-select: none;
}

.controls > button + button {
  margin-left: 10px;
}
</style>
