<template>
  <div class="account-plan">
    <div class="section-heading">
      <h2>Plan</h2>
      <BaseButton
        variant="primary"
        v-if="!isLegacyPlan && !isDelinquent"
        :to="{ name: 'subscription-plan' }"
        data-test="link-change-plan"
      >
        Change Plan
      </BaseButton>
    </div>
    <div
      class="section"
      id="plan"
      v-if="subscription"
      :class="['content', `type-${displayType}`]"
    >
      <div class="icon" v-if="icon"><img :src="icon" alt="" /></div>
      <div class="details" data-test="content-change-plan">
        <div class="title">
          {{ subscriptionName }}
          <span v-if="isActive"
            >&mdash; ${{ billingAmount / 100 }} per month</span
          >
        </div>
        <div class="info" v-if="isActive">
          {{ inFreePromo ? "Billing starts" : "Next charge" }}
          on {{ nextChargeDate }}
        </div>
        <div class="info" v-if="isPending">
          <span class="subtitle">Pending Review...</span>
        </div>
        <div class="info" v-if="isProcessingError">
          Your payment could not be processed. Please
          <router-link :to="{ name: billingRoute }"
            >update your billing information</router-link
          >.
        </div>
        <div class="info" v-if="isCanceled">Ending on {{ endDate }}</div>
        <div class="info" v-if="isDelinquent">
          Your last charge of ${{ delinquentAmount / 100 }}
          failed. Please
          <router-link :to="{ name: billingRoute }"
            >update your billing information</router-link
          >.
        </div>
        <div class="info" v-if="!billingAmount">$0 per month</div>
      </div>
      <FundingPreview
        v-if="!isCanceled && fundingSource && !isLegacyPlan"
        :source="fundingSource"
        size="sm"
        class="ml-auto"
        @click="() => $router.push({ name: billingRoute })"
      />
      <BaseButton
        v-else-if="isActive && !fundingSource"
        :to="{ name: billingRoute }"
        variant="warning"
        class="ml-auto d-flex align-items-center funding-error"
        v-b-tooltip.hover.d300
        title="Looks like you don't have a subscription payment method set. Please update your billing info."
      >
        Please update your billing info
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts">
import { fundingStore, subscriptionStore } from "@/store";
import {
  Subscription,
  SubscriptionFundingType,
  SubscriptionState,
  SubscriptionType,
} from "@/types/Subscription";
import { Component, Vue } from "vue-property-decorator";
import FundingPreview from "@/components/FundingPreview.vue";
import { OnboardingSteps } from "@/types/Onboarding";
import { formatDate, utcDate } from "@/lib/dates";
import { V2FundingSource } from "@/types/Funding";

const LegacyPlans = [
  SubscriptionType.API_ISSUING,
  SubscriptionType.STARTER_ISSUING,
  SubscriptionType.SMB_ENTERPRISE,
];

@Component({
  components: {
    FundingPreview,
  },
})
export default class AccountPlan extends Vue {
  billingRoute = `onboarding-${OnboardingSteps.BILLING}`;

  get subscription() {
    return subscriptionStore.getters.subscription || ({} as Subscription);
  }

  get icon() {
    return this.currentPlan?.iconSvg;
  }

  get displayType() {
    return this.currentPlan?.displayType;
  }

  get currentPlan() {
    return subscriptionStore.getters.currentPlan;
  }

  get inFreePromo() {
    return this.subscription.inPromoPeriod;
  }

  get billingAmount() {
    return this.subscription.billingAmount;
  }

  get nextChargeDate() {
    return this.subscription.nextChargeDate
      ? formatDate("MM/DD/YY", utcDate(this.subscription.nextChargeDate))
      : null;
  }

  get endDate() {
    return this.subscription.endDate
      ? formatDate("MM/DD/YY", utcDate(this.subscription.endDate))
      : null;
  }

  get subscriptionName() {
    return this.subscription.displayName || "subscription";
  }

  get isLegacyPlan() {
    // @ts-ignore
    return LegacyPlans.includes(this.currentPlan?.type);
  }

  get isActive() {
    return (
      subscriptionStore.getters.isPaidSubscription &&
      this.subscription.state === SubscriptionState.ACTIVE
    );
  }

  get isPending(): boolean {
    return (
      this.subscription.state === SubscriptionState.PENDING &&
      !this.subscription.pendingFailure
    );
  }

  get isCanceled() {
    return this.subscription.state === SubscriptionState.CANCELLED;
  }

  get isDelinquent() {
    return (
      this.subscription.delinquentAmount &&
      this.subscription.state === SubscriptionState.DELINQUENT
    );
  }

  get delinquentAmount() {
    return this.subscription.delinquentAmount || 0;
  }

  get isProcessingError() {
    return (
      this.subscription.state === SubscriptionState.PENDING &&
      this.subscription.pendingFailure
    );
  }

  get existingCard() {
    return fundingStore.getters.subscriptionCards.find(
      (card: V2FundingSource) => card.uuid === this.subscription?.fundingUuid
    );
  }

  get fundingSource() {
    return this.subscription?.fundingType === SubscriptionFundingType.DEFAULT
      ? fundingStore.getters.default
      : this.existingCard;
  }

  created() {
    fundingStore.actions.fetchFundingSources();
  }
}
</script>

<style lang="scss" scoped>
.title-aside {
  color: $blue;
  a {
    text-decoration: none;
  }
}

.account-plan a {
  cursor: pointer;
}

.account-plan .section.content {
  color: $gray-800;
  display: flex;
  align-items: center;
  padding-top: 26px;
  padding-bottom: 26px;

  &.type-pro {
    background-color: $blue;
    color: $white;
  }

  &.type-team {
    background-color: $gray-800;
    color: $white;
  }

  &.type-enterprise {
    background-color: $purple;
    color: $white;
  }

  &.type-issuing {
    background-color: $color-blue-darker;
    color: $white;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  height: 50px;
  width: 40px;

  img {
    width: 40px;
  }
}

.details {
  line-height: 20px;
}

.title {
  margin-bottom: 10px;
  font-weight: bold;
}

.info {
  padding-right: 10px;

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.funding-preview {
  cursor: pointer;
}

.funding-error {
  min-height: 62px;
}
</style>
