<template>
  <div class="disclosure-page">
    <BaseForm @submit="saveDisclosures">
      <h3 class="title">Authorizations and Consent</h3>
      <p class="subtitle">
        Please review these authorizations and consent terms carefully.
      </p>
      <b-alert
        variant="danger"
        show
        v-if="errorMessage"
        data-test="alert-reset-error"
      >
        {{ errorMessage }}
      </b-alert>
      <DisclosureCheckbox v-model="agreed" :agreementError="agreementError" />
      <BaseButton
        type="submit"
        variant="primary"
        @click="saveDisclosures"
        :success="success"
      >
        Agree and Continue
      </BaseButton>
    </BaseForm>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import DisclosureCheckbox from "@/components/DisclosureCheckbox.vue";
import { userStore } from "@/store";

@Component({
  components: {
    DisclosureCheckbox,
  },
})
export default class Disclosures extends Vue {
  agreed = false;
  agreementError: boolean | null = null;
  errorMessage = "";
  success = false;

  saveDisclosures() {
    if (!this.agreed) {
      this.errorMessage =
        "You must be a US resident, and agree to the terms and authorizations.";
      this.agreementError = true;
      return;
    }
    userStore.actions
      .acceptDisclosure()
      .then(() => {
        this.agreementError = null;
        this.errorMessage = "";
        this.success = true;
        setTimeout(() => {
          this.$emit("complete-step");
        }, 1500);
      })
      .catch(() => {
        this.agreementError = null;
        this.errorMessage =
          "There was an error submitting your response. Please try again later.";
      });
  }
}
</script>

<style lang="scss" scoped>
.disclosure-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;
  padding-top: 100px; // required in angular context
}
</style>
