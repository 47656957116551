<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      @submit="submit"
      :class="['signup-phone', { '-loading': waiting }]"
      data-test="phone-setup-form"
    >
      <h1>Phone Number</h1>
      <p class="blurb">We'll text you a code to verify it's your number.</p>
      <b-alert variant="danger" :show="errorMessage.length > 0">
        {{ errorMessage }}
      </b-alert>

      <BaseInput
        name="phone"
        label="US Phone"
        placeholder="Your Phone Number"
        v-model="user.phone"
        type="text"
        maxlength="14"
        format="phone-us"
        :state="errorMessage.length > 0 ? false : null"
        :autofocus="true"
        data-test="phone-number-field"
      />
      <b-alert variant="warning" :show="hasSms2Fa">
        You currently have SMS as your Two-Factor Authentication method. If you
        change your phone number, this will be changed to Email.
      </b-alert>
      <BaseButton
        variant="primary"
        type="submit"
        size="lg"
        :loading="waiting"
        data-test="button-submit-phone"
        @click="trackClick"
      >
        Send Confirmation Text
      </BaseButton>
      <OnboardingFooterActions
        :allowChangeAccountType="true"
        @footer-action="onFooterAction"
      />
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Prop, Mixins } from "vue-property-decorator";
import { userStore } from "@/store";
import OnboardingFooterActions from "@/components/OnboardingFooterActions.vue";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";
import { TwoFactorPreferences } from "@/types/User";
import { Toast } from "@/mixins/Toast";

@Component({
  components: {
    OnboardingFooterActions,
  },
})
export default class SignupPhone extends Mixins(Toast) {
  @Prop({ default: false }) fullpage!: boolean;

  user = {
    phone: "",
  };
  errorMessage = "";
  phoneError = false;
  waiting = false;

  get hasSms2Fa() {
    return (
      userStore.getters.currentUser?.tfaPreference === TwoFactorPreferences.SMS
    );
  }

  trackClick() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.PHONE)} Primary CTA`,
    });
  }

  async submit() {
    this.errorMessage = "";

    if (!this.user.phone.length) {
      this.phoneError = true;
      this.errorMessage = "Please enter your phone number";
      return;
    } else if (this.user.phone.length < 10) {
      this.phoneError = true;
      this.errorMessage = "Please enter a valid 10-digit phone number";
      return;
    }

    this.waiting = true;

    const phoneNumeric = this.user.phone.replace(/\D/g, "");

    try {
      await userStore.actions.phoneSetup(phoneNumeric);
      if (this.hasSms2Fa) {
        userStore.mutations.updateCurrentUser({
          tfaPreference: TwoFactorPreferences.EMAIL,
          twoFactorAuth: false,
        });
        this.successToast("Your 2FA method has been changed to Email.");
      }
      this.$emit("complete-step");
    } catch (err: any) {
      this.errorMessage =
        err.response?.data?.message || "Error sending SMS code";
      this.waiting = false;
    }
  }

  onFooterAction(nextScreen: OnboardingSteps): void {
    this.$emit("complete-step", nextScreen);
  }
}
</script>

<style lang="scss" scoped>
h1 {
  all: unset;
  font-family: $font-stack-wes-fy;
  font-size: 24px;
  text-align: center;
  margin-bottom: 15px;
  line-height: 1.4;
  color: $gray-800;
}
</style>
