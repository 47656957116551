<template>
  <label class="field" :class="{ disabled }">
    <div class="title" :class="{ disabled }" v-if="label">{{ label }}</div>
    <b-form-textarea
      v-bind="$attrs"
      :value="value"
      @input="handleInput"
      :disabled="disabled"
      no-resize
    ></b-form-textarea>
  </label>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
})
export default class BaseTextarea extends Vue {
  @Prop({ default: "" }) label!: string;
  @Prop() value?: string;
  @Prop({ default: false }) disabled!: boolean;

  handleInput(value: string) {
    this.$emit("input", value);
  }
}
</script>

<style lang="scss" scoped>
.field {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 10rem;
  cursor: text;

  &:hover:not(.disabled) .title,
  &:focus-within .title {
    background-color: $gray-200;
  }

  .title {
    padding: 1.25rem 0.25rem 0.25rem;
    margin: 1px 3.5rem 0 1rem;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 0.8;
    background-color: $gray-100;
    pointer-events: none;
    z-index: 1;

    &.disabled {
      color: rgba($gray-800, 0.5);
    }
  }

  ::v-deep textarea {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%; // see PRV-1064
    padding-top: 2.5rem;

    &:enabled:hover {
      background-color: $gray-200;
      border-color: $gray-200;
    }
  }
}
</style>
