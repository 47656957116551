<template>
  <div class="page-wrapper">
    <div class="logo-wrapper">
      <PrivacyLogo
        href="/"
        class="privacy-logo"
        aria-label="Go to Home"
      ></PrivacyLogo>
    </div>
    <div class="container-wrapper">
      <h1 class="title">Page Not Found</h1>
      <p class="blurb">The page you are looking for is not here.</p>
      <b-link to="/" aria-label="Go to Home">Go to Home</b-link>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import PrivacyLogo from "@/components/PrivacyLogo.vue";

@Component({
  components: {
    PrivacyLogo,
  },
})
export default class NotFound extends Vue {}
</script>

<style lang="scss" scoped>
.page-wrapper {
  text-align: center;
  padding: 20px 100px;

  > .container-wrapper {
    margin-top: 50px;
  }
  .title {
    font-weight: 800;
    font-size: 42px;
    margin-bottom: 16px;
  }

  .blurb {
    font-size: 16px;
  }
}
</style>
