<template>
  <SimplePage :fullpage="true">
    <BaseForm data-test="email-confirm">
      <img
        alt="Mail envelope"
        src="/assets/images/onboarding/confirm-email.png"
        width="300"
      />
      <h1>You've Got Mail</h1>
      <p class="blurb">
        We've emailed you a confirmation link to
        <span class="user-email -bold">{{ email }}</span> which you'll have to
        click to start using Privacy.
      </p>
      <p class="blurb -resend-email" v-show="!emailSent">
        <span class="title -bold">Don't see the email?</span>
        Check your spam folder. You can also
        <b-link class="-bold" @click="onClickEditEmailAddress"
          >edit your email address</b-link
        >
        or
        <b-link
          class="-bold"
          @click="onClickResend"
          aria-label="resend confirmation email"
          >send another one.</b-link
        >
      </p>
      <p class="blurb -resend-email -success" v-show="emailSent">
        <SVGIcon icon="tick" /> Email Resent
      </p>
    </BaseForm>
  </SimplePage>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import SVGIcon from "@/components/SVGIcon.vue";
import { userStore } from "@/store";

const SUCCESS_TIMEOUT = 5000;

@Component({
  components: {
    SVGIcon,
  },
})
export default class EmailConfirm extends Vue {
  emailSent = false;

  get user() {
    return userStore.getters.currentUser;
  }

  get email() {
    return this.user?.email;
  }

  onClickResend() {
    this.$piwik.trackClick({ name: "Confirm Email Secondary CTA" });
    userStore.actions.resendConfirmationEmail();
    this.emailSent = true;
    window.setTimeout(() => {
      this.emailSent = false;
    }, SUCCESS_TIMEOUT);
  }

  onClickEditEmailAddress() {
    this.$router.push({
      name: "change-email",
    });
  }
}
</script>
<style lang="scss" scoped>
@import "./email-confirm-base";

.blurb {
  .user-email {
    color: $color-foreground;
  }

  &.-resend-email {
    background-color: $gray-100;
    border-radius: $radius-large;
    padding: 20px 30px;

    .title {
      display: block;
      color: $color-foreground;
      margin-bottom: 1em;
    }
  }
}

.-bold {
  font-weight: bold;
}
</style>
