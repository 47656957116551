<template>
  <div class="signup-step -pre-signup">
    <h1>New User?</h1>
    <p>
      Sign up for Privacy and get more control over your payments in just a few
      minutes.
    </p>
    <b-button variant="white" href="signup" class="mt-2 p-3"
      >Get Started</b-button
    >
    <img
      src="/assets/images/onboarding/pre-signup-illustration.png"
      width="360"
      alt=""
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";

@Component({
  components: {
    BButton,
  },
})
export default class NewUser extends Vue {}
</script>

<style lang="scss" scoped>
$_color-pre-signup-text-blue: #6c92a6;

.signup-step {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 20px 10px;
  min-height: 100%;
  max-width: 320px;
}

h1 {
  all: unset;
  margin-bottom: 15px;
  margin-top: auto;
  font-family: $font-stack-wes-fy;
  font-size: 24px;
  text-align: center;
  color: $gray-800;
  line-height: 1.4;
}

p {
  margin: 5px 0 15px;
  font-size: 16px;
  text-align: center;
  color: $_color-pre-signup-text-blue;
}

img {
  margin-top: auto;
  margin-right: -40px;
  margin-bottom: -20px;
  margin-left: -40px;
  pointer-events: none;
}

@media #{$media-width-900} {
  .signup-step {
    margin: 0 auto;
  }
}
</style>
