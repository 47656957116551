<template>
  <div class="banner-pending-alternate-funding-request">
    <div class="wrapper">
      <img src="/assets/images/business/plan-icon-pro.svg" alt="" />
      <div>
        <div class="title">We'll be in touch</div>
        <div class="description">
          Before you can connect a bank account with account and routing
          numbers, we need to review your account.<br />
          When the review is done, we'll send you an email and you'll be able to
          connect your bank account.
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class BannerPendingAlternateFundingRequest extends Vue {}
</script>
<style lang="scss" scoped>
.banner-pending-alternate-funding-request {
  $media-width-1200: "only screen and (max-width: 1200px)";
  $media-width-1110: "only screen and (max-width: 1110px)";
  $media-width-820: "only screen and (max-width: 820px)";

  $responsive-column-1200: 1090px;
  $responsive-column-1110: 820px;
  $responsive-column-820: 550px;

  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 30px 20px;

  .wrapper {
    display: grid;
    grid-template-columns: min-content 1fr auto;
    align-items: center;
    gap: 20px;
    background: $gray-100;
    border-radius: $border-radius-lg;
    padding: 30px;

    > img {
      height: 56px;
    }

    .title {
      color: $gray-900;
      font-weight: bold;
    }

    .description {
      color: $gray-600;
    }
  }

  @media #{$media-width-1200} {
    max-width: $responsive-column-1200;
  }

  @media #{$media-width-1110} {
    max-width: $responsive-column-1110;
    padding: 20px;
  }

  @media #{$media-width-820} {
    flex-direction: column;
    max-width: 580px;

    .wrapper {
      grid-template-columns: min-content 1fr;

      button {
        grid-column: auto / span 2;
      }
    }
  }
}
</style>
