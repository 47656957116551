import { storeBuilder } from "./storeBuilder";

import * as userStore from "./user";
import * as cardStore from "./card";
import * as sharingStore from "./sharing";
import * as subscriptionStore from "./subscription";
import * as organizationStore from "./organization";
import * as fundingCardStore from "./fundingCard";
import * as fundingStore from "./funding";
import * as loginStore from "./login";
import * as cashbackStore from "./cashback";
import * as metricsStore from "./metrics";
import * as notificationStore from "./notification";
import * as promoStore from "./promo";
import * as bankStore from "./bank";
import * as cdnStore from "./cdn";
import * as tagStore from "./tag";
import * as transactionStore from "./transaction";
import * as repaymentStore from "./repayment";
import * as eventStore from "./event";
import * as featureStore from "./feature";
import * as merchantCategoriesStore from "./merchantCategories";

export {
  userStore,
  cardStore,
  sharingStore,
  subscriptionStore,
  organizationStore,
  fundingStore,
  fundingCardStore,
  loginStore,
  cashbackStore,
  metricsStore,
  notificationStore,
  promoStore,
  bankStore,
  cdnStore,
  tagStore,
  transactionStore,
  repaymentStore,
  eventStore,
  featureStore,
  merchantCategoriesStore,
};

export default storeBuilder.vuexStore();
