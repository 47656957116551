import dayjs, { Dayjs, ConfigType, OptionType } from "dayjs";
import dayjsUtc from "dayjs/plugin/utc";
import dayjsTimezone from "dayjs/plugin/timezone";
import dayjsIsSameOrBefore from "dayjs/plugin/isSameOrBefore";
import dayjsIsSameOrAfter from "dayjs/plugin/isSameOrAfter";
import dayjsIsBetween from "dayjs/plugin/isBetween";
import daysjsLocalizedFormat from "dayjs/plugin/localizedFormat";
import dayjsMinMax from "dayjs/plugin/minMax";
import dayjsCalendar from "dayjs/plugin/calendar";
import dayjsCustomParseFormat from "dayjs/plugin/customParseFormat";
import dayjsDuration from "dayjs/plugin/duration";

dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsIsSameOrBefore);
dayjs.extend(dayjsIsSameOrAfter);
dayjs.extend(dayjsIsBetween);
dayjs.extend(daysjsLocalizedFormat);
dayjs.extend(dayjsMinMax);
dayjs.extend(dayjsCalendar);
dayjs.extend(dayjsCustomParseFormat);
dayjs.extend(dayjsDuration);

export function getDate(date?: ConfigType, inputFormat?: OptionType): Dayjs {
  return dayjs(date, inputFormat);
}

export function formatDate(
  format = "YYYY-MM-DD",
  date?: ConfigType,
  inputFormat?: OptionType
): string {
  return getDate(date, inputFormat).format(format);
}

export function utcDate(
  date?: ConfigType,
  inputFormat?: OptionType,
  keepLocalTime?: boolean
): Dayjs {
  return getDate(date, inputFormat).utc(keepLocalTime);
}

export function unixDate(unix: number): Dayjs {
  return dayjs.unix(unix);
}

export function maxDate(dates: Dayjs[]): Dayjs | null {
  return dayjs.max(dates);
}

export function isValidDate(
  date?: ConfigType,
  inputFormat?: OptionType
): boolean {
  return dayjs(date, inputFormat, true).isValid();
}

export function relativeDate(
  date?: ConfigType,
  inputFormat?: OptionType
): string {
  return utcDate(date, inputFormat).local().startOf("day").calendar(null, {
    sameDay: "[Today]",
    lastDay: "[Yesterday]",
    lastWeek: "dddd",
    sameElse: "MMM YYYY",
  });
}

export function formatInputDate(rawDate = "", shortDate = false) {
  const numDate = rawDate.replace(/\D/g, "");

  let date = "";
  if (numDate.slice(0, 2)) {
    date += numDate.slice(0, 2);
  }
  if (numDate.slice(2, 4)) {
    date += `/${numDate.slice(2, 4)}`;
  }
  if (numDate.slice(4, 8) && !shortDate) {
    date += `/${numDate.slice(4, 8)}`;
  }
  return date;
}

function addBusinessDays(date: Dayjs, days: number): Dayjs {
  let remaining = days;

  while (remaining > 0) {
    date = date.add(1, "day");
    const newDay = date.day();

    if (newDay !== 6 && newDay !== 0) {
      remaining -= 1;
    }
  }

  return date;
}

const BANK_CLOSING_HOUR = 15;
const BANK_CLOSING_MINUTES = 28;

export function nextBankDay(
  date?: ConfigType,
  inputFormat?: OptionType
): string {
  const now = getDate(date, inputFormat).tz("America/New_York");
  const bankClosing = now.hour(BANK_CLOSING_HOUR).minute(BANK_CLOSING_MINUTES);
  let businessDayForDeposit = 2;

  if (now.isBefore(bankClosing)) {
    businessDayForDeposit = 1;
  }

  return addBusinessDays(now, businessDayForDeposit).calendar(null, {
    nextDay: "[tomorrow morning]",
    nextWeek: "dddd [morning]",
    sameElse: "dddd [morning]",
  });
}

export function duration(
  value: number,
  inputUnit: dayjsDuration.DurationUnitType = "ms"
): dayjsDuration.Duration {
  return dayjs.duration(value, inputUnit);
}

export type DateType = Dayjs;
export type DurationType = dayjsDuration.Duration;
export type DateInputType = ConfigType;
