<template>
  <KybVerificationContainer>
    <BaseForm class="pt-0 mt-0" @submit="submit">
      <h3 class="heading">
        Please tell us about everyone who owns 25% or more of this business
      </h3>
      <b-alert
        variant="danger"
        :show="errorMessage.length > 0"
        data-test="error-business-execs"
      >
        {{ errorMessage }}
      </b-alert>
      <div v-for="(owner, index) in business.owners" :key="index">
        <div v-if="currentlyEditing === index" class="owner-form">
          <span class="form-heading mb-2 d-flex align-items-center">
            <SVGIcon icon="contact" size="16" class="mr-2" /> Additional Owner
            <div class="contact-actions ml-auto">
              <SVGIcon
                icon="trash"
                @click.prevent="removeOwner(index)"
              ></SVGIcon>
            </div>
          </span>
          <BaseInputGroup class="mb-3">
            <BaseInput
              name="firstName"
              label="Legal First Name"
              placeholder="First Name"
              v-model="owner.firstName"
              type="text"
              maxlength="128"
              class="mr-1"
            />
            <BaseInput
              name="lastName"
              label="Legal Last Name"
              placeholder="Last Name"
              v-model="owner.lastName"
              type="text"
              maxlength="128"
            />
          </BaseInputGroup>

          <AddressInput
            @address-changed="handleAddressChanged($event, owner)"
            :existingAddress="owner.address1"
            name="address1"
            label="Residential Street Address"
            placeholder="US street address"
          />

          <BaseInputGroup class="mb-3">
            <BaseInput
              name="address2"
              label="Apt/Unit"
              placeholder="#"
              v-model="owner.address2"
              class="mr-1"
              type="text"
              maxlength="128"
            />
            <BaseInput
              name="zipcode"
              label="ZIP Code"
              placeholder="00000"
              v-model="owner.zipcode"
              type="text"
              maxlength="5"
            />
          </BaseInputGroup>

          <BaseInputGroup>
            <BaseInput
              name="dob"
              label="Date of Birth"
              placeholder="MM/DD/YYYY"
              format="date"
              v-model="owner.dob"
              type="text"
              class="mr-1"
              maxlength="128"
            />
            <BaseInput
              name="ssn"
              label="Social Security"
              format="ssn"
              placeholder="555-55-5555"
              v-model="owner.ssn"
              maxlength="11"
              :disabled="owner.needsAlternateID"
            />
          </BaseInputGroup>

          <div class="mb-2">
            <label class="checkbox-label">
              <input
                type="checkbox"
                class="mr-2"
                v-model="owner.needsAlternateID"
              />
              This owner does not have an SSN
            </label>
          </div>
        </div>
        <button
          class="owner-contact"
          :class="{
            complete: isOwnerValid(owner),
            incomplete: errorMessage && !isOwnerValid(owner),
          }"
          v-else
          @click.prevent
        >
          <div class="button-content">
            <div class="contact-icon">
              <SVGIcon icon="contact" size="16" />
            </div>
            <div class="text-content">
              <span class="primary-text">Additional Owner</span>
              <span class="secondary-text"
                >{{ owner.firstName }} {{ owner.lastName }}</span
              >
            </div>
          </div>
          <div class="contact-actions">
            <SVGIcon
              icon="pencil"
              class="mr-3"
              @click.prevent="editOwner(index)"
            ></SVGIcon>
            <SVGIcon icon="trash" @click.prevent="removeOwner(index)"></SVGIcon>
          </div>
        </button>
      </div>

      <!-- Add another owner button -->
      <button
        class="owner-contact"
        @click.prevent="addOwner"
        :hidden="!canAddMoreOwners"
      >
        <div class="button-content">
          <div class="contact-icon">
            <SVGIcon icon="contact" size="16" />
          </div>
          <div class="text-content">
            <span class="primary-text">Add another owner</span>
            <span class="secondary-text">25% ownership or more</span>
          </div>
        </div>
        <span class="plus-icon">+</span>
      </button>
      <BaseButton
        variant="primary"
        type="submit"
        :loading="waiting"
        class="submit-button mt-3 mb-3"
      >
        Continue
      </BaseButton>
    </BaseForm>
  </KybVerificationContainer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { organizationStore, userStore } from "@/store";
import { BusinessOwner, OwnershipType } from "@/types/Organization";
import KybVerificationContainer from "@/views/kyb-verification/KybVerificationContainer.vue";
import BaseForm from "@/components/BaseForm.vue";
import BaseButton from "@/components/BaseButton.vue";
import SVGIcon from "@/components/SVGIcon.vue";
import AddressInput from "@/components/AddressInput.vue";
import { AddressResult } from "@/types/Onboarding";

@Component({
  components: {
    KybVerificationContainer,
    BaseForm,
    BaseButton,
    SVGIcon,
    AddressInput,
  },
})
export default class BusinessOwners extends Vue {
  currentlyEditing = -1;
  waiting = false;
  errorMessage = "";

  business: {
    ownershipType?: OwnershipType;
    owners: BusinessOwner[];
  } = {
    ownershipType: undefined,
    owners: [],
  };

  get kybUser() {
    return userStore.getters.currentUser!.kybVerificationUser;
  }

  get piwikPrefix() {
    return this.kybUser ? "Verification" : "Onboarding";
  }

  get canAddMoreOwners() {
    return (
      (this.business.ownershipType === OwnershipType.PRIMARY_USER_OWNS &&
        this.business.owners.length < 3) ||
      (this.business.ownershipType === OwnershipType.OTHER_PERSON_OWNS &&
        this.business.owners.length < 4)
    );
  }

  async created() {
    await userStore.actions.getCurrentUser();
    this.$piwik.trackEvent("Modal", "View", {
      name: `${this.piwikPrefix} Additional Owners`,
    });

    const currentStep = `kybBusinessOwners?ownership=${this.$route.query.ownership}`;
    if (this.kybUser) {
      userStore.actions.updateVerificationStep(currentStep);
    } else {
      userStore.actions.updateUser({ kybCurrentStep: currentStep });
    }

    if (this.$route.query.ownership === "primary") {
      this.business.ownershipType = OwnershipType.PRIMARY_USER_OWNS;
    } else if (this.$route.query.ownership === "other") {
      this.business.ownershipType = OwnershipType.OTHER_PERSON_OWNS;
    } else {
      this.$router.push({ name: "home" });
    }
  }

  mounted() {
    this.addOwner();
  }

  nextStep() {
    this.$router.push({ name: "kybBusinessUsecase" });
  }

  addOwner() {
    this.errorMessage = "";
    this.$piwik.trackClick({
      name: `${this.piwikPrefix} Additional Owners Add Another CTA`,
    });
    this.business.owners.push({
      firstName: "",
      lastName: "",
      address1: "",
      address2: "",
      zipcode: "",
      dob: "",
      ssn: "",
      needsAlternateID: false,
    });
    this.currentlyEditing = this.business.owners.length - 1;
  }

  handleAddressChanged(
    addressResult: AddressResult,
    currentOwner: BusinessOwner
  ): void {
    // Fill in data from google.  If user needs to change it, they can.
    currentOwner.address1 = addressResult.address1;
    if (addressResult.zipcode) {
      currentOwner.zipcode = addressResult.zipcode;
    }
  }

  removeOwner(index: number) {
    this.$bvModal
      .msgBoxConfirm("Are you sure you want to remove this owner?", {
        contentClass: "shadow confirm-content",
        centered: true,
        headerClass: "justify-content-center p-0",
        bodyClass: "text-center confirm-body p-3",
        footerClass: "justify-content-center pt-0",
        cancelVariant: "light",
        title: "Remove Owner",
        okTitle: "Yes",
        cancelTitle: "No",
      })
      .then((value) => {
        if (value) {
          this.$piwik.trackClick({
            name: `${this.piwikPrefix} Additional Owners Remove Owner CTA`,
          });
          this.business.owners.splice(index, 1);
          if (this.currentlyEditing === index) {
            this.currentlyEditing = -1;
          } else if (this.currentlyEditing > index) {
            this.currentlyEditing -= 1;
          }
        }
      });
  }

  editOwner(index: number) {
    this.$piwik.trackClick({
      name: `${this.piwikPrefix} Additional Owners Edit Owner CTA`,
    });
    this.currentlyEditing = index;
  }

  isOwnerValid(owner: BusinessOwner) {
    return (
      owner.firstName &&
      owner.lastName &&
      owner.address1 &&
      owner.zipcode &&
      owner.zipcode.length === 5 &&
      owner.dob &&
      owner.dob.length === 10 &&
      (owner.needsAlternateID || (owner.ssn && owner.ssn.length === 11))
    );
  }

  submit() {
    this.errorMessage = "";
    const ownersToSubmit = structuredClone(this.business.owners);

    if (this.business.ownershipType === OwnershipType.OTHER_PERSON_OWNS) {
      if (ownersToSubmit.length === 0) {
        this.errorMessage =
          "You must provide information for at least one owner.";
        return;
      }

      if (ownersToSubmit.length > 4) {
        this.errorMessage =
          "Please only provide information for owners who own 25% or more";
        return;
      }
    }

    if (
      this.business.ownershipType === OwnershipType.PRIMARY_USER_OWNS &&
      ownersToSubmit.length > 3
    ) {
      this.errorMessage =
        "Please only provide information for owners other than yourself who own 25% or more";
      return;
    }

    const hasInvalidOwner = ownersToSubmit.some(
      (owner) => !this.isOwnerValid(owner)
    );

    if (!hasInvalidOwner) {
      this.errorMessage = "";
    } else {
      this.errorMessage =
        "Please provide all required information for all owners.";
      return;
    }

    ownersToSubmit.forEach((owner) => {
      if (owner.ssn) {
        owner.ssn = owner.ssn.replace(/-/g, "");
      }
    });

    this.$piwik.trackClick({
      name: `${this.piwikPrefix} Additional Owners CTA`,
    });

    this.waiting = true;

    organizationStore.actions
      .execs({
        ownershipType: this.business.ownershipType,
        owners: ownersToSubmit,
      })
      .then(() => {
        this.nextStep();
      })
      .catch((err) => {
        this.errorMessage = err.response?.data?.message || "There was an error";
      })
      .finally(() => {
        this.waiting = false;
      });
  }
}
</script>

<style lang="scss" scoped>
::v-deep .kyb-verification-card {
  width: 460px !important;
}

::v-deep .form {
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
}

.form-heading {
  font-size: 16px;
  font-weight: 600;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
}

.owner-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 16px;
  border-radius: 12px;
  margin: 0 0 10px;
  width: 100%;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
  border: 1px solid #e0e0e0;
}

.owner-form {
  border: 1px solid #e0e0e0;
  margin: 0 0 10px;
  padding: 16px;
  border-radius: 16px;
}

.owner-contact:hover {
  background-color: #f8f8f8;
}

.complete {
  background-color: #1f995c1a;
  &:hover {
    background-color: #1f995c33;
  }
}

.incomplete {
  background-color: $danger-10;
  &:hover {
    background-color: $danger-20;
  }
}

.button-content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.contact-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.primary-text {
  font-size: 16px;
  color: #333;
  font-weight: 600;
}

.secondary-text {
  font-size: 14px;
  color: #666;
}

.plus-icon {
  font-size: 24px;
  color: #666;
  font-weight: 300;
}
</style>
