<template>
  <SimplePage :fullpage="fullpage">
    <div class="remediation-success" data-test="remediation-success">
      <div class="wrapper">
        <h1>You're all set!</h1>
        <div>Thank you for confirming your account information.</div>
        <BaseButton
          variant="primary"
          size="lg"
          @click="onCompleteStep"
          data-test="close"
        >
          {{ fullpage ? "Go to Dashboard" : "Dismiss" }}
        </BaseButton>
      </div>
    </div>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component
export default class RemediationSuccess extends Vue {
  @Prop({ default: false }) fullpage!: boolean;

  onCompleteStep() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.REMEDIATION_SUCCESS)} Primary CTA`,
    });

    this.$emit("complete-step", OnboardingSteps.COMPLETE);
  }
}
</script>

<style lang="scss">
.remediation-success {
  h1 {
    all: unset;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    color: $gray-800;
  }

  button {
    width: 100%;
  }

  .wrapper {
    max-width: 300px;
    margin: 0 auto;
    padding: 0px;
    display: grid;
    gap: 20px;
    text-align: center;
  }
}
</style>
