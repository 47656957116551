<template>
  <div class="card-list" ref="cardList">
    <div class="_card" v-for="card in cardsToDisplay" :key="card.cardID">
      <PrivacyCard
        :cardProp="card"
        :preview="true"
        @click-card="onClickCard(card)"
      />
      <TagRow
        v-if="showTags && selectedTagIds"
        @click-tag="openTagMenu"
        :cardID="card.cardID"
      >
      </TagRow>
    </div>
    <div class="zero-state _card" v-if="!cardsToDisplay.length">
      <div class="empty-card">No Cards</div>
    </div>
    <div class="spinner-container" v-if="loading">
      <b-spinner />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Card } from "@/types/Card";
import { tagStore } from "@/store";
import { Tag } from "@/types/Tag";
import { Toast } from "../mixins/Toast";
import { CardListPropsAndHandlers } from "../mixins/CardListPropsAndHandlers";
import PrivacyCard from "./PrivacyCard.vue";
import TagRow from "./TagRow.vue";

const INITIAL_LIMIT = 3;

@Component({
  components: {
    PrivacyCard,
    TagRow,
  },
})
export default class CardListComponent extends Mixins(
  Toast,
  CardListPropsAndHandlers
) {
  @Prop({ default: INITIAL_LIMIT }) incrementByProp!: number;

  tagList: Tag[] = [];
  limit = INITIAL_LIMIT;
  incrementBy = INITIAL_LIMIT;
  loading = false;
  offset = INITIAL_LIMIT;
  nodePaginate = false;
  intervalId = 0;
  cards: Card[] = [];

  get numberOfColumns() {
    const el = this.$refs.cardList as HTMLElement;
    return Math.max(Math.floor(el.offsetWidth / 240), 1);
  }

  created() {
    this.tagList = tagStore.getters.getTags;
    this.limit = this.limitProp;
    this.incrementBy = this.incrementByProp;
    this.cards = this.cardsProp;
  }

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.fillScreenWithCards();
  }

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  // "Pagination" (we don't actually paginate requests, just show more cards) is handled internally,
  // so if there's an update from the parent component, reset everything
  @Watch("cardsProp")
  replaceCardList() {
    const newCards = this.cardsProp;
    if (newCards) {
      this.cards = newCards;
      this.resetPagination();
    }
  }

  // in the case that the list end is above the viewport bottom, display more cards
  // until the screen is full and scroll bar appears
  @Watch("cardsProp")
  fillScreenWithCards() {
    clearInterval(this.intervalId);
    this.intervalId = window.setInterval(() => {
      if (this.shouldShowMore()) {
        this.incrementLimit();
      } else {
        clearInterval(this.intervalId);
      }
    }, 100);
  }

  resetPagination() {
    this.limit = INITIAL_LIMIT;
  }

  incrementLimit() {
    this.incrementBy = this.incrementBy
      ? this.incrementBy * this.numberOfColumns
      : INITIAL_LIMIT;
    this.limit += this.incrementBy;
  }

  handleScroll() {
    if (this.shouldShowMore()) {
      this.incrementLimit();
    }
  }

  shouldShowMore() {
    const el = this.$refs.cardList as HTMLElement;
    const bounding = el.getBoundingClientRect();
    return (
      bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) + 100
    );
  }
}
</script>
<style lang="scss" scoped>
.card-list {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > .spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  > ._card {
    display: flex;
    flex-basis: 200px;
    flex-direction: column;
    margin-bottom: 20px;
  }

  > ._card > card {
    cursor: pointer;
  }

  > ._card > .details {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    max-width: 200px;
  }

  > ._card > .details > .name {
    flex-grow: 1;
    max-width: 120px;
    overflow: hidden;
    font-size: 13px;
    white-space: nowrap;
  }

  > ._card > .details > .controls {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    opacity: 0;
    transition: opacity $duration-shorter;
  }

  > ._card:hover > .details > .controls {
    opacity: 1;
  }

  > ._card > .details > .controls > .svg-icon {
    opacity: 0.5;
    transition: opacity $duration-shorter;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  > ._card > .tag-row {
    width: 240px;
    margin-top: 10px;
  }

  .empty-card {
    height: 150px;
    width: 240px;
    background-color: $white;
    border-radius: 16px;
    border: 2px dashed rgba(141, 149, 166, 0.2);
    color: $gray-600;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
