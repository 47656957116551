<template>
  <div class="modal-statements" :class="{ '-single': singleStatement }">
    <div class="statement-list" v-if="!singleStatement">
      <div class="header">
        <div class="title">Statements</div>
      </div>
      <div class="content" :class="{ '-loading': statementLoading }">
        <div class="spinner-container" v-if="statementLoading || exportLoading">
          <b-spinner></b-spinner>
        </div>
        <div class="statements" v-if="statements.length">
          <div
            class="year"
            v-for="(year, i) in getReversedStatementYears()"
            :key="i"
          >
            <div class="year-split">
              {{ year }}
            </div>
            <div class="statement -note" v-if="i === 0">
              Your next statement will be available on
              {{ dateFormat(nextStatementAvailable, "MMMM D") }}
            </div>
            <div
              class="statement"
              v-for="(statement, j) in getReversedStatementsByYear(year)"
              @click="showStatement(statement)"
              :key="j"
            >
              <div class="date-range">
                {{ dateFormat(statement.startDate) }} -
                {{ dateFormat(statement.endDate) }}
              </div>
              <div class="amount">
                {{ parseCentAmount(statement.total) }}
              </div>
            </div>
          </div>
        </div>
        <div class="statement -empty" v-if="!statements.length">
          Your account has not been open long enough for a statement.
        </div>
      </div>
      <div class="footer" v-if="statements.length">
        <v-date-picker
          v-model="range"
          is-range
          locale="en-US"
          :min-date="minDate"
          :max-date="maxDate"
          class="datepicker"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <BaseInput
              label="Start Date"
              type="text"
              :value="dateFormat(inputValue.start)"
              readonly
              v-on="inputEvents.start"
            />
            <BaseInput
              label="End Date"
              type="text"
              :value="dateFormat(inputValue.end)"
              readonly
              v-on="inputEvents.end"
            />
          </template>
        </v-date-picker>
        <b-dropdown variant="success" size="sm" text="Export">
          <b-dropdown-item
            v-for="exportOption in exportOptions"
            @click="getCustomRangeStatement(exportOption)"
            :key="exportOption.label"
          >
            {{ exportOption.label }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="single-statement" v-if="singleStatement">
      <div class="header">
        <div class="title">
          <div class="back" @click="singleStatement = null"></div>
          <div class="month">
            {{ dateFormat(singleStatement.startDate, "MM/DD/YY") }} -
            {{ dateFormat(singleStatement.endDate, "MM/DD/YY") }}
          </div>
          <div class="total">Total</div>
          <div class="amount">
            {{ parseCentAmount(singleStatement.total) }}
          </div>
        </div>
      </div>
      <div class="content" :class="{ '-loading': !singleStatement.loaded }">
        <div
          class="spinner-container"
          v-if="!singleStatement.loaded || exportLoading"
        >
          <b-spinner></b-spinner>
        </div>
        <div class="transaction-list-header">
          <div class="merchant">Merchant</div>
          <div class="card-name">Card Name</div>
          <div class="card-number">Card #</div>
          <div class="date">Date</div>
          <div class="amount">Amount</div>
        </div>
        <div
          class="transaction-list"
          v-if="
            singleStatement &&
            singleStatement.statementTransactions &&
            singleStatement.statementTransactions.length
          "
        >
          <div
            class="transaction"
            v-for="transaction in singleStatement.statementTransactions"
            :key="transaction.transactionID"
          >
            <div class="merchant" :title="transaction.descriptor">
              {{ transaction.descriptor }}
            </div>
            <div
              class="card-name"
              :title="transaction.card && transaction.card.name"
            >
              {{ transaction.card && transaction.card.name }}
            </div>
            <div class="card-number" v-if="transaction.card">
              {{ "· · · " + transaction.card.PAN.slice(-4) }}
            </div>
            <div class="date">
              {{ utcDateFormat(transaction.displayDate) }}
            </div>
            <div
              class="amount"
              :class="{ '-refunded': transaction.state === 'DISPUTE_CLOSED' }"
            >
              {{ parseCentAmount(transaction.netAmount) }}
            </div>
          </div>
        </div>
        <div
          class="no-history"
          v-if="
            singleStatement &&
            (!singleStatement.statementTransactions ||
              !singleStatement.statementTransactions.length)
          "
        >
          No activity for this period
        </div>
      </div>
      <div class="footer">
        <b-dropdown class="download" size="sm" variant="success" text="Export">
          <b-dropdown-item
            v-for="exportOption in exportOptions"
            @click="getStatement(exportOption)"
            :key="exportOption.label"
          >
            {{ exportOption.label }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import ldGet from "lodash/get";
import { DateInputType, formatDate, getDate, utcDate } from "@/lib/dates";
import { Mixins, Component, Prop } from "vue-property-decorator";
import { transactionStore, cardStore } from "@/store";
import { parseCentAmount } from "@/util";
import { Statement, FormatOption } from "@/types/Statement";
import { Transaction, TransactionList } from "@/types/Transaction";
import { Card } from "@/types/Card";
import { User } from "@/types/User";
import { Toast } from "@/mixins/Toast";

@Component
export default class Statements extends Mixins(Toast) {
  @Prop({ required: true }) user!: User;

  parseCentAmount = parseCentAmount;
  singleStatement: Statement | null = null;
  statementLoading = false;
  statements: Statement[] = [];
  nextStatementAvailable: Date | null = null;
  minDate: Date | null = null;
  maxDate: Date | null = null;
  range: { start: Date | null; end: Date | null } = {
    start: null,
    end: null,
  };
  cardList: Card[] = [];
  exportLoading = false;
  exportOptions = [
    {
      label: "PDF",
      format: "pdf",
      extension: "pdf",
    },
    {
      label: "CSV",
      format: "csv",
      extension: "csv",
    },
  ];

  created() {
    const today = getDate();
    // date account was created
    let startDate = getDate(this.user.created).startOf("day");

    const minDate = startDate;
    let maxDate = getDate().subtract(7, "days");

    if (maxDate.isBefore(minDate)) {
      maxDate = minDate;
    }

    this.minDate = minDate.toDate();
    this.maxDate = maxDate.toDate();

    if (!this.statements.length) {
      this.statementLoading = true;

      let endDate = getDate(startDate).add(13, "days").endOf("day");

      // generate a list of statements to iterate over.
      // dont include statements with activity from the last week,
      // because these transactions may still be pending
      while (endDate.isSameOrBefore(getDate(today).subtract(7, "days"))) {
        this.statements.push({
          startDate: startDate.toDate(),
          endDate: endDate.toDate(),
          total: 0,
          loaded: false,
        });

        startDate = startDate.add(14, "days");
        endDate = endDate.add(14, "days");
      }

      this.nextStatementAvailable = getDate(endDate).add(7, "days").toDate();

      // limits for custom date range
      let dateRangeEnd = today.subtract(7, "days");
      const dateRangeStart = getDate(today).startOf("month");

      if (dateRangeEnd.isBefore(dateRangeStart)) {
        dateRangeEnd = dateRangeStart;
      }

      this.range.start = dateRangeStart.toDate();
      this.range.end = dateRangeEnd.toDate();

      if (!this.statements.length) {
        this.statementLoading = false;
        return;
      }

      cardStore.actions.getCards({}).then(() => {
        this.cardList = cardStore.getters.cardList().all;
      });

      // fetch all transactions
      transactionStore.actions
        .fetchTransactions({})
        .then((transactionList: TransactionList) => {
          this.statements.forEach((statement: Statement) => {
            const statementTransactions = transactionList.all.filter(
              (transaction: Transaction) => {
                const transactionDate = getDate(
                  transaction.dateAuthorized || transaction.dateSettled
                );
                const { startDate: stmtStartDate, endDate: stmtEndDate } =
                  statement;
                const isInStatement =
                  transactionDate.isAfter(getDate(stmtStartDate)) &&
                  transactionDate.isSameOrBefore(getDate(stmtEndDate));
                return (
                  isInStatement &&
                  transaction.dateSettled &&
                  transaction.accepted
                );
              }
            );

            statement.total = 0;
            statementTransactions.forEach((transaction: Transaction) => {
              statement.total += parseFloat(
                String(
                  transaction.amount - (transaction.promoCreditAmount || 0)
                )
              );
            });
          });

          this.statementLoading = false;
        });
    }
  }

  getReversedStatementYears() {
    const years = new Set<number>();
    this.statements.forEach((statement: Statement) => {
      years.add(statement.startDate.getFullYear());
    });
    return [...years].slice().reverse();
  }

  getReversedStatementsByYear(year: number) {
    return this.statements
      .slice()
      .reverse()
      .filter((statement: Statement) => {
        return statement.startDate.getFullYear() === year;
      });
  }

  getCardByID(cardID = "") {
    if (this.cardList && this.cardList.length > 0) {
      return this.cardList.filter((card) => {
        if (`${card.cardID}` === `${cardID}`) {
          card.panWithSpaces = (card.PAN.toString().match(/.{4}/g) || []).join(
            " "
          ); // split on every fourth for easy digesting
          card.name = card.memo || card.hostname || "Unnamed Card";
          return card;
        }
        return false;
      })[0];
    }
  }

  configureTransactionForDisplay(transaction: Transaction) {
    if (transaction.dateAuthorized) {
      transaction.dateAuthorized = utcDate(transaction.dateAuthorized).toDate();
    }
    if (transaction.dateSettled) {
      transaction.dateSettled = utcDate(transaction.dateSettled).toDate();
    }

    transaction.displayDate = formatDate(
      "YYYY-MM-DD HH:mm:ss",
      transaction.dateAuthorized || transaction.dateSettled
    );
    transaction.netAmount =
      transaction.amount - (transaction.promoCreditAmount || 0);
    transaction.card = this.getCardByID(transaction.cardID);

    return transaction;
  }

  showStatement(statement: Statement) {
    this.singleStatement = statement;
    const { startDate, endDate } = statement;
    const formattedStartDate = formatDate("YYYY-MM-DD", startDate);
    const formattedEndDate = formatDate(
      "YYYY-MM-DD HH:mm:ss",
      getDate(endDate).endOf("day")
    );

    transactionStore.actions
      .getStatement({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      })
      .then((result) => {
        if (this.singleStatement) {
          const transactionList = ldGet<any, string, Transaction[]>(
            result,
            "data.transactionList",
            []
          ).map(this.configureTransactionForDisplay);

          this.singleStatement = {
            ...this.singleStatement,
            statementTransactions: transactionList,
            loaded: true,
          };
        }
      });
  }

  getStatement(formatOption: FormatOption) {
    const startDate = ldGet<any, string>(this, "singleStatement.startDate");
    const endDate = ldGet<any, string>(this, "singleStatement.endDate");
    this.sendStatementRequest(formatOption, startDate, endDate);
  }

  getCustomRangeStatement(formatOption: FormatOption) {
    this.sendStatementRequest(formatOption, this.range.start, this.range.end);
  }

  sendStatementRequest(
    formatOption: FormatOption,
    startDate: Date | null,
    endDate: Date | null
  ) {
    this.exportLoading = true;
    // Format start and end dates
    const formattedStartDate = formatDate("YYYY-MM-DD", startDate);
    const formattedEndDate = formatDate(
      "YYYY-MM-DD HH:mm:ss",
      getDate(endDate).endOf("day")
    );

    transactionStore.actions
      .getStatementExport({
        formatOption,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      })
      .catch((error) => {
        if (error) {
          this.errorToast("There was an error retrieving your statement");
        }
      })
      .finally(() => {
        this.exportLoading = false;
      });
  }

  closeModal() {
    this.$bvModal.hide("statements");
  }

  dateFormat(date: DateInputType, format = "MMM D, YYYY") {
    return formatDate(format, date);
  }

  utcDateFormat(date: DateInputType) {
    return formatDate("MM/DD/YY", utcDate(date));
  }
}
</script>
<style lang="scss" scoped>
.modal-statements {
  &.-single {
    position: relative;
    height: 500px;
  }

  > .statement-list {
    > .header {
      padding-bottom: 30px;
      box-shadow: 0 1px 0 0 $color-shadow-black-faint;

      > .title {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        color: $gray-800;
      }
    }

    > .content {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 350px;

      &.-loading {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        > *:not(.spinner-container) {
          display: none;
        }
      }

      > .spinner-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      > .statements {
        height: 350px;
        width: 100%;
        overflow-y: auto;
        transform: translateZ(0px); // fixing scrollbar visual bug
        padding: 10px 0;

        > .year > .year-split {
          padding: 10px 0;
          overflow: hidden;
          font-size: 10px;
          font-weight: 800;
          letter-spacing: 1.5px;
          line-height: 8px;
          text-transform: uppercase;
          white-space: nowrap;
          color: $gray-600;

          &::after {
            content: "";
            position: relative;
            top: -3px;
            left: 10px;
            display: inline-block;
            height: 1px;
            width: 100%;
            background: $color-shadow-black-faint;
          }
        }

        .statement {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 7.5px 0;

          &.-note {
            color: $gray-600;
          }

          &:hover:not(.-note) {
            color: $gray-600;
            cursor: pointer;
          }

          > .month {
            display: flex;
            align-items: center;
          }

          > .amount {
            margin-left: auto;
            width: 100px;
            text-align: right;
          }
        }
      }

      > .statement.-empty {
        margin-top: 30px;
        color: $gray-600;
      }
    }

    > .footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 30px 0 20px;
      box-shadow: 0 -1px 0 0 $color-shadow-black-faint;

      .datepicker {
        width: 100%;

        .field {
          margin-bottom: 0px;
          display: inline-flex;
          width: 120px;

          &:first-of-type {
            margin-right: 20px;
          }
        }
      }
    }
  }

  > .single-statement {
    > .header {
      padding-bottom: 30px;
      box-shadow: 0 1px 0 0 $color-shadow-black-faint;

      > .title {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        color: $gray-800;

        > .back {
          float: left;
          margin: -10px 0 -12px -14px;
          height: 40px;
          width: 40px;
          background: url("/assets/images/icons/chevron-left-20.svg") center
            no-repeat;
          transition: $duration-shorter;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        }

        > .date-range {
          width: 240px;
          font-size: 24px;
          font-weight: 600;
          line-height: 30px;
          color: $gray-800;
        }

        > .total {
          margin-top: auto;
          margin-left: auto;
          font-size: 12px;
          text-align: right;
        }

        > .amount {
          margin-left: 20px;
          text-align: right;
        }
      }
    }

    > .content {
      position: relative;
      height: 380px;
      overflow-y: auto;

      &.-loading {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        > *:not(.spinner-container) {
          display: none;
        }
      }

      > .spinner-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      > .transaction-list-header {
        display: flex;
        flex-shrink: 0;
        padding: 3px 0 0;
        box-shadow: 0 1px 0 rgba(20, 31, 51, 0.1);
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;

        > .merchant {
          width: 240px;
        }

        > .card-name {
          width: 100px;
        }

        > .card-number {
          width: 70px;
          text-align: right;
        }

        > .month {
          width: 120px;
        }

        > .date {
          margin-left: auto;
          width: 50px;
          text-align: right;
        }

        > .amount {
          margin-left: auto;
          width: 100px;
          text-align: right;
        }
      }

      > .transaction-list > .transaction {
        display: flex;
        align-items: center;
        padding: 7.5px 0;

        > .merchant {
          width: 240px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        > .card-name {
          width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        > .card-number {
          width: 70px;
          text-align: right;
        }

        > .month {
          width: 120px;
        }

        > .date {
          margin-left: auto;
          width: 50px;
          font-size: 10px;
          font-weight: bold;
          letter-spacing: 1px;
          text-align: right;
          text-transform: uppercase;
          opacity: 0.5;
        }

        > .amount {
          margin-left: auto;
          width: 100px;
          font-weight: 600;
          text-align: right;

          &.refunded {
            color: $color-green;
          }

          &.refunded::before {
            content: "+";
          }

          &.minus {
            color: $color-red;
          }
        }

        &:hover {
          color: $gray-600 !important;
        }
      }

      > .no-history {
        padding: 30px 0;
        color: $gray-600;
      }
    }

    > .footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 30px;
      box-shadow: 0 -1px 0 0 $color-shadow-black-faint;
    }
  }
}
</style>
