<template>
  <div class="funding-card-preview" v-on="$listeners" v-if="fundingCard">
    <div class="card-name">{{ title }}</div>
    <div class="last-four">
      &middot; &middot; &middot;
      {{ fundingCard.lastFour || fundingCard.fundingLastFour }}
    </div>
    <SVGIcon class="card" v-if="fundingCard" :icon="svgIcon" size="40" />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { fundingCardStore } from "@/store";
import SVGIcon from "@/components/SVGIcon.vue";
import { FundingCard } from "@/types/Funding";

@Component({
  components: {
    SVGIcon,
  },
})
export default class FundingCardPreview extends Vue {
  @Prop({ default: null }) fundingCard?: FundingCard;
  @Prop({ default: "Funding Card" }) title!: string;

  svgIcon = "card";

  created() {
    this.svgIcon = fundingCardStore.getters.icon(this.fundingCard) || "card";
  }
}
</script>

<style lang="scss" scoped>
.funding-card-preview {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  height: 60px;
  border: 2px solid $gray-100;
  border-radius: $border-radius;
  font-size: 14px;

  > .card.svg-icon {
    height: auto;
    width: auto;
  }

  > .card-name {
    font-weight: bold;
  }

  > .last-four {
    margin-right: 10px;
    margin-left: auto;
  }
}
</style>
