import { ActionHandler, MutationHandler } from "./vuex-typex";
import { RootState, storeBuilder } from "./storeBuilder";

class CdnState {
  memoized: { [key: string]: boolean } = {};
}

const builder = storeBuilder.module<CdnState>("cdn", new CdnState());

type CdnMutation<Payload = void> = MutationHandler<CdnState, Payload>;
type CdnAction<Payload = void, Type = void> = ActionHandler<
  CdnState,
  RootState,
  any,
  Payload,
  Type
>;

const setUrlStatus: CdnMutation<{
  url: string;
  status: boolean;
}> = (state, { url, status }) => {
  state.memoized[url] = status;
};

export const mutations = {
  setUrlStatus: builder.commit(setUrlStatus),
};

const tryUrl: CdnAction<string> = ({ state }, url) => {
  return new Promise((resolve, reject) => {
    function success() {
      resolve();
      mutations.setUrlStatus({ url, status: true });
    }

    function error() {
      reject();
      mutations.setUrlStatus({ url, status: false });
    }

    if (url) {
      if (state.memoized[url] !== undefined) {
        // Return previous load result (true if it loaded, false if it did not)
        if (state.memoized[url]) {
          resolve();
        } else {
          reject();
        }
      } else {
        const img = new Image();
        img.addEventListener("load", success);
        img.addEventListener("error", error);
        img.src = url;
      }
    } else {
      reject();
    }
  });
};

export const actions = {
  tryUrl: builder.dispatch(tryUrl),
};
