<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm :fullpage="fullpage" @submit="submit" class="success-bank">
      <h1>Your Details</h1>
      <div class="explainer">
        <div class="content" v-if="mostRecentBank">
          <BankImage :bank="mostRecentBank.bank" width="60" />
          <div>
            <b class="title">{{ mostRecentBank.accountName }}</b>
            <div>
              · · · {{ mostRecentBank.number }}
              {{ mostRecentBank.bankName }}
            </div>
          </div>
          <div class="created">
            <div class="wrapper">
              <SVGIcon icon="tick" />
            </div>
            Account Connected
          </div>
        </div>
      </div>
      <BaseButton
        class="mt-2"
        type="submit"
        variant="primary"
        size="lg"
        data-test="success-bank-continue"
        @click="trackClick"
      >
        Continue
      </BaseButton>
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

import { User } from "@/types/User";
import { userStore } from "@/store";
import BankImage from "@/components/BankImage.vue";
import SVGIcon from "@/components/SVGIcon.vue";
import { FundingBank } from "@/types/Funding";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component({
  components: {
    BankImage,
    SVGIcon,
  },
})
export default class SuccessBank extends Vue {
  @Prop({ default: false }) fullpage!: boolean;

  user: User | null = null;

  get mostRecentBank() {
    const sortedBanks = [...(this.user?.bankAccountList || [])].sort(
      (a: FundingBank, b: FundingBank) => {
        return new Date(b.created).getTime() - new Date(a.created).getTime();
      }
    );
    return sortedBanks[0];
  }

  created() {
    userStore.actions.getCurrentUser().then(() => {
      this.user = userStore.getters.currentUser;
    });
  }

  trackClick(): void {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.SUCCESS_BANK)} Primary CTA`,
    });
  }

  submit() {
    this.$emit("complete-step");
  }
}
</script>

<style lang="scss" scoped>
::v-deep .form {
  width: 21.75rem;
}

.success-bank {
  text-align: center;
  opacity: 0;
  animation: show-step $duration-default;
  animation-timing-function: $ease-out-expo;
  animation-fill-mode: forwards;

  > h1 {
    all: unset;
    margin-bottom: 15px;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    color: $gray-800;
    line-height: 1.4;
  }

  .explainer {
    position: relative;

    .content {
      padding: 48px 32px;
      background: $gray-100;
      border-radius: $border-radius;
      color: $gray-600;
      display: grid;
      gap: 30px;

      .created {
        display: flex;
        justify-content: center;
        color: $color-green;
        font-weight: 600;

        .wrapper {
          width: 24px;
          height: 24px;
          background: $color-green;
          border-radius: 50%;
          margin-right: 8px;
        }
      }

      ::v-deep .svg-icon path {
        fill: $gray-100;
      }

      .title {
        font-weight: 600;
        color: $gray-800;
      }
    }
  }

  > button {
    margin: 20px 0 15px !important;
  }
}
</style>
