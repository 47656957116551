<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :fullpage="fullpage"
      @submit="onClickFunding"
      class="funding-type-charge"
      data-test="funding-type-charge"
    >
      <h1>Connect Bank Account</h1>
      <b-alert variant="danger" :show="errorMessage.length > 0">
        {{ errorMessage }}
      </b-alert>
      <div class="explainer">
        <div class="content">
          <SVGIcon
            class="funding-icon-bank"
            icon="funding-bank"
            size="80"
            data-test="funding-icon-bank"
          />
          <div class="description">
            The account you connect will be used to fund purchases you make on
            Privacy cards.
          </div>
          <div class="description">
            By connecting, you're enrolling in the automatic funding of
            purchases.
          </div>
          <BaseButton
            class="mt-2"
            type="submit"
            variant="primary"
            size="lg"
            data-test="connect-enroll"
            aria-label="connect and enroll"
            @click="trackClick"
          >
            Connect &amp; Enroll
          </BaseButton>
          <b-link
            @click="onClickCard"
            data-test="connect-debit-card"
            aria-label="connect a debit card"
          >
            Can't connect a bank?
          </b-link>
          <b-link
            @click="onClickReadMore"
            data-test="other-payment-types"
            aria-label="read more on other payment types"
          >
            Other payment types
          </b-link>
        </div>
      </div>
    </BaseForm>
    <ManualPaymentsExplainer
      @manual-payments="onClickManualPayments"
      @automatic-payments="onClickFunding"
    ></ManualPaymentsExplainer>
    <BaseModal id="add-card" hide-header>
      <AddCard @complete-step="submit(FundingSource.CARD)" />
    </BaseModal>
    <BaseModal id="add-funding" hide-header>
      <AddFunding
        @complete-step="submit(FundingSource.BANK)"
        :mobile="mobile"
      />
    </BaseModal>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Confirm } from "@/mixins/Confirm";

import { FundingSource } from "@/types/Funding";
import { User } from "@/types/User";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";
import { userStore } from "@/store";
import { BAlert } from "bootstrap-vue";
import SVGIcon from "@/components/SVGIcon.vue";
import { Toast } from "@/mixins/Toast";
import ManualPaymentsExplainer from "@/components/ManualPaymentsExplainer.vue";
import AddCard from "./AddCard.vue";
import AddFunding from "./AddFunding.vue";

@Component({
  components: {
    BAlert,
    SVGIcon,
    AddCard,
    AddFunding,
    ManualPaymentsExplainer,
  },
})
export default class FundingTypeCharge extends Mixins(Confirm, Toast) {
  @Prop({ default: false }) fullpage!: boolean;
  @Prop({ default: false }) mobile!: boolean;

  FundingSource = FundingSource;
  errorMessage = "";
  user: User | null = null;

  created(): void {
    userStore.actions.getCurrentUser().then(() => {
      this.user = userStore.getters.currentUser;
    });
  }

  trackClick() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.FUNDING_TYPE_CHARGE)} Primary CTA`,
    });
  }

  onClickReadMore(): void {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.FUNDING_TYPE_CHARGE)} Other Payment Types CTA`,
    });

    this.$bvModal.show("manual-payments-explainer");
  }

  onClickFunding(): void {
    userStore.actions
      .automaticPaymentsEnroll()
      .then(() => {
        this.user = userStore.getters.currentUser;
        this.$bvModal.hide("automatic-payments");
        if (this.fullpage) {
          this.$emit("complete-step", OnboardingSteps.ADD_BANK);
        } else {
          this.$bvModal.show("add-funding");
        }
      })
      .catch((error) => {
        let msg = error.message;

        if (error.response) {
          msg = error.response.data.message || msg;
        }

        this.errorToast(msg);
      });
  }

  onClickCard(): void {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.FUNDING_TYPE_CHARGE)} Debit Card CTA`,
    });

    userStore.actions.automaticPaymentsEnroll().then(() => {
      this.user = userStore.getters.currentUser;
      if (this.fullpage) {
        this.$emit("complete-step", OnboardingSteps.ADD_CARD);
      } else {
        this.$bvModal.show("add-card");
      }
    });
  }

  onClickManualPayments(): void {
    if (userStore.getters.isPendingManualPaymentsApproval) {
      this.$emit("complete-step");
    } else {
      userStore.actions.manualPaymentsApply().then(() => {
        this.user = userStore.getters.currentUser;
        this.$emit("complete-step");
      });
    }
  }

  submit(fundingType: FundingSource = FundingSource.BANK): void {
    if (fundingType === FundingSource.BANK) {
      // TODO handle routing/account?
      this.$emit("complete-step", OnboardingSteps.SUCCESS_BANK);
    } else {
      this.$emit("complete-step");
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .form {
  width: 21.75rem;
}

.add-funding {
  width: auto;
}

.funding-type-charge {
  text-align: center;
  opacity: 0;
  animation: show-step $duration-default;
  animation-timing-function: $ease-out-expo;
  animation-fill-mode: forwards;

  > h1 {
    all: unset;
    margin-bottom: 15px;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    color: $gray-800;
    line-height: 1.4;
  }

  .explainer {
    position: relative;

    .content {
      padding: 24px 32px;
      background: $gray-100;
      border-radius: $border-radius;
      color: $gray-600;
      display: grid;
      gap: 20px;
      justify-items: center;

      ::v-deep .funding-icon-bank svg {
        zoom: 180%;
      }

      .title {
        margin-bottom: 5px;
        font-weight: 600;
        color: $gray-800;
      }
    }
  }

  button {
    width: 100%;
  }
}
</style>
