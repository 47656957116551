<template>
  <div class="helper -charge-terms" data-test="commercial-charge-terms-helper">
    <div class="title">We're updating our Privacy Cards!</div>
    <div class="body">
      <p>Take action to create new cards</p>
    </div>
    <div class="controls">
      <BaseButton
        data-test="commercial-get-started"
        variant="primary"
        shape="round"
        class="action"
        @click="onClickCTA"
        width="narrow"
        >Get Started</BaseButton
      >
    </div>
    <div class="bg-image">
      <img
        class="logo"
        src="/assets/images/components/dashboard-helpers/commercial-charge-helper.svg"
        width="150"
        alt=""
      />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import PrivacyLogo from "../PrivacyLogo.vue";

@Component({
  components: { PrivacyLogo },
})
export default class HelperChargeTerms extends Vue {
  onClickCTA(): void {
    this.$emit("show-commercial-charge-terms-reminder");
  }
}
</script>
<style lang="scss" scoped>
.helper.-charge-terms {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  background: linear-gradient(
      96.5deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    #e7eef6;

  > .title {
    font-family: $font-stack-wes-fy;
    font-size: 18px;
    color: $gray-800;
    max-width: 195px;
    line-height: 19px;
  }

  > .body {
    position: absolute;
    top: 80px;
    max-width: 300px;
    font-family: $font-stack-lato;
    font-size: 14px;
    font-weight: 400;
    color: $gray-600;
  }

  > .controls {
    display: flex;
    justify-content: flex-start;
    margin-top: auto;
    height: 50px;
    width: 100%;

    > .action {
      color: $white;
      font-family: $font-stack-wes-fy;
      font-weight: 900;
      font-size: 14px;
    }
  }

  > .bg-image {
    position: absolute;
    bottom: 0;
    right: -33px;
    height: 235px;
    width: 172px;
  }
}
</style>
