<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :fullpage="fullpage"
      @submit="submit"
      :class="['signup-step', '-business-owners', { '-loading': waiting }]"
      data-test="business-owners"
    >
      <h1>Executives &amp; Owners</h1>
      <p class="blurb">
        Regulations require us to collect information of company executives and
        persons with <strong>25% or more</strong> ownership in the business.
      </p>
      <b-alert variant="danger" :show="errorMessage.length > 0">{{
        errorMessage
      }}</b-alert>
      <div
        class="owner-field my-2"
        v-for="(owner, index) in owners"
        :key="index"
        :class="{ '-error': errorIndex === index }"
      >
        <div class="label">Owner Details</div>
        <a
          class="side-link"
          v-if="owners.length > 1"
          @click="removeBusinessOwner(index)"
          :data-test="'button-owner-remove-' + index"
          >Remove</a
        >
        <BaseInputGroup>
          <BaseInput
            :name="'firstName' + index"
            placeholder="First Name"
            v-model="owners[index].firstName"
            type="text"
            maxlength="128"
            :data-test="'input-owner-first-name-' + index"
          />
          <BaseInput
            :name="'lastName' + index"
            placeholder="Last Name"
            v-model="owners[index].lastName"
            type="text"
            maxlength="128"
            :data-test="'input-owner-last-name-' + index"
          />
        </BaseInputGroup>
        <div
          class="prefilled-phone"
          v-if="user && showPrefilledPhone && index === 0"
          @click="showPhoneInput($event)"
        >
          (&bull;&bull;&bull;) &bull;&bull;&bull;-{{ user.phoneLastFour }}
        </div>
        <BaseInput
          class="phone-input"
          v-show="!showPrefilledPhone || index !== 0"
          :name="'phone' + index"
          placeholder="Phone Number"
          v-model="owners[index].phone"
          type="text"
          maxlength="14"
          :format="Format.PHONE"
          :data-test="'input-owner-phone-' + index"
        />
        <BaseInput
          :name="'address1' + index"
          label="Street Address"
          placeholder="Your US Street Address"
          v-model="owners[index].address1"
          type="text"
          maxlength="128"
          :data-test="'input-owner-address1-' + index"
        />
        <BaseInputGroup>
          <BaseInput
            :name="'address2' + index"
            label="Apt/Unit"
            placeholder="Apt/Unit"
            v-model="owners[index].address2"
            type="text"
            maxlength="128"
          />
          <BaseInput
            :name="'zipcode' + index"
            label="ZIP Code"
            placeholder="ZIP Code"
            v-model="owners[index].zipcode"
            type="text"
            maxlength="5"
            :data-test="'input-owner-zipcode-' + index"
          />
        </BaseInputGroup>
        <BaseInput
          :name="'dob' + index"
          label="Date of Birth (MM/DD/YYYY)"
          placeholder="Date of Birth (MM/DD/YYYY)"
          v-model="owners[index].dob"
          type="text"
          maxlength="10"
          :format="Format.DATE"
          :data-test="'input-owner-dob-' + index"
        />
        <BaseInput
          class="ssn-input"
          v-if="isIssuingSignup"
          :name="'ssn' + index"
          placeholder="SSN"
          v-model="owners[index].ssn"
          type="text"
          maxlength="11"
          :format="Format.SSN"
          :data-test="'input-owner-ssn-' + index"
        />
      </div>

      <BaseButton
        class="add-business-owner my-1"
        v-if="owners.length < 4"
        variant="outline-dark"
        size="lg"
        @click="addBusinessOwner"
        data-test="button-add-owner"
      >
        <SVGIcon icon="add" size="12" />
        Add Additional
      </BaseButton>

      <BaseButton
        class="my-1"
        variant="primary"
        type="submit"
        size="lg"
        :loading="waiting"
        data-test="submit"
        >Submit</BaseButton
      >

      <BaseButton
        class="my-1"
        variant="light"
        size="lg"
        @click="sendToNextPage"
        data-test="button-business-owners-skip"
        >Skip</BaseButton
      >
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import ldGet from "lodash/get";
import { organizationStore, subscriptionStore, userStore } from "@/store";
import { User } from "@/types/User";
import { Format } from "@/types/Form";
import { BAlert } from "bootstrap-vue";
import SVGIcon from "@/components/SVGIcon.vue";
import { BusinessOwner } from "@/types/Organization";
import { formatNumOnly } from "@/util";

const newOwner = (): BusinessOwner => ({
  firstName: "",
  lastName: "",
  phone: "",
  address1: "",
  address2: "",
  zipcode: "",
  dob: "",
  ssn: "",
});

@Component({
  components: {
    BAlert,
    SVGIcon,
  },
})
export default class BusinessOwners extends Vue {
  @Prop({ default: false }) fullpage!: boolean;
  user: User | null = null;
  showPrefilledPhone = false;
  owners = [newOwner()];
  waiting = false;
  errorMessage = "";
  errorIndex = -1;
  isIssuingSignup = false;
  Format = Format;

  created() {
    userStore.actions.getCurrentUser().then(() => {
      this.user = userStore.getters.currentUser;
      const { subscription } = subscriptionStore.getters;

      this.isIssuingSignup =
        ldGet<any, string>(this.user, "organization.pendingIssuing") ||
        subscription?.subscriptionType === "STARTER_ISSUING";
      if (this.user?.firstName && this.user?.lastName) {
        this.owners[0].firstName = this.user.firstName;
        this.owners[0].lastName = this.user.lastName;
      }
      if (this.user?.phoneLastFour) {
        this.owners[0].reusePhone = true;
        this.showPrefilledPhone = true;
      }
    });
  }

  submit() {
    this.clearErrors();

    for (const [index, owner] of this.owners.entries()) {
      if (!owner.firstName || !owner.firstName.length) {
        this.errorMessage = "Please enter the owner's first name";
        this.errorIndex = index;
        return;
      }

      if (!owner.lastName || !owner.lastName.length) {
        this.errorMessage = "Please enter the owner's last name";
        this.errorIndex = index;
        return;
      }

      if ((!owner.phone || owner.phone.length !== 14) && !owner.reusePhone) {
        this.errorMessage = "Please enter a valid 10-digit phone number";
        this.errorIndex = index;
        return;
      }

      if (!owner.address1 || !owner.address1.length) {
        this.errorMessage = "Please enter your street address";
        this.errorIndex = index;
        return;
      }

      if (!owner.zipcode || owner.zipcode.length !== 5) {
        this.errorMessage = "Please enter a valid ZIP code";
        this.errorIndex = index;
        return;
      }

      if (!owner.dob || owner.dob.length !== 10) {
        this.errorMessage =
          "Please enter a correctly formatted date of birth (MM/DD/YYYY)";
        this.errorIndex = index;
        return;
      }

      if (this.isIssuingSignup && (!owner.ssn || owner.ssn.length !== 11)) {
        this.errorMessage =
          "Please enter a valid 9-digit Social Security number";
        this.errorIndex = index;
        return;
      }
    }

    // Strip formatting from some input values
    const ownersUnformatted = this.owners.map((owner) => ({
      ...owner,
      phone: formatNumOnly(owner.phone || ""),
      dob: formatNumOnly(owner.dob),
      ssn: formatNumOnly(owner.ssn || ""),
    }));

    this.waiting = true;
    organizationStore.actions
      .owners({ owners: ownersUnformatted })
      .then(() => {
        this.sendToNextPage();
      })
      .catch((err) => {
        this.errorMessage =
          err.response?.data?.message ||
          "We weren't able to update your account. Please try again later or contact support.";
      })
      .finally(() => {
        this.waiting = false;
      });
  }

  addBusinessOwner() {
    this.owners.push(newOwner());
  }

  removeBusinessOwner(index: number) {
    this.clearErrors();
    this.owners.splice(index, 1);
  }

  sendToNextPage() {
    this.$emit("complete-step");
  }

  showPhoneInput(event: any) {
    this.showPrefilledPhone = !this.showPrefilledPhone;
    this.owners[0].reusePhone = false;
    const el = event.target.parentElement.querySelector(".phone-input");
    window.setTimeout(function () {
      el.focus();
    });
  }

  clearErrors() {
    this.errorIndex = -1;
    this.errorMessage = "";
  }
}
</script>

<style lang="scss" scoped>
@import "./business-info-base";

::v-deep .signup-step.-business-owners {
  .owner-field {
    position: relative;
    padding: 15px 20px;
    background-color: $gray-100;
    border-radius: $border-radius;
    animation: $animation-fade-in-up !important;

    &.-error {
      box-shadow: inset 0 0 0 2px fade-out($color-privacy-red, 0.5);
    }

    > .label {
      padding: 5px 0 0;
      font-size: 12px;
      font-weight: bold;
      line-height: 10px;
    }

    > .side-link {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 12px;
      font-weight: bold;
      line-height: 10px;
      color: $blue;
      cursor: pointer;

      &:hover {
        color: darken($blue, 10%);
      }
    }

    .field > .title {
      display: none;
    }

    .field > input {
      position: static;
      padding: 5px 5px 0;
    }

    .field {
      padding: 0;
      background-color: transparent;
    }

    .prefilled-phone {
      padding: 10px 5px 0;
      background-color: transparent;

      &:hover {
        cursor: text;
      }
    }
  }

  .add-business-owner {
    box-shadow: inset 0 0 0 2px $gray-100;
    color: $gray-600;

    > .svg-icon {
      vertical-align: baseline;
      fill: $gray-600;
    }

    > .svg-icon path {
      fill: $gray-600;
    }
  }
}
</style>
