import throttle from "lodash/throttle";

const MINUTE = 1000 * 60;
const SESSION_LENGTH = MINUTE * 15; // 15 minutes
const SESSION_COUNTDOWN_LENGTH = MINUTE * 3; // 3 minutes
const INTERACTIVITY_TIMER_LENGTH = SESSION_LENGTH - SESSION_COUNTDOWN_LENGTH;
const INTERACTION_EVENTS = ["mousemove", "scroll", "keypress", "click"];

const eventListenerOptions: AddEventListenerOptions = {
  capture: true,
  passive: true,
};

interface AutoLogoutTimerOptions {
  onCountdownStart: (timeRemaining: number) => void;
  onCountdownUpdate: (timeRemaining: number) => void;
  onCountdownEnd: () => void;
}

export default class AutoLogoutTimer {
  private interactivityTimer: number | undefined;
  private warningInterval: number | undefined;
  private logoutTime: number | undefined;
  private onCountdownStart: AutoLogoutTimerOptions["onCountdownStart"];
  private onCountdownUpdate: AutoLogoutTimerOptions["onCountdownUpdate"];
  private onCountdownEnd: AutoLogoutTimerOptions["onCountdownEnd"];

  constructor(options: AutoLogoutTimerOptions) {
    ["onCountdownStart", "onCountdownUpdate", "onCountdownEnd"].forEach(
      (option) => {
        if (!options[option as keyof AutoLogoutTimerOptions]) {
          throw new Error(`Missing required option: ${option}`);
        }
      }
    );

    this.onCountdownEnd = options.onCountdownEnd;
    this.onCountdownStart = options.onCountdownStart;
    this.onCountdownUpdate = options.onCountdownUpdate;
  }

  get interactivityTimerLength() {
    return INTERACTIVITY_TIMER_LENGTH;
  }

  get sessionCountdownLength() {
    return SESSION_COUNTDOWN_LENGTH;
  }

  get sessionLogoutTime() {
    return this.logoutTime;
  }

  public start() {
    this.startSessionTimers();
    this.addEventListeners();
  }

  public stop() {
    this.removeEventListeners();
    this.clearSessionTimers();
    this.clearWarningInterval();
  }

  public reset() {
    this.stop();
    this.start();
  }

  private clearSessionTimers() {
    this.logoutTime = undefined;

    window.clearTimeout(this.interactivityTimer);
  }

  private clearWarningInterval() {
    window.clearInterval(this.warningInterval);
  }

  private resetSessionTimers = throttle(
    () => {
      this.clearSessionTimers();
      this.startSessionTimers();
    },
    2000,
    { leading: true, trailing: false }
  );

  private addEventListeners() {
    INTERACTION_EVENTS.forEach((event) => {
      window.addEventListener(
        event,
        this.resetSessionTimers,
        eventListenerOptions
      );
    });
  }

  private removeEventListeners() {
    INTERACTION_EVENTS.forEach((event) => {
      window.removeEventListener(
        event,
        this.resetSessionTimers,
        eventListenerOptions
      );
    });
  }

  private startWarningCountdown() {
    this.onCountdownStart?.(this.logoutTime! - Date.now());

    this.warningInterval = window.setInterval(() => {
      const remaining = this.logoutTime! - Date.now();
      if (remaining > 0) {
        this.onCountdownUpdate?.(remaining);
      } else {
        this.clearWarningInterval();
        this.onCountdownEnd?.();
      }
    }, 1000);
  }

  private startSessionTimers() {
    this.logoutTime = Date.now() + SESSION_LENGTH;

    this.interactivityTimer = window.setTimeout(() => {
      this.removeEventListeners();
      this.startWarningCountdown();
    }, INTERACTIVITY_TIMER_LENGTH);
  }
}
