<template>
  <b-modal
    :id="id"
    :dialog-class="{
      'sharing-dialog': true,
      'show-spend-limit': showSpendLimit,
    }"
    header-class="sharing-header"
    body-class="sharing-body"
    hide-footer
    centered
    @show="getThemes"
  >
    <EditSpendLimitForm
      :card="card"
      :isSharingCard="true"
      class="spend-limit-form"
      @update-card="updateCard"
      @cancel-update="closeModal"
      :subscription="subscription"
      v-if="showSpendLimit"
    />
    <div class="sharing-wrapper" v-else>
      <div class="theme-select">
        <div
          :class="[
            'theme-preview',
            currentTheme.toLowerCase() + '-theme',
            { 'bg-loaded': bgLoaded },
          ]"
          :style="backgroundImageVar"
        >
          <CardComponent
            :card="card"
            :preview="true"
            class="mx-auto mb-4 shared-card"
          >
            <template v-if="card.spendLimit" #preview>
              <div class="limit-preview">
                ${{ card.spendLimit }} {{ spendLimitDurationLabel }}
              </div>
            </template>
          </CardComponent>
          <div class="name">{{ card.memo || card.hostname || "Untitled" }}</div>
          <p class="mt-2 mb-0" v-if="card.note">
            {{ card.note }}
          </p>
        </div>
        <div class="themes">
          <ul class="theme-menu" role="menu">
            <li v-for="theme in themes" :key="theme.type">
              <div
                @click="currentTheme = theme.type"
                :class="[
                  'theme-button',
                  theme.type.toLowerCase() + '-theme',
                  { active: currentTheme === theme.type },
                ]"
                :aria-selected="currentTheme === theme.type"
                role="menuitem"
                tabindex="0"
                :aria-label="`Select ${theme.name} theme`"
              >
                <img
                  v-if="theme.previewImage"
                  :src="theme.previewImage"
                  alt=""
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="sharing flex-shrink-0">
        <h5 class="title">Share this card</h5>
        <form
          class="d-flex"
          @submit.prevent="newShare"
          v-if="hasCardSharingAccess"
        >
          <BaseInput
            class="flex-grow-1 mb-0"
            type="email"
            name="email"
            placeholder="someone@email.com"
            label="Email address"
            :hide-label="true"
            required
          />
          <BaseButton
            :disabled="submitting"
            class="ml-2"
            variant="primary"
            type="submit"
          >
            Share
          </BaseButton>
        </form>
        <div class="d-flex" v-else>
          <router-link
            to="/select-plan"
            class="upgrade-badge d-flex align-items-center"
          >
            <SVGIcon icon="padlock" size="16" class="mr-2" />
            Upgrade to share cards
          </router-link>
        </div>
        <div class="disclaimer">
          <p>
            Privacy's
            <a href="https://privacy.com/terms" target="_blank">
              terms of service
            </a>
            and
            <a href="https://privacy.com/cardholder-agreement" target="_blank">
              cardholder agreement
            </a>
            apply. You are responsible for all of the authorized user’s activity
            with the card.
          </p>
          <p>
            If you are an individual, you may only share cards with a family
            member over the age of 13. If you are a business, you may only share
            cards with employees.
          </p>
        </div>
        <div class="recipients" v-if="recipients.length">
          <h5 class="title">Shared with</h5>
          <ul class="list">
            <li
              class="d-flex align-items-center"
              v-for="recipient in recipients"
              :key="recipient._id"
            >
              <div
                :class="[
                  'theme-button',
                  recipient.shareTheme.toLowerCase() + '-theme',
                ]"
                v-b-tooltip.hover.d500
                :title="`Shared with the ${getTheme(recipient.shareTheme)?.name} theme`"
              >
                <img
                  v-if="getTheme(recipient.shareTheme)?.previewImage"
                  :src="getTheme(recipient.shareTheme)?.previewImage"
                  alt=""
                />
              </div>
              {{ recipient.recipientId.email }}
              <button
                :disabled="resharing === recipient.recipientId.email"
                class="ml-auto reshare-button"
                aria-label="Resend share link"
                @click="reshare(recipient.recipientId.email)"
                v-if="hasCardSharingAccess"
              >
                <SVGIcon icon="refresh" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { sharingStore, subscriptionStore } from "@/store";
import { Card, CardTime } from "@/types/Card";
import CardComponent from "@/components/card/CardComponent.vue";
import SVGIcon from "@/components/SVGIcon.vue";
import { Toast } from "@/mixins/Toast";
import { isValidSharedSpendLimit } from "@/util";
import EditSpendLimitForm from "../EditSpendLimitForm.vue";

@Component({
  components: {
    CardComponent,
    EditSpendLimitForm,
    SVGIcon,
  },
})
export default class CardSharingModal extends Mixins(Toast) {
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) card!: Card;

  currentTheme = "DEFAULT";
  bgLoaded = false;
  submitting = false;
  resharing = "";

  getThemes() {
    sharingStore.actions.fetchThemes();
  }

  get subscription() {
    return subscriptionStore.getters.subscription;
  }

  get hasCardSharingAccess() {
    return !!this.subscription?.features.cardSharing;
  }

  get themes() {
    return sharingStore.getters.themes;
  }

  get backgroundImage() {
    return this.themes.find((theme) => theme.type === this.currentTheme)
      ?.backgroundImage;
  }

  get backgroundImageVar() {
    if (!this.backgroundImage) {
      return;
    }

    return `--shared-background: url(${this.backgroundImage})`;
  }

  get recipients() {
    return this.card.sharedWithRecipients || [];
  }

  get spendLimitDurationLabel() {
    if (this.card.spendLimitDuration === CardTime.TRANSACTION) {
      return "transaction limit";
    } else if (this.card.spendLimitDuration === CardTime.MONTHLY) {
      return "monthly limit";
    } else if (this.card.spendLimitDuration === CardTime.ANNUALLY) {
      return "yearly limit";
    }

    return "total limit";
  }

  get showSpendLimit() {
    return (
      this.hasCardSharingAccess &&
      !isValidSharedSpendLimit(this.card, this.subscription!)
    );
  }

  @Watch("backgroundImage")
  onBackgroundChange() {
    this.bgLoaded = false;

    if (!this.backgroundImage) {
      return;
    }

    const img = new Image();
    img.src = this.backgroundImage;
    img.onload = () => {
      this.bgLoaded = true;
    };
  }

  getTheme(type: string) {
    return this.themes.find((theme) => theme.type === type);
  }

  async newShare(event: Event) {
    const target = event.target as HTMLFormElement;
    this.submitting = true;

    await this.share(target.email.value, this.currentTheme);

    target.reset();
    this.submitting = false;
  }

  async reshare(email: string) {
    this.resharing = email;
    await this.share(email);
    this.resharing = "";
  }

  async share(email: string, theme?: string) {
    try {
      await sharingStore.actions.shareCard({
        email,
        cardUuid: this.card.cardUuid!,
        theme,
      });

      this.successToast("Sharing link sent");
    } catch (err: any) {
      this.errorToast(err.response?.data?.message || "An error occurred");
    }
  }

  updateCard(updatedCard: Card) {
    this.$emit("update-card", updatedCard);
  }

  closeModal() {
    this.$bvModal.hide(this.id);
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .sharing-header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;

    h5 {
      display: none;
    }
  }

  .sharing-body {
    padding: 24px !important;
  }

  .sharing-dialog {
    max-width: min-content;
  }

  .sharing-dialog.show-spend-limit {
    max-width: 500px;
  }
}

.sharing-wrapper {
  display: flex;
  gap: 40px;

  > div {
    width: 382px;
  }
}

.limit-preview {
  flex-grow: 1;
  text-align: left;
}

.theme-preview {
  padding: 64px 40px 40px 40px;
  text-align: center;
  border-radius: 24px;
  background-color: #ebebff;
  margin-bottom: 16px;
  position: relative;
  transition:
    background-color 0.2s linear,
    color 0.2s linear;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 40%;
    content: "";
    opacity: 0;
    background-size: 100%;
    background-repeat: repeat-x;
    background-position: top center;
    transition: opacity 0.2s linear;
    background-image: var(--shared-background);
    z-index: 0;
  }

  &.bg-loaded::before {
    opacity: 1;
  }
}

.name {
  font-family: $font-stack-fk-grotesk;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.64px;
}

.theme-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.theme-button {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ebebff;

  &.active {
    outline: $brand-extended-purple-2 2px solid;
  }
}

.privacy-theme {
  background-color: $brand-lime;
}

.confetti-theme {
  color: #fff;
  background-color: $brand-extended-purple-5;
}

.shared-card {
  position: relative;
}

.title {
  font-family: $font-stack-fk-grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.16px;
  margin-bottom: 24px;
}

.disclaimer {
  margin-top: 16px;
  padding-bottom: 8px;

  p {
    font-family: $font-stack-graphik;
    font-size: 12px;
    color: $foreground-subtle;
  }
}

.recipients {
  border-top: 1px solid $neutrals-2;
  padding-top: 24px;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;

  .theme-button {
    cursor: default;
  }

  li {
    font-family: $font-stack-graphik;
    margin-bottom: 24px;
    gap: 8px;
  }
}

.reshare-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.upgrade-badge {
  background-color: $foreground-border;
  border-radius: $border-radius-lg;
  color: $foreground-subtle;
  font-weight: 600;
  margin-bottom: 8px;
  padding: 8px 16px;
}

.upgrade-badge ::v-deep path {
  fill: $foreground-muted;
}
</style>
