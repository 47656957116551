import Vue from "vue";

import BaseButton from "@/components/BaseButton.vue";
import BaseCardInput from "@/components/BaseCardInput.vue";
import BaseFileUpload from "@/components/BaseFileUpload.vue";
import BaseForm from "@/components/BaseForm.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseInputGroup from "@/components/BaseInputGroup.vue";
import BaseModal from "@/components/BaseModal.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import BaseTextarea from "@/components/BaseTextarea.vue";
import SimplePage from "@/components/SimplePage.vue";

const components = {
  BaseButton,
  BaseCardInput,
  BaseFileUpload,
  BaseForm,
  BaseInput,
  BaseInputGroup,
  BaseModal,
  BaseSelect,
  BaseTextarea,
  SimplePage,
};

Object.entries(components).forEach(([name, component]) => {
  // Register component globally
  Vue.component(name, component);
});
