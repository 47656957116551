import { RouteConfig } from "vue-router";

import SharedLoginPages from "@/views/shared/SharedLoginPages.vue";
import SharedDashboardCards from "@/views/shared/SharedDashboardCards.vue";
import SharedLoginEmail from "@/views/shared/login/Email.vue";
import SharedLoginOneTimeCode from "@/views/shared/login/Code.vue";
import paths from "./paths.json";

const routes: Array<RouteConfig> = [
  {
    path: paths.sharedLoginEmail,
    component: SharedLoginPages,
    children: [
      {
        path: "",
        component: SharedLoginEmail,
        name: "shared-login-email",
        meta: {
          title: "Shared Cards - Login",
        },
      },
      {
        path: ":linkToken",
        name: "shared-login-token",
        component: SharedLoginOneTimeCode,
        meta: {
          title: "Shared Cards - Login",
        },
      },
    ],
  },
  {
    path: paths.sharedDashboard,
    name: "shared-dashboard",
    component: SharedDashboardCards,
    meta: {
      title: "Shared Cards",
    },
  },
];

export default routes;
