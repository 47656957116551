<template>
  <div class="account-section -api" id="api">
    <div class="section-heading">
      <ToggleSwitch
        size="small"
        :value="user.api.enabled"
        :on-click="onClickToggleAPI"
        label="Enable API"
        data-test="api-toggle"
      />
      <h2 class="mb-0">Enable API</h2>
      <div class="title-aside ml-auto">
        <div v-show="user.api.enabled">
          <a
            class="tab-link"
            :class="{ '-active': view === 'production' }"
            @click="setView('production')"
            >Production</a
          ><a
            class="tab-link"
            :class="{ '-active': view === 'sandbox' }"
            @click="setView('sandbox')"
            >Sandbox</a
          >
        </div>
      </div>
    </div>

    <div class="section section-sandbox" v-if="!user.api.enabled">
      <p class="description">
        Access all your live data through our API. Analyze your spend, and get
        real-time event data through webhooks.
      </p>
      <p class="description">
        <b-link href="https://privacy-com.readme.io/docs" target="_blank"
          >Read our API docs to find out more.</b-link
        >
      </p>
    </div>
    <div
      class="section section-production"
      v-if="view === 'production' && user.api.enabled"
      :class="{ '-animate': shouldAnimateView }"
    >
      <div class="aside-title">
        <a
          @click="onClickGenerateKey('production')"
          data-test="api-refresh-button"
          >Generate New Key</a
        >
      </div>
      <div class="switch-title mr-auto">Production API Key</div>
      <BaseInput
        class="input-box -monospace"
        v-model="user.api.auth.key"
        v-click-to-copy="user.api.auth.key"
        click-to-copy-text="Production API key copied to clipboard!"
        readonly
        data-test="api-form-key"
      />
      <div class="switch-title mt-4">
        <ToggleSwitch
          size="small"
          :value="user.api.webhook.enabled"
          :on-click="toggleWebhook"
          data-test="api-webhook-toggle"
          label="Enable Production Webhook"
        />
        Enable Production Webhook
      </div>
      <BaseInput
        class="input-box"
        v-model="user.api.webhook.uri"
        @click="$bvModal.show('change-webhook')"
        placeholder="https://example.com/api/endpoint"
        readonly
        data-test="api-form-webhook"
      />
    </div>
    <div
      class="section section-sandbox -theme-dark"
      v-if="view === 'sandbox' && user.apiSandbox.enabled"
      :class="{ '-animate': shouldAnimateView }"
    >
      <div class="aside-title">
        <a @click="onClickGenerateKey('sandbox')" data-test="api-refresh-button"
          >Generate New Key</a
        >
      </div>
      <div class="switch-title">Sandbox API Key</div>
      <BaseInput
        class="input-box -monospace"
        v-model="user.apiSandbox.auth.key"
        v-click-to-copy="user.apiSandbox.auth.key"
        click-to-copy-text="Sandbox API key copied to clipboard!"
        readonly
        data-test="api-form-key"
      />
      <div class="switch-title mt-4">
        <ToggleSwitch
          size="small"
          :value="user.apiSandbox.webhook.enabled"
          :on-click="toggleWebhook"
          data-test="api-webhook-toggle"
          label="Enable Sandbox Webhook"
        />
        Enable Sandbox Webhook
      </div>
      <BaseInput
        class="input-box"
        v-model="user.apiSandbox.webhook.uri"
        @click="$bvModal.show('change-webhook')"
        placeholder="https://example.com/api/endpoint"
        readonly
        data-test="api-form-webhook"
      />
    </div>

    <BaseModal id="change-webhook" static>
      <ChangeWebhook
        :fullpage="false"
        modal-id="change-webhook"
        @complete="onChangedWebhook"
        :environment="view"
      />
    </BaseModal>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Confirm } from "@/mixins/Confirm";
import { Toast } from "@/mixins/Toast";
import { User } from "@/types/User";
import { userStore } from "@/store";
import ToggleSwitch from "@/components/ToggleSwitch.vue";
import ChangeWebhook from "@/views/ChangeWebhook.vue";

const envToKeyMap = {
  production: "api",
  sandbox: "apiSandbox",
} as const;

type env = "production" | "sandbox";

@Component({
  components: {
    ChangeWebhook,
    ToggleSwitch,
  },
})
export default class AccountAPI extends Mixins(Confirm, Toast) {
  @Prop({ required: true }) user!: User;

  view: "production" | "sandbox" =
    this.$cookies.get("accountSectionAPIView") || "production";
  shouldAnimateView = false;

  onClickToggleAPI() {
    const oldState = this.user.api.enabled;
    const resultString = !oldState ? "enabl" : "disabl";
    userStore.actions
      .toggleApi()
      .then(() => {
        // If the user is enabling the API for the first time, generate initial keys
        if (!this.user.api.auth.key) {
          return Promise.all([
            this.onClickGenerateKey("production", false),
            this.onClickGenerateKey("sandbox", false),
          ]);
        }
      })
      .then(() => {
        this.successToast(`API ${resultString}ed`);
        const newAPIState = {
          api: { ...this.user.api, enabled: !oldState },
          apiSandbox: { ...this.user.apiSandbox, enabled: !oldState },
        };
        userStore.mutations.updateCurrentUser(newAPIState);
      })
      .catch(() => {
        this.errorToast(`There was a problem ${resultString}ing the API.`);
      });
  }

  onChangedWebhook() {
    const environment = this.view;
    const key = envToKeyMap[environment];
    const isEnabled = this.user[key].webhook.enabled;
    if (!isEnabled) {
      this.toggleWebhook();
    }
    this.$bvModal.hide("change-webhook");
  }

  setView(view: "production" | "sandbox") {
    this.view = view;
    this.shouldAnimateView = true;
    this.$cookies.set("accountSectionAPIView", view);
  }

  toggleWebhook() {
    const environment = this.view;
    const key = envToKeyMap[environment];
    const isEnabling = !this.user[key].webhook.enabled;
    const hasURI = this.user[key].webhook.uri?.length > 0;
    const resultString = isEnabling ? "enabl" : "disabl";

    // Private function to be called shortly
    const _toggleWebhooks = () => {
      // Update the user
      const updateUser = { [key]: { ...this.user[key] } };
      updateUser[key].webhook.enabled = isEnabling;
      userStore.mutations.updateCurrentUser(updateUser);

      userStore.actions
        .toggleWebhooks(environment)
        .then(() => {
          this.successToast(`Your webook has been ${resultString}ed.`);
        })
        .catch(() => {
          // If there was an error. Revert the UI change.
          updateUser[key].webhook.enabled = !isEnabling;
          userStore.mutations.updateCurrentUser(updateUser);
          this.errorToast(
            `There was a problem ${resultString}ing your webhooks.`
          );
        });
    };

    if (environment === "production" && !isEnabling) {
      // If the user is turning this off in a production context, make sure they double
      // confirm the decision.
      this.confirm("This will disable all transaction event alerts", {
        title: "Are you sure?",
        cancelTitle: "Cancel",
        okTitle: "Disable Webhook",
      }).then((confirmed) => {
        if (confirmed) {
          _toggleWebhooks();
        }
      });
    } else if (!hasURI && isEnabling) {
      // If enabling webhooks for the first time, prompt the user for a URI
      this.$bvModal.show("change-webhook");
    } else {
      _toggleWebhooks();
    }
  }

  onClickGenerateKey(environment: env = "production", showAlerts = true) {
    const key = envToKeyMap[environment];
    const _newKey = () => {
      const updateUser = { [key]: { ...this.user[key] } };
      return userStore.actions
        .newPublicApiKey(environment)
        .then((resp) => {
          updateUser[key].auth.key = resp.data.apiKey;
          userStore.mutations.updateCurrentUser(updateUser);
          if (showAlerts) {
            this.successToast("Your public API key has been updated.");
          }
        })
        .catch((err) => {
          if (err) {
            if (showAlerts) {
              this.errorToast("There was an error generating a new API key.");
            }
            userStore.mutations.updateCurrentUser(updateUser);
          }
        });
    };

    if (environment === "production" && showAlerts) {
      return this.confirm(
        "This action cannot be undone and will invalidate your existing API key.",
        {
          title: "Are You Sure?",
          cancelTitle: "Cancel",
          okTitle: "Rotate Production API Key",
        }
      ).then((confirmed) => {
        if (confirmed) {
          return _newKey();
        }
      });
    }
    return _newKey();
  }
}
</script>

<style lang="scss" scoped>
.tab-link {
  padding: 5px 10px;
  border-radius: $border-radius;
  user-select: none;
  text-decoration: none;
  cursor: pointer;

  &:not(.-active):hover {
    background-color: fade-out($blue, 0.95);
  }

  &.-active {
    background-color: fade-out($blue, 0.9);
  }

  & + .tab-link {
    margin-left: 10px;
  }
}

.switch-title {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: $gray-800;
  margin-bottom: 25px;
}

.aside-title {
  float: right;
  a {
    cursor: pointer;
    text-decoration: none;
  }
}

.description:last-child {
  margin-bottom: 0;
}

.toggle-switch {
  margin-right: map-get($spacers, 3);
}

.-animate {
  animation: fade-in-right $duration-long;
  animation-timing-function: $ease-out-expo;
}

.section-sandbox {
  animation-name: fade-in-left;

  .toggle-switch {
    // NOTE: This is not a great solution. Ideally the toggle-switch should be
    // more visibly distinct when used in a visually dark context.
    background-color: $gray-800;
  }
}

.account-section .section.-theme-dark {
  background-color: $color-blue-gray-dark;
  color: $gray-400;

  .switch-title {
    color: $white;
  }

  ::v-deep input {
    box-shadow: none;
    color: $white;
    &,
    &:hover {
      background-color: $gray-800;
    }
  }
}

.section .input-box {
  padding-bottom: 2rem;
  color: $gray-800;

  ::v-deep {
    input {
      cursor: pointer;
      padding-top: 0;
      padding-bottom: 0;
      background-color: white;
      border: none;
      border-radius: $border-radius;
      box-shadow: $box-shadow-small;
      transition: box-shadow $duration-short;
      height: 100%; // see PRV-1064

      &,
      &:hover {
        background-color: white;
      }
    }

    .title {
      display: none;
    }
  }
}

.-monospace ::v-deep input {
  font-family: $font-stack-ocr;
}
</style>
