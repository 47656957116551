<template>
  <div
    :class="['svg-icon', ...getClassList]"
    v-html="data"
    @click="$emit('click', $event)"
  ></div>
</template>
<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import axios from "axios";

@Component
export default class SVGIcon extends Vue {
  @Prop() icon!: string;
  @Prop() uri!: string;
  @Prop({ default: 20 }) size?: number;

  data = "";
  mounted() {
    this.fetchIcon();
  }
  @Watch("icon")
  @Watch("uri")
  fetchIcon() {
    if (!this.icon && !this.uri) return;
    const iconURI =
      this.uri || `/assets/images/icons/${this.icon}-${this.size}.svg`;
    axios
      .get(iconURI)
      .then((response) => {
        this.$set(this, "data", response.data);
      })
      .catch(() => {
        /* noop */
      });
  }
  get getClassList() {
    const classList = [];
    const sizeClass = `-size-${this.size}`;
    classList.push(sizeClass);

    return classList;
  }
}
</script>
<style lang="scss" scoped>
.svg-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  vertical-align: middle;

  &.-size-40 {
    height: 40px;
    width: 40px;
  }

  &.-size-80 {
    height: 80px;
    width: 80px;
  }
}
</style>
