<template>
  <div class="spending-graph" ref="spendingGraph">
    <HighChart :options="chartOptions" />
    <div
      class="total-spend"
      v-if="formattedTotalSpend !== null"
      :class="{ '-positive': totalSpend < 0 }"
    >
      {{ formattedTotalSpend }}
    </div>
  </div>
</template>
<script lang="ts">
import ldGet from "lodash/get";
import { Chart } from "highcharts-vue";
import { DateType, formatDate, getDate } from "@/lib/dates";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Card } from "@/types/Card";
import { Transaction } from "@/types/Transaction";

const columnColor = "rgba(51, 153, 255, 0.2)";
const columnHoverColor = "rgba(51, 153, 255, 0.5)";
const columnRefundColor = "rgba(82, 204, 122, 0.3)";

interface Column {
  x: number;
  y: number;
  color?: string;
  negative?: boolean;
}

interface Bucket {
  type: string;
  numberOf: number;
  totalDaysEstimate: number;
}

@Component({
  components: {
    HighChart: Chart,
  },
})
export default class SpendingGraph extends Vue {
  @Prop({ default: null }) currentCard!: Card | null;
  @Prop({ default: () => [] }) transactions!: Transaction[];
  @Prop({ default: null }) firstDate!: DateType | null;
  @Prop({ default: null }) lastDate!: DateType | null;
  bucket: Bucket = { type: "months", numberOf: 1, totalDaysEstimate: 30 };
  clientWidth = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartOptions: Omit<any, "series, tooltip"> = {
    chart: {
      className: "column-chart",
      type: "column",
      backgroundColor: "transparent",
      margin: [20, 4, 8, 4],
      height: 200,
    },
    title: { text: "" },
    xAxis: {
      type: "datetime",
      visible: false,
      gridLineWidth: 0,
    },
    yAxis: {
      min: 0,
      className: "y-axis",
      gridLineWidth: 0,
      labels: {
        formatter: (
          ctx: Highcharts.AxisLabelsFormatterContextObject // eslint-disable-line
        ): string => {
          return "$" + (Number(ctx.value) / 100).toLocaleString();
        },
        x: 10,
        useHTML: true,
        align: "left",
      },
      title: {
        enabled: false,
      },
      showFirstLabel: false,
      showLastLabel: false,
      tickPosition: "inside",
    },
    legend: { enabled: false },
    tooltip: {
      animation: false,
      distance: 20,
      backgroundColor: "#3399FF",
      borderRadius: 10,
      borderWidth: 0,
      useHTML: true,
      shadow: {
        color: "#202D4A",
        offsetX: 0,
        offsetY: 0,
        opacity: 0.06,
        width: 4,
      },
      shared: true,
      style: {
        color: "#FFFFFF",
        lineHeight: "20px",
      },
      outside: true,
      formatter: function (): string {
        const timestamp = Number(this.points[0].key);
        const dateStr = formatDate("ddd, MMM D", timestamp);
        return `<div class="tooltip-custom">
                    ${dateStr}<br />
                    <div class="amount">
                        ${
                          (this.points[0] && this.points[0].color) ===
                          columnRefundColor
                            ? "-"
                            : ""
                        }
                        $${(this.y / 100).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                    </div>
                </div>`;
      },
    },
    credits: { enabled: false },
    plotOptions: {
      column: {
        borderColor: "transparent",
        pointPadding: 0.0,
        groupPadding: 0.05,
        borderRadius: 4,
        shadow: false,
        minPointLength: 10,
      },
    },
    series: [
      {
        animation: { duration: 0 },
        name: "Spend",
        color: columnColor,
        states: {
          hover: {
            color: columnHoverColor,
          },
        },
        data: [],
      },
    ],
  };

  get filteredTransactions(): Transaction[] {
    let filterFirstDate = getDate().subtract(1, "months").startOf("day");
    let filterLastDate = getDate().endOf("day");

    if (this.firstDate && this.lastDate) {
      filterFirstDate = getDate(this.firstDate).startOf("day");
      filterLastDate = getDate(this.lastDate).endOf("day");
    }

    return this.transactions.filter((t) => {
      const authorized = getDate(t.dateAuthorized || t.dateSettled);

      return (
        (t.dateAuthorized || t.dateSettled) &&
        filterFirstDate.isSameOrBefore(authorized) &&
        filterLastDate.isSameOrAfter(authorized) &&
        (!this.currentCard ||
          String(t.cardID) ===
            String(ldGet<any, string, any>(this, "currentCard.cardID", null)))
      );
    });
  }

  get totalSpend() {
    return this.filteredTransactions.reduce((acc, t) => {
      return acc + parseFloat(String(t.amount));
    }, 0);
  }

  get formattedTotalSpend() {
    return (Math.abs(this.totalSpend) / 100).toLocaleString("en", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.setChartData();
  }

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    const el = this.$refs.spendingGraph as HTMLElement;
    const bounding = el.getBoundingClientRect();
    this.clientWidth = bounding.width;
  }

  // Based on the width of the window and number of days, return a 'bucket' size for the columns
  // This is to ensure the columns are roughly ~25px or whatever length is passed in
  ratioToRange(ratio: number): Bucket {
    const buckets = [
      { type: "days", numberOf: 1, totalDaysEstimate: 1 },
      { type: "days", numberOf: 2, totalDaysEstimate: 2 },
      { type: "weeks", numberOf: 1, totalDaysEstimate: 7 },
      { type: "weeks", numberOf: 2, totalDaysEstimate: 14 },
      { type: "months", numberOf: 1, totalDaysEstimate: 30 },
      { type: "months", numberOf: 2, totalDaysEstimate: 61 },
      { type: "months", numberOf: 3, totalDaysEstimate: 91 },
      { type: "months", numberOf: 4, totalDaysEstimate: 122 },
      { type: "months", numberOf: 6, totalDaysEstimate: 183 },
      { type: "years", numberOf: 1, totalDaysEstimate: 365 },
    ];

    let bestDiff = Infinity;
    let returnRange = buckets[0];

    // Return the bucket where the estimated column width is
    // the closest to column width (default 30)
    buckets.forEach((bucket) => {
      // Calculates how wide a column would be in pixels based on the bucket size and subtract ideal width
      const diff = Math.abs(bucket.totalDaysEstimate * ratio - 30);
      if (diff < bestDiff) {
        returnRange = bucket;
        bestDiff = diff;
      }
    });

    return returnRange;
  }

  @Watch("currentCard")
  @Watch("firstDate")
  @Watch("lastDate")
  setChartData() {
    let filterFirstDate = getDate().subtract(1, "months").startOf("day");
    let filterLastDate = getDate().endOf("day");

    if (this.firstDate && this.lastDate) {
      filterFirstDate = getDate(this.firstDate).startOf("day");
      filterLastDate = getDate(this.lastDate).endOf("day");
    }

    const chartWidth = this.clientWidth;
    const rangeInDays = filterLastDate.diff(filterFirstDate, "days");
    const bucket = this.ratioToRange(chartWidth / rangeInDays);
    this.bucket = bucket;

    // Temporarily calculate spending sums for every 1 unit of bucket type
    // Ex. If bucket = { type: months, numberOf: 4 }, just calculate spending by each month
    const aggregatedTransactions: {
      [key: number]: number | null;
    } = this.filteredTransactions.reduce(
      (acc: { [key: number]: number | null }, t: Transaction) => {
        const key = getDate(t.dateAuthorized || t.dateSettled)
          .startOf(bucket.type as any)
          .valueOf();
        acc[key as keyof typeof acc] =
          (acc[key] || 0) + parseFloat(String(t.amount));
        return acc;
      },
      {}
    );

    const allKeys = [];

    for (
      let itr = filterFirstDate;
      filterLastDate.isAfter(itr);
      itr = itr.add(bucket.numberOf, bucket.type as any)
    ) {
      const newKey = getDate(itr)
        .startOf(bucket.type as any)
        .valueOf();
      let total = aggregatedTransactions[newKey] || 0;
      // Sum up singular bucket units to the desired size
      // Ex. if bucket = { type: months, numberOf: 4 }, add up every 4 months to fit bucket
      for (let bucketSize = 1; bucketSize < bucket.numberOf; bucketSize += 1) {
        const buff = getDate(newKey)
          .add(bucketSize, bucket.type as any)
          .valueOf();
        total += aggregatedTransactions[buff] || 0;
        aggregatedTransactions[buff] = null;
      }

      aggregatedTransactions[newKey] = total;
      allKeys.push(newKey.valueOf());
    }

    const chartData = allKeys.map((key) => {
      const amount: number | null = aggregatedTransactions[key];
      if (amount && amount < 0) {
        return {
          x: key,
          y: amount ? Math.abs(amount) : 0,
          color: columnRefundColor,
          negative: true,
        };
      }
      return { x: key, y: amount || 0 };
    });

    this.chartOptions.series[0].data = chartData;

    // When there is no data, ensures the graph isn't vertically centered
    this.chartOptions.yAxis = Object.assign(
      {},
      this.chartOptions.yAxis,
      chartData.every((col: Column) => col.y === 0)
        ? { max: 10, visible: false }
        : { max: null, visible: true }
    );

    this.chartOptions = Object.assign({}, this.chartOptions);
  }
}
</script>
<style lang="scss" scoped>
$color-pastel-blue-darker: #586b95;

.spending-graph {
  position: relative;
  height: 100%;
  max-height: 700px;
  width: 100%;
  background: rgba(51, 153, 244, 0.05);
  border-radius: 6px;

  > highchart {
    display: block;
    height: 100%;
    width: 100%;
  }

  .column-chart {
    font-family: $font-stack-lato;
  }

  .y-axis {
    span {
      font-family: $font-stack-lato !important;
      color: $color-pastel-blue-darker !important;
      font-size: 12px !important;
      font-weight: bold !important;
    }
  }

  .total-spend {
    position: absolute;
    top: 14px;
    right: 20px;
    font-family: $font-stack-lato;
    color: $color-pastel-blue-darker;
    font-size: 16px;
    font-weight: bold;

    &::before {
      content: "$";
    }

    &.-positive {
      color: rgba(82, 204, 122, 0.9) !important;
    }
  }
}

.tooltip-custom {
  font-family: $font-stack-lato;
  display: block;
  padding: 4px;
  text-align: center;

  > .amount {
    font-weight: bold;
  }
}

.highcharts-tooltip-container {
  z-index: 1;
}
</style>
