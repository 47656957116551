<template>
  <BaseModal :id="modalId">
    <div
      class="manual-payments-explainer"
      data-test="manual-payments-explainer"
    >
      <h2 class="title">{{ title }}</h2>
      <p>
        The easiest and most convenient way to use Privacy is with automatic
        payments.
      </p>
      <p>
        To apply for manual payments, please submit in writing your full name,
        the email address you signed up with, your phone number, along with an
        explicit request to enroll in manual payments.
      </p>
      <p>
        Mail this request to:
        <br />
        Lithic, Inc. (DBA Privacy.com)
        <br />
        228 Park Ave S
        <br />
        PMB 57488
        <br />
        New York, New York 10003-1502 US
      </p>
      <p>
        You won't be able to create cards or make purchases until the request
        has been submitted, reviewed by our team, and you've provided ID
        verification.
      </p>
      <BaseButton
        variant="light"
        size="lg"
        @click="onClickManualPayments"
        data-test="continue-with-manual"
      >
        Continue with manual payments
      </BaseButton>
      <BaseButton
        variant="primary"
        size="lg"
        @click="onClickAutomaticPayments"
        data-test="use-automatic"
      >
        Use Automatic Payments
      </BaseButton>
    </div>
  </BaseModal>
</template>
<script lang="ts">
import { Component, Prop, Mixins } from "vue-property-decorator";
import { Toast } from "@/mixins/Toast";

@Component
export default class ManualPaymentsExplainer extends Mixins(Toast) {
  @Prop({ default: "manual-payments-explainer" }) modalId!: string;
  @Prop({ default: "Automatic Payments" }) title!: string;

  onClickManualPayments() {
    this.$piwik.trackClick({
      name: "Onboarding Manual Payments CTA",
    });

    this.$emit("manual-payments");
  }

  onClickAutomaticPayments() {
    this.$piwik.trackClick({
      name: "Onboarding Automatic Payments CTA",
    });

    this.$emit("automatic-payments");
  }
}
</script>
<style lang="scss" scoped>
.manual-payments-explainer {
  padding: 2.5rem;
  display: grid;
  gap: 20px;

  > h2 {
    font-family: $font-stack-wes-fy;
    text-align: center;
    margin-top: -20px;
  }

  > p {
    margin-bottom: 0;
  }

  > div {
    white-space: pre-line;
  }
}
</style>
