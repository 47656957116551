import Vue from "vue";
import { Component } from "vue-property-decorator";
import { measureLatency, math, ice } from "@/util";
import { userStore } from "@/store";
import Fingerprint from "@fingerprintjs/fingerprintjs";

declare global {
  interface Window {
    rtl?: number;
  }
}

@Component
export class Introspection extends Vue {
  fingerprintBrowser() {
    // Initialize the agent at application startup.
    const fpPromise = Fingerprint.load();

    // Get the visitor identifier when you need it.
    fpPromise
      .then((fp) => fp.get())
      .then((result) => {
        userStore.actions.fingerprint({
          hash: result.visitorId,
          properties: result.components,
        });
      });
  }

  // ICE (Interactive_Connectivity_Establishment) is a technique used to faciliate p2p connections when one or more
  // sides is behind a NAT (aka router). We use it to coerce fraudsters to leak their real IPs behind proxies/VPNs
  // More info: https://en.wikipedia.org/wiki/Interactive_Connectivity_Establishment
  iceProbe() {
    try {
      ice((candidates: string[]) => {
        if (candidates && candidates.length > 0) {
          userStore.actions.ice({ candidates });
        }
      });
    } catch (e) {
      // do nothing
    }
  }

  measureRTL() {
    const rtlMeasurements: number[] = [];
    const rtlPromise = setInterval(() => {
      measureLatency((err: string | null, rtl?: number) => {
        if (err) {
          // Not all browsers implement the timing API we're using to measure latency, so stop checking.
          // Stick an empty client rtl measurement in the root scope so it will go up with the next API
          // request regardless, to kick off the IP analysis process.
          window.rtl = 0;
          return clearInterval(rtlPromise);
        }

        if (rtl) {
          rtlMeasurements.push(rtl);
        }

        const numMeasurements = rtlMeasurements.length;
        if (
          (numMeasurements >= 3 &&
            math.standardDeviation(rtlMeasurements) <= 10) ||
          numMeasurements >= 10
        ) {
          // Latency is pretty steady, so we're confident that network jitter isnt mucking with our results;
          // or we've made at least 10 measurements so stop and record the average (we don't want to create
          // too much traffic for this test)
          clearInterval(rtlPromise);

          // Now stick this on the window object, our app's axios interceptor will notice it and ship it up
          // with the next API request in the headers.  I've chosen to do it this way because I don't want
          // to be *totally* explicit about how we're measuring latency on the client, but its a necessary
          // evil to combat the fraud via proxies/botnets/VPNs :\
          window.rtl = math.mean(rtlMeasurements);
        }
      });
    }, 1500);
  }

  runAllIntrospectionChecks() {
    if (!userStore.getters.currentUser?.blockExt) {
      // **NOTE: Staging is primarily used by support agents, so don't run any fraud checks on
      //         our own support team.
      this.fingerprintBrowser();
      this.iceProbe();
      this.measureRTL();
    }
  }
}
