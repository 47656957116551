<template>
  <KybVerificationContainer>
    <BaseForm
      @submit="submit"
      :class="['business-use', 'pt-0', { '-loading': waiting }]"
    >
      <h3 class="heading mb-4">
        Please confirm how you plan to use Privacy for your business
      </h3>
      <b-alert variant="danger" :show="errorMessage.length > 0">
        {{ errorMessage }}
      </b-alert>
      <p class="mb-2">Tell us about your business</p>
      <BaseTextarea
        name="businessServices"
        placeholder="(i.e. what goods or services do you offer?) "
        v-model="businessServices"
        :state="errorType.includes('businessServices') ? false : null"
        maxlength="500"
        rows="6"
        class="pt-0"
      />
      <p class="mb-2 mt-3">
        Tell us how you plan to use Privacy for your business
      </p>
      <BaseTextarea
        name="useCase"
        placeholder="(i.e. what types of things will you be buying with your Privacy Cards)"
        v-model="useCase"
        :state="errorType.includes('usecase') ? false : null"
        maxlength="500"
        rows="6"
      />
      <div class="divider mt-3 mb-2" />
      <BaseButton
        variant="primary"
        type="submit"
        :loading="waiting"
        class="submit-button"
      >
        Continue
      </BaseButton>
    </BaseForm>
  </KybVerificationContainer>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { organizationStore, userStore } from "@/store";
import KybVerificationContainer from "./KybVerificationContainer.vue";

@Component({
  components: {
    KybVerificationContainer,
  },
})
export default class BusinessUse extends Vue {
  get kybUser() {
    return userStore.getters.currentUser!.kybVerificationUser;
  }

  get piwikPrefix() {
    return this.kybUser ? "Verification" : "Onboarding";
  }

  nextStep() {
    this.$router.push({ name: "kybInReview" });
  }

  async created() {
    await userStore.actions.getCurrentUser();
    this.$piwik.trackEvent("Modal", "View", {
      name: `${this.piwikPrefix} Use Case`,
    });

    const currentStep = "kybBusinessUsecase";
    if (this.kybUser) {
      userStore.actions.updateVerificationStep(currentStep);
    } else {
      userStore.actions.updateUser({ kybCurrentStep: currentStep });
    }
  }

  @Prop({ default: false }) fullpage!: boolean;
  waiting = false;
  businessServices = "";
  useCase = "";
  errorMessage = "";
  errorType = "";

  get hasEnteredData(): boolean {
    return this.useCase !== "" && this.businessServices !== "";
  }

  getErrorType(): string {
    let errorType = "";
    if (this.useCase.length < 5) {
      errorType += "usecase";
    }
    if (this.businessServices.length < 5) {
      errorType += "businessServices";
    }
    return errorType;
  }

  submit(): void {
    if (this.useCase.length >= 5 && this.businessServices.length >= 5) {
      this.errorMessage = "";
      this.errorType = "";
    } else {
      this.errorMessage = "Please provide more detail";
      this.errorType = this.getErrorType();
      return;
    }

    this.$piwik.trackEvent("Modal", "Click", {
      name: `${this.piwikPrefix} Use Case CTA`,
    });

    this.waiting = true;
    organizationStore.actions
      .useCase({
        useCase: this.useCase,
        businessServices: this.businessServices,
      })
      .then(() => {
        this.nextStep();
      })
      .catch((err) => {
        this.errorMessage = err.response?.data?.message || "There was an error";
        this.errorType = err.response?.data?.type;
      })
      .finally(() => {
        this.waiting = false;
      });
  }
}
</script>

<style lang="scss" scoped>
.submit-button {
  margin-top: 1rem;
}
::v-deep .field {
  height: 100px !important;
}
::v-deep textarea {
  padding-top: 10px !important;
}
</style>
