<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :class="'ssn-last-4'"
      @submit="onSubmit"
      data-test="ssn-last-4-form"
    >
      <ProgressTracker
        v-if="showProgressTracker"
        :currentStep="3"
        :totalSteps="5"
      ></ProgressTracker>
      <h1>SSN Verification (Last 4 Digits)</h1>
      <CreditScoreExplainer></CreditScoreExplainer>
      <b-alert v-if="errorMessage" variant="danger" show>
        {{ errorMessage }}
      </b-alert>
      <BaseInput
        label="Social Security (Last 4)"
        placeholder="0000"
        v-model="ssnLast4"
        maxlength="4"
        :state="errorMessage ? false : null"
        :disabled="loading"
        data-test="input-ssn-last-4"
      >
        <SVGIcon class="padlock" icon="padlock" v-if="!errorMessage" />
      </BaseInput>
      <p class="secure">
        Your information is encrypted and never shared. Learn more about how we
        use and protect your personal data by reading our
        <b-link href="https://privacy.com/privacy-policy" target="_blank"
          >Privacy Policy
        </b-link>
        and
        <b-link href="https://privacy.com/security" target="_blank"
          >Security</b-link
        >
        pages.
      </p>
      <p class="privacy">
        By proceeding, I agree to the
        <b-link href="https://privacy.com/privacy-policy" target="_blank"
          >Privacy Policy</b-link
        >
      </p>
      <BaseButton
        type="submit"
        variant="primary"
        size="lg"
        :disabled="loading"
        v-if="!success"
        data-test="ssn-last-4-submit"
        @click="trackClick"
      >
        Next
      </BaseButton>
      <BaseButton
        type="submit"
        variant="success"
        size="lg"
        :disabled="true"
        v-else
      >
        Saved!&nbsp;<span v-if="fullpage">Redirecting...</span>
      </BaseButton>
      <OnboardingFooterActions
        :allowChangeAccountType="true"
        :showFinishLater="isRemediatingKYC"
        @footer-action="onFooterAction"
      />
    </BaseForm>
  </SimplePage>
</template>
<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import CreditScoreExplainer from "@/components/CreditScoreExplainer.vue";
import ProgressTracker from "@/components/ProgressTracker.vue";
import SVGIcon from "@/components/SVGIcon.vue";
import OnboardingFooterActions from "@/components/OnboardingFooterActions.vue";
import { eventStore, userStore } from "@/store";
import { EVENTS } from "@/types/Event";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component({
  components: {
    CreditScoreExplainer,
    ProgressTracker,
    SVGIcon,
    OnboardingFooterActions,
  },
})
export default class SignupSSNLastFour extends Vue {
  @Prop({ default: false }) fullpage!: boolean;
  @Prop({ default: false }) showProgressTracker!: boolean;
  ssnLast4 = "";
  errorMessage = "";
  loading = false;
  success = false;

  get isRemediatingKYC(): boolean {
    return !!userStore.getters.currentUser?.isRemediatingKYC;
  }

  mounted() {
    const user = userStore.getters.currentUser;
    this.ssnLast4 = user?.ssnLast4 || "";
  }

  @Watch("ssnlast4")
  resetErrorState() {
    this.errorMessage = "";
  }

  created() {
    eventStore.actions.record({
      name: EVENTS.MODAL.VIEWED,
      data: {
        modalName: "SSN Last 4 Screen Viewed",
      },
    });
  }

  trackClick() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.SSN_LAST_FOUR)} Primary CTA`,
    });
  }

  onSubmit() {
    this.resetErrorState();

    const { ssnLast4 } = this;

    if (!ssnLast4 || !/^[0-9]{4}$/.test(ssnLast4)) {
      this.errorMessage = "Please enter the last 4 digits of your SSN";
      return;
    }

    this.loading = true;
    userStore.actions
      .saveSsnLastFour({ ssnLast4 })
      .then(() => {
        userStore.mutations.updateCurrentUser({ isEditingDetails: false });
        this.loading = false;
        this.$emit("complete-step");
      })
      .catch(({ response }) => {
        this.loading = false;
        this.errorMessage =
          response?.data?.message ||
          "There was an internal server error, please contact support@privacy.com";
      });
  }

  onFooterAction(nextScreen: OnboardingSteps): void {
    this.$emit("complete-step", nextScreen);
  }
}
</script>
<style lang="scss" scoped>
.ssn-last-4 {
  h1 {
    all: unset;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    margin-bottom: 15px;
    line-height: 1.4;
    color: $gray-800;
  }

  p {
    font-size: 14px;
    color: $gray-600;
    text-align: center;
    padding: 0 10px;

    > a {
      font-weight: bold;
    }
  }

  .btn {
    margin-top: 1em;
  }

  .secure {
    margin-top: 1em;
  }

  .secure,
  .privacy {
    font-size: 12px;
  }

  .privacy {
    color: $color-foreground;
  }

  ::v-deep .padlock {
    position: absolute;
    right: 20px;
    bottom: 30px;
  }

  ::v-deep .credit-score-explainer {
    padding: 1em 20px;

    > .text {
      padding-right: 10px;
    }
  }
}
</style>
