<template>
  <div :class="{ 'simple-page': fullpage }" v-bind="$attrs">
    <PrivacyLogo
      v-if="fullpage"
      href="/"
      class="privacy-logo"
      aria-label="Go to Home"
    />
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import PrivacyLogo from "./PrivacyLogo.vue";

@Component({
  components: {
    PrivacyLogo,
  },
})
export default class SimplePage extends Vue {
  @Prop({ default: true }) fullpage?: boolean;
}
</script>
<style lang="scss" scoped>
.simple-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: auto;
  padding: 1.25rem 0;
}

.privacy-logo {
  margin: 1.25rem auto 3.75rem;
}
</style>
