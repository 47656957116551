import axios from "axios";

import { MerchantCategory } from "@/types/MerchantCategory";
import { ActionHandler, MutationHandler } from "./vuex-typex";
import { RootState, storeBuilder } from "./storeBuilder";

type MerchantCategoriesState = {
  all: MerchantCategory[];
  defaults: MerchantCategory[];
  others: MerchantCategory[];
};

const builder = storeBuilder.module<MerchantCategoriesState>(
  "merchantCategories",
  {
    all: [],
    defaults: [],
    others: [],
  }
);

type MerchantCategoriesAction<Payload = void, Type = void> = ActionHandler<
  MerchantCategoriesState,
  RootState,
  any,
  Payload,
  Type
>;
type MerchantCategoriesMutation<Payload = void> = MutationHandler<
  MerchantCategoriesState,
  Payload
>;

const base = "/api/v2/merchantCategories";

const setMerchantCategories: MerchantCategoriesMutation<MerchantCategory[]> = (
  state,
  categories
) => {
  if (!categories) {
    return;
  }
  state.all = categories;
  state.defaults = categories.filter((c) => c.isPrimary);
  state.others = categories.filter((c) => !c.isPrimary);
};

export const mutations = {
  setMerchantCategories: builder.commit(setMerchantCategories),
};

const getAll = builder.read((state) => state.all, "all");
const getDefaults = builder.read((state) => state.defaults, "defaults");
const getOthers = builder.read((state) => state.others, "others");

export const getters = {
  get all() {
    return getAll();
  },
  get defaults() {
    return getDefaults();
  },
  get others() {
    return getOthers();
  },
};

const getMerchantCategories: MerchantCategoriesAction<
  void,
  MerchantCategory[]
> = () => {
  return axios.get(base).then((resp) => {
    mutations.setMerchantCategories(resp.data.data);
    return resp.data.data;
  });
};

export const actions = {
  getMerchantCategories: builder.dispatch(getMerchantCategories),
};
