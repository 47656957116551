<template>
  <div class="credit-score-explainer">
    <span class="text">{{ displayText }}</span>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class CreditScoreExplainer extends Vue {
  @Prop({
    default:
      "Don't worry, this isn't a credit check and won't affect your credit score.",
  })
  displayText!: string;
}
</script>
<style lang="scss" scoped>
.credit-score-explainer {
  background-color: fade-out($color-dark-green, 0.9);
  border-radius: $radius-large;
  color: $color-dark-green;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1em 24px;
  margin-bottom: 1em;

  .text {
    display: block;
    text-align: left;
    font-weight: bold;
    padding-right: 50px;
  }

  ::v-deep path {
    fill: $color-dark-green;
  }
}
</style>
