<template>
  <div class="progress-tracker">
    <div class="inner" :style="'width: ' + percent + '%'"></div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ProgressTracker extends Vue {
  @Prop({ required: true }) currentStep!: number;
  @Prop({ required: true }) totalSteps!: number;

  get percent() {
    return (this.currentStep / this.totalSteps) * 100;
  }
}
</script>

<style lang="scss" scoped>
.progress-tracker {
  height: 10px;
  width: 100%;
  max-width: 300px;
  display: flex;
  background-color: $gray-100;
  border-radius: 5px;
  margin: 0 auto;
  margin-bottom: 1.25rem;

  > .inner {
    border-radius: inherit;
    background-color: $color-green;
  }
}
</style>
