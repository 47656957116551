<template>
  <div>
    <section class="tfa">
      <header class="section-heading">
        <h2>Two-Factor Authentication</h2>
      </header>
      <div class="section">
        <p>
          To protect your account we always require 2FA, but you can choose how
          you want to receive your 2FA codes.
        </p>
        <div>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="tfa-preferences"
              v-model="tfaPreference"
              :aria-describedby="ariaDescribedby"
              name="tfa-preference"
              @change="selectTfaPreference"
            >
              <b-form-radio id="tfa-pref-email" value="email" class="mb-1">
                Email
              </b-form-radio>
              <div id="tfa-sms">
                <b-form-radio
                  :disabled="!phoneVerified"
                  value="sms"
                  class="mb-1"
                  id="tfa-pref-sms"
                >
                  SMS
                </b-form-radio>
                <b-tooltip
                  :disabled="phoneVerified"
                  target="tfa-sms"
                  delay="400"
                  placement="left"
                >
                  Verify your phone number to enable SMS 2FA.
                </b-tooltip>
              </div>
              <div id="tfa-authenticator">
                <b-form-radio
                  :disabled="!phoneVerified"
                  value="authenticator"
                  id="tfa-pref-authenticator"
                >
                  Authentication App
                </b-form-radio>
                <b-tooltip
                  :disabled="phoneVerified"
                  target="tfa-authenticator"
                  delay="400"
                  placement="left"
                >
                  Verify your phone number to enable authentication app 2FA.
                </b-tooltip>
              </div>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
    </section>
    <BaseModal id="enable-tfa-modal" @hide="setUserPreference">
      <TFASetupForm :fullpage="false" modal-id="enable-tfa-modal" />
    </BaseModal>
  </div>
</template>
<script lang="ts">
import { Component, Mixins, Watch } from "vue-property-decorator";
import { userStore } from "@/store";
import { TwoFactorPreferences, User } from "@/types/User";
import { Toast } from "@/mixins/Toast";
import TFASetupForm from "@/views/TFASetup.vue";

@Component({
  components: {
    TFASetupForm,
  },
})
export default class AccountAuth extends Mixins(Toast) {
  loadingTfa = false;
  tfaPreference: TwoFactorPreferences | null = TwoFactorPreferences.EMAIL;

  get user(): User {
    return userStore.getters.currentUser || ({} as User);
  }

  get phoneVerified(): boolean {
    return !!this.user.phoneNumberVerified;
  }

  @Watch("user.tfaPreference", { immediate: true })
  onTfaPreferenceChange(value: TwoFactorPreferences) {
    this.tfaPreference = value || TwoFactorPreferences.EMAIL;
  }

  async selectTfaPreference(value: TwoFactorPreferences) {
    if (value === TwoFactorPreferences.AUTHENTICATOR) {
      this.$bvModal.show("enable-tfa-modal");
      return;
    }

    try {
      await userStore.actions.updateTfaPreference(value);
      this.successToast("Your 2FA settings have been updated.");
    } catch (error) {
      this.setUserPreference();

      this.errorToast(
        (error as any)?.response?.data?.message ||
          "There was a problem saving your 2FA settings."
      );
    }
  }

  setUserPreference() {
    this.onTfaPreferenceChange(this.user.tfaPreference);
  }
}
</script>
<style lang="scss" scoped>
.tfa ::v-deep label {
  color: $gray-800;
  font-weight: bold;
}

.authenticator {
  padding: 0 20px;
  display: flex;
  align-items: center;

  .label {
    font-size: 14px;
    color: $gray-800;
    margin-left: 10px;
  }
}
</style>
