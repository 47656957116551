import { RouteConfig } from "vue-router";

import Reset from "@/views/Reset.vue";
import Signup from "@/views/signup/Signup.vue";
import EmailConfirm from "@/views/email-confirm/EmailConfirm.vue";
import EmailConfirmError from "@/views/email-confirm/EmailConfirmError.vue";
import Logout from "@/views/Logout.vue";
import ExtensionInstall from "@/components/ExtensionInstall.vue";
import paths from "./paths.json";

const routes: Array<RouteConfig> = [
  {
    path: paths.reset,
    name: "reset",
    component: Reset,
    meta: {
      title: "Password Reset",
      robotsIndexValue: "noindex",
    },
  },
  {
    path: paths.signup,
    name: "signup",
    component: Signup,
    meta: {
      title: "Signup",
    },
  },
  {
    path: paths.emailConfirm,
    name: "email-confirm",
    component: EmailConfirm,
    meta: {
      title: "Confirm your email address",
      authenticate: true,
    },
  },
  {
    path: paths.extensionInstall,
    name: "extension-install",
    component: ExtensionInstall,
    meta: {
      title: "Install the browser extension",
      authenticate: true,
    },
    props: {
      fullpage: true,
    },
  },
  {
    path: paths.confirmError,
    name: "confirm-error",
    component: EmailConfirmError,
    meta: {
      title: "Broken Confirmation Link",
    },
  },
  {
    path: paths.logout,
    name: "logout",
    component: Logout,
    meta: {
      title: "Logout",
    },
  },
];

export default routes;
