<template>
  <SimplePage :fullpage="fullpage" id="additional-verification">
    <BaseForm :fullpage="fullpage" data-test="additional-verification">
      <h3>Pending Review</h3>
      <p class="blurb">
        Hello! Unfortunately we weren’t able to verify your identity based on
        the info you provided. We’ll need a bit more time to have a member of
        our team review your account.
      </p>
      <p class="blurb">
        This step usually takes less than 48 hours. We’ll be in touch soon!
      </p>
      <BaseButton
        size="lg"
        variant="primary"
        block
        @click="handleClick"
        data-test="got-it"
        aria-label="confirm"
      >
        Got it!
      </BaseButton>
    </BaseForm>
  </SimplePage>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import bowser from "bowser";
import { OnboardingSteps, onboardingStepName } from "@/types/Onboarding";

@Component
export default class AdditionalVerification extends Vue {
  @Prop({ default: false }) fullpage!: boolean;
  browser = bowser;
  loading = true;

  handleClick() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.ADDITIONAL_VERIFICATION)} Primary CTA`,
    });

    this.$emit("complete-step");
  }
}
</script>
<style lang="scss" scoped>
#additional-verification {
  form {
    align-items: center;
  }
}
</style>
