<template>
  <div class="pagination-container">
    <div class="pagination-controls" data-test="pagination-controls">
      <b-pagination
        v-bind="$attrs"
        v-on="$listeners"
        class="hide-first-last-li"
      ></b-pagination>
      <ul class="pagination">
        <li class="page-item">
          <b-button
            variant="outline"
            data-test="previous-page-button"
            class="pagination-button page-link"
            @click="goToPreviousPage"
            :disabled="disablePreviousButton"
          >
            <SVGIcon icon="arrow-left" />
          </b-button>
        </li>
        <li class="page-item">
          <b-button
            variant="outline"
            data-test="next-page-button"
            class="pagination-button page-link"
            @click="goToNextPage"
            :disabled="disableNextButton"
          >
            <SVGIcon icon="arrow-right" />
          </b-button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import SVGIcon from "@/components/SVGIcon.vue";

@Component({
  components: {
    SVGIcon,
  },
})
export default class PrivacyStyledPagination extends Vue {
  goToNextPage() {
    this.$emit("input", parseInt(this.$attrs.value) + 1);
  }

  goToPreviousPage() {
    this.$emit("input", parseInt(this.$attrs.value) - 1);
  }

  get disablePreviousButton() {
    return parseInt(this.$attrs.value) === 1;
  }

  get disableNextButton() {
    return (
      parseInt(this.$attrs.value) ===
      Math.ceil(
        parseInt(this.$attrs["total-rows"]) / parseInt(this.$attrs["per-page"])
      )
    );
  }
}
</script>
<style lang="scss" scoped>
.pagination-container {
  padding: 0 20px;
  width: 1080px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
}
.pagination,
.pagination-controls {
  display: flex;
  flex-direction: row;
}
::v-deep .pagination li.page-item .page-link {
  border-color: white;
  color: $gray-800;
  &:hover {
    border-radius: 10px;
  }
  &:focus {
    box-shadow: none;
  }
}

::v-deep .pagination li.active .page-link {
  color: white;
  border-radius: 10px;
}

::v-deep ul.hide-first-last-li {
  li:first-child,
  li:last-child {
    display: none;
  }
}

::v-deep li.page-item .pagination-button {
  background-color: none !important;
  border: none;
  color: $gray-800;
  font-weight: bold;
  &:focus {
    box-shadow: none;
  }
}
::v-deep li.page-item .page-link:disabled {
  color: $gray-400;
}
</style>
