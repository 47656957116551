import Vue from "vue";
import bowser from "bowser";
import { Component } from "vue-property-decorator";
import { EVENTS } from "@/types/Event";
import { eventStore, userStore } from "@/store";

enum INSTALL_RESULT {
  SUCCESS = 0,
  FAIL = 1,
  UNKNOWN = 2,
}

declare global {
  interface Window {
    hasExtension?: boolean;
  }
}

@Component
export class Extension extends Vue {
  CHROME_STORE_LINK =
    "https://chrome.google.com/webstore/detail/pay-by-privacycom/hmgpakheknboplhmlicfkkgjipfabmhp";
  FIREFOX_STORE_LINK =
    "https://addons.mozilla.org/en-US/firefox/addon/pay-by-privacy/";
  SAFARI_STORE_LINK =
    "https://apps.apple.com/us/app/privacy-for-safari/id6449850851";
  EDGE_STORE_LINK =
    "https://microsoftedge.microsoft.com/addons/detail/ilepgekjgphnboopkgdhoaiobccoogcm";

  // Bowser does not correctly detect Edge browsers so we need to detect it independently
  isEdge = window.navigator.userAgent.includes("Edg/");

  //  bowser.chrome is set in Edge so we need to also use our own isEdge check
  isChrome = bowser.chrome && !this.isEdge;
  isFirefox = bowser.firefox;
  isSafari = bowser.safari;

  isExtensionInstalled() {
    return (
      !!document.body.getAttribute("data-extension-installed") ||
      userStore.getters.hasExtension
    );
  }

  get browserName() {
    if (this.isSafari) {
      return "Safari";
    } else if (this.isEdge) {
      return "Edge";
    } else if (this.isChrome) {
      return "Chrome";
    } else if (this.isFirefox) {
      return "Firefox";
    }
    return "";
  }

  get extensionExistsForBrowser() {
    return this.isEdge || this.isChrome || this.isFirefox || this.isSafari;
  }

  browserIcon(size: string) {
    const browser = this.browserName.replaceAll(" ", "").toLowerCase();
    return `/assets/images/icons/browsers/${browser}-${size}@2x.png`;
  }

  canInstallExtension() {
    return (
      !bowser.mobile &&
      !bowser.tablet &&
      this.extensionExistsForBrowser &&
      !this.isExtensionInstalled()
    );
  }

  openStoreLink() {
    return new Promise((resolve) => {
      let link;
      if (this.isSafari) {
        link = this.SAFARI_STORE_LINK;
      } else if (this.isEdge) {
        link = this.EDGE_STORE_LINK;
      } else if (this.isChrome) {
        link = this.CHROME_STORE_LINK;
      } else if (this.isFirefox) {
        link = this.FIREFOX_STORE_LINK;
      }
      window.open(link, "_blank");
      resolve(null);
    });
  }

  install(referrer = "") {
    return new Promise((res, rej) => {
      const callbackWithFallback = (result: INSTALL_RESULT) => {
        try {
          res(result);
          this.openStoreLink();
        } catch (e) {
          rej(e);
        }
      };

      this._recordInstallation(referrer);
      callbackWithFallback(INSTALL_RESULT.SUCCESS);
    });
  }

  _recordInstallation(referrer = "") {
    if (Vue.$cookies.get("token")) {
      userStore.actions.recordInstall().then((res) => {
        if (res && res.data && res?.data?.token) {
          // We've successfully installed the extension but the page was loaded before it was installed
          // (so there is no content script listening for the 'extension::login' event). Create an
          // invisible iframe which is a whole new document which will load the content script so we can pass
          // it the extension token so the user is automatically logged in.
          const iframe = document.createElement("iframe");
          iframe.setAttribute("id", "_pwp_extension_bridge");
          iframe.setAttribute(
            "src",
            window.location.protocol + "//" + window.location.host
          );
          iframe.style.display = "none";
          iframe.addEventListener("load", () => {
            window.postMessage(
              {
                invoke: "extension::login",
                token: res.data.token,
              },
              window.location.protocol + "//" + window.location.host
            );

            // Now clean up after ourselves
            setTimeout(function () {
              iframe.remove();
            }, 100);
          });

          document.body.append(iframe);
        }
      });
    }

    // window.hasExtension is set in order to detect if extension is installed before a page refresh has happened
    window.hasExtension = true;
    userStore.mutations.setHasExtension(true);

    eventStore.actions.record({
      name: EVENTS.EXTENSION.INSTALLED,
      data: {
        referredBy: referrer,
      },
    });
  }
}
