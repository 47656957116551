<template>
  <div
    class="plans-container d-flex flex-column justify-content-center align-items-center"
  >
    <h2 class="title mt-5 mb-4">Your Current Plan</h2>
    <b-alert variant="warning" class="mb-4" :show="isCancelled">
      Your current
      <strong>{{ subscription?.displayName }}</strong> subscription is ending on
      {{ endDate }}
    </b-alert>
    <div class="current-plan mb-4 p-4">
      <SubscriptionPlan :plan="userPlan" />
    </div>
    <div class="text-center px-4">
      <h1 class="title mt-3 mb-3">All Available Plans</h1>
      <p class="blurb">
        Choose the plan that’s best for you. For full details, see our
        <a href="https://privacy.com/pricing">Pricing Page.</a>
      </p>
    </div>

    <div
      class="available-plans-container container-lg p-4 d-flex flex-column flex-lg-row"
    >
      <SubscriptionPlan
        v-for="plan in availablePlans"
        :plan="plan"
        :key="plan.uuid"
      >
        <BaseButton
          variant="primary"
          class="select-plan-button"
          :data-test="'button-submit-plan-' + plan.displayType"
          @click="submit(plan)"
          :disabled="disablePlan(plan.type)"
        >
          Choose Plan
        </BaseButton>
      </SubscriptionPlan>
    </div>

    <div class="footer container-lg mt-5 mb-5 p-4">
      The Privacy card is issued by Patriot Bank, N.A., pursuant to licenses
      from Mastercard® International Incorporated and Visa U.S.A. Inc. and may
      be used everywhere Mastercard and Visa are accepted. Mastercard is a
      registered trademark of Mastercard International.
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { Confirm } from "@/mixins/Confirm";
import { subscriptionStore, userStore } from "@/store";
import {
  SubscriptionType,
  SubscriptionState,
  PlanData,
  Plan,
} from "@/types/Subscription";
import { OnboardingSteps } from "@/types/Onboarding";
import { formatDate, utcDate } from "@/lib/dates";
import SubscriptionPlan from "./SubscriptionPlan.vue";

@Component({
  components: {
    SubscriptionPlan,
  },
})
export default class SelectSubscriptionPlan extends Mixins(Confirm) {
  waiting = false;
  error = "";

  get user() {
    return userStore.getters.currentUser!;
  }

  get subscription() {
    return subscriptionStore.getters.subscription;
  }

  get plans() {
    return subscriptionStore.getters.plans;
  }

  get availablePlans() {
    return this.plans.filter(
      (plan) => plan.type !== this.subscription?.subscriptionType
    );
  }

  get userPlan() {
    return this.plans.find(
      (plan) => plan.type === this.subscription?.subscriptionType
    );
  }

  get currentPlanType() {
    return this.userPlan?.type;
  }

  get endDate() {
    return this.subscription?.endDate
      ? formatDate("MM/DD/YY", utcDate(this.subscription.endDate))
      : null;
  }

  get isCancelled() {
    return this.subscription?.state === SubscriptionState.CANCELLED;
  }

  disablePlan(type: SubscriptionType) {
    const currentPlanCheck = type === this.currentPlanType && !this.isCancelled;
    const freePlanCheck =
      type === SubscriptionType.FREE &&
      (this.isCancelled ||
        this.subscription?.subscriptionType === SubscriptionType.FREE);

    return currentPlanCheck || freePlanCheck;
  }

  shouldShowDowngradeWarning = (selectedPlan: Plan) => {
    return !!(
      (!selectedPlan.cardTypes?.digitalWalletCards &&
        this.subscription?.features?.digitalWalletCards) ||
      (!selectedPlan.cardTypes?.categoryCards &&
        this.subscription?.features?.categoryCards)
    );
  };

  async submit(selectedPlan: PlanData) {
    this.error = "";
    subscriptionStore.actions.removeTemporaryPlanCookie();

    if (!selectedPlan) {
      this.error = "Please select a plan";

      return;
    }

    if (selectedPlan.type !== SubscriptionType.FREE) {
      subscriptionStore.actions.setTemporaryPlanCookie({
        planType: selectedPlan.uuid,
      });
    }

    if (this.shouldShowDowngradeWarning(selectedPlan)) {
      this.$router.push({ name: "downgrade-warning" });
      return;
    } else if (selectedPlan.type !== SubscriptionType.FREE) {
      this.$router.push({ name: `onboarding-${OnboardingSteps.BILLING}` });
      return;
    }

    this.waiting = true;
    let subscription;
    try {
      subscription = await subscriptionStore.actions.delete(
        this.subscription!.subscriptionUuid
      );
    } catch (err) {
      // @ts-ignore
      this.error = err.message;
      this.waiting = false;

      return;
    }

    let endDate = "";

    if (subscription.state === SubscriptionState.CANCELLED) {
      endDate += " It will remain active until ";

      endDate += subscription.endDate
        ? formatDate("MM/DD/YYYY", utcDate(subscription.endDate))
        : "the end of the current billing period.";
    }

    await this.confirm(`Your subscription has been cancelled.${endDate}`, {
      title: "Subscription Cancelled",
      okOnly: true,
    });

    this.$router.push({ name: "home" });
  }
}
</script>

<style lang="scss" scoped>
.plans-container {
  background: #f0f0f5;
  padding-top: 80px;
}

::v-deep .current-plan {
  .subscription-plan {
    background: white;
    color: $gray-800;
    gap: 16px;
    min-width: 400px;

    @media #{$media-phone} {
      min-width: unset;
    }
  }
  .divider {
    background: $gray-300;
  }
}

.title {
  font-family: $font-stack-wes-fy;
  font-size: 24px;
  color: $gray-800;
  line-height: 1.4;
}

.available-plans-container {
  gap: 24px;

  ::v-deep .disclaimer a {
    color: white;
    text-decoration: underline;
  }
}

.select-plan-button {
  border-radius: 8px;
  background: $brand-purple;
}

.footer {
  color: $gray-700;
  text-align: center;
}
</style>
