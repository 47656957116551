<template>
  <KybVerificationContainer>
    <h3 class="heading">Verification Complete</h3>
    <div class="w-100 d-flex flex-column align-items-center">
      <img
        src="/assets/images/kyb-verification/checkmark-inreview.svg"
        alt="Account In Review"
      />
      <span class="account-state mt-4">Account In Review</span>
    </div>
    <p class="blurb pb-0 mb-0">
      Your business information has been submitted for review by our team. Keep
      an eye out for an email update from us.
    </p>
    <p class="blurb pb-0 mb-0">
      Thank you in advance for your patience. You may continue to use your
      Privacy account in the meantime.
    </p>
    <div class="divider"></div>
    <BaseButton variant="primary" class="cta-btn" @click="nextStep">
      Close
    </BaseButton>
  </KybVerificationContainer>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { Toast } from "@/mixins/Toast";
import { userStore } from "@/store";
import BaseButton from "@/components/BaseButton.vue";
import KybVerificationContainer from "./KybVerificationContainer.vue";

@Component({
  components: {
    BaseButton,
    KybVerificationContainer,
  },
})
export default class KybInReview extends Mixins(Toast) {
  get kybUser() {
    return userStore.getters.currentUser!.kybVerificationUser;
  }

  get piwikPrefix() {
    return this.kybUser ? "Verification" : "Onboarding";
  }

  nextStep() {
    this.$piwik.trackClick({
      name: `${this.piwikPrefix} Complete - In Review CTA`,
    });
    this.$router.push({ name: "home" });
  }

  async created() {
    await userStore.actions.getCurrentUser();
    this.$piwik.trackEvent("Modal", "View", {
      name: `${this.piwikPrefix} Complete - In Review`,
    });
  }
}
</script>

<style lang="scss" scoped>
.account-state {
  padding: 4px;
  background-color: $warning-10;
  font-size: 13px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 1.1px;
  color: $warning-default;
  text-transform: uppercase;
  border-radius: 4px;
  margin-right: 4px;
}

.blurb {
  font-size: 15px !important;
}
</style>
