<template>
  <BaseModal :id="modalId" hide-header>
    <div class="modal-card-edit-funding">
      <div class="header"><div class="title">Funding Source</div></div>
      <div class="funding-source-list">
        <FundingSource
          v-for="account in userFundingSources"
          :key="account._id"
          :source="account"
          :isSelected="viewFundingSourceId === account.bankAccountID"
          @click.native="onPressFundingSource(account)"
        />
      </div>
    </div>
  </BaseModal>
</template>
<script lang="ts">
import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import { Card } from "@/types/Card";
import { EVENTS } from "@/types/Event";
import { cardStore, eventStore, userStore } from "@/store";
import { Toast } from "@/mixins/Toast";
import FundingSource from "./FundingSource.vue";

@Component({
  components: {
    FundingSource,
  },
})
export default class EditFundingSource extends Mixins(Toast) {
  @Prop({ default: "edit-funding" }) modalId!: string;
  @Prop() card!: Card;
  @Prop() state?: string;

  submitting = false;
  viewFundingSourceId = "";

  get user() {
    return userStore.getters.currentUser;
  }

  get isNewCard() {
    return !this.card.created;
  }

  created() {
    this.onStateChange();
  }

  @Watch("state")
  onStateChange() {
    this.viewFundingSourceId = this.card.bankAccountID?.toString();
  }

  closeModal() {
    this.$bvModal.hide("edit-funding");
  }

  get userFundingSources() {
    return (this.user?.bankAccountList || []).concat(
      this.user?.fundingCardList || []
    );
  }

  onPressFundingSource(account: any) {
    const TIMEOUT = 200;
    this.viewFundingSourceId = account.bankAccountID;

    if (this.submitting) {
      return;
    }

    this.submitting = true;

    const updateData = {
      bankAccountID: this.viewFundingSourceId,
    };

    const updatedCard = { ...this.card, ...updateData };

    if (this.isNewCard) {
      this.$emit("update-card", updatedCard);
      setTimeout(this.closeModal, TIMEOUT);
      return;
    }

    cardStore.actions
      .update({ uuid: this.card.cardUuid, updates: updateData })
      .then(() => {
        eventStore.actions.record({
          name: EVENTS.CARD.SET_FUNDING_SOURCE,
        });

        this.$emit("update-card", updatedCard);
        setTimeout(this.closeModal, TIMEOUT);
      })
      .catch((err) => {
        this.errorToast(
          err?.response?.data?.message || "Couldn't update funding source"
        );
      })
      .finally(() => {
        this.submitting = false;
      });
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mixins.scss";

.modal-card-edit-funding {
  @include modal-card-edit;

  padding: 30px 10px 0;
  max-height: calc(100vh - 100px);
  max-width: calc(100vw - 20px);

  > .funding-source-list {
    padding-bottom: 20px;
    overflow-y: auto;
  }

  ::v-deep > .funding-source-list > .funding-source-wrapper {
    padding: $border-radius-lg;
    border-radius: $border-radius-lg;
    cursor: pointer;

    &:hover,
    &.-selected {
      background-color: $gray-100;
    }
  }

  @media #{$media-phone-small} {
    padding: 30px 0 0;
  }
}
</style>
