import { InvitedUser, Promo } from "@/types/Promo";
import axios, { AxiosResponse } from "axios";
import { ActionHandler } from "./vuex-typex";

import { RootState, storeBuilder } from "./storeBuilder";

type PromoState = Record<string, never>;

type PromoAction<Payload = void, Type = void> = ActionHandler<
  PromoState,
  RootState,
  any,
  Payload,
  Type
>;

const builder = storeBuilder.module<PromoState>("promo", {});
const base = "/api/v1/promo";

const getActivePromo: PromoAction<void, AxiosResponse<Promo>> = () => {
  return axios.get(`${base}/activePromo`);
};

const sendReferInviteEmail: PromoAction<
  string[],
  AxiosResponse<{ newlyInvitedUsers: InvitedUser[] }>
> = (context, inviteEmails) => {
  return axios.post(`${base}/sendReferInviteEmail`, { inviteEmails });
};

export const actions = {
  getActivePromo: builder.dispatch(getActivePromo),
  sendReferInviteEmail: builder.dispatch(sendReferInviteEmail),
};
