<template>
  <KybVerificationContainer>
    <BaseForm class="business-owner pt-0 mt-0">
      <h3 class="heading mb-3">Please confirm who owns this business</h3>
      <p class="blurb mb-4">Are you the only owner of this business?</p>
      <BaseButton
        variant="light"
        type="submit"
        class="mb-2"
        @click="nextStep(true)"
      >
        Yes
      </BaseButton>
      <BaseButton variant="light" type="submit" @click="nextStep(false)">
        No
      </BaseButton>
    </BaseForm>
  </KybVerificationContainer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { organizationStore, userStore } from "@/store";
import KybVerificationContainer from "@/views/kyb-verification/KybVerificationContainer.vue";
import { OwnershipType } from "@/types/Organization";

@Component({
  components: {
    KybVerificationContainer,
  },
})
export default class ConfirmBusinessOnlyOwner extends Vue {
  get kybUser() {
    return userStore.getters.currentUser!.kybVerificationUser;
  }

  get piwikPrefix() {
    return this.kybUser ? "Verification" : "Onboarding";
  }

  nextStep(isOnlyOwner: boolean) {
    if (isOnlyOwner) {
      organizationStore.actions.execs({
        ownershipType: OwnershipType.PRIMARY_USER_OWNS,
        owners: [],
      });
      this.$piwik.trackClick({
        name: `${this.piwikPrefix} Sole Owner Yes CTA`,
      });
      this.$router.push({ name: "kybBusinessUsecase" });
    } else {
      this.$piwik.trackClick({
        name: `${this.piwikPrefix} Sole Owner No CTA`,
      });
      this.$router.push({ name: "kybConfirmBusinessOwners" });
    }
  }

  async created() {
    await userStore.actions.getCurrentUser();
    this.$piwik.trackEvent("Modal", "View", {
      name: `${this.piwikPrefix} Sole Owner`,
    });

    const currentStep = "kybConfirmBusinessOnlyOwner";
    if (this.kybUser) {
      userStore.actions.updateVerificationStep(currentStep);
    } else {
      userStore.actions.updateUser({ kybCurrentStep: currentStep });
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  width: 320px !important;
}
.business-owner {
  .blurb {
    margin-bottom: 1rem;
    color: $gray-800;
  }
}
</style>
