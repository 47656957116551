// This is the entry point for running as a vue-only application
// using npm run serve.
import Vue from "vue";
import bowser from "bowser";
import * as Sentry from "@sentry/vue";

import "./base-components";
import "./class-hooks";
import "./config";
import "./directives";

import App from "./App.vue";
import router from "./router";
import store, { eventStore } from "./store";

Vue.config.productionTip = false;

Vue.config.errorHandler = function (err) {
  try {
    const { currentRoute } = router;
    let browserName = bowser.name || "Unknown";
    browserName += bowser.version ? "::" + bowser.version : "";

    // Log the error for analytics
    const error = err.stack
      ? err.stack
          .split("\n")
          .slice(0, 8)
          .reduce(function (prev, line) {
            return prev + line.trim() + "~~";
          }, "")
          .slice(0, -2)
      : "";
    const errorStr = currentRoute.path + "::" + browserName + "::" + error;
    eventStore.actions.error(errorStr);
  } catch (e) {
    /* ignored */
  }
};

Sentry.init({
  Vue,
  dsn: "https://994c9221d2231386cbaf108277547205@o1053332.ingest.us.sentry.io/4507612074409984",
  integrations: [
    Sentry.vueIntegration({ Vue, attachProps: false }),
    Sentry.extraErrorDataIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app\.privacy\.com\/api/],
  enabled: process.env.NODE_ENV === "production",
  environment: process.env.NODE_ENV,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
