import type {
  VueZendesk,
  VueZendeskPlugin,
  ZendeskSettings,
  ZendeskConfig,
} from "./types";

const Zendesk: VueZendeskPlugin = {
  install(Vue, config) {
    if (!config.key) {
      throw new Error("Zendesk key is required");
    }

    const instance: VueZendesk = new Vue();

    window.zESettings = config.settings;

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.defer = true;
    script.id = "ze-snippet";
    script.src = "https://static.zdassets.com/ekr/snippet.js?key=" + config.key;

    document.head.appendChild(script);

    const loaded = new Promise((resolve) => {
      script.onload = (event) => {
        instance.$emit("loaded", event);

        window.zE("webWidget:on", "open", () => {
          // wait for the animations (support button and widget) to finish
          setTimeout(() => instance.$emit("open"), 550);
        });

        window.zE("webWidget:on", "close", () => {
          // wait for the animations (widget) to finish
          setTimeout(() => instance.$emit("close"), 300);
        });

        resolve(undefined);
      };
    });

    instance.clear = async () => {
      await loaded;
      window.zE("webWidget", "clear");
    };
    instance.close = async () => {
      await loaded;
      window.zE("webWidget", "close");
    };
    instance.hide = async () => {
      await loaded;
      window.zE("webWidget", "hide");
      // wait for the animations to finish
      setTimeout(() => instance.$emit("hide"), 300);
    };
    instance.identify = async (user) => {
      await loaded;
      window.zE("webWidget", "identify", user);
    };
    instance.logout = async () => {
      await loaded;
      window.zE("webWidget", "logout");
    };
    instance.on = async (command, prop) => {
      await loaded;
      window.zE("webWidget:on", command, prop);
    };
    instance.open = async () => {
      await loaded;
      window.zE("webWidget", "open");
    };
    instance.prefill = async (user) => {
      await loaded;
      window.zE("webWidget", "prefill", user);
    };
    instance.reset = async () => {
      await loaded;
      window.zE("webWidget", "reset");
    };
    instance.show = async () => {
      await loaded;
      window.zE("webWidget", "show");
      // wait for the animations to finish
      setTimeout(() => instance.$emit("show"), 300);
    };
    instance.toggle = async () => {
      await loaded;
      window.zE("webWidget", "toggle");
    };
    instance.updateSettings = async (settings) => {
      await loaded;
      window.zE("webWidget", "updateSettings", settings);
    };

    Vue.prototype.$zendesk = instance;
  },
};

export default Zendesk;
export type { VueZendeskPlugin, ZendeskSettings, ZendeskConfig };
