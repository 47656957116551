<template>
  <div class="get-extension-container">
    <h1>Save Time at Checkout</h1>
    <p>Create and autofill Privacy Cards with our browser extension</p>
    <button class="cta-button" @click="install('login-screen')">
      <div class="cta-button-content">
        Add To {{ browserName }}
        <img :src="browserIconSrc" class="browser-icon" />
      </div>
    </button>
    <img
      src="/assets/images/misc/login-extension-download.png"
      class="background-image"
      alt=""
    />
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { Extension } from "@/mixins/Extension";

@Component
export default class GetExtension extends Mixins(Extension) {
  get browserIconSrc(): string {
    return this.browserIcon("24");
  }
}
</script>

<style lang="scss" scoped>
.get-extension-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-height: 100%;
  width: 391px;
}

h1 {
  margin-top: 30px;
  font-family: $font-stack-wes-fy;
  font-weight: 900;
  font-size: 18px;
  color: #ffffff;
  line-height: 22px;
}

p {
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  font-family: $font-stack-lato;
}
.cta-button {
  width: 145px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 6px 10px;
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  font-family: $font-stack-lato;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  border: none;

  &:hover {
    background-color: white;
    color: #0b1730;
  }

  .cta-button-content {
    width: 100%;
  }

  .browser-icon {
    margin-left: 8px;
    margin-bottom: 2px;
    width: 16px;
    height: 16px;
  }
}

.background-image {
  margin-top: auto;
  margin-right: -40px;
  margin-left: -40px;
  margin-bottom: 20px;
  pointer-events: none;
}

@media #{$media-width-900} {
  .get-extension-container {
    margin: 0 auto;
  }
}
</style>
